import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompaniesForFuelCompanyReconciliationReportsRepository
  from "../../repositories/FuelCompaniesForFuelCompanyReconciliationReportsRepository"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"

export interface GetFuelCompaniesForFuelCompanyReconciliationReportsParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase
  implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompaniesForFuelCompanyReconciliationReportsRepository:
    FuelCompaniesForFuelCompanyReconciliationReportsRepository

  constructor(parameters: {
    readonly fuelCompaniesForFuelCompanyReconciliationReportsRepository:
      FuelCompaniesForFuelCompanyReconciliationReportsRepository
  }) {
    this.fuelCompaniesForFuelCompanyReconciliationReportsRepository =
      parameters.fuelCompaniesForFuelCompanyReconciliationReportsRepository
  }

  async call(
    parameters: GetFuelCompaniesForFuelCompanyReconciliationReportsParameters
  ): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompaniesForFuelCompanyReconciliationReportsRepository.getFuelCompanies(parameters)
  }
}
