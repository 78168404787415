import TransportsTextProvider from "../TransportsTextProvider"

export default class DefaultRuTransportsTextProvider implements TransportsTextProvider {

  blockedField(): string {
    return "Блокировка"
  }

  stateNumberField(): string {
    return "Госномер"
  }

  vinNumberField(): string {
    return "VIN-номер"
  }

  existObjectTitle(): string {
    return "Автомобиль"
  }

  listTitle(): string {
    return "Автомобили"
  }

  modelField(): string {
    return "Марка/модель"
  }

  carrierField(): string {
    return "Перевозчик"
  }

  newObjectTitle(): string {
    return "Новый автомобиль"
  }

  statusField(): string {
    return "Статус"
  }

  vehicleCertificateNumberField(): string {
    return "Номер ПТС/СТС"
  }

  fuelTypesField(): string {
    return "Виды топлива"
  }

  maximumFillingVolumeField(): string {
    return "Максимальный объем заправки"
  }

  statusValueActive(): string {
    return "Активен"
  }

  statusValueBlocked(): string {
    return "Заблокирован"
  }

  refuellingOrdersLink(): string {
    return "Заправки"
  }

  transportFuelFuelIdField(): string {
    return "Вид топлива"
  }

  transportFuelTankVolumeField(): string {
    return "Объем бака"
  }

  addTransportFuel(): string {
    return "Новый тип топлива"
  }

  newTransportFuel({
    index
  }: {
    readonly index: number
  }): string {
    return `Тип топлива № ${index}`
  }

  carrierFilter(): string {
    return "Перевозчик"
  }
}
