import TransportsRepository from "../../domain/repositories/TransportsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultTransportsRepository from "../../data/repositories/DefaultTransportsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForTransportRepository from "../../domain/repositories/CarriersForTransportRepository"
import DefaultCarriersForTransportRepository from "../../data/repositories/DefaultCarriersForTransportRepository"
import FuelsForTransportsRepository from "../../domain/repositories/FuelsForTransportsRepository"
import DefaultFuelsForTransportsRepository from "../../data/repositories/DefaultFuelsForTransportsRepository"

export default interface TransportsDataDiModule {
  provideTransportsRepository(): TransportsRepository

  provideCarriersForTransportsRepository(): CarriersForTransportRepository

  provideFuelsForTransportsRepository(): FuelsForTransportsRepository
}

export class DefaultTransportsDataDiModule extends DiModule implements TransportsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideTransportsRepository(): TransportsRepository {
    return this.single(
      DefaultTransportsRepository.name,
      () => new DefaultTransportsRepository({
        transportsNetworkSource: this.appDataDiModule.provideTransportsNetworkSource()
      })
    )
  }

  provideCarriersForTransportsRepository(): CarriersForTransportRepository {
    return this.single(
      DefaultCarriersForTransportRepository.name,
      () => new DefaultCarriersForTransportRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }

  provideFuelsForTransportsRepository(): FuelsForTransportsRepository {
    return this.single(
      DefaultFuelsForTransportsRepository.name,
      () => new DefaultFuelsForTransportsRepository({
        fuelsNetworkSource: this.appDataDiModule.provideFuelsNetworkSource()
      })
    )
  }
}
