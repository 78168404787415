import React from "react"
import { MultiSelectFormFieldViewState } from "../../../entities/forms/form-field-by-type/MultiSelectFormField"
import styles from "./MultiSelectFormFieldComponent.module.scss"
import AppMultiSelectPickerComponent
  from "../../../../../../core/presentation/components/app-multi-select-picker/AppMultiSelectPickerComponent"
import LabelComponent from "../../../../../../lib/label/LabelComponent"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"

export default function MultiSelectFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: MultiSelectFormFieldViewState<unknown>
  readonly isDisabled: boolean
}) {
  const {
    selectedOptions,
    options,
    isLoading,
    query,
    page,
    onOpened,
    onSelect,
    onQueryChanged,
    onSearchRequested,
    onNextPageRequested,
    onSelectedItemClicked
  } = fieldViewState

  const theme = useCoreThemeProvider()
  const label = fieldViewState.getTitle()
  const name = fieldViewState.getId()
  const isRequired = fieldViewState.getRequired()
  const isClearable = fieldViewState.getClearable()
  const errors = fieldViewState.getErrors()
  const placeholder = fieldViewState.getPlaceholder()

  return (
    <div className={styles.root}>
      {label && name && (
        <LabelComponent
          htmlFor={name}
          text={`${label}${isRequired ? " *" : ""}`}
          className={theme.label1PrimaryClassName()}
        />
      )}
      <AppMultiSelectPickerComponent
        selectedOptions={selectedOptions}
        options={options}
        disabled={isDisabled || !fieldViewState.getEditable()}
        isLoading={isLoading}
        clearable={isClearable}
        placeholder={placeholder}
        query={query}
        page={page}
        onSelect={onSelect}
        onSelectedItemClicked={onSelectedItemClicked}
        onOpened={onOpened}
        onQueryChanged={onQueryChanged}
        onSearchRequested={onSearchRequested}
        onNextPageRequested={onNextPageRequested}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
