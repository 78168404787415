import { Expose, Type } from "class-transformer"
import NetworkCarrierCommercialTerm from "../NetworkCarrierCommercialTerm"

export default class NetworkCarrierCommercialTermRequestBody {

  @Expose()
  @Type(() => NetworkCarrierCommercialTerm)
  readonly carrierCommercialTerm?: NetworkCarrierCommercialTerm | null

  constructor(parameters: {
    readonly carrierCommercialTerm?: NetworkCarrierCommercialTerm | null
  }) {
    this.carrierCommercialTerm = parameters.carrierCommercialTerm
  }
}
