import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkCarrierBalanceChangeDocument from "../NetworkCarrierBalanceChangeDocument"

export default class NetworkCarrierBalanceChangeDocumentsResponseBody {

  @Expose()
  @Type(() => NetworkCarrierBalanceChangeDocument)
  readonly carrierBalanceReplenishments?: NetworkCarrierBalanceChangeDocument[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
