import styles from "./LeftMenuComponent.module.scss"
import { NavLink } from "react-router-dom"
import React, { ReactElement } from "react"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useRootThemeProvider } from "../../contexts/RootThemeProviderContext"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import UserProfile from "../../../../../core/domain/entities/user-profile/UserProfile"
import { filterNotEmpty } from "../../../../../lib/filterNotEmpty"
import isPresent from "../../../../../lib/isPresent"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import UiMenuItem from "./UiMenuItem"

const profileMenuItem = "profileMenuItem"
const settingsMenuItem = "settingsMenuItem"
const logoutMenuItem = "logoutMenuItem"
const adminLabelItem = "adminLabelItem"
const customMenuItems = [profileMenuItem, logoutMenuItem, adminLabelItem]

interface BottomMenuItem {
  readonly id: string
  readonly title: string
  readonly url?: string
  readonly iconComponent?: ReactElement
}

export default function LeftMenuComponent({
  menuItems = [],
  userProfile,
  onLogoutClicked
}: {
  readonly menuItems?: UiMenuItem[]
  readonly userProfile?: UserProfile
  readonly onLogoutClicked: () => void
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const coreTheme = useCoreThemeProvider()
  const rootTheme = useRootThemeProvider()

  const urlProvider = new CoreUrlProvider()
  const needShowSettings = false

  const bottomMenuItems: BottomMenuItem[] = filterNotEmpty([
    isPresent(userProfile?.adminLabel) ? {
      id: adminLabelItem,
      title: userProfile!.adminLabel
    } : undefined,
    isPresent(userProfile) ? {
      id: profileMenuItem,
      title: filterNotEmpty([userProfile.firstName, userProfile.lastName]).join(" "),
      url: urlProvider.buildUserProfileUrl(),
      iconComponent: coreTheme.icProfileComponent()
    } : undefined,
    needShowSettings ? {
      id: settingsMenuItem,
      title: coreTextProvider.settings(),
      url: urlProvider.buildSettingsUrl(),
      iconComponent: coreTheme.icSettingsComponent()
    } : undefined,
    {
      id: logoutMenuItem,
      title: coreTextProvider.logout(),
      url: urlProvider.buildRootUrl(),
      iconComponent: coreTheme.icLogoutComponent()
    }
  ])

  const calculateNavLinkClassName = ({ isActive }: { readonly isActive: boolean }) => {
    return `${styles.menuItemLink} ${isActive ? coreTheme.menu1PrimaryActiveClassName() : coreTheme.menu1PrimaryDefaultClassName()}`
  }

  function renderLogoutMenuItem(menuItem: BottomMenuItem) {
    return (
      <div
        className={`${styles.menuItemLink} ${coreTheme.menu1PrimaryDefaultClassName()}`}
        onClick={onLogoutClicked}
      >
        {menuItem.iconComponent}
        <div className={styles.menuItemText}>
          {menuItem.title}
        </div>
      </div>
    )
  }

  function renderProfileMenuItem(menuItem: BottomMenuItem) {
    return (
      <div className={`${styles.menuItemLink} ${styles.defaultCursor} ${coreTheme.menu1PrimaryDefaultClassName()}`}>
        {menuItem.iconComponent}
        <div title={menuItem.title} className={styles.menuItemText}>
          {menuItem.title}
        </div>
      </div>
    )
  }

  function renderAdminLabelMenuItem(menuItem: BottomMenuItem) {
    return (
      <div className={`${styles.menuItemLink} ${styles.defaultCursor} ${coreTheme.menu1PrimaryDefaultClassName()}`}>
        <div title={menuItem.title} className={styles.menuItemText}>
          {menuItem.title}
        </div>
      </div>
    )
  }

  function renderDefaultMenuItem(menuItem: BottomMenuItem) {
    return (
      <NavLink
        to={menuItem.url!}
        className={calculateNavLinkClassName}
        end
      >
        {menuItem.iconComponent}
        <div className={styles.menuItemText}>
          {menuItem.title}
        </div>
      </NavLink>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <NavLink
          to={urlProvider.buildRootUrl()}
          end
        >
          <img
            src={rootTheme.imLeftMenuLogoPath()}
            alt="ImLeftMenuLogoPath"
          />
        </NavLink>
      </div>
      <nav className={styles.menu}>
        <ul className={styles.menuItemsList}>
          {menuItems.map((menuItem: UiMenuItem) => (
            <li key={menuItem.key}>
              <NavLink
                to={menuItem.url}
                className={calculateNavLinkClassName}
                end={menuItem.url === urlProvider.buildRootUrl()}
              >
                {menuItem.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.footer}>
        <nav className={styles.menu}>
          <ul className={styles.menuItemsList}>
            {bottomMenuItems.map((menuItem) => {
              return (
                <li key={menuItem.id}>
                  {menuItem.id === logoutMenuItem && renderLogoutMenuItem(menuItem)}
                  {menuItem.id === profileMenuItem && renderProfileMenuItem(menuItem)}
                  {menuItem.id === adminLabelItem && renderAdminLabelMenuItem(menuItem)}
                  {!customMenuItems.includes(menuItem.id) && renderDefaultMenuItem(menuItem)}
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}
