import FuelPricesRepository from "../../repositories/FuelPricesRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelPrice from "../../../../../core/domain/fuel-prices/FuelPrice"
import FuelPricesFilter from "../../../../../core/domain/fuel-prices/FuelPricesFilter"

export interface GetFuelPricesParameters extends GetObjectsParameters<FuelPricesFilter> {
}

export default class GetFuelPricesUseCase implements GetObjectsUseCase<FuelPrice> {
  private readonly fuelPricesRepository: FuelPricesRepository

  constructor(parameters: {
    readonly fuelPricesRepository: FuelPricesRepository
  }) {
    this.fuelPricesRepository = parameters.fuelPricesRepository
  }

  async call(parameters: GetFuelPricesParameters): Promise<GetObjectsResult<FuelPrice>> {
    return await this.fuelPricesRepository.getFuelPrices(parameters)
  }
}
