import { Expose, Type } from "class-transformer"
import NetworkPermission from "./NetworkPermission"

export default class NetworkPermissionGroup {
  @Expose()
  @Type(() => NetworkPermission)
  readonly permissions?: NetworkPermission[] | null

  @Expose()
  @Type(() => String)
  readonly userType?: string | null
}
