import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import CarriersForFuelCompanyTermCommercialTermsRepository
  from "../../domain/repositories/CarriersForCarrierCommercialTermsRepository"
import CarriersNetworkSource from "../../../../core/data/sources/network/CarriersNetworkSource"
import {
  GetCarriersForFuelCompanyCommercialTermsParameters
} from "../../domain/use-cases/carriers/GetCarriersForFuelCompanyCommercialTermsUseCase"
import Carrier from "../../../../core/domain/carriers/Carrier"
import NetworkCarriersRequestFilter
  from "../../../../core/data/entities/carriers/request-queries/NetworkCarriersRequestFilter"
import CarriersMapper from "../../../../core/data/mappers/CarriersMapper"

export default class DefaultCarriersForFuelCompanyCommercialTermsRepository
  implements CarriersForFuelCompanyTermCommercialTermsRepository {
  private readonly carriersNetworkSource: CarriersNetworkSource

  constructor(parameters: {
    readonly carriersNetworkSource: CarriersNetworkSource
  }) {
    this.carriersNetworkSource = parameters.carriersNetworkSource
  }

  async getCarriers({
    ids,
    query,
    pagination,
    sort
  }: GetCarriersForFuelCompanyCommercialTermsParameters): Promise<GetObjectsResult<Carrier>> {
    const result = await this.carriersNetworkSource.getCarriers({
      filter: new NetworkCarriersRequestFilter({
        query,
        ids
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const carriersMapper = new CarriersMapper()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.carriers!.map((carrier) => {
              return carriersMapper.mapNetworkToDomain({ carrier })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
