import TableValue, { TableValueParameters } from "../TableValue"

export interface ArrayTableValueItem {
  readonly key: string
  readonly text: string | null | undefined
  readonly url?: string | null
}

export default class ArrayTableValue extends TableValue {
  private readonly values: ArrayTableValueItem[] | undefined | null

  constructor(parameters: TableValueParameters & {
    readonly values: ArrayTableValueItem[] | undefined | null
  }) {
    super(parameters)
    this.values = parameters.values
  }

  getValues(): ArrayTableValueItem[] {
    return this.values ?? []
  }
}
