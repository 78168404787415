import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompanyForGasStationsRepository from "../../repositories/FuelCompanyForGasStationsRepository"

export interface GetFuelCompaniesForGasStationsParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForGasStationsUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompanyForGasStationsRepository: FuelCompanyForGasStationsRepository

  constructor(parameters: {
    readonly fuelCompanyForGasStationsRepository: FuelCompanyForGasStationsRepository
  }) {
    this.fuelCompanyForGasStationsRepository = parameters.fuelCompanyForGasStationsRepository
  }

  async call(parameters: GetFuelCompaniesForGasStationsParameters): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompanyForGasStationsRepository.getFuelCompanies(parameters)
  }
}
