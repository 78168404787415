import FuelCompanyUsersRepository from "../../repositories/FuelCompanyUsersRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import UserError from "../../../../../core/domain/users/UserError"

export interface CreateFuelCompanyUserParameters {
  readonly object: User
}

export default class CreateFuelCompanyUserUseCase implements CreateObjectUseCase<User, UserError> {
  private readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository

  constructor(parameters: {
    readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository
  }) {
    this.fuelCompanyUsersRepository = parameters.fuelCompanyUsersRepository
  }

  async call(parameters: CreateFuelCompanyUserParameters): Promise<CreateObjectResult<User, UserError>> {
    return await this.fuelCompanyUsersRepository.createUser(parameters)
  }
}
