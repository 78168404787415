import TableValue from "./TableValue"
import { Entity } from "../../../../../core/domain/entities/user-profile/Entity"

export enum TableColumnAlignment {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center"
}

export default class TableColumn<DomainObject> {
  private readonly name: string
  private readonly title: string
  private readonly entity?: Entity
  private readonly isSortingAvailable?: boolean
  private readonly isVisible?: boolean
  private readonly columnAlignment?: TableColumnAlignment
  private readonly reversedSortOrder: boolean
  private visibilityByPermission?: boolean
  private visibilityUrlByPermission?: boolean

  readonly createValue: (object: DomainObject) => TableValue | null

  constructor(parameters: {
    readonly name: string
    readonly title: string
    readonly entity?: Entity
    readonly isSortingAvailable?: boolean
    readonly isVisible?: boolean
    readonly columnAlignment?: TableColumnAlignment
    readonly reversedSortOrder?: boolean
    readonly createValue: (object: DomainObject) => TableValue | null
  }) {
    this.name = parameters.name
    this.title = parameters.title
    this.entity = parameters.entity
    this.isSortingAvailable = parameters.isSortingAvailable
    this.isVisible = parameters.isVisible ?? true
    this.columnAlignment = parameters.columnAlignment
    this.reversedSortOrder = parameters.reversedSortOrder ?? false
    this.createValue = parameters.createValue
  }

  setVisibilityByPermission(isVisible: boolean) {
    this.visibilityByPermission = isVisible
  }

  setUrlVisibilityByPermission(isVisible: boolean) {
    this.visibilityUrlByPermission = isVisible
  }

  isUrlVisibleByPermission() {
    return this.visibilityUrlByPermission
  }

  getEntity() {
    return this.entity
  }

  getName() {
    return this.name
  }

  getTitle() {
    return this.title
  }

  getSortingAvailable() {
    return this.isSortingAvailable
  }

  getVisible() {
    if (this.visibilityByPermission === false) return false

    return this.isVisible
  }

  getColumnAlignment() {
    return this.columnAlignment
  }

  isReversedSortOrder() {
    return this.reversedSortOrder
  }
}
