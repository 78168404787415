import InstallationInstancesRepository from "../../repositories/InstallationInstancesRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import InstallationInstance from "../../../../../core/domain/installation-instance/InstallationInstance"
import InstallationInstancesFilter from "../../../../../core/domain/installation-instance/InstallationInstancesFilter"

export interface GetInstallationInstancesParameters extends GetObjectsParameters<InstallationInstancesFilter> {
}

export default class GetInstallationInstancesUseCase implements GetObjectsUseCase<InstallationInstance> {
  private readonly installationInstancesRepository: InstallationInstancesRepository

  constructor(parameters: {
    readonly installationInstancesRepository: InstallationInstancesRepository
  }) {
    this.installationInstancesRepository = parameters.installationInstancesRepository
  }

  async call(parameters: GetInstallationInstancesParameters): Promise<GetObjectsResult<InstallationInstance>> {
    return await this.installationInstancesRepository.getInstallationInstances(parameters)
  }
}
