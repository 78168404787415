import CarrierReconciliationReportsTextProvider from "../CarrierReconciliationReportsTextProvider"

const carrierAppAdministratorUserType = "carrierAppAdministrator"

export default class DefaultRuCarrierReconciliationReportsTextProvider
  implements CarrierReconciliationReportsTextProvider {
  listTitle({
    userType
  }: {
    readonly userType: string
  }): string {
    return userType === carrierAppAdministratorUserType ?
      "Сверки взаиморасчётов" :
      "Сверка с перевозчиком"
  }

  listDescription(): string {
    return "Срок хранения сформированных отчетов 30 календарных дней"
  }

  newObjectTitle({
    userType
  }: {
    readonly userType: string
  }): string {
    return userType === carrierAppAdministratorUserType ?
      "Новая сверка" :
      "Новый отчет"
  }

  createdDateField(): string {
    return "Дата создания"
  }

  fromDateField(): string {
    return "Дата начала"
  }

  toDateField(): string {
    return "Дата окончания"
  }

  carrierField(): string {
    return "Перевозчик"
  }

  reportField(): string {
    return "Отчет"
  }
}
