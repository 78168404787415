import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelCompaniesRepository from "../../repositories/FuelCompaniesRepository"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"

export interface GetFuelCompanyParameters {
  readonly objectId: string
}

export default class GetFuelCompanyUseCase implements GetObjectUseCase<FuelCompany> {
  private readonly fuelCompaniesRepository: FuelCompaniesRepository

  constructor(parameters: {
    readonly fuelCompaniesRepository: FuelCompaniesRepository
  }) {
    this.fuelCompaniesRepository = parameters.fuelCompaniesRepository
  }

  async call(parameters: GetFuelCompanyParameters): Promise<GetObjectResult<FuelCompany>> {
    return await this.fuelCompaniesRepository.getFuelCompany(parameters)
  }
}
