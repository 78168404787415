import Carrier from "../../../../../core/domain/carriers/Carrier"
import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import CarriersForCarrierBalancesRepository
  from "../../repositories/CarriersForCarrierBalancesRepository"

export interface GetCarrierForCarrierBalancesParameters {
  readonly objectId: string
}

export default class GetCarrierForCarrierBalancesUseCase implements GetObjectUseCase<Carrier> {
  private readonly carriersForCarrierBalancesRepository: CarriersForCarrierBalancesRepository

  constructor(parameters: {
    readonly carriersForCarrierBalancesRepository: CarriersForCarrierBalancesRepository
  }) {
    this.carriersForCarrierBalancesRepository = parameters.carriersForCarrierBalancesRepository
  }

  async call(parameters: GetCarrierForCarrierBalancesParameters): Promise<GetObjectResult<Carrier>> {
    return await this.carriersForCarrierBalancesRepository.getCarrier(parameters)
  }
}
