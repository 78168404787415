import RefuellingOrdersReportsRepository from "../../repositories/RefuellingOrdersReportsRepository"
import RefuellingOrdersReport from "../../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReport"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import RefuellingOrdersReportError from "../../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReportError"

export interface CreateRefuellingOrdersReportParameters {
  readonly object: RefuellingOrdersReport
}

export default class CreateRefuellingOrdersReportUseCase
  implements CreateObjectUseCase<RefuellingOrdersReport, RefuellingOrdersReportError> {
  private readonly refuellingOrdersReportsRepository: RefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly refuellingOrdersReportsRepository: RefuellingOrdersReportsRepository
  }) {
    this.refuellingOrdersReportsRepository = parameters.refuellingOrdersReportsRepository
  }

  async call(
    parameters: CreateRefuellingOrdersReportParameters
  ): Promise<CreateObjectResult<RefuellingOrdersReport, RefuellingOrdersReportError>> {
    return await this.refuellingOrdersReportsRepository.createRefuellingOrdersReports(parameters)
  }
}
