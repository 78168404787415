import React from "react"
import NotFoundView, { NotFoundViewState } from "./NotFoundView"
import NotFoundPresenter from "./NotFoundPresenter"
import HelmetComponent from "../../../../core/presentation/components/helmet/HelmetComponent"
import NotFoundTextProvider from "../../i18n/NotFoundTextProvider"
import NotFoundComponent from "../components/not-found/NotFoundComponent"

interface Props {
  readonly providePresenter: () => NotFoundPresenter
  readonly notFoundTextProvider: NotFoundTextProvider
}

interface State {
  readonly notFoundViewState?: NotFoundViewState
}

export default class NotFoundPage
  extends React.Component<Props, State>
  implements NotFoundView {

  private readonly presenter: NotFoundPresenter

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showNotFoundViewState(notFoundViewState: NotFoundViewState) {
    this.setState({ notFoundViewState })
  }

  render() {
    const { notFoundViewState } = this.state
    const { notFoundTextProvider } = this.props

    if (!notFoundViewState) {
      return <></>
    }

    return (
      <>
        <HelmetComponent
          title={notFoundTextProvider.title()}
        />
        <NotFoundComponent />
      </>
    )
  }
}
