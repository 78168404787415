import CurrentFuelCompanyBalancesI18n from "../../i18n/CurrentFuelCompanyBalancesI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import CurrentFuelCompanyBalancesTextProvider from "../../i18n/CurrentFuelCompanyBalancesTextProvider"

export default interface CurrentFuelCompanyBalancesI18nDiModule {
  provideCurrentFuelCompanyBalancesI18n(): CurrentFuelCompanyBalancesI18n
}

export class DefaultCurrentFuelCompanyBalancesI18nDiModule
  extends I18nDiModule<CurrentFuelCompanyBalancesTextProvider>
  implements CurrentFuelCompanyBalancesI18nDiModule {

  provideCurrentFuelCompanyBalancesI18n(): CurrentFuelCompanyBalancesI18n {
    return this.single(
      CurrentFuelCompanyBalancesI18n.name,
      () => new CurrentFuelCompanyBalancesI18n(this.getI18nParameters())
    )
  }
}
