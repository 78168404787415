import TransportsRepository from "../../repositories/TransportsRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import Transport from "../../../../../core/domain/transport/Transport"
import TransportError from "../../../../../core/domain/transport/TransportError"

export interface UpdateTransportParameters {
  readonly objectId: string
  readonly object: Transport
}

export default class UpdateTransportUseCase implements UpdateObjectUseCase<Transport, TransportError> {
  private readonly transportsRepository: TransportsRepository

  constructor(parameters: {
    readonly transportsRepository: TransportsRepository
  }) {
    this.transportsRepository = parameters.transportsRepository
  }

  async call(parameters: UpdateTransportParameters): Promise<UpdateObjectResult<Transport, TransportError>> {
    return await this.transportsRepository.updateTransport(parameters)
  }
}
