import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import Carrier from "../../../../../core/domain/carriers/Carrier"
import Transport from "../../../../../core/domain/transport/Transport"
import TransportsForTripsRepository from "../../repositories/TransportsForTripsRepository"
import TransportsFilter from "../../../../../core/domain/transport/TransportsFilter"

export interface GetTransportsForTripsParameters extends GetObjectsParameters<TransportsFilter> {
}

export default class GetTransportsForTripsUseCase implements GetObjectsUseCase<Transport> {
  private readonly transportsForTripsRepository: TransportsForTripsRepository

  constructor(parameters: {
    readonly transportsForTripsRepository: TransportsForTripsRepository
  }) {
    this.transportsForTripsRepository = parameters.transportsForTripsRepository
  }

  async call(parameters: GetTransportsForTripsParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.transportsForTripsRepository.getTransports(parameters)
  }
}
