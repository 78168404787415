import GetFuelCompaniesUseCase from "../../domain/use-cases/fuel-companies/GetFuelCompaniesUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import FuelCompaniesDataDiModule from "./FuelCompaniesDataDiModule"
import CreateFuelCompanyUseCase from "../../domain/use-cases/fuel-companies/CreateFuelCompanyUseCase"
import GetFuelCompanyUseCase from "../../domain/use-cases/fuel-companies/GetFuelCompanyUseCase"
import UpdateFuelCompanyUseCase from "../../domain/use-cases/fuel-companies/UpdateFuelCompanyUseCase"

export default interface FuelCompaniesDomainDiModule {
  provideGetFuelCompaniesUseCase(): GetFuelCompaniesUseCase

  provideGetFuelCompanyUseCase(): GetFuelCompanyUseCase

  provideCreateFuelCompanyUseCase(): CreateFuelCompanyUseCase

  provideUpdateFuelCompanyUseCase(): UpdateFuelCompanyUseCase
}

export class DefaultFuelCompaniesDomainDiModule extends DiModule implements FuelCompaniesDomainDiModule {
  private readonly fuelCompaniesDataDiModule: FuelCompaniesDataDiModule

  constructor(parameters: {
    readonly fuelCompaniesDataDiModule: FuelCompaniesDataDiModule
  }) {
    super()
    this.fuelCompaniesDataDiModule = parameters.fuelCompaniesDataDiModule
  }

  provideGetFuelCompaniesUseCase(): GetFuelCompaniesUseCase {
    return this.single(
      GetFuelCompaniesUseCase.name,
      () => new GetFuelCompaniesUseCase({
        fuelCompaniesRepository: this.fuelCompaniesDataDiModule.provideFuelCompaniesRepository()
      })
    )
  }

  provideGetFuelCompanyUseCase(): GetFuelCompanyUseCase {
    return this.single(
      GetFuelCompanyUseCase.name,
      () => new GetFuelCompanyUseCase({
        fuelCompaniesRepository: this.fuelCompaniesDataDiModule.provideFuelCompaniesRepository()
      })
    )
  }

  provideCreateFuelCompanyUseCase(): CreateFuelCompanyUseCase {
    return this.single(
      CreateFuelCompanyUseCase.name,
      () => new CreateFuelCompanyUseCase({
        fuelCompaniesRepository: this.fuelCompaniesDataDiModule.provideFuelCompaniesRepository()
      })
    )
  }

  provideUpdateFuelCompanyUseCase(): UpdateFuelCompanyUseCase {
    return this.single(
      UpdateFuelCompanyUseCase.name,
      () => new UpdateFuelCompanyUseCase({
        fuelCompaniesRepository: this.fuelCompaniesDataDiModule.provideFuelCompaniesRepository()
      })
    )
  }
}
