import FuelCompanyReconciliationReportsRepository
  from "../../domain/repositories/FuelCompanyReconciliationReportsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultFuelCompanyReconciliationReportsRepository
  from "../../data/repositories/DefaultFuelCompanyReconciliationReportsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForFuelCompanyReconciliationReportsRepository
  from "../../domain/repositories/FuelCompaniesForFuelCompanyReconciliationReportsRepository"
import DefaultFuelCompaniesForFuelCompanyReconciliationReportsRepository
  from "../../data/repositories/DefaultFuelCompaniesForFuelCompanyReconciliationReportsRepository"

export default interface FuelCompanyReconciliationReportsDataDiModule {
  provideFuelCompanyReconciliationReportsRepository(): FuelCompanyReconciliationReportsRepository

  provideFuelCompaniesForFuelCompanyReconciliationReportsRepository(): FuelCompaniesForFuelCompanyReconciliationReportsRepository
}

export class DefaultFuelCompanyReconciliationReportsDataDiModule
  extends DiModule
  implements FuelCompanyReconciliationReportsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideFuelCompanyReconciliationReportsRepository(): FuelCompanyReconciliationReportsRepository {
    return this.single(
      DefaultFuelCompanyReconciliationReportsRepository.name,
      () => new DefaultFuelCompanyReconciliationReportsRepository({
        fuelCompanyReconciliationReportsNetworkSource:
          this.appDataDiModule.provideFuelCompanyReconciliationReportsNetworkSource()
      })
    )
  }

  provideFuelCompaniesForFuelCompanyReconciliationReportsRepository(): FuelCompaniesForFuelCompanyReconciliationReportsRepository {
    return this.single(
      DefaultFuelCompaniesForFuelCompanyReconciliationReportsRepository.name,
      () => new DefaultFuelCompaniesForFuelCompanyReconciliationReportsRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }
}
