import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import TransportsI18 from "../../i18n/TransportsI18"
import Transport from "../../../../core/domain/transport/Transport"
import TransportsFilter from "../../../../core/domain/transport/TransportsFilter"
import GetCarriersForTransportUseCase from "../../domain/use-cases/carriers/GetCarriersForTransportUseCase"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class TransportsTableProvider implements TableProvider<Transport, TransportsFilter> {
  private readonly coreI18n: CoreI18n
  private readonly transportsI18: TransportsI18
  private readonly getCarriersUseCase: GetCarriersForTransportUseCase

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly transportsI18: TransportsI18
    readonly getCarriersForUseCase: GetCarriersForTransportUseCase
  }) {
    this.coreI18n = parameters.coreI18n
    this.transportsI18 = parameters.transportsI18
    this.getCarriersUseCase = parameters.getCarriersForUseCase
  }

  getEntity(): string {
    return Entity.TRANSPORTS
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<Transport, TransportsFilter> {
    const coreTextProvider = this.coreI18n.getTextProvider()
    const appUrlProvider = new AppUrlProvider()
    const transportsTextProvider = this.transportsI18.getTextProvider()

    return new Table<Transport, TransportsFilter>({
      title: transportsTextProvider.listTitle(),
      getObjectId: (transport: Transport) => transport.id!.toString(),
      getRowUrl: (transport: Transport) => appUrlProvider.buildShowTransportUrl({
        transportId: transport.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewTransportUrl(),
      columns: [
        new TableColumn<Transport>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: transportsTextProvider.carrierField(),
          createValue: (transport: Transport) => new TextTableValue({
            value: transport.carrier?.name,
            url: appUrlProvider.buildShowCarrierUrl({
              carrierId: transport.carrierId!
            })
          })
        }),
        new TableColumn<Transport>({
          name: "name",
          isSortingAvailable: true,
          title: transportsTextProvider.modelField(),
          createValue: (transport: Transport) => new TextTableValue({
            value: transport.name
          })
        }),
        new TableColumn<Transport>({
          name: "vehicleCertificateNumber",
          title: transportsTextProvider.vehicleCertificateNumberField(),
          createValue: (transport: Transport) => new TextTableValue({
            value: transport.vehicleCertificateNumber
          })
        }),
        new TableColumn<Transport>({
          name: "vinNumber",
          title: transportsTextProvider.vinNumberField(),
          createValue: (transport: Transport) => new TextTableValue({
            value: transport.vinNumber
          })
        }),
        new TableColumn<Transport>({
          name: "stateNumber",
          title: transportsTextProvider.stateNumberField(),
          createValue: (transport: Transport) => new TextTableValue({
            value: transport.stateNumber
          })
        }),
        new TableColumn<Transport>({
          name: "blocked",
          title: transportsTextProvider.statusField(),
          isSortingAvailable: true,
          reversedSortOrder: true,
          createValue: (transport: Transport) => {
            const { blocked = false } = transport

            return new TextTableValue({
              value: (blocked ?? false) ?
                transportsTextProvider.statusValueBlocked() :
                transportsTextProvider.statusValueActive(),
              isActive: !transport.blocked
            })
          }
        })
      ],
      filter: new Filter<TransportsFilter>({
        buildFilterObject: (): TransportsFilter => ({}),
        fields: [
          new SingleSelectFormField<TransportsFilter, void, Carrier>({
            title: transportsTextProvider.carrierFilter(),
            entity: Entity.CARRIERS,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "carrierId",
            getValue: (filter: TransportsFilter) => filter.carrier,
            setValue: (
              filter: TransportsFilter,
              carrier: Carrier | null
            ): TransportsFilter => ({
              ...filter,
              carrier,
              carrierId: carrier?.id
            }),
            getObjectsUseCase: this.getCarriersUseCase,
            getOptionId: (optionObject: Carrier) => optionObject.id!.toString(),
            getOptionText: (optionObject: Carrier) => optionObject.name
          })
        ]
      })
    })
  }
}
