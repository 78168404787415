import { Entity } from "../../../../../core/domain/entities/user-profile/Entity"

export interface InfoRowParameters {
  readonly name: string
  readonly entity?: Entity
  readonly title: string
  readonly isVisible?: boolean
}

export default class InfoRow {
  private readonly name: string
  private readonly entity?: Entity
  private readonly title: string
  private readonly isVisible: boolean
  private visibilityByPermission?: boolean
  private visibilityUrlByPermission?: boolean

  protected constructor(parameters: InfoRowParameters) {
    this.name = parameters.name
    this.title = parameters.title
    this.entity = parameters.entity
    this.isVisible = parameters.isVisible ?? true
  }

  setVisibilityByPermission(isVisible: boolean) {
    this.visibilityByPermission = isVisible
  }

  setUrlVisibilityByPermission(isVisible: boolean) {
    this.visibilityUrlByPermission = isVisible
  }

  isUrlVisibleByPermission() {
    return this.visibilityUrlByPermission
  }

  getEntity() {
    return this.entity
  }

  getName() {
    return this.name
  }

  getTitle() {
    return this.title
  }

  getIsVisible() {
    if (this.visibilityByPermission === false) return false
    return this.isVisible
  }
}
