import NetworkTripErrorsObjectAttributes from "./NetworkTripErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import TripErrorsObject from "../../../../../domain/trips/TripErrorsObject"
import NetworkTripLimitErrorsObject from "../../../trip-limits/response-partials/errors/NetworkTripLimitErrorsObject"

export default class NetworkTripErrorsObject implements TripErrorsObject {
  @Expose()
  @Type(() => NetworkTripErrorsObjectAttributes)
  readonly attributes?: NetworkTripErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkTripLimitErrorsObject)
  readonly limits?: NetworkTripLimitErrorsObject[] | null
}
