import GasStationsRepository from "../../domain/repositories/GasStationsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultGasStationsRepository from "../../data/repositories/DefaultGasStationsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompanyForGasStationsRepository from "../../domain/repositories/FuelCompanyForGasStationsRepository"
import DefaultFuelCompaniesForGasStationsRepository
  from "../../data/repositories/DefaultFuelCompaniesForGasStationsRepository"

export default interface GasStationsDataDiModule {
  provideGasStationsRepository(): GasStationsRepository

  provideFuelCompanyForGasStationsRepository(): FuelCompanyForGasStationsRepository
}

export class DefaultGasStationsDataDiModule extends DiModule implements GasStationsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideGasStationsRepository(): GasStationsRepository {
    return this.single(
      DefaultGasStationsRepository.name,
      () => new DefaultGasStationsRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }

  provideFuelCompanyForGasStationsRepository(): FuelCompanyForGasStationsRepository {
    return this.single(
      DefaultFuelCompaniesForGasStationsRepository.name,
      () => new DefaultFuelCompaniesForGasStationsRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }
}
