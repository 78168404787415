import AppI18 from "../../../i18n/AppI18"
import TripStatus, { TripStatusSelectOption } from "../../../domain/trips/TripStatus"
import isPresent from "../../../../../admin/lib/isPresent"

export default class TripStatusesStaticSource {
  private readonly appI18n: AppI18

  constructor(parameters: {
    readonly appI18n: AppI18
  }) {
    this.appI18n = parameters.appI18n
  }

  async getTripStatuses({
    ids
  }: {
    readonly ids?: string[] | null
  }): Promise<TripStatusSelectOption[]> {
    const values = [
      {
        id: TripStatus.PENDING,
        text: this.appI18n.getTextProvider().tripStatusPendingText()
      },
      {
        id: TripStatus.CONFIRMED,
        text: this.appI18n.getTextProvider().tripStatusConfirmedText()
      },
      {
        id: TripStatus.FINISHED,
        text: this.appI18n.getTextProvider().tripStatusFinishedText()
      },
      {
        id: TripStatus.CANCELED,
        text: this.appI18n.getTextProvider().tripStatusCanceledText()
      }
    ]

    if (isPresent(ids)) {
      return values.filter((value) => ids.includes(value.id))
    }

    return values
  }
}
