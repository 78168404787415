import { Expose, Type } from "class-transformer"
import NetworkFuelCompany from "../NetworkFuelCompany"

export default class NetworkFuelCompanyRequestBody {

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompany?: NetworkFuelCompany | null

  constructor(parameters: {
    readonly fuelCompany?: NetworkFuelCompany | null
  }) {
    this.fuelCompany = parameters.fuelCompany
  }
}
