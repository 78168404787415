import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkDriversRequestFilter from "../../entities/drivers/request-queries/NetworkDriversRequestFilter"
import NetworkDriversRequestQuery from "../../entities/drivers/request-queries/NetworkDriversRequestQuery"
import NetworkDriversResponseBody from "../../entities/drivers/response-bodies/NetworkDriversResponseBody"
import NetworkDriverResponseBody from "../../entities/drivers/response-bodies/NetworkDriverResponseBody"
import NetworkDriverErrorResponseBody
  from "../../entities/drivers/response-bodies/errors/NetworkDriverErrorResponseBody"
import NetworkUser from "../../entities/users/NetworkUser"
import NetworkDriverRequestBody from "../../entities/drivers/request-bodies/NetworkDriverRequestBody"

const basePath = "/api/admin/drivers"

export default class DriversNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getDrivers({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkDriversRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetDriversNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkDriversRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDriversResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getDriver({
    driverId
  }: {
    readonly driverId: number
  }): Promise<GetDriverNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${driverId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDriverResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createDriver({
    driver
  }: {
    readonly driver?: NetworkUser | null
  }): Promise<CreateDriverNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkDriverRequestBody({
        driver
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDriverResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkDriverErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateDriver({
    driverId,
    driver
  }: {
    readonly driverId: number
    readonly driver?: NetworkUser | null
  }): Promise<UpdateDriverNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${driverId}`,
      body: instanceToPlain(new NetworkDriverRequestBody({
        driver
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkDriverResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkDriverErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetDriversNetworkResult =
  SuccessExecutionResult<NetworkDriversResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetDriverNetworkResult =
  SuccessExecutionResult<NetworkDriverResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateDriverNetworkResult =
  SuccessExecutionResult<NetworkDriverResponseBody> |
  ErrorExecutionResult<NetworkDriverErrorResponseBody> |
  FailureExecutionResult

export type UpdateDriverNetworkResult =
  SuccessExecutionResult<NetworkDriverResponseBody> |
  ErrorExecutionResult<NetworkDriverErrorResponseBody> |
  FailureExecutionResult
