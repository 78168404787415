import CarriersI18 from "../../i18n/CarriersI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import CarriersTextProvider from "../../i18n/CarriersTextProvider"

export default interface CarriersI18nDiModule {
  provideCarriersI18n(): CarriersI18
}

export class DefaultCarriersI18nDiModule
  extends I18nDiModule<CarriersTextProvider>
  implements CarriersI18nDiModule {

  provideCarriersI18n(): CarriersI18 {
    return this.single(
      CarriersI18.name,
      () => new CarriersI18(this.getI18nParameters())
    )
  }
}
