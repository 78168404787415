import { Expose, Type } from "class-transformer"

export default class NetworkPasswordRecoveryRequestRequestBody {
  @Expose()
  @Type(() => String)
  readonly emailAddress?: string | null

  constructor(parameters: {
    readonly emailAddress?: string | null
  }) {
    this.emailAddress = parameters.emailAddress
  }
}
