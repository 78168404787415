import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import DriversFilter from "../../../../../core/domain/drivers/DriversFilter"
import DriversForRefuellingOrdersReportsRepository from "../../repositories/DriversForRefuellingOrdersReportsRepository"

export interface GetDriversForRefuellingOrdersReportsParameters extends GetObjectsParameters<DriversFilter> {
}

export default class GetDriversForRefuellingOrdersReportsUseCase implements GetObjectsUseCase<User> {
  private readonly driversForRefuellingOrdersReportsRepository: DriversForRefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly driversForRefuellingOrdersReportsRepository: DriversForRefuellingOrdersReportsRepository
  }) {
    this.driversForRefuellingOrdersReportsRepository = parameters.driversForRefuellingOrdersReportsRepository
  }

  async call(parameters: GetDriversForRefuellingOrdersReportsParameters): Promise<GetObjectsResult<User>> {
    return await this.driversForRefuellingOrdersReportsRepository.getDrivers(parameters)
  }
}
