import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkRefuellingOrdersReport from "../NetworkRefuellingOrdersReport"

export default class NetworkRefuellingOrdersReportsResponseBody {

  @Expose()
  @Type(() => NetworkRefuellingOrdersReport)
  readonly reports?: NetworkRefuellingOrdersReport[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
