import CoreUrlProvider from "../../../../admin/core/presentation/services/CoreUrlProvider"
import { filterNotEmpty } from "../../../../admin/lib/filterNotEmpty"
import isBlank from "../../../../admin/lib/isBlank"

export interface RefuellingOrdersUrlParameters {
  readonly carrierId?: string | number | null
  readonly transportId?: string | number | null
  readonly driverId?: string | number | null
  readonly tripId?: string | number | null
  readonly status?: string | number | null
  readonly startDate?: string | number | null
  readonly endDate?: string | number | null
  readonly fuelCompanyId?: string | number | null
  readonly gasStationId?: string | number | null
}

export interface DriversUrlParameters {
  readonly carrierId?: string | number | null
}

export interface TransportsUrlParameters {
  readonly carrierId?: string | number | null
}

export interface TripsUrlParameters {
  readonly carrierId?: string | number | null
}

export interface UsersUrlParameters {
  readonly carrierId?: string | number | null
  readonly fuelCompanyId?: string | number | null
}

export interface GasStationsUrlParameters {
  readonly fuelCompanyId?: string | number | null
}

export interface InstallationInstancesUrlParameters {
  readonly fuelCompanyId?: string | number | null
}

export interface FuelPricesUrlParameters {
  readonly gasStationId?: string | number | null
}

export interface TripsUrlParameters {
  readonly carrierId?: string | number | null
  readonly transportId?: string | number | null
  readonly driverId?: string | number | null
}

export interface TripChangingLogUrlParameters {
  readonly tripId?: string | number | null
  readonly changedById?: string | number | null
}

export interface GasStationBreaksUrlParameters {
  readonly gasStationId?: string | number | null
}

export default class AppUrlProvider extends CoreUrlProvider {
  buildCarriersUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.carriersPath()}`
    })
  }

  buildNewCarrierUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newCarrierPath()}`
    })
  }

  buildShowCarrierUrl(parameters: {
    readonly carrierId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showCarrierPath(parameters)}`
    })
  }

  buildEditCarrierUrl(parameters: {
    readonly carrierId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editCarrierPath(parameters)}`
    })
  }

  buildCarrierCommercialTermsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.carrierCommercialTermsPath()}`
    })
  }

  buildNewCarrierCommercialTermUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newCarrierCommercialTermPath()}`
    })
  }

  buildShowCarrierCommercialTermUrl(parameters: {
    readonly carrierCommercialTermId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showCarrierCommercialTermPath(parameters)}`
    })
  }

  buildEditCarrierCommercialTermUrl(parameters: {
    readonly carrierCommercialTermId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editCarrierCommercialTermPath(parameters)}`
    })
  }

  buildFuelCompaniesUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.fuelCompaniesPath()}`
    })
  }

  buildNewFuelCompanyUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newFuelCompanyPath()}`
    })
  }

  buildShowFuelCompanyUrl(parameters: {
    readonly fuelCompanyId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showFuelCompanyPath(parameters)}`
    })
  }

  buildEditFuelCompanyUrl(parameters: {
    readonly fuelCompanyId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editFuelCompanyPath(parameters)}`
    })
  }

  buildFuelCompanyCommercialTermsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.fuelCompanyCommercialTermsPath()}`
    })
  }

  buildNewFuelCompanyCommercialTermUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newFuelCompanyCommercialTermPath()}`
    })
  }

  buildShowFuelCompanyCommercialTermUrl(parameters: {
    readonly fuelCompanyCommercialTermId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showFuelCompanyCommercialTermPath(parameters)}`
    })
  }

  buildEditFuelCompanyCommercialTermUrl(parameters: {
    readonly fuelCompanyCommercialTermId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editFuelCompanyCommercialTermPath(parameters)}`
    })
  }

  buildGasStationsUrl(parameters?: GasStationsUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.gasStationsPath(parameters)}`
    })
  }

  buildNewGasStationUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newGasStationPath()}`
    })
  }

  buildShowGasStationUrl(parameters: {
    readonly gasStationId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showGasStationPath(parameters)}`
    })
  }

  buildEditGasStationUrl(parameters: {
    readonly gasStationId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editGasStationPath(parameters)}`
    })
  }

  buildClaimedRefuellingOrdersUrl(parameters?: RefuellingOrdersUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.claimedRefuellingOrdersPath(parameters)}`
    })
  }

  buildShowClaimedRefuellingOrderUrl(parameters: {
    readonly refuellingOrderId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showClaimedRefuellingOrderPath(parameters)}`
    })
  }

  buildEditClaimedRefuellingOrderUrl(parameters: {
    readonly refuellingOrderId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editClaimedRefuellingOrderPath(parameters)}`
    })
  }

  buildCancelledRefuellingOrdersUrl(parameters?: RefuellingOrdersUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.cancelledRefuellingOrdersPath(parameters)}`
    })
  }

  buildShowCancelledRefuellingOrderUrl(parameters: {
    readonly refuellingOrderId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showCancelledRefuellingOrderPath(parameters)}`
    })
  }

  buildRefuellingOrdersUrl(parameters?: RefuellingOrdersUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.refuellingOrdersPath(parameters)}`
    })
  }

  buildShowRefuellingOrderUrl(parameters: {
    readonly refuellingOrderId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showRefuellingOrderPath(parameters)}`
    })
  }

  buildEditRefuellingOrderUrl(parameters: {
    readonly refuellingOrderId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editRefuellingOrderPath(parameters)}`
    })
  }

  buildFuelPricesUrl(parameters?: FuelPricesUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.fuelPricesPath(parameters)}`
    })
  }

  buildNewFuelPriceUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newFuelPricePath()}`
    })
  }

  buildShowFuelPriceUrl(parameters: {
    readonly fuelPriceId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showFuelPricePath(parameters)}`
    })
  }

  buildEditFuelPriceUrl(parameters: {
    readonly fuelPriceId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editFuelPricePath(parameters)}`
    })
  }

  buildDriversUrl(parameters?: DriversUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.driversPath(parameters)}`
    })
  }

  buildNewDriverUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newDriverPath()}`
    })
  }

  buildShowDriverUrl(parameters: {
    readonly driverId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showDriverPath(parameters)}`
    })
  }

  buildEditDriverUrl(parameters: {
    readonly driverId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editDriverPath(parameters)}`
    })
  }

  buildTransportsUrl(parameters?: TransportsUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.transportsPath(parameters)}`
    })
  }

  buildNewTransportUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newTransportPath()}`
    })
  }

  buildShowTransportUrl(parameters: {
    readonly transportId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showTransportPath(parameters)}`
    })
  }

  buildEditTransportUrl(parameters: {
    readonly transportId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editTransportPath(parameters)}`
    })
  }

  buildTripsUrl(parameters?: TripsUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.tripsPath(parameters)}`
    })
  }

  buildNewTripUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newTripPath()}`
    })
  }

  buildShowTripUrl(parameters: {
    readonly tripId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showTripPath(parameters)}`
    })
  }

  buildEditTripUrl(parameters: {
    readonly tripId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editTripPath(parameters)}`
    })
  }

  buildCurrentCarrierBalanceUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.currentCarrierBalancePath()}`
    })
  }

  buildCarrierBalanceUrl(parameters: {
    readonly carrierId: string | number
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.carrierBalancePath(parameters)}`
    })
  }

  buildCarrierBalanceReplenishmentUrl(parameters: {
    readonly carrierId: string | number
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.carrierBalanceReplenishmentPath(parameters)}`
    })
  }

  buildCurrentFuelCompanyBalanceUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.currentFuelCompanyBalancePath()}`
    })
  }

  buildFuelCompanyBalanceUrl(parameters: {
    readonly fuelCompanyId: string | number
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.fuelCompanyBalancePath(parameters)}`
    })
  }

  buildFuelCompanyBalanceChangeDocumentUrl(parameters: {
    readonly fuelCompanyId: string | number
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.fuelCompanyBalanceChangeDocumentPath(parameters)}`
    })
  }

  buildUsersUrl(parameters?: UsersUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.usersPath(parameters)}`
    })
  }

  buildShowUserUrl(parameters: {
    readonly userId: string | number
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showUserPath(parameters)}`
    })
  }

  buildEditUserUrl(parameters: {
    readonly userId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editUserPath(parameters)}`
    })
  }

  buildNewUserUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newUserPath()}`
    })
  }

  buildCarrierUsersUrl(parameters?: UsersUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.carrierUsersPath(parameters)}`
    })
  }

  buildShowCarrierUserUrl(parameters: {
    readonly userId: string | number
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showCarrierUserPath(parameters)}`
    })
  }

  buildEditCarrierUserUrl(parameters: {
    readonly userId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editCarrierUserPath(parameters)}`
    })
  }

  buildNewCarrierUserUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newCarrierUserPath()}`
    })
  }

  buildFuelCompanyUsersUrl(parameters?: UsersUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.fuelCompanyUsersPath(parameters)}`
    })
  }

  buildShowFuelCompanyUserUrl(parameters: {
    readonly userId: string | number
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showFuelCompanyUserPath(parameters)}`
    })
  }

  buildEditFuelCompanyUserUrl(parameters: {
    readonly userId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editFuelCompanyUserPath(parameters)}`
    })
  }

  buildNewFuelCompanyUserUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newFuelCompanyUserPath()}`
    })
  }

  buildInstallationInstancesUrl(parameters?: InstallationInstancesUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.installationInstancesPath(parameters)}`
    })
  }

  buildNewInstallationInstanceUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newInstallationInstancePath()}`
    })
  }

  buildShowInstallationInstanceUrl(parameters: {
    readonly installationInstanceId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showInstallationInstancePath(parameters)}`
    })
  }

  buildEditInstallationInstanceUrl(parameters: {
    readonly installationInstanceId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editInstallationInstancePath(parameters)}`
    })
  }

  buildTripChangingLogUrl(parameters?: TripChangingLogUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.tripChangingLogPath(parameters)}`
    })
  }

  buildForbiddenUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.forbiddenPath()}`
    })
  }

  buildRefuellingOrdersReportsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.refuellingOrdersReportsPath()}`
    })
  }

  buildNewRefuellingOrdersReportsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newRefuellingOrdersReportsPath()}`
    })
  }

  buildCarrierReconciliationReportsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.carrierReconciliationReportsPath()}`
    })
  }

  buildNewCarrierReconciliationReportsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newCarrierReconciliationReportsPath()}`
    })
  }

  buildFuelCompanyReconciliationReportsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.fuelCompanyReconciliationReportsPath()}`
    })
  }

  buildNewFuelCompanyReconciliationReportsUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newFuelCompanyReconciliationReportsPath()}`
    })
  }

  buildGasStationBreaksUrl(parameters?: GasStationBreaksUrlParameters): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.gasStationBreaksPath(parameters)}`
    })
  }

  buildNewGasStationBreaksUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.newGasStationBreakPath()}`
    })
  }

  buildShowGasStationBreakUrl(parameters: {
    readonly gasStationBreakId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.showGasStationBreakPath(parameters)}`
    })
  }

  buildEditGasStationBreakUrl(parameters: {
    readonly gasStationBreakId: number | string
  }): string {
    return this.buildUrl({
      path: `${this.rootPath()}/${this.editGasStationBreakPath(parameters)}`
    })
  }

  private currentCarrierPath(): string {
    return "current_carrier"
  }

  private carriersPath(): string {
    return "carriers"
  }

  private newCarrierPath(): string {
    return `${this.carriersPath()}/new`
  }

  private showCarrierPath({
    carrierId
  }: {
    readonly carrierId: number | string
  }): string {
    return `${this.carriersPath()}/${carrierId}`
  }

  private editCarrierPath({
    carrierId
  }: {
    readonly carrierId: number | string
  }): string {
    return `${this.carriersPath()}/${carrierId}/edit`
  }

  private carrierCommercialTermsPath(): string {
    return "carrier_commercial_terms"
  }

  private newCarrierCommercialTermPath(): string {
    return `${this.carrierCommercialTermsPath()}/new`
  }

  private showCarrierCommercialTermPath({
    carrierCommercialTermId
  }: {
    readonly carrierCommercialTermId: number | string
  }): string {
    return `${this.carrierCommercialTermsPath()}/${carrierCommercialTermId}`
  }

  private editCarrierCommercialTermPath({
    carrierCommercialTermId
  }: {
    readonly carrierCommercialTermId: number | string
  }): string {
    return `${this.carrierCommercialTermsPath()}/${carrierCommercialTermId}/edit`
  }

  private currentFuelCompanyPath(): string {
    return "current_fuel_company"
  }

  private fuelCompaniesPath(): string {
    return "fuel_companies"
  }

  private newFuelCompanyPath(): string {
    return `${this.fuelCompaniesPath()}/new`
  }

  private showFuelCompanyPath({
    fuelCompanyId
  }: {
    readonly fuelCompanyId: number | string
  }): string {
    return `${this.fuelCompaniesPath()}/${fuelCompanyId}`
  }

  private editFuelCompanyPath({
    fuelCompanyId
  }: {
    readonly fuelCompanyId: number | string
  }): string {
    return `${this.fuelCompaniesPath()}/${fuelCompanyId}/edit`
  }

  private fuelCompanyCommercialTermsPath(): string {
    return "fuel_company_commercial_terms"
  }

  private newFuelCompanyCommercialTermPath(): string {
    return `${this.fuelCompanyCommercialTermsPath()}/new`
  }

  private showFuelCompanyCommercialTermPath({
    fuelCompanyCommercialTermId
  }: {
    readonly fuelCompanyCommercialTermId: number | string
  }): string {
    return `${this.fuelCompanyCommercialTermsPath()}/${fuelCompanyCommercialTermId}`
  }

  private editFuelCompanyCommercialTermPath({
    fuelCompanyCommercialTermId
  }: {
    readonly fuelCompanyCommercialTermId: number | string
  }): string {
    return `${this.fuelCompanyCommercialTermsPath()}/${fuelCompanyCommercialTermId}/edit`
  }

  private gasStationsPath(parameters?: GasStationsUrlParameters): string {
    const { fuelCompanyId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`
    ]).join("&")

    return `gas_stations${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private newGasStationPath(): string {
    return `${this.gasStationsPath()}/new`
  }

  private showGasStationPath({
    gasStationId
  }: {
    readonly gasStationId: number | string
  }): string {
    return `${this.gasStationsPath()}/${gasStationId}`
  }

  private editGasStationPath({
    gasStationId
  }: {
    readonly gasStationId: number | string
  }): string {
    return `${this.gasStationsPath()}/${gasStationId}/edit`
  }

  private claimedRefuellingOrdersPath(parameters?: RefuellingOrdersUrlParameters): string {
    const {
      carrierId,
      transportId,
      driverId,
      tripId,
      startDate,
      endDate,
      fuelCompanyId,
      gasStationId
    } = parameters ?? {}

    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`,
      transportId && `filter[transportId]=${transportId}`,
      driverId && `filter[driverId]=${driverId}`,
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`,
      tripId && `filter[tripId]=${tripId}`,
      startDate && `filter[startDate]=${startDate}`,
      endDate && `filter[endDate]=${endDate}`,
      gasStationId && `filter[gasStationId]=${gasStationId}`
    ]).join("&")

    return `claimed_refuelling_orders${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private showClaimedRefuellingOrderPath({
    refuellingOrderId
  }: {
    readonly refuellingOrderId: number | string
  }): string {
    return `${this.claimedRefuellingOrdersPath()}/${refuellingOrderId}`
  }

  private editClaimedRefuellingOrderPath({
    refuellingOrderId
  }: {
    readonly refuellingOrderId: number | string
  }): string {
    return `${this.claimedRefuellingOrdersPath()}/${refuellingOrderId}/edit`
  }

  private cancelledRefuellingOrdersPath(parameters?: RefuellingOrdersUrlParameters): string {
    const {
      carrierId,
      transportId,
      driverId,
      tripId,
      startDate,
      endDate,
      fuelCompanyId,
      gasStationId
    } = parameters ?? {}

    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`,
      transportId && `filter[transportId]=${transportId}`,
      driverId && `filter[driverId]=${driverId}`,
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`,
      tripId && `filter[tripId]=${tripId}`,
      startDate && `filter[startDate]=${startDate}`,
      endDate && `filter[endDate]=${endDate}`,
      gasStationId && `filter[gasStationId]=${gasStationId}`
    ]).join("&")

    return `cancelled_refuelling_orders${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private showCancelledRefuellingOrderPath({
    refuellingOrderId
  }: {
    readonly refuellingOrderId: number | string
  }): string {
    return `${this.cancelledRefuellingOrdersPath()}/${refuellingOrderId}`
  }

  private refuellingOrdersPath(parameters?: RefuellingOrdersUrlParameters): string {
    const {
      carrierId,
      transportId,
      driverId,
      tripId,
      status,
      startDate,
      endDate,
      fuelCompanyId,
      gasStationId
    } = parameters ?? {}

    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`,
      transportId && `filter[transportId]=${transportId}`,
      driverId && `filter[driverId]=${driverId}`,
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`,
      tripId && `filter[tripId]=${tripId}`,
      status && `filter[status]=${status}`,
      startDate && `filter[startDate]=${startDate}`,
      endDate && `filter[endDate]=${endDate}`,
      gasStationId && `filter[gasStationId]=${gasStationId}`
    ]).join("&")

    return `refuelling_orders${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private showRefuellingOrderPath({
    refuellingOrderId
  }: {
    readonly refuellingOrderId: number | string
  }): string {
    return `${this.refuellingOrdersPath()}/${refuellingOrderId}`
  }

  private editRefuellingOrderPath({
    refuellingOrderId
  }: {
    readonly refuellingOrderId: number | string
  }): string {
    return `${this.refuellingOrdersPath()}/${refuellingOrderId}/edit`
  }

  private fuelPricesPath(parameters?: FuelPricesUrlParameters): string {
    const { gasStationId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      gasStationId && `filter[gasStationId]=${gasStationId}`
    ]).join("&")

    return `fuel_prices${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private newFuelPricePath(): string {
    return `${this.fuelPricesPath()}/new`
  }

  private showFuelPricePath({
    fuelPriceId
  }: {
    readonly fuelPriceId: number | string
  }): string {
    return `${this.fuelPricesPath()}/${fuelPriceId}`
  }

  private editFuelPricePath({
    fuelPriceId
  }: {
    readonly fuelPriceId: number | string
  }): string {
    return `${this.fuelPricesPath()}/${fuelPriceId}/edit`
  }

  private driversPath(parameters?: DriversUrlParameters): string {
    const { carrierId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`
    ]).join("&")

    return `drivers${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private newDriverPath(): string {
    return `${this.driversPath()}/new`
  }

  private showDriverPath({
    driverId
  }: {
    readonly driverId: number | string
  }): string {
    return `${this.driversPath()}/${driverId}`
  }

  private editDriverPath({
    driverId
  }: {
    readonly driverId: number | string
  }): string {
    return `${this.driversPath()}/${driverId}/edit`
  }

  private transportsPath(parameters?: TransportsUrlParameters): string {
    const { carrierId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`
    ]).join("&")

    return `transports${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private newTransportPath(): string {
    return `${this.transportsPath()}/new`
  }

  private showTransportPath({
    transportId
  }: {
    readonly transportId: number | string
  }): string {
    return `${this.transportsPath()}/${transportId}`
  }

  private editTransportPath({
    transportId
  }: {
    readonly transportId: number | string
  }): string {
    return `${this.transportsPath()}/${transportId}/edit`
  }

  private tripsPath(parameters?: TripsUrlParameters): string {
    const {
      carrierId,
      transportId,
      driverId
    } = parameters ?? {}

    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`,
      transportId && `filter[transportId]=${transportId}`,
      driverId && `filter[driverId]=${driverId}`
    ]).join("&")

    return `trips${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private newTripPath(): string {
    return `${this.tripsPath()}/new`
  }

  private showTripPath({
    tripId
  }: {
    readonly tripId: number | string
  }): string {
    return `${this.tripsPath()}/${tripId}`
  }

  private editTripPath({
    tripId
  }: {
    readonly tripId: number | string
  }): string {
    return `${this.tripsPath()}/${tripId}/edit`
  }

  private currentCarrierBalancePath(): string {
    return `${this.currentCarrierPath()}/balance`
  }

  private carrierBalancePath({
    carrierId
  }: {
    readonly carrierId: number | string
  }): string {
    return `${this.carriersPath()}/${carrierId}/balance`
  }

  private carrierBalanceReplenishmentPath({
    carrierId
  }: {
    readonly carrierId: number | string
  }): string {
    return `${this.carriersPath()}/${carrierId}/balance/replenishment`
  }

  private currentFuelCompanyBalancePath(): string {
    return `${this.currentFuelCompanyPath()}/balance`
  }

  private fuelCompanyBalancePath({
    fuelCompanyId
  }: {
    readonly fuelCompanyId: number | string
  }): string {
    return `${this.fuelCompaniesPath()}/${fuelCompanyId}/balance`
  }

  private fuelCompanyBalanceChangeDocumentPath({
    fuelCompanyId
  }: {
    readonly fuelCompanyId: number | string
  }): string {
    return `${this.fuelCompaniesPath()}/${fuelCompanyId}/balance/write_off`
  }

  private usersPath(parameters?: UsersUrlParameters): string {
    const { carrierId, fuelCompanyId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`,
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`
    ]).join("&")

    return `users${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private editUserPath({
    userId
  }: {
    readonly userId: number | string
  }): string {
    return `${this.usersPath()}/${userId}/edit`
  }

  private showUserPath({
    userId
  }: {
    readonly userId: number | string
  }): string {
    return `${this.usersPath()}/${userId}`
  }

  private newUserPath(): string {
    return `${this.usersPath()}/new`
  }

  private carrierUsersPath(parameters?: UsersUrlParameters): string {
    const { carrierId, fuelCompanyId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`,
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`
    ]).join("&")

    return `carrier_users${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private editCarrierUserPath({
    userId
  }: {
    readonly userId: number | string
  }): string {
    return `${this.carrierUsersPath()}/${userId}/edit`
  }

  private showCarrierUserPath({
    userId
  }: {
    readonly userId: number | string
  }): string {
    return `${this.carrierUsersPath()}/${userId}`
  }

  private newCarrierUserPath(): string {
    return `${this.carrierUsersPath()}/new`
  }

  private fuelCompanyUsersPath(parameters?: UsersUrlParameters): string {
    const { carrierId, fuelCompanyId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      carrierId && `filter[carrierId]=${carrierId}`,
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`
    ]).join("&")

    return `fuel_company_users${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private editFuelCompanyUserPath({
    userId
  }: {
    readonly userId: number | string
  }): string {
    return `${this.fuelCompanyUsersPath()}/${userId}/edit`
  }

  private showFuelCompanyUserPath({
    userId
  }: {
    readonly userId: number | string
  }): string {
    return `${this.fuelCompanyUsersPath()}/${userId}`
  }

  private newFuelCompanyUserPath(): string {
    return `${this.fuelCompanyUsersPath()}/new`
  }

  private installationInstancesPath(parameters?: InstallationInstancesUrlParameters): string {
    const { fuelCompanyId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      fuelCompanyId && `filter[fuelCompanyId]=${fuelCompanyId}`
    ]).join("&")

    return `installation_instances${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private newInstallationInstancePath(): string {
    return `${this.installationInstancesPath()}/new`
  }

  private showInstallationInstancePath({
    installationInstanceId
  }: {
    readonly installationInstanceId: number | string
  }): string {
    return `${this.installationInstancesPath()}/${installationInstanceId}`
  }

  private editInstallationInstancePath({
    installationInstanceId
  }: {
    readonly installationInstanceId: number | string
  }): string {
    return `${this.installationInstancesPath()}/${installationInstanceId}/edit`
  }

  private tripChangingLogPath(parameters?: TripChangingLogUrlParameters): string {
    const { tripId, changedById } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      tripId && `filter[tripId]=${tripId}`,
      changedById && `filter[changedById]=${changedById}`
    ]).join("&")

    return `trip_changing_log${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private forbiddenPath(): string {
    return "forbidden"
  }

  private newRefuellingOrdersReportsPath(): string {
    return `${this.refuellingOrdersReportsPath()}/new`
  }

  private refuellingOrdersReportsPath(): string {
    return "refuelling_orders_reports"
  }

  private newCarrierReconciliationReportsPath(): string {
    return `${this.carrierReconciliationReportsPath()}/new`
  }

  private carrierReconciliationReportsPath(): string {
    return "carrier_reconciliation_reports"
  }

  private newFuelCompanyReconciliationReportsPath(): string {
    return `${this.fuelCompanyReconciliationReportsPath()}/new`
  }

  private fuelCompanyReconciliationReportsPath(): string {
    return "fuel_company_reconciliation_reports"
  }

  private gasStationBreaksPath(parameters?: GasStationBreaksUrlParameters): string {
    const { gasStationId } = parameters ?? {}
    const queryParameters = filterNotEmpty([
      gasStationId && `filter[gasStationId]=${gasStationId}`
    ]).join("&")

    return `gas_station_breaks${isBlank(queryParameters) ? "" : `?${queryParameters}`}`
  }

  private newGasStationBreakPath(): string {
    return `${this.gasStationBreaksPath()}/new`
  }

  private showGasStationBreakPath({
    gasStationBreakId
  }: {
    readonly gasStationBreakId: number | string
  }): string {
    return `${this.gasStationBreaksPath()}/${gasStationBreakId}`
  }

  private editGasStationBreakPath({
    gasStationBreakId
  }: {
    readonly gasStationBreakId: number | string
  }): string {
    return `${this.gasStationBreaksPath()}/${gasStationBreakId}/edit`
  }
}
