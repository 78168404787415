import { Expose, Transform, Type } from "class-transformer"
import { Decimal } from "decimal.js"
import transformDecimal from "../../network/transformers/transformDecimal"

export default class NetworkClaim {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Transform(transformDecimal)
  readonly expectedValue?: Decimal | null

  @Expose()
  @Transform(transformDecimal)
  readonly actualValue?: Decimal | null

  @Expose()
  @Transform(transformDecimal)
  readonly carrierValue?: Decimal | null

  @Expose()
  @Transform(transformDecimal)
  readonly fuelCompanyValue?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedExpectedValue?: string | null

  @Expose()
  @Type(() => String)
  readonly formattedActualValue?: string | null

  @Expose()
  @Type(() => String)
  readonly formattedCarrierValue?: string | null

  @Expose()
  @Type(() => String)
  readonly formattedFuelCompanyValue?: string | null
}
