import GetObjectsUseCase, {
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PricingTypesForCarrierCommercialTermsRepository
  from "../../repositories/PricingTypesForCarrierCommercialTermsRepository"
import { PricingTypeSelectOption } from "../../../../../core/domain/commercial-terms/PricingType"

export default class GetPricingTypesForCarrierCommercialTermsUseCase
  implements GetObjectsUseCase<PricingTypeSelectOption> {
  private readonly pricingTypesForCarrierCommercialTermsRepository: PricingTypesForCarrierCommercialTermsRepository

  constructor(parameters: {
    readonly pricingTypesForCarrierCommercialTermsRepository: PricingTypesForCarrierCommercialTermsRepository
  }) {
    this.pricingTypesForCarrierCommercialTermsRepository = parameters.pricingTypesForCarrierCommercialTermsRepository
  }

  async call(): Promise<GetObjectsResult<PricingTypeSelectOption>> {
    return await this.pricingTypesForCarrierCommercialTermsRepository.getPricingTypes()
  }
}
