import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import UsersRepository from "../../repositories/UsersRepository"
import User from "../../../../../core/domain/users/User"

export interface GetUserParameters {
  readonly objectId: string
}

export default class GetUserUseCase implements GetObjectUseCase<User> {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: GetUserParameters): Promise<GetObjectResult<User>> {
    return await this.usersRepository.getUser(parameters)
  }
}
