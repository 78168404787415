import CarrierUsersRepository from "../../repositories/CarrierUsersRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import UserError from "../../../../../core/domain/users/UserError"

export interface UpdateCarrierUserParameters {
  readonly objectId: string
  readonly object: User
}

export default class UpdateCarrierUserUseCase implements UpdateObjectUseCase<User, UserError> {
  private readonly carrierUsersRepository: CarrierUsersRepository

  constructor(parameters: {
    readonly carrierUsersRepository: CarrierUsersRepository
  }) {
    this.carrierUsersRepository = parameters.carrierUsersRepository
  }

  async call(parameters: UpdateCarrierUserParameters): Promise<UpdateObjectResult<User, UserError>> {
    return await this.carrierUsersRepository.updateUser(parameters)
  }
}
