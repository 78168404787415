import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import styles from "./TextInputComponent.module.scss"
import isPresent from "../isPresent"
import isBlank from "../isBlank"
import IMask, { FactoryArg, InputMask } from "imask"
import TextInputComponentStyle from "./TextInputComponentStyles"

export interface TextInputComponentRef {
  readonly focusOnInput: () => void
  readonly setupMask: (opts: FactoryArg) => InputMask<any>
}

export enum TextInputType {
  TEXT = "text",
  EMAIL = "email",
  PASSWORD = "password",
  NUMBER = "number"
}

const defaultPadding = 8

export function TextInputComponent({
  name,
  type = TextInputType.TEXT,
  value = "",
  isDisabled = false,
  hasErrors = false,
  isTextAllCaps = false,
  isActive = false,
  placeholder = "",
  className = "",
  componentStyle = {},
  onChange = () => undefined,
  onKeyDown = () => undefined,
  onClick = () => undefined
}: {
  readonly name?: string
  readonly value?: string | null
  readonly type?: TextInputType
  readonly isDisabled?: boolean
  readonly isTextAllCaps?: boolean
  readonly isActive?: boolean
  readonly placeholder?: string
  readonly hasErrors?: boolean
  readonly className?: string
  readonly componentStyle?: TextInputComponentStyle
  readonly onChange?: (value: string) => void
  readonly onKeyDown?: (key: React.KeyboardEvent<HTMLInputElement>) => void
  readonly onClick?: () => void
}, forwardedRef: React.ForwardedRef<TextInputComponentRef>) {
  function focusOnInput() {
    inputRef?.current?.focus()
  }

  function setupMask(opts: FactoryArg): InputMask<any> {
    return IMask(inputRef.current!, opts)
  }

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        focusOnInput,
        setupMask
      }
    })

  const [isFocused, setFocused] = useState(false)
  const [leftIconWidth, setLeftIconWidth] = useState(defaultPadding)
  const [rightIconWidth, setRightIconWidth] = useState(defaultPadding)

  const [inputText, setInputText] = useState(value)
  const [cursorPosition, setCursorPosition] = useState(0)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const leftIconDivRef = useRef<HTMLDivElement>(null)
  const rightIconDivRef = useRef<HTMLDivElement>(null)

  const currentlyActive = isFocused || isActive

  const {
    classes: {
      inputDefault: inputDefaultClassName = "",
      inputActive: inputActiveClassName = "",
      inputErrors: inputErrorsClassName = ""
    } = {},
    icons = {}
  } = componentStyle

  const leftIcon = (() => {
    if (isDisabled) {
      return icons.leftIconDisabled ?? icons.leftIconDefault
    }

    if (currentlyActive) {
      return icons.leftIconActive ?? icons.leftIconDefault
    }

    return icons.leftIconDefault
  })()

  const rightIcon = (() => {
    if (isDisabled) {
      return icons.rightIconDisabled ?? icons.rightIconDefault
    }

    if (currentlyActive) {
      return icons.rightIconActive ?? icons.rightIconDefault
    }

    return icons.rightIconDefault
  })()

  // TODO: Think how to do it without useEffect
  useEffect(() => {
    if (isPresent(leftIconDivRef.current)) {
      setLeftIconWidth(leftIconDivRef.current.clientWidth)
    }

    if (isPresent(rightIconDivRef.current)) {
      setRightIconWidth(rightIconDivRef.current.clientWidth)
    }
  }, [])

  useEffect(() => {
    if (isBlank(inputRef.current) || type === TextInputType.NUMBER || type === TextInputType.EMAIL) return

    inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
  }, [inputText])

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value, selectionStart } } = event
    setCursorPosition(selectionStart ?? 0)
    const formattedValue = isTextAllCaps ? value.toUpperCase() : value
    setInputText(formattedValue)
    onChange(formattedValue)
  }

  return (
    <div className={`${styles.root} ${className}`}>
      {leftIcon && (
        <div
          className={`${styles.icon} ${styles.left}`}
          ref={leftIconDivRef}
        >
          {leftIcon}
        </div>
      )}
      <input
        id={name}
        ref={inputRef}
        className={`${styles.input} ${inputDefaultClassName} ${hasErrors ? inputErrorsClassName : ""} ${currentlyActive ? inputActiveClassName : ""}`}
        type={type}
        value={value ?? ""}
        placeholder={placeholder}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onChange={handleOnChange}
        disabled={isDisabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          paddingLeft: leftIconWidth,
          paddingRight: rightIconWidth
        }}
      />
      {rightIcon && (
        <div
          className={`${styles.icon} ${styles.right}`}
          ref={rightIconDivRef}
        >
          {rightIcon}
        </div>
      )}
    </div>
  )
}

export default forwardRef(TextInputComponent)
