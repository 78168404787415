import FormField from "./FormField"
import FormPermissionsSet from "./FormPermissionsSet"
import FormFieldGroup from "./FormFieldGroup"

export default class Form<DomainObject, ErrorsObject> {
  private readonly groups: FormFieldGroup[]
  private readonly fields: FormField<DomainObject, ErrorsObject>[]
  private readonly permissionsSet: FormPermissionsSet

  constructor(parameters: {
    readonly groups: FormFieldGroup[]
    readonly fields: FormField<DomainObject, ErrorsObject>[]
    readonly permissionsSet: FormPermissionsSet
  }) {
    this.groups = parameters.groups
    this.fields = parameters.fields
    this.permissionsSet = parameters.permissionsSet
  }

  getFormFields() {
    return this.fields
  }

  getFieldsGroups() {
    return this.groups
  }

  getPermissionsSet() {
    return this.permissionsSet
  }
}
