import GetDriversUseCase from "../../domain/use-cases/drivers/GetDriversUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DriversDataDiModule from "./DriversDataDiModule"
import CreateDriverUseCase from "../../domain/use-cases/drivers/CreateDriverUseCase"
import GetDriverUseCase from "../../domain/use-cases/drivers/GetDriverUseCase"
import UpdateDriverUseCase from "../../domain/use-cases/drivers/UpdateDriverUseCase"
import GetCarriersForDriverUseCase from "../../domain/use-cases/carriers/GetCarriersForDriverUseCase"

export default interface DriversDomainDiModule {
  provideGetDriversUseCase(): GetDriversUseCase

  provideGetDriverUseCase(): GetDriverUseCase

  provideCreateDriverUseCase(): CreateDriverUseCase

  provideUpdateDriverUseCase(): UpdateDriverUseCase

  provideGetCarriersForDriverUseCase(): GetCarriersForDriverUseCase
}

export class DefaultDriversDomainDiModule extends DiModule implements DriversDomainDiModule {
  private readonly driversDataDiModule: DriversDataDiModule

  constructor(parameters: {
    readonly driversDataDiModule: DriversDataDiModule
  }) {
    super()
    this.driversDataDiModule = parameters.driversDataDiModule
  }

  provideGetDriversUseCase(): GetDriversUseCase {
    return this.single(
      GetDriversUseCase.name,
      () => new GetDriversUseCase({
        driversRepository: this.driversDataDiModule.provideDriversRepository()
      })
    )
  }

  provideGetDriverUseCase(): GetDriverUseCase {
    return this.single(
      GetDriverUseCase.name,
      () => new GetDriverUseCase({
        driversRepository: this.driversDataDiModule.provideDriversRepository()
      })
    )
  }

  provideCreateDriverUseCase(): CreateDriverUseCase {
    return this.single(
      CreateDriverUseCase.name,
      () => new CreateDriverUseCase({
        driversRepository: this.driversDataDiModule.provideDriversRepository()
      })
    )
  }

  provideUpdateDriverUseCase(): UpdateDriverUseCase {
    return this.single(
      UpdateDriverUseCase.name,
      () => new UpdateDriverUseCase({
        driversRepository: this.driversDataDiModule.provideDriversRepository()
      })
    )
  }

  provideGetCarriersForDriverUseCase(): GetCarriersForDriverUseCase {
    return this.single(
      GetCarriersForDriverUseCase.name,
      () => new GetCarriersForDriverUseCase({
        carriersForDriverRepository: this.driversDataDiModule.provideCarriersForDriverRepository()
      })
    )
  }
}
