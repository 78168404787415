import { Expose, Type } from "class-transformer"
import NetworkTrip from "../NetworkTrip"

export default class NetworkTripRequestBody {

  @Expose()
  @Type(() => NetworkTrip)
  readonly trip?: NetworkTrip | null

  constructor(parameters: {
    readonly trip?: NetworkTrip | null
  }) {
    this.trip = parameters.trip
  }
}
