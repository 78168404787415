import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import UsersI18 from "../../i18n/UsersI18"
import User from "../../../../core/domain/users/User"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class UsersTableProvider implements TableProvider<User> {
  private readonly usersI18: UsersI18

  constructor(parameters: {
    readonly usersI18: UsersI18
  }) {
    this.usersI18 = parameters.usersI18
  }

  getEntity(): string {
    return Entity.USERS
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<User> {
    const appUrlProvider = new AppUrlProvider()
    const usersTextProvider = this.usersI18.getTextProvider()

    return new Table<User>({
      title: usersTextProvider.listTitle(),
      getObjectId: (user: User) => user.id!.toString(),
      getRowUrl: (user: User) => appUrlProvider.buildShowUserUrl({
        userId: user.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewUserUrl(),
      columns: [
        new TableColumn<User>({
          name: "lastName",
          title: usersTextProvider.lastNameField(),
          createValue: (user: User) => new TextTableValue({
            value: user.profile?.lastName
          })
        }),
        new TableColumn<User>({
          name: "firstName",
          title: usersTextProvider.firstNameField(),
          createValue: (user: User) => new TextTableValue({
            value: user.profile?.firstName
          })
        }),
        new TableColumn<User>({
          name: "middleName",
          title: usersTextProvider.middleNameField(),
          createValue: (user: User) => new TextTableValue({
            value: user.profile?.middleName
          })
        }),
        new TableColumn<User>({
          name: "emailAddress",
          title: usersTextProvider.emailField(),
          createValue: (user: User) => new TextTableValue({
            value: user.emailAddress
          })
        }),
        new TableColumn<User>({
          name: "blocked",
          title: usersTextProvider.statusField(),
          isSortingAvailable: true,
          reversedSortOrder: true,
          createValue: (user: User) => {
            const { blocked = false } = user

            return new TextTableValue({
              value: (blocked ?? false) ?
                usersTextProvider.statusValueBlocked() :
                usersTextProvider.statusValueActive(),
              isActive: !user.blocked
            })
          }
        })
      ]
    })
  }
}
