import React from "react"
import styles from "./ForbiddenComponent.module.scss"
import { useForbiddenTextProvider } from "../../contexts/ForbiddenTextProviderContext"
import { useForbiddenThemeProvider } from "../../contexts/ForbiddenThemeProviderContext"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import LinkButtonComponent from "../../../../../lib/button/LinkButtonComponent"

export default function ForbiddenComponent() {
  const coreTheme = useCoreThemeProvider()
  const forbiddenTheme = useForbiddenThemeProvider()
  const forbiddenTextProvider = useForbiddenTextProvider()
  const urlProvider = new CoreUrlProvider()

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <img
          src={forbiddenTheme.imForbiddenLogoPath()}
          alt="imForbiddenLogoPath"
        />
        <h1 className={`${styles.title} ${coreTheme.headline3PrimaryClassName()}`}>
          {forbiddenTextProvider.title()}
        </h1>
        <div className={`${styles.text} ${coreTheme.body5SecondaryClassName()}`}>
          {forbiddenTextProvider.text()}
        </div>
        <LinkButtonComponent
          componentStyles={{
            ...coreTheme.buttonFilled1PrimaryStyle(),
            classes: {
              ...coreTheme.buttonFilled1PrimaryStyle().classes,
              default: `${styles.button} ${coreTheme.buttonFilled1PrimaryStyle().classes?.default ?? ""}`
            }
          }}
          url={urlProvider.buildRootUrl()}
          title={forbiddenTextProvider.redirectToMainLink()}
        />
      </div>
    </div>
  )
}
