import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import TripsFilter from "../../../../../core/domain/trips/TripsFilter"
import Trip from "../../../../../core/domain/trips/Trip"
import TripsForTripChangingLogRepository from "../../repositories/TripsForTripChangingLogRepository"

export interface GetTripsForTripChangingLogParameters extends GetObjectsParameters<TripsFilter> {
}

export default class GetTripsForTripChangingLogUseCase implements GetObjectsUseCase<Trip> {
  private readonly tripsForTripChangingLogItemsRepository: TripsForTripChangingLogRepository

  constructor(parameters: {
    readonly tripsForTripChangingLogItemsRepository: TripsForTripChangingLogRepository
  }) {
    this.tripsForTripChangingLogItemsRepository = parameters.tripsForTripChangingLogItemsRepository
  }

  async call(parameters: GetTripsForTripChangingLogParameters): Promise<GetObjectsResult<Trip>> {
    return await this.tripsForTripChangingLogItemsRepository.getTrips(parameters)
  }
}
