import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import { TripStatusSelectOption } from "../../../../../core/domain/trips/TripStatus"
import TripStatusesForTripsRepository from "../../repositories/TripStatusesForTripsRepository"

export interface GetTripStatusesForTripsParameters extends GetObjectsParameters {
}

export default class GetTripStatusesForTripsUseCase implements GetObjectsUseCase<TripStatusSelectOption> {
  private readonly tripStatusesForTripsRepository: TripStatusesForTripsRepository

  constructor(parameters: {
    readonly tripStatusesForTripsRepository: TripStatusesForTripsRepository
  }) {
    this.tripStatusesForTripsRepository = parameters.tripStatusesForTripsRepository
  }

  async call(parameters: GetTripStatusesForTripsParameters): Promise<GetObjectsResult<TripStatusSelectOption>> {
    return await this.tripStatusesForTripsRepository.getTripStatuses(parameters)
  }
}
