import UsersRepository from "../../repositories/UsersRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import UsersFilter from "../../../../../core/domain/users/UsersFilter"

export interface GetUsersParameters extends GetObjectsParameters<UsersFilter> {
}

export default class GetUsersUseCase implements GetObjectsUseCase<User> {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: GetUsersParameters): Promise<GetObjectsResult<User>> {
    return await this.usersRepository.getUsers(parameters)
  }
}
