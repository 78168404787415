import { Expose, Type } from "class-transformer"
import NetworkDriversRequestFilter from "./NetworkDriversRequestFilter"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkDriversRequestQuery {
  @Expose()
  @Type(() => NetworkDriversRequestFilter)
  readonly filter?: NetworkDriversRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  @Type(() => String)
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkDriversRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
