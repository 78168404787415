import CarriersRepository from "../../domain/repositories/CarriersRepository"
import { GetCarriersParameters } from "../../domain/use-cases/carriers/GetCarriersUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import Carrier from "../../../../core/domain/carriers/Carrier"
import NetworkCarriersRequestFilter from "../../../../core/data/entities/carriers/request-queries/NetworkCarriersRequestFilter"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import CarriersMapper from "../../../../core/data/mappers/CarriersMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateCarrierParameters } from "../../domain/use-cases/carriers/CreateCarrierUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import CarrierError from "../../../../core/domain/carriers/CarrierError"
import { GetCarrierParameters } from "../../domain/use-cases/carriers/GetCarrierUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateCarrierParameters } from "../../domain/use-cases/carriers/UpdateCarrierUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import CarriersNetworkSource, {
  GetCarriersNetworkResult
} from "../../../../core/data/sources/network/CarriersNetworkSource"

export default class DefaultCarriersRepository implements CarriersRepository {
  private readonly carriersNetworkSource: CarriersNetworkSource

  constructor(parameters: {
    readonly carriersNetworkSource: CarriersNetworkSource
  }) {
    this.carriersNetworkSource = parameters.carriersNetworkSource
  }

  async getCarriers({
    query,
    pagination,
    sort
  }: GetCarriersParameters): Promise<GetObjectsResult<Carrier>> {
    const result: GetCarriersNetworkResult = await this.carriersNetworkSource.getCarriers({
      filter: new NetworkCarriersRequestFilter({
        query
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success": {
        const carriersMapper = new CarriersMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.carriers!.map((carrier) => {
              return carriersMapper.mapNetworkToDomain({ carrier })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }

  async getCarrier({
    objectId
  }: GetCarrierParameters): Promise<GetObjectResult<Carrier>> {
    const mapper = new CarriersMapper()
    const result = await this.carriersNetworkSource.getCarrier({
      carrierId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            carrier: result.data.carrier!
          })
        }
      default:
        return result
    }
  }

  async createCarrier({
    object: carrier
  }: CreateCarrierParameters): Promise<CreateObjectResult<Carrier, CarrierError>> {
    const mapper = new CarriersMapper()
    const result = await this.carriersNetworkSource.createCarrier({
      carrier: mapper.mapDomainToNetwork({ carrier })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            carrier: result.data.carrier!
          })
        }
      default:
        return result
    }
  }

  async updateCarrier({
    objectId: carrierId,
    object: carrier
  }: UpdateCarrierParameters): Promise<UpdateObjectResult<Carrier, CarrierError>> {
    const mapper = new CarriersMapper()
    const result = await this.carriersNetworkSource.updateCarrier({
      carrierId: parseInt(carrierId),
      carrier: mapper.mapDomainToNetwork({
        carrier
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            carrier: result.data.carrier!
          })
        }
      default:
        return result
    }
  }
}
