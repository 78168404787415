import FuelCompanyCommercialTermsRepository from "../../repositories/FuelCompanyCommercialTermsRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import FuelCompanyCommercialTerm
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"
import FuelCompanyCommercialTermError
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTermError"

export interface UpdateFuelCompanyCommercialTermParameters {
  readonly objectId: string
  readonly object: FuelCompanyCommercialTerm
}

export default class UpdateCarrierCommercialTermUseCase
  implements UpdateObjectUseCase<FuelCompanyCommercialTerm, FuelCompanyCommercialTermError> {
  private readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository

  constructor(parameters: {
    readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository
  }) {
    this.fuelCompanyCommercialTermsRepository = parameters.fuelCompanyCommercialTermsRepository
  }

  async call(
    parameters: UpdateFuelCompanyCommercialTermParameters
  ): Promise<UpdateObjectResult<FuelCompanyCommercialTerm, FuelCompanyCommercialTermError>> {
    return await this.fuelCompanyCommercialTermsRepository.updateFuelCompanyCommercialTerm(parameters)
  }
}
