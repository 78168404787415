import React from "react"
import styles from "./LabelComponent.module.scss"

export default function LabelComponent({
  htmlFor,
  text,
  className = ""
}: {
  readonly htmlFor: string
  readonly text?: string
  readonly className?: string
}) {
  return (
    <label
      htmlFor={htmlFor}
      className={`${styles.root} ${className}`}
    >
      { text }
    </label>
  )
}
