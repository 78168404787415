import NetworkTripLimitType from "../entities/trip-limits-types/NetworkTripLimitType"
import TripLimitType from "../../domain/trip-limit-type/TripLimitType"

export default class TripLimitTypesMapper {
  mapNetworkToDomain({
    tripLimitType
  }: {
    readonly tripLimitType: NetworkTripLimitType
  }): TripLimitType {
    return {
      id: tripLimitType.id,
      code: tripLimitType.code,
      name: tripLimitType.name,
      needsFuelTypeSelect: tripLimitType.needsFuelTypeSelect
    }
  }

  mapDomainToNetwork({
    tripLimitType
  }: {
    readonly tripLimitType: TripLimitType
  }): NetworkTripLimitType {
    return {
      id: tripLimitType.id,
      code: tripLimitType.code,
      name: tripLimitType.name,
      needsFuelTypeSelect: tripLimitType.needsFuelTypeSelect
    }
  }
}
