import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStationsForFuelCompanyCommercialTermsRepository
  from "../../repositories/GasStationsForFuelCompanyCommercialTermsRepository"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationsFilter from "../../../../../core/domain/gas-stations/GasStationsFilter"

export interface GetGasStationsForFuelCompanyCommercialTermsParameters
  extends GetObjectsParameters<GasStationsFilter> {
}

export default class GetGasStationsForFuelCompanyCommercialTermsUseCase implements GetObjectsUseCase<GasStation> {
  private readonly gasStationsForFuelCompanyCommercialTermsRepository:
    GasStationsForFuelCompanyCommercialTermsRepository

  constructor(parameters: {
    readonly gasStationsForFuelCompanyCommercialTermsRepository: GasStationsForFuelCompanyCommercialTermsRepository
  }) {
    this.gasStationsForFuelCompanyCommercialTermsRepository =
      parameters.gasStationsForFuelCompanyCommercialTermsRepository
  }

  async call(parameters: GetGasStationsForFuelCompanyCommercialTermsParameters): Promise<GetObjectsResult<GasStation>> {
    return await this.gasStationsForFuelCompanyCommercialTermsRepository.getGasStations(parameters)
  }
}
