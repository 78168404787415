import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelCompanyReconciliationReportsRequestFilter
  from "../../entities/fuel-company-reconciliation-reports/request-queries/NetworkFuelCompanyReconciliationReportsRequestFilter"
import NetworkFuelCompanyReconciliationReportsRequestQuery
  from "../../entities/fuel-company-reconciliation-reports/request-queries/NetworkFuelCompanyReconciliationReportsRequestQuery"
import NetworkFuelCompanyReconciliationReportsResponseBody
  from "../../entities/fuel-company-reconciliation-reports/response-bodies/NetworkFuelCompanyReconciliationReportsResponseBody"
import NetworkFuelCompanyReconciliationReportResponseBody
  from "../../entities/fuel-company-reconciliation-reports/response-bodies/NetworkFuelCompanyReconciliationReportResponseBody"
import NetworkFuelCompanyReconciliationReportErrorResponseBody
  from "../../entities/fuel-company-reconciliation-reports/response-bodies/errors/NetworkFuelCompanyReconciliationReportErrorResponseBody"
import NetworkFuelCompanyReconciliationReportRequestBody
  from "../../entities/fuel-company-reconciliation-reports/request-bodies/NetworkFuelCompanyReconciliationReportRequestBody"
import NetworkFuelCompanyReconciliationReport
  from "../../entities/fuel-company-reconciliation-reports/NetworkFuelCompanyReconciliationReport"

const basePath = "/api/admin/fuel_company_reconciliation_reports"

export default class FuelCompanyReconciliationReportsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getFuelCompanyReconciliationReports({
    pagination,
    sort,
    filter,
    needPagination
  }: {
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
    readonly filter?: NetworkFuelCompanyReconciliationReportsRequestFilter | null
    readonly needPagination?: boolean | null
  }): Promise<GetFuelCompanyReconciliationReportsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkFuelCompanyReconciliationReportsRequestQuery({
        pagination,
        sort,
        filter,
        needPagination
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyReconciliationReportsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createFuelCompanyReconciliationReport({
    report
  }: {
    readonly report?: NetworkFuelCompanyReconciliationReport | null
  }): Promise<CreateFuelCompanyReconciliationReportNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkFuelCompanyReconciliationReportRequestBody({ report }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyReconciliationReportResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelCompanyReconciliationReportErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetFuelCompanyReconciliationReportsNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyReconciliationReportsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateFuelCompanyReconciliationReportNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyReconciliationReportResponseBody> |
  ErrorExecutionResult<NetworkFuelCompanyReconciliationReportErrorResponseBody> |
  FailureExecutionResult
