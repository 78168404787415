import { Decimal } from "decimal.js"
import { Expose, Transform, Type } from "class-transformer"
import transformDecimal from "../../network/transformers/transformDecimal"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"
import NetworkCarrierBalanceTransaction from "../carrier-balance-transactions/NetworkCarrierBalanceTransaction"

export default class NetworkCarrierBalanceChangeDocument {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly date?: Date | null

  @Expose()
  @Transform(transformDecimal)
  readonly value?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedValue?: string | null

  @Expose()
  @Type(() => String)
  readonly comment?: string | null

  @Expose()
  @Type(() => String)
  readonly reason?: string | null

  @Expose()
  @Type(() => NetworkCarrierBalanceTransaction)
  readonly carrierBalanceTransaction?: NetworkCarrierBalanceTransaction

}
