import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkCarrierBalanceChangeDocumentsResponseBody
  from "../../entities/carrier-balance-change-documents/response-bodies/NetworkCarrierBalanceChangeDocumentsResponseBody"
import NetworkCarrierBalanceChangeDocumentsRequestQuery
  from "../../entities/carrier-balance-change-documents/request-queries/NetworkCarrierBalanceChangeDocumentsRequestQuery"

const basePath = "/api/admin/current_carrier_balance_replenishments"

export default class CurrentCarrierBalanceChangeDocumentsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getDocuments({
    pagination,
    sort
  }: {
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetCurrentCarrierBalanceChangeDocumentsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkCarrierBalanceChangeDocumentsRequestQuery({
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierBalanceChangeDocumentsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCurrentCarrierBalanceChangeDocumentsNetworkResult =
  SuccessExecutionResult<NetworkCarrierBalanceChangeDocumentsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

