import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import TransportFuelErrorsObjectAttributes
  from "../../../../../domain/transport-fuel/TransportFuelErrorsObjectAttributes"

export default class NetworkTransportFuelErrorsObjectAttributes implements TransportFuelErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly tankVolume?: NetworkAttributeError[] | null
}
