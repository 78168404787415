import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import { RefuellingOrderStatusSelectOption } from "../../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import RefuellingOrderStatusesForRefuellingOrdersRepository
  from "../../repositories/RefuellingOrderStatusesForRefuellingOrdersRepository"

export interface GetRefuellingOrderStatusesForRefuellingOrdersParameters extends GetObjectsParameters {
}

export default class GetRefuellingOrderStatusesForRefuellingOrdersUseCase
  implements GetObjectsUseCase<RefuellingOrderStatusSelectOption> {
  private readonly refuellingOrderStatusesForRefuellingOrdersRepository:
    RefuellingOrderStatusesForRefuellingOrdersRepository

  constructor(parameters: {
    readonly refuellingOrderStatusesForRefuellingOrdersRepository: RefuellingOrderStatusesForRefuellingOrdersRepository
  }) {
    this.refuellingOrderStatusesForRefuellingOrdersRepository =
      parameters.refuellingOrderStatusesForRefuellingOrdersRepository
  }

  async call(
    parameters: GetRefuellingOrderStatusesForRefuellingOrdersParameters
  ): Promise<GetObjectsResult<RefuellingOrderStatusSelectOption>> {
    return await this.refuellingOrderStatusesForRefuellingOrdersRepository.getRefuellingOrderStatuses(parameters)
  }
}
