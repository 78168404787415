import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkGasStationBreak from "../NetworkGasStationBreak"

export default class NetworkGasStationBreaksResponseBody {

  @Expose()
  @Type(() => NetworkGasStationBreak)
  readonly gasStationBreaks?: NetworkGasStationBreak[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
