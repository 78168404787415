import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import GasStationBreaksRepository from "../../repositories/GasStationBreaksRepository"
import GasStationBreak from "../../../../../core/domain/gas-station-breaks/GasStationBreak"

export interface GetGasStationBreakParameters {
  readonly objectId: string
}

export default class GetGasStationBreakUseCase implements GetObjectUseCase<GasStationBreak> {
  private readonly gasStationBreaksRepository: GasStationBreaksRepository

  constructor(parameters: {
    readonly gasStationBreaksRepository: GasStationBreaksRepository
  }) {
    this.gasStationBreaksRepository = parameters.gasStationBreaksRepository
  }

  async call(parameters: GetGasStationBreakParameters): Promise<GetObjectResult<GasStationBreak>> {
    return await this.gasStationBreaksRepository.getGasStationBreak(parameters)
  }
}
