import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import DriversFilter from "../../../../../core/domain/drivers/DriversFilter"
import DriversForRefuellingOrdersRepository from "../../repositories/DriversForRefuellingOrdersRepository"

export interface GetDriversForRefuellingOrdersParameters extends GetObjectsParameters<DriversFilter> {
}

export default class GetDriversForRefuellingOrdersUseCase implements GetObjectsUseCase<User> {
  private readonly driversForRefuellingOrdersRepository: DriversForRefuellingOrdersRepository

  constructor(parameters: {
    readonly driversForRefuellingOrdersRepository: DriversForRefuellingOrdersRepository
  }) {
    this.driversForRefuellingOrdersRepository = parameters.driversForRefuellingOrdersRepository
  }

  async call(parameters: GetDriversForRefuellingOrdersParameters): Promise<GetObjectsResult<User>> {
    return await this.driversForRefuellingOrdersRepository.getDrivers(parameters)
  }
}
