import { Expose, Type } from "class-transformer"

export default class NetworkInstallationInstancesRequestFilter {
  @Expose()
  @Type(() => String)
  readonly query?: string | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly gasStationIds?: number[] | null

  constructor(parameters: {
    readonly query?: string | null
    readonly fuelCompanyIds?: number[] | null
    readonly gasStationIds?: number[] | null
  }) {
    this.query = parameters.query
    this.fuelCompanyIds = parameters.fuelCompanyIds
    this.gasStationIds = parameters.gasStationIds
  }
}
