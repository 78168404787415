import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import TripsTableProvider from "../../presentation/table-providers/TripsTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import TripFormProvider from "../../presentation/form-providers/TripFormProvider"
import TripsInfoProvider from "../../presentation/info-providers/TripsInfoProvider"
import TripsI18nDiModule from "./TripsI18nDiModule"
import TripsDomainDiModule from "./TripsDomainDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"

const moduleKey = "trips"

export default interface TripsPresentationDiModule {
  provideTripsListPage(): React.ReactNode

  provideTripsInfoPage(): React.ReactNode

  provideTripsFormPage(): React.ReactNode
}

export class DefaultTripsPresentationDiModule
  extends DiModule
  implements TripsPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly tripsI18nDiModule: TripsI18nDiModule
  private readonly tripsDomainDiModule: TripsDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly tripsI18nDiModule: TripsI18nDiModule
    readonly tripsDomainDiModule: TripsDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.tripsI18nDiModule = parameters.tripsI18nDiModule
    this.tripsDomainDiModule = parameters.tripsDomainDiModule
  }

  provideTripsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.tripsDomainDiModule.provideGetTripsUseCase(),
      tableProvider: this.provideTransportsTableProvider()
    })
  }

  private provideTransportsTableProvider(): TripsTableProvider {
    return this.single(
      TripsTableProvider.name,
      () => new TripsTableProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        appI18: this.appI18nDiModule.provideAppI18n(),
        tripsI18n: this.tripsI18nDiModule.provideTripsI18n(),
        getCarriersUseCase: this.tripsDomainDiModule.provideGetCarriersForTripsUseCase(),
        getDriversUseCase: this.tripsDomainDiModule.provideGetDriversForTripsUseCase(),
        getTransportsUseCase: this.tripsDomainDiModule.provideGetTransportsForTripsUseCase(),
        getTripStatusesUseCase: this.tripsDomainDiModule.provideGetTripStatusesForTripsUseCase()
      })
    )
  }

  provideTripsFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.tripsDomainDiModule.provideGetTripUseCase(),
      createObjectUseCase: this.tripsDomainDiModule.provideCreateTripUseCase(),
      updateObjectUseCase: this.tripsDomainDiModule.provideUpdateTripUseCase(),
      formProvider: this.provideTransportFormProvider()
    })
  }

  private provideTransportFormProvider(): TripFormProvider {
    return this.single(
      TripFormProvider.name,
      () => new TripFormProvider({
        timeZone: this.timeZone,
        appI18: this.appI18nDiModule.provideAppI18n(),
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        tripsI18: this.tripsI18nDiModule.provideTripsI18n(),
        getCarriersUseCase: this.tripsDomainDiModule.provideGetCarriersForTripsUseCase(),
        getDriversUseCase: this.tripsDomainDiModule.provideGetDriversForTripsUseCase(),
        getTransportsUseCase: this.tripsDomainDiModule.provideGetTransportsForTripsUseCase(),
        getTripLimitTypesUseCase: this.tripsDomainDiModule.provideGetTripLimitTypesForTripLimitsUseCase(),
        getTripStatusesUseCase: this.tripsDomainDiModule.provideGetTripStatusesForTripsUseCase(),
        getFuelsUseCase: this.tripsDomainDiModule.provideGetFuelsForTripLimitsUseCase()
      })
    )
  }

  provideTripsInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.tripsDomainDiModule.provideGetTripUseCase(),
      infoProvider: this.provideTripsInfoProvider()
    })
  }

  private provideTripsInfoProvider(): TripsInfoProvider {
    return this.single(
      TripsInfoProvider.name,
      () => new TripsInfoProvider({
        timeZone: this.timeZone,
        appI18: this.appI18nDiModule.provideAppI18n(),
        tripsI18: this.tripsI18nDiModule.provideTripsI18n()
      })
    )
  }
}
