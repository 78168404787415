import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import {
  InstallationInstanceTypeSelectOption
} from "../../../../../core/domain/installation-instance/InstallationInstanceType"
import InstallationInstanceTypesForInstallationInstancesRepository
  from "../../repositories/InstallationInstanceTypesForInstallationInstancesRepository"

export interface GetInstallationInstanceTypesForInstallationInstancesParameters extends GetObjectsParameters {
}

export default class GetInstallationInstanceTypesForInstallationInstancesUseCase implements
  GetObjectsUseCase<InstallationInstanceTypeSelectOption> {

  private readonly installationInstanceTypesForInstallationInstancesRepository:
    InstallationInstanceTypesForInstallationInstancesRepository

  constructor(parameters: {
    readonly installationInstanceTypesForInstallationInstancesRepository:
      InstallationInstanceTypesForInstallationInstancesRepository
  }) {
    this.installationInstanceTypesForInstallationInstancesRepository =
      parameters.installationInstanceTypesForInstallationInstancesRepository
  }

  async call(
    parameters: GetInstallationInstanceTypesForInstallationInstancesParameters
  ): Promise<GetObjectsResult<InstallationInstanceTypeSelectOption>> {
    return await this.installationInstanceTypesForInstallationInstancesRepository
      .getInstallationInstanceTypes(parameters)
  }
}
