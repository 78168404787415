import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import CarrierBalancesI18n from "../../i18n/CarrierBalancesI18n"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import DecimalFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import CarrierBalanceChangeDocument
  from "../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import CarrierBalanceChangeDocumentError
  from "../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentError"
import CarrierBalanceChangeDocumentErrorsObject
  from "../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentErrorsObject"

const mainGroupName = "main"

export default class CarrierBalanceChangeDocumentFormProvider
  implements FormProvider<
    CarrierBalanceChangeDocument,
    CarrierBalanceChangeDocumentError,
    CarrierBalanceChangeDocumentErrorsObject
  > {
  private readonly carrierBalancesI18n: CarrierBalancesI18n

  constructor(parameters: {
    readonly carrierBalancesI18n: CarrierBalancesI18n
  }) {
    this.carrierBalancesI18n = parameters.carrierBalancesI18n
  }

  getEntity(): string {
    return Entity.CARRIER_BALANCE_REPLENISHMENTS
  }

  getNewObjectTitle(): string {
    return this.carrierBalancesI18n.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle(): string {
    return ""
  }

  getExistedObjectTitle(): string {
    return ""
  }

  async buildObject(): Promise<CarrierBalanceChangeDocument> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: CarrierBalanceChangeDocumentError
  }): CarrierBalanceChangeDocumentErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>[] {
    const carrierBalancesTextProvider = this.carrierBalancesI18n.getTextProvider()

    return [
      new DateFormField<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>({
        title: carrierBalancesTextProvider.dateField(),
        groupName: mainGroupName,
        required: true,
        getId: () => "date",
        getValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => carrierBalanceChangeDocument.date,
        setValue: (
          carrierBalanceChangeDocument: CarrierBalanceChangeDocument,
          date: Date | null | undefined
        ): CarrierBalanceChangeDocument => ({
          ...carrierBalanceChangeDocument, date
        }),
        getErrors: (errorsObject?: CarrierBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.date
      }),
      new DecimalFormField<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>({
        title: carrierBalancesTextProvider.valueField(),
        groupName: mainGroupName,
        required: true,
        getId: () => "value",
        getValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => carrierBalanceChangeDocument.value,
        setValue: (
          carrierBalanceChangeDocument: CarrierBalanceChangeDocument,
          value: Decimal | null | undefined
        ): CarrierBalanceChangeDocument => ({
          ...carrierBalanceChangeDocument, value
        }),
        getErrors: (errorsObject?: CarrierBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.value
      }),
      new StringFormField<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>({
        title: carrierBalancesTextProvider.reasonField(),
        groupName: mainGroupName,
        getId: () => "reason",
        getValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => carrierBalanceChangeDocument.reason,
        setValue: (
          carrierBalanceChangeDocument: CarrierBalanceChangeDocument,
          reason: string | null | undefined
        ): CarrierBalanceChangeDocument => ({
          ...carrierBalanceChangeDocument, reason
        }),
        getErrors: (errorsObject?: CarrierBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.reason
      }),
      new StringFormField<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>({
        title: carrierBalancesTextProvider.commentField(),
        groupName: mainGroupName,
        getId: () => "comment",
        getValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => carrierBalanceChangeDocument.comment,
        setValue: (
          carrierBalanceChangeDocument: CarrierBalanceChangeDocument,
          comment: string | null | undefined
        ): CarrierBalanceChangeDocument => ({
          ...carrierBalanceChangeDocument, comment
        }),
        getErrors: (errorsObject?: CarrierBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.comment
      })
    ]
  }
}
