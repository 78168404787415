import CarrierUsersRepository from "../../domain/repositories/CarrierUsersRepository"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import User from "../../../../core/domain/users/User"
import { GetCarrierUsersParameters } from "../../domain/use-cases/users/GetCarrierUsersUseCase"
import UsersMapper from "../../../../core/data/mappers/UsersMapper"
import { GetCarrierUserParameters } from "../../domain/use-cases/users/GetCarrierUserUseCase"
import { CreateCarrierUserParameters } from "../../domain/use-cases/users/CreateCarrierUserUseCase"
import isPresent from "../../../../../admin/lib/isPresent"
import NetworkUsersRequestFilter from "../../../../core/data/entities/users/request-queries/NetworkUsersRequestFilter"
import { UpdateCarrierUserParameters } from "../../domain/use-cases/users/UpdateCarrierUserUseCase"
import UserError from "../../../../core/domain/users/UserError"
import CarrierUsersNetworkSource from "../../../../core/data/sources/network/CarrierUsersNetworkSource"

export default class DefaultCarrierUsersRepository implements CarrierUsersRepository {
  private readonly carrierUsersNetworkSource: CarrierUsersNetworkSource

  constructor(parameters: {
    readonly carrierUsersNetworkSource: CarrierUsersNetworkSource
  }) {
    this.carrierUsersNetworkSource = parameters.carrierUsersNetworkSource
  }

  async getUsers({
    filter,
    query,
    pagination,
    sort
  }: GetCarrierUsersParameters): Promise<GetObjectsResult<User>> {
    const carrierId = filter?.carrierId
    const result = await this.carrierUsersNetworkSource.getUsers({
      filter: new NetworkUsersRequestFilter({
        query,
        carrierIds: isPresent(carrierId) ? [carrierId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.users!.map((user) => {
              return new UsersMapper().mapNetworkToDomain({ user })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getUser({
    objectId
  }: GetCarrierUserParameters): Promise<GetObjectResult<User>> {
    const result = await this.carrierUsersNetworkSource.getUser({
      userId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }

  async createUser({
    object: user
  }: CreateCarrierUserParameters): Promise<CreateObjectResult<User, UserError>> {
    const mapper = new UsersMapper()
    const result = await this.carrierUsersNetworkSource.createUser({
      user: mapper.mapDomainToNetwork({ user: user })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }

  async updateUser({
    objectId: userId,
    object: user
  }: UpdateCarrierUserParameters): Promise<UpdateObjectResult<User, UserError>> {
    const mapper = new UsersMapper()
    const result = await this.carrierUsersNetworkSource.updateUser({
      userId: parseInt(userId),
      user: mapper.mapDomainToNetwork({ user })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }
}
