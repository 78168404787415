import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import RefuellingOrdersReportsI18n from "../../i18n/RefuellingOrdersReportsI18n"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import AppI18 from "../../../../core/i18n/AppI18"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import RefuellingOrdersReport from "../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReport"
import ArrayTableValue, {
  ArrayTableValueItem
} from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/ArrayTableValue"

export interface RefuellingOrdersTableProviderParameters {
  readonly appI18n: AppI18
  readonly refuellingOrdersReportsI18n: RefuellingOrdersReportsI18n
  readonly timeZone: string
}

export default class RefuellingOrdersReportsTableProvider
  implements TableProvider<RefuellingOrdersReport> {
  protected readonly refuellingOrdersReportsI18n: RefuellingOrdersReportsI18n
  private readonly appI18n: AppI18
  private readonly timeZone: string

  constructor(parameters: RefuellingOrdersTableProviderParameters) {
    this.appI18n = parameters.appI18n
    this.refuellingOrdersReportsI18n = parameters.refuellingOrdersReportsI18n
    this.timeZone = parameters.timeZone
  }

  getEntity(): string {
    return Entity.REFUELLING_ORDERS_REPORTS
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getTable(): Table<RefuellingOrdersReport> {
    const appUrlProvider = new AppUrlProvider()
    const appTextProvider = this.appI18n.getTextProvider()
    const refuellingOrdersReportsTextProvider = this.refuellingOrdersReportsI18n.getTextProvider()

    return new Table<RefuellingOrdersReport>({
      title: refuellingOrdersReportsTextProvider.listTitle(),
      description: refuellingOrdersReportsTextProvider.listDescription(),
      newObjectPageUrl: appUrlProvider.buildNewRefuellingOrdersReportsUrl(),
      getObjectId: (report: RefuellingOrdersReport) => report.id!.toString(),
      getRowUrl: () => "",
      needRefreshObject: (report: RefuellingOrdersReport) => report.needRefresh ?? false,
      columns: [
        new TableColumn<RefuellingOrdersReport>({
          name: "createdAt",
          title: refuellingOrdersReportsTextProvider.createdDateField(),
          createValue: (report: RefuellingOrdersReport) => new DateTableValue({
            value: report.createdAt,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "fromDate",
          title: refuellingOrdersReportsTextProvider.fromDateField(),
          createValue: (report: RefuellingOrdersReport) => new DateTableValue({
            value: report.fromDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "toDate",
          title: refuellingOrdersReportsTextProvider.toDateField(),
          createValue: (report: RefuellingOrdersReport) => new DateTableValue({
            value: report.toDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "fuelCompanies",
          entity: Entity.FUEL_COMPANIES,
          title: refuellingOrdersReportsTextProvider.fuelCompaniesField(),
          createValue: (report: RefuellingOrdersReport) => new ArrayTableValue({
            values: report.fuelCompanies?.map((fuelCompany): ArrayTableValueItem => {
              return {
                key: `${fuelCompany.id!}`,
                text: fuelCompany.name,
                url: appUrlProvider.buildShowFuelCompanyUrl({
                  fuelCompanyId: fuelCompany.id!
                })
              }
            })
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "carriers",
          entity: Entity.CARRIERS,
          title: refuellingOrdersReportsTextProvider.carriersField(),
          createValue: (report: RefuellingOrdersReport) => new ArrayTableValue({
            values: report.carriers?.map((carrier): ArrayTableValueItem => {
              return {
                key: `${carrier.id!}`,
                text: carrier.name,
                url: appUrlProvider.buildShowCarrierUrl({
                  carrierId: carrier.id!
                })
              }
            })
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "gasStations",
          entity: Entity.GAS_STATIONS,
          title: refuellingOrdersReportsTextProvider.gasStationsField(),
          createValue: (report: RefuellingOrdersReport) => new ArrayTableValue({
            values: report.gasStations?.map((gasStation): ArrayTableValueItem => {
              return {
                key: `${gasStation.id!}`,
                text: gasStation.name,
                url: appUrlProvider.buildShowGasStationUrl({
                  gasStationId: gasStation.id!
                })
              }
            })
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "trips",
          entity: Entity.TRIPS,
          title: refuellingOrdersReportsTextProvider.tripsField(),
          createValue: (report: RefuellingOrdersReport) => new ArrayTableValue({
            values: report.trips?.map((trip): ArrayTableValueItem => {
              return {
                key: `${trip.id!}`,
                text: trip.name,
                url: appUrlProvider.buildShowTripUrl({
                  tripId: trip.id!
                })
              }
            })
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "drivers",
          entity: Entity.DRIVERS,
          title: refuellingOrdersReportsTextProvider.driversField(),
          createValue: (report: RefuellingOrdersReport) => new ArrayTableValue({
            values: report.drivers?.map((driver): ArrayTableValueItem => {
              return {
                key: `${driver.id!}`,
                text: appTextProvider.driverName({
                  user: driver
                }),
                url: appUrlProvider.buildShowDriverUrl({
                  driverId: driver.id!
                })
              }
            })
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "refuellingOrderStatuses",
          title: refuellingOrdersReportsTextProvider.refuellingOrderStatusesField(),
          createValue: (report: RefuellingOrdersReport) => new ArrayTableValue({
            values: report.refuellingOrderStatusesSelectOptions?.map((refuellingOrderStatus): ArrayTableValueItem => {
              return {
                key: `${refuellingOrderStatus.id!}`,
                text: refuellingOrderStatus.text
              }
            })
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "fuels",
          title: refuellingOrdersReportsTextProvider.fuelsField(),
          createValue: (report: RefuellingOrdersReport) => new ArrayTableValue({
            values: report.fuels?.map((fuel): ArrayTableValueItem => {
              return {
                key: `${fuel.id!}`,
                text: fuel.name
              }
            })
          })
        }),
        new TableColumn<RefuellingOrdersReport>({
          name: "report",
          title: refuellingOrdersReportsTextProvider.reportField(),
          createValue: (report: RefuellingOrdersReport) => new TextTableValue({
            value: report.statusName,
            url: report.documentUrl && report.documentUrl,
            openInNewTab: true
          })
        })
      ]
    })
  }
}
