import GasStationsTextProvider from "../GasStationsTextProvider"

export default class DefaultRuGasStationsTextProvider implements GasStationsTextProvider {

  blockedField(): string {
    return "Блокировка"
  }

  codeField(): string {
    return "Шифр"
  }

  contactsField(): string {
    return "Контакты"
  }

  phoneNumberField(): string {
    return "Номер телефона"
  }

  existObjectTitle(): string {
    return "АЗС"
  }

  listTitle(): string {
    return "АЗС"
  }

  nameField(): string {
    return "Наименование"
  }

  gpsCoordinatesField(): string {
    return "GPS координаты АЗС"
  }

  latitudeField(): string {
    return "Широта"
  }

  longitudeField(): string {
    return "Долгота"
  }

  fuelCompanyField(): string {
    return "Топливная компания"
  }

  newObjectTitle(): string {
    return "Новая АЗС"
  }

  pricesLink(): string {
    return "Цены"
  }

  breaksLink(): string {
    return "Перерывы"
  }

  statusField(): string {
    return "Статус"
  }

  addressField(): string {
    return "Адрес"
  }

  statusValueActive(): string {
    return "Активна"
  }

  statusValueBlocked(): string {
    return "Заблокирована"
  }

  fuelCompanyFilter(): string {
    return "Топливная компания"
  }
}
