import NetworkInstallationInstanceErrorsObjectAttributes from "./NetworkInstallationInstanceErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import InstallationInstanceErrorsObject
  from "../../../../../domain/installation-instance/InstallationInstanceErrorsObject"

export default class NetworkInstallationInstanceErrorsObject implements InstallationInstanceErrorsObject {
  @Expose()
  @Type(() => NetworkInstallationInstanceErrorsObjectAttributes)
  readonly attributes?: NetworkInstallationInstanceErrorsObjectAttributes | null
}
