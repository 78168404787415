import NetworkAttributeError from "../../entities/network/errors/NetworkAttributeError"
import AttributeError from "../../../domain/entities/errors/AttributeError"
import AttributeErrorObjectsMapper from "./AttributeErrorObjectsMapper"

export default class AttributeErrorsMapper {
  mapNetworkToDomain({ object }: { readonly object: NetworkAttributeError }): AttributeError {
    const attributeErrorObjectsMapper = new AttributeErrorObjectsMapper()
    const { objects } = object
    return {
      type: object.type,
      message: object.message,
      objects: objects && objects.map((attributeErrorObject) => {
        return attributeErrorObjectsMapper.mapNetworkToDomain({ object: attributeErrorObject })
      })
    }
  }
}
