import AuthenticationTheme from "../../admin/features/authentication/theme/AuthenticationTheme"

import ImAuthenticationLogo from "../resources/assets/illustrations/imAuthenticationLogo.svg"

export default class AppAuthenticationTheme implements AuthenticationTheme {

  imAuthenticationLogoPath(): string {
    return ImAuthenticationLogo
  }
}
