import CurrentFuelCompanyBalanceTransactionsRepository
  from "../../domain/repositories/CurrentFuelCompanyBalanceTransactionsRepository"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import {
  GetCurrentFuelCompanyBalanceTransactionsParameters
} from "../../domain/use-cases/fuel-company-balance-transactions/GetCurrentFuelCompanyBalanceTransactionsUseCase"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import FuelCompanyBalanceTransactionsMapper
  from "../../../../core/data/mappers/FuelCompanyBalanceTransactionsMapper"
import FuelCompanyBalanceTransaction
  from "../../../../core/domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"
import CurrentFuelCompanyBalanceTransactionsNetworkSource
  from "../../../../core/data/sources/network/CurrentFuelCompanyBalanceTransactionsNetworkSource"

export default class DefaultCurrentFuelCompanyBalanceTransactionsRepository
  implements CurrentFuelCompanyBalanceTransactionsRepository {
  private readonly currentFuelCompanyBalanceTransactionsNetworkSource: CurrentFuelCompanyBalanceTransactionsNetworkSource

  constructor(parameters: {
    readonly currentFuelCompanyBalanceTransactionsNetworkSource: CurrentFuelCompanyBalanceTransactionsNetworkSource
  }) {
    this.currentFuelCompanyBalanceTransactionsNetworkSource = parameters.currentFuelCompanyBalanceTransactionsNetworkSource
  }

  async getTransactions({
    pagination,
    sort
  }: GetCurrentFuelCompanyBalanceTransactionsParameters): Promise<GetObjectsResult<FuelCompanyBalanceTransaction>> {
    const result = await this.currentFuelCompanyBalanceTransactionsNetworkSource
      .getTransactions({
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
          pagination
        }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success": {
        const fuelCompanyBalanceTransactionsMapper = new FuelCompanyBalanceTransactionsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.fuelCompanyBalanceReplenishments!.map((fuelCompanyBalanceTransaction) => {
              return fuelCompanyBalanceTransactionsMapper.mapNetworkToDomain({ fuelCompanyBalanceTransaction })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
