import RefuellingOrdersReportsRepository from "../../domain/repositories/RefuellingOrdersReportsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultRefuellingOrdersReportsRepository from "../../data/repositories/DefaultRefuellingOrdersReportsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForRefuellingOrdersReportsRepository
  from "../../domain/repositories/FuelCompaniesForRefuellingOrdersReportsRepository"
import DefaultFuelCompaniesForRefuellingOrdersReportsRepository
  from "../../data/repositories/DefaultFuelCompaniesForRefuellingOrdersReportsRepository"
import CarriersForRefuellingOrdersReportsRepository
  from "../../domain/repositories/CarriersForRefuellingOrdersReportsRepository"
import DefaultCarriersForRefuellingOrdersReportsRepository
  from "../../data/repositories/DefaultCarriersForRefuellingOrdersReportsRepository"
import FuelsForRefuellingOrdersReportsRepository
  from "../../domain/repositories/FuelsForRefuellingOrdersReportsRepository"
import DefaultFuelsForRefuellingOrdersReportsRepository
  from "../../data/repositories/DefaultFuelsForRefuellingOrdersReportsRepository"
import GasStationsForRefuellingOrdersReportsRepository
  from "../../domain/repositories/GasStationsForRefuellingOrdersReportsRepository"
import DefaultGasStationsForRefuellingOrdersReportsRepository
  from "../../data/repositories/DefaultGasStationsForRefuellingOrdersReportsRepository"
import DriversForRefuellingOrdersReportsRepository
  from "../../domain/repositories/DriversForRefuellingOrdersReportsRepository"
import DefaultDriversForRefuellingOrdersReportsRepository
  from "../../data/repositories/DefaultDriversForRefuellingOrdersReportsRepository"
import RefuellingOrderStatusesForRefuellingOrdersReportsRepository
  from "../../domain/repositories/RefuellingOrderStatusesForRefuellingOrdersReportsRepository"
import DefaultRefuellingOrderStatusesForRefuellingOrdersReportsRepository
  from "../../data/repositories/DefaultRefuellingOrderStatusesForRefuellingOrdersReportsRepository"
import TripsForRefuellingOrdersReportsRepository
  from "../../domain/repositories/TripsForRefuellingOrdersReportsRepository"
import DefaultTripsForRefuellingOrdersReportsRepository
  from "../../data/repositories/DefaultTripsForRefuellingOrdersReportsRepository"

export default interface RefuellingOrdersReportsDataDiModule {
  provideRefuellingOrdersReportsRepository(): RefuellingOrdersReportsRepository

  provideCarriersForRefuellingOrdersReportsRepository(): CarriersForRefuellingOrdersReportsRepository

  provideFuelCompaniesForRefuellingOrdersReportsRepository(): FuelCompaniesForRefuellingOrdersReportsRepository

  provideFuelsForRefuellingOrdersReportsRepository(): FuelsForRefuellingOrdersReportsRepository

  provideGasStationsForRefuellingOrdersReportsRepository(): GasStationsForRefuellingOrdersReportsRepository

  provideDriversForRefuellingOrdersReportsRepository(): DriversForRefuellingOrdersReportsRepository

  provideRefuellingOrderStatusesForRefuellingOrdersReportsRepository(): RefuellingOrderStatusesForRefuellingOrdersReportsRepository

  provideTripsForRefuellingOrdersReportsRepository(): TripsForRefuellingOrdersReportsRepository
}

export class DefaultRefuellingOrdersReportsDataDiModule extends DiModule implements RefuellingOrdersReportsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideRefuellingOrdersReportsRepository(): RefuellingOrdersReportsRepository {
    return this.single(
      DefaultRefuellingOrdersReportsRepository.name,
      () => new DefaultRefuellingOrdersReportsRepository({
        refuellingOrdersReportsNetworkSource: this.appDataDiModule.provideRefuellingOrdersReportsNetworkSource(),
        refuellingOrderStatusesStaticSource: this.appDataDiModule.provideRefuellingOrderStatusesStaticSource()
      })
    )
  }

  provideCarriersForRefuellingOrdersReportsRepository(): CarriersForRefuellingOrdersReportsRepository {
    return this.single(
      DefaultCarriersForRefuellingOrdersReportsRepository.name,
      () => new DefaultCarriersForRefuellingOrdersReportsRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }

  provideFuelCompaniesForRefuellingOrdersReportsRepository(): FuelCompaniesForRefuellingOrdersReportsRepository {
    return this.single(
      DefaultFuelCompaniesForRefuellingOrdersReportsRepository.name,
      () => new DefaultFuelCompaniesForRefuellingOrdersReportsRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }

  provideFuelsForRefuellingOrdersReportsRepository(): FuelsForRefuellingOrdersReportsRepository {
    return this.single(
      DefaultFuelsForRefuellingOrdersReportsRepository.name,
      () => new DefaultFuelsForRefuellingOrdersReportsRepository({
        fuelsNetworkSource: this.appDataDiModule.provideFuelsNetworkSource()
      })
    )
  }

  provideGasStationsForRefuellingOrdersReportsRepository(): GasStationsForRefuellingOrdersReportsRepository {
    return this.single(
      DefaultGasStationsForRefuellingOrdersReportsRepository.name,
      () => new DefaultGasStationsForRefuellingOrdersReportsRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }

  provideDriversForRefuellingOrdersReportsRepository(): DriversForRefuellingOrdersReportsRepository {
    return this.single(
      DefaultDriversForRefuellingOrdersReportsRepository.name,
      () => new DefaultDriversForRefuellingOrdersReportsRepository({
        driversNetworkSource: this.appDataDiModule.provideDriversNetworkSource()
      })
    )
  }

  provideRefuellingOrderStatusesForRefuellingOrdersReportsRepository(): RefuellingOrderStatusesForRefuellingOrdersReportsRepository {
    return this.single(
      DefaultRefuellingOrderStatusesForRefuellingOrdersReportsRepository.name,
      () => new DefaultRefuellingOrderStatusesForRefuellingOrdersReportsRepository({
        refuellingOrderStatusesStaticSource: this.appDataDiModule.provideRefuellingOrderStatusesStaticSource()
      })
    )
  }

  provideTripsForRefuellingOrdersReportsRepository(): TripsForRefuellingOrdersReportsRepository {
    return this.single(
      DefaultTripsForRefuellingOrdersReportsRepository.name,
      () => new DefaultTripsForRefuellingOrdersReportsRepository({
        tripsNetworkSource: this.appDataDiModule.provideTripsNetworkSource()
      })
    )
  }
}
