import FuelCompanyBalancesI18n from "../../i18n/FuelCompanyBalancesI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import FuelCompanyBalancesTextProvider from "../../i18n/FuelCompanyBalancesTextProvider"

export default interface FuelCompanyBalancesI18nDiModule {
  provideFuelCompanyBalancesI18n(): FuelCompanyBalancesI18n
}

export class DefaultFuelCompanyBalancesI18nDiModule
  extends I18nDiModule<FuelCompanyBalancesTextProvider>
  implements FuelCompanyBalancesI18nDiModule {

  provideFuelCompanyBalancesI18n(): FuelCompanyBalancesI18n {
    return this.single(
      FuelCompanyBalancesI18n.name,
      () => new FuelCompanyBalancesI18n(this.getI18nParameters())
    )
  }
}
