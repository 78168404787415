import NetworkCarrierCommercialTermErrorsObjectAttributes from "./NetworkCarrierCommercialTermErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import CarrierCommercialTermErrorsObject
  from "../../../../../domain/carrier-commercial-terms/CarrierCommercialTermErrorsObject"

export default class NetworkCarrierCommercialTermErrorsObject implements CarrierCommercialTermErrorsObject {
  @Expose()
  @Type(() => NetworkCarrierCommercialTermErrorsObjectAttributes)
  readonly attributes?: NetworkCarrierCommercialTermErrorsObjectAttributes | null
}
