import React, { ReactElement, useEffect, useRef, useState } from "react"
import DropdownComponent from "../../dropdown/DropdownComponent"
import styles from "./SelectPickerComponent.module.scss"
import SelectOption from "../SelectOption"
import isBlank from "../../isBlank"
import PickerComponentStyles from "../PickerComponentStyles"
import ButtonComponent from "../../button/ButtonComponent"
import isPresent from "../../isPresent"

export interface SelectPickerComponentProps {
  readonly options: SelectOption[]
  readonly selectedOption: SelectOption | null
  readonly disabled?: boolean
  readonly clearable?: boolean
  readonly hasErrors?: boolean
  readonly isLoading?: boolean
  readonly searchingEnabled?: boolean
  readonly placeholder?: string
  readonly aboveOptionsElement?: React.ReactNode
  readonly belowOptionsElement?: React.ReactNode
  readonly className?: string
  readonly componentStyle?: PickerComponentStyles
  readonly onChange: (option: SelectOption | null) => void
  readonly onOpened?: () => void
}

const defaultPadding = 8

export default function SelectPickerComponent({
  options,
  selectedOption,
  disabled,
  clearable,
  hasErrors,
  placeholder,
  aboveOptionsElement,
  belowOptionsElement,
  className = "",
  componentStyle = {},
  onChange,
  onOpened
}: SelectPickerComponentProps) {
  const iconDivRef = useRef<HTMLDivElement>(null)
  const [rightIconWidth, setRightIconWidth] = useState(defaultPadding)

  const [isOpened, setIsOpened] = useState(false)
  const {
    clearButton: {
      iconClassName,
      styles: clearButtonStyles
    } = {},
    input: {
      icons: inputIcons = {},
      classes: {
        inputDefault: inputDefaultClassName = "",
        inputActive: inputActiveClassName = "",
        inputErrors: inputErrorsClassName = "",
        inputDisabled: inputDisabledClassName = "",
        inputPlaceholder: inputPlaceholderClassName = ""
      } = {}
    } = {},
    dropdown: {
      dropdownClassName = "",
      options: {
        classes: {
          optionDefault: dropdownOptionDefaultClassName = "",
          optionSelected: dropdownOptionSelectedClassName = ""
        } = {}
      } = {}
    } = {}
  } = componentStyle

  useEffect(() => {
    if (isBlank(iconDivRef.current)) return

    setRightIconWidth(iconDivRef.current.clientWidth)
  }, [])

  function handleRequestHide() {
    setIsOpened(false)
  }

  function handleOnOpenClick() {
    if (disabled) {
      return
    }

    callOnOpened()
    setIsOpened(true)
  }

  function handleOnClearClick() {
    onChange(null)
  }

  function handleOnOptionClick(option: SelectOption) {
    onChange(option)
    setIsOpened(false)
  }

  function isOptionSelected(option: SelectOption) {
    return option.id === selectedOption?.id
  }

  function callOnOpened() {
    onOpened && onOpened()
  }

  function getExpandMoreIcon(): ReactElement {
    if (disabled) {
      return (
        <>
          {inputIcons.rightIconDisabled}
        </>
      )
    }

    if (isOpened) {
      return (
        <>
          {inputIcons.rightIconActive}
        </>
      )
    }

    return (
      <>
        {inputIcons.rightIconDefault}
      </>
    )
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.selectButtonContainer}>
        <div className={`${styles.selectContainer} ${disabled ? styles.disabled : ""} `}>
          <div
            style={{ paddingRight: rightIconWidth }}
            className={[
              styles.selectButton,
              inputDefaultClassName,
              disabled ? inputDisabledClassName : "",
              selectedOption ? "" : inputPlaceholderClassName,
              hasErrors ? inputErrorsClassName : "",
              isOpened ? inputActiveClassName : ""
            ].join(" ")}
            onClick={handleOnOpenClick}
          >
            {selectedOption ? selectedOption.text : placeholder}
          </div>
          <div className={styles.icon} ref={iconDivRef}>
            {getExpandMoreIcon()}
          </div>
        </div>
        <DropdownComponent
          className={`${styles.dropdown} ${dropdownClassName}`}
          isVisible={isOpened}
          hideOnClickOutside={true}
          hasScroll={true}
          requestHide={handleRequestHide}
        >
          <div className={`${styles.dropdownContent} ${dropdownClassName}`}>
            {aboveOptionsElement}
            {options.map((option: SelectOption) => (
              <div
                key={option.id}
                className={[
                  styles.dropdownOption,
                  dropdownOptionDefaultClassName,
                  isOptionSelected(option) ? dropdownOptionSelectedClassName : ""
                ].join(" ")}
                onClick={() => handleOnOptionClick(option)}
              >
                {option.text}
              </div>
            ))}
            {belowOptionsElement}
          </div>
        </DropdownComponent>
      </div>
      {clearable && isPresent(selectedOption) && (
        <ButtonComponent
          icon={<div className={iconClassName} />}
          componentStyles={clearButtonStyles}
          isDisabled={disabled}
          onClick={handleOnClearClick}
        />
      )}
    </div>
  )
}
