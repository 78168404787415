export enum Entity {
  SESSIONS = "sessions",
  PASSWORDS = "passwords",
  MENUS = "menus",
  REFUELLING_ORDERS = "refuelling_orders",
  USER_PROFILE = "user_profile",
  USERS = "users",
  CARRIER_USERS = "carrier_users",
  FUEL_COMPANY_USERS = "fuel_company_users",
  CARRIERS = "carriers",
  CARRIER_BALANCE_REPLENISHMENTS = "carrier_balance_replenishments",
  CARRIER_BALANCE_WRITE_OFFS = "carrier_balance_write_offs",
  CURRENT_CARRIER_BALANCE_REPLENISHMENTS = "current_carrier_balance_replenishments",
  CURRENT_CARRIER_BALANCE_WRITE_OFFS = "current_carrier_balance_write_offs",
  FUEL_COMPANIES = "fuel_companies",
  FUEL_COMPANY_BALANCE_WRITE_OFFS = "fuel_company_balance_write_offs",
  FUEL_COMPANY_BALANCE_REPLENISHMENTS = "fuel_company_balance_replenishments",
  CURRENT_FUEL_COMPANY_BALANCE_WRITE_OFFS = "current_fuel_company_balance_write_offs",
  CURRENT_FUEL_COMPANY_BALANCE_REPLENISHMENTS = "current_fuel_company_balance_replenishments",
  GAS_STATIONS = "gas_stations",
  TRANSPORTS = "transports",
  CARRIER_COMMERCIAL_TERMS = "carrier_commercial_terms",
  INSTALLATION_INSTANCES = "installation_instances",
  FUEL_PRICES = "fuel_prices",
  FUEL_COMPANY_COMMERCIAL_TERMS = "fuel_company_commercial_terms",
  FUELS = "fuels",
  DRIVERS = "drivers",
  TRIPS = "trips",
  CHANGING_LOG_ITEMS = "changing_log_items",
  REFUELLING_ORDERS_REPORTS = "refuelling_orders_reports",
  CARRIER_RECONCILIATION_REPORTS = "carrier_reconciliation_reports",
  FUEL_COMPANY_RECONCILIATION_REPORTS = "fuel_company_reconciliation_reports",
  GAS_STATION_BREAKS = "gas_station_breaks"
}
