import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import { RefuellingOrderStatusSelectOption } from "../../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import RefuellingOrderStatusesForRefuellingOrdersReportsRepository
  from "../../repositories/RefuellingOrderStatusesForRefuellingOrdersReportsRepository"

export interface GetRefuellingOrderStatusesForRefuellingOrdersReportsParameters extends GetObjectsParameters {
}

export default class GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase
  implements GetObjectsUseCase<RefuellingOrderStatusSelectOption> {
  private readonly refuellingOrderStatusesForRefuellingOrdersReportsRepository:
    RefuellingOrderStatusesForRefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly refuellingOrderStatusesForRefuellingOrdersReportsRepository:
      RefuellingOrderStatusesForRefuellingOrdersReportsRepository
  }) {
    this.refuellingOrderStatusesForRefuellingOrdersReportsRepository =
      parameters.refuellingOrderStatusesForRefuellingOrdersReportsRepository
  }

  async call(
    parameters: GetRefuellingOrderStatusesForRefuellingOrdersReportsParameters
  ): Promise<GetObjectsResult<RefuellingOrderStatusSelectOption>> {
    return await this.refuellingOrderStatusesForRefuellingOrdersReportsRepository.getRefuellingOrderStatuses(parameters)
  }
}
