import { Expose, Type } from "class-transformer"
import NetworkUser from "../../users/NetworkUser"

export default class NetworkDriverRequestBody {

  @Expose()
  @Type(() => NetworkUser)
  readonly driver?: NetworkUser | null

  constructor(parameters: {
    readonly driver?: NetworkUser | null
  }) {
    this.driver = parameters.driver
  }
}
