import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkFuelCompaniesResponseBody
  from "../../entities/fuel-companies/response-bodies/NetworkFuelCompaniesResponseBody"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkFuelCompaniesRequestQuery
  from "../../entities/fuel-companies/request-queries/NetworkFuelCompaniesRequestQuery"
import NetworkFuelCompaniesRequestFilter
  from "../../entities/fuel-companies/request-queries/NetworkFuelCompaniesRequestFilter"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelCompanyResponseBody
  from "../../entities/fuel-companies/response-bodies/NetworkFuelCompanyResponseBody"
import NetworkFuelCompanyErrorResponseBody
  from "../../entities/fuel-companies/response-bodies/errors/NetworkFuelCompanyErrorResponseBody"
import NetworkFuelCompanyRequestBody from "../../entities/fuel-companies/request-bodies/NetworkFuelCompanyRequestBody"
import NetworkFuelCompany from "../../entities/fuel-companies/NetworkFuelCompany"

const basePath = "/api/admin/fuel_companies"

export default class FuelCompaniesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getFuelCompanies({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkFuelCompaniesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetFuelCompaniesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkFuelCompaniesRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompaniesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getFuelCompany({
    fuelCompanyId
  }: {
    readonly fuelCompanyId: number
  }): Promise<GetFuelCompanyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${fuelCompanyId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getCurrentFuelCompany(): Promise<GetFuelCompanyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/current`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createFuelCompany({
    fuelCompany
  }: {
    readonly fuelCompany?: NetworkFuelCompany | null
  }): Promise<CreateFuelCompanyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkFuelCompanyRequestBody({
        fuelCompany
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelCompanyErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateFuelCompany({
    fuelCompanyId,
    fuelCompany
  }: {
    readonly fuelCompanyId: number
    readonly fuelCompany?: NetworkFuelCompany | null
  }): Promise<UpdateFuelCompanyNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${fuelCompanyId}`,
      body: instanceToPlain(new NetworkFuelCompanyRequestBody({
        fuelCompany
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelCompanyErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetFuelCompaniesNetworkResult =
  SuccessExecutionResult<NetworkFuelCompaniesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetFuelCompanyNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateFuelCompanyNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyResponseBody> |
  ErrorExecutionResult<NetworkFuelCompanyErrorResponseBody> |
  FailureExecutionResult

export type UpdateFuelCompanyNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyResponseBody> |
  ErrorExecutionResult<NetworkFuelCompanyErrorResponseBody> |
  FailureExecutionResult
