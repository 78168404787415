import CarriersMapper from "./CarriersMapper"
import NetworkTrip from "../entities/trips/NetworkTrip"
import Trip from "../../domain/trips/Trip"
import TripLimitsMapper from "./TripLimitsMapper"
import UserTripsMapper from "./UserTripsMapper"
import TransportsMapper from "./TransportsMapper"
import { filterNotEmpty } from "../../../../admin/lib/filterNotEmpty"
import UsersMapper from "./UsersMapper"
import isBlank from "../../../../admin/lib/isBlank"
import { TripStatusSelectOption } from "../../domain/trips/TripStatus"

export default class TripsMapper {
  mapNetworkToDomain({
    trip,
    tripStatuses
  }: {
    readonly trip: NetworkTrip
    readonly tripStatuses: TripStatusSelectOption[]
  }): Trip {
    const tripLimitsMapper = new TripLimitsMapper()
    const usersMapper = new UsersMapper()
    const userTripsMapper = new UserTripsMapper()
    const carriersMapper = new CarriersMapper()
    const transportMapper = new TransportsMapper()

    const {
      carrier,
      transport,
      userTrips,
      limits
    } = trip

    return {
      id: trip.id,
      name: trip.name,
      status: trip.status,
      statusSelectOption: tripStatuses.find((option) => {
        return option.id === trip.status
      }),
      note: trip.note,
      startingAt: trip.startingAt,
      finishingAt: trip.finishingAt,
      carrierId: trip.carrierId,
      transportId: trip.transportId,
      carrier: carrier && carriersMapper.mapNetworkToDomain({ carrier }),
      transport: transport && transportMapper.mapNetworkToDomain({ transport }),
      driverIds: userTrips && filterNotEmpty(userTrips.map((userTrip) => userTrip.userId)),
      drivers: userTrips && filterNotEmpty(
        userTrips.map((userTrip) => {
            const { user } = userTrip
            if (isBlank(user)) return null

            return usersMapper.mapNetworkToDomain({ user })
          }
        )),
      userTrips: userTrips && userTrips.map((userTrip) => userTripsMapper.mapNetworkToDomain({ userTrip })),
      limits: limits && limits.map((tripLimit) => tripLimitsMapper.mapNetworkToDomain({ tripLimit }))
    }
  }

  mapDomainToNetwork({
    trip
  }: {
    readonly trip: Trip
  }): NetworkTrip {
    const tripLimitsMapper = new TripLimitsMapper()
    const {
      limits
    } = trip

    return {
      name: trip.name,
      status: trip.status,
      note: trip.note,
      startingAt: trip.startingAt,
      finishingAt: trip.finishingAt,
      carrierId: trip.carrierId,
      transportId: trip.transportId,
      driverIds: trip.driverIds,
      limits: limits && limits.map((tripLimit) => tripLimitsMapper.mapDomainToNetwork({ tripLimit }))
    }
  }
}
