import CarrierBalanceTransaction from "../../../../core/domain/carrier-balance-transactions/CarrierBalanceTransaction"
import TableColumn, {
  TableColumnAlignment
} from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import CurrentCarrierBalancesI18n from "../../i18n/CurrentCarrierBalancesI18n"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import isBlank from "../../../../../admin/lib/isBlank"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class CurrentCarrierBalanceTransactionsTableProvider
  implements TableProvider<CarrierBalanceTransaction> {
  protected readonly timeZone: string
  protected readonly carrierBalancesI18: CurrentCarrierBalancesI18n

  constructor(parameters: {
    readonly timeZone: string
    readonly carrierBalancesI18: CurrentCarrierBalancesI18n
  }) {
    this.timeZone = parameters.timeZone
    this.carrierBalancesI18 = parameters.carrierBalancesI18
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getEntity(): string {
    return Entity.CURRENT_CARRIER_BALANCE_WRITE_OFFS
  }

  getTable(): Table<CarrierBalanceTransaction> {
    const appUrlProvider = new AppUrlProvider()
    const carrierBalancesTextProvider = this.carrierBalancesI18.getTextProvider()

    return new Table<CarrierBalanceTransaction>({
      title: carrierBalancesTextProvider.listTitle(),
      getObjectId: (carrierBalanceTransaction: CarrierBalanceTransaction) => carrierBalanceTransaction.id!.toString(),
      getRowUrl: () => "",
      newObjectPageUrl: appUrlProvider.buildNewCarrierUrl(),
      columns: [
        new TableColumn<CarrierBalanceTransaction>({
          name: "id",
          title: carrierBalancesTextProvider.idField(),
          createValue: (carrierBalanceTransaction: CarrierBalanceTransaction) => new TextTableValue({
            value: `${carrierBalanceTransaction.id!}`
          })
        }),
        new TableColumn<CarrierBalanceTransaction>({
          name: "date",
          title: carrierBalancesTextProvider.dateField(),
          createValue: (carrierBalanceTransaction: CarrierBalanceTransaction) => new DateTableValue({
            value: carrierBalanceTransaction.date,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        }),
        new TableColumn<CarrierBalanceTransaction>({
          name: "value",
          title: carrierBalancesTextProvider.valueField(),
          columnAlignment: TableColumnAlignment.RIGHT,
          createValue: (carrierBalanceTransaction: CarrierBalanceTransaction) => new TextTableValue({
            value: withRubbleSymbol(carrierBalanceTransaction.formattedValue)
          })
        }),
        new TableColumn<CarrierBalanceTransaction>({
          name: "reason",
          entity: Entity.REFUELLING_ORDERS,
          title: carrierBalancesTextProvider.reasonField(),
          createValue: (carrierBalanceTransaction: CarrierBalanceTransaction) => new TextTableValue({
            value: carrierBalancesTextProvider.refuellingReasonValue({
              refuellingOrderCode: carrierBalanceTransaction.fuelTransaction?.refuellingOrder?.code
            }),
            url: (() => {
              const refuellingOrderId = carrierBalanceTransaction.fuelTransaction?.refuellingOrder?.id
              if (isBlank(refuellingOrderId)) return undefined

              return appUrlProvider.buildShowRefuellingOrderUrl({
                refuellingOrderId
              })
            })()
          })
        })
      ]
    })
  }
}
