import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import CarrierBalanceChangeDocumentsMapper
  from "../../../../core/data/mappers/CarrierBalanceChangeDocumentsMapper"
import CurrentCarrierBalanceChangeDocumentsRepository
  from "../../domain/repositories/CurrentCarrierBalanceChangeDocumentsRepository"
import CurrentCarrierBalanceChangeDocumentsNetworkSource
  from "../../../../core/data/sources/network/CurrentCarrierBalanceChangeDocumentsNetworkSource"
import {
  GetCurrentCarrierBalanceChangeDocumentsParameters
} from "../../domain/use-cases/carrier-balance-change-documents/GetCurrentCarrierBalanceChangeDocumentsUseCase"
import CarrierBalanceChangeDocument
  from "../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"

export default class DefaultCurrentCarrierBalanceChangeDocumentsRepository
  implements CurrentCarrierBalanceChangeDocumentsRepository {
  private readonly currentCarrierBalanceChangeDocumentsNetworkSource: CurrentCarrierBalanceChangeDocumentsNetworkSource

  constructor(parameters: {
    readonly currentCarrierBalanceChangeDocumentsNetworkSource: CurrentCarrierBalanceChangeDocumentsNetworkSource
  }) {
    this.currentCarrierBalanceChangeDocumentsNetworkSource =
      parameters.currentCarrierBalanceChangeDocumentsNetworkSource
  }

  async getDocuments({
    pagination,
    sort
  }: GetCurrentCarrierBalanceChangeDocumentsParameters): Promise<GetObjectsResult<CarrierBalanceChangeDocument>> {
    const result = await this.currentCarrierBalanceChangeDocumentsNetworkSource
      .getDocuments({
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
          pagination
        }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success": {
        const carrierBalanceChangeDocumentsMapper = new CarrierBalanceChangeDocumentsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.carrierBalanceReplenishments!.map((carrierBalanceChangeDocument) => {
              return carrierBalanceChangeDocumentsMapper.mapNetworkToDomain({ carrierBalanceChangeDocument })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
