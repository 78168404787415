import { Expose, Type } from "class-transformer"
import NetworkFuelCompanyReconciliationReport from "../NetworkFuelCompanyReconciliationReport"

export default class NetworkFuelCompanyReconciliationReportRequestBody {

  @Expose()
  @Type(() => NetworkFuelCompanyReconciliationReport)
  readonly report?: NetworkFuelCompanyReconciliationReport | null

  constructor(parameters: {
    readonly report?: NetworkFuelCompanyReconciliationReport | null
  }) {
    this.report = parameters.report
  }
}
