import Carrier from "../../../../core/domain/carriers/Carrier"
import CarrierError from "../../../../core/domain/carriers/CarrierError"
import CarrierErrorsObject from "../../../../core/domain/carriers/CarrierErrorsObject"
import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import BooleanFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/BooleanFormField"
import CarriersI18 from "../../i18n/CarriersI18"
import PhoneNumberFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/PhoneNumberFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"
const legalEntityGroupName = "legalEntity"
const contactPersonGroupName = "contactPerson"

export default class CarrierFormProvider implements FormProvider<Carrier, CarrierError, CarrierErrorsObject> {
  private readonly carriersI18: CarriersI18

  constructor(parameters: {
    readonly carriersI18: CarriersI18
  }) {
    this.carriersI18 = parameters.carriersI18
  }

  getEntity(): string {
    return Entity.CARRIERS
  }

  getNewObjectTitle(): string {
    return this.carriersI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: Carrier
  }): string {
    if (isBlank(object)) return this.carriersI18.getTextProvider().existObjectTitle()

    const { name } = object

    return isPresent(name) ? name : this.carriersI18.getTextProvider().existObjectTitle()
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: Carrier
  }): string {
    if (isBlank(object)) return this.carriersI18.getTextProvider().existObjectTitle()

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.carriersI18.getTextProvider().existObjectTitle()
  }

  async buildObject(): Promise<Carrier> {
    return {}
  }

  getErrorsObject({ error }: { readonly error?: CarrierError }): CarrierErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    const carriersTextProvider = this.carriersI18.getTextProvider()

    return [
      {
        name: mainGroupName,
        visible: false
      },
      {
        name: legalEntityGroupName,
        visible: true,
        title: carriersTextProvider.legalEntityField()
      },
      {
        name: contactPersonGroupName,
        visible: true,
        title: carriersTextProvider.contactPersonField()
      }
    ]
  }

  getFields(): FormField<Carrier, CarrierErrorsObject>[] {
    const carriersTextProvider = this.carriersI18.getTextProvider()

    return filterNotEmpty([
      new StringFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.nameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "name",
        getValue: (carrier: Carrier) => carrier.name,
        setValue: (carrier: Carrier, name: string) => ({ ...carrier, name }),
        getErrors: (errorsObject?: CarrierErrorsObject) => errorsObject?.attributes?.name
      }),
      new StringFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.codeField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "code",
        getValue: (carrier: Carrier) => carrier.code,
        setValue: (carrier: Carrier, code: string) => ({ ...carrier, code }),
        getErrors: (errorsObject?: CarrierErrorsObject) => errorsObject?.attributes?.code
      }),
      new BooleanFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.blockedField(),
        groupName: mainGroupName,
        getId: () => "blocked",
        getIsVisible: (carrier: Carrier) => isPresent(carrier.id),
        getValue: (carrier: Carrier) => carrier.blocked,
        setValue: (carrier: Carrier, blocked: boolean | null | undefined) => ({ ...carrier, blocked })
      }),
      new StringFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.legalEntityNameField(),
        groupName: legalEntityGroupName,
        getId: () => "legalEntityName",
        getValue: (carrier: Carrier) => carrier.legalEntity?.name,
        setValue: (carrier: Carrier, name: string) => {
          return {
            ...carrier,
            legalEntity: {
              ...carrier.legalEntity,
              name
            }
          }
        }
      }),
      new StringFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.legalEntityIndividualTaxpayerNumberField(),
        groupName: legalEntityGroupName,
        getId: () => "legalEntityNameIndividualTaxpayerNumber",
        getValue: (carrier: Carrier) => carrier.legalEntity?.individualTaxpayerNumber,
        setValue: (carrier: Carrier, individualTaxpayerNumber: string) => {
          return {
            ...carrier,
            legalEntity: {
              ...carrier.legalEntity,
              individualTaxpayerNumber
            }
          }
        },
        getErrors: (errorsObject?: CarrierErrorsObject) => {
          return errorsObject?.legalEntity?.attributes?.individualTaxpayerNumber
        }
      }),
      new StringFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.legalEntityTaxRegistrationReasonCode(),
        groupName: legalEntityGroupName,
        getId: () => "legalEntityTaxRegistrationReasonCode",
        getValue: (carrier: Carrier) => carrier.legalEntity?.taxRegistrationReasonCode,
        setValue: (carrier: Carrier, taxRegistrationReasonCode: string) => {
          return {
            ...carrier,
            legalEntity: {
              ...carrier.legalEntity,
              taxRegistrationReasonCode
            }
          }
        },
        getErrors: (errorsObject?: CarrierErrorsObject) => {
          return errorsObject?.legalEntity?.attributes?.taxRegistrationReasonCode
        }
      }),
      new StringFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.contactPersonNameField(),
        groupName: contactPersonGroupName,
        getId: () => "contactPersonName",
        getValue: (carrier: Carrier) => carrier.contactPerson?.name,
        setValue: (carrier: Carrier, name: string) => {
          return {
            ...carrier,
            contactPerson: {
              ...carrier.contactPerson,
              name
            }
          }
        }
      }),
      new StringFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.contactPersonEmailField(),
        groupName: contactPersonGroupName,
        getId: () => "contactPersonEmail",
        getValue: (carrier: Carrier) => carrier.contactPerson?.email,
        setValue: (carrier: Carrier, email: string) => {
          return {
            ...carrier,
            contactPerson: {
              ...carrier.contactPerson,
              email
            }
          }
        },
        getErrors: (errorsObject?: CarrierErrorsObject) => {
          return errorsObject?.contactPerson?.attributes?.email
        }
      }),
      new PhoneNumberFormField<Carrier, CarrierErrorsObject>({
        title: carriersTextProvider.contactPersonPhoneNumberField(),
        groupName: contactPersonGroupName,
        getId: () => "contactPersonPhoneNumber",
        getValue: (carrier: Carrier) => carrier.contactPerson?.phoneNumber,
        setValue: (carrier: Carrier, phoneNumber: string) => {
          return {
            ...carrier,
            contactPerson: {
              ...carrier.contactPerson,
              phoneNumber
            }
          }
        },
        getErrors: (errorsObject?: CarrierErrorsObject) => {
          return errorsObject?.contactPerson?.attributes?.phoneNumber
        }
      })
    ])
  }
}
