import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import RefuellingOrdersFilter from "../../../../core/domain/refuelling-orders/RefuellingOrdersFilter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import RefuellingOrderStatus, {
  RefuellingOrderStatusSelectOption
} from "../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import GetRefuellingOrderStatusesForRefuellingOrdersUseCase
  from "../../domain/use-cases/refuelling-order-statuses/GetRefuellingOrderStatusesForRefuellingOrdersUseCase"
import BaseRefuellingOrdersTableProvider, {
  RefuellingOrdersTableProviderParameters
} from "./BaseRefuellingOrdersTableProvider"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"

export default class RefuellingOrdersTableProvider extends BaseRefuellingOrdersTableProvider {

  private readonly getRefuellingOrderStatusesUseCase: GetRefuellingOrderStatusesForRefuellingOrdersUseCase

  constructor(parameters: RefuellingOrdersTableProviderParameters & {
    readonly getRefuellingOrderStatusesUseCase: GetRefuellingOrderStatusesForRefuellingOrdersUseCase
  }) {
    super(parameters)
    this.getRefuellingOrderStatusesUseCase = parameters.getRefuellingOrderStatusesUseCase
  }

  getRowUrl(refuellingOrder: RefuellingOrder) {
    const appUrlProvider = new AppUrlProvider()
    return appUrlProvider.buildShowRefuellingOrderUrl({
      refuellingOrderId: refuellingOrder.id!
    })
  }

  getFilter(): Filter<RefuellingOrdersFilter> {
    const refuellingOrdersTextProvider = this.refuellingOrdersI18n.getTextProvider()
    const filter = super.getFilter()

    const additionalFilters = [
      new SingleSelectFormField<RefuellingOrdersFilter, void, RefuellingOrderStatusSelectOption>({
        title: refuellingOrdersTextProvider.refuellingStatusField(),
        groupName: "",
        getId: () => "status",
        getValue: (filter: RefuellingOrdersFilter) => filter.statusSelectOption,
        setValue: (
          filter: RefuellingOrdersFilter,
          statusSelectOption: RefuellingOrderStatusSelectOption | null
        ): RefuellingOrdersFilter => ({
          ...filter,
          statusSelectOption,
          status: statusSelectOption?.id as RefuellingOrderStatus
        }),
        getObjectsUseCase: this.getRefuellingOrderStatusesUseCase,
        getOptionId: (optionObject: RefuellingOrderStatusSelectOption) => optionObject.id!.toString(),
        getOptionText: (optionObject: RefuellingOrderStatusSelectOption) => optionObject.text
      })
    ]

    return new Filter<RefuellingOrdersFilter>({
      fields: [
        ...filter.getFields(),
        ...additionalFilters
      ],
      buildFilterObject: filter.buildFilterObject
    })
  }

  getTitle(): string {
    const refuellingOrdersTextProvider = this.refuellingOrdersI18n.getTextProvider()
    return refuellingOrdersTextProvider.refuellingOrders()
  }
}
