import RootMenuProvider from "../../../../../admin/features/root/presentation/services/RootMenuProvider"
import MenuItem from "../../../../../admin/core/domain/entities/menus/MenuItem"
import UiMenuItem from "../../../../../admin/features/root/presentation/components/left-menu/UiMenuItem"
import isBlank from "../../../../../admin/lib/isBlank"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import { MenuItemType } from "../../../../../admin/core/domain/entities/menus/MenuItemType"

const carriersResourceName = "carriers"
const fuelCompaniesName = "fuel_companies"
const gasStationsName = "gas_stations"
const gasStationBreaksName = "gas_station_breaks"
const carrierCommercialTermsName = "carrier_commercial_terms"
const fuelCompanyCommercialTermsName = "fuel_company_commercial_terms"
const installationInstances = "installation_instances"
const transportsName = "transports"
const fuelPricesName = "fuel_prices"
const driversName = "drivers"
const tripsName = "trips"
const tripChangingLogName = "trip_changing_log"
const refuellingOrdersName = "refuelling_orders"
const claimedRefuellingOrdersName = "claimed_refuelling_orders"
const cancelledRefuellingOrdersName = "cancelled_refuelling_orders"
const usersName = "users"
const carrierUsersName = "carrier_users"
const fuelCompanyUsersName = "fuel_company_users"
const carrierBalanceName = "carrier_balance"
const fuelCompanyBalanceName = "fuel_company_balance"
const refuellingOrdersReportsName = "refuelling_orders_reports"
const carrierReconciliationReportsName = "carrier_reconciliation_reports"
const fuelCompanyReconciliationReportsName = "fuel_company_reconciliation_reports"

export default class DefaultRootMenuProvider implements RootMenuProvider {

  createLeftMenuItems({
    menuItems
  }: {
    readonly menuItems?: MenuItem[] | null
  }): UiMenuItem[] {
    if (isBlank(menuItems)) return []

    const urlProvider = new AppUrlProvider()
    const uiMenuItems: (UiMenuItem | undefined)[] = menuItems.map((menuItem) => {
      const url = (() => {
        switch (menuItem.type) {
          case MenuItemType.RESOURCE: {
            if (menuItem.resource === carriersResourceName) {
              return urlProvider.buildCarriersUrl()
            }

            if (menuItem.resource === fuelCompaniesName) {
              return urlProvider.buildFuelCompaniesUrl()
            }

            if (menuItem.resource === gasStationsName) {
              return urlProvider.buildGasStationsUrl()
            }

            if (menuItem.resource === carrierCommercialTermsName) {
              return urlProvider.buildCarrierCommercialTermsUrl()
            }

            if (menuItem.resource === fuelCompanyCommercialTermsName) {
              return urlProvider.buildFuelCompanyCommercialTermsUrl()
            }

            if (menuItem.resource === installationInstances) {
              return urlProvider.buildInstallationInstancesUrl()
            }

            if (menuItem.resource === transportsName) {
              return urlProvider.buildTransportsUrl()
            }

            if (menuItem.resource === fuelPricesName) {
              return urlProvider.buildFuelPricesUrl()
            }

            if (menuItem.resource === driversName) {
              return urlProvider.buildDriversUrl()
            }

            if (menuItem.resource === tripsName) {
              return urlProvider.buildTripsUrl()
            }

            if (menuItem.resource === tripChangingLogName) {
              return urlProvider.buildTripChangingLogUrl()
            }

            if (menuItem.resource === refuellingOrdersName) {
              return urlProvider.buildRefuellingOrdersUrl()
            }

            if (menuItem.resource === claimedRefuellingOrdersName) {
              return urlProvider.buildClaimedRefuellingOrdersUrl()
            }

            if (menuItem.resource === cancelledRefuellingOrdersName) {
              return urlProvider.buildCancelledRefuellingOrdersUrl()
            }

            if (menuItem.resource === usersName) {
              return urlProvider.buildUsersUrl()
            }

            if (menuItem.resource === carrierUsersName) {
              return urlProvider.buildCarrierUsersUrl()
            }

            if (menuItem.resource === fuelCompanyUsersName) {
              return urlProvider.buildFuelCompanyUsersUrl()
            }

            if (menuItem.resource === carrierBalanceName) {
              return urlProvider.buildCurrentCarrierBalanceUrl()
            }

            if (menuItem.resource === fuelCompanyBalanceName) {
              return urlProvider.buildCurrentFuelCompanyBalanceUrl()
            }

            if (menuItem.resource === refuellingOrdersReportsName) {
              return urlProvider.buildRefuellingOrdersReportsUrl()
            }

            if (menuItem.resource === carrierReconciliationReportsName) {
              return urlProvider.buildCarrierReconciliationReportsUrl()
            }

            if (menuItem.resource === fuelCompanyReconciliationReportsName) {
              return urlProvider.buildFuelCompanyReconciliationReportsUrl()
            }

            if (menuItem.resource === gasStationBreaksName) {
              return urlProvider.buildGasStationBreaksUrl()
            }

            return undefined
          }
          default:
            return undefined
        }
      })()

      if (isBlank(url)) return undefined

      return {
        key: `${menuItem.id!}`,
        title: menuItem.title,
        url
      }
    })

    return filterNotEmpty(uiMenuItems)
  }
}
