import GasStationsI18 from "../../i18n/GasStationsI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import GasStationsTextProvider from "../../i18n/GasStationsTextProvider"

export default interface GasStationsI18nDiModule {
  provideGasStationsI18n(): GasStationsI18
}

export class DefaultGasStationsI18nDiModule
  extends I18nDiModule<GasStationsTextProvider>
  implements GasStationsI18nDiModule {

  provideGasStationsI18n(): GasStationsI18 {
    return this.single(
      GasStationsI18.name,
      () => new GasStationsI18(this.getI18nParameters())
    )
  }
}
