import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForCarrierCommercialTermsRepository from "../../repositories/CarriersForCarrierCommercialTermsRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForCarrierCommercialTermsParameters extends GetObjectsParameters {
}

export default class GetCarriersForCarrierCommercialTermsUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForCarrierCommercialTermsRepository: CarriersForCarrierCommercialTermsRepository

  constructor(parameters: {
    readonly carriersForCarrierCommercialTermsRepository: CarriersForCarrierCommercialTermsRepository
  }) {
    this.carriersForCarrierCommercialTermsRepository = parameters.carriersForCarrierCommercialTermsRepository
  }

  async call(parameters: GetCarriersForCarrierCommercialTermsParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForCarrierCommercialTermsRepository.getCarriers(parameters)
  }
}
