import CurrentCarrierBalancesI18n from "../../i18n/CurrentCarrierBalancesI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import CurrentCarrierBalancesTextProvider from "../../i18n/CurrentCarrierBalancesTextProvider"

export default interface CurrentCarrierBalancesI18nDiModule {
  provideCurrentCarrierBalancesI18n(): CurrentCarrierBalancesI18n
}

export class DefaultCurrentCarrierBalancesI18nDiModule
  extends I18nDiModule<CurrentCarrierBalancesTextProvider>
  implements CurrentCarrierBalancesI18nDiModule {

  provideCurrentCarrierBalancesI18n(): CurrentCarrierBalancesI18n {
    return this.single(
      CurrentCarrierBalancesI18n.name,
      () => new CurrentCarrierBalancesI18n(this.getI18nParameters())
    )
  }
}
