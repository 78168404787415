import UsersRepository from "../../domain/repositories/UsersRepository"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import User from "../../../../core/domain/users/User"
import { GetUsersParameters } from "../../domain/use-cases/users/GetUsersUseCase"
import UsersMapper from "../../../../core/data/mappers/UsersMapper"
import { GetUserParameters } from "../../domain/use-cases/users/GetUserUseCase"
import { CreateUserParameters } from "../../domain/use-cases/users/CreateUserUseCase"
import isPresent from "../../../../../admin/lib/isPresent"
import UsersNetworkSource from "../../../../core/data/sources/network/UsersNetworkSource"
import NetworkUsersRequestFilter from "../../../../core/data/entities/users/request-queries/NetworkUsersRequestFilter"
import { UpdateUserParameters } from "../../domain/use-cases/users/UpdateUserUseCase"
import UserError from "../../../../core/domain/users/UserError"

export default class DefaultUsersRepository implements UsersRepository {
  private readonly usersNetworkSource: UsersNetworkSource

  constructor(parameters: {
    readonly usersNetworkSource: UsersNetworkSource
  }) {
    this.usersNetworkSource = parameters.usersNetworkSource
  }

  async getUsers({
    filter,
    query,
    pagination,
    sort
  }: GetUsersParameters): Promise<GetObjectsResult<User>> {
    const carrierId = filter?.carrierId
    const fuelCompanyId = filter?.fuelCompanyId
    const result = await this.usersNetworkSource.getUsers({
      filter: new NetworkUsersRequestFilter({
        query,
        carrierIds: isPresent(carrierId) ? [carrierId] : null,
        fuelCompanyIds: isPresent(fuelCompanyId) ? [fuelCompanyId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.users!.map((user) => {
              return new UsersMapper().mapNetworkToDomain({ user })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getUser({
    objectId
  }: GetUserParameters): Promise<GetObjectResult<User>> {
    const result = await this.usersNetworkSource.getUser({
      userId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }

  async createUser({
    object: user
  }: CreateUserParameters): Promise<CreateObjectResult<User, UserError>> {
    const mapper = new UsersMapper()
    const result = await this.usersNetworkSource.createUser({
      user: mapper.mapDomainToNetwork({ user: user })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }

  async updateUser({
    objectId: userId,
    object: user
  }: UpdateUserParameters): Promise<UpdateObjectResult<User, UserError>> {
    const mapper = new UsersMapper()
    const result = await this.usersNetworkSource.updateUser({
      userId: parseInt(userId),
      user: mapper.mapDomainToNetwork({ user })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }
}
