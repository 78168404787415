import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkUser from "../../users/NetworkUser"

export default class NetworkUsersResponseBody {

  @Expose()
  @Type(() => NetworkUser)
  readonly users?: NetworkUser[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
