import Carrier from "../../../../../core/domain/carriers/Carrier"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForTripChangingLogRepository from "../../repositories/CarriersForTripChangingLogRepository"

export interface GetCarriersForTripChangingLogParameters extends GetObjectsParameters {
}

export default class GetCarriersForTripChangingLogUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForTripChangingLogRepository: CarriersForTripChangingLogRepository

  constructor(parameters: {
    readonly carriersForTripChangingLogRepository: CarriersForTripChangingLogRepository
  }) {
    this.carriersForTripChangingLogRepository = parameters.carriersForTripChangingLogRepository
  }

  async call(parameters: GetCarriersForTripChangingLogParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForTripChangingLogRepository.getCarriers(parameters)
  }
}
