import FuelPricesRepository from "../../repositories/FuelPricesRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import FuelPrice from "../../../../../core/domain/fuel-prices/FuelPrice"
import FuelPriceError from "../../../../../core/domain/fuel-prices/FuelPriceError"

export interface UpdateFuelPriceParameters {
  readonly objectId: string
  readonly object: FuelPrice
}

export default class UpdateFuelPriceUseCase implements UpdateObjectUseCase<FuelPrice, FuelPriceError> {
  private readonly fuelPricesRepository: FuelPricesRepository

  constructor(parameters: {
    readonly fuelPricesRepository: FuelPricesRepository
  }) {
    this.fuelPricesRepository = parameters.fuelPricesRepository
  }

  async call(parameters: UpdateFuelPriceParameters): Promise<UpdateObjectResult<FuelPrice, FuelPriceError>> {
    return await this.fuelPricesRepository.updateFuelPrice(parameters)
  }
}
