import { Expose, Type } from "class-transformer"

export default class NetworkPermission {
  @Expose()
  @Type(() => String)
  readonly entity?: string | null

  @Expose()
  @Type(() => String)
  readonly entityField?: string | null

  @Expose()
  @Type(() => String)
  readonly restrictionType?: string | null
}
