import InfoRow, { InfoRowParameters } from "../InfoRow"

export interface ArrayInfoRowItem {
  readonly key: string
  readonly value: string | undefined |null
  readonly url?: string | null
}

export default class ArrayInfoRow extends InfoRow {
  readonly items: ArrayInfoRowItem[]

  constructor(parameters: InfoRowParameters & {
    readonly items: ArrayInfoRowItem[] | undefined | null
  }) {
    super(parameters)

    this.items = parameters.items ?? []
  }
}
