import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelCompanyBalanceTransactionsResponseBody
  from "../../entities/fuel-company-balance-transactions/response-bodies/NetworkFuelCompanyBalanceTransactionsResponseBody"
import NetworkFuelCompanyBalanceTransactionsRequestQuery
  from "../../entities/fuel-company-balance-transactions/request-queries/NetworkFuelCompanyBalanceTransactionsRequestQuery"

const baseUrl = "/api/admin/current_fuel_company_balance_replenishments"

export default class CurrentFuelCompanyBalanceTransactionsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getTransactions({
    pagination,
    sort
  }: {
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetCurrentFuelCompanyBalanceTransactionsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${baseUrl}`,
      parameters: instanceToPlain(new NetworkFuelCompanyBalanceTransactionsRequestQuery({
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyBalanceTransactionsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCurrentFuelCompanyBalanceTransactionsNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyBalanceTransactionsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

