import React from "react"
import SignInComponent from "../../components/sign-in/SignInComponent"
import SignInPresenter from "./SignInPresenter"
import SignInView, { SignInViewState } from "./SignInView"
import { Navigate } from "react-router-dom"
import assertNever from "../../../../../lib/assertNever"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"

interface Props {
  readonly providePresenter: () => SignInPresenter
}

interface State {
  readonly signInViewState?: SignInViewState
}

export default class SignInPage extends React.Component<Props, State> implements SignInView {
  private readonly presenter: SignInPresenter

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showSignInViewState(signInViewState: SignInViewState) {
    this.setState({ signInViewState: signInViewState })
  }

  render() {
    const adminCoreUrlProvider = new CoreUrlProvider()

    const { signInViewState } = this.state

    return (
      <>
        {signInViewState && (() => {
          switch (signInViewState.type) {
            case "not_authenticated":
              return (
                <SignInComponent
                  onEmailAddressChanged={this.presenter.onEmailAddressChange}
                  onPasswordChanged={this.presenter.onPasswordChange}
                  onAuthenticateClicked={this.presenter.onAuthenticateClicked}
                  user={signInViewState.user}
                  isAuthenticating={signInViewState.isAuthenticating}
                  authenticationError={signInViewState.authenticationError}
                  authenticationFailureException={signInViewState.authenticationFailureException}
                />
              )
            case "already_authenticated":
            case "now_authenticated":
              return <Navigate to={adminCoreUrlProvider.buildRootUrl()} replace={true} />
            default:
              assertNever(signInViewState)
          }
        })()}
      </>
    )
  }
}
