import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"

export default class BooleanFormField<DomainObject, ErrorsObject> extends FormField<DomainObject, ErrorsObject> {
  private readonly getValue: (object: DomainObject) => boolean | null | undefined
  private readonly setValue: (object: DomainObject, value: boolean | null | undefined) => DomainObject

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getValue: (object: DomainObject) => boolean | null | undefined
    readonly setValue: (object: DomainObject, value: boolean | null | undefined) => DomainObject
  }) {
    super(parameters)
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    return new BooleanFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      value: this.getValue(object),
      onChange: (value: boolean | null | undefined) => {
        this.setObject(this.setValue(object, value))
        this.setAndShowLoadedObjectViewState()
      }
    })
  }

  serializeValue(object: DomainObject): string | null | undefined {
    const value: boolean | null | undefined = this.getValue(object)
    return value?.toString()
  }

  async init({
    object,
    serializedValue
  }: {
    readonly object: DomainObject
    readonly serializedValue: string | null | undefined
  }): Promise<void> {
    const value: boolean | null | undefined = (() => {
      if (serializedValue === null || serializedValue === undefined) return serializedValue
      if (serializedValue === "true") return true
      if (serializedValue === "false") return false

      return undefined
    })()

    if (value === undefined || value === null) {
      return
    }

    this.setObject(this.setValue(object, value))
  }
}

export class BooleanFormFieldViewState extends FormFieldViewState {
  readonly value: boolean | null | undefined
  onChange: (value: boolean | null | undefined) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onChange: (value: boolean | null | undefined) => void
    readonly value: boolean | null | undefined
  }) {
    super(parameters)
    this.value = parameters.value
    this.onChange = parameters.onChange
  }

  hasValue(): boolean {
    return this.value ?? false
  }
}
