import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import GasStationsForRefuellingOrdersRepository
  from "../../domain/repositories/GasStationsForRefuellingOrdersRepository"
import GasStationsNetworkSource from "../../../../core/data/sources/network/GasStationsNetworkSource"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import {
  GetGasStationsForRefuellingOrdersParameters
} from "../../domain/use-cases/gas-stations/GetGasStationsForRefuellingOrdersUseCase"
import NetworkGasStationsRequestFilter
  from "../../../../core/data/entities/gas-stations/request-queries/NetworkGasStationsRequestFilter"
import GasStationsMapper from "../../../../core/data/mappers/GasStationsMapper"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultGasStationsForRefuellingOrdersRepository
  implements GasStationsForRefuellingOrdersRepository {
  private readonly gasStationsNetworkSource: GasStationsNetworkSource

  constructor(parameters: {
    readonly gasStationsNetworkSource: GasStationsNetworkSource
  }) {
    this.gasStationsNetworkSource = parameters.gasStationsNetworkSource
  }

  async getGasStations({
    query,
    pagination,
    sort,
    filter
  }: GetGasStationsForRefuellingOrdersParameters): Promise<GetObjectsResult<GasStation>> {
    const fuelCompanyId = filter?.fuelCompanyId
    const result = await this.gasStationsNetworkSource.getGasStations({
      filter: new NetworkGasStationsRequestFilter({
        query,
        fuelCompanyIds: isPresent(fuelCompanyId) ? [fuelCompanyId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const gasStationsMapper = new GasStationsMapper()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.gasStations!.map((gasStation) => {
              return gasStationsMapper.mapNetworkToDomain({ gasStation })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
