import Presenter from "../../../../../lib/presenter/Presenter"
import ObjectInfoView, { AbstractObjectInfoViewState, ObjectInfoViewState } from "./ObjectInfoView"
import GetObjectUseCase, { GetObjectResult } from "../../../domain/use-cases/objects/GetObjectUseCase"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import assertNever from "../../../../../lib/assertNever"
import autoBind from "auto-bind"
import InfoProvider from "../../providers/InfoProvider"
import InfoRow from "../../entities/info/InfoRow"
import LinkInfo from "../../entities/info/LinkInfo"
import ObjectsEvent, { ObjectsEventCallback } from "../../../domain/entities/ObjectsEvent"
import SubscribeToObjectsEventsUseCase from "../../../domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase from "../../../domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import { RestrictionType } from "../../../../../core/domain/entities/user-profile/RestrictionType"
import CheckPermissionDeniedUseCase
  from "../../../../../core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import { Entity } from "../../../../../core/domain/entities/user-profile/Entity"
import isBlank from "../../../../../lib/isBlank"

export default class ObjectInfoPresenter<DomainObject>
  extends Presenter<ObjectInfoView> {

  private readonly getObjectUseCase: GetObjectUseCase<DomainObject>
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
  private objectsEventsCallback?: ObjectsEventCallback
  private readonly infoProvider: InfoProvider<DomainObject>
  private readonly objectId: string
  private object?: DomainObject
  private objectInfoViewState?: ObjectInfoViewState
  private needReloadObjectsOnReAttach: boolean

  constructor(parameters: {
    readonly getObjectUseCase: GetObjectUseCase<DomainObject>
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
    readonly infoProvider: InfoProvider<DomainObject>
    readonly objectId: string
  }) {
    super()

    autoBind(this)

    this.getObjectUseCase = parameters.getObjectUseCase
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.checkPermissionDeniedUseCase = parameters.checkPermissionDeniedUseCase
    this.needReloadObjectsOnReAttach = false
    this.infoProvider = parameters.infoProvider
    this.objectId = parameters.objectId
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    if (this.canSeeInfo()) {
      this.subscribeToObjectsEvents()
      this.loadAndShowObject().then()
    } else {
      this.setAndShowForbiddenObjectInfoViewState()
    }
  }

  protected onViewReAttach() {
    super.onViewReAttach()
    this.showObjectInfoViewState()

    // TODO: reload objects if related objects changed, not only current object type.
    if (this.getNeedReloadObjectsOnReAttach()) {
      this.setNeedReloadObjectsOnReAttach(false)
      this.loadAndShowObject().then()
    }
  }

  protected onDestroy() {
    super.onDestroy()
    this.unsubscribeFromObjectsEvents()
  }

  private subscribeToObjectsEvents() {
    this.objectsEventsCallback = this.subscribeToObjectsEventsUseCase.call((event: ObjectsEvent) => {
      switch (event.type) {
        case "created":
        case "updated":
        case "destroyed":
          this.setNeedReloadObjectsOnReAttach(true)
          break
        default:
          assertNever(event)
      }
    })
  }

  private unsubscribeFromObjectsEvents() {
    this.objectsEventsCallback && this.unsubscribeFromObjectsEventsUseCase.call(this.objectsEventsCallback)
  }

  private buildTitle(): string {
    return this.infoProvider.getObjectTitle({
      object: this.object
    })
  }

  private buildShortTitle(): string {
    return this.infoProvider.getObjectShortTitle({
      object: this.object
    })
  }

  private buildInfoRows(): InfoRow[] {
    const infoRows = this.infoProvider.getInfoRows({ object: this.object })
    infoRows.forEach((infoRow) => {
      infoRow.setUrlVisibilityByPermission(this.getInfoRowUrlVisibility(infoRow.getEntity()))
      infoRow.setVisibilityByPermission(this.getInfoRowVisibility(infoRow.getName()))
    })
    return infoRows
  }

  private buildLinks(): LinkInfo[] {
    const links = this.infoProvider.getLinks({ object: this.object })
    return links.filter((linkInfo) => {
      return this.getLinkVisibility(linkInfo.entity)
    })
  }

  private buildEditUrl(): string {
    return this.infoProvider.getEditUrl({
      object: this.object
    })
  }

  private buildCanEdit(): boolean {
    return !this.isPermissionDenied({
      restrictionType: RestrictionType.DENY_ENTITY_EDIT
    })
  }

  private async loadAndShowObject(): Promise<void> {
    this.setAndShowLoadingObjectInfoViewState()

    const result: GetObjectResult<DomainObject> = await this.getObjectUseCase.call({
      objectId: this.objectId!
    })

    switch (result.type) {
      case "error":
        this.setAndShowLoadingErrorObjectInfoViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowLoadingFailureObjectInfoViewState({ exception: result.exception })
        break
      case "success":
        this.setObject(result.data)
        this.setAndShowLoadedObjectInfoViewState()
        break
      default:
        assertNever(result)
    }
  }

  private setAndShowLoadingObjectInfoViewState() {
    this.setAndShowObjectInfoViewState({
      ...this.buildAbstractObjectInfoViewState(),
      type: "loading"
    })
  }

  private setAndShowLoadingErrorObjectInfoViewState({
    error
  }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowObjectInfoViewState({
      ...this.buildAbstractObjectInfoViewState(),
      type: "loading_error",
      error
    })
  }

  private setAndShowLoadingFailureObjectInfoViewState({
    exception
  }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowObjectInfoViewState({
      ...this.buildAbstractObjectInfoViewState(),
      type: "loading_failure",
      exception
    })
  }

  private setAndShowLoadedObjectInfoViewState() {
    this.setAndShowObjectInfoViewState({
      ...this.buildAbstractObjectInfoViewState(),
      type: "loaded",
      infoRows: this.buildInfoRows(),
      links: this.buildLinks(),
      editUrl: this.buildEditUrl(),
      canEdit: this.buildCanEdit()
    })
  }

  private setAndShowForbiddenObjectInfoViewState() {
    this.setAndShowObjectInfoViewState({
      ...this.buildAbstractObjectInfoViewState(),
      type: "forbidden"
    })
  }

  private buildAbstractObjectInfoViewState(): AbstractObjectInfoViewState {
    return {
      title: this.buildTitle(),
      shortTitle: this.buildShortTitle(),
      objectId: this.objectId
    }
  }

  private setAndShowObjectInfoViewState(objectInfoViewState: ObjectInfoViewState) {
    this.setShowObjectInfoViewState(objectInfoViewState)
    this.showObjectInfoViewState()
  }

  private setShowObjectInfoViewState(objectInfoViewState: ObjectInfoViewState) {
    this.objectInfoViewState = objectInfoViewState
  }

  private showObjectInfoViewState() {
    this.objectInfoViewState && this.getView()?.showObjectInfoViewState(this.objectInfoViewState)
  }

  private setObject(object: DomainObject) {
    this.object = object
  }

  private getNeedReloadObjectsOnReAttach() {
    return this.needReloadObjectsOnReAttach
  }

  private setNeedReloadObjectsOnReAttach(needReloadObjectsOnReAttach: boolean) {
    this.needReloadObjectsOnReAttach = needReloadObjectsOnReAttach
  }

  private canSeeInfo() {
    return !this.isPermissionDenied({ restrictionType: RestrictionType.DENY_ENTITY_DISPLAY })
  }

  private getLinkVisibility(entity: Entity | undefined) {
    if (isBlank(entity)) return true

    return !this.isPermissionDenied({
      entity: entity,
      restrictionType: RestrictionType.DENY_ENTITIES_LIST_DISPLAY
    })
  }

  private getInfoRowUrlVisibility(entity: Entity | undefined) {
    if (isBlank(entity)) return true

    return !this.isPermissionDenied({
      entity: entity,
      restrictionType: RestrictionType.DENY_ENTITY_DISPLAY
    })
  }

  private getInfoRowVisibility(infoRowName: string) {
    return !this.isPermissionDenied({
      entityField: infoRowName,
      restrictionType: RestrictionType.DENY_ENTITY_FIELD_DISPLAY
    })
  }

  private isPermissionDenied(parameters: { entity?: string, entityField?: string, restrictionType: RestrictionType }) {
    return this.checkPermissionDeniedUseCase.call({
      entity: parameters.entity ?? this.infoProvider.getEntity(),
      entityField: parameters.entityField,
      restrictionType: parameters.restrictionType
    })
  }
}
