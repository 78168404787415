import React from "react"
import styles from "./MainPage.module.scss"
import { useRootThemeProvider } from "../../contexts/RootThemeProviderContext"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"

export default function MainPage() {
  const rootTheme = useRootThemeProvider()
  const coreTextProvider = useCoreTextProvider()

  return (
    <main className={styles.mainScreen}>
      <img
        src={rootTheme.imMainPageLogoPath()}
        className={styles.logo}
      />
      <span className={styles.link}>
        {coreTextProvider.supportEmailMessage()}
        <a href={`mailto:${coreTextProvider.supportEmailAddress()}`}>
          {coreTextProvider.supportEmailAddress()}
        </a>
      </span>
    </main>
  )
}
