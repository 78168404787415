import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import UsersI18 from "../../i18n/UsersI18"
import User from "../../../../core/domain/users/User"
import AppI18 from "../../../../core/i18n/AppI18"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class UserInfoProvider implements InfoProvider<User> {
  private readonly usersI18: UsersI18
  private readonly appI18: AppI18

  constructor(parameters: {
    readonly usersI18: UsersI18
    readonly appI18: AppI18
  }) {
    this.usersI18 = parameters.usersI18
    this.appI18 = parameters.appI18
  }

  getEntity(): string {
    return Entity.USERS
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().userName({ user: object })
  }

  getObjectTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().userName({ user: object })
  }

  getInfoRows({ object }: {
    readonly object: User
  }): InfoRow[] {
    const usersTextProvider = this.usersI18.getTextProvider()

    return [
      new StringInfoRow({
        name: "lastName",
        title: usersTextProvider.lastNameField(),
        value: object.profile?.lastName
      }),
      new StringInfoRow({
        name: "firstName",
        title: usersTextProvider.firstNameField(),
        value: object.profile?.firstName
      }),
      new StringInfoRow({
        name: "middleName",
        title: usersTextProvider.middleNameField(),
        value: object.profile?.middleName
      }),
      new StringInfoRow({
        name: "emailAddress",
        title: usersTextProvider.emailField(),
        value: object.emailAddress
      }),
      new StringInfoRow({
        name: "blocked",
        title: usersTextProvider.statusField(),
        value: (() => {
          const { blocked = false } = object

          return (blocked ?? false) ?
            usersTextProvider.statusValueBlocked() :
            usersTextProvider.statusValueActive()
        })()
      })
    ]
  }

  getLinks({ object }: {
    readonly object: User
  }): LinkInfo[] {
    const urlProvider = new AppUrlProvider()
    const usersTextProvider = this.usersI18.getTextProvider()

    return [
      {
        id: "tripEvents",
        entity: Entity.CHANGING_LOG_ITEMS,
        title: usersTextProvider.eventsLink(),
        url: urlProvider.buildTripChangingLogUrl({
          changedById: object.id
        })
      }
    ]
  }

  getEditUrl({
    object
  }: {
    readonly object: User
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditUserUrl({
      userId: object.id!
    })
  }
}
