import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelsForFuelCompanyCommercialTermsRepository from "../../repositories/FuelsForFuelCompanyCommercialTermsRepository"
import Fuel from "../../../../../core/domain/fuels/Fuel"

export interface GetFuelsForFuelCompanyCommercialTermsParameters extends GetObjectsParameters {
}

export default class GetFuelsForFuelCompanyCommercialTermsUseCase implements GetObjectsUseCase<Fuel> {
  private readonly fuelsForFuelCompanyCommercialTermsRepository: FuelsForFuelCompanyCommercialTermsRepository

  constructor(parameters: {
    readonly fuelsForFuelCompanyCommercialTermsRepository: FuelsForFuelCompanyCommercialTermsRepository
  }) {
    this.fuelsForFuelCompanyCommercialTermsRepository = parameters.fuelsForFuelCompanyCommercialTermsRepository
  }

  async call(parameters: GetFuelsForFuelCompanyCommercialTermsParameters): Promise<GetObjectsResult<Fuel>> {
    return await this.fuelsForFuelCompanyCommercialTermsRepository.getFuels(parameters)
  }
}
