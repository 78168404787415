import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import CarrierCommercialTermsI18 from "../../i18n/CarrierCommercialTermsI18"
import CarrierCommercialTerm from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import DateInfoRow from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/DateInfoRow"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import ArrayInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/ArrayInfoRow"
import PricingType from "../../../../core/domain/commercial-terms/PricingType"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class CarrierCommercialTermInfoProvider implements InfoProvider<CarrierCommercialTerm> {
  private readonly timeZone: string
  private readonly carrierCommercialTermsI18: CarrierCommercialTermsI18

  constructor(parameters: {
    readonly timeZone: string
    readonly carrierCommercialTermsI18: CarrierCommercialTermsI18
  }) {
    this.timeZone = parameters.timeZone
    this.carrierCommercialTermsI18 = parameters.carrierCommercialTermsI18
  }

  getEntity(): string {
    return Entity.CARRIER_COMMERCIAL_TERMS
  }

  getObjectShortTitle(_parameters: {
    readonly object?: CarrierCommercialTerm
  }): string {
    return this.carrierCommercialTermsI18.getTextProvider().existObjectTitle()
  }

  getObjectTitle(_parameters: {
    readonly object?: CarrierCommercialTerm
  }): string {
    return this.carrierCommercialTermsI18.getTextProvider()
      .existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: CarrierCommercialTerm
  }): InfoRow[] {
    const appUrlProvider = new AppUrlProvider()
    const carrierCommercialTermsTextProvider = this.carrierCommercialTermsI18.getTextProvider()

    const {
      carrier,
      fuelCompany,
      fuel,
      gasStations
    } = object

    return [
      new StringInfoRow({
        name: "carrier",
        entity: Entity.CARRIERS,
        title: carrierCommercialTermsTextProvider.carrierField(),
        value: carrier?.name,
        url: carrier && appUrlProvider.buildShowCarrierUrl({
          carrierId: carrier.id!
        })
      }),
      new StringInfoRow({
        name: "fuelCompany",
        entity: Entity.FUEL_COMPANIES,
        title: carrierCommercialTermsTextProvider.fuelCompanyField(),
        value: fuelCompany?.name,
        url: fuelCompany && appUrlProvider.buildShowFuelCompanyUrl({
          fuelCompanyId: fuelCompany.id!
        })
      }),
      new StringInfoRow({
        name: "fuel",
        title: carrierCommercialTermsTextProvider.fuelField(),
        value: fuel?.name
      }),
      new ArrayInfoRow({
        name: "gasStations",
        entity: Entity.GAS_STATIONS,
        title: carrierCommercialTermsTextProvider.gasStationsIdField(),
        items: gasStations?.map((gasStation) => {
          return {
            key: `${gasStation.id!}`,
            value: gasStation.name,
            url: appUrlProvider.buildShowGasStationUrl({
              gasStationId: gasStation.id!
            })
          }
        })
      }),
      new DateInfoRow({
        name: "beginDate",
        title: carrierCommercialTermsTextProvider.beginDateField(),
        value: object.beginDate,
        format: DateTimeFormat.DATE
      }),
      new DateInfoRow({
        name: "endDate",
        title: carrierCommercialTermsTextProvider.endDateField(),
        value: object.endDate,
        format: DateTimeFormat.DATE
      }),
      new StringInfoRow({
        name: "pricingType",
        title: carrierCommercialTermsTextProvider.pricingTypeField(),
        value: (() => {
          switch (object.pricingType) {
            case PricingType.FIXED:
              return carrierCommercialTermsTextProvider.pricingTypePriceValue({
                formattedPrice: object.formattedPrice
              })
            case PricingType.DISCOUNT:
              return carrierCommercialTermsTextProvider.pricingTypeDiscountPercentageValue({
                formattedDiscountPercentage: object.formattedDiscountPercentage
              })
            default:
              return null
          }
        })()
      }),
      new StringInfoRow({
        name: "documentUrl",
        title: carrierCommercialTermsTextProvider.documentUrlField(),
        value: object.documentUrl,
        url: object.documentUrl,
        openInNewTab: true
      })
    ]
  }

  getLinks(_parameters: {
    readonly object: CarrierCommercialTerm
  }): LinkInfo[] {
    return []
  }

  getEditUrl({
    object
  }: {
    readonly object: CarrierCommercialTerm
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditCarrierCommercialTermUrl({
      carrierCommercialTermId: object.id!
    })
  }
}
