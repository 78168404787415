import CarrierBalancePresenter from "./CarrierBalancePresenter"
import CarrierBalancePage from "./CarrierBalancePage"
import React from "react"
import useLocationContext from "../../../../../../admin/lib/presenter/useLocationContext"
import { Params, useLocation, useParams, useSearchParams } from "react-router-dom"
import isBlank from "../../../../../../admin/lib/isBlank"
import addSearchParam from "../../../../../../admin/lib/addSearchParam"
import CarrierBalancesI18n from "../../../i18n/CarrierBalancesI18n"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import { objectIdUrlPart } from "../../../../../../admin/core/presentation/constants/urlParts"

const TRANSACTIONS_TYPE_KEY = "transactions_type"

interface Props {
  readonly carrierBalancesI18n: CarrierBalancesI18n
  readonly providePresenter: (parameters: {
    readonly carrierId: string
    readonly transactionsType?: BalanceTransactionType | null
  }) => CarrierBalancePresenter
}

export default function CarrierBalancePageRouteElement({
  providePresenter,
  carrierBalancesI18n
}: Props) {
  const urlParams: Readonly<Params> = useParams()
  const carrierId: string = urlParams[objectIdUrlPart]!
  const locationContext = useLocationContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const transactionsType = parseBalanceTransactionType(searchParams.get(TRANSACTIONS_TYPE_KEY))

  function addTransactionsTypeToSearchParams(transactionsType: string) {
    let newSearchParams: URLSearchParams = searchParams

    newSearchParams = addSearchParam({
      searchParams: newSearchParams,
      key: TRANSACTIONS_TYPE_KEY,
      value: transactionsType
    })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function parseBalanceTransactionType(string: string | null): BalanceTransactionType | undefined {
    if (isBlank(string)) {
      return undefined
    }

    return string as BalanceTransactionType
  }

  return (
    <CarrierBalancePage
      carrierBalancesI18n={carrierBalancesI18n}
      key={locationContext.locationId}
      cacheTransactionsType={addTransactionsTypeToSearchParams}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            carrierId,
            transactionsType
          })
        })
      }}
    />
  )
}
