import NetworkCreateSessionErrorsObject from "./NetworkCreateSessionErrorsObject"
import { Expose, Type } from "class-transformer"
import {
  NetworkCreateSessionError as AdminNetworkCreateSessionError
} from "../../../../../admin/core/data/sources/sessions/SessionsNetworkSource"

export default class NetworkCreateSessionError implements AdminNetworkCreateSessionError {
  @Expose()
  readonly message?: string | null

  @Expose()
  @Type(() => NetworkCreateSessionErrorsObject)
  readonly errorsObject?: NetworkCreateSessionErrorsObject | null
}
