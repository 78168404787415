import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import CarriersDomainDiModule from "./CarriersDomainDiModule"
import CarriersTableProvider from "../../presentation/table-providers/CarriersTableProvider"
import CarrierFormProvider from "../../presentation/form-providers/CarrierFormProvider"
import CarrierInfoProvider from "../../presentation/info-providers/CarrierInfoProvider"
import CarriersI18nDiModule from "./CarriersI18nDiModule"

const moduleKey = "carriers"

export default interface CarriersPresentationDiModule {
  provideCarriersListPage(): React.ReactNode

  provideCarrierInfoPage(): React.ReactNode

  provideCarrierFormPage(): React.ReactNode
}

export class DefaultCarriersPresentationDiModule
  extends DiModule
  implements CarriersPresentationDiModule {

  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly carriersI18nDiModule: CarriersI18nDiModule
  private readonly carriersDomainDiModule: CarriersDomainDiModule

  constructor(parameters: {
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly carriersI18nDiModule: CarriersI18nDiModule
    readonly carriersDomainDiModule: CarriersDomainDiModule
  }) {
    super()
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.carriersI18nDiModule = parameters.carriersI18nDiModule
    this.carriersDomainDiModule = parameters.carriersDomainDiModule
  }

  provideCarriersListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.carriersDomainDiModule.provideGetCarriersUseCase(),
      tableProvider: this.provideCarriersTableProvider()
    })
  }

  private provideCarriersTableProvider(): CarriersTableProvider {
    return this.single(
      CarriersTableProvider.name,
      () => new CarriersTableProvider({
        carriersI18: this.carriersI18nDiModule.provideCarriersI18n()
      })
    )
  }

  provideCarrierFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.carriersDomainDiModule.provideGetCarrierUseCase(),
      createObjectUseCase: this.carriersDomainDiModule.provideCreateCarrierUseCase(),
      updateObjectUseCase: this.carriersDomainDiModule.provideUpdateCarrierUseCase(),
      formProvider: this.provideCarrierFormProvider()
    })
  }

  private provideCarrierFormProvider(): CarrierFormProvider {
    return this.single(
      CarrierFormProvider.name,
      () => new CarrierFormProvider({
        carriersI18: this.carriersI18nDiModule.provideCarriersI18n()
      })
    )
  }

  provideCarrierInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.carriersDomainDiModule.provideGetCarrierUseCase(),
      infoProvider: this.provideCarrierInfoProvider()
    })
  }

  private provideCarrierInfoProvider(): CarrierInfoProvider {
    return this.single(
      CarrierInfoProvider.name,
      () => new CarrierInfoProvider({
        carriersI18: this.carriersI18nDiModule.provideCarriersI18n()
      })
    )
  }
}
