import NetworkCountry from "../entities/countries/NetworkCountry"
import Country from "../../domain/countries/Country"

export default class CountriesMapper {
  mapNetworkToDomain({
    country
  }: {
    readonly country: NetworkCountry
  }): Country {
    const formattingMask = country.phoneNumberFormattingMask
      ?.replaceAll("c", country.callingCode ?? "")
      ?.replaceAll("D", "0")

    return {
      id: country.id,
      name: country.name,
      callingCode: country.callingCode,
      phoneNumberInputMask: country.phoneNumberInputMask,
      phoneNumberFormattingMask: formattingMask
    }
  }
}
