import GetFuelCompanyUsersUseCase from "../../domain/use-cases/fuel-company-users/GetFuelCompanyUsersUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import FuelCompanyUsersDataDiModule from "./FuelCompanyUsersDataDiModule"
import CreateFuelCompanyUserUseCase from "../../domain/use-cases/fuel-company-users/CreateFuelCompanyUserUseCase"
import GetFuelCompanyUserUseCase from "../../domain/use-cases/fuel-company-users/GetFuelCompanyUserUseCase"
import UpdateFuelCompanyUserUseCase from "../../domain/use-cases/fuel-company-users/UpdateFuelCompanyUserUseCase"
import GetFuelCompaniesForFuelCompanyUsersUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForFuelCompanyUsersUseCase"

export default interface FuelCompanyUsersDomainDiModule {
  provideGetFuelCompanyUsersUseCase(): GetFuelCompanyUsersUseCase

  provideGetFuelCompanyUserUseCase(): GetFuelCompanyUserUseCase

  provideCreateFuelCompanyUserUseCase(): CreateFuelCompanyUserUseCase

  provideUpdateFuelCompanyUsersUseCase(): UpdateFuelCompanyUserUseCase

  provideGetFuelCompaniesForFuelCompanyUsersUseCase(): GetFuelCompaniesForFuelCompanyUsersUseCase
}

export class DefaultFuelCompanyUsersDomainDiModule extends DiModule implements FuelCompanyUsersDomainDiModule {
  private readonly fuelCompanyUsersDataDiModule: FuelCompanyUsersDataDiModule

  constructor(parameters: {
    readonly fuelCompanyUsersDataDiModule: FuelCompanyUsersDataDiModule
  }) {
    super()
    this.fuelCompanyUsersDataDiModule = parameters.fuelCompanyUsersDataDiModule
  }

  provideGetFuelCompanyUsersUseCase(): GetFuelCompanyUsersUseCase {
    return this.single(
      GetFuelCompanyUsersUseCase.name,
      () => new GetFuelCompanyUsersUseCase({
        fuelCompanyUsersRepository: this.fuelCompanyUsersDataDiModule.provideFuelCompanyUsersRepository()
      })
    )
  }

  provideGetFuelCompanyUserUseCase(): GetFuelCompanyUserUseCase {
    return this.single(
      GetFuelCompanyUserUseCase.name,
      () => new GetFuelCompanyUserUseCase({
        fuelCompanyUsersRepository: this.fuelCompanyUsersDataDiModule.provideFuelCompanyUsersRepository()
      })
    )
  }

  provideCreateFuelCompanyUserUseCase(): CreateFuelCompanyUserUseCase {
    return this.single(
      CreateFuelCompanyUserUseCase.name,
      () => new CreateFuelCompanyUserUseCase({
        fuelCompanyUsersRepository: this.fuelCompanyUsersDataDiModule.provideFuelCompanyUsersRepository()
      })
    )
  }

  provideUpdateFuelCompanyUsersUseCase(): UpdateFuelCompanyUserUseCase {
    return this.single(
      UpdateFuelCompanyUserUseCase.name,
      () => new UpdateFuelCompanyUserUseCase({
        fuelCompanyUsersRepository: this.fuelCompanyUsersDataDiModule.provideFuelCompanyUsersRepository()
      })
    )
  }

  provideGetFuelCompaniesForFuelCompanyUsersUseCase(): GetFuelCompaniesForFuelCompanyUsersUseCase {
    return this.single(
      GetFuelCompaniesForFuelCompanyUsersUseCase.name,
      () => new GetFuelCompaniesForFuelCompanyUsersUseCase({
        fuelCompaniesForFuelCompanyUsersRepository:
          this.fuelCompanyUsersDataDiModule.provideFuelCompaniesForFuelCompanyUsersRepository()
      })
    )
  }
}
