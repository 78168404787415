import MenusRepository from "../../../../../../admin/features/root/domain/repositories/menus/MenusRepository"
import MenusNetworkSource from "../../sources/menus/MenusNetworkSource"
import { GetObjectResult } from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import Menu from "../../../../../../admin/core/domain/entities/menus/Menu"
import MenuMapper from "../../../../../../admin/core/data/mappers/menus/MenuMapper"

export default class DefaultMenusRepository implements MenusRepository {
  private readonly menusNetworkSource: MenusNetworkSource

  constructor(parameters: {
    readonly menusNetworkSource: MenusNetworkSource
  }) {
    this.menusNetworkSource = parameters.menusNetworkSource
  }

  async getMenu({
    objectId
  }: {
    readonly objectId: string
  }): Promise<GetObjectResult<Menu>> {
    const result = await this.menusNetworkSource.getMenu({
      menuId: objectId
    })

    switch (result.type) {
      case "success": {
        const { data: { menu } } = result

        return {
          type: "success",
          data: new MenuMapper().mapNetworkToDomain({
            menu: menu!
          })
        }
      }
      case "error": {
        return {
          type: "error",
          error: {
            message: result.error.message
          }
        }
      }
      default:
        return result
    }
  }
}
