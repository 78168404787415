import React from "react"
import ObjectFormView, { ObjectFormViewState } from "./ObjectFormView"
import ObjectFormPresenter from "./ObjectFormPresenter"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import ObjectContainerComponent from "../../components/object-container/ObjectContainerComponent"
import ObjectHeaderComponent from "../../components/object-header/ObjectHeaderComponent"
import ObjectFooterComponent from "../../components/object-footer/ObjectFooterComponent"
import FormMenuComponent from "../../components/form-menu/FormMenuComponent"
import ContentLoadingComponent
  from "../../../../../core/presentation/components/content-loading/ContentLoadingComponent"
import FormFieldsComponent from "../../components/form-fields/FormFieldsComponent"
import assertNever from "../../../../../lib/assertNever"
import { Navigate, NavigateFunction } from "react-router-dom"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import ExceptionLocalizer from "../../../../../core/presentation/services/ExceptionLocalizer"
import isPresent from "../../../../../lib/isPresent"
import AppUrlProvider from "../../../../../../app/core/presentation/services/AppUrlProvider"

interface Props<DomainObject, DomainError extends ExecutionError, ErrorsObject> {
  readonly providePresenter: () => ObjectFormPresenter<DomainObject, DomainError, ErrorsObject>
  readonly navigate: NavigateFunction
  readonly coreTextProvider: CoreTextProvider
}

interface State<DomainObject, ErrorsObject> {
  readonly objectFormViewState?: ObjectFormViewState<DomainObject, ErrorsObject>
}

export default class ObjectFormPage<DomainObject, DomainError extends ExecutionError, ErrorsObject>
  extends React.Component<Props<DomainObject, DomainError, ErrorsObject>, State<DomainObject, ErrorsObject>>
  implements ObjectFormView<DomainObject, ErrorsObject> {

  private readonly presenter: ObjectFormPresenter<DomainObject, DomainError, ErrorsObject>

  constructor(props: Props<DomainObject, DomainError, ErrorsObject>) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showObjectFormViewState(objectFormViewState: ObjectFormViewState<DomainObject, ErrorsObject>) {
    this.setState({ objectFormViewState: objectFormViewState })
  }

  render() {
    const { objectFormViewState } = this.state

    if (!objectFormViewState) {
      return <></>
    }

    const appUrlProvider = new AppUrlProvider()
    const {
      title,
      shortTitle,
      isNewObject,
      form
    } = objectFormViewState

    const errorMessage = (() => {
      switch (objectFormViewState.type) {
        case "loading_error": {
          return objectFormViewState.error.message
        }
        case "loading_failure": {
          return new ExceptionLocalizer({
            coreTextProvider: this.props.coreTextProvider
          }).localizeException(objectFormViewState.exception)
        }
        case "loaded": {
          if (isPresent(objectFormViewState.changingError)) {
            return objectFormViewState.changingError.message
          }

          if (isPresent(objectFormViewState.changingException)) {
            return new ExceptionLocalizer({
              coreTextProvider: this.props.coreTextProvider
            }).localizeException(objectFormViewState.changingException)
          }

          return null
        }
        default:
          return null
      }
    })()

    const shouldShowTitle = objectFormViewState.type === "loaded" ||
      objectFormViewState.type === "creating" ||
      objectFormViewState.type === "destroying" ||
      objectFormViewState.type === "updating"

    const isLoading = objectFormViewState.type === "loading" ||
      objectFormViewState.type === "creating" ||
      objectFormViewState.type === "destroying" ||
      objectFormViewState.type === "updating"

    return (
      <>
        <HelmetComponent
          title={shortTitle}
        />
        <form
          onSubmit={(event) => {
            event.preventDefault()
            this.presenter.onSubmitObjectClicked()
          }}
        >
          <ObjectContainerComponent
            headerComponent={(
              <ObjectHeaderComponent
                title={shouldShowTitle ? title : ""}
              />
            )}
            footerComponent={(
              <ObjectFooterComponent>
                <FormMenuComponent
                  isObjectLoaded={objectFormViewState.type !== "loading_error" && objectFormViewState.type !== "loading_failure"}
                  errorMessage={errorMessage}
                  isNewObject={isNewObject}
                  formPermissionsSet={form.getPermissionsSet()}
                  isLoading={isLoading}
                  onDeleteObjectClicked={this.presenter.onDeleteObjectClicked}
                  onCancelClicked={this.presenter.onCancelClicked}
                />
              </ObjectFooterComponent>
            )}
          >
            <>
              {(() => {
                switch (objectFormViewState.type) {
                  case "loading":
                    return <ContentLoadingComponent />
                  case "loading_error":
                  case "loading_failure": {
                    return (
                      <></>
                    )
                  }
                  case "loaded":
                  case "creating":
                  case "updating":
                  case "destroying": {
                    const { fieldViewStates, form } = objectFormViewState
                    return (
                      <FormFieldsComponent
                        fieldViewStates={fieldViewStates}
                        fieldsGroups={form.getFieldsGroups()}
                        isDisabled={objectFormViewState.type !== "loaded"}
                      />
                    )
                  }
                  case "canceling": {
                    this.props.navigate(-1)
                    return (
                      <></>
                    )
                  }
                  case "forbidden": {
                    return <Navigate to={appUrlProvider.buildForbiddenUrl()} replace={true} />
                  }
                  default:
                    assertNever(objectFormViewState)
                }
              })()}
            </>
          </ObjectContainerComponent>
        </form>
      </>
    )
  }
}
