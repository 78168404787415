import { TransformFnParams } from "class-transformer/types/interfaces"
import { TransformationType } from "class-transformer"
import assertNever from "../../../../../admin/lib/assertNever"
import ExtendedDate from "../../../../../admin/lib/date-picker/extended-date/ExtendedDate"

export default function transformDateWithoutTime({
  value,
  type
}: TransformFnParams) {
  switch (type) {
    case TransformationType.CLASS_TO_CLASS:
      return value
    case TransformationType.CLASS_TO_PLAIN:
      return value === null || value === undefined ? value : new ExtendedDate(value as Date).serializeDate()
    case TransformationType.PLAIN_TO_CLASS:
      return value === null || value === undefined ? value : ExtendedDate.parseDate(value)
    default:
      assertNever(type)
  }
}
