import SuccessExecutionResult from "../../../../../core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../core/domain/results/FailureExecutionResult"
import PasswordsRepository from "../../repositories/PasswordsRepository"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import PasswordRecoveryRequestResponseBody from "../../entities/PasswordRecoveryRequestResponseBody"

export interface RequestPasswordRecoveryParameters {
  readonly emailAddress: string
}

export default class RequestPasswordRecoveryUseCase {

  private readonly passwordsRepository: PasswordsRepository

  constructor(parameters: {
    readonly passwordsRepository: PasswordsRepository
  }) {
    this.passwordsRepository = parameters.passwordsRepository
  }

  async call(parameters: RequestPasswordRecoveryParameters): Promise<RequestPasswordRecoveryResult> {
    return await this.passwordsRepository.requestPasswordRecovery(parameters)
  }
}

export type RequestPasswordRecoveryResult =
  SuccessExecutionResult<PasswordRecoveryRequestResponseBody> |
  ErrorExecutionResult<ExecutionError> |
  FailureExecutionResult
