import React from "react"
import ObjectInfoView, { ObjectInfoViewState } from "./ObjectInfoView"
import ObjectInfoPresenter from "./ObjectInfoPresenter"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import ObjectContainerComponent from "../../components/object-container/ObjectContainerComponent"
import ObjectHeaderComponent from "../../components/object-header/ObjectHeaderComponent"
import ObjectFooterComponent from "../../components/object-footer/ObjectFooterComponent"
import ContentLoadingComponent
  from "../../../../../core/presentation/components/content-loading/ContentLoadingComponent"
import assertNever from "../../../../../lib/assertNever"
import InfoMenuComponent from "../../components/info-menu/InfoMenuComponent"
import InfoContentComponent from "../../components/info-content/InfoContentComponent"
import ExceptionLocalizer from "../../../../../core/presentation/services/ExceptionLocalizer"
import { Navigate } from "react-router-dom"
import AppUrlProvider from "../../../../../../app/core/presentation/services/AppUrlProvider"

interface Props<DomainObject> {
  readonly providePresenter: () => ObjectInfoPresenter<DomainObject>
  readonly coreTextProvider: CoreTextProvider
}

interface State {
  readonly objectInfoViewState?: ObjectInfoViewState
}

export default class ObjectInfoPage<DomainObject>
  extends React.Component<Props<DomainObject>, State>
  implements ObjectInfoView {

  private readonly presenter: ObjectInfoPresenter<DomainObject>

  constructor(props: Props<DomainObject>) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showObjectInfoViewState(objectInfoViewState: ObjectInfoViewState) {
    this.setState({ objectInfoViewState })
  }

  render() {
    const { objectInfoViewState } = this.state

    if (!objectInfoViewState) {
      return <></>
    }

    const appUrlProvider = new AppUrlProvider()
    const { title, shortTitle } = objectInfoViewState

    const errorMessage = (() => {
      switch (objectInfoViewState.type) {
        case "loading_error": {
          return objectInfoViewState.error.message
        }
        case "loading_failure": {
          return new ExceptionLocalizer({
            coreTextProvider: this.props.coreTextProvider
          }).localizeException(objectInfoViewState.exception)
        }
        default:
          return null
      }
    })()

    return (
      <>
        <HelmetComponent
          title={shortTitle}
        />
        <ObjectContainerComponent
          headerComponent={(
            <ObjectHeaderComponent
              title={objectInfoViewState.type !== "loaded" ? "" : title}
            />
          )}
          footerComponent={(
            <ObjectFooterComponent>
              <InfoMenuComponent
                errorMessage={errorMessage}
                objectInfoViewState={objectInfoViewState}
              />
            </ObjectFooterComponent>
          )}
        >
          <>
            {(() => {
              switch (objectInfoViewState.type) {
                case "loading":
                  return <ContentLoadingComponent />
                case "loading_error": {
                  return (
                    <></>
                  )
                }
                case "loading_failure": {
                  return (
                    <></>
                  )
                }
                case "loaded": {
                  const { infoRows, links } = objectInfoViewState
                  return (
                    <InfoContentComponent
                      infoRows={infoRows}
                      links={links}
                    />
                  )
                }
                case "forbidden": {
                  return <Navigate to={appUrlProvider.buildForbiddenUrl()} replace={true} />
                }
                default:
                  assertNever(objectInfoViewState)
              }
            })()}
          </>
        </ObjectContainerComponent>
      </>
    )
  }
}
