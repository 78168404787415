import FuelCompanyBalanceTransactionsRepository from "../../repositories/FuelCompanyBalanceTransactionsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompanyBalanceTransaction
  from "../../../../../core/domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"
import FuelCompanyBalanceChangeDocument
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import FuelCompanyBalanceChangeDocumentsRepository from "../../repositories/FuelCompanyBalanceChangeDocumentsRepository"

export interface GetFuelCompanyBalanceChangeDocumentsParameters
  extends GetObjectsParameters {
  readonly fuelCompanyId: string | number
}

export default class GetFuelCompanyBalanceChangeDocumentsUseCase
  implements GetObjectsUseCase<FuelCompanyBalanceChangeDocument> {
  private readonly fuelCompanyBalanceChangeDocumentsRepository: FuelCompanyBalanceChangeDocumentsRepository

  constructor(parameters: {
    readonly fuelCompanyBalanceChangeDocumentsRepository: FuelCompanyBalanceChangeDocumentsRepository
  }) {
    this.fuelCompanyBalanceChangeDocumentsRepository = parameters.fuelCompanyBalanceChangeDocumentsRepository
  }

  async call(
    parameters: GetFuelCompanyBalanceChangeDocumentsParameters
  ): Promise<GetObjectsResult<FuelCompanyBalanceTransaction>> {
    return await this.fuelCompanyBalanceChangeDocumentsRepository.getDocuments(parameters)
  }
}
