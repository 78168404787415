import NetworkClaim from "../entities/claims/NetworkClaim"
import Claim from "../../domain/claims/Claim"

export default class ClaimsMapper {
  mapNetworkToDomain({
    claim
  }: {
    readonly claim: NetworkClaim
  }): Claim {
    return {
      id: claim.id,
      expectedValue: claim.expectedValue,
      formattedExpectedValue: claim.formattedExpectedValue,
      actualValue: claim.actualValue,
      formattedActualValue: claim.formattedActualValue,
      carrierValue: claim.carrierValue,
      formattedCarrierValue: claim.formattedCarrierValue,
      fuelCompanyValue: claim.fuelCompanyValue,
      formattedFuelCompanyValue: claim.formattedFuelCompanyValue
    }
  }

  mapDomainToNetwork({
    claim
  }: {
    readonly claim: Claim
  }): NetworkClaim {
    return {
      carrierValue: claim.carrierValue,
      fuelCompanyValue: claim.fuelCompanyValue
    }
  }
}
