import AppI18 from "../../i18n/AppI18"
import { I18nDiModule } from "../../../../admin/core/di/modules/I18nDiModule"
import AppTextProvider from "../../i18n/AppTextProvider"

export default interface AppI18nDiModule {
  provideAppI18n(): AppI18
}

export class DefaultAppI18nDiModule
  extends I18nDiModule<AppTextProvider>
  implements AppI18nDiModule {

  provideAppI18n(): AppI18 {
    return this.single(
      AppI18.name,
      () => new AppI18(this.getI18nParameters())
    )
  }
}
