import NetworkContactPerson from "../entities/contact-people/NetworkContactPerson"
import ContactPerson from "../../domain/contact-people/ContactPerson"

export default class ContactPeopleMapper {
  mapNetworkToDomain({
    contactPerson
  }: {
    readonly contactPerson: NetworkContactPerson
  }): ContactPerson {
    return {
      id: contactPerson.id,
      name: contactPerson.name,
      email: contactPerson.email,
      phoneNumber: contactPerson.phoneNumber
    }
  }

  mapDomainToNetwork({
    contactPerson
  }: {
    readonly contactPerson: ContactPerson
  }): NetworkContactPerson {
    return {
      name: contactPerson.name,
      email: contactPerson.email,
      phoneNumber: contactPerson.phoneNumber
    }
  }
}
