import React from "react"
import { BooleanFormFieldViewState } from "../../../entities/forms/form-field-by-type/BooleanFormField"
import CheckboxComponent from "../../../../../../lib/checkbox/CheckboxComponent"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"

export default function BooleanFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: BooleanFormFieldViewState
  readonly isDisabled: boolean
}) {
  const theme = useCoreThemeProvider()
  const errors = fieldViewState.getErrors()

  return (
    <div>
      <CheckboxComponent
        name={fieldViewState.getId()}
        label={fieldViewState.getTitle()}
        checkboxClassName={theme.checkbox1ClassName()}
        labelClassName={theme.checkboxLabel1PrimaryClassName()}
        isDisabled={isDisabled || !fieldViewState.getEditable()}
        isChecked={fieldViewState.value || false}
        onChange={fieldViewState.onChange}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
