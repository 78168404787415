import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import RefuellingOrderStatusesForRefuellingOrdersRepository
  from "../../domain/repositories/RefuellingOrderStatusesForRefuellingOrdersRepository"
import {
  GetRefuellingOrderStatusesForRefuellingOrdersParameters
} from "../../domain/use-cases/refuelling-order-statuses/GetRefuellingOrderStatusesForRefuellingOrdersUseCase"
import { RefuellingOrderStatusSelectOption } from "../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import RefuellingOrderStatusesStaticSource
  from "../../../../core/data/sources/static/RefuellingOrderStatusesStaticSource"

export default class DefaultRefuellingOrderStatusesForRefuellingOrdersRepository
  implements RefuellingOrderStatusesForRefuellingOrdersRepository {
  private readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource

  constructor(parameters: {
    readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource
  }) {
    this.refuellingOrderStatusesStaticSource = parameters.refuellingOrderStatusesStaticSource
  }

  async getRefuellingOrderStatuses({
      ids,
      query
    }: GetRefuellingOrderStatusesForRefuellingOrdersParameters
  ): Promise<GetObjectsResult<RefuellingOrderStatusSelectOption>> {
    const statuses = await this.refuellingOrderStatusesStaticSource.getRefuellingOrderStatuses({
      ids, query
    })

    return {
      type: "success",
      data: {
        objects: statuses,
        page: {
          hasMore: false
        }
      }
    }
  }
}
