import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import DriversRepository from "../../repositories/DriversRepository"
import User from "../../../../../core/domain/users/User"

export interface GetDriverParameters {
  readonly objectId: string
}

export default class GetDriverUseCase implements GetObjectUseCase<User> {
  private readonly driversRepository: DriversRepository

  constructor(parameters: {
    readonly driversRepository: DriversRepository
  }) {
    this.driversRepository = parameters.driversRepository
  }

  async call(parameters: GetDriverParameters): Promise<GetObjectResult<User>> {
    return await this.driversRepository.getDriver(parameters)
  }
}
