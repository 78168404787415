import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import LegalEntityErrorsObjectAttributes from "../../../domain/legal-entities/LegalEntityErrorsObjectAttributes"

export default class NetworkLegalEntityErrorsObjectAttributes implements LegalEntityErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly individualTaxpayerNumber?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly taxRegistrationReasonCode?: NetworkAttributeError[] | null
}
