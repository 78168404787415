import NetworkPasswordRecoveryRequestResponseBody
  from "../../../../../../app/core/data/entities/password-recovery-request/response-bodies/NetworkPasswordRecoveryRequestResponseBody"
import PasswordRecoveryRequestResponseBody from "../../../domain/entities/PasswordRecoveryRequestResponseBody"

export default class PasswordRecoveryRequestMapper {
  mapNetworkToDomain({
    passwordRecoveryRequestResponseBody
  }: {
    readonly passwordRecoveryRequestResponseBody: NetworkPasswordRecoveryRequestResponseBody
  }): PasswordRecoveryRequestResponseBody {
    return {
      message: passwordRecoveryRequestResponseBody.message
    }
  }
}
