import React, { ReactNode } from "react"
import styles from "./ButtonComponent.module.scss"
import ButtonComponentStyles from "./ButtonComponentStyles"

export enum ButtonType {
  BUTTON = "button",
  SUBMIT = "submit"
}

export default function ButtonComponent({
  title = "",
  icon,
  type = ButtonType.BUTTON,
  isSelected = false,
  isDisabled = false,
  componentStyles = {},
  onClick = () => undefined,
  onMouseDown
}: {
  readonly title?: string
  readonly icon?: ReactNode
  readonly type?: ButtonType
  readonly isDisabled?: boolean
  readonly isSelected?: boolean
  readonly componentStyles?: ButtonComponentStyles
  readonly onClick?: (event: React.MouseEvent) => void
  readonly onMouseDown?: (event: React.MouseEvent) => void
}) {
  const {
    classes: {
      default: buttonDefaultClassName = "",
      selected: buttonSelectedClassName = ""
    } = {}
  } = componentStyles

  return (
    <button
      type={type}
      className={`${styles.root} ${title !== "" ? styles.textButton : ""} ${buttonDefaultClassName} ${isSelected ? buttonSelectedClassName : ""}`}
      disabled={isDisabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {icon}
      {title}
    </button>
  )
}
