import React, { useState } from "react"
import styles from "./ListHeaderComponent.module.scss"
import Table from "../../entities/tables/Table"
import TextInputComponent from "../../../../../lib/text-input/TextInputComponent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import LinkButtonComponent from "../../../../../lib/button/LinkButtonComponent"
import Filter from "../../entities/filters/Filter"
import ButtonComponent from "../../../../../lib/button/ButtonComponent"
import DropdownComponent, { DropdownAlign, DropdownWidthMode } from "../../../../../lib/dropdown/DropdownComponent"
import CoreTheme from "../../../../../core/presentation/theme/CoreTheme"
import { FilterViewState, SearchViewState } from "../../pages/objects-list/ObjectsListView"
import FilterComponent from "../../../../../core/presentation/components/filter/FilterComponent"
import isBlank from "../../../../../lib/isBlank"
import isPresent from "../../../../../lib/isPresent"

interface ListPageHeaderComponentProps<DomainObject, FilterObject = never> {
  readonly table: Table<DomainObject, FilterObject>
  readonly searchViewState?: SearchViewState
  readonly filterViewState?: FilterViewState
  readonly hasAppliedFilters: boolean
  readonly searchByQueryIsEnable: boolean
  readonly onQueryChanged: (query: string) => void
  readonly onSearchRequested: () => void
  readonly onResetFilterClicked: () => void
  readonly onApplyFilterClicked: () => void
}

export default function ListHeaderComponent<DomainObject, FilterObject = never>({
  table,
  searchViewState,
  filterViewState,
  hasAppliedFilters,
  searchByQueryIsEnable,
  onQueryChanged,
  onSearchRequested,
  onResetFilterClicked,
  onApplyFilterClicked
}: ListPageHeaderComponentProps<DomainObject, FilterObject>) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const theme: CoreTheme = useCoreThemeProvider()
  const newObjectPageUrl: string | undefined = table.getNewObjectPageUrl()
  const canCreateNewObject: boolean | undefined = table.getCanCreateNewObject()
  const needShowCreateButton = isPresent(newObjectPageUrl) && canCreateNewObject === true
  const filter: Filter<FilterObject> | undefined = table.getFilter()
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const hasSelectedFilters = (() => {
    switch (filterViewState?.type) {
      case "initializing":
        return false
      case "idle":
        return hasAppliedFilters
      default:
        return false
    }
  })()

  const handleInputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearchRequested()
    }
  }

  const handleFilterButtonClick = () => {
    setIsFilterVisible(!isFilterVisible)
  }

  const handleApplyFilterButtonClick = () => {
    onApplyFilterClicked()
    setIsFilterVisible(false)
  }

  const handleResetFilterButtonClick = () => {
    onResetFilterClicked()
    setIsFilterVisible(false)
  }

  const preventFilterDropdownCloseOnFilterButtonDown = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  const filterButtonIsVisible = (() => {
    if (
      isBlank(filter) ||
      isBlank(filterViewState)
    ) {
      return false
    }

    switch (filterViewState.type) {
      case "initializing":
        return false
      case "idle":
        return filterViewState.filterFieldViewStates
          .filter((state) => state.getVisible())
          .length > 0
    }
  })()

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={`${styles.header} ${theme.headline1PrimaryClassName()}`}>
          {table.getTitle()}
        </div>
        {searchViewState && (
          <TextInputComponent
            value={searchViewState.type === "idle" ? searchViewState.query : ""}
            onChange={onQueryChanged}
            onKeyDown={handleInputOnKeyDown}
            className={styles.search}
            componentStyle={theme.searchBar1PrimaryStyle()}
            placeholder={coreTextProvider.enterSearchQuery()}
            isDisabled={searchViewState.type === "initializing" || !searchByQueryIsEnable}
          />
        )}
        {filter && filterViewState && filterButtonIsVisible && (
          <div>
            <ButtonComponent
              icon={<div className={theme.filterIconClassName()} />}
              componentStyles={theme.buttonOutlined1PrimaryStyle()}
              isSelected={hasSelectedFilters}
              onClick={handleFilterButtonClick}
              onMouseDown={preventFilterDropdownCloseOnFilterButtonDown}
              isDisabled={filterViewState.type === "initializing"}
            />
            {filterViewState.type === "idle" && isFilterVisible && (
              <DropdownComponent
                className={`${styles.filter} ${theme.dropdownClassName()}`}
                isVisible={isFilterVisible}
                hideOnClickOutside={true}
                hasScroll={true}
                requestHide={() => setIsFilterVisible(false)}
                widthMode={DropdownWidthMode.WRAP_CONTENT}
                align={DropdownAlign.RIGHT}
              >
                <FilterComponent
                  filterFieldViewStates={filterViewState.filterFieldViewStates}
                  onResetFilterClicked={handleResetFilterButtonClick}
                  onApplyFilterClicked={handleApplyFilterButtonClick}
                />
              </DropdownComponent>
            )}
          </div>
        )}
        {needShowCreateButton && (
          <LinkButtonComponent
            title={coreTextProvider.goToNewObject()}
            componentStyles={theme.buttonOutlined1PrimaryStyle()}
            url={newObjectPageUrl}
          />
        )}
      </div>
    </div>
  )
}
