import NetworkFuelTransaction from "../entities/fuel-transactions/NetworkFuelTransaction"
import FuelTransaction from "../../domain/fuel-transactions/FuelTransaction"
import RefuellingOrdersMapper from "./RefuellingOrdersMapper"

export default class FuelTransactionsMapper {
  mapNetworkToDomain({
    fuelTransaction
  }: {
    readonly fuelTransaction: NetworkFuelTransaction
  }): FuelTransaction {
    const {
      refuellingOrder
    } = fuelTransaction

    return {
      id: fuelTransaction.id,
      carrierCommercialTermId: fuelTransaction.carrierCommercialTermId,
      fuelCompanyCommercialTermId: fuelTransaction.fuelCompanyCommercialTermId,
      fuelValue: fuelTransaction.fuelValue,
      moneyValue: fuelTransaction.moneyValue,
      costValue: fuelTransaction.costValue,
      status: fuelTransaction.status,
      formattedFuelValue: fuelTransaction.formattedFuelValue,
      formattedMoneyValue: fuelTransaction.formattedMoneyValue,
      formattedCostValue: fuelTransaction.formattedCostValue,
      refuellingOrder: refuellingOrder && new RefuellingOrdersMapper().mapNetworkToDomain({
        refuellingOrder: refuellingOrder,
        refuellingOrderStatuses: []
      })
    }
  }
}
