import React from "react"
import { InfoFormFieldViewState } from "../../../entities/forms/form-field-by-type/InfoFormField"
import { InfoInputComponent } from "../../../../../../lib/info/InfoInputComponent"

export default function InfoFormFieldComponent({
  fieldViewState
}: {
  readonly fieldViewState: InfoFormFieldViewState
}) {
  const title = fieldViewState.getTitle()
  const text = fieldViewState.value

  return (
    <InfoInputComponent
      title={title}
      text={text}
    />
  )
}
