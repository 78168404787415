import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelsRequestQuery from "../../entities/fuels/request-queries/NetworkFuelsRequestQuery"
import NetworkFuelsResponseBody from "../../entities/fuels/response-bodies/NetworkFuelsResponseBody"
import NetworkFuelsRequestFilter from "../../entities/fuels/request-queries/NetworkFuelsRequestFilter"

const basePath = "/api/admin/fuels"

export default class FuelsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getFuels({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkFuelsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetFuelsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkFuelsRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetFuelsNetworkResult =
  SuccessExecutionResult<NetworkFuelsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
