import Presenter from "../../../../../../admin/lib/presenter/Presenter"
import CurrentFuelCompanyBalanceView, {
  AbstractCurrentFuelCompanyBalanceHeaderViewState,
  AbstractCurrentFuelCompanyBalanceListViewState,
  CurrentFuelCompanyBalanceHeaderViewState, CurrentFuelCompanyBalanceListItem, CurrentFuelCompanyBalanceListViewState
} from "./CurrentFuelCompanyBalanceView"
import SubscribeToObjectsEventsUseCase
  from "../../../../../../admin/features/objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase
  from "../../../../../../admin/features/objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsEvent, {
  ObjectsEventCallback
} from "../../../../../../admin/features/objects/domain/entities/ObjectsEvent"
import Page from "../../../../../../admin/core/domain/entities/pages/Page"
import autoBind from "auto-bind"
import assertNever from "../../../../../../admin/lib/assertNever"
import ExecutionError from "../../../../../../admin/core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../../admin/core/domain/exceptions/ApplicationException"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import { GetObjectsResult } from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import Table from "../../../../../../admin/features/objects/presentation/entities/tables/Table"
import CurrentFuelCompanyBalanceChangeDocumentsTableProvider
  from "../../table-providers/CurrentFuelCompanyBalanceChangeDocumentsTableProvider"
import CurrentFuelCompanyBalanceTransactionsTableProvider
  from "../../table-providers/CurrentFuelCompanyBalanceTransactionsTableProvider"
import FuelCompanyBalanceTransaction
  from "../../../../../core/domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import CheckPermissionDeniedUseCase
  from "../../../../../../admin/core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import TableProviderUtils from "../../../../../../admin/features/objects/presentation/providers/TableProviderUtils"
import FuelCompanyBalanceChangeDocument
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import GetCurrentFuelCompanyBalanceChangeDocumentsUseCase
  from "../../../domain/use-cases/fuel-company-balance-change-documents/GetCurrentFuelCompanyBalanceChangeDocumentsUseCase"
import GetCurrentFuelCompanyBalanceTransactionsUseCase
  from "../../../domain/use-cases/fuel-company-balance-transactions/GetCurrentFuelCompanyBalanceTransactionsUseCase"
import GetFuelCompanyForCurrentFuelCompanyBalancesUseCase
  from "../../../domain/use-cases/fuel-companies/GetFuelCompanyForCurrentFuelCompanyBalancesUseCase"

export default class CurrentFuelCompanyBalancePresenter extends Presenter<CurrentFuelCompanyBalanceView> {

  private readonly getCurrentFuelCompanyBalanceChangeDocumentsUseCase:
    GetCurrentFuelCompanyBalanceChangeDocumentsUseCase

  private readonly getCurrentFuelCompanyBalanceTransactionsUseCase:
    GetCurrentFuelCompanyBalanceTransactionsUseCase

  private readonly getFuelCompanyUseCase: GetFuelCompanyForCurrentFuelCompanyBalancesUseCase
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly fuelCompanyBalanceTransactionsTable: Table<FuelCompanyBalanceTransaction>
  private readonly fuelCompanyBalanceChangeDocumentsTable: Table<FuelCompanyBalanceChangeDocument>
  private objectsEventsCallback?: ObjectsEventCallback
  private fuelCompanyBalanceListViewState?: CurrentFuelCompanyBalanceListViewState
  private fuelCompanyBalanceHeaderViewState?: CurrentFuelCompanyBalanceHeaderViewState
  private fuelCompany?: FuelCompany
  private items?: CurrentFuelCompanyBalanceListItem[]
  private balanceTransactionType: BalanceTransactionType
  private page?: Page
  private needReloadObjectsOnReAttach: boolean
  private isNextPageLoading = false
  private lastObjectsLoadingTimestamp?: number
  private fuelCompanyBalanceChangeDocumentsTableProviderUtils : TableProviderUtils<FuelCompanyBalanceChangeDocument>
  private fuelCompanyBalanceTransactionsTableProviderUtils : TableProviderUtils<FuelCompanyBalanceTransaction>

  constructor(parameters: {
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
    readonly getCurrentFuelCompanyBalanceChangeDocumentsUseCase:
      GetCurrentFuelCompanyBalanceChangeDocumentsUseCase
    readonly getCurrentFuelCompanyBalanceTransactionsUseCase:
      GetCurrentFuelCompanyBalanceTransactionsUseCase
    readonly getFuelCompanyUseCase: GetFuelCompanyForCurrentFuelCompanyBalancesUseCase
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly transactionsType: BalanceTransactionType | null | undefined
    readonly fuelCompanyBalanceChangeDocumentsTableProvider: CurrentFuelCompanyBalanceChangeDocumentsTableProvider
    readonly fuelCompanyBalanceTransactionsTableProvider: CurrentFuelCompanyBalanceTransactionsTableProvider
  }) {
    super()

    autoBind(this)

    this.balanceTransactionType = parameters.transactionsType ?? BalanceTransactionType.REPLENISHMENT
    this.getCurrentFuelCompanyBalanceTransactionsUseCase =
      parameters.getCurrentFuelCompanyBalanceTransactionsUseCase
    this.getCurrentFuelCompanyBalanceChangeDocumentsUseCase =
      parameters.getCurrentFuelCompanyBalanceChangeDocumentsUseCase
    this.getFuelCompanyUseCase = parameters.getFuelCompanyUseCase
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.needReloadObjectsOnReAttach = false
    this.fuelCompanyBalanceChangeDocumentsTable =
      parameters.fuelCompanyBalanceChangeDocumentsTableProvider.getTable()
    this.fuelCompanyBalanceTransactionsTable =
      parameters.fuelCompanyBalanceTransactionsTableProvider.getTable()

    this.fuelCompanyBalanceChangeDocumentsTableProviderUtils =
      this.buildFuelCompanyBalanceChangeDocumentsTableProviderUtils(parameters)
    this.fuelCompanyBalanceChangeDocumentsTableProviderUtils.initTableByPermissions()

    this.fuelCompanyBalanceTransactionsTableProviderUtils =
      this.buildFuelCompanyBalanceTransactionsTableProviderUtils(parameters)
    this.fuelCompanyBalanceTransactionsTableProviderUtils.initTableByPermissions()
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    const isWriteOffTableVisible = this.fuelCompanyBalanceChangeDocumentsTableProviderUtils.isTableVisibleByPermission()
    const isReplenishmentTableVisible = this.fuelCompanyBalanceTransactionsTableProviderUtils
      .isTableVisibleByPermission()

    if (!isWriteOffTableVisible || !isReplenishmentTableVisible) {
      this.setAndShowForbiddenTransactionsListViewState()
      return
    }

    const initialize = async(): Promise<void> => {
      this.setAndShowInitializingListViewState()

      this.subscribeToObjectsEvents()

      await this.loadAndShowInitialData()
    }

    initialize()
      .then()
  }

  protected onViewReAttach() {
    super.onViewReAttach()

    this.showFuelCompanyHeaderViewState()
    this.showTransactionsListViewState()

    // TODO: reload objects if related objects changed, not only current object-form type.
    if (this.getNeedReloadObjectsOnReAttach()) {
      this.setNeedReloadObjectsOnReAttach(false)
      this.loadAndShowInitialData()
        .then()
    }
  }

  protected onDestroy() {
    super.onDestroy()
    this.unsubscribeFromObjectsEvents()
  }

  onNextPageRequested() {
    const hasMore = this.page?.hasMore
    if (hasMore && !this.isNextPageLoading) {
      this.loadAndShowTransactionsNextPage()
        .then()
    }
  }

  onRetryLoadClicked() {
    const itemsSize = this.items?.length ?? 0
    if (itemsSize > 0) {
      this.loadAndShowTransactionsNextPage().then()
    } else {
      this.loadAndShowTransactionsFirstPage().then()
    }
  }

  onSegmentSelected(segmentItemId: string) {
    this.balanceTransactionType = segmentItemId as BalanceTransactionType

    this.setAndShowLoadedFuelCompanyHeaderViewState()
    this.loadAndShowTransactionsFirstPage()
      .then()
    this.cacheTransactionsTypeInView()
  }

  private subscribeToObjectsEvents() {
    this.objectsEventsCallback = this.subscribeToObjectsEventsUseCase.call((event: ObjectsEvent) => {
      switch (event.type) {
        case "created":
        case "updated":
        case "destroyed":
          this.setNeedReloadObjectsOnReAttach(true)
          break
        default:
          assertNever(event)
      }
    })
  }

  private unsubscribeFromObjectsEvents() {
    this.objectsEventsCallback && this.unsubscribeFromObjectsEventsUseCase.call(this.objectsEventsCallback)
  }

  private async loadAndShowInitialData() {
    await this.loadAndShowFuelCompany()
    await this.loadAndShowTransactionsFirstPage()
  }

  private async loadAndShowFuelCompany(): Promise<void> {
    this.setAndShowInitializingFuelCompanyHeaderViewState()

    const result = await this.getFuelCompanyUseCase.call({})

    switch (result.type) {
      case "error":
        this.setAndShowLoadingErrorFuelCompanyHeaderViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowLoadingFailureFuelCompanyHeaderViewState({ exception: result.exception })
        break
      case "success":
        this.setFuelCompany(result.data)
        this.setAndShowLoadedFuelCompanyHeaderViewState()
        break
    }
  }

  private async loadAndShowTransactionsFirstPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.setItems(undefined)
    this.setPage(undefined)
    this.setAndShowLoadingListViewState()

    const result: GetObjectsResult<CurrentFuelCompanyBalanceListItem> = await (async() => {
      switch (this.balanceTransactionType) {
        case BalanceTransactionType.WRITE_OFF:
          return await this.getCurrentFuelCompanyBalanceChangeDocumentsUseCase.call({})
        case BalanceTransactionType.REPLENISHMENT:
          return await this.getCurrentFuelCompanyBalanceTransactionsUseCase.call({})
      }
    })()

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setAndShowLoadingErrorListViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowLoadingFailureListViewState({ exception: result.exception })
        break
      case "success":
        this.setItems(result.data.objects)
        this.setPage(result.data.page)
        this.setAndShowLoadedListViewState()
        break
    }
  }

  private async loadAndShowTransactionsNextPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.isNextPageLoading = true
    this.setAndShowNextPageLoadingListViewState()

    const lastTransactionIndex: number = this.items!.length - 1
    const lastTransaction = this.items![lastTransactionIndex]

    const result: GetObjectsResult<CurrentFuelCompanyBalanceListItem> = await (async() => {
      switch (this.balanceTransactionType) {
        case BalanceTransactionType.WRITE_OFF:
          return await this.getCurrentFuelCompanyBalanceChangeDocumentsUseCase.call({
            pagination: {
              id: this.fuelCompanyBalanceChangeDocumentsTable.getObjectId(lastTransaction)
            }
          })
        case BalanceTransactionType.REPLENISHMENT:
          return await this.getCurrentFuelCompanyBalanceTransactionsUseCase.call({
            pagination: {
              id: this.fuelCompanyBalanceTransactionsTable.getObjectId(lastTransaction)
            }
          })
      }
    })()

    this.isNextPageLoading = false

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setAndShowNextPageLoadingErrorListViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowNextPageLoadingFailureListViewState({ exception: result.exception })
        break
      case "success":
        this.setItems([...this.items!, ...result.data.objects])
        this.setPage(result.data.page)
        this.setAndShowLoadedListViewState()
        break
    }
  }

  private setAndShowInitializingFuelCompanyHeaderViewState() {
    this.setAndShowFuelCompanyHeaderViewState({
      ...this.getAbstractFuelCompanyHeaderViewStateParameters(),
      type: "initializing"
    })
  }

  private setAndShowLoadingErrorFuelCompanyHeaderViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowFuelCompanyHeaderViewState({
      ...this.getAbstractFuelCompanyHeaderViewStateParameters(),
      type: "loading_error",
      error
    })
  }

  private setAndShowLoadingFailureFuelCompanyHeaderViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowFuelCompanyHeaderViewState({
      ...this.getAbstractFuelCompanyHeaderViewStateParameters(),
      type: "loading_failure",
      exception
    })
  }

  private setAndShowLoadedFuelCompanyHeaderViewState() {
    this.setAndShowFuelCompanyHeaderViewState({
      ...this.getAbstractFuelCompanyHeaderViewStateParameters(),
      type: "loaded",
      fuelCompany: this.fuelCompany!,
      transactionType: this.balanceTransactionType
    })
  }

  private getAbstractFuelCompanyHeaderViewStateParameters(): AbstractCurrentFuelCompanyBalanceHeaderViewState {
    return {
      transactionType: this.balanceTransactionType
    }
  }

  private setAndShowFuelCompanyHeaderViewState(fuelCompanyBalanceHeaderViewState: CurrentFuelCompanyBalanceHeaderViewState) {
    this.setFuelCompanyHeaderViewState(fuelCompanyBalanceHeaderViewState)
    this.showFuelCompanyHeaderViewState()
  }

  private showFuelCompanyHeaderViewState() {
    this.fuelCompanyBalanceHeaderViewState && this.getView()
      ?.showCurrentFuelCompanyBalanceHeaderViewState(this.fuelCompanyBalanceHeaderViewState)
  }

  private setFuelCompanyHeaderViewState(fuelCompanyBalanceHeaderViewState: CurrentFuelCompanyBalanceHeaderViewState) {
    this.fuelCompanyBalanceHeaderViewState = fuelCompanyBalanceHeaderViewState
  }

  private setAndShowInitializingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "initializing"
    })
  }

  private setAndShowLoadingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading"
    })
  }

  private setAndShowLoadingErrorListViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading_error",
      error
    })
  }

  private setAndShowLoadingFailureListViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading_failure",
      exception
    })
  }

  private setAndShowLoadedListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loaded",
      items: this.items!,
      page: this.page!
    })
  }

  private setAndShowNextPageLoadingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading",
      items: this.items!
    })
  }

  private setAndShowNextPageLoadingErrorListViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading_error",
      items: this.items!,
      error
    })
  }

  private setAndShowNextPageLoadingFailureListViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading_failure",
      items: this.items!,
      exception
    })
  }

  private setAndShowForbiddenTransactionsListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "forbidden"
    })
  }

  private getAbstractListViewStateParameters(): AbstractCurrentFuelCompanyBalanceListViewState {
    return {
      table: (() => {
        switch (this.balanceTransactionType) {
          case BalanceTransactionType.WRITE_OFF:
            return this.fuelCompanyBalanceChangeDocumentsTable
          case BalanceTransactionType.REPLENISHMENT:
            return this.fuelCompanyBalanceTransactionsTable
        }
      })()
    }
  }

  private setAndShowListViewState(listViewState: CurrentFuelCompanyBalanceListViewState) {
    this.setListViewState(listViewState)
    this.showTransactionsListViewState()
  }

  private showTransactionsListViewState() {
    this.fuelCompanyBalanceListViewState && this.getView()
      ?.showCurrentFuelCompanyBalanceListViewState(this.fuelCompanyBalanceListViewState)
  }

  private setListViewState(listViewState: CurrentFuelCompanyBalanceListViewState) {
    this.fuelCompanyBalanceListViewState = listViewState
  }

  private cacheTransactionsTypeInView() {
    this.getView()
      ?.cacheSelectedTransactionsType(this.balanceTransactionType)
  }

  private setItems(items: CurrentFuelCompanyBalanceListItem[] | undefined) {
    this.items = items
  }

  private setFuelCompany(fuelCompany: FuelCompany) {
    this.fuelCompany = fuelCompany
  }

  private setPage(page: Page | undefined) {
    this.page = page
  }

  private getNeedReloadObjectsOnReAttach() {
    return this.needReloadObjectsOnReAttach
  }

  private setNeedReloadObjectsOnReAttach(needReloadObjectsOnReAttach: boolean) {
    this.needReloadObjectsOnReAttach = needReloadObjectsOnReAttach
  }

  private buildFuelCompanyBalanceChangeDocumentsTableProviderUtils(parameters: {
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase,
    readonly fuelCompanyBalanceChangeDocumentsTableProvider: CurrentFuelCompanyBalanceChangeDocumentsTableProvider
  }) {
    return new TableProviderUtils<FuelCompanyBalanceChangeDocument>({
      table: this.fuelCompanyBalanceChangeDocumentsTable,
      tableProvider: parameters.fuelCompanyBalanceChangeDocumentsTableProvider,
      checkPermissionDeniedUseCase: parameters.checkPermissionDeniedUseCase
    })
  }

  private buildFuelCompanyBalanceTransactionsTableProviderUtils(parameters: {
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase,
    readonly fuelCompanyBalanceTransactionsTableProvider: CurrentFuelCompanyBalanceTransactionsTableProvider
  }) {
    return new TableProviderUtils<FuelCompanyBalanceTransaction>({
      table: this.fuelCompanyBalanceTransactionsTable,
      tableProvider: parameters.fuelCompanyBalanceTransactionsTableProvider,
      checkPermissionDeniedUseCase: parameters.checkPermissionDeniedUseCase
    })
  }
}
