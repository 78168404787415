import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import GasStationsRepository from "../../repositories/GasStationsRepository"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"

export interface GetGasStationParameters {
  readonly objectId: string
}

export default class GetGasStationUseCase implements GetObjectUseCase<GasStation> {
  private readonly gasStationsRepository: GasStationsRepository

  constructor(parameters: {
    readonly gasStationsRepository: GasStationsRepository
  }) {
    this.gasStationsRepository = parameters.gasStationsRepository
  }

  async call(parameters: GetGasStationParameters): Promise<GetObjectResult<GasStation>> {
    return await this.gasStationsRepository.getGasStation(parameters)
  }
}
