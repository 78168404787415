import React, { createContext, useContext } from "react"
import RootTheme from "../../theme/RootTheme"

export const RootThemeProviderContext: React.Context<RootTheme | null> =
  createContext<RootTheme | null>(null)

export function useRootThemeProvider(): RootTheme {
  return useContext(RootThemeProviderContext)!
}

export interface CoreThemeProviderProps {
  readonly children?: React.ReactNode
  readonly theme: RootTheme
}

export function RootThemeProvider({
  children,
  theme
}: CoreThemeProviderProps) {
  return (
    <RootThemeProviderContext.Provider value={theme}>
      {children}
    </RootThemeProviderContext.Provider>
  )
}
