import AuthorizationErrorRepository
  from "../../../../../../admin/features/root/domain/repositories/authorization-error/AuthorizationErrorRepository"
import {
  AuthorizationErrorEventCallback
} from "../../../../../../admin/features/root/domain/entities/AuthorizationErrorEvent"
import AuthorizationErrorNetworkSource from "../../sources/authorization-error/AuthorizationErrorNetworkSource"

export default class DefaultAuthorizationErrorRepository implements AuthorizationErrorRepository {
  private authorizationErrorNetworkSource: AuthorizationErrorNetworkSource

  constructor(parameters: {
    readonly authorizationErrorNetworkSource: AuthorizationErrorNetworkSource
  }) {
    this.authorizationErrorNetworkSource = parameters.authorizationErrorNetworkSource
  }

  subscribeToAuthorizationError(callback: AuthorizationErrorEventCallback): void {
    this.authorizationErrorNetworkSource.subscribeToAuthorizationError(callback)
  }
}
