import { Expose } from "class-transformer"
import NetworkGasStationsRequestFilter from "./NetworkGasStationsRequestFilter"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkGasStationsRequestQuery {
  @Expose()
  readonly filter?: NetworkGasStationsRequestFilter | null

  @Expose()
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkGasStationsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
