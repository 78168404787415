export default class Presenter<View> {
  private view?: View
  private isFirstViewAttach = true

  attachView(view: View) {
    this.view = view

    if (this.isFirstViewAttach) {
      this.onFirstViewAttach()
      this.isFirstViewAttach = false
    } else {
      this.onViewReAttach()
    }
  }

  detachView() {
    this.onDetachView()
    this.view = undefined
  }

  destroy() {
    this.onDestroy()
  }

  protected getView(): View | undefined {
    return this.view
  }

  protected onFirstViewAttach() {
    // do something
  }

  protected onViewReAttach() {
    // do something
  }

  protected onDestroy() {
    // do something
  }

  protected onDetachView() {
    // do something
  }
}
