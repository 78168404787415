import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForCarrierUsersRepository from "../../repositories/CarriersForCarrierUsersRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForCarrierUsersParameters extends GetObjectsParameters {
}

export default class GetCarriersForCarrierUsersUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForCarrierUsersRepository: CarriersForCarrierUsersRepository

  constructor(parameters: {
    readonly carriersForCarrierUsersRepository: CarriersForCarrierUsersRepository
  }) {
    this.carriersForCarrierUsersRepository = parameters.carriersForCarrierUsersRepository
  }

  async call(parameters: GetCarriersForCarrierUsersParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForCarrierUsersRepository.getCarriers(parameters)
  }
}
