import { Expose, Type } from "class-transformer"
import ChangingType from "../../../domain/changing-log-items/ChangingType"
import NetworkUser from "../users/NetworkUser"
import NetworkTrip from "../trips/NetworkTrip"

export default class NetworkChangingLogItem {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Number)
  readonly parentId?: number

  @Expose()
  @Type(() => String)
  readonly parentType?: string

  @Expose()
  @Type(() => NetworkTrip)
  readonly parentTrip?: NetworkTrip

  @Expose()
  @Type(() => Date)
  readonly createdAt?: Date | null

  @Expose()
  @Type(() => Number)
  readonly objectId?: number | null

  @Expose()
  @Type(() => String)
  readonly objectType?: string | null

  @Expose()
  @Type(() => String)
  readonly objectName?: string | null

  @Expose()
  @Type(() => String)
  readonly changingType?: ChangingType | null

  @Expose()
  @Type(() => String)
  readonly columnName?: string | null

  @Expose()
  @Type(() => String)
  readonly oldValue?: string | null

  @Expose()
  @Type(() => String)
  readonly oldValueName?: string | null

  @Expose()
  @Type(() => String)
  readonly newValue?: string | null

  @Expose()
  @Type(() => String)
  readonly newValueName?: string | null

  @Expose()
  @Type(() => String)
  readonly valueType?: string | null

  @Expose()
  @Type(() => Number)
  readonly changedById?: number | null

  @Expose()
  @Type(() => NetworkUser)
  readonly changedBy?: NetworkUser | null
}
