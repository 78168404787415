import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import isPresent from "../../../../../../lib/isPresent"
import isBlank from "../../../../../../lib/isBlank"
import DateTimeFormatter from "../../../../../../lib/DateTimeFormatter"

export default class DateFormField<DomainObject, ErrorsObject> extends FormField<DomainObject, ErrorsObject> {
  private readonly timeZone?: string
  private readonly withTime: boolean
  private readonly getValue: (object: DomainObject) => Date | null | undefined
  private readonly setValue: (object: DomainObject, value: Date | null | undefined) => DomainObject

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly timeZone?: string
    readonly withTime?: boolean
    readonly getValue: (object: DomainObject) => Date | null | undefined
    readonly setValue: (object: DomainObject, value: Date | null | undefined) => DomainObject
  }) {
    super(parameters)
    this.withTime = parameters.withTime ?? false
    this.timeZone = parameters.timeZone
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    return new DateFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      value: this.getValue(object),
      timeZone: this.timeZone,
      withTime: this.withTime,
      onChange: (value: Date | null | undefined) => {
        this.setObject(this.setValue(object, value))
        this.setAndShowLoadedObjectViewState()
      }
    })
  }

  // TODO: Serialize date filter
  async init({
    object,
    serializedValue
  }: {
    readonly object: DomainObject
    readonly serializedValue: string | null | undefined
  }): Promise<void> {
    if (isPresent(serializedValue)) {
      this.setObject(this.setValue(object, new Date(Date.parse(serializedValue))))
    }
  }

  serializeValue(object: DomainObject): string | null | undefined {
    const value: Date | null | undefined = this.getValue(object)
    return isBlank(value) ? value : new DateTimeFormatter(value).formatISODate()
  }
}

export class DateFormFieldViewState extends FormFieldViewState {
  readonly value: Date | null | undefined
  readonly timeZone?: string
  readonly withTime: boolean
  onChange: (value: Date | null | undefined) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly onChange: (value: Date | null | undefined) => void
    readonly value: Date | null | undefined
    readonly timeZone?: string
    readonly withTime: boolean
  }) {
    super(parameters)
    this.withTime = parameters.withTime ?? false
    this.timeZone = parameters.timeZone
    this.value = parameters.value
    this.onChange = parameters.onChange
  }

  hasValue(): boolean {
    return isPresent(this.value)
  }
}
