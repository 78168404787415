import SessionsRepository from "../../domain/repositories/SessionsRepository"
import DiModule from "../../../lib/di/DiModule"
import DefaultSessionsRepository from "../../data/repositories/DefaultSessionsRepository"
import CoreNetworkDiModule from "./CoreNetworkDiModule"
import CoreLocalDiModule from "./CoreLocalDiModule"
import DefaultUserProfileRepository from "../../data/repositories/user-profile/DefaultUserProfileRepository"
import UserProfileRepository from "../../domain/repositories/UserProfileRepository"

export default interface CoreDataDiModule {
  provideSessionsRepository(): SessionsRepository

  provideUserProfileRepository(): UserProfileRepository
}

export class DefaultCoreDataDiModule extends DiModule implements CoreDataDiModule {
  private readonly coreLocalDiModule: CoreLocalDiModule
  private readonly coreNetworkSourceDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly coreLocalDiModule: CoreLocalDiModule
    readonly coreNetworkSourceDiModule: CoreNetworkDiModule
  }) {
    super()
    this.coreLocalDiModule = parameters.coreLocalDiModule
    this.coreNetworkSourceDiModule = parameters.coreNetworkSourceDiModule
  }

  provideSessionsRepository(): SessionsRepository {
    return this.single(
      DefaultSessionsRepository.name,
      () => new DefaultSessionsRepository({
        sessionLocalSource: this.coreLocalDiModule.provideSessionLocalSource(),
        sessionNetworkSource: this.coreNetworkSourceDiModule.provideSessionNetworkSource()
      })
    )
  }

  provideUserProfileRepository(): UserProfileRepository {
    return this.single(
      DefaultUserProfileRepository.name,
      () => new DefaultUserProfileRepository({
        userProfileNetworkSource: this.coreNetworkSourceDiModule.provideUserProfileNetworkSource(),
        userProfileLocalSource: this.coreLocalDiModule.provideUserProfileLocalSource()
      })
    )
  }
}
