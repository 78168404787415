import NetworkCreateSessionErrorsObjectAttributes from "./NetworkCreateSessionErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import AdminNetworkCreateSessionErrorsObject
  from "../../../../../admin/core/data/entities/network/sessions/NetworkCreateSessionErrorsObject"

export default class NetworkCreateSessionErrorsObject implements AdminNetworkCreateSessionErrorsObject {
  @Expose()
  @Type(() => NetworkCreateSessionErrorsObjectAttributes)
  readonly attributes?: NetworkCreateSessionErrorsObjectAttributes | null
}
