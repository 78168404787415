import TableValue, { TableValueParameters } from "../TableValue"

export default class TextTableValue extends TableValue {
  private readonly value: string | null | undefined
  private readonly additionalValue: string | null | undefined

  constructor(parameters: TableValueParameters & {
    readonly value: string | null | undefined
    readonly additionalValue?: string | null
  }) {
    super(parameters)
    this.value = parameters.value
    this.additionalValue = parameters.additionalValue
  }

  getValue() {
    return this.value
  }

  getAdditionalValue() {
    return this.additionalValue
  }
}
