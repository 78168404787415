import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelCompanyBalanceChangeDocumentsResponseBody
  from "../../entities/fuel-company-balance-change-document/response-bodies/NetworkFuelCompanyBalanceChangeDocumentsResponseBody"
import NetworkFuelCompanyBalanceChangeDocumentResponseBody
  from "../../entities/fuel-company-balance-change-document/response-bodies/NetworkFuelCompanyBalanceChangeDocumentResponseBody"
import NetworkFuelCompanyBalanceChangeDocumentErrorResponseBody
  from "../../entities/fuel-company-balance-change-document/response-bodies/errors/NetworkFuelCompanyBalanceChangeDocumentErrorResponseBody"
import NetworkFuelCompanyBalanceChangeDocumentsRequestQuery
  from "../../entities/fuel-company-balance-change-document/request-queries/NetworkFuelCompanyBalanceChangeDocumentsRequestQuery"
import NetworkFuelCompanyBalanceChangeDocument
  from "../../entities/fuel-company-balance-change-document/NetworkFuelCompanyBalanceChangeDocument"
import NetworkFuelCompanyBalanceChangeDocumentRequestBody
  from "../../entities/fuel-company-balance-change-document/request-bodies/NetworkFuelCompanyBalanceChangeDocumentRequestBody"

const baseUrl = "/api/admin/fuel_company_balance_write_offs"

export default class FuelCompanyBalanceChangeDocumentsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getDocuments({
    fuelCompanyId,
    pagination,
    sort
  }: {
    readonly fuelCompanyId: string | number
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetFuelCompanyBalanceChangeDocumentsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${baseUrl}/${fuelCompanyId}`,
      parameters: instanceToPlain(new NetworkFuelCompanyBalanceChangeDocumentsRequestQuery({
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyBalanceChangeDocumentsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createDocument({
    fuelCompanyBalanceChangeDocument,
    fuelCompanyId
  }: {
    readonly fuelCompanyId: string | number
    readonly fuelCompanyBalanceChangeDocument?: NetworkFuelCompanyBalanceChangeDocument | null
  }): Promise<CreateFuelCompanyBalanceChangeDocumentNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${baseUrl}/${fuelCompanyId}`,
      body: instanceToPlain(new NetworkFuelCompanyBalanceChangeDocumentRequestBody({
        fuelCompanyBalanceWriteOff: fuelCompanyBalanceChangeDocument
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyBalanceChangeDocumentResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelCompanyBalanceChangeDocumentErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetFuelCompanyBalanceChangeDocumentsNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyBalanceChangeDocumentsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateFuelCompanyBalanceChangeDocumentNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyBalanceChangeDocumentResponseBody> |
  ErrorExecutionResult<NetworkFuelCompanyBalanceChangeDocumentErrorResponseBody> |
  FailureExecutionResult
