import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForCarrierReconciliationReportsRepository
  from "../../repositories/CarriersForCarrierReconciliationReportsRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForCarrierReconciliationReportsParameters extends GetObjectsParameters {
}

export default class GetCarriersForCarrierReconciliationReportsUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForCarrierReconciliationReportsRepository: CarriersForCarrierReconciliationReportsRepository

  constructor(parameters: {
    readonly carriersForCarrierReconciliationReportsRepository: CarriersForCarrierReconciliationReportsRepository
  }) {
    this.carriersForCarrierReconciliationReportsRepository = parameters.carriersForCarrierReconciliationReportsRepository
  }

  async call(parameters: GetCarriersForCarrierReconciliationReportsParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForCarrierReconciliationReportsRepository.getCarriers(parameters)
  }
}
