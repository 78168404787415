import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompanyReconciliationReportsRepository from "../../repositories/FuelCompanyReconciliationReportsRepository"
import FuelCompanyReconciliationReport
  from "../../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReport"

export interface GetFuelCompanyReconciliationReportsParameters extends GetObjectsParameters {
}

export default class GetFuelCompanyReconciliationReportsUseCase
  implements GetObjectsUseCase<FuelCompanyReconciliationReport> {
  private readonly fuelCompanyReconciliationReportsRepository: FuelCompanyReconciliationReportsRepository

  constructor(parameters: {
    readonly fuelCompanyReconciliationReportsRepository: FuelCompanyReconciliationReportsRepository
  }) {
    this.fuelCompanyReconciliationReportsRepository = parameters.fuelCompanyReconciliationReportsRepository
  }

  async call(
    parameters: GetFuelCompanyReconciliationReportsParameters
  ): Promise<GetObjectsResult<FuelCompanyReconciliationReport>> {
    return await this.fuelCompanyReconciliationReportsRepository.getFuelCompanyReconciliationReports(parameters)
  }
}
