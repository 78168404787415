import { Expose, Type } from "class-transformer"
import AttributeError from "../../../../../admin/core/domain/entities/errors/AttributeError"
import NetworkAttributeErrorObject from "./NetworkAttributeErrorObject"

export default class NetworkAttributeError implements AttributeError {
  @Expose()
  @Type(() => String)
  readonly type?: string | null

  @Expose()
  @Type(() => String)
  readonly message?: string | null

  @Expose()
  @Type(() => NetworkAttributeErrorObject)
  readonly objects?: NetworkAttributeErrorObject[] | null
}
