import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkTripLimitType from "../NetworkTripLimitType"

export default class NetworkTripLimitTypesResponseBody {

  @Expose()
  @Type(() => NetworkTripLimitType)
  readonly tripLimitTypes?: NetworkTripLimitType[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
