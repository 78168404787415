import NetworkFuelCompanyBalanceChangeDocument
  from "../entities/fuel-company-balance-change-document/NetworkFuelCompanyBalanceChangeDocument"
import FuelCompanyBalanceChangeDocument
  from "../../domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import FuelCompanyBalanceTransactionsMapper from "./FuelCompanyBalanceTransactionsMapper"

export default class FuelCompanyBalanceChangeDocumentsMapper {
  mapNetworkToDomain({
    fuelCompanyBalanceChangeDocument
  }: {
    readonly fuelCompanyBalanceChangeDocument: NetworkFuelCompanyBalanceChangeDocument
  }): FuelCompanyBalanceChangeDocument {
    const {
      fuelCompanyBalanceTransaction
    } = fuelCompanyBalanceChangeDocument

    return {
      id: fuelCompanyBalanceChangeDocument.id,
      date: fuelCompanyBalanceChangeDocument.date,
      value: fuelCompanyBalanceChangeDocument.value,
      formattedValue: fuelCompanyBalanceChangeDocument.formattedValue,
      comment: fuelCompanyBalanceChangeDocument.comment,
      reason: fuelCompanyBalanceChangeDocument.reason,
      fuelCompanyBalanceTransaction: fuelCompanyBalanceTransaction &&
        new FuelCompanyBalanceTransactionsMapper().mapNetworkToDomain({
          fuelCompanyBalanceTransaction
        })
    }
  }

  mapDomainToNetwork({
    fuelCompanyBalanceChangeDocument
  }: {
    readonly fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument
  }): NetworkFuelCompanyBalanceChangeDocument {
    return {
      date: fuelCompanyBalanceChangeDocument.date,
      value: fuelCompanyBalanceChangeDocument.value,
      comment: fuelCompanyBalanceChangeDocument.comment,
      reason: fuelCompanyBalanceChangeDocument.reason
    }
  }
}
