import DiModule from "../../../../lib/di/DiModule"
import React from "react"
import NotFoundPresenter from "../../presentation/pages/NotFoundPresenter"
import NotFoundPageRouteElement from "../../presentation/pages/NotFoundPageRouteElement"
import NotFoundThemeDiModule from "./NotFoundThemeDiModule"
import NotFoundI18nDiModule from "./NotFoundI18nDiModule"

export default interface NotFoundPresentationDiModule {
  notFoundPage(): React.ReactNode
}

export class DefaultNotFoundPresentationDiModule
  extends DiModule
  implements NotFoundPresentationDiModule {

  private readonly notFoundThemeDiModule: NotFoundThemeDiModule
  private readonly notFoundI18nDiModule: NotFoundI18nDiModule

  constructor(parameters: {
    readonly notFoundThemeDiModule: NotFoundThemeDiModule
    readonly notFoundI18nDiModule: NotFoundI18nDiModule
  }) {
    super()

    this.notFoundThemeDiModule = parameters.notFoundThemeDiModule
    this.notFoundI18nDiModule = parameters.notFoundI18nDiModule
  }

  notFoundPage(): React.ReactNode {
    return (
      <NotFoundPageRouteElement
        providePresenter={() => this.provideNotFoundPresenter()}
        notFoundTheme={this.notFoundThemeDiModule.provideNotFoundTheme()}
        notFoundI18n={this.notFoundI18nDiModule.provideNotFoundI18n()}
      />
    )
  }

  protected provideNotFoundPresenter(): NotFoundPresenter {
    return new NotFoundPresenter()
  }
}
