import CarrierReconciliationReportsRepository from "../../domain/repositories/CarrierReconciliationReportsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultCarrierReconciliationReportsRepository
  from "../../data/repositories/DefaultCarrierReconciliationReportsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForCarrierReconciliationReportsRepository
  from "../../domain/repositories/CarriersForCarrierReconciliationReportsRepository"
import DefaultCarriersForCarrierReconciliationReportsRepository
  from "../../data/repositories/DefaultCarriersForCarrierReconciliationReportsRepository"

export default interface CarrierReconciliationReportsDataDiModule {
  provideCarrierReconciliationReportsRepository(): CarrierReconciliationReportsRepository

  provideCarriersForCarrierReconciliationReportsRepository(): CarriersForCarrierReconciliationReportsRepository
}

export class DefaultCarrierReconciliationReportsDataDiModule
  extends DiModule
  implements CarrierReconciliationReportsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCarrierReconciliationReportsRepository(): CarrierReconciliationReportsRepository {
    return this.single(
      DefaultCarrierReconciliationReportsRepository.name,
      () => new DefaultCarrierReconciliationReportsRepository({
        carrierReconciliationReportsNetworkSource:
          this.appDataDiModule.provideCarrierReconciliationReportsNetworkSource()
      })
    )
  }

  provideCarriersForCarrierReconciliationReportsRepository(): CarriersForCarrierReconciliationReportsRepository {
    return this.single(
      DefaultCarriersForCarrierReconciliationReportsRepository.name,
      () => new DefaultCarriersForCarrierReconciliationReportsRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }
}
