import NetworkUserErrorsObjectAttributes from "./NetworkUserErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import NetworkPhoneNumberErrorsObject from "../../../phone-number/response-bodies/errors/NetworkPhoneNumberErrorsObject"
import NetworkDriverProfileErrorsObject
  from "../../../driver-profile/response-bodies/errors/NetworkDriverProfileErrorsObject"
import UserErrorsObject from "../../../../../domain/users/UserErrorsObject"
import NetworkCarrierAppAdministratorErrorsObject
  from "../../../carrier-app-administrator/response-bodies/errors/NetworkCarrierAppAdministratorErrorsObject"
import NetworkFuelCompanyAppAdministratorErrorsObject
  from "../../../fuel-company-app-administrator/response-bodies/errors/NetworkFuelCompanyAppAdministratorErrorsObject"

export default class NetworkUserErrorsObject implements UserErrorsObject {
  @Expose()
  @Type(() => NetworkUserErrorsObjectAttributes)
  readonly attributes?: NetworkUserErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkPhoneNumberErrorsObject)
  readonly phoneNumber?: NetworkPhoneNumberErrorsObject | null

  @Expose()
  @Type(() => NetworkDriverProfileErrorsObject)
  readonly profile?: NetworkDriverProfileErrorsObject | null

  @Expose()
  @Type(() => NetworkCarrierAppAdministratorErrorsObject)
  readonly carrierAppAdministrator?: NetworkCarrierAppAdministratorErrorsObject | null

  @Expose()
  @Type(() => NetworkFuelCompanyAppAdministratorErrorsObject)
  readonly fuelCompanyAppAdministrator?: NetworkFuelCompanyAppAdministratorErrorsObject | null
}
