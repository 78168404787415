import NetworkFuelCompanyBalance from "../entities/fuel-company-balances/NetworkFuelCompanyBalance"
import FuelCompanyBalance from "../../domain/fuel-company-balances/FuelCompanyBalance"

export default class FuelCompanyBalancesMapper {
  mapNetworkToDomain({
    fuelCompanyBalance
  }: {
    readonly fuelCompanyBalance: NetworkFuelCompanyBalance
  }): FuelCompanyBalance {
    return {
      value: fuelCompanyBalance.value,
      formattedValue: fuelCompanyBalance.formattedValue
    }
  }
}
