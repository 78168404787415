import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import RefuellingOrdersRepository from "../../repositories/RefuellingOrdersRepository"
import RefuellingOrder from "../../../../../core/domain/refuelling-orders/RefuellingOrder"

export interface GetRefuellingOrderParameters {
  readonly objectId: string
}

export default class GetRefuellingOrderUseCase implements GetObjectUseCase<RefuellingOrder> {
  private readonly refuellingOrdersRepository: RefuellingOrdersRepository

  constructor(parameters: {
    readonly refuellingOrdersRepository: RefuellingOrdersRepository
  }) {
    this.refuellingOrdersRepository = parameters.refuellingOrdersRepository
  }

  async call(parameters: GetRefuellingOrderParameters): Promise<GetObjectResult<RefuellingOrder>> {
    return await this.refuellingOrdersRepository.getRefuellingOrder(parameters)
  }
}
