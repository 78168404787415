import DiModule from "../../../../../admin/lib/di/DiModule"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"
import RootDomainDiModule from "../../../../../admin/features/root/di/modules/RootDomainDiModule"
import React, { ReactElement } from "react"
import RootPageRouteElement from "../../../../../admin/features/root/presentation/pages/root/RootPageRouteElement"
import RootPresenter from "../../../../../admin/features/root/presentation/pages/root/RootPresenter"
import RootPresentationDiModule from "../../../../../admin/features/root/di/modules/RootPresentationDiModule"
import RootMenuProvider from "../../../../../admin/features/root/presentation/services/RootMenuProvider"
import DefaultRootMenuProvider from "../../presentation/services/DefaultRootMenuProvider"

export class DefaultRootPresentationDiModule extends DiModule implements RootPresentationDiModule {
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly rootDomainDiModule: RootDomainDiModule
  private readonly rootMenuProvider: RootMenuProvider

  constructor(parameters: {
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly rootDomainDiModule: RootDomainDiModule
  }) {
    super()
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.rootDomainDiModule = parameters.rootDomainDiModule
    this.rootMenuProvider = new DefaultRootMenuProvider()
  }

  provideRootPage(): ReactElement {
    return (
      <RootPageRouteElement
        providePresenter={() => this.provideRootPresenter()}
        menuProvider={this.rootMenuProvider}
      />
    )
  }

  provideRootPresenter(): RootPresenter {
    return new RootPresenter({
      isSessionExistUseCase: this.coreDomainDiModule.provideIsSessionExistUseCase(),
      destroySessionUseCase: this.coreDomainDiModule.provideDestroySessionUseCase(),
      getMenuUseCase: this.rootDomainDiModule.provideGetMenuUseCase(),
      subscribeToAuthorizationErrorUseCase: this.rootDomainDiModule.provideSubscribeToAuthorizationErrorUseCase(),
      getUserProfileUseCase: this.coreDomainDiModule.provideGetUserProfileUseCase(),
      clearPermissionGroupUseCase: this.coreDomainDiModule.provideClearPermissionGroupUseCase()
    })
  }
}
