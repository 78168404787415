import GetFuelCompanyCommercialTermsUseCase from "../../domain/use-cases/fuel-company-commercial-terms/GetFuelCompanyCommercialTermsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import FuelCompanyCommercialTermsDataDiModule from "./FuelCompanyCommercialTermsDataDiModule"
import CreateFuelCompanyCommercialTermUseCase from "../../domain/use-cases/fuel-company-commercial-terms/CreateFuelCompanyCommercialTermUseCase"
import GetFuelCompanyCommercialTermUseCase from "../../domain/use-cases/fuel-company-commercial-terms/GetFuelCompanyCommercialTermUseCase"
import UpdateCarrierCommercialTermUseCase from "../../domain/use-cases/fuel-company-commercial-terms/UpdateCarrierCommercialTermUseCase"
import GetFuelCompaniesForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForFuelCompanyCommercialTermsUseCase"
import GetCarriersForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForFuelCompanyCommercialTermsUseCase"
import GetFuelsForFuelCompanyCommercialTermsUseCase from "../../domain/use-cases/fuels/GetFuelsForFuelCompanyCommercialTermsUseCase"
import GetGasStationsForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForFuelCompanyCommercialTermsUseCase"
import GetPricingTypesForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/pricing-types/GetPricingTypesForFuelCompanyCommercialTermsUseCase"

export default interface FuelCompanyCommercialTermsDomainDiModule {
  provideGetFuelCompanyCommercialTermsUseCase(): GetFuelCompanyCommercialTermsUseCase

  provideGetFuelCompanyCommercialTermUseCase(): GetFuelCompanyCommercialTermUseCase

  provideCreateFuelCompanyCommercialTermUseCase(): CreateFuelCompanyCommercialTermUseCase

  provideUpdateFuelCompanyCommercialTermUseCase(): UpdateCarrierCommercialTermUseCase

  provideGetCarriersForFuelCompanyCommercialTermsUseCase(): GetCarriersForFuelCompanyCommercialTermsUseCase

  provideGetFuelCompaniesForFuelCompanyCommercialTermsUseCase(): GetFuelCompaniesForFuelCompanyCommercialTermsUseCase

  provideGetFuelsForFuelCompanyCommercialTermsUseCase(): GetFuelsForFuelCompanyCommercialTermsUseCase

  provideGetGasStationsForFuelCompanyCommercialTermsUseCase(): GetGasStationsForFuelCompanyCommercialTermsUseCase

  provideGetPricingTypesForFuelCompanyCommercialTermsUseCase(): GetPricingTypesForFuelCompanyCommercialTermsUseCase
}

export class DefaultFuelCompanyCommercialTermsDomainDiModule
  extends DiModule
  implements FuelCompanyCommercialTermsDomainDiModule {
  private readonly fuelCompanyCommercialTermsDataDiModule: FuelCompanyCommercialTermsDataDiModule

  constructor(parameters: {
    readonly fuelCompanyCommercialTermsDataDiModule: FuelCompanyCommercialTermsDataDiModule
  }) {
    super()
    this.fuelCompanyCommercialTermsDataDiModule = parameters.fuelCompanyCommercialTermsDataDiModule
  }

  provideGetFuelCompanyCommercialTermsUseCase(): GetFuelCompanyCommercialTermsUseCase {
    return this.single(
      GetFuelCompanyCommercialTermsUseCase.name,
      () => new GetFuelCompanyCommercialTermsUseCase({
        fuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideGetFuelCompanyCommercialTermUseCase(): GetFuelCompanyCommercialTermUseCase {
    return this.single(
      GetFuelCompanyCommercialTermUseCase.name,
      () => new GetFuelCompanyCommercialTermUseCase({
        fuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideCreateFuelCompanyCommercialTermUseCase(): CreateFuelCompanyCommercialTermUseCase {
    return this.single(
      CreateFuelCompanyCommercialTermUseCase.name,
      () => new CreateFuelCompanyCommercialTermUseCase({
        fuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideUpdateFuelCompanyCommercialTermUseCase(): UpdateCarrierCommercialTermUseCase {
    return this.single(
      UpdateCarrierCommercialTermUseCase.name,
      () => new UpdateCarrierCommercialTermUseCase({
        fuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideGetCarriersForFuelCompanyCommercialTermsUseCase(): GetCarriersForFuelCompanyCommercialTermsUseCase {
    return this.single(
      GetCarriersForFuelCompanyCommercialTermsUseCase.name,
      () => new GetCarriersForFuelCompanyCommercialTermsUseCase({
        carriersForFuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideCarriersForFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideGetFuelCompaniesForFuelCompanyCommercialTermsUseCase(): GetFuelCompaniesForFuelCompanyCommercialTermsUseCase {
    return this.single(
      GetFuelCompaniesForFuelCompanyCommercialTermsUseCase.name,
      () => new GetFuelCompaniesForFuelCompanyCommercialTermsUseCase({
        fuelCompanyForFuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideFuelCompaniesForFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideGetFuelsForFuelCompanyCommercialTermsUseCase(): GetFuelsForFuelCompanyCommercialTermsUseCase {
    return this.single(
      GetFuelsForFuelCompanyCommercialTermsUseCase.name,
      () => new GetFuelsForFuelCompanyCommercialTermsUseCase({
        fuelsForFuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideFuelsForFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideGetGasStationsForFuelCompanyCommercialTermsUseCase(): GetGasStationsForFuelCompanyCommercialTermsUseCase {
    return this.single(
      GetGasStationsForFuelCompanyCommercialTermsUseCase.name,
      () => new GetGasStationsForFuelCompanyCommercialTermsUseCase({
        gasStationsForFuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.provideGasStationsForFuelCompanyCommercialTermsRepository()
      })
    )
  }

  provideGetPricingTypesForFuelCompanyCommercialTermsUseCase(): GetPricingTypesForFuelCompanyCommercialTermsUseCase {
    return this.single(
      GetPricingTypesForFuelCompanyCommercialTermsUseCase.name,
      () => new GetPricingTypesForFuelCompanyCommercialTermsUseCase({
        pricingTypesForFuelCompanyCommercialTermsRepository:
          this.fuelCompanyCommercialTermsDataDiModule.providePricingTypesForFuelCompanyCommercialTermsRepository()
      })
    )
  }
}
