import FuelCompanyBalanceTransactionsRepository from "../../domain/repositories/FuelCompanyBalanceTransactionsRepository"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import {
  GetFuelCompanyBalanceTransactionsParameters
} from "../../domain/use-cases/fuel-company-balance-transactions/GetFuelCompanyBalanceTransactionsUseCase"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import FuelCompanyBalanceTransactionsNetworkSource
  from "../../../../core/data/sources/network/FuelCompanyBalanceTransactionsNetworkSource"
import FuelCompanyBalanceTransactionsMapper from "../../../../core/data/mappers/FuelCompanyBalanceTransactionsMapper"
import FuelCompanyBalanceTransaction
  from "../../../../core/domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"

export default class DefaultFuelCompanyBalanceTransactionsRepository
  implements FuelCompanyBalanceTransactionsRepository {
  private readonly fuelCompanyBalanceTransactionsNetworkSource: FuelCompanyBalanceTransactionsNetworkSource

  constructor(parameters: {
    readonly fuelCompanyBalanceTransactionsNetworkSource: FuelCompanyBalanceTransactionsNetworkSource
  }) {
    this.fuelCompanyBalanceTransactionsNetworkSource = parameters.fuelCompanyBalanceTransactionsNetworkSource
  }

  async getTransactions({
    fuelCompanyId,
    pagination,
    sort
  }: GetFuelCompanyBalanceTransactionsParameters): Promise<GetObjectsResult<FuelCompanyBalanceTransaction>> {
    const result = await this.fuelCompanyBalanceTransactionsNetworkSource
      .getTransactions({
        fuelCompanyId,
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
          pagination
        }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success": {
        const fuelCompanyBalanceTransactionsMapper = new FuelCompanyBalanceTransactionsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.fuelCompanyBalanceReplenishments!.map((fuelCompanyBalanceTransaction) => {
              return fuelCompanyBalanceTransactionsMapper.mapNetworkToDomain({
                fuelCompanyBalanceTransaction
              })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
