import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStationsForCarrierCommercialTermsRepository
  from "../../repositories/GasStationsForCarrierCommercialTermsRepository"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationsFilter from "../../../../../core/domain/gas-stations/GasStationsFilter"

export interface GetGasStationsForCarrierCommercialTermsParameters
  extends GetObjectsParameters<GasStationsFilter> {
}

export default class GetGasStationsForCarrierCommercialTermsUseCase implements GetObjectsUseCase<GasStation> {
  private readonly gasStationsForCarrierCommercialTermsRepository: GasStationsForCarrierCommercialTermsRepository

  constructor(parameters: {
    readonly gasStationsForCarrierCommercialTermsRepository: GasStationsForCarrierCommercialTermsRepository
  }) {
    this.gasStationsForCarrierCommercialTermsRepository = parameters.gasStationsForCarrierCommercialTermsRepository
  }

  async call(parameters: GetGasStationsForCarrierCommercialTermsParameters): Promise<GetObjectsResult<GasStation>> {
    return await this.gasStationsForCarrierCommercialTermsRepository.getGasStations(parameters)
  }
}
