import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkCarrierBalanceChangeDocumentsResponseBody
  from "../../entities/carrier-balance-change-documents/response-bodies/NetworkCarrierBalanceChangeDocumentsResponseBody"
import NetworkCarrierBalanceChangeDocumentResponseBody
  from "../../entities/carrier-balance-change-documents/response-bodies/NetworkCarrierBalanceChangeDocumentResponseBody"
import NetworkCarrierBalanceChangeDocumentErrorResponseBody
  from "../../entities/carrier-balance-change-documents/response-bodies/errors/NetworkCarrierBalanceChangeDocumentErrorResponseBody"
import NetworkCarrierBalanceChangeDocumentsRequestQuery
  from "../../entities/carrier-balance-change-documents/request-queries/NetworkCarrierBalanceChangeDocumentsRequestQuery"
import NetworkCarrierBalanceChangeDocument
  from "../../entities/carrier-balance-change-documents/NetworkCarrierBalanceChangeDocument"
import NetworkCarrierBalanceChangeDocumentRequestBody
  from "../../entities/carrier-balance-change-documents/request-bodies/NetworkCarrierBalanceChangeDocumentRequestBody"

const basePath = "/api/admin/carrier_balance_replenishments"

export default class CarrierBalanceChangeDocumentsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getDocuments({
    carrierId,
    pagination,
    sort
  }: {
    readonly carrierId: string | number
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetCarrierBalanceChangeDocumentsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${carrierId}`,
      parameters: instanceToPlain(new NetworkCarrierBalanceChangeDocumentsRequestQuery({
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierBalanceChangeDocumentsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createDocument({
    carrierBalanceChangeDocument,
    carrierId
  }: {
    readonly carrierId: string | number
    readonly carrierBalanceChangeDocument?: NetworkCarrierBalanceChangeDocument | null
  }): Promise<CreateCarrierBalanceChangeDocumentNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}/${carrierId}`,
      body: instanceToPlain(new NetworkCarrierBalanceChangeDocumentRequestBody({
        carrierBalanceReplenishment: carrierBalanceChangeDocument
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierBalanceChangeDocumentResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkCarrierBalanceChangeDocumentErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCarrierBalanceChangeDocumentsNetworkResult =
  SuccessExecutionResult<NetworkCarrierBalanceChangeDocumentsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateCarrierBalanceChangeDocumentNetworkResult =
  SuccessExecutionResult<NetworkCarrierBalanceChangeDocumentResponseBody> |
  ErrorExecutionResult<NetworkCarrierBalanceChangeDocumentErrorResponseBody> |
  FailureExecutionResult
