import NetworkUserTrip from "../entities/user-trips/NetworkUserTrip"
import UserTrip from "../../domain/user-trips/UserTrip"
import UsersMapper from "./UsersMapper"
import TripsMapper from "./TripsMapper"

export default class UserTripsMapper {
  mapNetworkToDomain({
    userTrip
  }: {
    readonly userTrip: NetworkUserTrip
  }): UserTrip {
    const usersMapper = new UsersMapper()
    const tripsMapper = new TripsMapper()
    const { user, trip } = userTrip

    return {
      id: userTrip.id,
      status: userTrip.status,
      userId: userTrip.userId,
      user: user && usersMapper.mapNetworkToDomain({ user }),
      trip: trip && tripsMapper.mapNetworkToDomain({
        trip: trip, tripStatuses: []
      })
    }
  }
}
