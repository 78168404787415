import React, { ReactNode, useEffect, useRef, useState } from "react"
import styles from "./CalendarComponent.module.scss"
import MonthPickerComponent from "../month-picker/MonthPickerComponent"
import ExtendedDate from "../extended-date/ExtendedDate"
import { correctDateRange, DateRange } from "../date-range/DateRange"
import isBlank from "../../isBlank"
import isPresent from "../../isPresent"
import PickerComponentStyles from "../../picker/PickerComponentStyles"

const calendarWeeksCount = 6
const weekDaysCount = 7
const startWeekDayNumber = 1
const millisecondsInSecond = 1000
const secondsInMinute = 60
const minutesInHour = 60
const hoursInDay = 24
const millisecondsInDay = millisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay

export interface CalendarComponentIcons {
  readonly previousMonthIcon?: ReactNode
  readonly nextMonthIcon?: ReactNode
}

export interface CalendarComponentClasses {
  weekDay?: string
  day?: string
  currentDay?: string
  otherMonthDay?: string
  otherMonthCurrentDay?: string
  selectedDay?: string
  selectedCurrentDay?: string
}

export interface CalendarComponentStyles {
  readonly icons?: CalendarComponentIcons
  readonly selectPicker?: PickerComponentStyles
  readonly classes?: CalendarComponentClasses
}

export default function CalendarComponent({
  weekDayNameByNumber,
  monthNameByNumber,
  dateRange,
  focusedDate,
  shownMonth: defaultShownMonth,
  isRangeDisplayEnabled,
  needShowPreviousMonthPart = true,
  needShowNextMonthPart = true,
  renderDateDescription,
  onDateClick,
  onMonthChanged,
  onDateFocused,
  componentStyles = {}
}: {
  readonly componentStyles?: CalendarComponentStyles
  readonly weekDayNameByNumber: { [_: number]: string }
  readonly monthNameByNumber: { [_: number]: string }
  readonly dateRange: Partial<DateRange> | null
  readonly focusedDate?: Date
  readonly shownMonth?: Date
  readonly isRangeDisplayEnabled?: boolean
  readonly needShowPreviousMonthPart?: boolean
  readonly needShowNextMonthPart?: boolean
  readonly renderDateDescription?: (parameters: { readonly date: Date }) => React.ReactNode
  readonly onDateClick?: (date: Date) => void
  readonly onMonthChanged?: (parameters: { readonly month: Date }) => void
  readonly onDateFocused?: (date: Date) => void
}) {
  const {
    icons: {
      previousMonthIcon,
      nextMonthIcon
    } = {},
    classes: {
      weekDay: weekDayClassName = "",
      day: dayClassName = "",
      currentDay: currentDayClassName = "",
      otherMonthDay: otherMonthDayClassName = "",
      otherMonthCurrentDay: otherMonthCurrentDayClassName = "",
      selectedDay: selectedDayClassName = "",
      selectedCurrentDay: selectedCurrentDayClassName = ""
    } = {}
  } = componentStyles

  const isFirstDefaultShownMonthChange = useRef(true)
  const currentDate = new Date()
  const { dateFrom, dateTo } = dateRange ?? {}
  const [shownMonth, setShownMonth] = useState(defaultShownMonth ?? dateFrom ?? currentDate)
  const extendedShownMonth = new ExtendedDate(shownMonth)
  const shownMonthDaysCount = extendedShownMonth.getMonthDaysCount()
  const previousMonth: Date = extendedShownMonth.addMonths(-1)
  const nextMonth: Date = extendedShownMonth.addMonths(1)

  const focusedDateRange: Partial<DateRange> = correctDateRange({
    dateFrom: dateFrom ?? focusedDate,
    dateTo: dateTo ?? focusedDate
  })

  const weekDayNumbers: number[] = Array(weekDaysCount)
    .fill(null)
    .map((_, weekDayNumber: number) => (weekDayNumber + startWeekDayNumber) % weekDaysCount)

  const startCalendarDate = extendedShownMonth.getFirstMonthDate()
  const startCalendarDateWeekNumber = startCalendarDate.getDay()
  const extraPreviousDaysCount = weekDayNumbers.indexOf(startCalendarDateWeekNumber)
  startCalendarDate.setDate(1 - extraPreviousDaysCount)
  const extendedStartCalendarDate = new ExtendedDate(startCalendarDate)

  const calendarDatesCount = calendarWeeksCount * weekDaysCount
  const calendarDates: Date[] = Array(calendarDatesCount)
    .fill(null)
    .map((_, calendarDateIndex: number) => extendedStartCalendarDate.addDays(calendarDateIndex))

  const calendarDatesByWeek: Date[][] = []

  for (let i = 0; i < calendarDates.length; i += weekDaysCount) {
    const weekCalendarDates = calendarDates.slice(i, i + weekDaysCount)
    calendarDatesByWeek.push(weekCalendarDates)
  }

  useEffect(() => {
    if (isBlank(shownMonth)) {
      return
    }

    onMonthChanged && onMonthChanged({ month: shownMonth })
  }, [shownMonth])

  useEffect(() => {
    if (isFirstDefaultShownMonthChange.current) {
      isFirstDefaultShownMonthChange.current = false
      return
    }

    if (isBlank(defaultShownMonth)) {
      return
    }

    if (defaultShownMonth.getTime() !== shownMonth.getTime()) {
      setShownMonth(defaultShownMonth)
    }
  }, [defaultShownMonth?.getTime()])

  function isCurrentDate(date: Date) {
    return isDateEquals(date, currentDate)
  }

  function isSelectedDate(date: Date) {
    return isPresent(dateFrom) && isDateEquals(date, dateFrom) ||
      isPresent(dateTo) && isDateEquals(date, dateTo)
  }

  function isDateEquals(dateOne: Date, dateTwo: Date) {
    return dateOne.getFullYear() === dateTwo.getFullYear() &&
      dateOne.getMonth() === dateTwo.getMonth() &&
      dateOne.getDate() === dateTwo.getDate()
  }

  function isShownMonth(date: Date) {
    return date.getFullYear() === shownMonth.getFullYear() &&
      date.getMonth() === shownMonth.getMonth()
  }

  function isPreviousMonth(date: Date) {
    return date.getFullYear() === previousMonth.getFullYear() &&
      date.getMonth() === previousMonth.getMonth()
  }

  function isNextMonth(date: Date) {
    return date.getFullYear() === nextMonth.getFullYear() &&
      date.getMonth() === nextMonth.getMonth()
  }

  function isDateFrom(date: Date) {
    return focusedDateRange.dateFrom !== undefined && isDateEquals(focusedDateRange.dateFrom, date)
  }

  function isDateTo(date: Date) {
    return focusedDateRange.dateTo !== undefined && isDateEquals(focusedDateRange.dateTo, date)
  }

  function isInRange(date: Date) {
    if (isSelectedDate(date)) {
      return false
    }

    if (isBlank(dateFrom) && isBlank(dateTo)) {
      return false
    }

    return isPresent(focusedDateRange.dateFrom) &&
      isPresent(focusedDateRange.dateTo) &&
      date.getTime() >= focusedDateRange.dateFrom.getTime() &&
      date.getTime() <= focusedDateRange.dateTo.getTime()
  }

  function handleOnPreviousMonthButtonClick() {
    const shownMonthFirstDate: Date = new Date(shownMonth.getFullYear(), shownMonth.getMonth(), 1)
    const previousMonth: Date = new Date(shownMonthFirstDate.getTime() - millisecondsInDay)
    setShownMonth(previousMonth)
  }

  function handleOnNextMonthButtonClick() {
    const shownMonthLastDate: Date = new Date(shownMonth.getFullYear(), shownMonth.getMonth(), shownMonthDaysCount)
    const previousMonth: Date = new Date(shownMonthLastDate.getTime() + millisecondsInDay)
    setShownMonth(previousMonth)
  }

  function handleOnMonthChange({ date }: { readonly date: Date | null }) {
    setShownMonth(date ?? currentDate)
  }

  function handleOnDateClick(date: Date) {
    onDateClick && onDateClick(date)
  }

  function handleOnDateMouseOver(date: Date) {
    if (needShowPreviousMonthPart && isPreviousMonth(date) ||
      needShowNextMonthPart && isNextMonth(date) ||
      isShownMonth(date)) {
      onDateFocused && onDateFocused(date)
    }
  }

  return (
    <div className={styles.calendar}>
      <table>
        <thead>
          <tr>
            <td colSpan={weekDaysCount}>
              <div className={styles.header}>
                <div
                  className={`${styles.changeMonthButton}`}
                  onClick={handleOnPreviousMonthButtonClick}
                >
                  {previousMonthIcon}
                </div>
                <span className={styles.monthName}>
                  <MonthPickerComponent
                    date={shownMonth}
                    isDisabled={false}
                    onChange={handleOnMonthChange}
                    monthNameByNumber={monthNameByNumber}
                    componentStyle={{
                      selectPicker: componentStyles.selectPicker
                    }}
                  />
                </span>
                <div
                  className={`${styles.changeMonthButton}`}
                  onClick={handleOnNextMonthButtonClick}
                >
                  {nextMonthIcon}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            {weekDayNumbers.map((weekDayNumber: number) => (
              <td key={weekDayNumber}>
                <div className={`${styles.weekDay} ${weekDayClassName}`}>
                  {weekDayNameByNumber[weekDayNumber]}
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {calendarDatesByWeek.map((weekCalendarDates: Date[]) => (
            <tr key={weekCalendarDates.map((calendarDate: Date) => calendarDate.toString()).join(",")}>
              {weekCalendarDates.map((calendarDate: Date) => {
                const dayContainerClassName = (() => {
                  if (isCurrentDate(calendarDate)) {
                    return isShownMonth(calendarDate) ? currentDayClassName : otherMonthCurrentDayClassName
                  } else {
                    return isShownMonth(calendarDate) ? dayClassName : otherMonthDayClassName
                  }
                })()

                const dayNameClassName = (() => {
                  if (!isSelectedDate(calendarDate)) return ""

                  if (isCurrentDate(calendarDate)) {
                    return selectedCurrentDayClassName
                  } else {
                    return selectedDayClassName
                  }
                })()

                return (
                  <td key={calendarDate.toString()}>
                    <div
                      className={[
                        styles.day,
                        dayContainerClassName,
                        isRangeDisplayEnabled && isInRange(calendarDate) ? styles.range : "",
                        isRangeDisplayEnabled && isDateFrom(calendarDate) ? styles.dateFrom : "",
                        isRangeDisplayEnabled && isDateTo(calendarDate) ? styles.dateTo : "",
                        !needShowPreviousMonthPart && isPreviousMonth(calendarDate) ? styles.hidden : "",
                        !needShowNextMonthPart && isNextMonth(calendarDate) ? styles.hidden : ""
                      ].join(" ")}
                      onClick={() => handleOnDateClick(calendarDate)}
                      onMouseOver={() => handleOnDateMouseOver(calendarDate)}
                    >
                      <div
                        className={[
                          styles.dayName,
                          dayNameClassName
                        ].join(" ")}
                      >
                        {calendarDate.getDate()}
                      </div>
                      {renderDateDescription && (
                        <div className={styles.dayDescription}>
                          {isShownMonth(calendarDate) ? renderDateDescription({ date: calendarDate }) : <>&nbsp;</>}
                        </div>
                      )}
                    </div>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
