import { Expose, Type } from "class-transformer"
import NetworkInstallationInstancesRequestFilter from "./NetworkInstallationInstancesRequestFilter"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkInstallationInstancesRequestQuery {
  @Expose()
  @Type(() => NetworkInstallationInstancesRequestFilter)
  readonly filter?: NetworkInstallationInstancesRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  @Type(() => String)
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkInstallationInstancesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
