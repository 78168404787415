import FuelCompanyCommercialTermsTextProvider from "../FuelCompanyCommercialTermsTextProvider"
import isBlank from "../../../../../admin/lib/isBlank"

export default class DefaultRuFuelCompanyCommercialTermsTextProvider implements FuelCompanyCommercialTermsTextProvider {

  idField(): string {
    return "№"
  }

  fuelCompanyFilter(): string {
    return "Топливная компания"
  }

  priceField(): string {
    return "Цена"
  }

  discountPercentageField(): string {
    return "Процент скидки"
  }

  gasStationsIdField(): string {
    return "АЗС"
  }

  carrierField(): string {
    return "Перевозчик"
  }

  fuelCompanyField(): string {
    return "Топливная компания"
  }

  fuelField(): string {
    return "Вид топлива"
  }

  beginDateField(): string {
    return "Дата начала действия"
  }

  endDateField(): string {
    return "Дата окончания действия"
  }

  pricingTypeField(): string {
    return "Ценообразование"
  }

  documentUrlField(): string {
    return "Юр. документ"
  }

  newObjectTitle(): string {
    return "Новое коммерческое условие"
  }

  existObjectTitle(): string {
    return "Коммерческое условие"
  }

  defaultListTitle(): string {
    return "Коммерческие условия топливных компаний"
  }

  fuelCompanyAdministratorListTitle(): string {
    return "Коммерческие условия"
  }

  pricingTypeDiscountPercentageValue({
    formattedDiscountPercentage
  }: {
    readonly formattedDiscountPercentage: string | null | undefined
  }): string {
    if (isBlank(formattedDiscountPercentage)) return ""

    return `Скидка ${formattedDiscountPercentage}\u00A0%`
  }

  pricingTypePriceValue({
    formattedPrice
  }: {
    readonly formattedPrice: string | null | undefined
  }): string {
    if (isBlank(formattedPrice)) return ""

    return `Фикс. цена ${formattedPrice}\u00A0₽ за ед.`
  }
}
