import ForbiddenI18n from "../../i18n/ForbiddenI18n"
import ForbiddenTextProvider from "../../i18n/ForbiddenTextProvider"
import { I18nDiModule } from "../../../../core/di/modules/I18nDiModule"

export default interface ForbiddenI18nDiModule {
  provideForbiddenI18n(): ForbiddenI18n
}

export class DefaultForbiddenI18nDiModule
  extends I18nDiModule<ForbiddenTextProvider>
  implements ForbiddenI18nDiModule {

  provideForbiddenI18n(): ForbiddenI18n {
    return this.single(
      ForbiddenI18n.name,
      () => new ForbiddenI18n(this.getI18nParameters())
    )
  }
}
