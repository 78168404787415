import Presenter from "../../../../lib/presenter/Presenter"
import NotFoundView, { NotFoundViewState } from "./NotFoundView"

export default class NotFoundPresenter extends Presenter<NotFoundView> {

  private notFoundViewState?: NotFoundViewState

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    this.setAndShowNotFoundViewState(this.buildNotFoundViewState())
  }

  protected onViewReAttach() {
    super.onViewReAttach()

    this.setAndShowNotFoundViewState(this.buildNotFoundViewState())
  }

  private buildNotFoundViewState(): NotFoundViewState {
    return {}
  }

  private setAndShowNotFoundViewState(notFoundViewState: NotFoundViewState) {
    this.setObjectFormViewState(notFoundViewState)
    this.showNotFoundViewState()
  }

  private setObjectFormViewState(notFoundViewState: NotFoundViewState) {
    this.notFoundViewState = notFoundViewState
  }

  private showNotFoundViewState() {
    this.notFoundViewState && this.getView()?.showNotFoundViewState(this.notFoundViewState)
  }
}
