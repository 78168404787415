import NetworkTransportErrorsObjectAttributes from "./NetworkTransportErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import TransportErrorsObject from "../../../../../domain/transport/TransportErrorsObject"
import NetworkTransportFuelErrorsObject
  from "../../../transport-fuel/response-partials/errors/NetworkTransportFuelErrorsObject"

export default class NetworkTransportErrorsObject implements TransportErrorsObject {
  @Expose()
  @Type(() => NetworkTransportErrorsObjectAttributes)
  readonly attributes?: NetworkTransportErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkTransportFuelErrorsObject)
  readonly transportFuels?: NetworkTransportFuelErrorsObject[] | null
}
