import Presenter from "../../../../lib/presenter/Presenter"
import ForbiddenView, { ForbiddenViewState } from "./ForbiddenView"

export default class ForbiddenPresenter extends Presenter<ForbiddenView> {

  private forbiddenViewState?: ForbiddenViewState

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    this.setAndShowForbiddenViewState(this.buildForbiddenViewState())
  }

  protected onViewReAttach() {
    super.onViewReAttach()

    this.setAndShowForbiddenViewState(this.buildForbiddenViewState())
  }

  private buildForbiddenViewState(): ForbiddenViewState {
    return {}
  }

  private setAndShowForbiddenViewState(forbiddenViewState: ForbiddenViewState) {
    this.setObjectFormViewState(forbiddenViewState)
    this.showForbiddenViewState()
  }

  private setObjectFormViewState(forbiddenViewState: ForbiddenViewState) {
    this.forbiddenViewState = forbiddenViewState
  }

  private showForbiddenViewState() {
    this.forbiddenViewState && this.getView()?.showForbiddenViewState(this.forbiddenViewState)
  }
}
