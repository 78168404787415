import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import MultiSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/MultiSelectFormField"
import AppI18 from "../../../../core/i18n/AppI18"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import RefuellingOrdersReport from "../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReport"
import RefuellingOrdersReportError from "../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReportError"
import RefuellingOrdersReportErrorsObject from "../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReportErrorsObject"
import RefuellingOrdersReportsI18n from "../../i18n/RefuellingOrdersReportsI18n"
import GetCarriersForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForRefuellingOrdersReportsUseCase"
import GetDriversForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/drivers/GetDriversForRefuellingOrdersReportsUseCase"
import GetFuelCompaniesForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForRefuellingOrdersReportsUseCase"
import GetFuelsForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/fuels/GetFuelsForRefuellingOrdersReportsUseCase"
import GetGasStationsForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForRefuellingOrdersReportsUseCase"
import GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/refuelling-order-statuses/GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import Carrier from "../../../../core/domain/carriers/Carrier"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import Trip from "../../../../core/domain/trips/Trip"
import GetTripsForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/trips/GetTripsForRefuellingOrdersReportsUseCase"
import User from "../../../../core/domain/users/User"
import { RefuellingOrderStatusSelectOption } from "../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import Fuel from "../../../../core/domain/fuels/Fuel"

const mainGroupName = "main"

export default class RefuellingOrdersReportsFormProvider
  implements FormProvider<RefuellingOrdersReport, RefuellingOrdersReportError, RefuellingOrdersReportErrorsObject> {
  private readonly refuellingOrdersReportsI18: RefuellingOrdersReportsI18n
  private readonly appI18: AppI18
  private readonly getCarriersUseCase: GetCarriersForRefuellingOrdersReportsUseCase
  private readonly getDriversUseCase: GetDriversForRefuellingOrdersReportsUseCase
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForRefuellingOrdersReportsUseCase
  private readonly getFuelsUseCase: GetFuelsForRefuellingOrdersReportsUseCase
  private readonly getGasStationsUseCase: GetGasStationsForRefuellingOrdersReportsUseCase
  private readonly getRefuellingOrderStatusesUseCase: GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase
  private readonly getTripsForRefuellingOrdersReportsUseCase: GetTripsForRefuellingOrdersReportsUseCase

  constructor(parameters: {
    readonly refuellingOrdersReportsI18: RefuellingOrdersReportsI18n
    readonly appI18: AppI18
    readonly getCarriersUseCase: GetCarriersForRefuellingOrdersReportsUseCase
    readonly getDriversUseCase: GetDriversForRefuellingOrdersReportsUseCase
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForRefuellingOrdersReportsUseCase
    readonly getFuelsUseCase: GetFuelsForRefuellingOrdersReportsUseCase
    readonly getGasStationsUseCase: GetGasStationsForRefuellingOrdersReportsUseCase
    readonly getRefuellingOrderStatusesUseCase: GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase
    readonly getTripsForRefuellingOrdersReportsUseCase: GetTripsForRefuellingOrdersReportsUseCase
  }) {
    this.appI18 = parameters.appI18
    this.refuellingOrdersReportsI18 = parameters.refuellingOrdersReportsI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getDriversUseCase = parameters.getDriversUseCase
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getFuelsUseCase = parameters.getFuelsUseCase
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
    this.getRefuellingOrderStatusesUseCase = parameters.getRefuellingOrderStatusesUseCase
    this.getTripsForRefuellingOrdersReportsUseCase = parameters.getTripsForRefuellingOrdersReportsUseCase
  }

  getEntity(): string {
    return Entity.REFUELLING_ORDERS_REPORTS
  }

  getNewObjectTitle(): string {
    return this.refuellingOrdersReportsI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectTitle(): string {
    return ""
  }

  getExistedObjectShortTitle(): string {
    return ""
  }

  async buildObject(): Promise<RefuellingOrdersReport> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: RefuellingOrdersReportError
  }): RefuellingOrdersReportErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject>[] {
    const refuellingOrdersReportsTextProvider = this.refuellingOrdersReportsI18.getTextProvider()
    const appTextProvider = this.appI18.getTextProvider()

    return [
      new DateFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject>({
        title: refuellingOrdersReportsTextProvider.fromDateField(),
        clearable: true,
        groupName: mainGroupName,
        getId: () => "fromDate",
        getValue: (report: RefuellingOrdersReport) => report.fromDate,
        setValue: (
          report: RefuellingOrdersReport,
          fromDate: Date | null | undefined
        ): RefuellingOrdersReport => ({
          ...report, fromDate
        }),
        getErrors: (errorsObject?: RefuellingOrdersReportErrorsObject) => errorsObject?.attributes?.fromDate
      }),
      new DateFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject>({
        title: refuellingOrdersReportsTextProvider.toDateField(),
        clearable: true,
        groupName: mainGroupName,
        getId: () => "toDate",
        getValue: (report: RefuellingOrdersReport) => report.toDate,
        setValue: (
          report: RefuellingOrdersReport,
          toDate: Date | null | undefined
        ): RefuellingOrdersReport => ({
          ...report, toDate
        }),
        getErrors: (errorsObject?: RefuellingOrdersReportErrorsObject) => errorsObject?.attributes?.toDate
      }),
      new MultiSelectFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject, FuelCompany>({
        title: refuellingOrdersReportsTextProvider.fuelCompaniesField(),
        clearable: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelCompaniesUseCase,
        getId: () => "fuelCompanies",
        getOptionId: (fuelCompany: FuelCompany) => fuelCompany.id!.toString(),
        getOptionText: (fuelCompany: FuelCompany) => fuelCompany.name,
        getValue: (report: RefuellingOrdersReport) => report.fuelCompanies,
        setValue: (report: RefuellingOrdersReport, fuelCompanies: FuelCompany[] | null) => {
          return {
            ...report,
            fuelCompanies,
            fuelCompanyIds: fuelCompanies?.map((fuelCompany) => fuelCompany.id!)
          }
        }
      }),
      new MultiSelectFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject, Carrier>({
        title: refuellingOrdersReportsTextProvider.carriersField(),
        clearable: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getCarriersUseCase,
        getId: () => "carriers",
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (report: RefuellingOrdersReport) => report.carriers,
        setValue: (report: RefuellingOrdersReport, carriers: Carrier[] | null) => {
          return {
            ...report,
            carriers,
            carrierIds: carriers?.map((carrier) => carrier.id!)
          }
        }
      }),
      new MultiSelectFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject, GasStation>({
        title: refuellingOrdersReportsTextProvider.gasStationsField(),
        clearable: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getGasStationsUseCase,
        getId: () => "gasStations",
        getOptionId: (gasStation: GasStation) => gasStation.id!.toString(),
        getOptionText: (gasStation: GasStation) => gasStation.name,
        getValue: (report: RefuellingOrdersReport) => report.gasStations,
        setValue: (report: RefuellingOrdersReport, gasStations: GasStation[] | null) => {
          return {
            ...report,
            gasStations,
            gasStationIds: gasStations?.map((gasStation) => gasStation.id!)
          }
        }
      }),
      new MultiSelectFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject, Trip>({
        title: refuellingOrdersReportsTextProvider.tripsField(),
        clearable: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getTripsForRefuellingOrdersReportsUseCase,
        getId: () => "trips",
        getOptionId: (trip: Trip) => trip.id!.toString(),
        getOptionText: (trip: Trip) => trip.name,
        getValue: (report: RefuellingOrdersReport) => report.trips,
        setValue: (report: RefuellingOrdersReport, trips: Trip[] | null) => {
          return {
            ...report,
            trips,
            tripIds: trips?.map((trips) => trips.id!)
          }
        }
      }),
      new MultiSelectFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject, User>({
        title: refuellingOrdersReportsTextProvider.driversField(),
        clearable: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getDriversUseCase,
        getId: () => "drivers",
        getOptionId: (user: User) => user.id!.toString(),
        getOptionText: (user: User) => appTextProvider.driverName({ user }),
        getValue: (report: RefuellingOrdersReport) => report.drivers,
        setValue: (report: RefuellingOrdersReport, drivers: User[] | null) => {
          return {
            ...report,
            drivers,
            driverIds: drivers?.map((driver) => driver.id!)
          }
        }
      }),
      new MultiSelectFormField<
        RefuellingOrdersReport,
        RefuellingOrdersReportErrorsObject,
        RefuellingOrderStatusSelectOption
      >({
        title: refuellingOrdersReportsTextProvider.refuellingOrderStatusesField(),
        clearable: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getRefuellingOrderStatusesUseCase,
        getId: () => "refuellingOrderStatuses",
        getOptionId: (
          refuellingOrderStatusSelectOption: RefuellingOrderStatusSelectOption
        ) => refuellingOrderStatusSelectOption.id!.toString(),
        getOptionText: (
          refuellingOrderStatusSelectOption: RefuellingOrderStatusSelectOption
        ) => refuellingOrderStatusSelectOption.text,
        getValue: (report: RefuellingOrdersReport) => report.refuellingOrderStatusesSelectOptions,
        setValue: (
          report: RefuellingOrdersReport,
          refuellingOrderStatusesSelectOptions: RefuellingOrderStatusSelectOption[] | null
        ) => {
          return {
            ...report,
            refuellingOrderStatusesSelectOptions,
            refuellingOrderStatuses: refuellingOrderStatusesSelectOptions?.map((status) => status.id!)
          }
        }
      }),
      new MultiSelectFormField<RefuellingOrdersReport, RefuellingOrdersReportErrorsObject, Fuel>({
        title: refuellingOrdersReportsTextProvider.fuelsField(),
        clearable: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelsUseCase,
        getId: () => "fuels",
        getOptionId: (fuel: Fuel) => fuel.id!.toString(),
        getOptionText: (fuel: Fuel) => fuel.name,
        getValue: (report: RefuellingOrdersReport) => report.fuels,
        setValue: (report: RefuellingOrdersReport, fuels: Fuel[] | null) => {
          return {
            ...report,
            fuels,
            fuelIds: fuels?.map((fuel) => fuel.id!)
          }
        }
      })
    ]
  }
}
