import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import InstallationInstancesI18 from "../../i18n/InstallationInstancesI18"
import InstallationInstance from "../../../../core/domain/installation-instance/InstallationInstance"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class InstallationInstanceInfoProvider implements InfoProvider<InstallationInstance> {
  private readonly installationInstancesI18: InstallationInstancesI18

  constructor(parameters: {
    readonly installationInstanceI18: InstallationInstancesI18
  }) {
    this.installationInstancesI18 = parameters.installationInstanceI18
  }

  getEntity(): string {
    return Entity.INSTALLATION_INSTANCES
  }

  getObjectShortTitle(): string {
    return this.installationInstancesI18.getTextProvider().existObjectTitle()
  }

  getObjectTitle(): string {
    return this.installationInstancesI18.getTextProvider().existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: InstallationInstance
  }): InfoRow[] {
    const installationInstancesTextProvider = this.installationInstancesI18.getTextProvider()
    const appUrlProvider = new AppUrlProvider()

    return [
      new StringInfoRow({
        name: "guid",
        title: installationInstancesTextProvider.guidField(),
        value: object.guid
      }),
      new StringInfoRow({
        name: "type",
        title: installationInstancesTextProvider.typeField(),
        value: object.typeSelectOption?.text
      }),
      new StringInfoRow({
        name: "fuelCompany",
        entity: Entity.FUEL_COMPANIES,
        title: installationInstancesTextProvider.fuelCompanyField(),
        value: object?.gasStation?.fuelCompany?.name,
        url: (() => {
          const fuelCompany = object.gasStation?.fuelCompany
          return fuelCompany && appUrlProvider.buildShowFuelCompanyUrl({
            fuelCompanyId: fuelCompany.id!
          })
        })()
      }),
      new StringInfoRow({
        name: "gasStation",
        entity: Entity.GAS_STATIONS,
        title: installationInstancesTextProvider.gasStationField(),
        value: object?.gasStation?.name,
        url: (() => {
          const gasStation = object.gasStation
          return gasStation && appUrlProvider.buildShowGasStationUrl({
            gasStationId: gasStation.id!
          })
        })()
      }),
      new StringInfoRow({
        name: "blocked",
        title: installationInstancesTextProvider.statusField(),
        value: (() => {
          const { blocked = false } = object

          return (blocked ?? false) ?
            installationInstancesTextProvider.statusValueBlocked() :
            installationInstancesTextProvider.statusValueActive()
        })()
      })
    ]
  }

  getLinks(): LinkInfo[] {
    return []
  }

  getEditUrl({
    object
  }: {
    readonly object: InstallationInstance
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditInstallationInstanceUrl({
      installationInstanceId: object.id!
    })
  }
}
