import CarrierErrorsObjectAttributes from "../../../../../domain/carriers/CarrierErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"

export default class NetworkCarrierErrorsObjectAttributes implements CarrierErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly code?: NetworkAttributeError[] | null
}
