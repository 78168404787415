import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import FuelCompanyBalanceChangeDocument
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import FuelCompanyBalanceChangeDocumentError
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentError"
import FuelCompanyBalanceChangeDocumentsRepository from "../../repositories/FuelCompanyBalanceChangeDocumentsRepository"

export interface CreateFuelCompanyBalanceChangeDocumentParameters {
  readonly object: FuelCompanyBalanceChangeDocument
  readonly fuelCompanyId: string | number
}

export default class CreateFuelCompanyBalanceChangeDocumentUseCase
  implements CreateObjectUseCase<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentError> {
  private readonly fuelCompanyBalanceChangeDocumentsRepository: FuelCompanyBalanceChangeDocumentsRepository

  constructor(parameters: {
    readonly fuelCompanyBalanceChangeDocumentsRepository: FuelCompanyBalanceChangeDocumentsRepository
  }) {
    this.fuelCompanyBalanceChangeDocumentsRepository = parameters.fuelCompanyBalanceChangeDocumentsRepository
  }

  async call(
    parameters: CreateFuelCompanyBalanceChangeDocumentParameters
  ): Promise<CreateObjectResult<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentError>> {
    return await this.fuelCompanyBalanceChangeDocumentsRepository.createDocument(parameters)
  }
}
