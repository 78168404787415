import CoreTextProvider from "../CoreTextProvider"

export default class DefaultRuCoreTextProvider implements CoreTextProvider {
  somethingWentWrong(): string {
    return "Что-то пошло не так"
  }

  logout(): string {
    return "Выйти из акаунта"
  }

  loading(): string {
    return "Загрузка..."
  }

  goToNewObject(): string {
    return "Создать"
  }

  newObject(): string {
    return "Создание"
  }

  createObject(): string {
    return "Создать"
  }

  deleteObject(): string {
    return "Удалить"
  }

  updateObject(): string {
    return "Сохранить"
  }

  editObject(): string {
    return "Изменить"
  }

  clear(): string {
    return "Очистить"
  }

  select(): string {
    return "Выберите"
  }

  unlimited(): string {
    return "Без ограничений"
  }

  enterSearchQuery(): string {
    return "Поиск"
  }

  noObjects(): string {
    return "Нет данных"
  }

  noFoundedObjects(): string {
    return "По вашему запросу ничего не найдено"
  }

  loadMore(): string {
    return "Загрузить ещё"
  }

  input(): string {
    return "Введите"
  }

  settings(): string {
    return "Настройки"
  }

  appName(): string {
    return "Метаника"
  }

  appDescription(): string {
    return "Панель администратора для мобильных приложений Метаника - Водитель и Метаника - Заправщик"
  }

  retry(): string {
    return "Повторить загрузку"
  }

  cancel(): string {
    return "Отмена"
  }

  sundayShort(): string {
    return "Вс"
  }

  mondayShort(): string {
    return "Пн"
  }

  tuesdayShort(): string {
    return "Вт"
  }

  wednesdayShort(): string {
    return "Ср"
  }

  thursdayShort(): string {
    return "Чт"
  }

  fridayShort(): string {
    return "Пт"
  }

  saturdayShort(): string {
    return "Сб"
  }

  january(): string {
    return "Январь"
  }

  february(): string {
    return "Февраль"
  }

  march(): string {
    return "Март"
  }

  april(): string {
    return "Апрель"
  }

  may(): string {
    return "Май"
  }

  june(): string {
    return "Июнь"
  }

  july(): string {
    return "Июль"
  }

  august(): string {
    return "Август"
  }

  september(): string {
    return "Сентябрь"
  }

  october(): string {
    return "Октябрь"
  }

  november(): string {
    return "Ноябрь"
  }

  december(): string {
    return "Декабрь"
  }

  selectDate(): string {
    return "Выберите дату"
  }

  applyFilter(): string {
    return "Применить"
  }

  resetFilter(): string {
    return "Сбросить"
  }

  addNestedObject(): string {
    return "Добавить"
  }

  deleteNestedObject(): string {
    return "Удалить"
  }

  supportEmailMessage(): string {
      return "Служба технической поддержки:"
  }

  supportEmailAddress(): string {
      return "support@methanica.ru"
  }
}
