import { Expose, Type } from "class-transformer"
import NetworkRefuellingOrdersReportErrorsObject from "./NetworkRefuellingOrdersReportErrorsObject"
import NetworkExecutionError from "../../../errors/NetworkExecutionError"
import RefuellingOrdersReportError from "../../../../../domain/refuelling-orders-reports/RefuellingOrdersReportError"

export default class NetworkRefuellingOrdersReportErrorResponseBody extends NetworkExecutionError implements RefuellingOrdersReportError {

  @Expose()
  @Type(() => NetworkRefuellingOrdersReportErrorsObject)
  readonly errorsObject?: NetworkRefuellingOrdersReportErrorsObject | null
}
