import React, { createContext, useContext } from "react"
import AuthenticationTheme from "../../theme/AuthenticationTheme"

export const AuthenticationThemeProviderContext: React.Context<AuthenticationTheme | null> =
  createContext<AuthenticationTheme | null>(null)

export function useAuthenticationThemeProvider(): AuthenticationTheme {
  return useContext(AuthenticationThemeProviderContext)!
}

export interface CoreThemeProviderProps {
  readonly children?: React.ReactNode
  readonly theme: AuthenticationTheme
}

export function AuthenticationThemeProvider({
  children,
  theme
}: CoreThemeProviderProps) {
  return (
    <AuthenticationThemeProviderContext.Provider value={theme}>
      {children}
    </AuthenticationThemeProviderContext.Provider>
  )
}
