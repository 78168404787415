import CarrierBalanceChangeDocumentFormPage from "./CarrierBalanceChangeDocumentFormPage"
import React from "react"
import CarrierBalanceChangeDocumentFormPresenter from "./CarrierBalanceChangeDocumentFormPresenter"
import { Params, useNavigate, useParams } from "react-router-dom"
import useLocationContext, { LocationContext } from "../../../../../../admin/lib/presenter/useLocationContext"
import { useCoreTextProvider } from "../../../../../../admin/core/presentation/contexts/CoreTextProviderContext"
import { carrierIdUrlPart } from "../../../../../core/presentation/constants/urlParts"

interface Props {
  readonly providePresenter: (parameters: {
    readonly carrierId: string | number
  }) => CarrierBalanceChangeDocumentFormPresenter
}

export default function CarrierBalanceChangeDocumentFormPageRouteElement({
  providePresenter
}: Props) {
  const urlParams: Readonly<Params<string>> = useParams()
  const carrierId: string | null = urlParams[carrierIdUrlPart]!
  const locationContext: LocationContext = useLocationContext()
  const navigate = useNavigate()
  const coreTextProvider = useCoreTextProvider()

  return (
    <CarrierBalanceChangeDocumentFormPage
      key={locationContext.locationId}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            carrierId
          })
        })
      }}
      coreTextProvider={coreTextProvider}
      navigate={navigate}
    />
  )
}
