import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import GasStationBreakErrorsObjectAttributes
  from "../../../../../domain/gas-station-breaks/GasStationBreakErrorsObjectAttributes"

export default class NetworkGasStationBreaksErrorsObjectAttributes implements GasStationBreakErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly beginDateTime?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly endDateTime?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly gasStationId?: NetworkAttributeError[] | null
}
