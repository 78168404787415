import { Expose, Type } from "class-transformer"
import NetworkFuelCompaniesRequestFilter from "./NetworkFuelCompaniesRequestFilter"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkFuelCompaniesRequestQuery {
  @Expose()
  @Type(() => NetworkFuelCompaniesRequestFilter)
  readonly filter?: NetworkFuelCompaniesRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  @Type(() => String)
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkFuelCompaniesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
