import GetCarrierBalanceTransactionsUseCase from "../../domain/use-cases/carrier-balance-transactions/GetCarrierBalanceTransactionsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CarrierBalancesDataDiModule from "./CarrierBalancesDataDiModule"
import GetCarrierForCarrierBalancesUseCase from "../../domain/use-cases/carriers/GetCarrierForCarrierBalancesUseCase"
import GetCarrierBalanceChangeDocumentsUseCase
  from "../../domain/use-cases/carrier-balance-change-documents/GetCarrierBalanceChangeDocumentsUseCase"
import CreateCarrierBalanceChangeDocumentUseCase
  from "../../domain/use-cases/carrier-balance-change-documents/CreateCarrierBalanceChangeDocumentUseCase"

export default interface CarrierBalancesDomainDiModule {
  provideGetCarrierBalanceChangeTransactionsUseCase(): GetCarrierBalanceTransactionsUseCase

  provideGetCarrierBalanceChangeDocumentsUseCase(): GetCarrierBalanceChangeDocumentsUseCase

  provideCreateCarrierBalanceChangeDocumentUseCase(): CreateCarrierBalanceChangeDocumentUseCase

  provideGetCarrierForCarrierBalancesUseCase(): GetCarrierForCarrierBalancesUseCase
}

export class DefaultCarrierBalancesDomainDiModule extends DiModule implements CarrierBalancesDomainDiModule {
  private readonly carrierBalancesDataDiModule: CarrierBalancesDataDiModule

  constructor(parameters: {
    readonly carrierBalancesDiModule: CarrierBalancesDataDiModule
  }) {
    super()
    this.carrierBalancesDataDiModule = parameters.carrierBalancesDiModule
  }

  provideGetCarrierBalanceChangeTransactionsUseCase(): GetCarrierBalanceTransactionsUseCase {
    return this.single(
      GetCarrierBalanceTransactionsUseCase.name,
      () => new GetCarrierBalanceTransactionsUseCase({
        carrierBalanceTransactionsRepository:
          this.carrierBalancesDataDiModule.provideCarrierBalanceTransactionsRepository()
      })
    )
  }

  provideGetCarrierBalanceChangeDocumentsUseCase(): GetCarrierBalanceChangeDocumentsUseCase {
    return this.single(
      GetCarrierBalanceChangeDocumentsUseCase.name,
      () => new GetCarrierBalanceChangeDocumentsUseCase({
        carrierBalanceChangeDocumentsRepository:
          this.carrierBalancesDataDiModule.provideCarrierBalanceChangeDocumentsRepository()
      })
    )
  }

  provideCreateCarrierBalanceChangeDocumentUseCase(): CreateCarrierBalanceChangeDocumentUseCase {
    return this.single(
      CreateCarrierBalanceChangeDocumentUseCase.name,
      () => new CreateCarrierBalanceChangeDocumentUseCase({
        carrierBalanceChangeDocumentsRepository:
          this.carrierBalancesDataDiModule.provideCarrierBalanceChangeDocumentsRepository()
      })
    )
  }

  provideGetCarrierForCarrierBalancesUseCase(): GetCarrierForCarrierBalancesUseCase {
    return this.single(
      GetCarrierForCarrierBalancesUseCase.name,
      () => new GetCarrierForCarrierBalancesUseCase({
        carriersForCarrierBalancesRepository:
          this.carrierBalancesDataDiModule.provideCarriersForCarrierBalancesRepository()
      })
    )
  }
}
