import InstallationInstancesRepository from "../../repositories/InstallationInstancesRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import InstallationInstance from "../../../../../core/domain/installation-instance/InstallationInstance"
import InstallationInstanceError from "../../../../../core/domain/installation-instance/InstallationInstanceError"

export interface UpdateInstallationInstanceParameters {
  readonly objectId: string
  readonly object: InstallationInstance
}

export default class UpdateInstallationInstanceUseCase
  implements UpdateObjectUseCase<InstallationInstance, InstallationInstanceError> {

  private readonly installationInstancesRepository: InstallationInstancesRepository

  constructor(parameters: {
    readonly installationInstanceRepository: InstallationInstancesRepository
  }) {
    this.installationInstancesRepository = parameters.installationInstanceRepository
  }

  async call(parameters: UpdateInstallationInstanceParameters):
    Promise<UpdateObjectResult<InstallationInstance, InstallationInstanceError>> {
    return await this.installationInstancesRepository.updateInstallationInstance(parameters)
  }
}
