import { GetObjectResult } from "../../../../features/objects/domain/use-cases/objects/GetObjectUseCase"
import UserProfile from "../../../domain/entities/user-profile/UserProfile"
import UserProfilesMapper from "../../mappers/user-profile/UserProfilesMapper"
import PermissionGroupMapper from "../../mappers/user-profile/PermissionGroupMapper"
import UserProfileRepository from "../../../domain/repositories/UserProfileRepository"
import UserProfileNetworkSource from "../../sources/user-profile/UserProfileNetworkSource"
import UserProfileLocalSource from "../../sources/user-profile/UserProfileLocalSource"
import { CheckPermissionDeniedParameters } from "../../../domain/use-cases/user-profile/CheckPermissionDeniedUseCase"

export default class DefaultUserProfileRepository implements UserProfileRepository {
  private readonly userProfileNetworkSource: UserProfileNetworkSource
  private readonly userProfileLocalSource: UserProfileLocalSource

  constructor(parameters: {
    readonly userProfileNetworkSource: UserProfileNetworkSource
    readonly userProfileLocalSource: UserProfileLocalSource
  }) {
    this.userProfileNetworkSource = parameters.userProfileNetworkSource
    this.userProfileLocalSource = parameters.userProfileLocalSource
  }

  async getUserProfile(): Promise<GetObjectResult<UserProfile>> {
    const result = await this.userProfileNetworkSource.getUserProfile()

    switch (result.type) {
      case "success": {
        const { data: { userProfile, permissionGroup } } = result

        const mappedPermissionGroup =
          permissionGroup && new PermissionGroupMapper().mapNetworkToDomain({ permissionGroup })
        this.userProfileLocalSource.setPermissionGroup(mappedPermissionGroup ?? undefined)

        return {
          type: "success",
          data: new UserProfilesMapper().mapNetworkToDomain({
            userProfile: userProfile!
          })
        }
      }
      case "error": {
        return {
          type: "error",
          error: {
            message: result.error.message
          }
        }
      }
      default:
        return result
    }
  }

  checkPermissionDenied(parameters: CheckPermissionDeniedParameters): boolean {
    return this.userProfileLocalSource.checkPermissionsDenied(parameters)
  }

  clearPermissionGroup() {
    return this.userProfileLocalSource.clearPermissionGroup()
  }

  getUserType(): string {
    return this.userProfileLocalSource.getUserType()
  }
}
