import RefuellingOrdersReportsI18n from "../../i18n/RefuellingOrdersReportsI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import RefuellingOrdersReportsTextProvider from "../../i18n/RefuellingOrdersReportsTextProvider"

export default interface RefuellingOrdersReportsI18nDiModule {
  provideRefuellingOrdersReportsI18n(): RefuellingOrdersReportsI18n
}

export class DefaultRefuellingOrdersReportsI18nDiModule
  extends I18nDiModule<RefuellingOrdersReportsTextProvider>
  implements RefuellingOrdersReportsI18nDiModule {

  provideRefuellingOrdersReportsI18n(): RefuellingOrdersReportsI18n {
    return this.single(
      RefuellingOrdersReportsI18n.name,
      () => new RefuellingOrdersReportsI18n(this.getI18nParameters())
    )
  }
}
