import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import FuelCompanyCommercialTermsI18 from "../../i18n/FuelCompanyCommercialTermsI18"
import CarrierCommercialTerm from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import DateInfoRow from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/DateInfoRow"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import ArrayInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/ArrayInfoRow"
import PricingType from "../../../../core/domain/commercial-terms/PricingType"
import FuelCompanyCommercialTerm from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class FuelCompanyCommercialTermInfoProvider implements InfoProvider<FuelCompanyCommercialTerm> {
  private readonly timeZone: string
  private readonly fuelCompanyCommercialTermsI18: FuelCompanyCommercialTermsI18

  constructor(parameters: {
    readonly timeZone: string
    readonly fuelCompanyCommercialTermsI18: FuelCompanyCommercialTermsI18
  }) {
    this.timeZone = parameters.timeZone
    this.fuelCompanyCommercialTermsI18 = parameters.fuelCompanyCommercialTermsI18
  }

  getEntity(): string {
    return Entity.FUEL_COMPANY_COMMERCIAL_TERMS
  }

  getObjectShortTitle(_parameters: {
    readonly object?: CarrierCommercialTerm
  }): string {
    return this.fuelCompanyCommercialTermsI18.getTextProvider().existObjectTitle()
  }

  getObjectTitle(_parameters: {
    readonly object?: CarrierCommercialTerm
  }): string {
    return this.fuelCompanyCommercialTermsI18.getTextProvider()
      .existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: FuelCompanyCommercialTerm
  }): InfoRow[] {
    const appUrlProvider = new AppUrlProvider()
    const fuelCompanyCommercialTermsTextProvider = this.fuelCompanyCommercialTermsI18.getTextProvider()

    const {
      carrier,
      fuelCompany,
      fuel,
      gasStations
    } = object

    return [
      new StringInfoRow({
        name: "fuelCompany",
        entity: Entity.FUEL_COMPANIES,
        title: fuelCompanyCommercialTermsTextProvider.fuelCompanyField(),
        value: fuelCompany?.name,
        url: fuelCompany && appUrlProvider.buildShowFuelCompanyUrl({
          fuelCompanyId: fuelCompany.id!
        })
      }),
      new StringInfoRow({
        name: "carrier",
        entity: Entity.CARRIERS,
        title: fuelCompanyCommercialTermsTextProvider.carrierField(),
        value: carrier?.name,
        url: carrier && appUrlProvider.buildShowCarrierUrl({
          carrierId: carrier.id!
        })
      }),
      new StringInfoRow({
        name: "fuel",
        title: fuelCompanyCommercialTermsTextProvider.fuelField(),
        value: fuel?.name
      }),
      new ArrayInfoRow({
        name: "gasStations",
        entity: Entity.GAS_STATIONS,
        title: fuelCompanyCommercialTermsTextProvider.gasStationsIdField(),
        items: gasStations?.map((gasStation) => {
          return {
            key: `${gasStation.id!}`,
            value: gasStation.name,
            url: appUrlProvider.buildShowGasStationUrl({
              gasStationId: gasStation.id!
            })
          }
        })
      }),
      new DateInfoRow({
        name: "beginDate",
        title: fuelCompanyCommercialTermsTextProvider.beginDateField(),
        value: object.beginDate,
        format: DateTimeFormat.DATE
      }),
      new DateInfoRow({
        name: "endDate",
        title: fuelCompanyCommercialTermsTextProvider.endDateField(),
        value: object.endDate,
        format: DateTimeFormat.DATE
      }),
      new StringInfoRow({
        name: "pricingType",
        title: fuelCompanyCommercialTermsTextProvider.pricingTypeField(),
        value: (() => {
          switch (object.pricingType) {
            case PricingType.FIXED:
              return fuelCompanyCommercialTermsTextProvider.pricingTypePriceValue({
                formattedPrice: object.formattedPrice
              })
            case PricingType.DISCOUNT:
              return fuelCompanyCommercialTermsTextProvider.pricingTypeDiscountPercentageValue({
                formattedDiscountPercentage: object.formattedDiscountPercentage
              })
            default:
              return null
          }
        })()
      }),
      new StringInfoRow({
        name: "documentUrl",
        title: fuelCompanyCommercialTermsTextProvider.documentUrlField(),
        value: object.documentUrl,
        url: object.documentUrl,
        openInNewTab: true
      })
    ]
  }

  getLinks(_parameters: {
    readonly object: FuelCompanyCommercialTerm
  }): LinkInfo[] {
    return []
  }

  getEditUrl({
    object
  }: {
    readonly object: FuelCompanyCommercialTerm
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditFuelCompanyCommercialTermUrl({
      fuelCompanyCommercialTermId: object.id!
    })
  }
}
