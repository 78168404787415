import Carrier from "../../../../../core/domain/carriers/Carrier"
import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import CarriersForCurrentCarrierBalancesRepository from "../../repositories/CarriersForCurrentCarrierBalancesRepository"

export interface GetCarrierForCarrierBalancesParameters {
}

export default class GetCarrierForCurrentCarrierBalancesUseCase implements GetObjectUseCase<Carrier> {
  private readonly carriersForCarrierBalancesRepository: CarriersForCurrentCarrierBalancesRepository

  constructor(parameters: {
    readonly carriersForCarrierBalancesRepository: CarriersForCurrentCarrierBalancesRepository
  }) {
    this.carriersForCarrierBalancesRepository = parameters.carriersForCarrierBalancesRepository
  }

  async call(parameters: GetCarrierForCarrierBalancesParameters): Promise<GetObjectResult<Carrier>> {
    return await this.carriersForCarrierBalancesRepository.getCarrier(parameters)
  }
}
