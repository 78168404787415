import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import Fuel from "../../../../../core/domain/fuels/Fuel"
import FuelsForFuelPricesRepository from "../../repositories/FuelsForFuelPricesRepository"

export interface GetFuelsForFuelPriceParameters extends GetObjectsParameters {
}

export default class GetFuelsForFuelPriceUseCase implements GetObjectsUseCase<Fuel> {
  private readonly fuelsForFuelPricesRepository: FuelsForFuelPricesRepository

  constructor(parameters: {
    readonly fuelsForFuelPricesRepository: FuelsForFuelPricesRepository
  }) {
    this.fuelsForFuelPricesRepository = parameters.fuelsForFuelPricesRepository
  }

  async call(parameters: GetFuelsForFuelPriceParameters): Promise<GetObjectsResult<Fuel>> {
    return await this.fuelsForFuelPricesRepository.getFuels(parameters)
  }
}
