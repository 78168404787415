import LegalEntity from "../../domain/legal-entities/LegalEntity"
import NetworkLegalEntity from "../entities/legal-entities/NetworkLegalEntity"

export default class LegalEntitiesMapper {
  mapNetworkToDomain({
    legalEntity
  }: {
    readonly legalEntity: NetworkLegalEntity
  }): LegalEntity {
    return {
      id: legalEntity.id,
      name: legalEntity.name,
      individualTaxpayerNumber: legalEntity.individualTaxpayerNumber,
      taxRegistrationReasonCode: legalEntity.taxRegistrationReasonCode
    }
  }

  mapDomainToNetwork({
    legalEntity
  }: {
    readonly legalEntity: LegalEntity
  }): NetworkLegalEntity {
    return {
      name: legalEntity.name,
      individualTaxpayerNumber: legalEntity.individualTaxpayerNumber,
      taxRegistrationReasonCode: legalEntity.taxRegistrationReasonCode
    }
  }
}
