import NetworkCarrierBalanceChangeDocumentErrorsObjectAttributes from "./NetworkCarrierBalanceChangeDocumentErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import CarrierBalanceChangeDocumentErrorsObject
  from "../../../../../domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentErrorsObject"

export default class NetworkCarrierBalanceChangeDocumentErrorsObject
  implements CarrierBalanceChangeDocumentErrorsObject {
  @Expose()
  @Type(() => NetworkCarrierBalanceChangeDocumentErrorsObjectAttributes)
  readonly attributes?: NetworkCarrierBalanceChangeDocumentErrorsObjectAttributes | null
}
