import { Expose, Type } from "class-transformer"

export default class NetworkUsersRequestFilter {
  @Expose()
  @Type(() => String)
  readonly query?: string | null

  @Expose()
  @Type(() => Number)
  readonly ids?: string[] | null

  @Expose()
  @Type(() => Number)
  readonly carrierIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyIds?: number[] | null

  constructor(parameters: {
    readonly query?: string | null
    readonly ids?: string[] | null
    readonly carrierIds?: number[] | null
    readonly fuelCompanyIds?: number[] | null
  }) {
    this.query = parameters.query
    this.carrierIds = parameters.carrierIds
    this.fuelCompanyIds = parameters.fuelCompanyIds
    this.ids = parameters.ids
  }
}
