import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import CarrierUsersDomainDiModule from "./CarrierUsersDomainDiModule"
import CarrierUsersTableProvider from "../../presentation/table-providers/CarrierUsersTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import CarrierUsersFormProvider from "../../presentation/form-providers/CarrierUsersFormProvider"
import CarrierUserInfoProvider from "../../presentation/info-providers/CarrierUserInfoProvider"
import CarrierUsersI18nDiModule from "./CarrierUsersI18nDiModule"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "carrierUsers"

export default interface CarrierUsersPresentationDiModule {
  provideCarrierUsersListPage(): React.ReactNode

  provideCarrierUserInfoPage(): React.ReactNode

  provideCarrierUserFormPage(): React.ReactNode
}

export class DefaultCarrierUsersPresentationDiModule
  extends DiModule
  implements CarrierUsersPresentationDiModule {

  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly carrierUsersI18nDiModule: CarrierUsersI18nDiModule
  private readonly carrierUsersDomainDiModule: CarrierUsersDomainDiModule

  constructor(parameters: {
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly carrierUsersI18nDiModule: CarrierUsersI18nDiModule
    readonly carrierUsersDomainDiModule: CarrierUsersDomainDiModule
  }) {
    super()
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.carrierUsersI18nDiModule = parameters.carrierUsersI18nDiModule
    this.carrierUsersDomainDiModule = parameters.carrierUsersDomainDiModule
  }

  provideCarrierUsersListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.carrierUsersDomainDiModule.provideGetCarrierUsersUseCase(),
      tableProvider: this.provideCarrierUsersTableProvider()
    })
  }

  private provideCarrierUsersTableProvider(): CarrierUsersTableProvider {
    return this.single(
      CarrierUsersTableProvider.name,
      () => new CarrierUsersTableProvider({
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase(),
        usersI18: this.carrierUsersI18nDiModule.provideCarrierUsersI18n(),
        getCarriersUseCase: this.carrierUsersDomainDiModule.provideGetCarriersForCarrierUsersUseCase()
      })
    )
  }

  provideCarrierUserFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.carrierUsersDomainDiModule.provideGetCarrierUserUseCase(),
      createObjectUseCase: this.carrierUsersDomainDiModule.provideCreateCarrierUserUseCase(),
      updateObjectUseCase: this.carrierUsersDomainDiModule.provideUpdateCarrierUsersUseCase(),
      formProvider: this.provideCarrierUserFormProvider()
    })
  }

  private provideCarrierUserFormProvider(): CarrierUsersFormProvider {
    return this.single(
      CarrierUsersFormProvider.name,
      () => new CarrierUsersFormProvider({
        usersI18: this.carrierUsersI18nDiModule.provideCarrierUsersI18n(),
        appI18: this.appI18nDiModule.provideAppI18n(),
        getCarriersUseCase: this.carrierUsersDomainDiModule.provideGetCarriersForCarrierUsersUseCase()
      })
    )
  }

  provideCarrierUserInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.carrierUsersDomainDiModule.provideGetCarrierUserUseCase(),
      infoProvider: this.provideCarrierUserInfoProvider()
    })
  }

  private provideCarrierUserInfoProvider(): CarrierUserInfoProvider {
    return this.single(
      CarrierUserInfoProvider.name,
      () => new CarrierUserInfoProvider({
        usersI18: this.carrierUsersI18nDiModule.provideCarrierUsersI18n(),
        appI18: this.appI18nDiModule.provideAppI18n()
      })
    )
  }
}
