import React from "react"
import styles from "./ArrayTableValueComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import TableColumn, { TableColumnAlignment } from "../../../entities/tables/TableColumn"
import ArrayTableValue from "../../../entities/tables/table-value-by-type/ArrayTableValue"
import isBlank from "../../../../../../lib/isBlank"
import { Link } from "react-router-dom"

export default function ArrayTableValueComponent<DomainObject>({
  arrayTableValue,
  column
}: {
  readonly column: TableColumn<DomainObject>
  readonly arrayTableValue: ArrayTableValue
}) {
  const theme = useCoreThemeProvider()

  const alignmentClassName = (() => {
    switch (column.getColumnAlignment()) {
      case TableColumnAlignment.RIGHT:
        return styles.rightAlignment
      default:
        return undefined
    }
  })()

  const canShowUrl = column.isUrlVisibleByPermission() === true

  return (
    <div className={`${styles.root} ${alignmentClassName}`}>
      {arrayTableValue.getValues().map((value) => {
        const { url, text } = value
        if (isBlank(url) || !canShowUrl) {
          return (
            <div
              key={value.key}
              className={theme.tableRow1PrimaryClassName()}
            >
              {text}
            </div>
          )
        }

        return (
          <Link
            key={value.key}
            className={`${styles.link} ${theme.tableRow1PrimaryClassName()}`}
            to={url}
          >
            {text}
          </Link>
        )
      })}
    </div>
  )
}
