import React from "react"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import styles from "./ObjectHeaderComponent.module.scss"

export default function ObjectHeaderComponent({
  title
}: {
  readonly title?: string
}) {
  const theme = useCoreThemeProvider()

  return (
    <div className={`${styles.root} ${theme.objectHeaderClassName()}`}>
      <h1 className={`${styles.title} ${theme.headline1PrimaryClassName()}`}>
        {title}
      </h1>
    </div>
  )
}
