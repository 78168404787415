import InstallationInstancesRepository from "../../domain/repositories/InstallationInstancesRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultInstallationInstancesRepository from "../../data/repositories/DefaultInstallationInstancesRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import GasStationsForInstallationInstancesRepository
  from "../../domain/repositories/GasStationsForInstallationInstancesRepository"
import DefaultGasStationsForInstallationInstancesRepository
  from "../../data/repositories/DefaultGasStationsForInstallationInstancesRepository"
import FuelCompaniesForInstallationInstancesRepository
  from "../../domain/repositories/FuelCompaniesForInstallationInstancesRepository"
import DefaultFuelCompaniesForInstallationInstancesRepository
  from "../../data/repositories/DefaultFuelCompaniesForInstallationInstancesRepository"
import InstallationInstanceTypesForInstallationInstancesRepository
  from "../../domain/repositories/InstallationInstanceTypesForInstallationInstancesRepository"
import DefaultInstallationInstanceTypesForInstallationInstancesRepository
  from "../../data/repositories/DefaultInstallationInstanceTypesForInstallationInstancesRepository"

export default interface InstallationInstancesDataDiModule {
  provideInstallationInstancesRepository(): InstallationInstancesRepository

  provideGasStationsForInstallationInstancesRepository(): GasStationsForInstallationInstancesRepository

  provideFuelCompaniesForInstallationInstancesRepository(): FuelCompaniesForInstallationInstancesRepository

  provideInstallationInstanceTypesForInstallationInstancesRepository():
    InstallationInstanceTypesForInstallationInstancesRepository
}

export class DefaultInstallationInstancesDataDiModule extends DiModule implements InstallationInstancesDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideInstallationInstancesRepository(): InstallationInstancesRepository {
    return this.single(
      DefaultInstallationInstancesRepository.name,
      () => new DefaultInstallationInstancesRepository({
        installationInstancesNetworkSource: this.appDataDiModule.provideInstallationInstancesNetworkSource(),
        installationInstanceTypesStaticSource: this.appDataDiModule.provideInstallationInstanceTypesStaticSource()
      })
    )
  }

  provideGasStationsForInstallationInstancesRepository(): GasStationsForInstallationInstancesRepository {
    return this.single(
      DefaultGasStationsForInstallationInstancesRepository.name,
      () => new DefaultGasStationsForInstallationInstancesRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }

  provideFuelCompaniesForInstallationInstancesRepository(): FuelCompaniesForInstallationInstancesRepository {
    return this.single(
      DefaultFuelCompaniesForInstallationInstancesRepository.name,
      () => new DefaultFuelCompaniesForInstallationInstancesRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }

  provideInstallationInstanceTypesForInstallationInstancesRepository():
    InstallationInstanceTypesForInstallationInstancesRepository {
    return this.single(
      DefaultInstallationInstanceTypesForInstallationInstancesRepository.name,
      () => new DefaultInstallationInstanceTypesForInstallationInstancesRepository({
        installationInstanceTypesStaticSource: this.appDataDiModule.provideInstallationInstanceTypesStaticSource()
      })
    )
  }
}
