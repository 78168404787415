import FuelCompanyCommercialTermsI18 from "../../i18n/FuelCompanyCommercialTermsI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import FuelCompanyCommercialTermsTextProvider from "../../i18n/FuelCompanyCommercialTermsTextProvider"

export default interface FuelCompanyCommercialTermsI18nDiModule {
  provideFuelCompanyCommercialTermsI18n(): FuelCompanyCommercialTermsI18
}

export class DefaultFuelCompanyCommercialTermsI18nDiModule
  extends I18nDiModule<FuelCompanyCommercialTermsTextProvider>
  implements FuelCompanyCommercialTermsI18nDiModule {

  provideFuelCompanyCommercialTermsI18n(): FuelCompanyCommercialTermsI18 {
    return this.single(
      FuelCompanyCommercialTermsI18.name,
      () => new FuelCompanyCommercialTermsI18(this.getI18nParameters())
    )
  }
}
