import RefuellingOrdersTextProvider from "../RefuellingOrdersTextProvider"
import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"

export default class DefaultRuRefuellingOrdersTextProvider implements RefuellingOrdersTextProvider {

  existObjectTitle({
    refuellingOrder
  }: {
    refuellingOrder: RefuellingOrder | null | undefined
  }): string {
    const defaultTitle = "Заправка"
    if (isBlank(refuellingOrder)) {
      return defaultTitle
    }

    const { code } = refuellingOrder
    const components = filterNotEmpty([code])
    return components.length > 0 ? `${defaultTitle} ${components.join(" ")}` : defaultTitle
  }

  refuellingOrders(): string {
    return "Заправки"
  }

  cancelledRefuellingOrders(): string {
    return "Отмененные заправки"
  }

  claimedRefuellingOrders(): string {
    return "Претензии"
  }

  codeField(): string {
    return "Код заправки"
  }

  driverField(): string {
    return "Водитель"
  }

  priceField(): string {
    return "Цена стелы"
  }

  refuellingStatusField(): string {
    return "Статус заправки"
  }

  tripField(): string {
    return "Рейс"
  }

  gasStationField(): string {
    return "АЗС"
  }

  carrierField(): string {
    return "Перевозчик"
  }

  fuelCompanyField(): string {
    return "Топливная компания"
  }

  fuelField(): string {
    return "Вид топлива"
  }

  beginDateField(): string {
    return "Дата/время начала заправки"
  }

  transportField(): string {
    return "Автомобиль"
  }

  transportStateNumberFilter(): string {
    return "Госномер автомобиля"
  }

  dateFromFilter(): string {
    return "Дата от"
  }

  dateToFilter(): string {
    return "Дата до"
  }

  fillingVolumeField(): string {
    return "Объем заправки"
  }

  moneyValueField(): string {
    return "Сумма заправки"
  }

  costValueField(): string {
    return "Сумма закупки"
  }

  fuelCompanyCommercialTermsField(): string {
    return "Коммерческое условие топливной компании"
  }

  carrierCommercialTermsField(): string {
    return "Коммерческое условие перевозчика"
  }

  move(): string {
    return "Перейти"
  }

  releasedFuelByCarrierInfoField(parameters: { refuellingOrder: RefuellingOrder | null | undefined }): string {
    const title = "Отпущенный объем топлива по данным заправщика"
    return this.addUomToTitle(title, parameters.refuellingOrder)
  }

  releasedFuelByFuelCompanyInfoField(parameters: { refuellingOrder: RefuellingOrder | null | undefined }): string {
    const title = "Объем топлива в заявке водителя"
    return this.addUomToTitle(title, parameters.refuellingOrder)
  }

  fuelCompanyFuelField(parameters: { refuellingOrder: RefuellingOrder | null | undefined }): string {
    const title = "Фактически отпущенное топливо по данным представителя топливной компании"
    return this.addUomToTitle(title, parameters.refuellingOrder)
  }

  carrierFuelField(parameters: { refuellingOrder: RefuellingOrder | null | undefined }): string {
    const title = "Фактически отпущенное топливо по данным представителя перевозчика"
    return this.addUomToTitle(title, parameters.refuellingOrder)
  }

  claimInfoTitle(): string {
    return "Справка:"
  }

  claimInfoDescription(): string {
    return "  Для того, чтобы заправка из статуса \"Претензия\" перешла в статус \"Завершена\", необходимо, чтобы " +
      "ваше введенное значение фактически отпущенного топлива совпало со значением представителя другой стороны.\n\n" +
      "  Для того, чтобы заправка перешла в статус \"Отменена\", необходимо чтобы каждая из сторон указала значение 0."
  }

  carrierContacts(): string {
    return "Контакты представителя перевозчика"
  }

  fuelCompanyContacts(): string {
    return "Контакты представителя топливной компании"
  }

  private addUomToTitle(title: string, refuellingOrder: RefuellingOrder | null | undefined) {
    const shortFormattedUom = refuellingOrder?.fuel?.shortFormattedUom
    if (isBlank(shortFormattedUom)) {
      return title
    }

    return `${title}, ${shortFormattedUom}`
  }
}
