import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelsForRefuellingOrdersRepository from "../../repositories/FuelsForRefuellingOrdersRepository"
import Fuel from "../../../../../core/domain/fuels/Fuel"

export interface GetFuelsForRefuellingOrdersParameters extends GetObjectsParameters {
}

export default class GetFuelsForRefuellingOrdersUseCase implements GetObjectsUseCase<Fuel> {
  private readonly fuelsForCarrierCommercialTermsRepository: FuelsForRefuellingOrdersRepository

  constructor(parameters: {
    readonly fuelsForCarrierCommercialTermsRepository: FuelsForRefuellingOrdersRepository
  }) {
    this.fuelsForCarrierCommercialTermsRepository = parameters.fuelsForCarrierCommercialTermsRepository
  }

  async call(parameters: GetFuelsForRefuellingOrdersParameters): Promise<GetObjectsResult<Fuel>> {
    return await this.fuelsForCarrierCommercialTermsRepository.getFuels(parameters)
  }
}
