import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarrierReconciliationReportsRepository from "../../repositories/CarrierReconciliationReportsRepository"
import CarrierReconciliationReport
  from "../../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReport"

export interface GetCarrierReconciliationReportsParameters extends GetObjectsParameters {
}

export default class GetCarrierReconciliationReportsUseCase
  implements GetObjectsUseCase<CarrierReconciliationReport> {
  private readonly carrierReconciliationReportsRepository: CarrierReconciliationReportsRepository

  constructor(parameters: {
    readonly carrierReconciliationReportsRepository: CarrierReconciliationReportsRepository
  }) {
    this.carrierReconciliationReportsRepository = parameters.carrierReconciliationReportsRepository
  }

  async call(
    parameters: GetCarrierReconciliationReportsParameters
  ): Promise<GetObjectsResult<CarrierReconciliationReport>> {
    return await this.carrierReconciliationReportsRepository.getCarrierReconciliationReports(parameters)
  }
}
