import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkFuel from "../NetworkFuel"

export default class NetworkFuelsResponseBody {

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuels?: NetworkFuel[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
