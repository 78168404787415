import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelCompaniesForFuelCompanyBalancesRepository
  from "../../repositories/FuelCompaniesForFuelCompanyBalancesRepository"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"

export interface GetFuelCompanyForFuelCompanyBalancesParameters {
  readonly objectId: string
}

export default class GetFuelCompanyForFuelCompanyBalancesUseCase implements GetObjectUseCase<FuelCompany> {
  private readonly fuelCompaniesForFuelCompanyBalancesRepository: FuelCompaniesForFuelCompanyBalancesRepository

  constructor(parameters: {
    readonly fuelCompaniesForFuelCompanyBalancesRepository: FuelCompaniesForFuelCompanyBalancesRepository
  }) {
    this.fuelCompaniesForFuelCompanyBalancesRepository = parameters.fuelCompaniesForFuelCompanyBalancesRepository
  }

  async call(parameters: GetFuelCompanyForFuelCompanyBalancesParameters): Promise<GetObjectResult<FuelCompany>> {
    return await this.fuelCompaniesForFuelCompanyBalancesRepository.getFuelCompany(parameters)
  }
}
