import FuelCompaniesRepository from "../../repositories/FuelCompaniesRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompanyError from "../../../../../core/domain/fuel-companies/FuelCompanyError"

export interface UpdateFuelCompanyParameters {
  readonly objectId: string
  readonly object: FuelCompany
}

export default class UpdateFuelCompanyUseCase implements UpdateObjectUseCase<FuelCompany, FuelCompanyError> {
  private readonly fuelCompaniesRepository: FuelCompaniesRepository

  constructor(parameters: {
    readonly fuelCompaniesRepository: FuelCompaniesRepository
  }) {
    this.fuelCompaniesRepository = parameters.fuelCompaniesRepository
  }

  async call(parameters: UpdateFuelCompanyParameters): Promise<UpdateObjectResult<FuelCompany, FuelCompanyError>> {
    return await this.fuelCompaniesRepository.updateFuelCompany(parameters)
  }
}
