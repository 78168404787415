import CarrierReconciliationReportsRepository from "../../repositories/CarrierReconciliationReportsRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import CarrierReconciliationReport
  from "../../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReport"
import CarrierReconciliationReportError
  from "../../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReportError"

export interface CreateCarrierReconciliationReportParameters {
  readonly object: CarrierReconciliationReport
}

export default class CreateCarrierReconciliationReportUseCase
  implements CreateObjectUseCase<CarrierReconciliationReport, CarrierReconciliationReportError> {
  private readonly carrierReconciliationReportsRepository: CarrierReconciliationReportsRepository

  constructor(parameters: {
    readonly carrierReconciliationReportsRepository: CarrierReconciliationReportsRepository
  }) {
    this.carrierReconciliationReportsRepository = parameters.carrierReconciliationReportsRepository
  }

  async call(
    parameters: CreateCarrierReconciliationReportParameters
  ): Promise<CreateObjectResult<CarrierReconciliationReport, CarrierReconciliationReportError>> {
    return await this.carrierReconciliationReportsRepository.createCarrierReconciliationReport(parameters)
  }
}
