import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkFuelCompanyReconciliationReport from "../NetworkFuelCompanyReconciliationReport"

export default class NetworkFuelCompanyReconciliationReportsResponseBody {

  @Expose()
  @Type(() => NetworkFuelCompanyReconciliationReport)
  readonly reports?: NetworkFuelCompanyReconciliationReport[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
