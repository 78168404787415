import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import TransportsI18 from "../../i18n/TransportsI18"
import Transport from "../../../../core/domain/transport/Transport"
import ArrayInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/ArrayInfoRow"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class TransportInfoProvider implements InfoProvider<Transport> {
  private readonly transportsI18: TransportsI18

  constructor(parameters: {
    readonly transportsI18: TransportsI18
  }) {
    this.transportsI18 = parameters.transportsI18
  }

  getEntity(): string {
    return Entity.TRANSPORTS
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: Transport
  }): string {
    if (isBlank(object)) {
      return this.transportsI18.getTextProvider().existObjectTitle()
    }

    const { name } = object

    return isPresent(name) ? name : this.transportsI18.getTextProvider().existObjectTitle()
  }

  getObjectTitle({
    object
  }: {
    readonly object?: Transport
  }): string {
    if (isBlank(object)) {
      return this.transportsI18.getTextProvider().existObjectTitle()
    }

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.transportsI18.getTextProvider().existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: Transport
  }): InfoRow[] {
    const {
      transportFuels,
      carrier
    } = object

    const transportsTextProvider = this.transportsI18.getTextProvider()
    const appUrlProvider = new AppUrlProvider()

    return [
      new StringInfoRow({
        name: "carrier",
        entity: Entity.CARRIERS,
        title: transportsTextProvider.carrierField(),
        value: carrier?.name,
        url: carrier && appUrlProvider.buildShowCarrierUrl({
          carrierId: carrier.id!
        })
      }),
      new StringInfoRow({
        name: "model",
        title: transportsTextProvider.modelField(),
        value: object?.name
      }),
      new StringInfoRow({
        name: "vehicleCertificateNumber",
        title: transportsTextProvider.vehicleCertificateNumberField(),
        value: object?.vehicleCertificateNumber
      }),
      new StringInfoRow({
        name: "vinNumber",
        title: transportsTextProvider.vinNumberField(),
        value: object?.vinNumber
      }),
      new StringInfoRow({
        name: "stateNumber",
        title: transportsTextProvider.stateNumberField(),
        value: object?.stateNumber
      }),
      new ArrayInfoRow({
        name: "transportFuels",
        title: transportsTextProvider.fuelTypesField(),
        items: transportFuels?.map((transportFuel) => {
          return {
            key: `${transportFuel.id!}`,
            value: `${transportFuel.fuel?.name}: ${transportFuel.tankVolume} ${transportFuel.fuel?.shortFormattedUom}`
          }
        })
      }),
      new StringInfoRow({
        name: "blocked",
        title: transportsTextProvider.statusField(),
        value: (() => {
          const { blocked = false } = object

          return (blocked ?? false) ?
            transportsTextProvider.statusValueBlocked() :
            transportsTextProvider.statusValueActive()
        })()
      })
    ]
  }

  getLinks({ object }: {
    readonly object: Transport
  }): LinkInfo[] {
    const urlProvider = new AppUrlProvider()
    const fuelCompaniesTextProvider = this.transportsI18.getTextProvider()

    return [
      {
        id: "refuellingOrders",
        entity: Entity.REFUELLING_ORDERS,
        title: fuelCompaniesTextProvider.refuellingOrdersLink(),
        url: urlProvider.buildRefuellingOrdersUrl({
          transportId: object.id
        })
      }
    ]
  }

  getEditUrl({
    object
  }: {
    readonly object: Transport
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditTransportUrl({
      transportId: object.id!
    })
  }
}
