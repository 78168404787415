import GetObjectUseCase, {
  GetObjectResult
} from "../../../../objects/domain/use-cases/objects/GetObjectUseCase"
import Menu from "../../../../../core/domain/entities/menus/Menu"
import MenusRepository from "../../repositories/menus/MenusRepository"

export default class GetMenuUseCase implements GetObjectUseCase<Menu> {
  private readonly menusRepository: MenusRepository

  constructor(parameters: {
    readonly menusRepository: MenusRepository
  }) {
    this.menusRepository = parameters.menusRepository
  }

  async call(parameters: { readonly objectId: string }): Promise<GetObjectResult<Menu>> {
    return await this.menusRepository.getMenu(parameters)
  }
}
