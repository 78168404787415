import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStationsForGasStationBreaksRepository from "../../repositories/GasStationsForGasStationBreaksRepository"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"

export interface GetGasStationsForGasStationBreaksParameters extends GetObjectsParameters {
}

export default class GetGasStationsForGasStationBreaksUseCase implements GetObjectsUseCase<GasStation> {
  private readonly gasStationsForGasStationBreaksRepository: GasStationsForGasStationBreaksRepository

  constructor(parameters: {
    readonly gasStationsForGasStationBreaksRepository: GasStationsForGasStationBreaksRepository
  }) {
    this.gasStationsForGasStationBreaksRepository = parameters.gasStationsForGasStationBreaksRepository
  }

  async call(parameters: GetGasStationsForGasStationBreaksParameters): Promise<GetObjectsResult<GasStation>> {
    return await this.gasStationsForGasStationBreaksRepository.getGasStations(parameters)
  }
}
