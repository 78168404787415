import AppI18 from "../../../i18n/AppI18"
import PricingType, { PricingTypeSelectOption } from "../../../domain/commercial-terms/PricingType"

export default class PricingTypesStaticSource {
  private readonly appI18n: AppI18

  constructor(parameters: {
    readonly appI18n: AppI18
  }) {
    this.appI18n = parameters.appI18n
  }

  async getPricingTypes(): Promise<PricingTypeSelectOption[]> {
    return [
      {
        id: PricingType.FIXED,
        text: this.appI18n.getTextProvider().pricingTypeFixedText()
      },
      {
        id: PricingType.DISCOUNT,
        text: this.appI18n.getTextProvider().pricingTypeDiscountText()
      }
    ]
  }
}
