import GetFuelCompanyReconciliationReportsUseCase
  from "../../domain/use-cases/fuel-company-reconciliation-reports/GetFuelCompanyReconciliationReportsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import FuelCompanyReconciliationReportsDataDiModule from "./FuelCompanyReconciliationReportsDataDiModule"
import CreateFuelCompanyReconciliationReportUseCase
  from "../../domain/use-cases/fuel-company-reconciliation-reports/CreateFuelCompanyReconciliationReportUseCase"
import GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase
  from "../../domain/use-cases/fuel-company/GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase"

export default interface FuelCompanyReconciliationReportsDomainDiModule {
  provideGetFuelCompanyReconciliationReportsUseCase(): GetFuelCompanyReconciliationReportsUseCase

  provideCreateFuelCompanyReconciliationReportUseCase(): CreateFuelCompanyReconciliationReportUseCase

  provideGetFuelCompaniesForFuelCompanyReconciliationReportsUseCase(): GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase
}

export class DefaultFuelCompanyReconciliationReportsDomainDiModule
  extends DiModule
  implements FuelCompanyReconciliationReportsDomainDiModule {
  private readonly fuelCompanyReconciliationReportsDataDiModule: FuelCompanyReconciliationReportsDataDiModule

  constructor(parameters: {
    readonly fuelCompanyReconciliationReportsDataDiModule: FuelCompanyReconciliationReportsDataDiModule
  }) {
    super()
    this.fuelCompanyReconciliationReportsDataDiModule = parameters.fuelCompanyReconciliationReportsDataDiModule
  }

  provideGetFuelCompanyReconciliationReportsUseCase(): GetFuelCompanyReconciliationReportsUseCase {
    return this.single(
      GetFuelCompanyReconciliationReportsUseCase.name,
      () => new GetFuelCompanyReconciliationReportsUseCase({
        fuelCompanyReconciliationReportsRepository:
          this.fuelCompanyReconciliationReportsDataDiModule.provideFuelCompanyReconciliationReportsRepository()
      })
    )
  }

  provideCreateFuelCompanyReconciliationReportUseCase(): CreateFuelCompanyReconciliationReportUseCase {
    return this.single(
      CreateFuelCompanyReconciliationReportUseCase.name,
      () => new CreateFuelCompanyReconciliationReportUseCase({
        fuelCompanyReconciliationReportsRepository:
          this.fuelCompanyReconciliationReportsDataDiModule.provideFuelCompanyReconciliationReportsRepository()
      })
    )
  }

  provideGetFuelCompaniesForFuelCompanyReconciliationReportsUseCase(): GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase {
    return this.single(
      GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase.name,
      () => new GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase({
        fuelCompaniesForFuelCompanyReconciliationReportsRepository:
          this.fuelCompanyReconciliationReportsDataDiModule.provideFuelCompaniesForFuelCompanyReconciliationReportsRepository()
      })
    )
  }
}
