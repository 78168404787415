import DiModule from "../../../../lib/di/DiModule"
import React from "react"
import ForbiddenPresenter from "../../presentation/pages/ForbiddenPresenter"
import ForbiddenPageRouteElement from "../../presentation/pages/ForbiddenPageRouteElement"
import ForbiddenThemeDiModule from "./ForbiddenThemeDiModule"
import ForbiddenI18nDiModule from "./ForbiddenI18nDiModule"

export default interface ForbiddenPresentationDiModule {
  forbiddenPage(): React.ReactNode
}

export class DefaultForbiddenPresentationDiModule
  extends DiModule
  implements ForbiddenPresentationDiModule {

  private readonly forbiddenThemeDiModule: ForbiddenThemeDiModule
  private readonly forbiddenI18nDiModule: ForbiddenI18nDiModule

  constructor(parameters: {
    readonly forbiddenThemeDiModule: ForbiddenThemeDiModule
    readonly forbiddenI18nDiModule: ForbiddenI18nDiModule
  }) {
    super()

    this.forbiddenThemeDiModule = parameters.forbiddenThemeDiModule
    this.forbiddenI18nDiModule = parameters.forbiddenI18nDiModule
  }

  forbiddenPage(): React.ReactNode {
    return (
      <ForbiddenPageRouteElement
        providePresenter={() => this.provideForbiddenPresenter()}
        forbiddenTheme={this.forbiddenThemeDiModule.provideForbiddenTheme()}
        forbiddenI18n={this.forbiddenI18nDiModule.provideForbiddenI18n()}
      />
    )
  }

  protected provideForbiddenPresenter(): ForbiddenPresenter {
    return new ForbiddenPresenter()
  }
}
