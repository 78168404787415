import Presenter from "../../../../../lib/presenter/Presenter"
import RootView from "./RootView"
import IsSessionExistUseCase from "../../../../../core/domain/use-cases/sessions/IsSessionExistUseCase"
import DestroySessionUseCase from "../../../../../core/domain/use-cases/sessions/DestroySessionUseCase"
import MenuItem from "../../../../../core/domain/entities/menus/MenuItem"
import GetMenuUseCase from "../../../domain/use-cases/menus/GetMenuUseCase"
import UserProfile from "../../../../../core/domain/entities/user-profile/UserProfile"
import autoBind from "auto-bind"
import { GetObjectResult } from "../../../../objects/domain/use-cases/objects/GetObjectUseCase"
import Menu from "../../../../../core/domain/entities/menus/Menu"
import ClearPermissionGroupUseCase from "../../../../../core/domain/use-cases/user-profile/ClearPermissionGroupUseCase"
import GetUserProfileUseCase from "../../../../../core/domain/use-cases/user-profile/GetUserProfileUseCase"
import SubscribeToAuthorizationErrorUseCase
  from "../../../domain/use-cases/authorization-error/SubscribeToAuthorizationErrorUseCase"
import AuthorizationErrorEvent from "../../../domain/entities/AuthorizationErrorEvent"

const mainMenuId = "main"

export default class RootPresenter extends Presenter<RootView> {
  private readonly isSessionExistUseCase: IsSessionExistUseCase
  private readonly destroySessionUseCase: DestroySessionUseCase
  private readonly clearPermissionGroupUseCase: ClearPermissionGroupUseCase
  private readonly getMenuUseCase: GetMenuUseCase
  private readonly getUserProfileUseCase: GetUserProfileUseCase
  private readonly subscribeToAuthorizationErrorUseCase: SubscribeToAuthorizationErrorUseCase
  private menuItems: MenuItem[]
  private userProfile?: UserProfile

  constructor(parameters: {
    readonly isSessionExistUseCase: IsSessionExistUseCase
    readonly destroySessionUseCase: DestroySessionUseCase
    readonly clearPermissionGroupUseCase: ClearPermissionGroupUseCase
    readonly getMenuUseCase: GetMenuUseCase
    readonly getUserProfileUseCase: GetUserProfileUseCase
    readonly subscribeToAuthorizationErrorUseCase: SubscribeToAuthorizationErrorUseCase
  }) {
    super()

    this.isSessionExistUseCase = parameters.isSessionExistUseCase
    this.destroySessionUseCase = parameters.destroySessionUseCase
    this.clearPermissionGroupUseCase = parameters.clearPermissionGroupUseCase
    this.getMenuUseCase = parameters.getMenuUseCase
    this.getUserProfileUseCase = parameters.getUserProfileUseCase
    this.subscribeToAuthorizationErrorUseCase = parameters.subscribeToAuthorizationErrorUseCase

    this.menuItems = []

    autoBind(this)
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()
    this.subscribeToAuthorizationError()
    this.checkIsSessionExistAndShowRootViewState()
  }

  onLogoutClicked() {
    this.destroySessionAndShowNotAuthenticatedRootViewState()
  }

  private subscribeToAuthorizationError() {
    this.subscribeToAuthorizationErrorUseCase.call((event: AuthorizationErrorEvent) => {
      switch (event.type) {
        case "received":
          this.destroySessionAndShowNotAuthenticatedRootViewState()
      }
    })
  }

  private checkIsSessionExistAndShowRootViewState() {
    const isSessionExist: boolean = this.isSessionExistUseCase.call()

    if (isSessionExist) {
      this.showLoadingRootViewState()
      this.loadAndProcessLeftMenuData()
        .then()
    } else {
      this.showNotAuthenticatedRootViewState()
    }
  }

  private destroySessionAndShowNotAuthenticatedRootViewState() {
    this.clearPermissionGroupUseCase.call()
    this.destroySessionUseCase.call()
    this.showNotAuthenticatedRootViewState()
  }

  private showLoadingRootViewState() {
    this.getView()?.showRootViewState({
      type: "loading"
    })
  }

  private showLeftMenuData({
    menuItems = [],
    userProfile
  }: {
    readonly menuItems?: MenuItem[]
    readonly userProfile?: UserProfile
  }) {
    this.menuItems = menuItems
    this.userProfile = userProfile
    this.getView()?.showRootViewState({
      type: "authenticated",
      menuItems: this.menuItems,
      userProfile: this.userProfile
    })
  }

  private showNotAuthenticatedRootViewState() {
    this.getView()?.showRootViewState({
      type: "not_authenticated"
    })
  }

  private async loadAndProcessLeftMenuData() {
    const loadRootDataResults: LoadRootDataResult[] = await Promise.all([
      this.loadMenu(),
      this.loadUserProfile()
    ])

    let menuItems: MenuItem[] = []
    let userProfile: UserProfile = {}
    const allDataLoadedSuccessfully = loadRootDataResults.reduce((allDataLoaded, rootDataResult) => {
      switch (rootDataResult.type) {
        case "menu": {
          const { result } = rootDataResult
          switch (result.type) {
            case "success": {
              menuItems = result.data.menuItems!
              return allDataLoaded && true
            }
            default:
              return allDataLoaded && false
          }
        }
        case "user_profile": {
          const { result } = rootDataResult
          switch (result.type) {
            case "success": {
              userProfile = result.data
              return allDataLoaded && true
            }
            default:
              return allDataLoaded && false
          }
        }
        default: {
          return allDataLoaded && true
        }
      }
    }, true)

    if (allDataLoadedSuccessfully) {
      this.showLeftMenuData({
        menuItems,
        userProfile
      })
    }
  }

  private async loadMenu(): Promise<LoadMenuResult> {
    const result = await this.getMenuUseCase.call({
      objectId: mainMenuId
    })

    return {
      type: "menu",
      result
    }
  }

  private async loadUserProfile(): Promise<LoadUserProfileResult> {
    const result =  await this.getUserProfileUseCase.call()
    return {
      type: "user_profile",
      result
    }
  }
}

interface LoadMenuResult {
  readonly type: "menu"
  readonly result: GetObjectResult<Menu>
}

interface LoadUserProfileResult {
  readonly type: "user_profile"
  readonly result: GetObjectResult<UserProfile>
}

type LoadRootDataResult = LoadMenuResult |
  LoadUserProfileResult
