import FuelCompanyCommercialTermsRepository from "../../repositories/FuelCompanyCommercialTermsRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import FuelCompanyCommercialTerm
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"
import FuelCompanyCommercialTermError
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTermError"

export interface CreateFuelCompanyCommercialTermParameters {
  readonly object: FuelCompanyCommercialTerm
}

export default class CreateFuelCompanyCommercialTermUseCase
  implements CreateObjectUseCase<FuelCompanyCommercialTerm, FuelCompanyCommercialTermError> {
  private readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository

  constructor(parameters: {
    readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository
  }) {
    this.fuelCompanyCommercialTermsRepository = parameters.fuelCompanyCommercialTermsRepository
  }

  async call(
    parameters: CreateFuelCompanyCommercialTermParameters
  ): Promise<CreateObjectResult<FuelCompanyCommercialTerm, FuelCompanyCommercialTermError>> {
    return await this.fuelCompanyCommercialTermsRepository.createFuelCompanyCommercialTerm(parameters)
  }
}
