import FormField from "../forms/FormField"
import { FilterValueByFilterId } from "../../pages/objects-list/ObjectsListView"

export default class Filter<FilterObject> {
  private readonly fields: FormField<FilterObject, never>[]
  readonly buildFilterObject: (filterValueByFilterId?: FilterValueByFilterId) => FilterObject

  constructor(parameters: {
    readonly buildFilterObject: (filterValueByFilterId?: FilterValueByFilterId) => FilterObject
    readonly fields: FormField<FilterObject, never>[]
  }) {
    this.buildFilterObject = parameters.buildFilterObject
    this.fields = parameters.fields
  }

  getFields(): FormField<FilterObject, never>[] {
    return this.fields
  }
}
