import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelCompaniesForCurrentFuelCompanyBalancesRepository
  from "../../repositories/FuelCompaniesForCurrentFuelCompanyBalancesRepository"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"

export interface GetFuelCompanyForCurrentFuelCompanyBalancesParameters {
}

export default class GetFuelCompanyForCurrentFuelCompanyBalancesUseCase implements GetObjectUseCase<FuelCompany> {
  private readonly fuelCompaniesForFuelCompanyBalancesRepository: FuelCompaniesForCurrentFuelCompanyBalancesRepository

  constructor(parameters: {
    readonly fuelCompaniesForFuelCompanyBalancesRepository: FuelCompaniesForCurrentFuelCompanyBalancesRepository
  }) {
    this.fuelCompaniesForFuelCompanyBalancesRepository = parameters.fuelCompaniesForFuelCompanyBalancesRepository
  }

  async call(parameters: GetFuelCompanyForCurrentFuelCompanyBalancesParameters): Promise<GetObjectResult<FuelCompany>> {
    return await this.fuelCompaniesForFuelCompanyBalancesRepository.getFuelCompany(parameters)
  }
}
