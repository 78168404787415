import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesForInstallationInstancesRepository
  from "../../repositories/FuelCompaniesForInstallationInstancesRepository"

export interface GetFuelCompaniesForInstallationInstancesParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForInstallationInstancesUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompaniesForInstallationInstancesRepository:
    FuelCompaniesForInstallationInstancesRepository

  constructor(parameters: {
    readonly fuelCompaniesForInstallationInstancesRepository: FuelCompaniesForInstallationInstancesRepository
  }) {
    this.fuelCompaniesForInstallationInstancesRepository =
      parameters.fuelCompaniesForInstallationInstancesRepository
  }

  async call(
    parameters: GetFuelCompaniesForInstallationInstancesParameters
  ): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompaniesForInstallationInstancesRepository.getFuelCompanies(parameters)
  }
}
