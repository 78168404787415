import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import DriversForTripsRepository from "../../repositories/DriversForTripsRepository"
import DriversFilter from "../../../../../core/domain/drivers/DriversFilter"

export interface GetDriversForTripsParameters extends GetObjectsParameters<DriversFilter> {
}

export default class GetDriversForTripsUseCase implements GetObjectsUseCase<User> {
  private readonly driversForTripsRepository: DriversForTripsRepository

  constructor(parameters: {
    readonly driversForTripsRepository: DriversForTripsRepository
  }) {
    this.driversForTripsRepository = parameters.driversForTripsRepository
  }

  async call(parameters: GetDriversForTripsParameters): Promise<GetObjectsResult<User>> {
    return await this.driversForTripsRepository.getDrivers(parameters)
  }
}
