import { Expose, Type } from "class-transformer"

export default class NetworkCountry {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly callingCode?: string | null

  @Expose()
  @Type(() => String)
  readonly phoneNumberInputMask?: string | null

  @Expose()
  @Type(() => String)
  readonly phoneNumberFormattingMask?: string | null
}
