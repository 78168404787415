import React from "react"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import ObjectButtonsContainerComponent from "../object-buttons-container/ObjectButtonsContainerComponent"
import { ObjectInfoViewState } from "../../pages/object-info/ObjectInfoView"
import LinkButtonComponent from "../../../../../lib/button/LinkButtonComponent"
import isPresent from "../../../../../lib/isPresent"

export default function InfoMenuComponent({
  objectInfoViewState,
  errorMessage
}: {
  readonly objectInfoViewState: ObjectInfoViewState
  readonly errorMessage?: string | null
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const theme = useCoreThemeProvider()

  const isButtonsDisabled = objectInfoViewState.type !== "loaded"

  const editUrl: string | undefined = (() => {
    switch (objectInfoViewState.type) {
      case "loaded": {
        return objectInfoViewState.editUrl
      }
      default:
        return undefined
    }
  })()

  const canEdit: boolean | undefined = (() => {
    switch (objectInfoViewState.type) {
      case "loaded": {
        return objectInfoViewState.canEdit
      }
      default:
        return undefined
    }
  })()

  const needShowButton = isPresent(editUrl) && canEdit === true
  return (
    <ObjectButtonsContainerComponent
      errorMessage={errorMessage}
      leftButtons={(
        <>
          {needShowButton && (
            <LinkButtonComponent
              url={editUrl}
              isDisabled={isButtonsDisabled}
              title={coreTextProvider.editObject()}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
          )}
        </>
      )}
    />
  )
}
