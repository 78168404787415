import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkInstallationInstance from "../NetworkInstallationInstance"

export default class NetworkInstallationInstancesResponseBody {

  @Expose()
  @Type(() => NetworkInstallationInstance)
  readonly installationInstances?: NetworkInstallationInstance[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
