import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import TripLimitTypesForTripLimitsRepository from "../../repositories/TripLimitTypesForTripLimitsRepository"
import TripLimitType from "../../../../../core/domain/trip-limit-type/TripLimitType"

export interface GetTripLimitTypesForTripLimitsParameters extends GetObjectsParameters {
}

export default class GetTripLimitTypesForTripLimitsUseCase
  implements GetObjectsUseCase<TripLimitType> {
  private readonly tripLimitTypesForTripLimitsRepository: TripLimitTypesForTripLimitsRepository

  constructor(parameters: {
    readonly tripLimitTypesForTripLimitsRepository: TripLimitTypesForTripLimitsRepository
  }) {
    this.tripLimitTypesForTripLimitsRepository = parameters.tripLimitTypesForTripLimitsRepository
  }

  async call(
    parameters: GetTripLimitTypesForTripLimitsParameters
  ): Promise<GetObjectsResult<TripLimitType>> {
    return await this.tripLimitTypesForTripLimitsRepository.getTripLimitTypes(parameters)
  }
}
