import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import PasswordsNetworkSource, {
  NetworkCheckTokenResult,
  NetworkRecoveryPasswordResult,
  NetworkRequestPasswordRecoveryResult
} from "../../../../../admin/features/authentication/data/sources/PasswordsNetworkSource"
import NetworkPasswordRecoveryRequestRequestBody
  from "../../entities/password-recovery-request/request-bodies/NetworkPasswordRecoveryRequestRequestBody"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import NetworkPasswordRecoveryRequestResponseBody
  from "../../entities/password-recovery-request/response-bodies/NetworkPasswordRecoveryRequestResponseBody"
import NetworkCheckTokenRequestQuery from "../../entities/check-token/request-queries/NetworkCheckTokenRequestQuery"
import NetworkPasswordRecoveryBody from "../../entities/password-recovery/request-bodies/NetworkPasswordRecoveryBody"
import NetworkPasswordRecoveryResponseBody
  from "../../entities/password-recovery/response-bodies/NetworkPasswordRecoveryResponseBody"

const basePath = "/api/admin/passwords"

export default class DefaultPasswordsNetworkSource implements PasswordsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async requestPasswordRecovery({
    emailAddress
  }: {
    readonly emailAddress: string
  }): Promise<NetworkRequestPasswordRecoveryResult> {
    const requestBody = new NetworkPasswordRecoveryRequestRequestBody({ emailAddress: emailAddress })

    const backendHttpClientResult: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}/request_reset`,
      body: instanceToPlain(requestBody, { strategy: "excludeAll" })
    })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkPasswordRecoveryRequestResponseBody: NetworkPasswordRecoveryRequestResponseBody = plainToInstance(
          NetworkPasswordRecoveryRequestResponseBody,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkPasswordRecoveryRequestResponseBody
        }
      }
      case "error": {
        const error: NetworkExecutionError = plainToInstance(NetworkExecutionError, backendHttpClientResult.body)

        return {
          type: "error",
          error: error
        }
      }
      case "failure":
        return backendHttpClientResult
    }
  }

  async recoveryPassword({
    password,
    passwordConfirmation,
    token
  }: {
    readonly password: string,
    readonly passwordConfirmation: string,
    readonly token: string
  }): Promise<NetworkRecoveryPasswordResult> {
    const requestBody = new NetworkPasswordRecoveryBody({
      password: password,
      passwordConfirmation: passwordConfirmation,
      token: token
    })

    const backendHttpClientResult: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/update`,
      body: instanceToPlain(requestBody, { strategy: "excludeAll" })
    })

    switch (backendHttpClientResult.type) {
      case "success": {
        const networkPasswordRecoveryResponseBody: NetworkPasswordRecoveryResponseBody = plainToInstance(
          NetworkPasswordRecoveryResponseBody,
          backendHttpClientResult.body
        )

        return {
          type: "success",
          data: networkPasswordRecoveryResponseBody
        }
      }
      case "error": {
        const error: NetworkExecutionError = plainToInstance(NetworkExecutionError, backendHttpClientResult.body)

        return {
          type: "error",
          error: error
        }
      }
      case "failure":
        return backendHttpClientResult
    }
  }

  async checkToken({
    token
  }: {
    readonly token: string
  }): Promise<NetworkCheckTokenResult> {
    const backendHttpClientResult: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/check_token`,
      parameters: instanceToPlain(new NetworkCheckTokenRequestQuery({ token }))
    })

    switch (backendHttpClientResult.type) {
      case "success": {
        return {
          type: "success",
          data: undefined
        }
      }
      case "error": {
        const error: NetworkExecutionError = plainToInstance(NetworkExecutionError, backendHttpClientResult.body)

        return {
          type: "error",
          error: error
        }
      }
      case "failure":
        return backendHttpClientResult
    }
  }
}
