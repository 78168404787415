import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarrierBalanceChangeDocument
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import CurrentCarrierBalanceChangeDocumentsRepository
  from "../../repositories/CurrentCarrierBalanceChangeDocumentsRepository"

export interface GetCurrentCarrierBalanceChangeDocumentsParameters extends GetObjectsParameters {
}

export default class GetCurrentCarrierBalanceChangeDocumentsUseCase
  implements GetObjectsUseCase<CarrierBalanceChangeDocument> {
  private readonly currentCarrierBalanceChangeDocumentsRepository: CurrentCarrierBalanceChangeDocumentsRepository

  constructor(parameters: {
    readonly currentCarrierBalanceChangeDocumentsRepository: CurrentCarrierBalanceChangeDocumentsRepository
  }) {
    this.currentCarrierBalanceChangeDocumentsRepository = parameters.currentCarrierBalanceChangeDocumentsRepository
  }

  async call(
    parameters: GetCurrentCarrierBalanceChangeDocumentsParameters
  ): Promise<GetObjectsResult<CarrierBalanceChangeDocument>> {
    return await this.currentCarrierBalanceChangeDocumentsRepository.getDocuments(parameters)
  }
}
