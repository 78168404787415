import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import GasStationsI18 from "../../i18n/GasStationsI18"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import formatPhoneNumber from "../../../../../admin/lib/formatPhone"

export default class GasStationInfoProvider implements InfoProvider<GasStation> {
  private readonly gasStationsI18: GasStationsI18

  constructor(parameters: {
    readonly gasStationsI18: GasStationsI18
  }) {
    this.gasStationsI18 = parameters.gasStationsI18
  }

  getEntity(): string {
    return Entity.GAS_STATIONS
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: GasStation
  }): string {
    if (isBlank(object)) {
      return this.gasStationsI18.getTextProvider()
        .existObjectTitle()
    }

    const { name } = object

    return isPresent(name) ? name : this.gasStationsI18.getTextProvider()
      .existObjectTitle()
  }

  getObjectTitle({
    object
  }: {
    readonly object?: GasStation
  }): string {
    if (isBlank(object)) {
      return this.gasStationsI18.getTextProvider()
        .existObjectTitle()
    }

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.gasStationsI18.getTextProvider()
        .existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: GasStation
  }): InfoRow[] {
    const fuelCompaniesTextProvider = this.gasStationsI18.getTextProvider()
    const appUrlProvider = new AppUrlProvider()

    return [
      new StringInfoRow({
        name: "fuelCompany",
        entity: Entity.FUEL_COMPANIES,
        title: fuelCompaniesTextProvider.fuelCompanyField(),
        value: object?.fuelCompany?.name,
        url: object.fuelCompany && appUrlProvider.buildShowFuelCompanyUrl({
          fuelCompanyId: object.fuelCompany.id!
        })
      }),
      new StringInfoRow({
        name: "code",
        title: fuelCompaniesTextProvider.codeField(),
        value: object.code
      }),
      new StringInfoRow({
        name: "address",
        title: fuelCompaniesTextProvider.addressField(),
        value: object.address
      }),
      new StringInfoRow({
        name: "phoneNumber",
        title: fuelCompaniesTextProvider.phoneNumberField(),
        value: formatPhoneNumber(object.phoneNumber)
      }),
      new StringInfoRow({
        name: "blocked",
        title: fuelCompaniesTextProvider.statusField(),
        value: (() => {
          const { blocked = false } = object

          return (blocked ?? false) ?
            fuelCompaniesTextProvider.statusValueBlocked() :
            fuelCompaniesTextProvider.statusValueActive()
        })()
      }),
      new StringInfoRow({
        name: "latitude",
        title: fuelCompaniesTextProvider.latitudeField(),
        value: isPresent(object.location?.formattedLatitude) ? `${object.location?.formattedLatitude}` : ""
      }),
      new StringInfoRow({
        name: "longitude",
        title: fuelCompaniesTextProvider.longitudeField(),
        value: isPresent(object.location?.formattedLongitude) ? `${object.location?.formattedLongitude}` : ""
      })
    ]
  }

  getLinks({ object }: {
    readonly object: GasStation
  }): LinkInfo[] {
    const urlProvider = new AppUrlProvider()
    const fuelCompaniesTextProvider = this.gasStationsI18.getTextProvider()

    return [
      {
        id: "prices",
        entity: Entity.FUEL_PRICES,
        title: fuelCompaniesTextProvider.pricesLink(),
        url: urlProvider.buildFuelPricesUrl({
          gasStationId: object.id
        })
      },
      {
        id: "gasStationBreaks",
        entity: Entity.GAS_STATION_BREAKS,
        title: fuelCompaniesTextProvider.breaksLink(),
        url: urlProvider.buildGasStationBreaksUrl({
          gasStationId: object.id
        })
      }
    ]
  }

  getEditUrl({
    object
  }: {
    readonly object: GasStation
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditGasStationUrl({
      gasStationId: object.id!
    })
  }
}
