import React from "react"
import ForbiddenView, { ForbiddenViewState } from "./ForbiddenView"
import ForbiddenPresenter from "./ForbiddenPresenter"
import HelmetComponent from "../../../../core/presentation/components/helmet/HelmetComponent"
import ForbiddenTextProvider from "../../i18n/ForbiddenTextProvider"
import ForbiddenComponent from "../components/forbidden/ForbiddenComponent"

interface Props {
  readonly providePresenter: () => ForbiddenPresenter
  readonly forbiddenTextProvider: ForbiddenTextProvider
}

interface State {
  readonly forbiddenViewState?: ForbiddenViewState
}

export default class ForbiddenPage
  extends React.Component<Props, State>
  implements ForbiddenView {

  private readonly presenter: ForbiddenPresenter

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showForbiddenViewState(forbiddenViewState: ForbiddenViewState) {
    this.setState({ forbiddenViewState: forbiddenViewState })
  }

  render() {
    const { forbiddenViewState } = this.state
    const { forbiddenTextProvider } = this.props

    if (!forbiddenViewState) {
      return <></>
    }

    return (
      <>
        <HelmetComponent
          title={forbiddenTextProvider.title()}
        />
        <ForbiddenComponent />
      </>
    )
  }
}
