import { Expose, Type } from "class-transformer"

export default class NetworkTripLimitType {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly code?: string | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly needsFuelTypeSelect?: boolean | null
}
