import GPSLocation from "../../domain/gps-locations/GPSLocation"
import NetworkGPSLocation from "../entities/gps-locations/NetworkGPSLocation"

export default class GPSLocationsMapper {
  mapNetworkToDomain({
    location
  }: {
    readonly location: NetworkGPSLocation
  }): GPSLocation {
    return {
      latitude: location.latitude,
      longitude: location.longitude,
      formattedLatitude: location.formattedLatitude,
      formattedLongitude: location.formattedLongitude
    }
  }

  mapDomainToNetwork({
    location
  }: {
    readonly location: GPSLocation
  }): NetworkGPSLocation {
    return {
      latitude: location.latitude,
      longitude: location.longitude
    }
  }
}
