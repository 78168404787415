import { Expose, Type } from "class-transformer"
import NetworkFuelCompanyBalanceChangeDocument from "../NetworkFuelCompanyBalanceChangeDocument"

export default class NetworkFuelCompanyBalanceChangeDocumentRequestBody {
  @Expose()
  @Type(() => NetworkFuelCompanyBalanceChangeDocument)
  readonly fuelCompanyBalanceWriteOff?: NetworkFuelCompanyBalanceChangeDocument | null

  constructor(parameters: {
    readonly fuelCompanyBalanceWriteOff?: NetworkFuelCompanyBalanceChangeDocument | null
  }) {
    this.fuelCompanyBalanceWriteOff = parameters.fuelCompanyBalanceWriteOff
  }
}
