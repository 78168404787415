import CarriersRepository from "../../repositories/CarriersRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import CarrierError from "../../../../../core/domain/carriers/CarrierError"

export interface CreateCarrierParameters {
  readonly object: Carrier
}

export default class CreateCarrierUseCase implements CreateObjectUseCase<Carrier, CarrierError> {
  private readonly carriersRepository: CarriersRepository

  constructor(parameters: {
    readonly carriersRepository: CarriersRepository
  }) {
    this.carriersRepository = parameters.carriersRepository
  }

  async call(parameters: CreateCarrierParameters): Promise<CreateObjectResult<Carrier, CarrierError>> {
    return await this.carriersRepository.createCarrier(parameters)
  }
}
