import SelectOption from "../../../../admin/lib/picker/SelectOption"

enum RefuellingOrderStatus {
  GAS_STATION_CONFIRMATION_PENDING = "gasStationConfirmationPending",
  NOT_CONFIRMED_BY_GAS_STATION = "notConfirmedByGasStation",
  REFUELLING_PENDING = "refuellingPending",
  CANCELLED = "cancelled",
  REFUELLING = "refuelling",
  DRIVER_CONFIRMATION_PENDING = "driverConfirmationPending",
  FINISHED = "finished",
  GAS_STATION_CORRECTION_PENDING = "gasStationCorrectionPending",
  CLAIM = "claim"
}

export default RefuellingOrderStatus

export interface RefuellingOrderStatusSelectOption extends SelectOption { }
