import DriversRepository from "../../repositories/DriversRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import DriversFilter from "../../../../../core/domain/drivers/DriversFilter"

export interface GetDriversParameters extends GetObjectsParameters<DriversFilter> {
}

export default class GetDriversUseCase implements GetObjectsUseCase<User> {
  private readonly driversRepository: DriversRepository

  constructor(parameters: {
    readonly driversRepository: DriversRepository
  }) {
    this.driversRepository = parameters.driversRepository
  }

  async call(parameters: GetDriversParameters): Promise<GetObjectsResult<User>> {
    return await this.driversRepository.getDrivers(parameters)
  }
}
