import TripChangingLogI18 from "../../i18n/TripChangingLogI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import TripChangingLogTextProvider from "../../i18n/TripChangingLogTextProvider"

export default interface TripChangingLogI18nDiModule {
  provideTripChangingLogI18n(): TripChangingLogI18
}

export class DefaultTripChangingLogI18nDiModule
  extends I18nDiModule<TripChangingLogTextProvider>
  implements TripChangingLogI18nDiModule {

  provideTripChangingLogI18n(): TripChangingLogI18 {
    return this.single(
      TripChangingLogI18.name,
      () => new TripChangingLogI18(this.getI18nParameters())
    )
  }
}
