import ForbiddenPage from "./ForbiddenPage"
import React from "react"
import ForbiddenPresenter from "./ForbiddenPresenter"
import useLocationContext, { LocationContext } from "../../../../lib/presenter/useLocationContext"
import ForbiddenTheme from "../theme/ForbiddenTheme"
import { ForbiddenThemeProvider } from "../contexts/ForbiddenThemeProviderContext"
import { ForbiddenTextProviderProvider } from "../contexts/ForbiddenTextProviderContext"
import ForbiddenI18n from "../../i18n/ForbiddenI18n"

interface Props {
  readonly providePresenter: () => ForbiddenPresenter
  readonly forbiddenTheme: ForbiddenTheme
  readonly forbiddenI18n: ForbiddenI18n
}

export default function ForbiddenPageRouteElement({
  forbiddenTheme,
  providePresenter,
  forbiddenI18n
}: Props) {
  const locationContext: LocationContext = useLocationContext()

  return (
    <ForbiddenTextProviderProvider forbiddenI18n={forbiddenI18n}>
      <ForbiddenThemeProvider theme={forbiddenTheme}>
        <ForbiddenPage
          key={locationContext.locationId}
          providePresenter={() => {
            return locationContext.getOrCreatePresenter(() => {
              return providePresenter()
            })
          }}
          forbiddenTextProvider={forbiddenI18n.getTextProvider()}
        />
      </ForbiddenThemeProvider>
    </ForbiddenTextProviderProvider>
  )
}
