import TableValue from "../TableValue"
import isBlank from "../../../../../../lib/isBlank"
import DateTimeFormatter from "../../../../../../lib/DateTimeFormatter"
import DateTimeFormat from "../../shared/DateTimeFormat"

export default class DateTableValue extends TableValue {
  private readonly value: Date | null | undefined
  private readonly format: DateTimeFormat
  private readonly timeZone?: string

  constructor(parameters: {
    readonly url?: string | null
    readonly value: Date | null | undefined
    readonly format?: DateTimeFormat
    readonly timeZone?: string
  }) {
    super(parameters)
    this.value = parameters.value
    this.format = parameters.format ?? DateTimeFormat.DATE_TIME
    this.timeZone = parameters.timeZone
  }

  getValue(): string {
    if (isBlank(this.value)) return ""
    const formatter = new DateTimeFormatter(this.value, this.timeZone)

    switch (this.format) {
      case DateTimeFormat.DATE_TIME:
        return formatter.formatDateTime()
      case DateTimeFormat.DATE:
        return formatter.formatDate()
      case DateTimeFormat.TIME:
        return formatter.formatHoursMinutes()
    }
  }
}
