import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import CarrierUsersRepository from "../../repositories/CarrierUsersRepository"
import User from "../../../../../core/domain/users/User"

export interface GetCarrierUserParameters {
  readonly objectId: string
}

export default class GetCarrierUserUseCase implements GetObjectUseCase<User> {
  private readonly carrierUsersRepository: CarrierUsersRepository

  constructor(parameters: {
    readonly carrierUsersRepository: CarrierUsersRepository
  }) {
    this.carrierUsersRepository = parameters.carrierUsersRepository
  }

  async call(parameters: GetCarrierUserParameters): Promise<GetObjectResult<User>> {
    return await this.carrierUsersRepository.getUser(parameters)
  }
}
