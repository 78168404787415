import { Expose, Type } from "class-transformer"

export default class NetworkContactPerson {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly phoneNumber?: string | null

  @Expose()
  @Type(() => String)
  readonly email?: string | null
}
