import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import CarrierCommercialTermsRepository from "../../repositories/CarrierCommercialTermsRepository"
import CarrierCommercialTerm from "../../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import FuelCompanyCommercialTerm
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"

export interface GetCarrierCommercialTermParameters {
  readonly objectId: string
}

export default class GetCarrierCommercialTermUseCase implements GetObjectUseCase<CarrierCommercialTerm> {
  private readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository

  constructor(parameters: {
    readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository
  }) {
    this.carrierCommercialTermsRepository = parameters.carrierCommercialTermsRepository
  }

  async call(parameters: GetCarrierCommercialTermParameters): Promise<GetObjectResult<FuelCompanyCommercialTerm>> {
    return await this.carrierCommercialTermsRepository.getCarrierCommercialTerm(parameters)
  }
}
