import InstallationInstancesI18 from "../../i18n/InstallationInstancesI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import InstallationInstancesTextProvider from "../../i18n/InstallationInstancesTextProvider"

export default interface InstallationInstancesI18nDiModule {
  provideInstallationInstancesI18(): InstallationInstancesI18
}

export class DefaultInstallationInstancesI18nDiModule
  extends I18nDiModule<InstallationInstancesTextProvider>
  implements InstallationInstancesI18nDiModule {

  provideInstallationInstancesI18(): InstallationInstancesI18 {
    return this.single(
      InstallationInstancesI18.name,
      () => new InstallationInstancesI18(this.getI18nParameters())
    )
  }
}
