import React, { ReactElement, useEffect, useRef, useState } from "react"
import DropdownComponent from "../../dropdown/DropdownComponent"
import styles from "./MultiSelectPickerComponent.module.scss"
import SelectOption from "../SelectOption"
import isBlank from "../../isBlank"
import ChipComponent from "../../chip/ChipComponent"

interface IconsSet {
  default?: ReactElement
  disabled?: ReactElement
  opened?: ReactElement
}

interface InputClasses {
  default?: string
  active?: string
  disabled?: string
  errors?: string
  noValues?: string
}

interface DropdownClasses {
  default?: string
  itemDefault?: string
  itemSelected?: string
  itemDisabled?: string
}

interface ChipsClasses {
  default?: string
  clearItem?: string
}

export interface MultiSelectPickerComponentProps {
  readonly options: SelectOption[]
  readonly selectedOptions: SelectOption[]
  readonly disabled?: boolean
  readonly clearable?: boolean
  readonly hasErrors?: boolean,
  readonly isLoading?: boolean
  readonly placeholder?: string
  readonly clearButtonText?: string
  readonly aboveOptionsElement?: React.ReactNode
  readonly belowOptionsElement?: React.ReactNode
  readonly iconsSet?: IconsSet
  readonly inputClasses?: InputClasses
  readonly dropdownClasses?: DropdownClasses
  readonly chipsClasses?: ChipsClasses
  readonly onSelect: (option: SelectOption) => void
  readonly onSelectedItemClicked: (selectOption: SelectOption) => void
  readonly onOpened?: () => void
}

const defaultPadding = 8

export default function MultiSelectPickerComponent({
  options,
  selectedOptions,
  disabled,
  clearable,
  hasErrors,
  placeholder,
  clearButtonText,
  aboveOptionsElement,
  belowOptionsElement,
  iconsSet,
  inputClasses = {},
  dropdownClasses = {},
  chipsClasses = {},
  onSelect,
  onOpened,
  onSelectedItemClicked
}: MultiSelectPickerComponentProps) {
  const iconDivRef = useRef<HTMLDivElement>(null)
  const [rightIconWidth, setRightIconWidth] = useState(defaultPadding)
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    if (isBlank(iconDivRef.current)) return

    setRightIconWidth(iconDivRef.current.clientWidth)
  }, [])

  useEffect(() => {
    if (isOpened) {
      onOpened && onOpened()
    }
  }, [isOpened])

  const {
    default: dropdownClassName = "",
    itemDefault: dropdownItemClassName = "",
    itemSelected: dropdownItemSelectedClassName = ""
  } = dropdownClasses

  const {
    default: inputClassName = "",
    active: inputActiveClassName = "",
    disabled: inputDisabledClassName = "",
    errors: inputErrorsClassName = "",
    noValues: inputNoValuesClassName = ""
  } = inputClasses

  const {
    default: chipClassName = "",
    clearItem: chipClearItemClassName = ""
  } = chipsClasses

  function handleRequestHide() {
    setIsOpened(false)
  }

  function handleOnOpenClick() {
    if (disabled) {
      return
    }

    setIsOpened(true)
  }

  function handleOnOptionClick(option: SelectOption) {
    onSelect(option)
  }

  function isOptionSelected(option: SelectOption) {
    return selectedOptions?.find((selectedOption) => selectedOption.id === option.id)
  }

  function getExpandMoreIcon(): ReactElement {
    if (disabled) return iconsSet?.disabled ?? <></>
    if (isOpened) return iconsSet?.opened ?? <></>

    return iconsSet?.default ?? <></>
  }

  return (
    <div className={styles.root}>
      {selectedOptions.length > 0 && (
        <div className={styles.valuesContainer}>
          {(selectedOptions.map((selectedOption) => {
            return (
              <ChipComponent
                key={selectedOption.id}
                text={selectedOption.text ?? ""}
                onClear={() => onSelectedItemClicked(selectedOption)}
                isClearable={true}
                className={`${styles.selectedItem} ${chipClassName}`}
                clearClassName={chipClearItemClassName}
              />
            )
          }))}
        </div>
      )}
      <div className={styles.selectButtonContainer}>
        <div className={`${styles.selectContainer} ${disabled ? styles.disabled : ""} `}>
          <div
            style={{ paddingRight: rightIconWidth }}
            className={[
              styles.selectButton,
              inputClassName,
              disabled ? inputDisabledClassName : "",
              inputNoValuesClassName,
              hasErrors ? inputErrorsClassName : "",
              isOpened ? inputActiveClassName : ""
            ].join(" ")}
            onClick={handleOnOpenClick}
          >
            {placeholder}
          </div>
          <div className={styles.icon} ref={iconDivRef}>
            {getExpandMoreIcon()}
          </div>
        </div>
        <DropdownComponent
          isVisible={isOpened}
          hideOnClickOutside={true}
          hasScroll={true}
          requestHide={handleRequestHide}
          className={`${styles.dropdown} ${dropdownClassName}`}
        >
          <div className={`${styles.dropdownContent}`}>
            {aboveOptionsElement}
            {options.map((option: SelectOption) => (
              <div
                key={option.id}
                className={[
                  styles.dropdownOption,
                  dropdownItemClassName,
                  isOptionSelected(option) ? dropdownItemSelectedClassName : ""
                ].join(" ")}
                onClick={() => handleOnOptionClick(option)}
              >
                {option.text}
              </div>
            ))}
            {belowOptionsElement}
          </div>
        </DropdownComponent>
      </div>
    </div>
  )
}
