import GasStationBreaksRepository from "../../repositories/GasStationBreaksRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import GasStationBreak from "../../../../../core/domain/gas-station-breaks/GasStationBreak"
import GasStationBreakError from "../../../../../core/domain/gas-station-breaks/GasStationBreakError"

export interface CreateGasStationBreakParameters {
  readonly object: GasStationBreak
}

export default class CreateGasStationBreakUseCase implements CreateObjectUseCase<
  GasStationBreak, GasStationBreakError
> {
  private readonly gasStationBreaksRepository: GasStationBreaksRepository

  constructor(parameters: {
    readonly gasStationBreaksRepository: GasStationBreaksRepository
  }) {
    this.gasStationBreaksRepository = parameters.gasStationBreaksRepository
  }

  async call(
    parameters: CreateGasStationBreakParameters
  ): Promise<CreateObjectResult<GasStationBreak, GasStationBreakError>> {
    return await this.gasStationBreaksRepository.createGasStationBreak(parameters)
  }
}
