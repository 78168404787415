import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import FuelCompanyCommercialTermsDomainDiModule from "./FuelCompanyCommercialTermsDomainDiModule"
import FuelCompanyCommercialTermsTableProvider
  from "../../presentation/table-providers/FuelCompanyCommercialTermsTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import FuelCompanyCommercialTermFormProvider
  from "../../presentation/form-providers/FuelCompanyCommercialTermFormProvider"
import FuelCompanyCommercialTermInfoProvider
  from "../../presentation/info-providers/FuelCompanyCommercialTermInfoProvider"
import FuelCompanyCommercialTermsI18nDiModule from "./FuelCompanyCommercialTermsI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "fuelCompanyCommercialTerm"

export default interface FuelCompanyCommercialTermsPresentationDiModule {
  provideFuelCompanyCommercialTermsListPage(): React.ReactNode

  provideFuelCompanyCommercialTermsInfoPage(): React.ReactNode

  provideFuelCompanyCommercialTermsFormPage(): React.ReactNode
}

export class DefaultFuelCompanyCommercialTermsPresentationDiModule
  extends DiModule
  implements FuelCompanyCommercialTermsPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly fuelCompanyCommercialTermsI18nDiModule: FuelCompanyCommercialTermsI18nDiModule
  private readonly fuelCompanyCommercialTermsDomainDiModule: FuelCompanyCommercialTermsDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly fuelCompanyCommercialTermsI18nDiModule: FuelCompanyCommercialTermsI18nDiModule
    readonly fuelCompanyCommercialTermsDomainDiModule: FuelCompanyCommercialTermsDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.fuelCompanyCommercialTermsI18nDiModule = parameters.fuelCompanyCommercialTermsI18nDiModule
    this.fuelCompanyCommercialTermsDomainDiModule = parameters.fuelCompanyCommercialTermsDomainDiModule
  }

  provideFuelCompanyCommercialTermsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.fuelCompanyCommercialTermsDomainDiModule.provideGetFuelCompanyCommercialTermsUseCase(),
      tableProvider: this.provideCarrierCommercialTermsTableProvider()
    })
  }

  private provideCarrierCommercialTermsTableProvider(): FuelCompanyCommercialTermsTableProvider {
    return this.single(
      FuelCompanyCommercialTermsTableProvider.name,
      () => new FuelCompanyCommercialTermsTableProvider({
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase(),
        fuelCompanyCommercialTermsI18:
          this.fuelCompanyCommercialTermsI18nDiModule.provideFuelCompanyCommercialTermsI18n(),
        getFuelCompaniesUseCase:
          this.fuelCompanyCommercialTermsDomainDiModule.provideGetFuelCompaniesForFuelCompanyCommercialTermsUseCase()
      })
    )
  }

  provideFuelCompanyCommercialTermsFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase:
        this.fuelCompanyCommercialTermsDomainDiModule.provideGetFuelCompanyCommercialTermUseCase(),
      createObjectUseCase:
        this.fuelCompanyCommercialTermsDomainDiModule.provideCreateFuelCompanyCommercialTermUseCase(),
      updateObjectUseCase:
        this.fuelCompanyCommercialTermsDomainDiModule.provideUpdateFuelCompanyCommercialTermUseCase(),
      formProvider:
        this.provideCarrierCommercialTermFormProvider()
    })
  }

  private provideCarrierCommercialTermFormProvider(): FuelCompanyCommercialTermFormProvider {
    return this.single(
      FuelCompanyCommercialTermFormProvider.name,
      () => new FuelCompanyCommercialTermFormProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        fuelCompanyCommercialTermsI18:
          this.fuelCompanyCommercialTermsI18nDiModule.provideFuelCompanyCommercialTermsI18n(),
        getCarriersUseCase:
          this.fuelCompanyCommercialTermsDomainDiModule.provideGetCarriersForFuelCompanyCommercialTermsUseCase(),
        getFuelCompaniesUseCase:
          this.fuelCompanyCommercialTermsDomainDiModule.provideGetFuelCompaniesForFuelCompanyCommercialTermsUseCase(),
        getFuelsUseCase:
          this.fuelCompanyCommercialTermsDomainDiModule.provideGetFuelsForFuelCompanyCommercialTermsUseCase(),
        getGasStationsUseCase:
          this.fuelCompanyCommercialTermsDomainDiModule.provideGetGasStationsForFuelCompanyCommercialTermsUseCase(),
        getPricingTypesUseCase:
          this.fuelCompanyCommercialTermsDomainDiModule.provideGetPricingTypesForFuelCompanyCommercialTermsUseCase()
      })
    )
  }

  provideFuelCompanyCommercialTermsInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.fuelCompanyCommercialTermsDomainDiModule.provideGetFuelCompanyCommercialTermUseCase(),
      infoProvider: this.provideCarrierCommercialTermInfoProvider()
    })
  }

  private provideCarrierCommercialTermInfoProvider(): FuelCompanyCommercialTermInfoProvider {
    return this.single(
      FuelCompanyCommercialTermInfoProvider.name,
      () => new FuelCompanyCommercialTermInfoProvider({
        timeZone: this.timeZone,
        fuelCompanyCommercialTermsI18:
          this.fuelCompanyCommercialTermsI18nDiModule.provideFuelCompanyCommercialTermsI18n()
      })
    )
  }
}
