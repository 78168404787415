import React from "react"
import CarrierBalanceChangeDocumentFormPresenter from "./CarrierBalanceChangeDocumentFormPresenter"
import { Navigate, NavigateFunction } from "react-router-dom"
import CoreTextProvider from "../../../../../../admin/core/i18n/CoreTextProvider"
import CarrierBalanceChangeDocumentFormView, {
  CarrierBalanceChangeDocumentFormViewState
} from "./CarrierBalanceChangeDocumentFormView"
import isPresent from "../../../../../../admin/lib/isPresent"
import ExceptionLocalizer from "../../../../../../admin/core/presentation/services/ExceptionLocalizer"
import HelmetComponent from "../../../../../../admin/core/presentation/components/helmet/HelmetComponent"
import ObjectContainerComponent
  from "../../../../../../admin/features/objects/presentation/components/object-container/ObjectContainerComponent"
import ObjectHeaderComponent
  from "../../../../../../admin/features/objects/presentation/components/object-header/ObjectHeaderComponent"
import ObjectFooterComponent
  from "../../../../../../admin/features/objects/presentation/components/object-footer/ObjectFooterComponent"
import FormMenuComponent
  from "../../../../../../admin/features/objects/presentation/components/form-menu/FormMenuComponent"
import FormFieldsComponent
  from "../../../../../../admin/features/objects/presentation/components/form-fields/FormFieldsComponent"
import assertNever from "../../../../../../admin/lib/assertNever"
import AppUrlProvider from "../../../../../core/presentation/services/AppUrlProvider"

interface Props {
  readonly providePresenter: () => CarrierBalanceChangeDocumentFormPresenter
  readonly navigate: NavigateFunction
  readonly coreTextProvider: CoreTextProvider
}

interface State {
  readonly carrierBalanceChangeDocumentFormViewState?: CarrierBalanceChangeDocumentFormViewState
}

export default class CarrierBalanceChangeDocumentFormPage
  extends React.Component<Props, State>
  implements CarrierBalanceChangeDocumentFormView {

  private readonly presenter: CarrierBalanceChangeDocumentFormPresenter

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showCarrierBalanceChangeDocumentFormViewState(
    carrierBalanceChangeDocumentFormViewState: CarrierBalanceChangeDocumentFormViewState
  ) {
    this.setState({ carrierBalanceChangeDocumentFormViewState })
  }

  render() {
    const { carrierBalanceChangeDocumentFormViewState } = this.state

    if (!carrierBalanceChangeDocumentFormViewState) {
      return <></>
    }

    const appUrlProvider = new AppUrlProvider()
    if (carrierBalanceChangeDocumentFormViewState.type === "forbidden") {
      return <Navigate to={appUrlProvider.buildForbiddenUrl()} replace={true} />
    }

    const {
      title,
      form
    } = carrierBalanceChangeDocumentFormViewState

    const errorMessage = (() => {
      switch (carrierBalanceChangeDocumentFormViewState.type) {
        case "idle": {
          if (isPresent(carrierBalanceChangeDocumentFormViewState.changingError)) {
            return carrierBalanceChangeDocumentFormViewState.changingError?.message
          }

          if (isPresent(carrierBalanceChangeDocumentFormViewState.changingException)) {
            return new ExceptionLocalizer({
              coreTextProvider: this.props.coreTextProvider
            }).localizeException(carrierBalanceChangeDocumentFormViewState.changingException)
          }

          return null
        }
        default:
          return null
      }
    })()

    const isLoading = carrierBalanceChangeDocumentFormViewState.type === "creating"

    return (
      <>
        <HelmetComponent
          title={title}
        />
        <form
          onSubmit={(event) => {
            event.preventDefault()
            this.presenter.onSubmitObjectClicked()
          }}
        >
          <ObjectContainerComponent
            headerComponent={(
              <ObjectHeaderComponent
                title={title}
              />
            )}
            footerComponent={(
              <ObjectFooterComponent>
                <FormMenuComponent
                  errorMessage={errorMessage}
                  isLoading={isLoading}
                  formPermissionsSet={form.getPermissionsSet()}
                  isNewObject={true}
                  onCancelClicked={this.presenter.onCancelClicked}
                />
              </ObjectFooterComponent>
            )}
          >
            <>
              {(() => {
                switch (carrierBalanceChangeDocumentFormViewState.type) {
                  case "idle":
                  case "creating": {
                    const { fieldViewStates } = carrierBalanceChangeDocumentFormViewState
                    return (
                      <FormFieldsComponent
                        fieldViewStates={fieldViewStates}
                        fieldsGroups={form.getFieldsGroups()}
                        isDisabled={isLoading}
                      />
                    )
                  }
                  case "canceling": {
                    this.props.navigate(-1)
                    return (
                      <></>
                    )
                  }
                  default:
                    assertNever(carrierBalanceChangeDocumentFormViewState)
                }
              })()}
            </>
          </ObjectContainerComponent>
        </form>
      </>
    )
  }
}
