import GetCarrierUsersUseCase from "../../domain/use-cases/users/GetCarrierUsersUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CarrierUsersDataDiModule from "./CarrierUsersDataDiModule"
import CreateCarrierUserUseCase from "../../domain/use-cases/users/CreateCarrierUserUseCase"
import GetCarrierUserUseCase from "../../domain/use-cases/users/GetCarrierUserUseCase"
import UpdateCarrierUserUseCase from "../../domain/use-cases/users/UpdateCarrierUserUseCase"
import GetCarriersForCarrierUsersUseCase from "../../domain/use-cases/carriers/GetCarriersForCarrierUsersUseCase"

export default interface CarrierUsersDomainDiModule {
  provideGetCarrierUsersUseCase(): GetCarrierUsersUseCase

  provideGetCarrierUserUseCase(): GetCarrierUserUseCase

  provideCreateCarrierUserUseCase(): CreateCarrierUserUseCase

  provideUpdateCarrierUsersUseCase(): UpdateCarrierUserUseCase

  provideGetCarriersForCarrierUsersUseCase(): GetCarriersForCarrierUsersUseCase
}

export class DefaultCarrierUsersDomainDiModule extends DiModule implements CarrierUsersDomainDiModule {
  private readonly carrierUsersDataDiModule: CarrierUsersDataDiModule

  constructor(parameters: {
    readonly carrierUsersDataDiModule: CarrierUsersDataDiModule
  }) {
    super()
    this.carrierUsersDataDiModule = parameters.carrierUsersDataDiModule
  }

  provideGetCarrierUsersUseCase(): GetCarrierUsersUseCase {
    return this.single(
      GetCarrierUsersUseCase.name,
      () => new GetCarrierUsersUseCase({
        carrierUsersRepository: this.carrierUsersDataDiModule.provideCarrierUsersRepository()
      })
    )
  }

  provideGetCarrierUserUseCase(): GetCarrierUserUseCase {
    return this.single(
      GetCarrierUserUseCase.name,
      () => new GetCarrierUserUseCase({
        carrierUsersRepository: this.carrierUsersDataDiModule.provideCarrierUsersRepository()
      })
    )
  }

  provideCreateCarrierUserUseCase(): CreateCarrierUserUseCase {
    return this.single(
      CreateCarrierUserUseCase.name,
      () => new CreateCarrierUserUseCase({
        carrierUsersRepository: this.carrierUsersDataDiModule.provideCarrierUsersRepository()
      })
    )
  }

  provideUpdateCarrierUsersUseCase(): UpdateCarrierUserUseCase {
    return this.single(
      UpdateCarrierUserUseCase.name,
      () => new UpdateCarrierUserUseCase({
        carrierUsersRepository: this.carrierUsersDataDiModule.provideCarrierUsersRepository()
      })
    )
  }

  provideGetCarriersForCarrierUsersUseCase(): GetCarriersForCarrierUsersUseCase {
    return this.single(
      GetCarriersForCarrierUsersUseCase.name,
      () => new GetCarriersForCarrierUsersUseCase({
        carriersForCarrierUsersRepository: this.carrierUsersDataDiModule.provideCarriersForCarrierUsersRepository()
      })
    )
  }
}
