import React, { createContext, useContext } from "react"
import NotFoundTextProvider from "../../i18n/NotFoundTextProvider"
import NotFoundI18n from "../../i18n/NotFoundI18n"

// TODO: remove duplication between the same files
export const NotFoundTextProviderContext: React.Context<NotFoundTextProvider | null> =
  createContext<NotFoundTextProvider | null>(null)

export function useNotFoundTextProvider(): NotFoundTextProvider {
  return useContext(NotFoundTextProviderContext)!
}

export interface NotFoundTextProviderProps {
  readonly children?: React.ReactNode
  readonly notFoundI18n: NotFoundI18n
}

export function NotFoundTextProviderProvider({
  children,
  notFoundI18n
}: NotFoundTextProviderProps) {
  return (
    <NotFoundTextProviderContext.Provider value={notFoundI18n.getTextProvider()}>
      {children}
    </NotFoundTextProviderContext.Provider>
  )
}
