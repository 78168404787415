import NetworkCarrierBalanceChangeDocument
  from "../entities/carrier-balance-change-documents/NetworkCarrierBalanceChangeDocument"
import CarrierBalanceChangeDocument from "../../domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import CarrierBalanceTransactionsMapper from "./CarrierBalanceTransactionsMapper"

export default class CarrierBalanceChangeDocumentsMapper {
  mapNetworkToDomain({
    carrierBalanceChangeDocument
  }: {
    readonly carrierBalanceChangeDocument: NetworkCarrierBalanceChangeDocument
  }): CarrierBalanceChangeDocument {
    const {
      carrierBalanceTransaction
    } = carrierBalanceChangeDocument

    return {
      id: carrierBalanceChangeDocument.id,
      date: carrierBalanceChangeDocument.date,
      value: carrierBalanceChangeDocument.value,
      formattedValue: carrierBalanceChangeDocument.formattedValue,
      comment: carrierBalanceChangeDocument.comment,
      reason: carrierBalanceChangeDocument.reason,
      carrierBalanceTransaction: carrierBalanceTransaction && new CarrierBalanceTransactionsMapper()
        .mapNetworkToDomain({
          carrierBalanceTransaction
        })
    }
  }

  mapDomainToNetwork({
    carrierBalanceChangeDocument
  }: {
    readonly carrierBalanceChangeDocument: CarrierBalanceChangeDocument
  }): NetworkCarrierBalanceChangeDocument {
    return {
      date: carrierBalanceChangeDocument.date,
      value: carrierBalanceChangeDocument.value,
      comment: carrierBalanceChangeDocument.comment,
      reason: carrierBalanceChangeDocument.reason
    }
  }
}
