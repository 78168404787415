 import TripsRepository from "../../repositories/TripsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import TripsFilter from "../../../../../core/domain/trips/TripsFilter"
import Trip from "../../../../../core/domain/trips/Trip"

export interface GetTripsParameters extends GetObjectsParameters<TripsFilter> {
}

export default class GetTripsUseCase implements GetObjectsUseCase<Trip> {
  private readonly tripsRepository: TripsRepository

  constructor(parameters: {
    readonly tripsRepository: TripsRepository
  }) {
    this.tripsRepository = parameters.tripsRepository
  }

  async call(parameters: GetTripsParameters): Promise<GetObjectsResult<Trip>> {
    return await this.tripsRepository.getTrips(parameters)
  }
}
