import FuelCompanyBalanceTransactionsRepository from "../../domain/repositories/FuelCompanyBalanceTransactionsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForFuelCompanyBalancesRepository
  from "../../domain/repositories/FuelCompaniesForFuelCompanyBalancesRepository"
import DefaultFuelCompaniesForFuelCompanyBalancesRepository
  from "../../data/repositories/DefaultFuelCompaniesForFuelCompanyBalancesRepository"
import DefaultFuelCompanyBalanceTransactionsRepository
  from "../../data/repositories/DefaultFuelCompanyBalanceTransactionsRepository"
import FuelCompanyBalanceChangeDocumentsRepository
  from "../../domain/repositories/FuelCompanyBalanceChangeDocumentsRepository"
import DefaultFuelCompanyBalanceChangeDocumentsRepository
  from "../../data/repositories/DefaultFuelCompanyBalanceChangeDocumentsRepository"

export default interface FuelCompanyBalancesDataDiModule {
  provideFuelCompanyBalanceTransactionsRepository(): FuelCompanyBalanceTransactionsRepository

  provideFuelCompanyBalanceChangeDocumentsRepository(): FuelCompanyBalanceChangeDocumentsRepository

  provideFuelCompaniesForFuelCompanyBalancesRepository(): FuelCompaniesForFuelCompanyBalancesRepository
}

export class DefaultFuelCompanyBalancesDataDiModule extends DiModule implements FuelCompanyBalancesDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideFuelCompanyBalanceTransactionsRepository(): FuelCompanyBalanceTransactionsRepository {
    return this.single(
      DefaultFuelCompanyBalanceTransactionsRepository.name,
      () => new DefaultFuelCompanyBalanceTransactionsRepository({
        fuelCompanyBalanceTransactionsNetworkSource:
          this.appDataDiModule.provideFuelCompanyBalanceTransactionsNetworkSource()
      })
    )
  }

  provideFuelCompanyBalanceChangeDocumentsRepository(): FuelCompanyBalanceChangeDocumentsRepository {
    return this.single(
      DefaultFuelCompanyBalanceChangeDocumentsRepository.name,
      () => new DefaultFuelCompanyBalanceChangeDocumentsRepository({
        fuelCompanyBalanceChangeDocumentsNetworkSource:
          this.appDataDiModule.provideFuelCompanyBalanceChangeDocumentsNetworkSource()
      })
    )
  }

  provideFuelCompaniesForFuelCompanyBalancesRepository(): FuelCompaniesForFuelCompanyBalancesRepository {
    return this.single(
      DefaultFuelCompaniesForFuelCompanyBalancesRepository.name,
      () => new DefaultFuelCompaniesForFuelCompanyBalancesRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }
}
