import React from "react"
import styles from "./ChipComponent.module.scss"
import isBlank from "../isBlank"
import { Link } from "react-router-dom"

export default function ChipComponent({
  text = "",
  isClearable = false,
  url,
  canShowUrl,
  className = "",
  clearClassName = "",
  onClear = () => undefined
}: {
  readonly text?: string
  readonly isClearable?: boolean
  readonly url?: string
  readonly canShowUrl?: boolean
  readonly className?: string
  readonly clearClassName?: string
  readonly onClear?: () => void
}) {
  const chipContent = () => {
    return (
      <div className={styles.content}>
        {text}
        {isClearable && (
          <div
            className={`${styles.clear} ${clearClassName}`}
            onClick={onClear}
          />
        )}
      </div>
    )
  }

  if (isBlank(url) || canShowUrl === false) {
    return (
      <div className={`${styles.root} ${className}`}>
        {chipContent()}
      </div>
    )
  }

  return (
    <Link
      className={`${styles.root} ${className}`}
      to={url}
    >
      {chipContent()}
    </Link>
  )
}
