import ObjectInfoPage from "./ObjectInfoPage"
import React from "react"
import ObjectInfoPresenter from "./ObjectInfoPresenter"
import { Params, useParams } from "react-router-dom"
import useLocationContext, { LocationContext } from "../../../../../lib/presenter/useLocationContext"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import isBlank from "../../../../../lib/isBlank"
import { objectIdUrlPart } from "../../../../../core/presentation/constants/urlParts"

interface Props<DomainObject> {
  readonly providePresenter: (parameters: {
    readonly objectId: string
  }) => ObjectInfoPresenter<DomainObject>
}

export default function ObjectInfoPageRouteElement<DomainObject>({
  providePresenter
}: Props<DomainObject>) {
  const urlParams: Readonly<Params<string>> = useParams()
  const objectId: string | undefined = urlParams[objectIdUrlPart]
  const locationContext: LocationContext = useLocationContext()
  const coreTextProvider = useCoreTextProvider()

  if (isBlank(objectId)) return <></>

  return (
    <ObjectInfoPage
      key={locationContext.locationId}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            objectId
          })
        })
      }}
      coreTextProvider={coreTextProvider}
    />
  )
}
