import React from "react"
import ObjectsListView, {
  FilterValueByFilterId,
  FilterViewState,
  ObjectsListViewState,
  SearchViewState
} from "./ObjectsListView"
import ObjectsListPresenter from "./ObjectsListPresenter"
import Sort from "../../entities/tables/Sort"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import ListHeaderComponent from "../../components/list-header/ListHeaderComponent"
import TableComponent from "../../components/table/TableComponent"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import styles from "./ObjectsListPage.module.scss"
import { Navigate } from "react-router-dom"
import AppUrlProvider from "../../../../../../app/core/presentation/services/AppUrlProvider"

interface Props<DomainObject, FilterObject = never> {
  readonly providePresenter: () => ObjectsListPresenter<DomainObject, FilterObject>
  readonly cacheQuery: (query: string | undefined) => void
  readonly cacheSort: (sort: Sort | undefined) => void
  readonly cacheFilterValueByFilterId: (filterValueByFilterId: FilterValueByFilterId) => void
  readonly hasAppliedFilters: () => boolean
}

interface State<DomainObject, FilterObject = never> {
  readonly objectsListViewState?: ObjectsListViewState<DomainObject, FilterObject>
  readonly searchViewState?: SearchViewState
  readonly filterViewState?: FilterViewState
}

export default class ObjectsListPage<DomainObject, FilterObject = never>
  extends React.Component<Props<DomainObject, FilterObject>, State<DomainObject, FilterObject>>
  implements ObjectsListView<DomainObject, FilterObject> {

  private readonly presenter: ObjectsListPresenter<DomainObject, FilterObject>

  constructor(props: Props<DomainObject, FilterObject>) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showObjectsListViewState(objectsListViewState: ObjectsListViewState<DomainObject, FilterObject>) {
    this.setState({ objectsListViewState: objectsListViewState })
  }

  showSearchViewState(searchViewState: SearchViewState) {
    this.setState({ searchViewState })
  }

  showFilterViewState(filterViewState: FilterViewState) {
    this.setState({ filterViewState })
  }

  cacheQuery(query: string | undefined) {
    this.props.cacheQuery(query)
  }

  cacheSort(sort: Sort | undefined) {
    this.props.cacheSort(sort)
  }

  cacheFilterValueByFilterId(filterValueByFilterId: FilterValueByFilterId) {
    this.props.cacheFilterValueByFilterId(filterValueByFilterId)
  }

  render() {
    const {
      objectsListViewState,
      searchViewState,
      filterViewState
    } = this.state

    if (!objectsListViewState) {
      return <></>
    }

    const appUrlProvider = new AppUrlProvider()
    const {
      table,
      sort,
      searchByQueryIsEnable
    } = objectsListViewState

    const objects: DomainObject[] = (() => {
      switch (objectsListViewState.type) {
        case "loaded":
        case "next_page_loading":
        case "next_page_loading_error":
        case "next_page_loading_failure": {
          return objectsListViewState.objects
        }
        default:
          return []
      }
    })()

    const objectsLoadingError: ExecutionError | undefined = (() => {
      switch (objectsListViewState.type) {
        case "loading_error":
        case "next_page_loading_error": {
          return objectsListViewState.error
        }
        default:
          return undefined
      }
    })()

    const objectsLoadingException: ApplicationException | undefined = (() => {
      switch (objectsListViewState.type) {
        case "loading_failure":
        case "next_page_loading_failure": {
          return objectsListViewState.exception
        }
        default:
          return undefined
      }
    })()

    const objectsIsLoading = objectsListViewState.type === "initializing" ||
      objectsListViewState.type === "loading" ||
      objectsListViewState.type === "next_page_loading"

    const objectsIsForbidden = objectsListViewState.type === "forbidden"
    if (objectsIsForbidden) {
      return <Navigate to={appUrlProvider.buildForbiddenUrl()} replace={true} />
    }

    return (
      <>
        <HelmetComponent
          title={objectsListViewState.table.getTitle()}
        />
        <ListHeaderComponent
          table={table}
          searchViewState={searchViewState}
          filterViewState={filterViewState}
          hasAppliedFilters={this.props.hasAppliedFilters()}
          searchByQueryIsEnable={searchByQueryIsEnable}
          onQueryChanged={this.presenter.onQueryChanged}
          onSearchRequested={this.presenter.onSearchRequested}
          onApplyFilterClicked={this.presenter.onApplyFilterClicked}
          onResetFilterClicked={this.presenter.onResetFilterClicked}
        />
        <TableComponent
          className={styles.table}
          objects={objects}
          isLoading={objectsIsLoading}
          error={objectsLoadingError}
          exception={objectsLoadingException}
          table={table}
          sort={sort}
          onColumnClicked={this.presenter.onColumnClicked}
          onNextPageRequested={this.presenter.onNextPageRequested}
          onRetryLoadClicked={this.presenter.onRetryLoadClicked}
        />
      </>
    )
  }
}
