import GetRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/refuelling-orders-reports/GetRefuellingOrdersReportsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import RefuellingOrdersReportsDataDiModule from "./RefuellingOrdersReportsDataDiModule"
import CreateRefuellingOrdersReportUseCase
  from "../../domain/use-cases/refuelling-orders-reports/CreateRefuellingOrdersReportUseCase"
import GetCarriersForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForRefuellingOrdersReportsUseCase"
import GetFuelsForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/fuels/GetFuelsForRefuellingOrdersReportsUseCase"
import GetFuelCompaniesForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForRefuellingOrdersReportsUseCase"
import GetGasStationsForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForRefuellingOrdersReportsUseCase"
import GetDriversForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/drivers/GetDriversForRefuellingOrdersReportsUseCase"
import GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/refuelling-order-statuses/GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase"
import GetTripsForRefuellingOrdersReportsUseCase
  from "../../domain/use-cases/trips/GetTripsForRefuellingOrdersReportsUseCase"

export default interface RefuellingOrdersReportsDomainDiModule {
  provideGetRefuellingOrdersReportsUseCase(): GetRefuellingOrdersReportsUseCase

  provideCreateRefuellingOrderReportUseCase(): CreateRefuellingOrdersReportUseCase

  provideGetCarriersForRefuellingOrdersReportsUseCase(): GetCarriersForRefuellingOrdersReportsUseCase

  provideGetFuelCompaniesForRefuellingOrdersReportsUseCase(): GetFuelCompaniesForRefuellingOrdersReportsUseCase

  provideGetFuelsForRefuellingOrdersReportsUseCase(): GetFuelsForRefuellingOrdersReportsUseCase

  provideGetGasStationsForRefuellingOrdersReportsUseCase(): GetGasStationsForRefuellingOrdersReportsUseCase

  provideGetDriversForRefuellingOrdersReportsUseCase(): GetDriversForRefuellingOrdersReportsUseCase

  provideGetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase(): GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase

  provideGetTripsForRefuellingOrdersReportsUseCase(): GetTripsForRefuellingOrdersReportsUseCase
}

export class DefaultRefuellingOrdersReportsDomainDiModule
  extends DiModule
  implements RefuellingOrdersReportsDomainDiModule {
  private readonly refuellingOrdersReportsDataDiModule: RefuellingOrdersReportsDataDiModule

  constructor(parameters: {
    readonly refuellingOrdersReportsDataDiModule: RefuellingOrdersReportsDataDiModule
  }) {
    super()
    this.refuellingOrdersReportsDataDiModule = parameters.refuellingOrdersReportsDataDiModule
  }

  provideGetRefuellingOrdersReportsUseCase(): GetRefuellingOrdersReportsUseCase {
    return this.single(
      GetRefuellingOrdersReportsUseCase.name,
      () => new GetRefuellingOrdersReportsUseCase({
        refuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideRefuellingOrdersReportsRepository()
      })
    )
  }

  provideCreateRefuellingOrderReportUseCase(): CreateRefuellingOrdersReportUseCase {
    return this.single(
      CreateRefuellingOrdersReportUseCase.name,
      () => new CreateRefuellingOrdersReportUseCase({
        refuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideRefuellingOrdersReportsRepository()
      })
    )
  }

  provideGetCarriersForRefuellingOrdersReportsUseCase(): GetCarriersForRefuellingOrdersReportsUseCase {
    return this.single(
      GetCarriersForRefuellingOrdersReportsUseCase.name,
      () => new GetCarriersForRefuellingOrdersReportsUseCase({
        carriersForRefuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideCarriersForRefuellingOrdersReportsRepository()
      })
    )
  }

  provideGetFuelCompaniesForRefuellingOrdersReportsUseCase(): GetFuelCompaniesForRefuellingOrdersReportsUseCase {
    return this.single(
      GetFuelCompaniesForRefuellingOrdersReportsUseCase.name,
      () => new GetFuelCompaniesForRefuellingOrdersReportsUseCase({
        fuelCompanyForRefuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideFuelCompaniesForRefuellingOrdersReportsRepository()
      })
    )
  }

  provideGetFuelsForRefuellingOrdersReportsUseCase(): GetFuelsForRefuellingOrdersReportsUseCase {
    return this.single(
      GetFuelsForRefuellingOrdersReportsUseCase.name,
      () => new GetFuelsForRefuellingOrdersReportsUseCase({
        fuelsForRefuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideFuelsForRefuellingOrdersReportsRepository()
      })
    )
  }

  provideGetGasStationsForRefuellingOrdersReportsUseCase(): GetGasStationsForRefuellingOrdersReportsUseCase {
    return this.single(
      GetGasStationsForRefuellingOrdersReportsUseCase.name,
      () => new GetGasStationsForRefuellingOrdersReportsUseCase({
        gasStationsForRefuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideGasStationsForRefuellingOrdersReportsRepository()
      })
    )
  }

  provideGetDriversForRefuellingOrdersReportsUseCase(): GetDriversForRefuellingOrdersReportsUseCase {
    return this.single(
      GetDriversForRefuellingOrdersReportsUseCase.name,
      () => new GetDriversForRefuellingOrdersReportsUseCase({
        driversForRefuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideDriversForRefuellingOrdersReportsRepository()
      })
    )
  }

  provideGetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase(): GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase {
    return this.single(
      GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase.name,
      () => new GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase({
        refuellingOrderStatusesForRefuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideRefuellingOrderStatusesForRefuellingOrdersReportsRepository()
      })
    )
  }

  provideGetTripsForRefuellingOrdersReportsUseCase(): GetTripsForRefuellingOrdersReportsUseCase {
    return this.single(
      GetTripsForRefuellingOrdersReportsUseCase.name,
      () => new GetTripsForRefuellingOrdersReportsUseCase({
        tripsForRefuellingOrdersReportsRepository:
          this.refuellingOrdersReportsDataDiModule.provideTripsForRefuellingOrdersReportsRepository()
      })
    )
  }
}
