import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import UsersDomainDiModule from "./UsersDomainDiModule"
import UsersTableProvider from "../../presentation/table-providers/UsersTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import UsersFormProvider from "../../presentation/form-providers/UsersFormProvider"
import UserInfoProvider from "../../presentation/info-providers/UserInfoProvider"
import UsersI18nDiModule from "./UsersI18nDiModule"

const moduleKey = "users"

export default interface UsersPresentationDiModule {
  provideUsersListPage(): React.ReactNode

  provideUserInfoPage(): React.ReactNode

  provideUserFormPage(): React.ReactNode
}

export class DefaultUsersPresentationDiModule
  extends DiModule
  implements UsersPresentationDiModule {

  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly usersI18nDiModule: UsersI18nDiModule
  private readonly usersDomainDiModule: UsersDomainDiModule

  constructor(parameters: {
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly usersI18nDiModule: UsersI18nDiModule
    readonly usersDomainDiModule: UsersDomainDiModule
  }) {
    super()
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.usersI18nDiModule = parameters.usersI18nDiModule
    this.usersDomainDiModule = parameters.usersDomainDiModule
  }

  provideUsersListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.usersDomainDiModule.provideGetUsersUseCase(),
      tableProvider: this.provideUsersTableProvider()
    })
  }

  private provideUsersTableProvider(): UsersTableProvider {
    return this.single(
      UsersTableProvider.name,
      () => new UsersTableProvider({
        usersI18: this.usersI18nDiModule.provideUsersI18n()
      })
    )
  }

  provideUserFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.usersDomainDiModule.provideGetUserUseCase(),
      createObjectUseCase: this.usersDomainDiModule.provideCreateUserUseCase(),
      updateObjectUseCase: this.usersDomainDiModule.provideUpdateUsersUseCase(),
      formProvider: this.provideUserFormProvider()
    })
  }

  private provideUserFormProvider(): UsersFormProvider {
    return this.single(
      UsersFormProvider.name,
      () => new UsersFormProvider({
        usersI18: this.usersI18nDiModule.provideUsersI18n(),
        appI18: this.appI18nDiModule.provideAppI18n()
      })
    )
  }

  provideUserInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.usersDomainDiModule.provideGetUserUseCase(),
      infoProvider: this.provideUserInfoProvider()
    })
  }

  private provideUserInfoProvider(): UserInfoProvider {
    return this.single(
      UserInfoProvider.name,
      () => new UserInfoProvider({

        usersI18: this.usersI18nDiModule.provideUsersI18n(),
        appI18: this.appI18nDiModule.provideAppI18n()
      })
    )
  }
}
