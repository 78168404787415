import React from "react"
import { useSearchParams } from "react-router-dom"
import PasswordRecoveryPage from "./PasswordRecoveryPage"
import PasswordRecoveryPresenter from "./PasswordRecoveryPresenter"

const TOKEN_KEY = "token"

interface Props {
  readonly providePresenter: (parameters: {
    readonly token?: string | null
  }) => PasswordRecoveryPresenter
}

export default function PasswordRecoveryPageRouteElement({
  providePresenter
}: Props) {
  const [searchParams] = useSearchParams()
  const token: string | undefined = searchParams.get(TOKEN_KEY) ?? undefined

  return (
    <PasswordRecoveryPage
      providePresenter={() => {
        return providePresenter({ token })
      }}
    />
  )
}
