import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import GasStationBreaksDomainDiModule from "./GasStationBreaksDomainDiModule"
import GasStationBreaksTableProvider from "../../presentation/table-providers/GasStationBreaksTableProvider"
import GasStationBreakFormProvider from "../../presentation/form-providers/GasStationBreakFormProvider"
import GasStationBreakInfoProvider from "../../presentation/info-providers/GasStationBreakInfoProvider"
import GasStationBreaksI18nDiModule from "./GasStationBreaksI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"

const moduleKey = "gasStationBreaks"

export default interface GasStationBreaksPresentationDiModule {
  provideGasStationBreaksListPage(): React.ReactNode

  provideGasStationBreakInfoPage(): React.ReactNode

  provideGasStationBreakFormPage(): React.ReactNode
}

export class DefaultGasStationBreakPresentationDiModule
  extends DiModule
  implements GasStationBreaksPresentationDiModule {

  private readonly timeZone: string
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly gasStationBreaksI18nDiModule: GasStationBreaksI18nDiModule
  private readonly gasStationBreaksDomainDiModule: GasStationBreaksDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly gasStationBreaksI18nDiModule: GasStationBreaksI18nDiModule
    readonly gasStationBreaksDomainDiModule: GasStationBreaksDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.gasStationBreaksI18nDiModule = parameters.gasStationBreaksI18nDiModule
    this.gasStationBreaksDomainDiModule = parameters.gasStationBreaksDomainDiModule
  }

  provideGasStationBreaksListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.gasStationBreaksDomainDiModule.provideGetGasStationBreaksUseCase(),
      tableProvider: this.provideFuelPricesTableProvider()
    })
  }

  private provideFuelPricesTableProvider(): GasStationBreaksTableProvider {
    return this.single(
      GasStationBreaksTableProvider.name,
      () => new GasStationBreaksTableProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        gasStationBreaksI18n: this.gasStationBreaksI18nDiModule.provideGasStationBreaksI18n(),
        getGasStationsUseCase: this.gasStationBreaksDomainDiModule.provideGetGasStationsForGasStationBreaksUseCase()
      })
    )
  }

  provideGasStationBreakFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.gasStationBreaksDomainDiModule.provideGetGasStationBreakUseCase(),
      createObjectUseCase: this.gasStationBreaksDomainDiModule.provideCreateGasStationBreakUseCase(),
      updateObjectUseCase: this.gasStationBreaksDomainDiModule.provideUpdateGasStationBreakUseCase(),
      formProvider: this.provideGasStationBreakFormProvider()
    })
  }

  private provideGasStationBreakFormProvider(): GasStationBreakFormProvider {
    return this.single(
      GasStationBreakFormProvider.name,
      () => new GasStationBreakFormProvider({
        timeZone: this.timeZone,
        gasStationBreaksI18n: this.gasStationBreaksI18nDiModule.provideGasStationBreaksI18n(),
        getGasStationsUseCase: this.gasStationBreaksDomainDiModule.provideGetGasStationsForGasStationBreaksUseCase()
      })
    )
  }

  provideGasStationBreakInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.gasStationBreaksDomainDiModule.provideGetGasStationBreakUseCase(),
      infoProvider: this.provideGasStationBreakInfoProvider()
    })
  }

  private provideGasStationBreakInfoProvider(): GasStationBreakInfoProvider {
    return this.single(
      GasStationBreakInfoProvider.name,
      () => new GasStationBreakInfoProvider({
        timeZone: this.timeZone,
        gasStationBreaksI18n: this.gasStationBreaksI18nDiModule.provideGasStationBreaksI18n()
      })
    )
  }
}
