import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import TransportsForRefuellingOrdersRepository from "../../repositories/TransportsForRefuellingOrdersRepository"
import Transport from "../../../../../core/domain/transport/Transport"
import TransportsFilter from "../../../../../core/domain/transport/TransportsFilter"

export interface GetTransportsForRefuellingOrdersParameters extends GetObjectsParameters<TransportsFilter> {
}

export default class GetTransportsForRefuellingOrdersUseCase implements GetObjectsUseCase<Transport> {
  private readonly transportsForRefuellingOrdersRepository: TransportsForRefuellingOrdersRepository

  constructor(parameters: {
    readonly transportsForRefuellingOrdersRepository: TransportsForRefuellingOrdersRepository
  }) {
    this.transportsForRefuellingOrdersRepository = parameters.transportsForRefuellingOrdersRepository
  }

  async call(parameters: GetTransportsForRefuellingOrdersParameters): Promise<GetObjectsResult<Transport>> {
    return await this.transportsForRefuellingOrdersRepository.getTransports(parameters)
  }
}
