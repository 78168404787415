import { Expose, Type } from "class-transformer"
import NetworkCountry from "../countries/NetworkCountry"

export default class NetworkPhoneNumber {
  @Expose()
  @Type(() => String)
  readonly value?: string | null

  @Expose()
  @Type(() => Number)
  readonly countryId?: number | null

  @Expose()
  @Type(() => NetworkCountry)
  readonly country?: NetworkCountry | null
}
