import FuelCompanyReconciliationReportsTextProvider from "../FuelCompanyReconciliationReportsTextProvider"

const fuelCompanyAppAdministratorUserType = "fuelCompanyAppAdministrator"
export default class DefaultRuFuelCompanyReconciliationReportsTextProvider
  implements FuelCompanyReconciliationReportsTextProvider {
  listTitle({
    userType
  }: {
    readonly userType: string
  }): string {
    return userType === fuelCompanyAppAdministratorUserType ?
      "Сверки взаиморасчётов" :
      "Сверка с топливной компанией"
  }

  listDescription(): string {
    return "Срок хранения сформированных отчетов 30 календарных дней"
  }

  newObjectTitle({
    userType
  }: {
    readonly userType: string
  }): string {
    return userType === fuelCompanyAppAdministratorUserType ?
      "Новая сверка" :
      "Новый отчет"
  }

  createdDateField(): string {
    return "Дата создания"
  }

  fromDateField(): string {
    return "Дата начала"
  }

  toDateField(): string {
    return "Дата окончания"
  }

  fuelCompanyField(): string {
    return "Топливная компания"
  }

  reportField(): string {
    return "Отчет"
  }
}
