import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import RefuellingOrdersI18n from "../../i18n/RefuellingOrdersI18n"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import isBlank from "../../../../../admin/lib/isBlank"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import RefuellingOrdersFilter from "../../../../core/domain/refuelling-orders/RefuellingOrdersFilter"
import AppI18 from "../../../../core/i18n/AppI18"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import GetCarriersForRefuellingOrdersUseCase
  from "../../domain/use-cases/carriers/GetCarriersForRefuellingOrdersUseCase"
import Fuel from "../../../../core/domain/fuels/Fuel"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import GetFuelsForRefuellingOrdersUseCase from "../../domain/use-cases/fuels/GetFuelsForRefuellingOrdersUseCase"
import GetFuelCompaniesForRefuellingOrdersUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForRefuellingOrdersUseCase"
import GetGasStationsForRefuellingOrdersUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForRefuellingOrdersUseCase"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import RefuellingOrderErrorsObject from "../../../../core/domain/refuelling-orders/RefuellingOrderErrorsObject"
import GasStationsFilter from "../../../../core/domain/gas-stations/GasStationsFilter"
import User from "../../../../core/domain/users/User"
import GetDriversForRefuellingOrdersUseCase from "../../domain/use-cases/drivers/GetDriversForRefuellingOrdersUseCase"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import Transport from "../../../../core/domain/transport/Transport"
import GetTransportsForRefuellingOrdersUseCase
  from "../../domain/use-cases/transports/GetTransportsForRefuellingOrdersUseCase"
import TransportsFilter from "../../../../core/domain/transport/TransportsFilter"
import DriversFilter from "../../../../core/domain/drivers/DriversFilter"
import isPresent from "../../../../../admin/lib/isPresent"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const tripIdFilterName = "tripId"

export interface RefuellingOrdersTableProviderParameters {
  readonly coreI18n: CoreI18n
  readonly appI18n: AppI18
  readonly refuellingOrdersI18n: RefuellingOrdersI18n
  readonly timeZone: string
  readonly getCarriersUseCase: GetCarriersForRefuellingOrdersUseCase
  readonly getFuelsUseCase: GetFuelsForRefuellingOrdersUseCase
  readonly getFuelCompaniesUseCase: GetFuelCompaniesForRefuellingOrdersUseCase
  readonly getGasStationsUseCase: GetGasStationsForRefuellingOrdersUseCase
  readonly getDriversUseCase: GetDriversForRefuellingOrdersUseCase
  readonly getTransportsUseCase: GetTransportsForRefuellingOrdersUseCase
}

export default abstract class BaseRefuellingOrdersTableProvider
  implements TableProvider<RefuellingOrder, RefuellingOrdersFilter> {
  protected readonly refuellingOrdersI18n: RefuellingOrdersI18n
  private readonly coreI18n: CoreI18n
  private readonly appI18n: AppI18
  private readonly timeZone: string
  private readonly getCarriersUseCase: GetCarriersForRefuellingOrdersUseCase
  private readonly getFuelsUseCase: GetFuelsForRefuellingOrdersUseCase
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForRefuellingOrdersUseCase
  private readonly getGasStationsUseCase: GetGasStationsForRefuellingOrdersUseCase
  private readonly getDriversUseCase: GetDriversForRefuellingOrdersUseCase
  private readonly getTransportsUseCase: GetTransportsForRefuellingOrdersUseCase

  constructor(parameters: RefuellingOrdersTableProviderParameters) {
    this.coreI18n = parameters.coreI18n
    this.appI18n = parameters.appI18n
    this.refuellingOrdersI18n = parameters.refuellingOrdersI18n
    this.timeZone = parameters.timeZone
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getFuelsUseCase = parameters.getFuelsUseCase
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
    this.getDriversUseCase = parameters.getDriversUseCase
    this.getTransportsUseCase = parameters.getTransportsUseCase
  }

  abstract getRowUrl(refuellingOrder: RefuellingOrder): string

  abstract getTitle(): string

  getEntity(): string {
    return Entity.REFUELLING_ORDERS
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getFilter(): Filter<RefuellingOrdersFilter> {
    const refuellingOrdersTextProvider = this.refuellingOrdersI18n.getTextProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()
    const appTextProvider = this.appI18n.getTextProvider()

    return new Filter<RefuellingOrdersFilter>({
      buildFilterObject: (filterValueByFilterId): RefuellingOrdersFilter => {
        const tripIdFilterValue = filterValueByFilterId?.[tripIdFilterName]
        const tripId = isPresent(tripIdFilterValue) ? parseInt(tripIdFilterValue) : null
        return {
          tripId: tripId
        }
      },
      fields: [
        new SingleSelectFormField<RefuellingOrdersFilter, void, Carrier>({
          title: refuellingOrdersTextProvider.carrierField(),
          entity: Entity.CARRIERS,
          groupName: "",
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getId: () => "carrierId",
          getValue: (filter: RefuellingOrdersFilter) => filter.carrier,
          setValue: (filter: RefuellingOrdersFilter, carrier: Carrier | null): RefuellingOrdersFilter => {
            const previousCarrierId = filter.carrierId
            let transport = filter.transport
            let driver = filter.driver
            if (isPresent(carrier?.id) && previousCarrierId !== carrier?.id) {
              transport = null
              driver = null
            }

            return {
              ...filter,
              carrier,
              carrierId: carrier?.id ?? null,
              transport,
              transportId: transport?.id ?? null,
              driver,
              driverId: driver?.id ?? null
            }
          },
          getObjectsUseCase: this.getCarriersUseCase,
          getOptionId: (optionObject: Carrier) => optionObject.id!.toString(),
          getOptionText: (optionObject: Carrier) => optionObject.name
        }),
        new SingleSelectFormField<RefuellingOrdersFilter, void, Fuel>({
          title: refuellingOrdersTextProvider.fuelField(),
          entity: Entity.FUELS,
          groupName: "",
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getId: () => "fuelId",
          getValue: (filter: RefuellingOrdersFilter) => filter.fuel,
          setValue: (filter: RefuellingOrdersFilter, fuel: Fuel | null): RefuellingOrdersFilter => ({
            ...filter,
            fuel,
            fuelId: fuel?.id
          }),
          getObjectsUseCase: this.getFuelsUseCase,
          getOptionId: (optionObject: Fuel) => optionObject.id!.toString(),
          getOptionText: (optionObject: Fuel) => optionObject.name
        }),
        new SingleSelectFormField<RefuellingOrdersFilter, void, FuelCompany>({
          title: refuellingOrdersTextProvider.fuelCompanyField(),
          entity: Entity.FUEL_COMPANIES,
          groupName: "",
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getId: () => "fuelCompanyId",
          getValue: (filter: RefuellingOrdersFilter) => filter.fuelCompany,
          setValue: (filter: RefuellingOrdersFilter, fuelCompany: FuelCompany | null) => {
            const previousFuelCompanyId = filter.fuelCompanyId
            let gasStation = filter.gasStation
            if (isPresent(fuelCompany?.id) && previousFuelCompanyId !== fuelCompany?.id) {
              gasStation = null
            }

            return {
              ...filter,
              fuelCompany,
              fuelCompanyId: fuelCompany?.id ?? null,
              gasStation,
              gasStationId: gasStation?.id ?? null
            }
          },
          getObjectsUseCase: this.getFuelCompaniesUseCase,
          getOptionId: (optionObject: FuelCompany) => optionObject.id!.toString(),
          getOptionText: (optionObject: FuelCompany) => optionObject.name
        }),
        new SingleSelectFormField<RefuellingOrdersFilter, void, GasStation, GasStationsFilter>({
          title: refuellingOrdersTextProvider.gasStationField(),
          entity: Entity.GAS_STATIONS,
          groupName: "",
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getId: () => "gasStationId",
          getValue: (filter: RefuellingOrdersFilter) => filter.gasStation,
          setValue: (filter: RefuellingOrdersFilter, gasStation: GasStation | null): RefuellingOrdersFilter => ({
            ...filter,
            gasStation,
            gasStationId: gasStation?.id
          }),
          getObjectsUseCase: this.getGasStationsUseCase,
          getOptionObjectsFilter: (filter: RefuellingOrdersFilter) => ({
            fuelCompany: filter.fuelCompany,
            fuelCompanyId: filter.fuelCompany?.id
          }),
          getOptionId: (optionObject: GasStation) => optionObject.id!.toString(),
          getOptionText: (optionObject: GasStation) => optionObject.name
        }),
        new SingleSelectFormField<RefuellingOrdersFilter, void, User, DriversFilter>({
          title: refuellingOrdersTextProvider.driverField(),
          entity: Entity.DRIVERS,
          groupName: "",
          getId: () => "driverId",
          getValue: (filter: RefuellingOrdersFilter) => filter.driver,
          setValue: (filter: RefuellingOrdersFilter, user: User | null): RefuellingOrdersFilter => ({
            ...filter,
            driver: user,
            driverId: user?.id
          }),
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getObjectsUseCase: this.getDriversUseCase,
          getOptionObjectsFilter: (filter: RefuellingOrdersFilter) => ({
            carrier: filter.carrier,
            carrierId: filter.carrier?.id
          }),
          getOptionId: (optionObject: User) => optionObject.id!.toString(),
          getOptionText: (optionObject: User) => appTextProvider.driverName({
            user: optionObject
          })
        }),
        new DateFormField<RefuellingOrdersFilter, RefuellingOrderErrorsObject>({
          title: refuellingOrdersTextProvider.dateFromFilter(),
          groupName: "",
          getId: () => "dateFrom",
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getValue: (filter: RefuellingOrdersFilter) => filter.dateFrom,
          setValue: (filter: RefuellingOrdersFilter, dateFrom: Date | null | undefined): RefuellingOrdersFilter => ({
            ...filter, dateFrom
          })
        }),
        new DateFormField<RefuellingOrdersFilter, RefuellingOrderErrorsObject>({
          title: refuellingOrdersTextProvider.dateToFilter(),
          groupName: "",
          getId: () => "dateTo",
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getValue: (filter: RefuellingOrdersFilter) => filter.dateTo,
          setValue: (filter: RefuellingOrdersFilter, dateTo: Date | null | undefined): RefuellingOrdersFilter => ({
            ...filter, dateTo
          })
        }),
        new SingleSelectFormField<RefuellingOrdersFilter, void, Transport, TransportsFilter>({
          title: refuellingOrdersTextProvider.transportStateNumberFilter(),
          entity: Entity.TRANSPORTS,
          groupName: "",
          getId: () => "transportId",
          placeholder: coreTextProvider.unlimited(),
          clearable: true,
          getValue: (filter: RefuellingOrdersFilter) => filter.transport,
          setValue: (filter: RefuellingOrdersFilter, transport: Transport | null): RefuellingOrdersFilter => ({
            ...filter,
            transport: transport,
            transportId: transport?.id
          }),
          getObjectsUseCase: this.getTransportsUseCase,
          getOptionObjectsFilter: (filter: RefuellingOrdersFilter) => ({
            carrier: filter.carrier,
            carrierId: filter.carrier?.id
          }),
          getOptionId: (optionObject: Transport) => optionObject.id!.toString(),
          getOptionText: (optionObject: Transport) => optionObject.stateNumber
        })
      ]
    })
  }

  getTable(): Table<RefuellingOrder, RefuellingOrdersFilter> {
    const appUrlProvider = new AppUrlProvider()
    const appTextProvider = this.appI18n.getTextProvider()
    const refuellingOrdersTextProvider = this.refuellingOrdersI18n.getTextProvider()

    return new Table<RefuellingOrder, RefuellingOrdersFilter>({
      title: this.getTitle(),
      getObjectId: (refuellingOrder: RefuellingOrder) => refuellingOrder.id!.toString(),
      getRowUrl: (refuellingOrder: RefuellingOrder) => this.getRowUrl(refuellingOrder),
      columns: [
        new TableColumn<RefuellingOrder>({
          name: "createdAt",
          title: refuellingOrdersTextProvider.beginDateField(),
          createValue: (refuellingOrder: RefuellingOrder) => new DateTableValue({
            value: refuellingOrder.createdAt,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        }),
        new TableColumn<RefuellingOrder>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: refuellingOrdersTextProvider.carrierField(),
          createValue: (refuellingOrder: RefuellingOrder) => new TextTableValue({
            value: refuellingOrder.carrier?.name,
            url: (() => {
              const { carrier } = refuellingOrder
              if (isBlank(carrier)) return null

              return appUrlProvider.buildShowCarrierUrl({
                carrierId: carrier.id!
              })
            })()
          })
        }),
        new TableColumn<RefuellingOrder>({
          name: "fuelCompany",
          entity: Entity.FUEL_COMPANIES,
          title: refuellingOrdersTextProvider.fuelCompanyField(),
          createValue: (refuellingOrder: RefuellingOrder) => new TextTableValue({
            value: refuellingOrder.fuelCompany?.name,
            url: (() => {
              const { fuelCompany } = refuellingOrder
              if (isBlank(fuelCompany)) return null

              return appUrlProvider.buildShowFuelCompanyUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })()
          })
        }),
        new TableColumn<RefuellingOrder>({
          name: "gasStation",
          entity: Entity.GAS_STATIONS,
          title: refuellingOrdersTextProvider.gasStationField(),
          createValue: (refuellingOrder: RefuellingOrder) => new TextTableValue({
            value: refuellingOrder.gasStation?.name,
            url: (() => {
              const { gasStation } = refuellingOrder
              if (isBlank(gasStation)) return null

              return appUrlProvider.buildShowGasStationUrl({
                gasStationId: gasStation.id!
              })
            })()
          })
        }),
        new TableColumn<RefuellingOrder>({
          name: "trip",
          entity: Entity.TRIPS,
          title: refuellingOrdersTextProvider.tripField(),
          createValue: (refuellingOrder: RefuellingOrder) => new TextTableValue({
            value: refuellingOrder.userTrip?.trip?.name,
            url: (() => {
              const { userTrip } = refuellingOrder
              if (isBlank(userTrip?.trip)) return null

              return appUrlProvider.buildShowTripUrl({
                tripId: userTrip!.trip.id!
              })
            })()
          })
        }),
        new TableColumn<RefuellingOrder>({
          name: "transport",
          entity: Entity.TRANSPORTS,
          title: refuellingOrdersTextProvider.transportField(),
          createValue: (refuellingOrder: RefuellingOrder) => {
            const transport = refuellingOrder.userTrip?.trip?.transport
            return new TextTableValue({
              value: transport?.name,
              additionalValue: transport?.stateNumber,
              url: (() => {
                if (isBlank(transport)) return null

                return appUrlProvider.buildShowTransportUrl({
                  transportId: transport.id!
                })
              })()
            })
          }
        }),
        new TableColumn<RefuellingOrder>({
          name: "driver",
          entity: Entity.DRIVERS,
          title: refuellingOrdersTextProvider.driverField(),
          createValue: (refuellingOrder: RefuellingOrder) => new TextTableValue({
            value: appTextProvider.driverName({
              user: refuellingOrder.userTrip?.user
            }),
            url: (() => {
              const { userTrip } = refuellingOrder
              if (isBlank(userTrip?.user)) return null

              return appUrlProvider.buildShowDriverUrl({
                driverId: userTrip!.user.id!
              })
            })()
          })
        }),
        new TableColumn<RefuellingOrder>({
          name: "fuel",
          title: refuellingOrdersTextProvider.fuelField(),
          createValue: (refuellingOrder: RefuellingOrder) => new TextTableValue({
            value: refuellingOrder.fuel?.name
          })
        }),
        new TableColumn<RefuellingOrder>({
          name: "fuelPrice",
          title: refuellingOrdersTextProvider.priceField(),
          createValue: (refuellingOrder: RefuellingOrder) => {
            const { formattedFuelPrice } = refuellingOrder
            const value = (() => {
              if (isBlank(formattedFuelPrice)) return undefined

              return formattedFuelPrice && withRubbleSymbol(formattedFuelPrice)
            })()

            return new TextTableValue({
              value
            })
          }
        }),
        new TableColumn<RefuellingOrder>({
          name: "fuelValue",
          title: refuellingOrdersTextProvider.fillingVolumeField(),
          createValue: (refuellingOrder: RefuellingOrder) => {
            const formattedFuelValue = refuellingOrder.currentTransaction?.formattedFuelValue
            const value = (() => {
              if (isBlank(formattedFuelValue)) return undefined

              return `${formattedFuelValue} ${refuellingOrder.fuel?.shortFormattedUom}`
            })()

            return new TextTableValue({ value })
          }
        }),
        new TableColumn<RefuellingOrder>({
          name: "moneyValue",
          title: refuellingOrdersTextProvider.moneyValueField(),
          createValue: (refuellingOrder: RefuellingOrder) => {
            const formattedMoneyValue = refuellingOrder.currentTransaction?.formattedMoneyValue
            const value = (() => {
              if (isBlank(formattedMoneyValue)) return undefined

              return formattedMoneyValue && withRubbleSymbol(formattedMoneyValue)
            })()

            return new TextTableValue({ value })
          }
        }),
        new TableColumn<RefuellingOrder>({
          name: "costValue",
          title: refuellingOrdersTextProvider.costValueField(),
          createValue: (refuellingOrder: RefuellingOrder) => {
            const formattedCostValue = refuellingOrder.currentTransaction?.formattedCostValue
            const value = (() => {
              if (isBlank(formattedCostValue)) return undefined

              return formattedCostValue && withRubbleSymbol(formattedCostValue)
            })()

            return new TextTableValue({ value })
          }
        }),
        new TableColumn<RefuellingOrder>({
          name: "status",
          title: refuellingOrdersTextProvider.refuellingStatusField(),
          createValue: (refuellingOrder: RefuellingOrder) => new TextTableValue({
            value: refuellingOrder.statusSelectOption?.text,
            valueColorHex: refuellingOrder.statusColorHex
          })
        })
      ],
      filter: this.getFilter()
    })
  }
}
