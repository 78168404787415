import DiModule from "../../../../admin/lib/di/DiModule"
import AppNetworkDiModule from "./AppNetworkDiModule"
import AuthenticationNetworkDiModule
  from "../../../../admin/features/authentication/di/modules/AuthenticationNetworkDiModule"
import PasswordsNetworkSource from "../../../../admin/features/authentication/data/sources/PasswordsNetworkSource"
import DefaultPasswordsNetworkSource from "../../data/sources/network/DefaultPasswordsNetworkSource"

export default class DefaultAuthenticationNetworkDiModule
  extends DiModule
  implements AuthenticationNetworkDiModule {

  private readonly appNetworkDiModule: AppNetworkDiModule

  constructor(parameters: {
    readonly appNetworkDiModule: AppNetworkDiModule
  }) {
    super()
    this.appNetworkDiModule = parameters.appNetworkDiModule
  }

  providePasswordsNetworkSource(): PasswordsNetworkSource {
    return this.single(
      DefaultPasswordsNetworkSource.name,
      () => new DefaultPasswordsNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
