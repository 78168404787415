import { Expose, Type } from "class-transformer"
import AttributeErrorObject from "../../../../../admin/core/domain/entities/errors/AttributeErrorObject"

export default class NetworkAttributeErrorObject implements AttributeErrorObject {
  @Expose()
  @Type(() => String)
  readonly type?: string | null

  @Expose()
  @Type(() => Number)
  readonly id?: number | null
}
