import UsersI18 from "../../i18n/UsersI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import UsersTextProvider from "../../i18n/UsersTextProvider"

export default interface UsersI18nDiModule {
  provideUsersI18n(): UsersI18
}

export class DefaultUsersI18nDiModule
  extends I18nDiModule<UsersTextProvider>
  implements UsersI18nDiModule {

  provideUsersI18n(): UsersI18 {
    return this.single(
      UsersI18.name,
      () => new UsersI18(this.getI18nParameters())
    )
  }
}
