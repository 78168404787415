import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import TripLimitErrorsObjectAttributes from "../../../../../domain/trip-limits/TripLimitErrorsObjectAttributes"
import AttributeError from "../../../../../../../admin/core/domain/entities/errors/AttributeError"

export default class NetworkTripLimitErrorsObjectAttributes implements TripLimitErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelId?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly tripLimitTypeId?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly value?: AttributeError[] | null
}
