import UrlProvider from "./UrlProvider"

export default class CoreUrlProvider extends UrlProvider {
  buildMainUrl(): string {
    return this.buildUrl({
      path: `${this.rootPath()}/`
    })
  }

  buildRootUrl(): string {
    return this.buildUrl({ path: this.rootPath() })
  }

  buildUserProfileUrl(): string {
    return this.buildUrl({ path: this.userProfilePath() })
  }

  buildSettingsUrl(): string {
    return this.buildUrl({ path: this.settingsPath() })
  }

   buildSignInUrl(): string {
    return this.buildUrl({ path: this.rootSignInPath() })
  }

  buildPasswordRecoveryRequestUrl(): string {
    return this.buildUrl({ path: this.rootPasswordRecoveryRequestPath() })
  }

  buildPasswordRecoveryUrl(): string {
    return this.buildUrl({ path: this.rootPasswordRecoveryPath() })
  }

  protected rootPath(): string {
    return "admin"
  }

  protected rootSignInPath(): string {
    return `${this.rootPath()}/authentication`
  }

  protected rootPasswordRecoveryRequestPath(): string {
    return `${this.rootPath()}/password_recovery_request`
  }

  protected rootPasswordRecoveryPath(): string {
    return `${this.rootPath()}/password_recovery`
  }

  protected userProfilePath(): string {
    return `${this.rootPath()}/user_profile`
  }

  protected settingsPath(): string {
    return `${this.rootPath()}/settings`
  }
}
