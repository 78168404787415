import GasStationsRepository from "../../repositories/GasStationsRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationError from "../../../../../core/domain/gas-stations/GasStationError"

export interface CreateGasStationParameters {
  readonly object: GasStation
}

export default class CreateGasStationUseCase implements CreateObjectUseCase<GasStation, GasStationError> {
  private readonly gasStationsRepository: GasStationsRepository

  constructor(parameters: {
    readonly gasStationsRepository: GasStationsRepository
  }) {
    this.gasStationsRepository = parameters.gasStationsRepository
  }

  async call(parameters: CreateGasStationParameters): Promise<CreateObjectResult<GasStation, GasStationError>> {
    return await this.gasStationsRepository.createGasStation(parameters)
  }
}
