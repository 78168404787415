import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import FuelCompanyReconciliationReportErrorsObjectAttributes
  from "../../../../../domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReportErrorsObjectAttributes"

export default class NetworkFuelCompanyReconciliationReportErrorsObjectAttributes
  implements FuelCompanyReconciliationReportErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fromDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly toDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelCompanyId?: NetworkAttributeError[] | null
}
