import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import FuelCompanyUsersDomainDiModule from "./FuelCompanyUsersDomainDiModule"
import FuelCompanyUsersTableProvider from "../../presentation/table-providers/FuelCompanyUsersTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import FuelCompanyUsersFormProvider from "../../presentation/form-providers/FuelCompanyUsersFormProvider"
import FuelCompanyUserInfoProvider from "../../presentation/info-providers/FuelCompanyUserInfoProvider"
import FuelCompanyUsersI18nDiModule from "./FuelCompanyUsersI18nDiModule"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "fuelCompanyUsers"

export default interface FuelCompanyUsersPresentationDiModule {
  provideFuelCompanyUsersListPage(): React.ReactNode

  provideFuelCompanyUserInfoPage(): React.ReactNode

  provideFuelCompanyUserFormPage(): React.ReactNode
}

export class DefaultFuelCompanyUsersPresentationDiModule
  extends DiModule
  implements FuelCompanyUsersPresentationDiModule {

  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly fuelCompanyUsersI18nDiModule: FuelCompanyUsersI18nDiModule
  private readonly fuelCompanyUsersDomainDiModule: FuelCompanyUsersDomainDiModule

  constructor(parameters: {
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly fuelCompanyUsersI18nDiModule: FuelCompanyUsersI18nDiModule
    readonly fuelCompanyUsersDomainDiModule: FuelCompanyUsersDomainDiModule
  }) {
    super()
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.fuelCompanyUsersI18nDiModule = parameters.fuelCompanyUsersI18nDiModule
    this.fuelCompanyUsersDomainDiModule = parameters.fuelCompanyUsersDomainDiModule
  }

  provideFuelCompanyUsersListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.fuelCompanyUsersDomainDiModule.provideGetFuelCompanyUsersUseCase(),
      tableProvider: this.provideFuelCompanyUsersTableProvider()
    })
  }

  private provideFuelCompanyUsersTableProvider(): FuelCompanyUsersTableProvider {
    return this.single(
      FuelCompanyUsersTableProvider.name,
      () => new FuelCompanyUsersTableProvider({
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase(),
        fuelCompanyUsersI18: this.fuelCompanyUsersI18nDiModule.provideFuelCompanyUsersI18n(),
        getFuelCompaniesUseCase:
          this.fuelCompanyUsersDomainDiModule.provideGetFuelCompaniesForFuelCompanyUsersUseCase()
      })
    )
  }

  provideFuelCompanyUserFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.fuelCompanyUsersDomainDiModule.provideGetFuelCompanyUserUseCase(),
      createObjectUseCase: this.fuelCompanyUsersDomainDiModule.provideCreateFuelCompanyUserUseCase(),
      updateObjectUseCase: this.fuelCompanyUsersDomainDiModule.provideUpdateFuelCompanyUsersUseCase(),
      formProvider: this.provideFuelCompanyUserFormProvider()
    })
  }

  private provideFuelCompanyUserFormProvider(): FuelCompanyUsersFormProvider {
    return this.single(
      FuelCompanyUsersFormProvider.name,
      () => new FuelCompanyUsersFormProvider({
        usersI18: this.fuelCompanyUsersI18nDiModule.provideFuelCompanyUsersI18n(),
        appI18: this.appI18nDiModule.provideAppI18n(),
        getFuelCompaniesUseCase:
          this.fuelCompanyUsersDomainDiModule.provideGetFuelCompaniesForFuelCompanyUsersUseCase()
      })
    )
  }

  provideFuelCompanyUserInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.fuelCompanyUsersDomainDiModule.provideGetFuelCompanyUserUseCase(),
      infoProvider: this.provideFuelCompanyUserInfoProvider()
    })
  }

  private provideFuelCompanyUserInfoProvider(): FuelCompanyUserInfoProvider {
    return this.single(
      FuelCompanyUserInfoProvider.name,
      () => new FuelCompanyUserInfoProvider({
        fuelCompanyUsersI18: this.fuelCompanyUsersI18nDiModule.provideFuelCompanyUsersI18n(),
        appI18: this.appI18nDiModule.provideAppI18n()
      })
    )
  }
}
