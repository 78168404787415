import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import TransportErrorsObjectAttributes from "../../../../../domain/transport/TransportErrorsObjectAttributes"

export default class NetworkTransportErrorsObjectAttributes implements TransportErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly stateNumber?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly carrierId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly vehicleCertificateNumber?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly transportFuels?: NetworkAttributeError[] | null
}
