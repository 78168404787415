import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForTripsRepository from "../../repositories/CarriersForTripsRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForTripsParameters extends GetObjectsParameters {
}

export default class GetCarriersForTripsUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForTripsRepository: CarriersForTripsRepository

  constructor(parameters: {
    readonly carriersForTripsRepository: CarriersForTripsRepository
  }) {
    this.carriersForTripsRepository = parameters.carriersForTripsRepository
  }

  async call(parameters: GetCarriersForTripsParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForTripsRepository.getCarriers(parameters)
  }
}
