import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import GasStationsForFuelPricesRepository from "../../domain/repositories/GasStationsForFuelPricesRepository"
import {
  GetGasStationsForFuelPriceParameters
} from "../../domain/use-cases/gas-stations/GetGasStationsForFuelPriceUseCase"
import GasStationsNetworkSource from "../../../../core/data/sources/network/GasStationsNetworkSource"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import NetworkGasStationsRequestFilter
  from "../../../../core/data/entities/gas-stations/request-queries/NetworkGasStationsRequestFilter"
import GasStationsMapper from "../../../../core/data/mappers/GasStationsMapper"

export default class DefaultGasStationsForFuelPricesRepository implements GasStationsForFuelPricesRepository {
  private readonly gasStationsNetworkSource: GasStationsNetworkSource

  constructor(parameters: {
    readonly gasStationsNetworkSource: GasStationsNetworkSource
  }) {
    this.gasStationsNetworkSource = parameters.gasStationsNetworkSource
  }

  async getGasStations({
    ids,
    query,
    pagination,
    sort
  }: GetGasStationsForFuelPriceParameters): Promise<GetObjectsResult<GasStation>> {
    const result = await this.gasStationsNetworkSource.getGasStations({
      filter: new NetworkGasStationsRequestFilter({
        query,
        ids
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.gasStations!.map((gasStation) => {
              return new GasStationsMapper().mapNetworkToDomain({ gasStation })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
