import NetworkFuelCompanyCommercialTermErrorsObjectAttributes from "./NetworkFuelCompanyCommercialTermErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import FuelCompanyCommercialTermErrorsObject
  from "../../../../../domain/fuel-company-commercial-terms/FuelCompanyCommercialTermErrorsObject"

export default class NetworkFuelCompanyCommercialTermErrorsObject implements FuelCompanyCommercialTermErrorsObject {
  @Expose()
  @Type(() => NetworkFuelCompanyCommercialTermErrorsObjectAttributes)
  readonly attributes?: NetworkFuelCompanyCommercialTermErrorsObjectAttributes | null
}
