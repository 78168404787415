import NetworkPermissionGroup from "../../entities/network/user-profile/NetworkPermissionGroup"
import PermissionGroup from "../../../domain/entities/user-profile/PermissionGroup"
import PermissionsMapper from "./PermissionsMapper"

export default class PermissionGroupMapper {
  mapNetworkToDomain({
    permissionGroup
  }: {
    readonly permissionGroup: NetworkPermissionGroup
  }): PermissionGroup {
    const permissionsMapper = new PermissionsMapper()

    return {
      userType: permissionGroup.userType,
      permissions: permissionGroup.permissions && permissionGroup.permissions
        .map((permission) => permissionsMapper.mapNetworkToDomain({ permission }))
    }
  }
}
