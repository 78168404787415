import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelCompanyCommercialTermsRequestFilter
  from "../../entities/fuel-company-commercial-terms/request-queries/NetworkFuelCompanyCommercialTermsRequestFilter"
import NetworkFuelCompanyCommercialTermsResponseBody
  from "../../entities/fuel-company-commercial-terms/response-bodies/NetworkFuelCompanyCommercialTermsResponseBody"
import NetworkFuelCompanyCommercialTermResponseBody
  from "../../entities/fuel-company-commercial-terms/response-bodies/NetworkFuelCompanyCommercialTermResponseBody"
import NetworkFuelCompanyCommercialTermErrorResponseBody
  from "../../entities/fuel-company-commercial-terms/response-bodies/errors/NetworkFuelCompanyCommercialTermErrorResponseBody"
import NetworkFuelCompanyCommercialTermsRequestQuery
  from "../../entities/fuel-company-commercial-terms/request-queries/NetworkFuelCompanyCommercialTermsRequestQuery"
import NetworkFuelCompanyCommercialTerm
  from "../../entities/fuel-company-commercial-terms/NetworkFuelCompanyCommercialTerm"
import NetworkFuelCompanyCommercialTermRequestBody
  from "../../entities/fuel-company-commercial-terms/request-bodies/NetworkFuelCompanyCommercialTermRequestBody"

const basePath = "/api/admin/fuel_company_commercial_terms"

export default class FuelCompanyCommercialTermsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getFuelCompanyCommercialTerms({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkFuelCompanyCommercialTermsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetFuelCompanyCommercialTermsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkFuelCompanyCommercialTermsRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyCommercialTermsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getFuelCompanyCommercialTerm({
    fuelCompanyCommercialTermId
  }: {
    readonly fuelCompanyCommercialTermId: number
  }): Promise<GetFuelCompanyCommercialTermNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${fuelCompanyCommercialTermId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyCommercialTermResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createFuelCompanyCommercialTerm({
    fuelCompanyCommercialTerm
  }: {
    readonly fuelCompanyCommercialTerm?: NetworkFuelCompanyCommercialTerm | null
  }): Promise<CreateFuelCompanyCommercialTermNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkFuelCompanyCommercialTermRequestBody({
        fuelCompanyCommercialTerm
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyCommercialTermResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelCompanyCommercialTermErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateFuelCompanyCommercialTerm({
    fuelCompanyCommercialTermId,
    fuelCompanyCommercialTerm
  }: {
    readonly fuelCompanyCommercialTermId: number
    readonly fuelCompanyCommercialTerm?: NetworkFuelCompanyCommercialTerm | null
  }): Promise<UpdateFuelCompanyCommercialTermNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${fuelCompanyCommercialTermId}`,
      body: instanceToPlain(new NetworkFuelCompanyCommercialTermRequestBody({
        fuelCompanyCommercialTerm
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyCommercialTermResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelCompanyCommercialTermErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetFuelCompanyCommercialTermsNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyCommercialTermsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetFuelCompanyCommercialTermNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyCommercialTermResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateFuelCompanyCommercialTermNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyCommercialTermResponseBody> |
  ErrorExecutionResult<NetworkFuelCompanyCommercialTermErrorResponseBody> |
  FailureExecutionResult

export type UpdateFuelCompanyCommercialTermNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyCommercialTermResponseBody> |
  ErrorExecutionResult<NetworkFuelCompanyCommercialTermErrorResponseBody> |
  FailureExecutionResult
