import GetCarriersUseCase from "../../domain/use-cases/carriers/GetCarriersUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CarriersDataDiModule from "./CarriersDataDiModule"
import CreateCarrierUseCase from "../../domain/use-cases/carriers/CreateCarrierUseCase"
import GetCarrierUseCase from "../../domain/use-cases/carriers/GetCarrierUseCase"
import UpdateCarrierUseCase from "../../domain/use-cases/carriers/UpdateCarrierUseCase"

export default interface CarriersDomainDiModule {
  provideGetCarriersUseCase(): GetCarriersUseCase

  provideGetCarrierUseCase(): GetCarrierUseCase

  provideCreateCarrierUseCase(): CreateCarrierUseCase

  provideUpdateCarrierUseCase(): UpdateCarrierUseCase
}

export class DefaultCarriersDomainDiModule extends DiModule implements CarriersDomainDiModule {
  private readonly carriersDataDiModule: CarriersDataDiModule

  constructor(parameters: {
    readonly carriersDataDiModule: CarriersDataDiModule
  }) {
    super()
    this.carriersDataDiModule = parameters.carriersDataDiModule
  }

  provideGetCarriersUseCase(): GetCarriersUseCase {
    return this.single(
      GetCarriersUseCase.name,
      () => new GetCarriersUseCase({
        carriersRepository: this.carriersDataDiModule.provideCarriersRepository()
      })
    )
  }

  provideGetCarrierUseCase(): GetCarrierUseCase {
    return this.single(
      GetCarrierUseCase.name,
      () => new GetCarrierUseCase({
        carriersRepository: this.carriersDataDiModule.provideCarriersRepository()
      })
    )
  }

  provideCreateCarrierUseCase(): CreateCarrierUseCase {
    return this.single(
      CreateCarrierUseCase.name,
      () => new CreateCarrierUseCase({
        carriersRepository: this.carriersDataDiModule.provideCarriersRepository()
      })
    )
  }

  provideUpdateCarrierUseCase(): UpdateCarrierUseCase {
    return this.single(
      UpdateCarrierUseCase.name,
      () => new UpdateCarrierUseCase({
        carriersRepository: this.carriersDataDiModule.provideCarriersRepository()
      })
    )
  }
}
