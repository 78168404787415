import { Expose, Type } from "class-transformer"

export default class NetworkPasswordRecoveryBody {
  @Expose()
  @Type(() => String)
  readonly token?: string | null

  @Expose()
  @Type(() => String)
  readonly password?: string | null

  @Expose()
  @Type(() => String)
  readonly passwordConfirmation?: string | null

  constructor(parameters: {
    readonly token?: string | null
    readonly password?: string | null
    readonly passwordConfirmation?: string | null
  }) {
    this.token = parameters.token
    this.password = parameters.password
    this.passwordConfirmation = parameters.passwordConfirmation
  }
}
