import { Expose, Type } from "class-transformer"

export default class NetworkFuelCompaniesRequestFilter {
  @Expose()
  @Type(() => String)
  readonly query?: string | null

  @Expose()
  readonly ids?: string[] | null

  constructor(parameters: {
    readonly query?: string | null
    readonly ids?: string[] | null
  }) {
    this.query = parameters.query
    this.ids = parameters.ids
  }
}
