import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import DriversI18 from "../../i18n/DriversI18"
import User from "../../../../core/domain/users/User"
import isBlank from "../../../../../admin/lib/isBlank"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import DriversFilter from "../../../../core/domain/drivers/DriversFilter"
import Carrier from "../../../../core/domain/carriers/Carrier"
import GetCarriersForDriverUseCase from "../../domain/use-cases/carriers/GetCarriersForDriverUseCase"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import IMask from "imask"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class DriversTableProvider implements TableProvider<User, DriversFilter> {
  private readonly coreI18n: CoreI18n
  private readonly driversI18: DriversI18
  private readonly getCarriersUseCase: GetCarriersForDriverUseCase

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly driversI18: DriversI18
    readonly getCarriersUseCase: GetCarriersForDriverUseCase
  }) {
    this.coreI18n = parameters.coreI18n
    this.driversI18 = parameters.driversI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
  }

  getEntity(): string {
    return Entity.DRIVERS
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<User, DriversFilter> {
    const appUrlProvider = new AppUrlProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()
    const driversTextProvider = this.driversI18.getTextProvider()

    return new Table<User, DriversFilter>({
      title: driversTextProvider.listTitle(),
      getObjectId: (driver: User) => driver.id!.toString(),
      getRowUrl: (driver: User) => appUrlProvider.buildShowDriverUrl({
        driverId: driver.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewDriverUrl(),
      columns: [
        new TableColumn<User>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: driversTextProvider.carrierField(),
          createValue: (driver: User) => new TextTableValue({
            value: driver.carrier?.name,
            url: (() => {
              const { carrier } = driver
              if (isBlank(carrier)) return null

              return appUrlProvider.buildShowCarrierUrl({
                carrierId: carrier.id!
              })
            })()
          })
        }),
        new TableColumn<User>({
          name: "lastName",
          title: driversTextProvider.lastNameField(),
          createValue: (driver: User) => new TextTableValue({
            value: driver.profile?.lastName
          })
        }),
        new TableColumn<User>({
          name: "firstName",
          title: driversTextProvider.firstNameField(),
          createValue: (driver: User) => new TextTableValue({
            value: driver.profile?.firstName
          })
        }),
        new TableColumn<User>({
          name: "middleName",
          title: driversTextProvider.middleNameField(),
          createValue: (driver: User) => new TextTableValue({
            value: driver.profile?.middleName
          })
        }),
        new TableColumn<User>({
          name: "phoneNumber",
          title: driversTextProvider.phoneNumberField(),
          createValue: (driver: User) => new TextTableValue({
            value: (() => {
              const mask = driver.phoneNumber?.country?.phoneNumberFormattingMask
              const phoneNumber = driver.phoneNumber?.value
              if (isBlank(mask) || isBlank(phoneNumber)) return phoneNumber
              const numberPipe = IMask.createPipe({
                mask
              })

              return numberPipe(phoneNumber)
            })()
          })
        }),
        new TableColumn<User>({
          name: "blocked",
          title: driversTextProvider.statusField(),
          isSortingAvailable: true,
          reversedSortOrder: true,
          createValue: (driver: User) => {
            const { blocked = false } = driver

            return new TextTableValue({
              value: (blocked ?? false) ?
                driversTextProvider.statusValueBlocked() :
                driversTextProvider.statusValueActive(),
              isActive: !driver.blocked
            })
          }
        })
      ],
      filter: new Filter<DriversFilter>({
        buildFilterObject: (): DriversFilter => ({}),
        fields: [
          new SingleSelectFormField<DriversFilter, void, Carrier>({
            title: driversTextProvider.carrierField(),
            entity: Entity.CARRIERS,
            groupName: "",
            getId: () => "carrierId",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getValue: (filter: DriversFilter) => filter.carrier,
            setValue: (filter: DriversFilter, carrier: Carrier | null): DriversFilter => ({
              ...filter,
              carrier,
              carrierId: carrier?.id
            }),
            getObjectsUseCase: this.getCarriersUseCase,
            getOptionId: (carrier: Carrier) => carrier.id!.toString(),
            getOptionText: (carrier: Carrier) => carrier.name
          })
        ]
      })
    })
  }
}
