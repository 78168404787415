import React from "react"
import PasswordRecoveryPresenter from "./PasswordRecoveryPresenter"
import PasswordRecoveryView, { PasswordRecoveryViewState } from "./PasswordRecoveryView"
import { Navigate } from "react-router-dom"
import assertNever from "../../../../../lib/assertNever"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import PasswordRecoveryComponent from "../../components/password-recovery/PasswordRecoveryComponent"
import SuccessPasswordRecoveryComponent from "../../components/password-recovery/SuccessPasswordRecoveryComponent"

interface Props {
  readonly providePresenter: () => PasswordRecoveryPresenter
}

interface State {
  readonly passwordRecoveryViewState?: PasswordRecoveryViewState
}

export default class PasswordRecoveryPage
  extends React.Component<Props, State>
  implements PasswordRecoveryView {
  private readonly presenter: PasswordRecoveryPresenter

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showPasswordRecoveryViewState(passwordRecoveryViewState: PasswordRecoveryViewState) {
    this.setState({ passwordRecoveryViewState: passwordRecoveryViewState })
  }

  render() {
    const adminCoreUrlProvider = new CoreUrlProvider()

    const { passwordRecoveryViewState } = this.state

    return (
      <>
        {passwordRecoveryViewState && (() => {
          switch (passwordRecoveryViewState.type) {
            case "not_authenticated":
              return (
                <PasswordRecoveryComponent
                  onPasswordChanged={this.presenter.onPasswordChanged}
                  onPasswordConfirmationChanged={this.presenter.onPasswordConfirmationChanged}
                  onRecoveryPasswordButtonClicked={this.presenter.onRecoveryPasswordButtonClicked}
                  password={passwordRecoveryViewState.password}
                  passwordConfirmation={passwordRecoveryViewState.passwordConfirmation}
                  isPasswordRecovering={passwordRecoveryViewState.isPasswordRecovering}
                  passwordRecoveryError={passwordRecoveryViewState.passwordRecoveryError}
                  passwordRecoveryFailureException={passwordRecoveryViewState.passwordRecoveryFailureException}
                />
              )
            case "password_recovered":
              return (
                <SuccessPasswordRecoveryComponent
                  successMessage={passwordRecoveryViewState.successMessage}
                />
              )
            case "already_authenticated":
              return <Navigate to={adminCoreUrlProvider.buildSignInUrl()} replace={true} />
            default:
              assertNever(passwordRecoveryViewState)
          }
        })()}
      </>
    )
  }
}
