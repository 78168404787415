import GetGasStationBreaksUseCase from "../../domain/use-cases/gas-station-breaks/GetGasStationBreaksUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import GasStationBreaksDataDiModule from "./GasStationBreaksDataDiModule"
import CreateGasStationBreakUseCase from "../../domain/use-cases/gas-station-breaks/CreateGasStationBreakUseCase"
import GetGasStationBreakUseCase from "../../domain/use-cases/gas-station-breaks/GetGasStationBreakUseCase"
import UpdateGasStationBreakUseCase from "../../domain/use-cases/gas-station-breaks/UpdateGasStationBreakUseCase"
import GetGasStationsForGasStationBreaksUseCase from "../../domain/use-cases/gas-stations/GetGasStationsForGasStationBreaksUseCase"

export default interface GasStationBreaksDomainDiModule {
  provideGetGasStationBreaksUseCase(): GetGasStationBreaksUseCase

  provideGetGasStationBreakUseCase(): GetGasStationBreakUseCase

  provideCreateGasStationBreakUseCase(): CreateGasStationBreakUseCase

  provideUpdateGasStationBreakUseCase(): UpdateGasStationBreakUseCase

  provideGetGasStationsForGasStationBreaksUseCase(): GetGasStationsForGasStationBreaksUseCase
}

export class DefaultGasStationBreaksDomainDiModule extends DiModule implements GasStationBreaksDomainDiModule {
  private readonly gasStationBreaksDataDiModule: GasStationBreaksDataDiModule

  constructor(parameters: {
    readonly gasStationBreaksDataDiModule: GasStationBreaksDataDiModule
  }) {
    super()
    this.gasStationBreaksDataDiModule = parameters.gasStationBreaksDataDiModule
  }

  provideGetGasStationBreaksUseCase(): GetGasStationBreaksUseCase {
    return this.single(
      GetGasStationBreaksUseCase.name,
      () => new GetGasStationBreaksUseCase({
        gasStationBreaksRepository: this.gasStationBreaksDataDiModule.provideGasStationBreaksRepository()
      })
    )
  }

  provideGetGasStationBreakUseCase(): GetGasStationBreakUseCase {
    return this.single(
      GetGasStationBreakUseCase.name,
      () => new GetGasStationBreakUseCase({
        gasStationBreaksRepository: this.gasStationBreaksDataDiModule.provideGasStationBreaksRepository()
      })
    )
  }

  provideCreateGasStationBreakUseCase(): CreateGasStationBreakUseCase {
    return this.single(
      CreateGasStationBreakUseCase.name,
      () => new CreateGasStationBreakUseCase({
        gasStationBreaksRepository: this.gasStationBreaksDataDiModule.provideGasStationBreaksRepository()
      })
    )
  }

  provideUpdateGasStationBreakUseCase(): UpdateGasStationBreakUseCase {
    return this.single(
      UpdateGasStationBreakUseCase.name,
      () => new UpdateGasStationBreakUseCase({
        gasStationBreaksRepository: this.gasStationBreaksDataDiModule.provideGasStationBreaksRepository()
      })
    )
  }

  provideGetGasStationsForGasStationBreaksUseCase(): GetGasStationsForGasStationBreaksUseCase {
    return this.single(
      GetGasStationsForGasStationBreaksUseCase.name,
      () => new GetGasStationsForGasStationBreaksUseCase({
        gasStationsForGasStationBreaksRepository:
          this.gasStationBreaksDataDiModule.provideGasStationsForGasStationBreaksRepository()
      })
    )
  }
}
