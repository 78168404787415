import React, { createContext, useContext } from "react"
import NotFoundTheme from "../theme/NotFoundTheme"

export const NotFoundThemeProviderContext: React.Context<NotFoundTheme | null> =
  createContext<NotFoundTheme | null>(null)

export function useNotFoundThemeProvider(): NotFoundTheme {
  return useContext(NotFoundThemeProviderContext)!
}

export interface NotFoundThemeProviderProps {
  readonly children?: React.ReactNode
  readonly theme: NotFoundTheme
}

export function NotFoundThemeProvider({
  children,
  theme
}: NotFoundThemeProviderProps) {
  return (
    <NotFoundThemeProviderContext.Provider value={theme}>
      {children}
    </NotFoundThemeProviderContext.Provider>
  )
}
