import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import isPresent from "../../../../../../lib/isPresent"

export default class InfoFormField<DomainObject, ErrorsObject> extends FormField<DomainObject, ErrorsObject> {
  private readonly getValue: (object: DomainObject) => string | null | undefined

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly getValue: (object: DomainObject) => string | null | undefined
  }) {
    super(parameters)
    this.getValue = parameters.getValue
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    return new InfoFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      value: this.getValue(object)
    })
  }

  serializeValue(object: DomainObject): string | null | undefined {
    return this.getValue(object)
  }

  async init(): Promise<void> {
    return undefined
  }
}

export class InfoFormFieldViewState extends FormFieldViewState {
  readonly value: string | null | undefined

  constructor(parameters: FormFieldViewStateParameters & {
    readonly value: string | null | undefined
  }) {
    super(parameters)
    this.value = parameters.value
  }

  hasValue(): boolean {
    return isPresent(this.value)
  }
}
