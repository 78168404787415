import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import CarrierUsersI18 from "../../i18n/CarrierUsersI18"
import User from "../../../../core/domain/users/User"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import GetCarriersForCarrierUsersUseCase from "../../domain/use-cases/carriers/GetCarriersForCarrierUsersUseCase"
import UsersFilter from "../../../../core/domain/users/UsersFilter"
import isBlank from "../../../../../admin/lib/isBlank"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GetUserTypeUseCase from "../../../../../admin/core/domain/use-cases/user-profile/GetUserTypeUseCase"
import { UserType } from "../../../../../admin/core/domain/entities/user-profile/UserType"

export default class CarrierUsersTableProvider implements TableProvider<User, UsersFilter> {
  private readonly usersI18: CarrierUsersI18
  private readonly getCarriersUseCase: GetCarriersForCarrierUsersUseCase
  private readonly getUserTypeUseCase: GetUserTypeUseCase

  constructor(parameters: {
    readonly usersI18: CarrierUsersI18
    readonly getCarriersUseCase: GetCarriersForCarrierUsersUseCase
    readonly getUserTypeUseCase: GetUserTypeUseCase
  }) {
    this.usersI18 = parameters.usersI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getUserTypeUseCase = parameters.getUserTypeUseCase
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getEntity(): string {
    return Entity.CARRIER_USERS
  }

  getTable(): Table<User, UsersFilter> {
    const appUrlProvider = new AppUrlProvider()
    const usersTextProvider = this.usersI18.getTextProvider()

    return new Table<User, UsersFilter>({
      title: this.getTitle(),
      getObjectId: (user: User) => user.id!.toString(),
      getRowUrl: (user: User) => appUrlProvider.buildShowCarrierUserUrl({
        userId: user.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewCarrierUserUrl(),
      columns: [
        new TableColumn<User>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: usersTextProvider.carrierField(),
          createValue: (user: User) => new TextTableValue({
            value: user.carrierAppAdministrator?.carrier?.name,
            url: (() => {
              const { carrierAppAdministrator } = user
              const carrierId = carrierAppAdministrator?.carrierId
              if (isBlank(carrierId)) return null

              return appUrlProvider.buildShowCarrierUrl({
                carrierId: carrierId
              })
            })()
          })
        }),
        new TableColumn<User>({
          name: "lastName",
          title: usersTextProvider.lastNameField(),
          createValue: (user: User) => new TextTableValue({
            value: user.profile?.lastName
          })
        }),
        new TableColumn<User>({
          name: "firstName",
          title: usersTextProvider.firstNameField(),
          createValue: (user: User) => new TextTableValue({
            value: user.profile?.firstName
          })
        }),
        new TableColumn<User>({
          name: "middleName",
          title: usersTextProvider.middleNameField(),
          createValue: (user: User) => new TextTableValue({
            value: user.profile?.middleName
          })
        }),
        new TableColumn<User>({
          name: "emailAddress",
          title: usersTextProvider.emailField(),
          createValue: (user: User) => new TextTableValue({
            value: user.emailAddress
          })
        }),
        new TableColumn<User>({
          name: "blocked",
          title: usersTextProvider.statusField(),
          isSortingAvailable: true,
          reversedSortOrder: true,
          createValue: (user: User) => {
            const { blocked = false } = user

            return new TextTableValue({
              value: (blocked ?? false) ?
                usersTextProvider.statusValueBlocked() :
                usersTextProvider.statusValueActive(),
              isActive: !user.blocked
            })
          }
        }),
        new TableColumn<User>({
          name: "isOwner",
          title: usersTextProvider.ownerField(),
          createValue: (user: User) => {
            const isOwner = user.carrierAppAdministrator?.isOwner ?? false

            return new TextTableValue({
              value: (isOwner) ? usersTextProvider.ownerValuePositive() : usersTextProvider.ownerValueNegative(),
              isActive: isOwner
            })
          }
        })
      ],
      filter: new Filter<UsersFilter>({
        buildFilterObject: (): UsersFilter => ({}),
        fields: [
          new SingleSelectFormField<UsersFilter, void, Carrier>({
            title: usersTextProvider.carrierField(),
            entity: Entity.CARRIERS,
            groupName: "",
            getId: () => "carrierId",
            getValue: (filter: UsersFilter) => filter.carrier,
            setValue: (filter: UsersFilter, carrier: Carrier | null): UsersFilter => ({
              ...filter,
              carrier,
              carrierId: carrier?.id
            }),
            getObjectsUseCase: this.getCarriersUseCase,
            getOptionId: (carrier: Carrier) => carrier.id!.toString(),
            getOptionText: (carrier: Carrier) => carrier.name
          })
        ]
      })
    })
  }

  private getTitle() {
    const textProvider = this.usersI18.getTextProvider()
    const userType = this.getUserTypeUseCase.call()
    const isCarrierAppAdministrator = userType === UserType.CARRIER_APP_ADMINISTRATOR
    return isCarrierAppAdministrator ? textProvider.carrierAdministratorListTitle() : textProvider.defaultListTitle()
  }
}
