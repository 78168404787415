import UserProfileRepository from "../../repositories/UserProfileRepository"

export default class GetUserTypeUseCase {
  private readonly userProfileRepository: UserProfileRepository

  constructor(parameters: {
    readonly userProfileRepository: UserProfileRepository
  }) {
    this.userProfileRepository = parameters.userProfileRepository
  }

  call(): string {
    return this.userProfileRepository.getUserType()
  }
}
