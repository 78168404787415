import CheckPermissionDeniedUseCase from "../../../../core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import { RestrictionType } from "../../../../core/domain/entities/user-profile/RestrictionType"
import FormProvider from "./FormProvider"
import Form from "../entities/forms/Form"

export default class FormProviderUtils<DomainObject, DomainError=never, ErrorsObject = never> {
  private readonly formProvider: FormProvider<DomainObject, DomainError, ErrorsObject>
  private readonly form: Form<DomainObject, ErrorsObject>
  private readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
  private readonly isNewObject: boolean
  private readonly object: DomainObject

  constructor(parameters: {
    readonly formProvider: FormProvider<DomainObject, DomainError, ErrorsObject>
    readonly form: Form<DomainObject, ErrorsObject>
    readonly isNewObject: boolean
    readonly object: DomainObject
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
  }) {
    this.formProvider = parameters.formProvider
    this.form = parameters.form
    this.isNewObject = parameters.isNewObject
    this.object = parameters.object
    this.checkPermissionDeniedUseCase = parameters.checkPermissionDeniedUseCase
  }

  isFormVisibleByPermission() {
    if (this.isNewObject) {
      return !this.isPermissionDenied({ restrictionType: RestrictionType.DENY_ENTITY_CREATE })
    } else {
      return !this.isPermissionDenied({ restrictionType: RestrictionType.DENY_ENTITY_EDIT })
    }
  }

  initFormByPermissions() {
    this.initFormFields()
  }

  private initFormFields() {
    const formFields = this.form.getFormFields()
    formFields.forEach((formField) => {
      formField.setVisibilityByPermission(this.getFormFieldVisibility(formField.getId(this.object)))
      formField.setDisableByPermission(this.getFormFieldDisabled(formField.getId(this.object)))
    })
  }

  private getFormFieldVisibility(formFieldId: string) {
    return !this.isPermissionDenied({
      entityField: formFieldId,
      restrictionType: RestrictionType.DENY_ENTITY_FIELD_DISPLAY
    })
  }

  private getFormFieldDisabled(formFieldId: string) {
    return this.isPermissionDenied({
      entityField: formFieldId,
      restrictionType: RestrictionType.DENY_ENTITY_FIELD_EDIT
    })
  }

  private isPermissionDenied(parameters: { entityField?: string, restrictionType: RestrictionType }) {
    return this.checkPermissionDeniedUseCase.call({
      entity: this.formProvider.getEntity(),
      entityField: parameters.entityField,
      restrictionType: parameters.restrictionType
    })
  }
}
