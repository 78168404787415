import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import BooleanFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/BooleanFormField"
import TransportsI18 from "../../i18n/TransportsI18"
import GetCarriersForTransportUseCase from "../../domain/use-cases/carriers/GetCarriersForTransportUseCase"
import Transport from "../../../../core/domain/transport/Transport"
import TransportError from "../../../../core/domain/transport/TransportError"
import TransportErrorsObject from "../../../../core/domain/transport/TransportErrorsObject"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import ListFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/ListFormField"
import TransportFuel from "../../../../core/domain/transport-fuel/TransportFuel"
import TransportFuelErrorsObject from "../../../../core/domain/transport-fuel/TransportFuelErrorsObject"
import GetFuelsForTransportsUseCase from "../../domain/use-cases/fuels/GetFuelsForTransportsUseCase"
import Fuel from "../../../../core/domain/fuels/Fuel"
import NumberFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/NumberFormField"
import { v4 as uuidv4 } from "uuid"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"

export default class TransportFormProvider
  implements FormProvider<Transport, TransportError, TransportErrorsObject> {
  private readonly transportsI18: TransportsI18
  private readonly getCarriersUseCase: GetCarriersForTransportUseCase
  private readonly getFuelsUseCase: GetFuelsForTransportsUseCase

  constructor(parameters: {
    readonly transportsI18: TransportsI18
    readonly getCarriersUseCase: GetCarriersForTransportUseCase
    readonly getFuelsUseCase: GetFuelsForTransportsUseCase
  }) {
    this.transportsI18 = parameters.transportsI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getFuelsUseCase = parameters.getFuelsUseCase
  }

  getEntity(): string {
    return Entity.TRANSPORTS
  }

  getNewObjectTitle(): string {
    return this.transportsI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: Transport
  }): string {
    if (isBlank(object)) {
      return this.transportsI18.getTextProvider().existObjectTitle()
    }

    const { name } = object

    return isPresent(name) ? name : this.transportsI18.getTextProvider().existObjectTitle()
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: Transport
  }): string {
    if (isBlank(object)) {
      return this.transportsI18.getTextProvider().existObjectTitle()
    }

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.transportsI18.getTextProvider()
        .existObjectTitle()
  }

  async buildObject(): Promise<Transport> {
    return {}
  }

  getErrorsObject({ error }: { readonly error?: TransportError }): TransportErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<Transport, TransportErrorsObject>[] {
    const transportsTextProvider = this.transportsI18.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<Transport, TransportErrorsObject, Carrier>({
        title: transportsTextProvider.carrierField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getCarriersUseCase,
        getErrors: (errorsObject?: TransportErrorsObject) => errorsObject?.attributes?.carrierId,
        getId: () => "carrier",
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (transport: Transport) => transport.carrier,
        setValue: (transport: Transport, carrier: Carrier | null) => ({
          ...transport, carrier, carrierId: carrier?.id
        })
      }),
      new StringFormField<Transport, TransportErrorsObject>({
        title: transportsTextProvider.modelField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "model",
        getValue: (transport: Transport) => transport.name,
        setValue: (transport: Transport, name: string) => ({ ...transport, name }),
        getErrors: (errorsObject?: TransportErrorsObject) => errorsObject?.attributes?.name
      }),
      new StringFormField<Transport, TransportErrorsObject>({
        title: transportsTextProvider.vehicleCertificateNumberField(),
        groupName: mainGroupName,
        required: true,
        isTextAllCaps: true,
        getId: () => "vehicleCertificateNumber",
        getValue: (transport: Transport) => transport.vehicleCertificateNumber,
        setValue: (transport: Transport, vehicleCertificateNumber: string) => (
          { ...transport, vehicleCertificateNumber }
        ),
        getErrors: (errorsObject?: TransportErrorsObject) => errorsObject?.attributes?.vehicleCertificateNumber
      }),
      new StringFormField<Transport, TransportErrorsObject>({
        title: transportsTextProvider.vinNumberField(),
        groupName: mainGroupName,
        isTextAllCaps: true,
        getId: () => "vinNumber",
        getValue: (transport: Transport) => transport.vinNumber,
        setValue: (transport: Transport, vinNumber: string) => ({ ...transport, vinNumber })
      }),
      new StringFormField<Transport, TransportErrorsObject>({
        title: transportsTextProvider.stateNumberField(),
        groupName: mainGroupName,
        required: true,
        isTextAllCaps: true,
        getId: () => "stateNumber",
        getValue: (transport: Transport) => transport.stateNumber,
        setValue: (transport: Transport, stateNumber: string) => ({ ...transport, stateNumber }),
        getErrors: (errorsObject?: TransportErrorsObject) => errorsObject?.attributes?.stateNumber
      }),
      new BooleanFormField<Transport, TransportErrorsObject>({
        title: transportsTextProvider.blockedField(),
        groupName: mainGroupName,
        getIsVisible: (transport: Transport) => isPresent(transport.id),
        getId: () => "blocked",
        getValue: (transport: Transport) => transport.blocked,
        setValue: (transport: Transport, blocked: boolean | null | undefined) => ({ ...transport, blocked })
      }),
      new ListFormField<Transport, TransportErrorsObject, TransportFuel, TransportFuelErrorsObject>({
        title: transportsTextProvider.fuelTypesField(),
        required: true,
        groupName: mainGroupName,
        addObjectButton: transportsTextProvider.addTransportFuel(),
        getErrors: (errorsObject?: TransportErrorsObject) => errorsObject?.attributes?.transportFuels,
        newValue: () => ({ clientId: uuidv4() }),
        getId: () => "transportFuels",
        getValue: (transport: Transport) => transport.transportFuels,
        setValue: (transport: Transport, transportFuels: TransportFuel[]) => ({ ...transport, transportFuels }),
        getNestedObjectTitle: (
          transportFuel: TransportFuel,
          index: number
        ) => transportFuel.fuel?.name ?? transportsTextProvider.newTransportFuel({
          index: (index + 1)
        }),
        getNestedErrorsObject: (
          transportFuel: TransportFuel,
          transportErrorsObject?: TransportErrorsObject
        ): TransportFuelErrorsObject | undefined | null => {
          return transportErrorsObject?.transportFuels?.find((transportFuelErrorsObject: TransportFuelErrorsObject) => {
            return transportFuel.clientId === transportFuelErrorsObject.clientId
          })
        },
        fields: [
          new SingleSelectFormField<TransportFuel, TransportFuelErrorsObject, Fuel>({
            title: transportsTextProvider.transportFuelFuelIdField(),
            required: true,
            clearable: false,
            groupName: mainGroupName,
            getObjectsUseCase: this.getFuelsUseCase,
            getErrors: (errorsObject?: TransportFuelErrorsObject) => errorsObject?.attributes?.fuelId,
            getId: () => "fuelId",
            getOptionId: (fuel: Fuel) => fuel.id!.toString(),
            getOptionText: (fuel: Fuel) => fuel.name,
            getValue: (transportFuel: TransportFuel) => transportFuel.fuel,
            setValue: (transportFuel: TransportFuel, fuel: Fuel | null) => ({
              ...transportFuel, fuel, fuelId: fuel?.id
            })
          }),
          new NumberFormField<TransportFuel, TransportFuelErrorsObject>({
            getTitle: (transportFuel: TransportFuel) => {
              if (isBlank(transportFuel.fuel)) {
                return transportsTextProvider.transportFuelTankVolumeField()
              }

              return `${transportsTextProvider.transportFuelTankVolumeField()}, ${transportFuel.fuel.shortFormattedUom}`
            },
            required: true,
            groupName: mainGroupName,
            getId: () => "tankVolume",
            getValue: (transportFuel: TransportFuel) => transportFuel.tankVolume,
            setValue: (
              transportFuel: TransportFuel,
              tankVolume: number | null | undefined
            ) => ({ ...transportFuel, tankVolume }),
            getErrors: (errorsObject?: TransportFuelErrorsObject) => errorsObject?.attributes?.tankVolume
          })
        ]
      })
    ])
  }
}
