import CarrierBalancesI18n from "../../i18n/CarrierBalancesI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import CarrierBalancesTextProvider from "../../i18n/CarrierBalancesTextProvider"

export default interface CarrierBalancesI18nDiModule {
  provideCarrierBalancesI18n(): CarrierBalancesI18n
}

export class DefaultCarrierBalancesI18nDiModule
  extends I18nDiModule<CarrierBalancesTextProvider>
  implements CarrierBalancesI18nDiModule {

  provideCarrierBalancesI18n(): CarrierBalancesI18n {
    return this.single(
      CarrierBalancesI18n.name,
      () => new CarrierBalancesI18n(this.getI18nParameters())
    )
  }
}
