import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import CarrierCommercialTermsI18 from "../../i18n/CarrierCommercialTermsI18"
import GetFuelCompaniesForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForCarrierCommercialTermsUseCase"
import CarrierCommercialTermError from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTermError"
import CarrierCommercialTerm from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import CarrierCommercialTermErrorsObject
  from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTermErrorsObject"
import GetCarriersForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForCarrierCommercialTermsUseCase"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import GetFuelsForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/fuels/GetFuelsForCarrierCommercialTermsUseCase"
import Fuel from "../../../../core/domain/fuels/Fuel"
import MultiSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/MultiSelectFormField"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import GetGasStationsForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForCarrierCommercialTermsUseCase"
import GasStationsFilter from "../../../../core/domain/gas-stations/GasStationsFilter"
import GetPricingTypesForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/pricing-types/GetPricingTypesForCarrierCommercialTermsUseCase"
import PricingType, { PricingTypeSelectOption } from "../../../../core/domain/commercial-terms/PricingType"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import DecimalFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import AttributeError from "../../../../../admin/core/domain/entities/errors/AttributeError"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"

const carrierCommercialTermObjectType = "carrierCommercialTerm"

export default class CarrierCommercialTermFormProvider
  implements FormProvider<CarrierCommercialTerm, CarrierCommercialTermError, CarrierCommercialTermErrorsObject> {
  private readonly timeZone: string
  private readonly coreI18n: CoreI18n
  private readonly carrierCommercialTermsI18: CarrierCommercialTermsI18
  private readonly getCarriersUseCase: GetCarriersForCarrierCommercialTermsUseCase
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForCarrierCommercialTermsUseCase
  private readonly getFuelsUseCase: GetFuelsForCarrierCommercialTermsUseCase
  private readonly getGasStationsUseCase: GetGasStationsForCarrierCommercialTermsUseCase
  private readonly getPricingTypesUseCase: GetPricingTypesForCarrierCommercialTermsUseCase

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18n: CoreI18n
    readonly carrierCommercialTermsI18: CarrierCommercialTermsI18
    readonly getCarriersUseCase: GetCarriersForCarrierCommercialTermsUseCase
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForCarrierCommercialTermsUseCase
    readonly getFuelsUseCase: GetFuelsForCarrierCommercialTermsUseCase
    readonly getGasStationsUseCase: GetGasStationsForCarrierCommercialTermsUseCase
    readonly getPricingTypesUseCase: GetPricingTypesForCarrierCommercialTermsUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.coreI18n = parameters.coreI18n
    this.carrierCommercialTermsI18 = parameters.carrierCommercialTermsI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getFuelsUseCase = parameters.getFuelsUseCase
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
    this.getPricingTypesUseCase = parameters.getPricingTypesUseCase
  }

  getEntity(): string {
    return Entity.CARRIER_COMMERCIAL_TERMS
  }

  getNewObjectTitle(): string {
    return this.carrierCommercialTermsI18.getTextProvider()
      .newObjectTitle()
  }

  getExistedObjectShortTitle(_parameters: {
    readonly object?: CarrierCommercialTerm
  }): string {
    return this.carrierCommercialTermsI18.getTextProvider()
      .existObjectTitle()
  }

  getExistedObjectTitle(_parameters: {
    readonly object?: CarrierCommercialTerm
  }): string {
    return this.carrierCommercialTermsI18.getTextProvider()
      .existObjectTitle()
  }

  async buildObject(): Promise<CarrierCommercialTerm> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: CarrierCommercialTermError
  }): CarrierCommercialTermErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject>[] {
    const carrierCommercialTermsTextProvider = this.carrierCommercialTermsI18.getTextProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject, Carrier>({
        title: carrierCommercialTermsTextProvider.carrierField(),
        clearable: true,
        placeholder: coreTextProvider.unlimited(),
        groupName: mainGroupName,
        getObjectsUseCase: this.getCarriersUseCase,
        getErrors: (errorsObject?: CarrierCommercialTermErrorsObject) => errorsObject?.attributes?.carrierId,
        getId: () => "carrierId",
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.carrier,
        setValue: (carrierCommercialTerm: CarrierCommercialTerm, carrier: Carrier | null) => ({
          ...carrierCommercialTerm,
          carrier,
          carrierId: carrier?.id ?? null
        })
      }),
      new SingleSelectFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject, FuelCompany>({
        title: carrierCommercialTermsTextProvider.fuelCompanyField(),
        clearable: true,
        placeholder: coreTextProvider.unlimited(),
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelCompaniesUseCase,
        getErrors: (errorsObject?: CarrierCommercialTermErrorsObject) => errorsObject?.attributes?.fuelCompanyId,
        getId: () => "fuelCompanyId",
        getOptionId: (fuelCompany: FuelCompany) => fuelCompany.id!.toString(),
        getOptionText: (fuelCompany: FuelCompany) => fuelCompany.name,
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.fuelCompany,
        setValue: (carrierCommercialTerm: CarrierCommercialTerm, fuelCompany: FuelCompany | null) => {
          const previousFuelCompanyId = carrierCommercialTerm.fuelCompanyId
          let gasStations = carrierCommercialTerm.gasStations ?? []
          if (previousFuelCompanyId !== fuelCompany?.id) {
            gasStations = []
          }

          return {
            ...carrierCommercialTerm,
            fuelCompany,
            fuelCompanyId: fuelCompany?.id ?? null,
            gasStations,
            gasStationIds: gasStations?.map((gasStation) => gasStation.id!) ?? []
          }
        }
      }),
      new SingleSelectFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject, Fuel>({
        title: carrierCommercialTermsTextProvider.fuelField(),
        clearable: false,
        required: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelsUseCase,
        getErrors: (errorsObject?: CarrierCommercialTermErrorsObject) => errorsObject?.attributes?.fuelId,
        getId: () => "fuelId",
        getOptionId: (fuel: Fuel) => fuel.id!.toString(),
        getOptionText: (fuel: Fuel) => fuel.name,
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.fuel,
        setValue: (carrierCommercialTerm: CarrierCommercialTerm, fuel: Fuel | null) => ({
          ...carrierCommercialTerm,
          fuel,
          fuelId: fuel?.id ?? null
        })
      }),
      new MultiSelectFormField<
        CarrierCommercialTerm,
        CarrierCommercialTermErrorsObject,
        GasStation,
        GasStationsFilter
      >({
        title: carrierCommercialTermsTextProvider.gasStationsIdField(),
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getGasStationsUseCase,
        getErrors: (errorsObject?: CarrierCommercialTermErrorsObject) => errorsObject?.attributes?.gasStationIds,
        getId: () => "gasStationIds",
        getOptionId: (gasStation: GasStation) => gasStation.id!.toString(),
        getOptionText: (gasStation: GasStation) => gasStation.name,
        getOptionObjectsFilter: (carrierCommercialTerm: CarrierCommercialTerm) => ({
          fuelCompany: carrierCommercialTerm.fuelCompany,
          fuelCompanyId: carrierCommercialTerm.fuelCompanyId
        }),
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.gasStations,
        setValue: (carrierCommercialTerm: CarrierCommercialTerm, gasStations: GasStation[] | null) => {
          return {
            ...carrierCommercialTerm,
            gasStations,
            gasStationIds: gasStations?.map((gasStation) => gasStation.id!) ?? []
          }
        }
      }),
      new DateFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject>({
        title: carrierCommercialTermsTextProvider.beginDateField(),
        groupName: mainGroupName,
        placeholder: coreTextProvider.unlimited(),
        getId: () => "beginDate",
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.beginDate,
        setValue: (
          carrierCommercialTerm: CarrierCommercialTerm,
          beginDate: Date | null | undefined
        ): CarrierCommercialTerm => ({
          ...carrierCommercialTerm, beginDate
        }),
        getErrors: (errorsObject?: CarrierCommercialTermErrorsObject) => errorsObject?.attributes?.beginDate
      }),
      new DateFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject>({
        title: carrierCommercialTermsTextProvider.endDateField(),
        groupName: mainGroupName,
        placeholder: coreTextProvider.unlimited(),
        getId: () => "endDate",
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.endDate,
        setValue: (
          carrierCommercialTerm: CarrierCommercialTerm,
          endDate: Date | null | undefined
        ): CarrierCommercialTerm => ({
          ...carrierCommercialTerm, endDate
        }),
        getErrors: (errorsObject?: CarrierCommercialTermErrorsObject) => errorsObject?.attributes?.endDate
      }),
      new SingleSelectFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject, PricingTypeSelectOption>({
        title: carrierCommercialTermsTextProvider.pricingTypeField(),
        clearable: false,
        required: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getPricingTypesUseCase,
        searchingEnabled: false,
        getErrors: (
          errorsObject?: CarrierCommercialTermErrorsObject
        ) => this.generateLinksInsideAttributeErrors(errorsObject?.attributes?.pricingType),
        getId: () => "pricingType",
        getOptionId: (pricingTypeSelectOption: PricingTypeSelectOption) => pricingTypeSelectOption.id,
        getOptionText: (pricingTypeSelectOption: PricingTypeSelectOption) => pricingTypeSelectOption.text,
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.pricingTypeSelectOption,
        setValue: (
          carrierCommercialTerm: CarrierCommercialTerm,
          pricingTypeSelectOption: PricingTypeSelectOption | null
        ) => {
          const previousPricingType = carrierCommercialTerm.pricingType
          let price = carrierCommercialTerm.price
          let discountPercentage = carrierCommercialTerm.discountPercentage
          if (previousPricingType !== pricingTypeSelectOption?.id) {
            price = null
            discountPercentage = null
          }

          return {
            ...carrierCommercialTerm,
            pricingTypeSelectOption,
            price,
            discountPercentage,
            pricingType: pricingTypeSelectOption!.id as PricingType
          }
        }
      }),
      new DecimalFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject>({
        title: carrierCommercialTermsTextProvider.priceField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "price",
        getIsVisible: (
          carrierCommercialTerm: CarrierCommercialTerm
        ) => carrierCommercialTerm.pricingType === PricingType.FIXED,
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.price,
        setValue: (
          carrierCommercialTerm: CarrierCommercialTerm, price: Decimal | null | undefined
        ) => ({ ...carrierCommercialTerm, price }),
        getErrors: (
          errorsObject?: CarrierCommercialTermErrorsObject
        ) => this.generateLinksInsideAttributeErrors(errorsObject?.attributes?.price)
      }),
      new DecimalFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject>({
        title: carrierCommercialTermsTextProvider.discountPercentageField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "discountPercentage",
        getIsVisible: (
          carrierCommercialTerm: CarrierCommercialTerm
        ) => carrierCommercialTerm.pricingType === PricingType.DISCOUNT,
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.discountPercentage,
        setValue: (
          carrierCommercialTerm: CarrierCommercialTerm, discountPercentage: Decimal | null | undefined
        ) => ({ ...carrierCommercialTerm, discountPercentage }),
        getErrors: (
          errorsObject?: CarrierCommercialTermErrorsObject
        ) => this.generateLinksInsideAttributeErrors(errorsObject?.attributes?.discountPercentage)
      }),
      new StringFormField<CarrierCommercialTerm, CarrierCommercialTermErrorsObject>({
        title: carrierCommercialTermsTextProvider.documentUrlField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "documentUrl",
        getValue: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.documentUrl,
        setValue: (
          carrierCommercialTerm: CarrierCommercialTerm,
          documentUrl: string
        ) => ({ ...carrierCommercialTerm, documentUrl }),
        getErrors: (errorsObject?: CarrierCommercialTermErrorsObject) => errorsObject?.attributes?.documentUrl
      })
    ])
  }

  private generateLinksInsideAttributeErrors(
    attributeErrors: AttributeError[] | undefined | null
  ): AttributeError[] | undefined | null {
    const urlProvider = new AppUrlProvider()
    const carrierCommercialTermsTextProvider = this.carrierCommercialTermsI18.getTextProvider()

    return attributeErrors?.map((attributeError) => {
      const { objects } = attributeError
      return {
        ...attributeError,
        objects: objects && objects.map((attributeErrorObject) => {
          const linkInfo: LinkInfo | null | undefined = (() => {
            if (attributeErrorObject.type === carrierCommercialTermObjectType) {
              const url = urlProvider.buildShowCarrierCommercialTermUrl({
                carrierCommercialTermId: attributeErrorObject.id!
              })

              return {
                id: `${attributeErrorObject.id!}`,
                title: `${carrierCommercialTermsTextProvider.existObjectTitle()} ${attributeErrorObject.id!}`,
                url
              }
            }

            return undefined
          })()

          return {
            ...attributeErrorObject,
            linkInfo: linkInfo
          }
        })
      }
    })
  }
}
