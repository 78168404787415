import RefuellingOrdersI18n from "../../i18n/RefuellingOrdersI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import RefuellingOrdersTextProvider from "../../i18n/RefuellingOrdersTextProvider"

export default interface RefuellingOrdersI18nDiModule {
  provideRefuellingOrdersI18n(): RefuellingOrdersI18n
}

export class DefaultRefuellingOrdersI18nDiModule
  extends I18nDiModule<RefuellingOrdersTextProvider>
  implements RefuellingOrdersI18nDiModule {

  provideRefuellingOrdersI18n(): RefuellingOrdersI18n {
    return this.single(
      RefuellingOrdersI18n.name,
      () => new RefuellingOrdersI18n(this.getI18nParameters())
    )
  }
}
