import CarriersRepository from "../../repositories/CarriersRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"

export interface GetCarriersParameters extends GetObjectsParameters {
}

export default class GetCarriersUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersRepository: CarriersRepository

  constructor(parameters: {
    readonly carriersRepository: CarriersRepository
  }) {
    this.carriersRepository = parameters.carriersRepository
  }

  async call(parameters: GetCarriersParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersRepository.getCarriers(parameters)
  }
}
