import FuelCompanyReconciliationReportsRepository from "../../repositories/FuelCompanyReconciliationReportsRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import FuelCompanyReconciliationReport
  from "../../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReport"
import FuelCompanyReconciliationReportError
  from "../../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReportError"

export interface CreateFuelCompanyReconciliationReportParameters {
  readonly object: FuelCompanyReconciliationReport
}

export default class CreateFuelCompanyReconciliationReportUseCase
  implements CreateObjectUseCase<FuelCompanyReconciliationReport, FuelCompanyReconciliationReportError> {
  private readonly fuelCompanyReconciliationReportsRepository: FuelCompanyReconciliationReportsRepository

  constructor(parameters: {
    readonly fuelCompanyReconciliationReportsRepository: FuelCompanyReconciliationReportsRepository
  }) {
    this.fuelCompanyReconciliationReportsRepository = parameters.fuelCompanyReconciliationReportsRepository
  }

  async call(
    parameters: CreateFuelCompanyReconciliationReportParameters
  ): Promise<CreateObjectResult<FuelCompanyReconciliationReport, FuelCompanyReconciliationReportError>> {
    return await this.fuelCompanyReconciliationReportsRepository.createFuelCompanyReconciliationReport(parameters)
  }
}
