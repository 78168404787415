import ObjectFormPage from "./ObjectFormPage"
import React from "react"
import ObjectFormPresenter from "./ObjectFormPresenter"
import { Params, useNavigate, useParams } from "react-router-dom"
import useLocationContext, { LocationContext } from "../../../../../lib/presenter/useLocationContext"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { objectIdUrlPart } from "../../../../../core/presentation/constants/urlParts"

interface Props<DomainObject, DomainError extends ExecutionError, ErrorsObject> {
  readonly providePresenter: (parameters: {
    readonly objectId: string | undefined
  }) => ObjectFormPresenter<DomainObject, DomainError, ErrorsObject>
}

export default function ObjectFormPageRouteElement<DomainObject, DomainError extends ExecutionError, ErrorsObject>({
  providePresenter
}: Props<DomainObject, DomainError, ErrorsObject>) {
  const urlParams: Readonly<Params<string>> = useParams()
  const objectId: string | undefined = urlParams[objectIdUrlPart]
  const locationContext: LocationContext = useLocationContext()
  const navigate = useNavigate()
  const coreTextProvider = useCoreTextProvider()

  return (
    <ObjectFormPage
      key={locationContext.locationId}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            objectId
          })
        })
      }}
      coreTextProvider={coreTextProvider}
      navigate={navigate}
    />
  )
}
