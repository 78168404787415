import GetRefuellingOrdersUseCase from "../../domain/use-cases/refuelling-orders/GetRefuellingOrdersUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import RefuellingOrdersDataDiModule from "./RefuellingOrdersDataDiModule"
import GetRefuellingOrderUseCase from "../../domain/use-cases/refuelling-orders/GetRefuellingOrderUseCase"
import UpdateRefuellingOrderUseCase from "../../domain/use-cases/refuelling-orders/UpdateRefuellingOrderUseCase"
import GetCarriersForRefuellingOrdersUseCase
  from "../../domain/use-cases/carriers/GetCarriersForRefuellingOrdersUseCase"
import GetFuelsForRefuellingOrdersUseCase from "../../domain/use-cases/fuels/GetFuelsForRefuellingOrdersUseCase"
import GetFuelCompaniesForRefuellingOrdersUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForRefuellingOrdersUseCase"
import GetGasStationsForRefuellingOrdersUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForRefuellingOrdersUseCase"
import GetDriversForRefuellingOrdersUseCase from "../../domain/use-cases/drivers/GetDriversForRefuellingOrdersUseCase"
import GetTransportsForRefuellingOrdersUseCase
  from "../../domain/use-cases/transports/GetTransportsForRefuellingOrdersUseCase"
import GetRefuellingOrderStatusesForRefuellingOrdersUseCase
  from "../../domain/use-cases/refuelling-order-statuses/GetRefuellingOrderStatusesForRefuellingOrdersUseCase"

export default interface RefuellingOrdersDomainDiModule {
  provideGetRefuellingOrdersUseCase(): GetRefuellingOrdersUseCase

  provideGetRefuellingOrderUseCase(): GetRefuellingOrderUseCase

  provideUpdateRefuellingOrderUseCase(): UpdateRefuellingOrderUseCase

  provideGetCarriersForRefuellingOrdersUseCase(): GetCarriersForRefuellingOrdersUseCase

  provideGetFuelCompaniesForRefuellingOrdersUseCase(): GetFuelCompaniesForRefuellingOrdersUseCase

  provideGetFuelsForRefuellingOrdersUseCase(): GetFuelsForRefuellingOrdersUseCase

  provideGetGasStationsForRefuellingOrdersUseCase(): GetGasStationsForRefuellingOrdersUseCase

  provideGetDriversForRefuellingOrdersUseCase(): GetDriversForRefuellingOrdersUseCase

  provideGetTransportsForRefuellingOrdersUseCase(): GetTransportsForRefuellingOrdersUseCase

  provideGetRefuellingOrderStatusesForRefuellingOrdersUseCase(): GetRefuellingOrderStatusesForRefuellingOrdersUseCase
}

export class DefaultRefuellingOrdersDomainDiModule
  extends DiModule
  implements RefuellingOrdersDomainDiModule {
  private readonly refuellingOrdersDataDiModule: RefuellingOrdersDataDiModule

  constructor(parameters: {
    readonly refuellingOrdersDataDiModule: RefuellingOrdersDataDiModule
  }) {
    super()
    this.refuellingOrdersDataDiModule = parameters.refuellingOrdersDataDiModule
  }

  provideGetRefuellingOrdersUseCase(): GetRefuellingOrdersUseCase {
    return this.single(
      GetRefuellingOrdersUseCase.name,
      () => new GetRefuellingOrdersUseCase({
        refuellingOrdersRepository:
          this.refuellingOrdersDataDiModule.provideRefuellingOrdersRepository()
      })
    )
  }

  provideGetRefuellingOrderUseCase(): GetRefuellingOrderUseCase {
    return this.single(
      GetRefuellingOrderUseCase.name,
      () => new GetRefuellingOrderUseCase({
        refuellingOrdersRepository:
          this.refuellingOrdersDataDiModule.provideRefuellingOrdersRepository()
      })
    )
  }

  provideUpdateRefuellingOrderUseCase(): UpdateRefuellingOrderUseCase {
    return this.single(
      UpdateRefuellingOrderUseCase.name,
      () => new UpdateRefuellingOrderUseCase({
        refuellingOrdersRepository:
          this.refuellingOrdersDataDiModule.provideRefuellingOrdersRepository()
      })
    )
  }

  provideGetCarriersForRefuellingOrdersUseCase(): GetCarriersForRefuellingOrdersUseCase {
    return this.single(
      GetCarriersForRefuellingOrdersUseCase.name,
      () => new GetCarriersForRefuellingOrdersUseCase({
        carriersForCarrierCommercialTermsRepository:
          this.refuellingOrdersDataDiModule.provideCarriersForRefuellingOrdersRepository()
      })
    )
  }

  provideGetFuelCompaniesForRefuellingOrdersUseCase(): GetFuelCompaniesForRefuellingOrdersUseCase {
    return this.single(
      GetFuelCompaniesForRefuellingOrdersUseCase.name,
      () => new GetFuelCompaniesForRefuellingOrdersUseCase({
        fuelCompanyForCarrierCommercialTermsRepository:
          this.refuellingOrdersDataDiModule.provideFuelCompaniesForRefuellingOrdersRepository()
      })
    )
  }

  provideGetFuelsForRefuellingOrdersUseCase(): GetFuelsForRefuellingOrdersUseCase {
    return this.single(
      GetFuelsForRefuellingOrdersUseCase.name,
      () => new GetFuelsForRefuellingOrdersUseCase({
        fuelsForCarrierCommercialTermsRepository:
          this.refuellingOrdersDataDiModule.provideFuelsForRefuellingOrdersRepository()
      })
    )
  }

  provideGetGasStationsForRefuellingOrdersUseCase(): GetGasStationsForRefuellingOrdersUseCase {
    return this.single(
      GetGasStationsForRefuellingOrdersUseCase.name,
      () => new GetGasStationsForRefuellingOrdersUseCase({
        gasStationsForRefuellingOrdersRepository:
          this.refuellingOrdersDataDiModule.provideGasStationsForRefuellingOrdersRepository()
      })
    )
  }

  provideGetDriversForRefuellingOrdersUseCase(): GetDriversForRefuellingOrdersUseCase {
    return this.single(
      GetDriversForRefuellingOrdersUseCase.name,
      () => new GetDriversForRefuellingOrdersUseCase({
        driversForRefuellingOrdersRepository:
          this.refuellingOrdersDataDiModule.provideDriversForRefuellingOrdersRepository()
      })
    )
  }

  provideGetTransportsForRefuellingOrdersUseCase(): GetTransportsForRefuellingOrdersUseCase {
    return this.single(
      GetTransportsForRefuellingOrdersUseCase.name,
      () => new GetTransportsForRefuellingOrdersUseCase({
        transportsForRefuellingOrdersRepository:
          this.refuellingOrdersDataDiModule.provideTransportsForRefuellingOrdersRepository()
      })
    )
  }

  provideGetRefuellingOrderStatusesForRefuellingOrdersUseCase(): GetRefuellingOrderStatusesForRefuellingOrdersUseCase {
    return this.single(
      GetRefuellingOrderStatusesForRefuellingOrdersUseCase.name,
      () => new GetRefuellingOrderStatusesForRefuellingOrdersUseCase({
        refuellingOrderStatusesForRefuellingOrdersRepository:
          this.refuellingOrdersDataDiModule.provideRefuellingOrderStatusesForRefuellingOrdersRepository()
      })
    )
  }
}
