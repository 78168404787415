import CarrierUsersRepository from "../../repositories/CarrierUsersRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import UserError from "../../../../../core/domain/users/UserError"

export interface CreateCarrierUserParameters {
  readonly object: User
}

export default class CreateCarrierUserUseCase implements CreateObjectUseCase<User, UserError> {
  private readonly carrierUsersRepository: CarrierUsersRepository

  constructor(parameters: {
    readonly carrierUsersRepository: CarrierUsersRepository
  }) {
    this.carrierUsersRepository = parameters.carrierUsersRepository
  }

  async call(parameters: CreateCarrierUserParameters): Promise<CreateObjectResult<User, UserError>> {
    return await this.carrierUsersRepository.createUser(parameters)
  }
}
