import { Expose, Transform, Type } from "class-transformer"
import NetworkFuel from "../fuels/NetworkFuel"
import { Decimal } from "decimal.js"
import transformDecimal from "../../network/transformers/transformDecimal"
import NetworkTripLimitType from "../trip-limits-types/NetworkTripLimitType"

export default class NetworkTripLimit {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Number)
  readonly tripLimitTypeId?: number | null

  @Expose()
  @Type(() => String)
  readonly clientId?: string | null

  @Expose()
  @Type(() => NetworkTripLimitType)
  readonly tripLimitType?: NetworkTripLimitType | null

  @Expose()
  @Type(() => Number)
  readonly fuelId?: number | null

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuel?: NetworkFuel | null

  @Expose()
  @Transform(transformDecimal)
  readonly value?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedValue?: string | null
}
