import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import TripsRepository from "../../repositories/TripsRepository"
import Trip from "../../../../../core/domain/trips/Trip"

export interface GetTripParameters {
  readonly objectId: string
}

export default class GetTripUseCase implements GetObjectUseCase<Trip> {
  private readonly tripsRepository: TripsRepository

  constructor(parameters: {
    readonly tripsRepository: TripsRepository
  }) {
    this.tripsRepository = parameters.tripsRepository
  }

  async call(parameters: GetTripParameters): Promise<GetObjectResult<Trip>> {
    return await this.tripsRepository.getTrip(parameters)
  }
}
