import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import FuelCompanyBalanceTransaction
  from "../../../../core/domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn, {
  TableColumnAlignment
} from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import isBlank from "../../../../../admin/lib/isBlank"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import CurrentFuelCompanyBalancesI18n from "../../i18n/CurrentFuelCompanyBalancesI18n"

export default class CurrentFuelCompanyBalanceTransactionsTableProvider
  implements TableProvider<FuelCompanyBalanceTransaction> {
  protected timeZone: string
  protected readonly currentFuelCompanyBalancesI18n: CurrentFuelCompanyBalancesI18n

  constructor(parameters: {
    readonly timeZone: string
    readonly currentFuelCompanyBalancesI18n: CurrentFuelCompanyBalancesI18n
  }) {
    this.timeZone = parameters.timeZone
    this.currentFuelCompanyBalancesI18n = parameters.currentFuelCompanyBalancesI18n
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getEntity(): string {
    return Entity.CURRENT_FUEL_COMPANY_BALANCE_REPLENISHMENTS
  }

  getTable(): Table<FuelCompanyBalanceTransaction> {
    const appUrlProvider = new AppUrlProvider()
    const fuelCompanyBalancesTextProvider = this.currentFuelCompanyBalancesI18n.getTextProvider()

    return new Table<FuelCompanyBalanceTransaction>({
      title: fuelCompanyBalancesTextProvider.listTitle(),
      getObjectId: (
        fuelCompanyBalanceTransaction: FuelCompanyBalanceTransaction
      ) => fuelCompanyBalanceTransaction.id!.toString(),
      getRowUrl: () => "",
      newObjectPageUrl: "",
      columns: [
        new TableColumn<FuelCompanyBalanceTransaction>({
          name: "id",
          title: fuelCompanyBalancesTextProvider.idField(),
          createValue: (fuelCompanyBalanceTransaction: FuelCompanyBalanceTransaction) => new TextTableValue({
            value: `${fuelCompanyBalanceTransaction.id!}`
          })
        }),
        new TableColumn<FuelCompanyBalanceTransaction>({
          name: "date",
          title: fuelCompanyBalancesTextProvider.dateField(),
          createValue: (fuelCompanyBalanceTransaction: FuelCompanyBalanceTransaction) => new DateTableValue({
            value: fuelCompanyBalanceTransaction.date,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        }),
        new TableColumn<FuelCompanyBalanceTransaction>({
          name: "value",
          title: fuelCompanyBalancesTextProvider.valueField(),
          columnAlignment: TableColumnAlignment.RIGHT,
          createValue: (fuelCompanyBalanceTransaction: FuelCompanyBalanceTransaction) => new TextTableValue({
            value: withRubbleSymbol(fuelCompanyBalanceTransaction.formattedValue)
          })
        }),
        new TableColumn<FuelCompanyBalanceTransaction>({
          name: "reason",
          entity: Entity.REFUELLING_ORDERS,
          title: fuelCompanyBalancesTextProvider.reasonField(),
          createValue: (fuelCompanyBalanceTransaction: FuelCompanyBalanceTransaction) => new TextTableValue({
            value: fuelCompanyBalancesTextProvider.refuellingReasonValue({
              refuellingOrderCode: fuelCompanyBalanceTransaction.fuelTransaction?.refuellingOrder?.code
            }),
            url: (() => {
              const refuellingOrderId = fuelCompanyBalanceTransaction.fuelTransaction?.refuellingOrder?.id
              if (isBlank(refuellingOrderId)) return undefined

              return appUrlProvider.buildShowRefuellingOrderUrl({
                refuellingOrderId
              })
            })()
          })
        })
      ]
    })
  }
}
