import React from "react"
import styles from "./StringInfoRowComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import { Link } from "react-router-dom"
import isPresent from "../../../../../../lib/isPresent"

export default function StringInfoRowComponent({
  value,
  additionalValue,
  url,
  canShowUrl,
  openInNewTab
}: {
  readonly value?: string | null
  readonly additionalValue?: string | null
  readonly url?: string | null
  readonly canShowUrl?: boolean
  readonly openInNewTab: boolean
}) {
  const theme = useCoreThemeProvider()
  const needShowUrl = isPresent(url) && canShowUrl === true
  return (
    <div className={styles.root}>
      <div className={styles.valueContainer}>
        {needShowUrl && (
          <Link
            to={url}
            target={openInNewTab ? "_blank" : undefined}
            className={`${styles.value} ${theme.body2PrimaryClassName()}`}
          >
            { value }
          </Link>
        )}
        {!needShowUrl && (
          <div className={`${styles.value} ${theme.body2PrimaryClassName()}`}>
            {value}
          </div>
        )}
        {additionalValue && (
          <div className={`${styles.additionalValue} ${theme.body4SecondaryClassName()}`}>
            { additionalValue }
          </div>
        )}
      </div>
    </div>
  )
}
