import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkCarrierCommercialTerm from "../NetworkCarrierCommercialTerm"

export default class NetworkCarrierCommercialTermsResponseBody {

  @Expose()
  @Type(() => NetworkCarrierCommercialTerm)
  readonly carrierCommercialTerms?: NetworkCarrierCommercialTerm[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
