import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import CarrierReconciliationReportsI18n from "../../i18n/CarrierReconciliationReportsI18n"
import GetCarriersForCarrierReconciliationReportsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForCarrierReconciliationReportsUseCase"
import Carrier from "../../../../core/domain/carriers/Carrier"
import CarrierReconciliationReport
  from "../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReport"
import CarrierReconciliationReportError
  from "../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReportError"
import CarrierReconciliationReportErrorsObject
  from "../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReportErrorsObject"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import GetUserTypeUseCase from "../../../../../admin/core/domain/use-cases/user-profile/GetUserTypeUseCase"

const mainGroupName = "main"

export default class CarrierReconciliationReportsFormProvider
  implements FormProvider<
    CarrierReconciliationReport, CarrierReconciliationReportError, CarrierReconciliationReportErrorsObject
  > {
  private readonly carrierReconciliationReportsI18n: CarrierReconciliationReportsI18n
  private readonly getCarriersUseCase: GetCarriersForCarrierReconciliationReportsUseCase
  private readonly getUserTypeUseCase: GetUserTypeUseCase

  constructor(parameters: {
    readonly carrierReconciliationReportsI18: CarrierReconciliationReportsI18n
    readonly getCarriersUseCase: GetCarriersForCarrierReconciliationReportsUseCase
    readonly getUserTypeUseCase: GetUserTypeUseCase
  }) {
    this.carrierReconciliationReportsI18n = parameters.carrierReconciliationReportsI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getUserTypeUseCase = parameters.getUserTypeUseCase
  }

  getEntity(): string {
    return Entity.CARRIER_RECONCILIATION_REPORTS
  }

  getNewObjectTitle(): string {
    const userType = this.getUserTypeUseCase.call()
    return this.carrierReconciliationReportsI18n.getTextProvider().newObjectTitle({
      userType
    })
  }

  getExistedObjectTitle(): string {
    return ""
  }

  getExistedObjectShortTitle(): string {
    return ""
  }

  async buildObject(): Promise<CarrierReconciliationReport> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: CarrierReconciliationReportError
  }): CarrierReconciliationReportErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<CarrierReconciliationReport, CarrierReconciliationReportErrorsObject>[] {
    const carrierReconciliationReportsTextProvider = this.carrierReconciliationReportsI18n.getTextProvider()

    return [
      new DateFormField<CarrierReconciliationReport, CarrierReconciliationReportErrorsObject>({
        title: carrierReconciliationReportsTextProvider.fromDateField(),
        clearable: true,
        groupName: mainGroupName,
        required: true,
        getId: () => "fromDate",
        getValue: (report: CarrierReconciliationReport) => report.fromDate,
        setValue: (
          report: CarrierReconciliationReport,
          fromDate: Date | null | undefined
        ): CarrierReconciliationReport => ({
          ...report, fromDate
        }),
        getErrors: (errorsObject?: CarrierReconciliationReportErrorsObject) => errorsObject?.attributes?.fromDate
      }),
      new DateFormField<CarrierReconciliationReport, CarrierReconciliationReportErrorsObject>({
        title: carrierReconciliationReportsTextProvider.toDateField(),
        clearable: true,
        groupName: mainGroupName,
        required: true,
        getId: () => "toDate",
        getValue: (report: CarrierReconciliationReport) => report.toDate,
        setValue: (
          report: CarrierReconciliationReport,
          toDate: Date | null | undefined
        ): CarrierReconciliationReport => ({
          ...report, toDate
        }),
        getErrors: (errorsObject?: CarrierReconciliationReportErrorsObject) => errorsObject?.attributes?.toDate
      }),
      new SingleSelectFormField<CarrierReconciliationReport, CarrierReconciliationReportErrorsObject, Carrier>({
        title: carrierReconciliationReportsTextProvider.carrierField(),
        groupName: mainGroupName,
        required: true,
        getObjectsUseCase: this.getCarriersUseCase,
        getId: () => "carrier",
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (report: CarrierReconciliationReport) => report.carrier,
        setValue: (report: CarrierReconciliationReport, carrier: Carrier | null) => {
          return {
            ...report,
            carrier,
            carrierId: carrier?.id
          }
        },
        getErrors: (errorsObject?: CarrierReconciliationReportErrorsObject) => errorsObject?.attributes?.carrierId
      })
    ]
  }
}
