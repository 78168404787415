import SessionsRepository from "../../domain/repositories/SessionsRepository"
import SessionLocalSource from "../sources/sessions/SessionLocalSource"
import CreateSessionUserPartial from "../../domain/entities/session/CreateSessionUserPartial"
import { CreateSessionResult } from "../../domain/use-cases/sessions/CreateSessionUseCase"
import SessionsNetworkSource, { CreateSessionNetworkResult } from "../sources/sessions/SessionsNetworkSource"
import SessionsMapper from "../mappers/sessions/SessionsMapper"
import CreateSessionErrorsMapper from "../mappers/sessions/CreateSessionErrorsMapper"
import assertNever from "../../../lib/assertNever"

export default class DefaultSessionsRepository implements SessionsRepository {
  private readonly sessionLocalSource: SessionLocalSource
  private readonly sessionNetworkSource: SessionsNetworkSource

  constructor(parameters: {
    readonly sessionLocalSource: SessionLocalSource
    readonly sessionNetworkSource: SessionsNetworkSource
  }) {
    this.sessionLocalSource = parameters.sessionLocalSource
    this.sessionNetworkSource = parameters.sessionNetworkSource
  }

  isSessionExist(): boolean {
    return this.sessionLocalSource.isSessionExist()
  }

  async createSession({
    user
  }: {
    readonly user: CreateSessionUserPartial
  }): Promise<CreateSessionResult> {
    const result: CreateSessionNetworkResult = await this.sessionNetworkSource.createSession({ user })

    switch (result.type) {
      case "success": {
        this.sessionLocalSource.saveSession({
          session: new SessionsMapper().mapNetworkToLocal({
            object: result.data.session!
          })
        })

        return {
          type: "success",
          data: undefined
        }
      }
      case "error":
        return {
          type: "error",
          error: new CreateSessionErrorsMapper().mapNetworkToDomain({
            object: result.error
          })
        }
      case "failure":
        return result
      default:
        assertNever(result)
    }
  }

  destroySession() {
    this.sessionLocalSource.deleteSession()
  }
}
