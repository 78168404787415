import NetworkChangingLogItem from "../entities/changing-log-items/NetworkChangingLogItem"
import ChangingLogItem from "../../domain/changing-log-items/ChangingLogItem"
import UsersMapper from "./UsersMapper"
import TripsMapper from "./TripsMapper"

export default class ChangingLogItemsMapper {
  mapNetworkToDomain({
    changingLogItem
  }: {
    readonly changingLogItem: NetworkChangingLogItem
  }): ChangingLogItem {
    const {
      changedBy: user,
      parentTrip: trip
    } = changingLogItem

    return {
      id: changingLogItem.id,
      parentId: changingLogItem.parentId,
      parentType: changingLogItem.parentType,
      parentTrip: trip && new TripsMapper().mapNetworkToDomain({
        trip,
        tripStatuses: []
      }),
      createdAt: changingLogItem.createdAt,
      objectId: changingLogItem.objectId,
      objectType: changingLogItem.objectType,
      objectName: changingLogItem.objectName,
      changingType: changingLogItem.changingType,
      columnName: changingLogItem.columnName,
      oldValue: changingLogItem.oldValue,
      oldValueName: changingLogItem.oldValueName,
      newValue: changingLogItem.newValue,
      newValueName: changingLogItem.newValueName,
      valueType: changingLogItem.valueType,
      changedById: changingLogItem.changedById,
      changedBy: user && new UsersMapper().mapNetworkToDomain({ user })
    }
  }
}
