import ObjectsListPresenter from "../../presentation/pages/objects-list/ObjectsListPresenter"
import DiModule from "../../../../lib/di/DiModule"
import React from "react"
import TableProvider from "../../presentation/providers/TableProvider"
import ObjectsListPageRouteElement from "../../presentation/pages/objects-list/ObjectsListPageRouteElement"
import ObjectFormPresenter from "../../presentation/pages/object-form/ObjectFormPresenter"
import FormProvider from "../../presentation/providers/FormProvider"
import ObjectFormPageRouteElement from "../../presentation/pages/object-form/ObjectFormPageRouteElement"
import GetObjectsUseCase from "../../domain/use-cases/objects/GetObjectsUseCase"
import ExecutionError from "../../../../core/domain/entities/errors/ExecutionError"
import CreateObjectUseCase from "../../domain/use-cases/objects/CreateObjectUseCase"
import UpdateObjectUseCase from "../../domain/use-cases/objects/UpdateObjectUseCase"
import DestroyObjectUseCase from "../../domain/use-cases/objects/DestroyObjectUseCase"
import ObjectsDomainDiModule from "./ObjectsDomainDiModule"
import GetObjectUseCase from "../../domain/use-cases/objects/GetObjectUseCase"
import Sort from "../../presentation/entities/tables/Sort"
import InfoProvider from "../../presentation/providers/InfoProvider"
import ObjectInfoPageRouteElement from "../../presentation/pages/object-info/ObjectInfoPageRouteElement"
import ObjectInfoPresenter from "../../presentation/pages/object-info/ObjectInfoPresenter"
import { FilterValueByFilterId } from "../../presentation/pages/objects-list/ObjectsListView"
import CoreDomainDiModule from "../../../../core/di/modules/CoreDomainDiModule"

export default interface ObjectsPresentationDiModule {
  provideObjectsListPage<DomainObject, FilterObject = never>(parameters: {
    readonly key: string
    readonly getObjectsUseCase: GetObjectsUseCase<DomainObject>
    readonly tableProvider: TableProvider<DomainObject, FilterObject>
  }): React.ReactNode

  provideObjectInfoPage<DomainObject>(parameters: {
    readonly key: string
    readonly getObjectUseCase: GetObjectUseCase<DomainObject>
    readonly infoProvider: InfoProvider<DomainObject>
  }): React.ReactNode

  provideObjectFormPage<DomainObject, DomainError extends ExecutionError, ErrorsObject>(parameters: {
    readonly key: string
    readonly getObjectUseCase?: GetObjectUseCase<DomainObject>
    readonly createObjectUseCase?: CreateObjectUseCase<DomainObject, DomainError>
    readonly updateObjectUseCase?: UpdateObjectUseCase<DomainObject, DomainError>
    readonly destroyObjectUseCase?: DestroyObjectUseCase<DomainError>
    readonly formProvider: FormProvider<DomainObject, DomainError, ErrorsObject>
  }): React.ReactNode
}

export class DefaultObjectsPresentationDiModule
  extends DiModule
  implements ObjectsPresentationDiModule {

  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule

  constructor(parameters: {
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
  }) {
    super()
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
  }

  provideObjectsListPage<DomainObject, FilterObject = never>({
    key,
    getObjectsUseCase,
    tableProvider
  }: {
    readonly key: string
    readonly getObjectsUseCase: GetObjectsUseCase<DomainObject>
    readonly tableProvider: TableProvider<DomainObject, FilterObject>
  }): React.ReactNode {
    return (
      <ObjectsListPageRouteElement
        providePresenter={({
          sort,
          query,
          filterValueByFilterId
        }) => this.provideTablePresenter({
          key,
          getObjectsUseCase,
          tableProvider,
          sort,
          query,
          filterValueByFilterId
        })}
      />
    )
  }

  provideObjectInfoPage<DomainObject>({
    key,
    getObjectUseCase,
    infoProvider
  }: {
    readonly key: string
    readonly getObjectUseCase: GetObjectUseCase<DomainObject>
    readonly infoProvider: InfoProvider<DomainObject>
  }): React.ReactNode {
    return (
      <ObjectInfoPageRouteElement
        providePresenter={({ objectId }) => this.provideObjectInfoPresenter({
          key,
          getObjectUseCase,
          infoProvider,
          objectId
        })}
      />
    )
  }

  provideObjectFormPage<DomainObject, DomainError extends ExecutionError, ErrorsObject>({
    key,
    getObjectUseCase,
    createObjectUseCase,
    updateObjectUseCase,
    destroyObjectUseCase,
    formProvider
  }: {
    readonly key: string
    readonly getObjectUseCase: GetObjectUseCase<DomainObject>
    readonly createObjectUseCase: CreateObjectUseCase<DomainObject, DomainError>
    readonly updateObjectUseCase: UpdateObjectUseCase<DomainObject, DomainError>
    readonly destroyObjectUseCase: DestroyObjectUseCase<DomainError>
    readonly formProvider: FormProvider<DomainObject, DomainError, ErrorsObject>
  }): React.ReactNode {
    return (
      <ObjectFormPageRouteElement
        providePresenter={({ objectId }) => this.provideFormPresenter({
          key,
          getObjectUseCase,
          createObjectUseCase,
          updateObjectUseCase,
          destroyObjectUseCase,
          formProvider,
          objectId
        })}
      />
    )
  }

  protected provideTablePresenter<DomainObject, FilterObject = never>({
    key,
    getObjectsUseCase,
    tableProvider,
    sort,
    query,
    filterValueByFilterId
  }: {
    readonly key: string
    readonly getObjectsUseCase: GetObjectsUseCase<DomainObject>
    readonly tableProvider: TableProvider<DomainObject, FilterObject>
    readonly sort?: Sort
    readonly query?: string
    readonly filterValueByFilterId?: FilterValueByFilterId
  }): ObjectsListPresenter<DomainObject, FilterObject> {
    return new ObjectsListPresenter({
      subscribeToObjectsEventsUseCase:
        this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
          key
        }),
      unsubscribeFromObjectsEventsUseCase:
        this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
          key
        }),
      getObjectsUseCase,
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      tableProvider,
      sort,
      query,
      filterValueByFilterId
    })
  }

  protected provideFormPresenter<DomainObject, DomainError extends ExecutionError, ErrorsObject>({
    key,
    getObjectUseCase,
    createObjectUseCase,
    updateObjectUseCase,
    destroyObjectUseCase,
    formProvider,
    objectId
  }: {
    readonly key: string
    readonly getObjectUseCase: GetObjectUseCase<DomainObject>
    readonly createObjectUseCase?: CreateObjectUseCase<DomainObject, DomainError>
    readonly updateObjectUseCase?: UpdateObjectUseCase<DomainObject, DomainError>
    readonly destroyObjectUseCase?: DestroyObjectUseCase<DomainError>
    readonly formProvider: FormProvider<DomainObject, DomainError, ErrorsObject>
    readonly objectId: string | undefined
  }): ObjectFormPresenter<DomainObject, DomainError, ErrorsObject> {
    return new ObjectFormPresenter({
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key
      }),
      formProvider,
      getObjectUseCase,
      createObjectUseCase,
      destroyObjectUseCase,
      updateObjectUseCase,
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      objectId
    })
  }

  protected provideObjectInfoPresenter<DomainObject>({
    key,
    getObjectUseCase,
    infoProvider,
    objectId
  }: {
    readonly key: string
    readonly getObjectUseCase: GetObjectUseCase<DomainObject>
    readonly infoProvider: InfoProvider<DomainObject>
    readonly objectId: string
  }): ObjectInfoPresenter<DomainObject> {
    return new ObjectInfoPresenter({
      subscribeToObjectsEventsUseCase:
        this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
          key
        }),
      unsubscribeFromObjectsEventsUseCase:
        this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
          key
        }),
      infoProvider,
      getObjectUseCase,
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      objectId
    })
  }
}
