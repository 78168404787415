import ImLeftMenuLogo from "../resources/assets/illustrations/imLeftMenuLogo.svg"
import ImMainPageLogo from "../resources/assets/illustrations/imMainPageLogo.svg"
import RootTheme from "../../admin/features/root/theme/RootTheme"

export default class AppRootTheme implements RootTheme {

  imLeftMenuLogoPath(): string {
    return ImLeftMenuLogo
  }

  imMainPageLogoPath(): string {
    return ImMainPageLogo
  }
}
