import { Expose, Type } from "class-transformer"

export default class NetworkFuelCompanyCommercialTermsRequestFilter {
  @Expose()
  @Type(() => Number)
  readonly fuelCompanyIds?: number[] | null

  constructor(parameters: {
    readonly fuelCompanyIds?: number[] | null
  }) {
    this.fuelCompanyIds = parameters.fuelCompanyIds
  }
}
