import ObjectsListPresenter from "./ObjectsListPresenter"
import ObjectsListPage from "./ObjectsListPage"
import React from "react"
import useLocationContext, { LocationContext } from "../../../../../lib/presenter/useLocationContext"
import Sort from "../../entities/tables/Sort"
import { Location, useLocation, useSearchParams } from "react-router-dom"
import addSearchParam from "../../../../../lib/addSearchParam"
import isBlank from "../../../../../lib/isBlank"
import SortMapper from "../../mappers/SortMapper"
import { FilterValueByFilterId } from "./ObjectsListView"

const SORT_KEY = "sort"
const QUERY_KEY = "query"
const FILTER_KEY = "filter"

interface Props<DomainObject, FilterObject = never> {
  readonly providePresenter: (parameters: {
    readonly sort?: Sort,
    readonly query?: string
    readonly filterValueByFilterId?: FilterValueByFilterId
  }) => ObjectsListPresenter<DomainObject, FilterObject>
}

export default function ObjectsListPageRouteElement<DomainObject, FilterObject = never>({
  providePresenter
}: Props<DomainObject, FilterObject>) {
  const locationContext: LocationContext = useLocationContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const location: Location = useLocation()
  const sort: Sort | undefined = parseSort(searchParams.get(SORT_KEY))
  const query: string | undefined = searchParams.get(QUERY_KEY) ?? undefined
  const filterValueByFilterId: FilterValueByFilterId = parseFilterValueByFilterId(searchParams)

  function addQueryToSearchParams(query: string | undefined) {
    let newSearchParams: URLSearchParams = searchParams

    newSearchParams = addSearchParam({
      searchParams: newSearchParams,
      key: QUERY_KEY,
      value: query
    })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function addSortToSearchParams(sort: Sort | undefined) {
    let newSearchParams: URLSearchParams = searchParams

    newSearchParams = addSearchParam({
      searchParams: newSearchParams,
      key: SORT_KEY,
      value: serializeSort(sort)
    })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function addFilterValueByFilterIdToSearchParams(filterValueByFilterId: FilterValueByFilterId) {
    let newSearchParams: URLSearchParams = searchParams

    Object.entries(filterValueByFilterId)
      .forEach(([filterId, filterValue]) => {
        newSearchParams = addSearchParam({
          searchParams: newSearchParams,
          key: `${FILTER_KEY}[${filterId}]`,
          value: filterValue
        })
      })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function parseSort(jsonString: string | null): Sort | undefined {
    if (isBlank(jsonString)) {
      return undefined
    }

    return new SortMapper().mapBrowserSortToDomain({
      browserSort: jsonString
    })
  }

  function parseFilterValueByFilterId(searchParams: URLSearchParams): FilterValueByFilterId {
    const filterValueByFilterId: FilterValueByFilterId = {}
    const filterIdRegExp = new RegExp(/^filter\[(.*)\]$/)

    // TODO: universal parsing of url parameters into hash with nested objects and arrays.
    searchParams.forEach((value: string, key: string) => {
      const match: RegExpMatchArray | null = key.match(filterIdRegExp)

      if (match === null) {
        return
      }

      const filterId: string = match[1]

      filterValueByFilterId[filterId] = value
    })

    return filterValueByFilterId
  }

  function serializeSort(sort: Sort | undefined) {
    return new SortMapper().mapDomainSortToBrowser({
      sort: sort
    })
  }

  function hasAppliedFilters(): boolean {
    return Object.keys(filterValueByFilterId).length > 0
  }

  return (
    <ObjectsListPage
      key={locationContext.locationId}
      cacheQuery={addQueryToSearchParams}
      cacheSort={addSortToSearchParams}
      cacheFilterValueByFilterId={addFilterValueByFilterIdToSearchParams}
      hasAppliedFilters={hasAppliedFilters}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            sort,
            query,
            filterValueByFilterId
          })
        })
      }}
    />
  )
}
