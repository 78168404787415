import React from "react"
import DiModule from "../../../../lib/di/DiModule"
import SignInPage from "../../presentation/pages/sign-in/SignInPage"
import SignInPresenter from "../../presentation/pages/sign-in/SignInPresenter"
import CoreDomainDiModule from "../../../../core/di/modules/CoreDomainDiModule"
import PasswordRecoveryRequestPage from "../../presentation/pages/password-recovery-request/PasswordRecoveryRequestPage"
import PasswordRecoveryRequestPresenter
  from "../../presentation/pages/password-recovery-request/PasswordRecoveryRequestPresenter"
import AuthenticationDomainDiModule from "./AuthenticationDomainDiModule"
import PasswordRecoveryPresenter from "../../presentation/pages/password-recovery/PasswordRecoveryPresenter"
import PasswordRecoveryPageRouteElement
  from "../../presentation/pages/password-recovery/PasswordRecoveryPageRouteElement"

export default interface AuthenticationPresentationDiModule {
  provideSignInPage(): React.ReactNode

  provideSignInPresenter(): SignInPresenter

  providePasswordRecoveryRequestPage(): React.ReactNode

  providePasswordRecoveryRequestPresenter(): PasswordRecoveryRequestPresenter

  providePasswordRecoveryPage(): React.ReactNode

  providePasswordRecoveryPresenter(parameters: { readonly token?: string | null }): PasswordRecoveryPresenter
}

export class DefaultAuthenticationPresentationDiModule
  extends DiModule
  implements AuthenticationPresentationDiModule {

  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly authenticationDomainDiModule: AuthenticationDomainDiModule

  constructor(parameters: {
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly authenticationDomainDiModule: AuthenticationDomainDiModule
  }) {
    super()
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.authenticationDomainDiModule = parameters.authenticationDomainDiModule
  }

  provideSignInPage(): React.ReactNode {
    return (
      <SignInPage
        providePresenter={() => this.provideSignInPresenter()}
      />
    )
  }

  provideSignInPresenter(): SignInPresenter {
    return new SignInPresenter({
      isSessionExistUseCase: this.coreDomainDiModule.provideIsSessionExistUseCase(),
      createSessionUseCase: this.coreDomainDiModule.provideCreateSessionUseCase()
    })
  }

  providePasswordRecoveryRequestPage(): React.ReactNode {
    return (
      <PasswordRecoveryRequestPage
        providePresenter={() => this.providePasswordRecoveryRequestPresenter()}
      />
    )
  }

  providePasswordRecoveryRequestPresenter(): PasswordRecoveryRequestPresenter {
    return new PasswordRecoveryRequestPresenter({
      isSessionExistUseCase: this.coreDomainDiModule.provideIsSessionExistUseCase(),
      requestPasswordRecoveryUseCase: this.authenticationDomainDiModule.provideRequestPasswordRecoveryUseCase()
    })
  }

  providePasswordRecoveryPage(): React.ReactNode {
    return (
      <PasswordRecoveryPageRouteElement
        providePresenter={({ token }) => this.providePasswordRecoveryPresenter({ token })}
      />
    )
  }

  providePasswordRecoveryPresenter(parameters: { readonly token?: string | null }): PasswordRecoveryPresenter {
    return new PasswordRecoveryPresenter({
      token: parameters.token,
      isSessionExistUseCase: this.coreDomainDiModule.provideIsSessionExistUseCase(),
      checkTokenUseCase: this.authenticationDomainDiModule.provideCheckTokenUseCase(),
      recoveryPasswordUseCase: this.authenticationDomainDiModule.provideRecoveryPasswordUseCase()
    })
  }
}
