import FuelCompanyUsersI18 from "../../i18n/FuelCompanyUsersI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import FuelCompanyUsersTextProvider from "../../i18n/FuelCompanyUsersTextProvider"

export default interface FuelCompanyUsersI18nDiModule {
  provideFuelCompanyUsersI18n(): FuelCompanyUsersI18
}

export class DefaultFuelCompanyUsersI18nDiModule
  extends I18nDiModule<FuelCompanyUsersTextProvider>
  implements FuelCompanyUsersI18nDiModule {

  provideFuelCompanyUsersI18n(): FuelCompanyUsersI18 {
    return this.single(
      FuelCompanyUsersI18.name,
      () => new FuelCompanyUsersI18(this.getI18nParameters())
    )
  }
}
