import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import GasStationsI18 from "../../i18n/GasStationsI18"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import isBlank from "../../../../../admin/lib/isBlank"
import GetFuelCompaniesForGasStationsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForGasStationUseCase"
import GasStationsFilter from "../../../../core/domain/gas-stations/GasStationsFilter"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class GasStationsTableProvider implements TableProvider<GasStation, GasStationsFilter> {
  private readonly coreI18n: CoreI18n
  private readonly gasStationsI18: GasStationsI18
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForGasStationsUseCase

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly gasStationsI18: GasStationsI18
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForGasStationsUseCase
  }) {
    this.coreI18n = parameters.coreI18n
    this.gasStationsI18 = parameters.gasStationsI18
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
  }

  getEntity(): string {
    return Entity.GAS_STATIONS
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<GasStation, GasStationsFilter> {
    const appUrlProvider = new AppUrlProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()
    const gasStationsTextProvider = this.gasStationsI18.getTextProvider()

    return new Table<GasStation, GasStationsFilter>({
      title: gasStationsTextProvider.listTitle(),
      getObjectId: (gasStation: GasStation) => gasStation.id!.toString(),
      getRowUrl: (gasStation: GasStation) => appUrlProvider.buildShowGasStationUrl({
        gasStationId: gasStation.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewGasStationUrl(),
      columns: [
        new TableColumn<GasStation>({
          name: "fuelCompany",
          entity: Entity.FUEL_COMPANIES,
          title: gasStationsTextProvider.fuelCompanyField(),
          createValue: (gasStation: GasStation) => new TextTableValue({
            value: gasStation.fuelCompany?.name,
            url: (() => {
              const { fuelCompany } = gasStation
              if (isBlank(fuelCompany)) return null

              return appUrlProvider.buildShowFuelCompanyUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })()
          })
        }),
        new TableColumn<GasStation>({
          name: "name",
          title: gasStationsTextProvider.nameField(),
          isSortingAvailable: true,
          createValue: (gasStation: GasStation) => new TextTableValue({
            value: gasStation.name
          })
        }),
        new TableColumn<GasStation>({
          name: "address",
          title: gasStationsTextProvider.addressField(),
          createValue: (gasStation: GasStation) => new TextTableValue({
            value: gasStation.address
          })
        }),
        new TableColumn<GasStation>({
          name: "blocked",
          title: gasStationsTextProvider.statusField(),
          isSortingAvailable: true,
          reversedSortOrder: true,
          createValue: (gasStation: GasStation) => {
            const { blocked = false } = gasStation

            return new TextTableValue({
              value: (blocked ?? false) ?
                gasStationsTextProvider.statusValueBlocked() :
                gasStationsTextProvider.statusValueActive(),
              isActive: !gasStation.blocked
            })
          }
        })
      ],
      filter: new Filter<GasStationsFilter>({
        buildFilterObject: (): GasStationsFilter => ({}),
        fields: [
          new SingleSelectFormField<GasStationsFilter, void, FuelCompany>({
            title: gasStationsTextProvider.fuelCompanyFilter(),
            entity: Entity.FUEL_COMPANIES,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "fuelCompanyId",
            getValue: (filter: GasStationsFilter) => filter.fuelCompany,
            setValue: (filter: GasStationsFilter, fuelCompany: FuelCompany | null): GasStationsFilter => ({
              ...filter,
              fuelCompany,
              fuelCompanyId: fuelCompany?.id
            }),
            getObjectsUseCase: this.getFuelCompaniesUseCase,
            getOptionId: (optionObject: FuelCompany) => optionObject.id!.toString(),
            getOptionText: (optionObject: FuelCompany) => optionObject.name
          })
        ]
      })
    })
  }
}
