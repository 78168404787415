import { Expose, Type } from "class-transformer"
import NetworkCarrier from "../../carriers/NetworkCarrier"

export default class NetworkCarrierAppAdministrator {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Boolean)
  readonly isOwner?: boolean | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null
}
