import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import {
  GetRefuellingOrderStatusesForRefuellingOrdersReportsParameters
} from "../../domain/use-cases/refuelling-order-statuses/GetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase"
import { RefuellingOrderStatusSelectOption } from "../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import RefuellingOrderStatusesStaticSource
  from "../../../../core/data/sources/static/RefuellingOrderStatusesStaticSource"
import RefuellingOrderStatusesForRefuellingOrdersReportsRepository
  from "../../domain/repositories/RefuellingOrderStatusesForRefuellingOrdersReportsRepository"

export default class DefaultRefuellingOrderStatusesForRefuellingOrdersReportsRepository
  implements RefuellingOrderStatusesForRefuellingOrdersReportsRepository {
  private readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource

  constructor(parameters: {
    readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource
  }) {
    this.refuellingOrderStatusesStaticSource = parameters.refuellingOrderStatusesStaticSource
  }

  async getRefuellingOrderStatuses({
      ids,
      query
    }: GetRefuellingOrderStatusesForRefuellingOrdersReportsParameters
  ): Promise<GetObjectsResult<RefuellingOrderStatusSelectOption>> {
    const statuses = await this.refuellingOrderStatusesStaticSource.getRefuellingOrderStatuses({
      ids, query
    })

    return {
      type: "success",
      data: {
        objects: statuses,
        page: {
          hasMore: false
        }
      }
    }
  }
}
