import NetworkFuel from "../entities/fuels/NetworkFuel"
import Fuel from "../../domain/fuels/Fuel"

export default class FuelsMapper {
  mapNetworkToDomain({
    fuel
  }: {
    readonly fuel: NetworkFuel
  }): Fuel {
    return {
      id: fuel.id,
      name: fuel.name,
      uom: fuel.uom,
      shortFormattedUom: fuel.shortFormattedUom,
      colorHex: fuel.colorHex
    }
  }

  mapDomainToNetwork({
    fuel
  }: {
    readonly fuel: Fuel
  }): NetworkFuel {
    return {
      id: fuel.id,
      name: fuel.name,
      uom: fuel.uom,
      shortFormattedUom: fuel.shortFormattedUom,
      colorHex: fuel.colorHex
    }
  }
}
