import React from "react"
import { StringFormFieldViewState, StringFormType } from "../../../entities/forms/form-field-by-type/StringFormField"
import styles from "./StringFormFieldComponent.module.scss"
import TextInputComponent, { TextInputType } from "../../../../../../lib/text-input/TextInputComponent"
import LabelComponent from "../../../../../../lib/label/LabelComponent"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"

export default function StringFormFieldComponent({
  fieldViewState,
  isDisabled = false
}: {
  readonly fieldViewState: StringFormFieldViewState
  readonly isDisabled?: boolean
}) {
  const theme = useCoreThemeProvider()

  const name = fieldViewState.getId()
  const label = fieldViewState.getTitle()
  const isRequired = fieldViewState.getRequired()
  const errors = fieldViewState.getErrors()

  function calculateTextInputType(): TextInputType {
    const type = fieldViewState.type
    switch (type) {
      case StringFormType.EMAIL:
        return TextInputType.EMAIL
      default:
        return TextInputType.TEXT
    }
  }

  return (
    <div className={styles.root}>
      {label && name && (
        <LabelComponent
          htmlFor={name}
          text={`${label}${isRequired ? " *" : ""}`}
          className={theme.label1PrimaryClassName()}
        />
      )}
      <TextInputComponent
        name={name}
        value={fieldViewState.value}
        isDisabled={isDisabled || !fieldViewState.getEditable()}
        type={calculateTextInputType()}
        isTextAllCaps={fieldViewState.isTextAllCaps}
        onChange={fieldViewState.onChange}
        className={styles.inputWrapper}
        componentStyle={theme.input1PrimaryStyle()}
        hasErrors={(errors && errors.length > 0) ?? false}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
