import CarrierBalanceTransactionsRepository from "../../repositories/CarrierBalanceTransactionsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarrierBalanceTransaction
  from "../../../../../core/domain/carrier-balance-transactions/CarrierBalanceTransaction"

export interface GetCarrierBalanceTransactionsParameters
  extends GetObjectsParameters {
  readonly carrierId: string | number
}

export default class GetCarrierBalanceTransactionsUseCase
  implements GetObjectsUseCase<CarrierBalanceTransaction> {
  private readonly carrierBalanceTransactionsRepository: CarrierBalanceTransactionsRepository

  constructor(parameters: {
    readonly carrierBalanceTransactionsRepository: CarrierBalanceTransactionsRepository
  }) {
    this.carrierBalanceTransactionsRepository = parameters.carrierBalanceTransactionsRepository
  }

  async call(
    parameters: GetCarrierBalanceTransactionsParameters
  ): Promise<GetObjectsResult<CarrierBalanceTransaction>> {
    return await this.carrierBalanceTransactionsRepository.getTransactions(parameters)
  }
}
