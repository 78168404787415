import SuccessExecutionResult from "../../../../../core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../core/domain/results/FailureExecutionResult"
import PasswordsRepository from "../../repositories/PasswordsRepository"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import PasswordRecoveryResponseBody from "../../entities/PasswordRecoveryResponseBody"

export interface RecoveryPasswordParameters {
  readonly password: string
  readonly passwordConfirmation: string
  readonly token: string
}

export default class RecoveryPasswordUseCase {
  private readonly passwordsRepository: PasswordsRepository

  constructor(parameters: {
    readonly passwordsRepository: PasswordsRepository
  }) {
    this.passwordsRepository = parameters.passwordsRepository
  }

  async call(parameters: RecoveryPasswordParameters): Promise<RecoveryPasswordResult> {
    return await this.passwordsRepository.recoveryPassword(parameters)
  }
}

export type RecoveryPasswordResult =
  SuccessExecutionResult<PasswordRecoveryResponseBody> |
  ErrorExecutionResult<ExecutionError> |
  FailureExecutionResult
