import SessionLocalSource from "../../data/sources/sessions/SessionLocalSource"
import DiModule from "../../../lib/di/DiModule"
import KeyValueStorage from "../../data/storages/KeyValueStorage"
import BrowserKeyValueStorage from "../../data/storages/BrowserKeyValueStorage"
import UserProfileLocalSource from "../../data/sources/user-profile/UserProfileLocalSource"

export default interface CoreLocalDiModule {
  provideSessionLocalSource(): SessionLocalSource

  provideUserProfileLocalSource(): UserProfileLocalSource

  provideKeyValueStorage(): KeyValueStorage
}

export class DefaultCoreLocalDiModule
  extends DiModule
  implements CoreLocalDiModule {

  private readonly localStorageKeyPrefix: string

  constructor(parameters: {
    readonly localStorageKeyPrefix: string
  }) {
    super()
    this.localStorageKeyPrefix = parameters.localStorageKeyPrefix
  }

  provideSessionLocalSource(): SessionLocalSource {
    return this.single(
      SessionLocalSource.name,
      () => new SessionLocalSource({
        keyValueStorage: this.provideKeyValueStorage()
      })
    )
  }

  provideUserProfileLocalSource(): UserProfileLocalSource {
    return this.single(
      UserProfileLocalSource.name,
      () => new UserProfileLocalSource()
    )
  }

  provideKeyValueStorage(): KeyValueStorage {
    return this.single(
      BrowserKeyValueStorage.name,
      () => new BrowserKeyValueStorage({
        keyPrefix: this.localStorageKeyPrefix
      })
    )
  }
}
