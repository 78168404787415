import React, { ReactNode } from "react"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import styles from "./ObjectContainerComponent.module.scss"

export default function ObjectContainerComponent({
  headerComponent,
  footerComponent,
  children
}: {
  readonly headerComponent?: ReactNode
  readonly footerComponent?: ReactNode
  readonly children: ReactNode
}) {
  const theme = useCoreThemeProvider()

  return (
    <div className={`${styles.root}`}>
      { headerComponent }
      <div className={`${styles.content} ${headerComponent && styles.withHeader} ${footerComponent && styles.withFooter} ${theme.objectContentClassName()}`}>
        { children }
      </div>
      { footerComponent }
    </div>
  )
}
