import CarriersRepository from "../../repositories/CarriersRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"
import CarrierError from "../../../../../core/domain/carriers/CarrierError"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"

export interface UpdateCarrierParameters {
  readonly objectId: string
  readonly object: Carrier
}

export default class UpdateCarrierUseCase implements UpdateObjectUseCase<Carrier, CarrierError> {
  private readonly carriersRepository: CarriersRepository

  constructor(parameters: {
    readonly carriersRepository: CarriersRepository
  }) {
    this.carriersRepository = parameters.carriersRepository
  }

  async call(parameters: UpdateCarrierParameters): Promise<UpdateObjectResult<Carrier, CarrierError>> {
    return await this.carriersRepository.updateCarrier(parameters)
  }
}
