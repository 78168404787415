import { Expose, Type } from "class-transformer"
import NetworkPermissionGroup from "../NetworkPermissionGroup"
import NetworkUserProfile from "../NetworkUserProfile"

export default class NetworkUserProfileResponseBody {
  @Expose()
  @Type(() => NetworkUserProfile)
  readonly userProfile?: NetworkUserProfile | null

  @Expose()
  @Type(() => NetworkPermissionGroup)
  readonly permissionGroup?: NetworkPermissionGroup | null
}
