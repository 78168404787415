import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import FuelCompanyCommercialTermsI18 from "../../i18n/FuelCompanyCommercialTermsI18"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import isBlank from "../../../../../admin/lib/isBlank"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import ArrayTableValue, {
  ArrayTableValueItem
} from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/ArrayTableValue"
import PricingType from "../../../../core/domain/commercial-terms/PricingType"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import FuelCompanyCommercialTerm from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"
import GetFuelCompaniesForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForFuelCompanyCommercialTermsUseCase"
import FuelCompanyCommercialTermsFilter
  from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTermsFilter"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GetUserTypeUseCase from "../../../../../admin/core/domain/use-cases/user-profile/GetUserTypeUseCase"
import { UserType } from "../../../../../admin/core/domain/entities/user-profile/UserType"

export default class FuelCompanyCommercialTermsTableProvider
  implements TableProvider<FuelCompanyCommercialTerm, FuelCompanyCommercialTermsFilter> {
  private readonly coreI18n: CoreI18n
  private readonly fuelCompanyCommercialTermsI18: FuelCompanyCommercialTermsI18
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForFuelCompanyCommercialTermsUseCase
  private readonly getUserTypeUseCase: GetUserTypeUseCase

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly fuelCompanyCommercialTermsI18: FuelCompanyCommercialTermsI18
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForFuelCompanyCommercialTermsUseCase
    readonly getUserTypeUseCase: GetUserTypeUseCase
  }) {
    this.coreI18n = parameters.coreI18n
    this.fuelCompanyCommercialTermsI18 = parameters.fuelCompanyCommercialTermsI18
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getUserTypeUseCase = parameters.getUserTypeUseCase
  }

  getEntity(): string {
    return Entity.FUEL_COMPANY_COMMERCIAL_TERMS
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getTable(): Table<FuelCompanyCommercialTerm, FuelCompanyCommercialTermsFilter> {
    const appUrlProvider = new AppUrlProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()
    const fuelCompanyCommercialTermsTextProvider = this.fuelCompanyCommercialTermsI18.getTextProvider()

    return new Table<FuelCompanyCommercialTerm, FuelCompanyCommercialTermsFilter>({
      title: this.getTitle(),
      getObjectId: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.id!.toString(),
      getRowUrl: (
        fuelCompanyCommercialTerm: FuelCompanyCommercialTerm
      ) => appUrlProvider.buildShowFuelCompanyCommercialTermUrl({
        fuelCompanyCommercialTermId: fuelCompanyCommercialTerm.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewFuelCompanyCommercialTermUrl(),
      columns: [
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "id",
          title: fuelCompanyCommercialTermsTextProvider.idField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new TextTableValue({
            value: `${fuelCompanyCommercialTerm.id!}`
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "fuelCompany",
          entity: Entity.FUEL_COMPANIES,
          title: fuelCompanyCommercialTermsTextProvider.fuelCompanyField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new TextTableValue({
            value: fuelCompanyCommercialTerm.fuelCompany?.name,
            url: (() => {
              const { fuelCompany } = fuelCompanyCommercialTerm
              if (isBlank(fuelCompany)) return null

              return appUrlProvider.buildShowFuelCompanyUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })()
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: fuelCompanyCommercialTermsTextProvider.carrierField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new TextTableValue({
            value: fuelCompanyCommercialTerm.carrier?.name,
            url: (() => {
              const { carrier } = fuelCompanyCommercialTerm
              if (isBlank(carrier)) return null

              return appUrlProvider.buildShowCarrierUrl({
                carrierId: carrier.id!
              })
            })()
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "fuel",
          title: fuelCompanyCommercialTermsTextProvider.fuelField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new TextTableValue({
            value: fuelCompanyCommercialTerm.fuel?.name
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "gasStationIds",
          entity: Entity.GAS_STATIONS,
          title: fuelCompanyCommercialTermsTextProvider.gasStationsIdField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new ArrayTableValue({
            values: fuelCompanyCommercialTerm.gasStations?.map((gasStation): ArrayTableValueItem => {
              return {
                key: `${gasStation.id!}`,
                text: gasStation.name,
                url: appUrlProvider.buildShowGasStationUrl({
                  gasStationId: gasStation.id!
                })
              }
            })
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "beginDate",
          title: fuelCompanyCommercialTermsTextProvider.beginDateField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new DateTableValue({
            value: fuelCompanyCommercialTerm.beginDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "endDate",
          title: fuelCompanyCommercialTermsTextProvider.endDateField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new DateTableValue({
            value: fuelCompanyCommercialTerm.endDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "pricingType",
          title: fuelCompanyCommercialTermsTextProvider.pricingTypeField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new TextTableValue({
            value: (() => {
              switch (fuelCompanyCommercialTerm.pricingType) {
                case PricingType.FIXED:
                  return fuelCompanyCommercialTermsTextProvider.pricingTypePriceValue({
                    formattedPrice: fuelCompanyCommercialTerm.formattedPrice
                  })
                case PricingType.DISCOUNT:
                  return fuelCompanyCommercialTermsTextProvider.pricingTypeDiscountPercentageValue({
                    formattedDiscountPercentage: fuelCompanyCommercialTerm.formattedDiscountPercentage
                  })
                default:
                  return undefined
              }
            })()
          })
        }),
        new TableColumn<FuelCompanyCommercialTerm>({
          name: "documentUrl",
          title: fuelCompanyCommercialTermsTextProvider.documentUrlField(),
          createValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => new TextTableValue({
            value: fuelCompanyCommercialTerm.documentUrl,
            url: fuelCompanyCommercialTerm.documentUrl,
            openInNewTab: true
          })
        })
      ],
      filter: new Filter<FuelCompanyCommercialTermsFilter>({
        buildFilterObject: (): FuelCompanyCommercialTermsFilter => ({}),
        fields: [
          new SingleSelectFormField<FuelCompanyCommercialTermsFilter, void, FuelCompany>({
            title: fuelCompanyCommercialTermsTextProvider.fuelCompanyFilter(),
            entity: Entity.FUEL_COMPANIES,
            groupName: "",
            clearable: true,
            placeholder: coreTextProvider.unlimited(),
            getId: () => "fuelCompanyId",
            getValue: (filter: FuelCompanyCommercialTermsFilter) => filter.fuelCompany,
            setValue: (
              filter: FuelCompanyCommercialTermsFilter,
              fuelCompany: FuelCompany | null
            ): FuelCompanyCommercialTermsFilter => ({
              ...filter,
              fuelCompany,
              fuelCompanyId: fuelCompany?.id
            }),
            getObjectsUseCase: this.getFuelCompaniesUseCase,
            getOptionId: (optionObject: FuelCompany) => optionObject.id!.toString(),
            getOptionText: (optionObject: FuelCompany) => optionObject.name
          })
        ]
      })
    })
  }

  private getTitle() {
    const textProvider = this.fuelCompanyCommercialTermsI18.getTextProvider()
    const userType = this.getUserTypeUseCase.call()
    const isFuelCompanyAdministrator = userType === UserType.FUEL_COMPANY_APP_ADMINISTRATOR
    return isFuelCompanyAdministrator ?
      textProvider.fuelCompanyAdministratorListTitle() :
      textProvider.defaultListTitle()
  }
}
