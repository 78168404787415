import Sort from "../entities/tables/Sort"
import { TableColumnSortingType } from "../entities/tables/TableColumnSortingType"
import isBlank from "../../../../lib/isBlank"

export default class SortMapper {
  mapDomainSortToBrowser({
    sort
  }: {
    readonly sort?: Sort
  }): string | undefined {
    return sort?.id && sort?.type ? `${sort.id}_${sort.type}` : undefined
  }

  mapBrowserSortToDomain({
    browserSort
  }: {
    readonly browserSort: string
  }): Sort | undefined {
    if (isBlank(browserSort)) return undefined

    const parts: string[] = browserSort.split("_")
    const id: string = parts[0]
    if (isBlank(id)) return undefined

    const type = parts[1] as TableColumnSortingType
    const isKnownSortingType: boolean = Object.values(TableColumnSortingType)
      .includes(type)

    if (!isKnownSortingType) return undefined

    return {
      id: id,
      type: type
    }
  }
}
