import GetMenuUseCase from "../../domain/use-cases/menus/GetMenuUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import RootDataDiModule from "./RootDataDiModule"
import SubscribeToAuthorizationErrorUseCase
  from "../../domain/use-cases/authorization-error/SubscribeToAuthorizationErrorUseCase"

export default interface RootDomainDiModule {
  provideGetMenuUseCase(): GetMenuUseCase

  provideSubscribeToAuthorizationErrorUseCase(): SubscribeToAuthorizationErrorUseCase
}

export class DefaultRootDomainDiModule extends DiModule implements RootDomainDiModule {
  private readonly rootDataDiModule: RootDataDiModule

  constructor(parameters: {
    readonly rootDataDiModule: RootDataDiModule
  }) {
    super()
    this.rootDataDiModule = parameters.rootDataDiModule
  }

  provideGetMenuUseCase(): GetMenuUseCase {
    return this.single(
      GetMenuUseCase.name,
      () => new GetMenuUseCase({
        menusRepository: this.rootDataDiModule.provideMenusRepository()
      })
    )
  }

  provideSubscribeToAuthorizationErrorUseCase(): SubscribeToAuthorizationErrorUseCase {
    return this.single(
      SubscribeToAuthorizationErrorUseCase.name,
      () => new SubscribeToAuthorizationErrorUseCase({
        authorizationErrorRepository: this.rootDataDiModule.provideAuthorizationErrorRepository()
      })
    )
  }
}

