import GasStationBreaksRepository from "../../repositories/GasStationBreaksRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStationBreaksFilter from "../../../../../core/domain/gas-station-breaks/GasStationBreaksFilter"
import GasStationBreak from "../../../../../core/domain/gas-station-breaks/GasStationBreak"

export interface GetGasStationBreaksParameters extends GetObjectsParameters<GasStationBreaksFilter> {
}

export default class GetGasStationBreaksUseCase implements GetObjectsUseCase<GasStationBreak> {
  private readonly gasStationBreaksRepository: GasStationBreaksRepository

  constructor(parameters: {
    readonly gasStationBreaksRepository: GasStationBreaksRepository
  }) {
    this.gasStationBreaksRepository = parameters.gasStationBreaksRepository
  }

  async call(parameters: GetGasStationBreaksParameters): Promise<GetObjectsResult<GasStationBreak>> {
    return await this.gasStationBreaksRepository.getGasStationBreaks(parameters)
  }
}
