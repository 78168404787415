import TransportsRepository from "../../repositories/TransportsRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import Transport from "../../../../../core/domain/transport/Transport"
import TransportError from "../../../../../core/domain/transport/TransportError"

export interface CreateTransportParameters {
  readonly object: Transport
}

export default class CreateTransportUseCase implements CreateObjectUseCase<Transport, TransportError> {
  private readonly transportsRepository: TransportsRepository

  constructor(parameters: {
    readonly transportsRepository: TransportsRepository
  }) {
    this.transportsRepository = parameters.transportsRepository
  }

  async call(parameters: CreateTransportParameters): Promise<CreateObjectResult<Transport, TransportError>> {
    return await this.transportsRepository.createTransport(parameters)
  }
}
