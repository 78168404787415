import { Expose, Type } from "class-transformer"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import InstallationInstanceType from "../../../domain/installation-instance/InstallationInstanceType"

export default class NetworkInstallationInstance {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly guid?: string | null

  @Expose()
  @Type(() => String)
  readonly type?: InstallationInstanceType | null

  @Expose()
  @Type(() => Boolean)
  readonly blocked?: boolean | null

  @Expose()
  @Type(() => Number)
  readonly gasStationId?: number | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStation?: NetworkGasStation | null
}
