import GasStationBreaksI18n from "../../i18n/GasStationBreaksI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import GasStationBreaksTextProvider from "../../i18n/GasStationBreaksTextProvider"

export default interface GasStationBreaksI18nDiModule {
  provideGasStationBreaksI18n(): GasStationBreaksI18n
}

export class DefaultGasStationBreaksI18nDiModule
  extends I18nDiModule<GasStationBreaksTextProvider>
  implements GasStationBreaksI18nDiModule {

  provideGasStationBreaksI18n(): GasStationBreaksI18n {
    return this.single(
      GasStationBreaksI18n.name,
      () => new GasStationBreaksI18n(this.getI18nParameters())
    )
  }
}
