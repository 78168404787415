import React, { createContext, useContext } from "react"
import ForbiddenTheme from "../theme/ForbiddenTheme"

export const ForbiddenThemeProviderContext: React.Context<ForbiddenTheme | null> =
  createContext<ForbiddenTheme | null>(null)

export function useForbiddenThemeProvider(): ForbiddenTheme {
  return useContext(ForbiddenThemeProviderContext)!
}

export interface ForbiddenThemeProviderProps {
  readonly children?: React.ReactNode
  readonly theme: ForbiddenTheme
}

export function ForbiddenThemeProvider({
  children,
  theme
}: ForbiddenThemeProviderProps) {
  return (
    <ForbiddenThemeProviderContext.Provider value={theme}>
      {children}
    </ForbiddenThemeProviderContext.Provider>
  )
}
