import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkInstallationInstancesRequestFilter
  from "../../entities/installation-instance/request-queries/NetworkInstallationInstancesRequestFilter"
import NetworkInstallationInstancesResponseBody
  from "../../entities/installation-instance/response-bodies/NetworkInstallationInstancesResponseBody"
import NetworkInstallationInstanceResponseBody
  from "../../entities/installation-instance/response-bodies/NetworkInstallationInstanceResponseBody"
import NetworkInstallationInstanceErrorResponseBody
  from "../../entities/installation-instance/response-bodies/errors/NetworkInstallationInstanceErrorResponseBody"
import NetworkInstallationInstance from "../../entities/installation-instance/NetworkInstallationInstance"
import NetworkInstallationInstanceRequestBody
  from "../../entities/installation-instance/request-bodies/NetworkInstallationInstanceRequestBody"
import NetworkInstallationInstancesRequestQuery
  from "../../entities/installation-instance/request-queries/NetworkInstallationInstancesRequestQuery"

const basePath = "/api/admin/installation_instances"

export default class InstallationInstancesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getInstallationInstances({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkInstallationInstancesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetInstallationInstancesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkInstallationInstancesRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkInstallationInstancesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getInstallationInstance({
    installationInstanceId
  }: {
    readonly installationInstanceId: number
  }): Promise<GetInstallationInstanceNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${installationInstanceId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkInstallationInstanceResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createInstallationInstance({
    installationInstance
  }: {
    readonly installationInstance?: NetworkInstallationInstance | null
  }): Promise<CreateInstallationInstanceNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkInstallationInstanceRequestBody({
        installationInstance
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkInstallationInstanceResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkInstallationInstanceErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateInstallationInstance({
    installationInstanceId,
    installationInstance
  }: {
    readonly installationInstanceId: number
    readonly installationInstance?: NetworkInstallationInstance | null
  }): Promise<UpdateInstallationInstanceNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${installationInstanceId}`,
      body: instanceToPlain(new NetworkInstallationInstanceRequestBody({
        installationInstance
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkInstallationInstanceResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkInstallationInstanceErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetInstallationInstancesNetworkResult =
  SuccessExecutionResult<NetworkInstallationInstancesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetInstallationInstanceNetworkResult =
  SuccessExecutionResult<NetworkInstallationInstanceResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateInstallationInstanceNetworkResult =
  SuccessExecutionResult<NetworkInstallationInstanceResponseBody> |
  ErrorExecutionResult<NetworkInstallationInstanceErrorResponseBody> |
  FailureExecutionResult

export type UpdateInstallationInstanceNetworkResult =
  SuccessExecutionResult<NetworkInstallationInstanceResponseBody> |
  ErrorExecutionResult<NetworkInstallationInstanceErrorResponseBody> |
  FailureExecutionResult
