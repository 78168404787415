import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkCarriersResponseBody from "../../entities/carriers/response-bodies/NetworkCarriersResponseBody"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkCarriersRequestQuery from "../../entities/carriers/request-queries/NetworkCarriersRequestQuery"
import NetworkCarriersRequestFilter from "../../entities/carriers/request-queries/NetworkCarriersRequestFilter"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkCarrierResponseBody from "../../entities/carriers/response-bodies/NetworkCarrierResponseBody"
import NetworkCarrierErrorResponseBody
  from "../../entities/carriers/response-bodies/errors/NetworkCarrierErrorResponseBody"
import NetworkCarrier from "../../entities/carriers/NetworkCarrier"
import NetworkCarrierRequestBody from "../../entities/carriers/request-bodies/NetworkCarrierRequestBody"

const basePath = "/api/admin/carriers"

export default class CarriersNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getCarriers({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkCarriersRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetCarriersNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkCarriersRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarriersResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getCarrier({
    carrierId
  }: {
    readonly carrierId: number
  }): Promise<GetCarrierNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${carrierId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getCurrentCarrier(): Promise<GetCarrierNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/current`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createCarrier({
    carrier
  }: {
    readonly carrier?: NetworkCarrier | null
  }): Promise<CreateCarrierNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkCarrierRequestBody({
        carrier
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkCarrierErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateCarrier({
    carrierId,
    carrier
  }: {
    readonly carrierId: number
    readonly carrier?: NetworkCarrier | null
  }): Promise<UpdateCarrierNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${carrierId}`,
      body: instanceToPlain(new NetworkCarrierRequestBody({
        carrier
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkCarrierErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCarriersNetworkResult =
  SuccessExecutionResult<NetworkCarriersResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetCarrierNetworkResult =
  SuccessExecutionResult<NetworkCarrierResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateCarrierNetworkResult =
  SuccessExecutionResult<NetworkCarrierResponseBody> |
  ErrorExecutionResult<NetworkCarrierErrorResponseBody> |
  FailureExecutionResult

export type UpdateCarrierNetworkResult =
  SuccessExecutionResult<NetworkCarrierResponseBody> |
  ErrorExecutionResult<NetworkCarrierErrorResponseBody> |
  FailureExecutionResult
