import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import TransportsRepository from "../../repositories/TransportsRepository"
import Transport from "../../../../../core/domain/transport/Transport"

export interface GetTransportParameters {
  readonly objectId: string
}

export default class GetTransportUseCase implements GetObjectUseCase<Transport> {
  private readonly transportsRepository: TransportsRepository

  constructor(parameters: {
    readonly transportsRepository: TransportsRepository
  }) {
    this.transportsRepository = parameters.transportsRepository
  }

  async call(parameters: GetTransportParameters): Promise<GetObjectResult<Transport>> {
    return await this.transportsRepository.getTransport(parameters)
  }
}
