import React from "react"
import styles from "./ErrorsContainerComponent.module.scss"
import AttributeError from "../../core/domain/entities/errors/AttributeError"
import { Link } from "react-router-dom"

export default function ErrorsContainerComponent({
  errors = [],
  errorMessageClassName = ""
}: {
  readonly errors?: AttributeError[]
  readonly errorMessageClassName?: string
}) {
  if (errors.length === 0) return <></>

  return (
    <div className={styles.root}>
      {errors.map((attributeError: AttributeError) => (
        <React.Fragment key={attributeError.message}>
          <>
            <div className={errorMessageClassName}>
              {attributeError.message}
            </div>
            {attributeError?.objects?.map((attributeErrorObject) => (
              <div className={styles.links} key={attributeErrorObject.id!}>
                { attributeErrorObject.linkInfo && (
                  <Link
                    to={attributeErrorObject.linkInfo.url}
                    target="_blank"
                    className={errorMessageClassName}
                  >
                    {attributeErrorObject.linkInfo.title}
                  </Link>
                )}
              </div>
            ))}
          </>
        </React.Fragment>

      ))}
    </div>
  )
}
