import SelectOption from "../../../../admin/lib/picker/SelectOption"

enum ChangingType {
  CREATED = "created",
  UPDATED = "updated",
  DELETED = "deleted"
}

export default ChangingType

export interface ChangingTypeSelectOption extends SelectOption { }
