import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import TripChangingLogDomainDiModule from "./TripChangingLogDomainDiModule"
import TripsChangingLogTableProvider from "../../presentation/table-providers/TripsChangingLogTableProvider"
import TripChangingLogI18nDiModule from "./TripChangingLogI18nDiModule"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"

const moduleKey = "tripChangingLog"

export default interface TripChangingLogPresentationDiModule {
  provideTripChangingLogPage(): React.ReactNode
}

export class DefaultTripChangingLogPresentationDiModule
  extends DiModule
  implements TripChangingLogPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly tripChangingLogI18nDiModule: TripChangingLogI18nDiModule
  private readonly tripChangingLogDomainDiModule: TripChangingLogDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly tripChangingLogI18nDiModule: TripChangingLogI18nDiModule
    readonly tripChangingLogDomainDiModule: TripChangingLogDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.tripChangingLogI18nDiModule = parameters.tripChangingLogI18nDiModule
    this.tripChangingLogDomainDiModule = parameters.tripChangingLogDomainDiModule
  }

  provideTripChangingLogPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.tripChangingLogDomainDiModule.provideGetChangingLogItemsUseCase(),
      tableProvider: this.provideTripChangingLogTableProvider()
    })
  }

  private provideTripChangingLogTableProvider(): TripsChangingLogTableProvider {
    return this.single(
      TripsChangingLogTableProvider.name,
      () => new TripsChangingLogTableProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        appI18: this.appI18nDiModule.provideAppI18n(),
        tripChangingLogI18: this.tripChangingLogI18nDiModule.provideTripChangingLogI18n(),
        getCarriersUseCase: this.tripChangingLogDomainDiModule.provideGetCarriersForTripChangingLogUseCase(),
        getTripsUseCase: this.tripChangingLogDomainDiModule.provideGetTripsForTripChangingLogUseCase(),
        getChangingTypesUseCase: this.tripChangingLogDomainDiModule.provideGetChangingTypesForTripChangingLogUseCase()
      })
    )
  }
}
