import CarrierCommercialTermsI18 from "../../i18n/CarrierCommercialTermsI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import CarrierCommercialTermsTextProvider from "../../i18n/CarrierCommercialTermsTextProvider"

export default interface CarrierCommercialTermsI18nDiModule {
  provideCarrierCommercialTermsI18n(): CarrierCommercialTermsI18
}

export class DefaultCarrierCommercialTermsI18nDiModule
  extends I18nDiModule<CarrierCommercialTermsTextProvider>
  implements CarrierCommercialTermsI18nDiModule {

  provideCarrierCommercialTermsI18n(): CarrierCommercialTermsI18 {
    return this.single(
      CarrierCommercialTermsI18.name,
      () => new CarrierCommercialTermsI18(this.getI18nParameters())
    )
  }
}
