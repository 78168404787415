import Menu from "../../../domain/entities/menus/Menu"
import NetworkMenu from "../../../../../app/features/root/data/entities/menus/NetworkMenu"
import MenuItemsMapper from "./MenuItemsMapper"

export default class MenuMapper {
  mapNetworkToDomain({
    menu
  }: {
    readonly menu: NetworkMenu
  }): Menu {
    const mapper = new MenuItemsMapper()
    const { menuItems } = menu
    return {
      menuItems: menuItems && menuItems.map((menuItem) => {
        return mapper.mapNetworkToDomain({
          menuItem
        })
      })
    }
  }
}
