import NetworkCarrier from "../entities/carriers/NetworkCarrier"
import Carrier from "../../domain/carriers/Carrier"
import LegalEntitiesMapper from "./LegalEntitiesMapper"
import ContactPeopleMapper from "./ContactPeopleMapper"
import CarrierBalancesMapper from "./CarrierBalancesMapper"

export default class CarriersMapper {
  mapNetworkToDomain({
    carrier
  }: {
    readonly carrier: NetworkCarrier
  }): Carrier {
    const {
      legalEntity,
      contactPerson,
      balance: carrierBalance
    } = carrier

    return {
      id: carrier.id,
      name: carrier.name,
      code: carrier.code,
      blocked: carrier.blocked,
      legalEntity: legalEntity && new LegalEntitiesMapper().mapNetworkToDomain({ legalEntity }),
      contactPerson: contactPerson && new ContactPeopleMapper().mapNetworkToDomain({ contactPerson }),
      balance: carrierBalance && new CarrierBalancesMapper().mapNetworkToDomain({ carrierBalance })
    }
  }

  mapDomainToNetwork({
    carrier
  }: {
    readonly carrier: Carrier
  }): NetworkCarrier {
    const { legalEntity, contactPerson } = carrier
    return {
      name: carrier.name,
      code: carrier.code,
      blocked: carrier.blocked,
      legalEntity: legalEntity && new LegalEntitiesMapper().mapDomainToNetwork({ legalEntity }),
      contactPerson: contactPerson && new ContactPeopleMapper().mapDomainToNetwork({ contactPerson })
    }
  }
}
