import { Expose, Type } from "class-transformer"
import NetworkCarrier from "../NetworkCarrier"

export default class NetworkCarrierRequestBody {

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null

  constructor(parameters: {
    readonly carrier?: NetworkCarrier | null
  }) {
    this.carrier = parameters.carrier
  }
}
