import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import isBlank from "../../../../../admin/lib/isBlank"
import TripsI18 from "../../i18n/TripsI18"
import GetCarriersForTripsUseCase from "../../domain/use-cases/carriers/GetCarriersForTripsUseCase"
import Transport from "../../../../core/domain/transport/Transport"
import Trip from "../../../../core/domain/trips/Trip"
import TripError from "../../../../core/domain/trips/TripError"
import TripErrorsObject from "../../../../core/domain/trips/TripErrorsObject"
import GetDriversForTripsUseCase from "../../domain/use-cases/drivers/GetDriversForTripsUseCase"
import GetTransportsForTripsUseCase from "../../domain/use-cases/transports/GetTransportsForTripsUseCase"
import GetTripStatusesForTripsUseCase from "../../domain/use-cases/trip-statuses/GetTripStatusesForTripsUseCase"
import GetTripLimitTypesForTripLimitsUseCase
  from "../../domain/use-cases/trip-limits/GetTripLimitTypesForTripLimitsUseCase"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import TripStatus, { TripStatusSelectOption } from "../../../../core/domain/trips/TripStatus"
import isPresent from "../../../../../admin/lib/isPresent"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import TransportsFilter from "../../../../core/domain/transport/TransportsFilter"
import MultiSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/MultiSelectFormField"
import DriversFilter from "../../../../core/domain/drivers/DriversFilter"
import User from "../../../../core/domain/users/User"
import AppI18 from "../../../../core/i18n/AppI18"
import ListFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/ListFormField"
import TripLimit from "../../../../core/domain/trip-limits/TripLimit"
import TripLimitErrorsObject from "../../../../core/domain/trip-limits/TripLimitErrorsObject"
import { v4 as uuidv4 } from "uuid"
import Fuel from "../../../../core/domain/fuels/Fuel"
import GetFuelsForTripLimitsUseCase from "../../domain/use-cases/fuels/GetFuelsForTripLimitsUseCase"
import TripLimitTargetType from "../../../../core/domain/trip-limits/TripLimitTargetType"
import DecimalFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import TransportFuel from "../../../../core/domain/transport-fuel/TransportFuel"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import TripLimitType from "../../../../core/domain/trip-limit-type/TripLimitType"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import { FormInitResult } from "../../../../../admin/features/objects/presentation/providers/FormInitResult"

const mainGroupName = "main"

export default class TripFormProvider
  implements FormProvider<Trip, TripError, TripErrorsObject> {
  private readonly timeZone: string
  private readonly tripsI18: TripsI18
  private readonly coreI18n: CoreI18n
  private readonly appI18: AppI18
  private readonly getCarriersUseCase: GetCarriersForTripsUseCase
  private readonly getDriversUseCase: GetDriversForTripsUseCase
  private readonly getTransportsUseCase: GetTransportsForTripsUseCase
  private readonly getTripStatusesUseCase: GetTripStatusesForTripsUseCase
  private readonly getFuelsUseCase: GetFuelsForTripLimitsUseCase
  private readonly getTripLimitTypesUseCase: GetTripLimitTypesForTripLimitsUseCase
  private trip?: Trip
  private tripLimitTypes: TripLimitType[] = []

  constructor(parameters: {
    readonly timeZone: string
    readonly tripsI18: TripsI18
    readonly coreI18n: CoreI18n
    readonly appI18: AppI18
    readonly getCarriersUseCase: GetCarriersForTripsUseCase
    readonly getDriversUseCase: GetDriversForTripsUseCase
    readonly getTransportsUseCase: GetTransportsForTripsUseCase
    readonly getTripStatusesUseCase: GetTripStatusesForTripsUseCase
    readonly getFuelsUseCase: GetFuelsForTripLimitsUseCase
    readonly getTripLimitTypesUseCase: GetTripLimitTypesForTripLimitsUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.appI18 = parameters.appI18
    this.coreI18n = parameters.coreI18n
    this.tripsI18 = parameters.tripsI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getFuelsUseCase = parameters.getFuelsUseCase
    this.getDriversUseCase = parameters.getDriversUseCase
    this.getTransportsUseCase = parameters.getTransportsUseCase
    this.getTripStatusesUseCase = parameters.getTripStatusesUseCase
    this.getTripLimitTypesUseCase = parameters.getTripLimitTypesUseCase
  }

  async init(): Promise<FormInitResult> {
    const getTripLimitTargetTypesResult: GetObjectsResult<TripLimitType> = await this.getTripLimitTypesUseCase.call({})

    switch (getTripLimitTargetTypesResult.type) {
      case "success":
        this.tripLimitTypes = getTripLimitTargetTypesResult.data.objects
        return { type: "success", data: undefined }
      case "error":
        return { type: "error", error: getTripLimitTargetTypesResult.error }
      case "failure":
        return { type: "failure", exception: getTripLimitTargetTypesResult.exception }
    }
  }

  getEntity(): string {
    return Entity.TRIPS
  }

  getNewObjectTitle(): string {
    return this.tripsI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: Transport
  }): string {
    if (isBlank(object)) {
      return ""
    }

    return this.tripsI18.getTextProvider().existObjectTitle({
      id: object.id!
    })
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: Transport
  }): string {
    if (isBlank(object)) {
      return ""
    }

    return this.tripsI18.getTextProvider().existObjectTitle({
      id: object.id!
    })
  }

  async buildObject(): Promise<Trip> {
    return {}
  }

  onNewObject(trip: Trip) {
    this.trip = trip
  }

  getErrorsObject({ error }: { readonly error?: TripError }): TripErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<Trip, TripErrorsObject>[] {
    const tripsTextProvider = this.tripsI18.getTextProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()
    const appTextProvider = this.appI18.getTextProvider()

    return [
      new StringFormField<Trip, TripErrorsObject>({
        title: tripsTextProvider.nameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "name",
        getValue: (trip: Trip) => trip.name,
        setValue: (
          trip: Trip,
          name: string | null | undefined
        ) => ({ ...trip, name }),
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.name
      }),
      new SingleSelectFormField<Trip, TripErrorsObject, Carrier>({
        title: tripsTextProvider.carrierField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getCarriersUseCase,
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.carrierId,
        getId: () => "carrier",
        getIsEditable: (trip: Trip) => isBlank(trip.id),
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (trip: Trip) => trip.carrier,
        setValue: (trip: Trip, carrier: Carrier | null) => {
          const oldCarrierId = trip.carrierId
          let updatedTrip: Trip = {
            ...trip,
            carrier,
            carrierId: carrier?.id
          }
          if (oldCarrierId !== carrier?.id) {
            updatedTrip = {
              ...updatedTrip,
              transport: null,
              transportId: null,
              drivers: [],
              driverIds: []
            }
          }

          return updatedTrip
        }
      }),
      new SingleSelectFormField<Trip, TripErrorsObject, Transport, TransportsFilter>({
        title: tripsTextProvider.transportField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getTransportsUseCase,
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.transportId,
        getOptionObjectsFilter: (trip: Trip) => ({
          carrier: trip.carrier,
          carrierId: trip.carrierId,
          blocked: false
        }),
        getId: () => "transport",
        getIsEditable: (trip: Trip) => isBlank(trip.id),
        getOptionId: (transport: Transport) => transport.id!.toString(),
        getOptionText: (transport: Transport) => `${transport.name} (${transport.stateNumber})`,
        getValue: (trip: Trip) => trip.transport,
        setValue: (trip: Trip, transport: Transport | null) => ({
          ...trip,
          transport,
          transportId: transport?.id,
          limits: this.prefillTripLimitsForTransportIfNeeds({
            trip,
            transport
          })
        })
      }),
      new MultiSelectFormField<
        Trip,
        TripErrorsObject,
        User,
        DriversFilter
      >({
        title: tripsTextProvider.driversField(),
        clearable: false,
        required: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getDriversUseCase,
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.driverIds,
        getId: () => "drivers",
        getOptionId: (user: User) => user.id!.toString(),
        getOptionText: (user: User) => appTextProvider.driverName({
          user
        }),
        getOptionObjectsFilter: (trip: Trip) => ({
          carrier: trip.carrier,
          carrierId: trip.carrierId,
          blocked: false
        }),
        getValue: (trip: Trip) => trip.drivers,
        setValue: (trip: Trip, drivers: User[] | null) => {
          return {
            ...trip,
            drivers,
            driverIds: drivers?.map((user) => user.id!)
          }
        }
      }),
      new SingleSelectFormField<Trip, TripErrorsObject, TripStatusSelectOption>({
        title: tripsTextProvider.statusField(),
        clearable: false,
        getIsVisible: (trip: Trip) => isPresent(trip.id),
        groupName: mainGroupName,
        getObjectsUseCase: this.getTripStatusesUseCase,
        searchingEnabled: false,
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.status,
        getId: () => "status",
        getOptionId: (option: TripStatusSelectOption) => option.id,
        getOptionText: (option: TripStatusSelectOption) => option.text,
        getValue: (trip: Trip) => trip.statusSelectOption,
        setValue: (trip: Trip, statusSelectOption: TripStatusSelectOption | null) => ({
          ...trip,
          statusSelectOption,
          status: statusSelectOption?.id as TripStatus
        })
      }),
      new DateFormField<Trip, TripErrorsObject>({
        title: tripsTextProvider.startingAtField(),
        groupName: mainGroupName,
        required: true,
        getId: () => "startingAt",
        getValue: (trip: Trip) => trip.startingAt,
        setValue: (
          trip: Trip,
          startingAt: Date | null | undefined
        ): Trip => ({
          ...trip, startingAt
        }),
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.startingAt
      }),
      new DateFormField<Trip, TripErrorsObject>({
        title: tripsTextProvider.finishingAtField(),
        groupName: mainGroupName,
        required: false,
        getId: () => "finishingAt",
        getValue: (trip: Trip) => trip.finishingAt,
        setValue: (
          trip: Trip,
          finishingAt: Date | null | undefined
        ): Trip => ({
          ...trip, finishingAt
        }),
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.finishingAt
      }),
      new StringFormField<Trip, TripErrorsObject>({
        title: tripsTextProvider.noteField(),
        groupName: mainGroupName,
        getId: () => "note",
        getValue: (trip: Trip) => trip.note,
        setValue: (
          trip: Trip,
          note: string | null | undefined
        ) => ({ ...trip, note })
      }),
      new ListFormField<Trip, TripErrorsObject, TripLimit, TripLimitErrorsObject>({
        title: tripsTextProvider.limitsField(),
        groupName: mainGroupName,
        addObjectButton: tripsTextProvider.addLimit(),
        newValue: () => this.buildNewLimit(),
        getId: () => "limits",
        getValue: (trip: Trip) => trip.limits,
        setValue: (trip: Trip, limits: TripLimit[]) => ({ ...trip, limits }),
        getErrors: (errorsObject?: TripErrorsObject) => errorsObject?.attributes?.limits,
        getNestedObjectTitle: (
          tripLimit: TripLimit,
          index: number
        ) => {
          const { fuel } = tripLimit
          const tripLimitTypeName = tripLimit.tripLimitType?.name

          if (isBlank(fuel?.name) && isBlank(tripLimitTypeName)) {
            return tripsTextProvider.newTripLimit({
              index: (index + 1)
            })
          }

          return filterNotEmpty([
            fuel?.name,
            isPresent(fuel?.name) ? tripLimitTypeName?.toLowerCase() : tripLimitTypeName
          ]).join(", ")
        },
        getNestedErrorsObject: (
          tripLimit: TripLimit,
          tripErrorsObject?: TripErrorsObject
        ): TripLimitErrorsObject | undefined | null => {
          return tripErrorsObject?.limits?.find((tripLimitErrorsObject: TripLimitErrorsObject) => {
            return tripLimitErrorsObject.clientId === tripLimit.clientId
          })
        },
        fields: [
          new SingleSelectFormField<TripLimit, TripLimitErrorsObject, TripLimitType>({
            title: tripsTextProvider.tripLimitTargetTypeField(),
            required: true,
            clearable: false,
            groupName: "",
            searchingEnabled: false,
            getObjectsUseCase: this.getTripLimitTypesUseCase,
            getErrors: (errorsObject?: TripLimitErrorsObject) => errorsObject?.attributes?.tripLimitTypeId,
            getId: () => "tripLimitTypeId",
            getOptionId: (tripLimitType: TripLimitType) => tripLimitType.id!.toString(),
            getOptionText: (tripLimitType: TripLimitType) => tripLimitType.name,
            getValue: (tripLimit: TripLimit) => tripLimit.tripLimitType,
            setValue: (tripLimit: TripLimit, tripLimitType: TripLimitType | null) => {
              const newTripLimit: TripLimit = {
                ...tripLimit,
                tripLimitType,
                tripLimitTypeId: tripLimitType?.id,
                fuel: tripLimitType?.needsFuelTypeSelect === false ? null : tripLimit.fuel,
                fuelId: tripLimitType?.needsFuelTypeSelect === false ? null : tripLimit.fuel?.id
              }

              return this.fillEmptyValueInTripLimitIfNeed({
                tripLimit: newTripLimit,
                transportFuels: this.trip!.transport?.transportFuels ?? []
              })
            }
          }),
          new SingleSelectFormField<TripLimit, TripLimitErrorsObject, Fuel>({
            title: tripsTextProvider.tripLimitFuelField(),
            required: true,
            clearable: false,
            groupName: "",
            getObjectsUseCase: this.getFuelsUseCase,
            getPlaceholder: (tripLimit: TripLimit) => {
              const needsFuelTypeSelect = tripLimit.tripLimitType?.needsFuelTypeSelect
              const canSelect = isBlank(needsFuelTypeSelect) || needsFuelTypeSelect
              return canSelect ? coreTextProvider.select() : tripsTextProvider.disableTripLimitFuelPlaceholder()
            },
            getIsEditable: (tripLimit: TripLimit) => tripLimit.tripLimitType?.needsFuelTypeSelect ?? true,
            getErrors: (errorsObject?: TripLimitErrorsObject) => errorsObject?.attributes?.fuelId,
            getId: () => "fuelId",
            getOptionId: (fuel: Fuel) => fuel.id!.toString(),
            getOptionText: (fuel: Fuel) => fuel.name,
            getValue: (tripLimit: TripLimit) => tripLimit.fuel,
            setValue: (tripLimit: TripLimit, fuel: Fuel | null): TripLimit => {
              const newTripLimit: TripLimit = {
                ...tripLimit,
                fuel,
                fuelId: fuel?.id
              }

              return this.fillEmptyValueInTripLimitIfNeed({
                tripLimit: newTripLimit,
                transportFuels: this.trip!.transport?.transportFuels ?? []
              })
            }
          }),
          new DecimalFormField<TripLimit, TripLimitErrorsObject>({
            getTitle: (tripLimit: TripLimit) => tripsTextProvider.tripLimitValueField({ tripLimit }),
            required: true,
            groupName: "",
            getId: () => "value",
            getValue: (tripLimit: TripLimit) => tripLimit.value,
            setValue: (tripLimit: TripLimit, value: Decimal | null | undefined) => ({ ...tripLimit, value }),
            getErrors: (errorsObject?: TripLimitErrorsObject) => errorsObject?.attributes?.value
          })
        ]
      })
    ]
  }

  private prefillTripLimitsForTransportIfNeeds({
    trip,
    transport
  }: {
    readonly trip: Trip,
    readonly transport: Transport | null
  }): TripLimit[] {
    const tripLimits: TripLimit[] = trip.limits ?? []

    if (isBlank(transport)) {
      return tripLimits
    }

    const transportFuels: TransportFuel[] = transport.transportFuels ?? []

    return [
      ...this.fillEmptyValuesInRefuellingTripLimitsByTankVolume({
        tripLimits,
        transportFuels
      }),
      ...this.createNotExistedRefuellingTripLimitsForTank({
        tripLimits,
        transportFuels
      })
    ]
  }

  private fillEmptyValuesInRefuellingTripLimitsByTankVolume({
    tripLimits,
    transportFuels
  }: {
    readonly tripLimits: TripLimit[]
    readonly transportFuels: TransportFuel[]
  }): TripLimit[] {
    return tripLimits.map((tripLimit: TripLimit) => {
      return this.fillEmptyValueInTripLimitIfNeed({
        tripLimit,
        transportFuels
      })
    })
  }

  private fillEmptyValueInTripLimitIfNeed({
    tripLimit,
    transportFuels
  }: {
    readonly tripLimit: TripLimit
    readonly transportFuels: TransportFuel[]
  }): TripLimit {
    if (!this.needReplaceTripLimitValue({ tripLimit })) {
      return tripLimit
    }

    const transportFuel: TransportFuel | undefined = this
      .getValidTransportFuels({ transportFuels })
      .find((transportFuel: TransportFuel): boolean => transportFuel.fuelId === tripLimit.fuelId)

    if (isBlank(transportFuel)) {
      return tripLimit
    }

    return {
      ...tripLimit,
      value: new Decimal(transportFuel.tankVolume!)
    }
  }

  private createNotExistedRefuellingTripLimitsForTank({
    tripLimits,
    transportFuels
  }: {
    readonly tripLimits: TripLimit[]
    readonly transportFuels: TransportFuel[]
  }): TripLimit[] {
    const transportFuelsToCreateTripLimits: TransportFuel[] = this
      .getValidTransportFuels({ transportFuels })
      .filter((transportFuel: TransportFuel): boolean => {
        const existedTripLimit: TripLimit | undefined = tripLimits.find((tripLimit: TripLimit): boolean => {
          return tripLimit.fuelId === transportFuel.fuelId &&
            tripLimit.tripLimitType?.code === TripLimitTargetType.REFUELLING_BY_FUEL_TYPE
        })

        return isBlank(existedTripLimit)
      })

    const tripLimitType: TripLimitType | undefined =
      this.tripLimitTypes.find((tripLimitType: TripLimitType): boolean => {
        return tripLimitType.code === TripLimitTargetType.REFUELLING_BY_FUEL_TYPE
      })

    return transportFuelsToCreateTripLimits.map((transportFuel: TransportFuel): TripLimit => {
      return {
        ...this.buildNewLimit(),
        fuelId: transportFuel.fuelId,
        fuel: transportFuel.fuel,
        tripLimitTypeId: tripLimitType?.id,
        tripLimitType: tripLimitType,
        value: new Decimal(transportFuel.tankVolume!)
      }
    })
  }

  private getValidTransportFuels({
    transportFuels
  }: {
    readonly transportFuels: TransportFuel[]
  }): TransportFuel[] {
    return transportFuels.filter((transportFuel: TransportFuel): boolean => {
      return isPresent(transportFuel.fuelId) &&
        isPresent(transportFuel.fuel) &&
        isPresent(transportFuel.tankVolume)
    })
  }

  private needReplaceTripLimitValue({
    tripLimit
  }: {
    readonly tripLimit: TripLimit
  }): boolean {
    return tripLimit.tripLimitType?.code === TripLimitTargetType.REFUELLING_BY_FUEL_TYPE &&
      isPresent(tripLimit.fuel) &&
      isBlank(tripLimit.value)
  }

  private buildNewLimit(): TripLimit {
    return { clientId: uuidv4() }
  }
}
