import { Expose, Type } from "class-transformer"

export default class NetworkCheckTokenRequestQuery {
  @Expose()
  @Type(() => String)
  readonly token?: string | null

  constructor(parameters: {
    readonly token?: string | null
  }) {
    this.token = parameters.token
  }
}
