import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import FuelCompanyBalancesI18n from "../../i18n/FuelCompanyBalancesI18n"
import TableColumn, {
  TableColumnAlignment
} from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import FuelCompanyBalanceChangeDocument
  from "../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"

export default class FuelCompanyBalanceChangeDocumentsTableProvider
  implements TableProvider<FuelCompanyBalanceChangeDocument> {
  protected timeZone: string
  protected readonly fuelCompanyBalancesI18n: FuelCompanyBalancesI18n

  constructor(parameters: {
    readonly timeZone: string
    readonly fuelCompanyBalancesI18n: FuelCompanyBalancesI18n
  }) {
    this.timeZone = parameters.timeZone
    this.fuelCompanyBalancesI18n = parameters.fuelCompanyBalancesI18n
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getEntity(): string {
    return Entity.FUEL_COMPANY_BALANCE_WRITE_OFFS
  }

  getTable(): Table<FuelCompanyBalanceChangeDocument> {
    const fuelCompanyBalancesTextProvider = this.fuelCompanyBalancesI18n.getTextProvider()

    return new Table<FuelCompanyBalanceChangeDocument>({
      title: fuelCompanyBalancesTextProvider.listTitle(),
      getObjectId: (
        fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument
      ) => fuelCompanyBalanceChangeDocument.id!.toString(),
      getRowUrl: () => "",
      newObjectPageUrl: "",
      columns: [
        new TableColumn<FuelCompanyBalanceChangeDocument>({
          name: "id",
          title: fuelCompanyBalancesTextProvider.idField(),
          createValue: (fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) => new TextTableValue({
            value: `${fuelCompanyBalanceChangeDocument.fuelCompanyBalanceTransaction?.id}`
          })
        }),
        new TableColumn<FuelCompanyBalanceChangeDocument>({
          name: "date",
          title: fuelCompanyBalancesTextProvider.dateField(),
          createValue: (fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) => new DateTableValue({
            value: fuelCompanyBalanceChangeDocument.fuelCompanyBalanceTransaction?.createdAt,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        }),
        new TableColumn<FuelCompanyBalanceChangeDocument>({
          name: "value",
          title: fuelCompanyBalancesTextProvider.valueField(),
          columnAlignment: TableColumnAlignment.RIGHT,
          createValue: (fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) => new TextTableValue({
            value: withRubbleSymbol(fuelCompanyBalanceChangeDocument.formattedValue)
          })
        }),
        new TableColumn<FuelCompanyBalanceChangeDocument>({
          name: "reason_id",
          title: fuelCompanyBalancesTextProvider.reasonIdField(),
          createValue: (fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) => new TextTableValue({
            value: `${fuelCompanyBalanceChangeDocument.id}`
          })
        }),
        new TableColumn<FuelCompanyBalanceChangeDocument>({
          name: "reason",
          title: fuelCompanyBalancesTextProvider.reasonField(),
          createValue: (fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) => new TextTableValue({
            value: fuelCompanyBalanceChangeDocument.reason
          })
        }),
        new TableColumn<FuelCompanyBalanceChangeDocument>({
          name: "reason_date",
          title: fuelCompanyBalancesTextProvider.reasonDateField(),
          createValue: (fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) => new DateTableValue({
            value: fuelCompanyBalanceChangeDocument.date,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<FuelCompanyBalanceChangeDocument>({
          name: "comment",
          title: fuelCompanyBalancesTextProvider.commentField(),
          createValue: (fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) => new TextTableValue({
            value: fuelCompanyBalanceChangeDocument.comment
          })
        })
      ]
    })
  }
}
