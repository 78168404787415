import NetworkTripLimit from "../entities/trip-limits/NetworkTripLimit"
import TripLimit from "../../domain/trip-limits/TripLimit"
import FuelsMapper from "./FuelsMapper"

import isPresent from "../../../../admin/lib/isPresent"
import { v4 as uuidv4 } from "uuid"
import TripLimitTypesMapper from "./TripLimitTypesMapper"

export default class TripLimitsMapper {
  mapNetworkToDomain({
    tripLimit
  }: {
    readonly tripLimit: NetworkTripLimit
  }): TripLimit {
    const { fuel, tripLimitType } = tripLimit
    const fuelsMapper = new FuelsMapper()
    const tripLimitTypesMapper = new TripLimitTypesMapper()

    return {
      id: tripLimit.id,
      fuelId: tripLimit.fuelId,
      clientId: isPresent(tripLimit.id) ? `${tripLimit.id}` : uuidv4(),
      value: tripLimit.value,
      formattedValue: tripLimit.formattedValue,
      tripLimitTypeId: tripLimit.tripLimitTypeId,
      tripLimitType: tripLimitType && tripLimitTypesMapper.mapNetworkToDomain({ tripLimitType }),
      fuel: fuel && fuelsMapper.mapNetworkToDomain({ fuel })
    }
  }

  mapDomainToNetwork({
    tripLimit
  }: {
    readonly tripLimit: TripLimit
  }): NetworkTripLimit {
    return {
      id: tripLimit.id,
      clientId: tripLimit.clientId,
      value: tripLimit.value,
      tripLimitTypeId: tripLimit.tripLimitTypeId,
      fuelId: tripLimit.fuelId
    }
  }
}
