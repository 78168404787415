import NetworkFuelCompanyAppAdministratorErrorsObjectAttributes
  from "./NetworkFuelCompanyAppAdministratorErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import FuelCompanyAppAdministratorErrorsObject
  from "../../../../../domain/fuel-company-app-administrators/FuelCompanyAppAdministratorErrorsObject"

export default class NetworkFuelCompanyAppAdministratorErrorsObject implements FuelCompanyAppAdministratorErrorsObject {
  @Expose()
  @Type(() => NetworkFuelCompanyAppAdministratorErrorsObjectAttributes)
  readonly attributes?: NetworkFuelCompanyAppAdministratorErrorsObjectAttributes | null
}
