import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkFuelPrice from "../NetworkFuelPrice"

export default class NetworkFuelPricesResponseBody {

  @Expose()
  @Type(() => NetworkFuelPrice)
  readonly fuelPrices?: NetworkFuelPrice[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
