import CarrierUsersRepository from "../../domain/repositories/CarrierUsersRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultCarrierUsersRepository from "../../data/repositories/DefaultCarrierUsersRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForCarrierUsersRepository from "../../domain/repositories/CarriersForCarrierUsersRepository"
import DefaultCarriersForCarrierUsersRepository from "../../data/repositories/DefaultCarriersForCarrierUsersRepository"
import GetCarriersForCarrierUsersUseCase from "../../domain/use-cases/carriers/GetCarriersForCarrierUsersUseCase"

export default interface CarrierUsersDataDiModule {
  provideCarrierUsersRepository(): CarrierUsersRepository

  provideCarriersForCarrierUsersRepository(): CarriersForCarrierUsersRepository
}

export class DefaultCarrierUsersDataDiModule extends DiModule implements CarrierUsersDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCarrierUsersRepository(): CarrierUsersRepository {
    return this.single(
      DefaultCarrierUsersRepository.name,
      () => new DefaultCarrierUsersRepository({
        carrierUsersNetworkSource: this.appDataDiModule.provideCarrierUsersNetworkSource()
      })
    )
  }

  provideCarriersForCarrierUsersRepository(): CarriersForCarrierUsersRepository {
    return this.single(
      GetCarriersForCarrierUsersUseCase.name,
      () => new DefaultCarriersForCarrierUsersRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }
}
