import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelsForRefuellingOrdersReportsRepository from "../../repositories/FuelsForRefuellingOrdersReportsRepository"
import Fuel from "../../../../../core/domain/fuels/Fuel"

export interface GetFuelsForRefuellingOrdersReportsParameters extends GetObjectsParameters {
}

export default class GetFuelsForRefuellingOrdersReportsUseCase implements GetObjectsUseCase<Fuel> {
  private readonly fuelsForRefuellingOrdersReportsRepository: FuelsForRefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly fuelsForRefuellingOrdersReportsRepository: FuelsForRefuellingOrdersReportsRepository
  }) {
    this.fuelsForRefuellingOrdersReportsRepository = parameters.fuelsForRefuellingOrdersReportsRepository
  }

  async call(parameters: GetFuelsForRefuellingOrdersReportsParameters): Promise<GetObjectsResult<Fuel>> {
    return await this.fuelsForRefuellingOrdersReportsRepository.getFuels(parameters)
  }
}
