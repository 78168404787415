import React from "react"
import styles from "./PhoneNumberFormFieldComponent.module.scss"
import LabelComponent from "../../../../../../lib/label/LabelComponent"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"
import { PhoneNumberInputComponent } from "../../../../../../lib/text-input/PhoneNumberInputComponent"
import { PhoneNumberFormFieldViewState } from "../../../entities/forms/form-field-by-type/PhoneNumberFormField"

export default function PhoneNumberFormFieldComponent({
  fieldViewState,
  isDisabled = false
}: {
  readonly fieldViewState: PhoneNumberFormFieldViewState
  readonly isDisabled?: boolean
}) {
  const theme = useCoreThemeProvider()

  const name = fieldViewState.getId()
  const label = fieldViewState.getTitle()
  const isRequired = fieldViewState.getRequired()
  const errors = fieldViewState.getErrors()

  return (
    <div className={styles.root}>
      {label && name && (
        <LabelComponent
          htmlFor={name}
          text={`${label}${isRequired ? " *" : ""}`}
          className={theme.label1PrimaryClassName()}
        />
      )}
      <PhoneNumberInputComponent
        name={name}
        value={fieldViewState.value}
        isDisabled={isDisabled || !fieldViewState.getEditable()}
        onChange={fieldViewState.onChange}
        className={styles.inputWrapper}
        componentStyle={theme.input1PrimaryStyle()}
        hasErrors={(errors && errors.length > 0) ?? false}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
