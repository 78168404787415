import { Expose, Transform, Type } from "class-transformer"
import transformDecimal from "../../network/transformers/transformDecimal"
import Decimal from "decimal.js"

export default class NetworkGPSLocation {
  @Expose()
  @Transform(transformDecimal)
  readonly latitude?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedLatitude?: string | null

  @Expose()
  @Transform(transformDecimal)
  readonly longitude?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedLongitude?: string | null
}
