import React from "react"
import PasswordRecoveryRequestComponent
  from "../../components/password-recovery-request/PasswordRecoveryRequestComponent"
import PasswordRecoveryRequestPresenter from "./PasswordRecoveryRequestPresenter"
import PasswordRecoveryRequestView, { PasswordRecoveryRequestViewState } from "./PasswordRecoveryRequestView"
import { Navigate } from "react-router-dom"
import assertNever from "../../../../../lib/assertNever"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import SuccessPasswordRecoveryRequestComponent
  from "../../components/password-recovery-request/SuccessPasswordRecoveryRequestComponent"

interface Props {
  readonly providePresenter: () => PasswordRecoveryRequestPresenter
}

interface State {
  readonly passwordRecoveryRequestViewState?: PasswordRecoveryRequestViewState
}

export default class PasswordRecoveryRequestPage
  extends React.Component<Props, State>
  implements PasswordRecoveryRequestView {
  private readonly presenter: PasswordRecoveryRequestPresenter

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showPasswordRecoveryRequestViewState(passwordRecoveryRequestViewState: PasswordRecoveryRequestViewState) {
    this.setState({ passwordRecoveryRequestViewState: passwordRecoveryRequestViewState })
  }

  render() {
    const adminCoreUrlProvider = new CoreUrlProvider()

    const { passwordRecoveryRequestViewState } = this.state

    return (
      <>
        {passwordRecoveryRequestViewState && (() => {
          switch (passwordRecoveryRequestViewState.type) {
            case "not_authenticated":
              return (
                <PasswordRecoveryRequestComponent
                  onEmailAddressChanged={this.presenter.onEmailAddressChanged}
                  onSendEmailClicked={this.presenter.onSendEmailClicked}
                  emailAddress={passwordRecoveryRequestViewState.emailAddress}
                  isPasswordRecovering={passwordRecoveryRequestViewState.isPasswordRecovering}
                  passwordRecoveryRequestError={passwordRecoveryRequestViewState.passwordRecoveryRequestError}
                  passwordRecoveryRequestFailureException=
                    {passwordRecoveryRequestViewState.passwordRecoveryRequestFailureException}
                />
              )
            case "password_recovery_requested":
              return (
                <SuccessPasswordRecoveryRequestComponent
                  successMessage={passwordRecoveryRequestViewState.successMessage}
                />
              )
            case "already_authenticated":
              return <Navigate to={adminCoreUrlProvider.buildSignInUrl()} replace={true} />
            default:
              assertNever(passwordRecoveryRequestViewState)
          }
        })()}
      </>
    )
  }
}
