import RootPage from "./RootPage"
import RootPresenter from "./RootPresenter"
import React from "react"
import useLocationContextProvider, {
  LocationContextProvider
} from "../../../../../lib/presenter/useLocationContextProvider"
import RootMenuProvider from "../../services/RootMenuProvider"

export default function RootPageRouteElement({
  providePresenter,
  menuProvider
}: {
  readonly providePresenter: () => RootPresenter
  readonly menuProvider: RootMenuProvider
}) {
  const locationContextProvider: LocationContextProvider = useLocationContextProvider()

  return (
    <RootPage
      menuProvider={menuProvider}
      providePresenter={providePresenter}
      locationContextProvider={locationContextProvider}
    />
  )
}
