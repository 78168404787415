import TransportsRepository from "../../repositories/TransportsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import Transport from "../../../../../core/domain/transport/Transport"
import TransportsFilter from "../../../../../core/domain/transport/TransportsFilter"

export interface GetTransportsParameters extends GetObjectsParameters<TransportsFilter> {
}

export default class GetTransportsUseCase implements GetObjectsUseCase<Transport> {
  private readonly transportsRepository: TransportsRepository

  constructor(parameters: {
    readonly transportsRepository: TransportsRepository
  }) {
    this.transportsRepository = parameters.transportsRepository
  }

  async call(parameters: GetTransportsParameters): Promise<GetObjectsResult<Transport>> {
    return await this.transportsRepository.getTransports(parameters)
  }
}
