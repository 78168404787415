import { Expose, Type } from "class-transformer"
import NetworkFuelCompanyReconciliationReportErrorsObject from "./NetworkFuelCompanyReconciliationReportErrorsObject"
import NetworkExecutionError from "../../../errors/NetworkExecutionError"
import FuelCompanyReconciliationReportError
  from "../../../../../domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReportError"

export default class NetworkFuelCompanyReconciliationReportErrorResponseBody
  extends NetworkExecutionError
  implements FuelCompanyReconciliationReportError {

  @Expose()
  @Type(() => NetworkFuelCompanyReconciliationReportErrorsObject)
  readonly errorsObject?: NetworkFuelCompanyReconciliationReportErrorsObject | null
}
