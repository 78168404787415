import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import DriversFilter from "../../../../../core/domain/drivers/DriversFilter"
import Trip from "../../../../../core/domain/trips/Trip"
import TripsForRefuellingOrdersReportsRepository from "../../repositories/TripsForRefuellingOrdersReportsRepository"

export interface GetTripsForRefuellingOrdersReportsParameters extends GetObjectsParameters<DriversFilter> {
}

export default class GetTripsForRefuellingOrdersReportsUseCase implements GetObjectsUseCase<Trip> {
  private readonly tripsForRefuellingOrdersReportsRepository: TripsForRefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly tripsForRefuellingOrdersReportsRepository: TripsForRefuellingOrdersReportsRepository
  }) {
    this.tripsForRefuellingOrdersReportsRepository = parameters.tripsForRefuellingOrdersReportsRepository
  }

  async call(parameters: GetTripsForRefuellingOrdersReportsParameters): Promise<GetObjectsResult<User>> {
    return await this.tripsForRefuellingOrdersReportsRepository.getTrips(parameters)
  }
}
