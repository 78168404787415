import React from "react"
import styles from "./InfoContentComponent.module.scss"
import InfoRow from "../../entities/info/InfoRow"
import LinkInfo from "../../entities/info/LinkInfo"
import StringInfoRowComponent from "../info-row-by-type/string-info-row/StringInfoRowComponent"
import StringInfoRow from "../../entities/info/info-row-by-type/StringInfoRow"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import { Link } from "react-router-dom"
import DateInfoRowComponent from "../info-row-by-type/date-info-row/DateInfoRowComponent"
import DateInfoRow from "../../entities/info/info-row-by-type/DateInfoRow"
import ArrayInfoRow from "../../entities/info/info-row-by-type/ArrayInfoRow"
import ArrayInfoRowComponent from "../info-row-by-type/array-info-row/ArrayInfoRowComponent"

export default function InfoContentComponent({
  infoRows,
  links
}: {
  readonly infoRows: InfoRow[]
  readonly links: LinkInfo[]
}) {
  const theme = useCoreThemeProvider()
  return (
    <div className={styles.root}>
      <div className={`${styles.infoRowsContainer} ${theme.objectContentClassName()}`}>
        <table className={styles.infoRowsTable}>
          <tbody>
            {infoRows
              .filter((infoRow) => {
                return infoRow.getIsVisible()
              })
              .map((infoRow) => {
              return (
                <tr key={infoRow.getName()} className={styles.infoRow}>
                  <td>
                    <div className={theme.title1PrimaryClassName()}>
                      {infoRow.getTitle()}
                    </div>
                  </td>
                  <td>
                    {(() => {
                      if (infoRow instanceof StringInfoRow) {
                        return (
                          <StringInfoRowComponent
                            value={infoRow.value}
                            additionalValue={infoRow.additionalValue}
                            url={infoRow.url}
                            canShowUrl={infoRow.isUrlVisibleByPermission()}
                            openInNewTab={infoRow.openInNewTab}
                          />
                        )
                      }

                      if (infoRow instanceof DateInfoRow) {
                        return (
                          <DateInfoRowComponent
                            value={infoRow.value}
                            format={infoRow.format}
                            timeZone={infoRow.timeZone}
                          />
                        )
                      }

                      if (infoRow instanceof ArrayInfoRow) {
                        return (
                          <ArrayInfoRowComponent
                            canShowUrl={infoRow.isUrlVisibleByPermission()}
                            items={infoRow.items}
                          />
                        )
                      }

                      throw "Unknown form field type"
                    })()}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {links.length > 0 && (
        <div className={`${styles.linksContainer} ${theme.objectContentClassName()}`}>
          {links.map((link) => {
            return (
              <Link
                key={link.id}
                to={link.url}
                className={`${styles.link} ${theme.body1LinkClassName()}`}
              >
                {link.title}
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}
