import GasStationsRepository from "../../repositories/GasStationsRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationError from "../../../../../core/domain/gas-stations/GasStationError"

export interface UpdateGasStationParameters {
  readonly objectId: string
  readonly object: GasStation
}

export default class UpdateGasStationUseCase implements UpdateObjectUseCase<GasStation, GasStationError> {
  private readonly gasStationsRepository: GasStationsRepository

  constructor(parameters: {
    readonly gasStationsRepository: GasStationsRepository
  }) {
    this.gasStationsRepository = parameters.gasStationsRepository
  }

  async call(parameters: UpdateGasStationParameters): Promise<UpdateObjectResult<GasStation, GasStationError>> {
    return await this.gasStationsRepository.updateGasStation(parameters)
  }
}
