import CarriersMapper from "./CarriersMapper"
import NetworkCarrierAppAdministrator
  from "../entities/carrier-app-administrator/response-bodies/NetworkCarrierAppAdministrator"
import CarrierAppAdministrator from "../../domain/carrier-app-administrators/CarrierAppAdministrator"

export default class CarrierAppAdministratorsMapper {
  mapNetworkToDomain({
    carrierAppAdministrator
  }: {
    readonly carrierAppAdministrator: NetworkCarrierAppAdministrator
  }): CarrierAppAdministrator {
    const { carrier } = carrierAppAdministrator
    return {
      id: carrierAppAdministrator.id,
      isOwner: carrierAppAdministrator.isOwner,
      carrierId: carrierAppAdministrator.carrierId,
      carrier: carrier && new CarriersMapper().mapNetworkToDomain({ carrier })
    }
  }

  mapDomainToNetwork({
    carrierAppAdministrator
  }: {
    readonly carrierAppAdministrator: CarrierAppAdministrator
  }): NetworkCarrierAppAdministrator {
    return {
      isOwner: carrierAppAdministrator.isOwner,
      carrierId: carrierAppAdministrator.carrierId
    }
  }
}
