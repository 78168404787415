import UserProfile from "../../../domain/entities/user-profile/UserProfile"
import NetworkUserProfile from "../../../../../app/core/data/entities/user-profiles/NetworkUserProfile"

export default class UserProfilesMapper {
  mapNetworkToDomain({
    userProfile
  }: {
    readonly userProfile: NetworkUserProfile
  }): UserProfile {
    return {
      id: userProfile.id,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      adminLabel: userProfile.adminLabel
    }
  }
}
