import FuelCompanyUsersRepository from "../../repositories/FuelCompanyUsersRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import UserError from "../../../../../core/domain/users/UserError"

export interface UpdateFuelCompanyUserParameters {
  readonly objectId: string
  readonly object: User
}

export default class UpdateFuelCompanyUserUseCase implements UpdateObjectUseCase<User, UserError> {
  private readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository

  constructor(parameters: {
    readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository
  }) {
    this.fuelCompanyUsersRepository = parameters.fuelCompanyUsersRepository
  }

  async call(parameters: UpdateFuelCompanyUserParameters): Promise<UpdateObjectResult<User, UserError>> {
    return await this.fuelCompanyUsersRepository.updateUser(parameters)
  }
}
