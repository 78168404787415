import CurrentFuelCompanyBalanceTransactionsRepository
  from "../../repositories/CurrentFuelCompanyBalanceTransactionsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompanyBalanceTransaction
  from "../../../../../core/domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"

export interface GetCurrentFuelCompanyBalanceTransactionsParameters extends GetObjectsParameters {
}

export default class GetCurrentFuelCompanyBalanceTransactionsUseCase
  implements GetObjectsUseCase<FuelCompanyBalanceTransaction> {
  private readonly currentFuelCompanyBalanceTransactionsRepository: CurrentFuelCompanyBalanceTransactionsRepository

  constructor(parameters: {
    readonly currentFuelCompanyBalanceTransactionsRepository: CurrentFuelCompanyBalanceTransactionsRepository
  }) {
    this.currentFuelCompanyBalanceTransactionsRepository = parameters.currentFuelCompanyBalanceTransactionsRepository
  }

  async call(
    parameters: GetCurrentFuelCompanyBalanceTransactionsParameters
  ): Promise<GetObjectsResult<FuelCompanyBalanceTransaction>> {
    return await this.currentFuelCompanyBalanceTransactionsRepository.getTransactions(parameters)
  }
}
