import GetTransportsUseCase from "../../domain/use-cases/transports/GetTransportsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import TransportsDataDiModule from "./TransportsDataDiModule"
import CreateTransportUseCase from "../../domain/use-cases/transports/CreateTransportUseCase"
import GetTransportUseCase from "../../domain/use-cases/transports/GetTransportUseCase"
import UpdateTransportUseCase from "../../domain/use-cases/transports/UpdateTransportUseCase"
import GetCarriersForTransportUseCase from "../../domain/use-cases/carriers/GetCarriersForTransportUseCase"
import GetFuelsForTransportsUseCase from "../../domain/use-cases/fuels/GetFuelsForTransportsUseCase"

export default interface TransportsDomainDiModule {
  provideGetTransportsUseCase(): GetTransportsUseCase

  provideGetTransportUseCase(): GetTransportUseCase

  provideCreateTransportUseCase(): CreateTransportUseCase

  provideUpdateTransportUseCase(): UpdateTransportUseCase

  provideGetCarriersForTransportUseCase(): GetCarriersForTransportUseCase

  provideGetFuelsForTransportsUseCase(): GetFuelsForTransportsUseCase
}

export class DefaultTransportsDomainDiModule extends DiModule implements TransportsDomainDiModule {
  private readonly transportsDataDiModule: TransportsDataDiModule

  constructor(parameters: {
    readonly transportsDataDiModule: TransportsDataDiModule
  }) {
    super()
    this.transportsDataDiModule = parameters.transportsDataDiModule
  }

  provideGetTransportsUseCase(): GetTransportsUseCase {
    return this.single(
      GetTransportsUseCase.name,
      () => new GetTransportsUseCase({
        transportsRepository: this.transportsDataDiModule.provideTransportsRepository()
      })
    )
  }

  provideGetTransportUseCase(): GetTransportUseCase {
    return this.single(
      GetTransportUseCase.name,
      () => new GetTransportUseCase({
        transportsRepository: this.transportsDataDiModule.provideTransportsRepository()
      })
    )
  }

  provideCreateTransportUseCase(): CreateTransportUseCase {
    return this.single(
      CreateTransportUseCase.name,
      () => new CreateTransportUseCase({
        transportsRepository: this.transportsDataDiModule.provideTransportsRepository()
      })
    )
  }

  provideUpdateTransportUseCase(): UpdateTransportUseCase {
    return this.single(
      UpdateTransportUseCase.name,
      () => new UpdateTransportUseCase({
        transportsRepository: this.transportsDataDiModule.provideTransportsRepository()
      })
    )
  }

  provideGetCarriersForTransportUseCase(): GetCarriersForTransportUseCase {
    return this.single(
      GetCarriersForTransportUseCase.name,
      () => new GetCarriersForTransportUseCase({
        carriersForTransportRepository: this.transportsDataDiModule.provideCarriersForTransportsRepository()
      })
    )
  }

  provideGetFuelsForTransportsUseCase(): GetFuelsForTransportsUseCase {
    return this.single(
      GetFuelsForTransportsUseCase.name,
      () => new GetFuelsForTransportsUseCase({
        fuelsForTransportsRepository: this.transportsDataDiModule.provideFuelsForTransportsRepository()
      })
    )
  }
}
