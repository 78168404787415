import CarriersMapper from "./CarriersMapper"
import NetworkCarrierReconciliationReport
  from "../entities/carrier-reconciliation-reports/NetworkCarrierReconciliationReport"
import CarrierReconciliationReport from "../../domain/carrier-reconciliation-reports/CarrierReconciliationReport"

export default class CarrierReconciliationReportsMapper {
  mapNetworkToDomain({
    report
  }: {
    readonly report: NetworkCarrierReconciliationReport
  }): CarrierReconciliationReport {
    const {
      carrier
    } = report

    return {
      id: report.id,
      createdAt: report.createdAt,
      fromDate: report.fromDate,
      toDate: report.toDate,
      carrierId: report.carrierId,
      carrier: carrier && new CarriersMapper().mapNetworkToDomain({ carrier }),
      statusName: report.statusName,
      documentUrl: report.documentUrl,
      needRefresh: report.needRefresh
    }
  }

  mapDomainToNetwork({
    report
  }: {
    readonly report: CarrierReconciliationReport
  }): NetworkCarrierReconciliationReport {
    return {
      fromDate: report.fromDate,
      toDate: report.toDate,
      carrierId: report.carrierId
    }
  }
}
