import CarrierReconciliationReportsI18n from "../../i18n/CarrierReconciliationReportsI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import CarrierReconciliationReportsTextProvider from "../../i18n/CarrierReconciliationReportsTextProvider"

export default interface CarrierReconciliationReportsI18nDiModule {
  provideCarrierReconciliationReportsI18n(): CarrierReconciliationReportsI18n
}

export class DefaultCarrierReconciliationReportsI18nDiModule
  extends I18nDiModule<CarrierReconciliationReportsTextProvider>
  implements CarrierReconciliationReportsI18nDiModule {

  provideCarrierReconciliationReportsI18n(): CarrierReconciliationReportsI18n {
    return this.single(
      CarrierReconciliationReportsI18n.name,
      () => new CarrierReconciliationReportsI18n(this.getI18nParameters())
    )
  }
}
