import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import BooleanFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/BooleanFormField"
import GasStationsI18 from "../../i18n/GasStationsI18"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import GasStationError from "../../../../core/domain/gas-stations/GasStationError"
import GasStationErrorsObject from "../../../../core/domain/gas-stations/GasStationErrorsObject"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import GetFuelCompaniesForGasStationsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForGasStationUseCase"
import PhoneNumberFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/PhoneNumberFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import DecimalFormField from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import Decimal from "decimal.js"
import GPSLocation from "../../../../core/domain/gps-locations/GPSLocation"

const mainGroupName = "main"
const contactsGroupName = "contacts"
const gpsGroupName = "gps"

export default class GasStationFormProvider
  implements FormProvider<GasStation, GasStationError, GasStationErrorsObject> {
  private readonly gasStationsI18: GasStationsI18
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForGasStationsUseCase

  constructor(parameters: {
    readonly gasStationsI18: GasStationsI18
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForGasStationsUseCase
  }) {
    this.gasStationsI18 = parameters.gasStationsI18
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
  }

  getEntity(): string {
    return Entity.GAS_STATIONS
  }

  getNewObjectTitle(): string {
    return this.gasStationsI18.getTextProvider()
      .newObjectTitle()
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: GasStation
  }): string {
    if (isBlank(object)) {
      return this.gasStationsI18.getTextProvider()
        .existObjectTitle()
    }

    const { name } = object

    return isPresent(name) ? name : this.gasStationsI18.getTextProvider()
      .existObjectTitle()
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: GasStation
  }): string {
    if (isBlank(object)) {
      return this.gasStationsI18.getTextProvider()
        .existObjectTitle()
    }

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.gasStationsI18.getTextProvider()
        .existObjectTitle()
  }

  async buildObject(): Promise<GasStation> {
    return {}
  }

  getErrorsObject({ error }: { readonly error?: GasStationError }): GasStationErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    const fuelCompaniesTextProvider = this.gasStationsI18.getTextProvider()

    return [
      {
        name: mainGroupName,
        visible: false
      },
      {
        name: contactsGroupName,
        visible: true,
        title: fuelCompaniesTextProvider.contactsField()
      },
      {
        name: gpsGroupName,
        visible: true,
        title: fuelCompaniesTextProvider.gpsCoordinatesField()
      }
    ]
  }

  getFields(): FormField<GasStation, GasStationErrorsObject>[] {
    const gasStationsTextProvider = this.gasStationsI18.getTextProvider()

    return filterNotEmpty([
      new StringFormField<GasStation, GasStationErrorsObject>({
        title: gasStationsTextProvider.nameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "name",
        getValue: (gasStation: GasStation) => gasStation.name,
        setValue: (gasStation: GasStation, name: string) => ({ ...gasStation, name }),
        getErrors: (errorsObject?: GasStationErrorsObject) => errorsObject?.attributes?.name
      }),
      new StringFormField<GasStation, GasStationErrorsObject>({
        title: gasStationsTextProvider.codeField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "code",
        getValue: (gasStation: GasStation) => gasStation.code,
        setValue: (gasStation: GasStation, code: string) => ({ ...gasStation, code }),
        getErrors: (errorsObject?: GasStationErrorsObject) => errorsObject?.attributes?.code
      }),
      new SingleSelectFormField<GasStation, GasStationErrorsObject, FuelCompany>({
        title: gasStationsTextProvider.fuelCompanyField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelCompaniesUseCase,
        getErrors: (errorsObject?: GasStationErrorsObject) => errorsObject?.attributes?.fuelCompanyId,
        getId: () => "fuelCompany",
        getOptionId: (fuelCompany: FuelCompany) => fuelCompany.id!.toString(),
        getOptionText: (fuelCompany: FuelCompany) => fuelCompany.name,
        getValue: (gasStation: GasStation) => gasStation.fuelCompany,
        setValue: (gasStation: GasStation, fuelCompany: FuelCompany | null) => ({
          ...gasStation, fuelCompany, fuelCompanyId: fuelCompany?.id
        })
      }),
      new BooleanFormField<GasStation, GasStationErrorsObject>({
        title: gasStationsTextProvider.blockedField(),
        groupName: mainGroupName,
        getId: () => "blocked",
        getIsVisible: (gasStation: GasStation) => isPresent(gasStation.id),
        getValue: (gasStation: GasStation) => gasStation.blocked,
        setValue: (gasStation: GasStation, blocked: boolean | null | undefined) => ({ ...gasStation, blocked })
      }),
      new PhoneNumberFormField<GasStation, GasStationErrorsObject>({
        title: gasStationsTextProvider.phoneNumberField(),
        groupName: contactsGroupName,
        getId: () => "phoneNumber",
        getValue: (gasStation: GasStation) => gasStation.phoneNumber,
        setValue: (gasStation: GasStation, phoneNumber: string) => ({ ...gasStation, phoneNumber }),
        getErrors: (errorsObject?: GasStationErrorsObject) => errorsObject?.attributes?.phoneNumber
      }),
      new StringFormField<GasStation, GasStationErrorsObject>({
        title: gasStationsTextProvider.addressField(),
        groupName: contactsGroupName,
        required: true,
        getId: () => "address",
        getValue: (gasStation: GasStation) => gasStation.address,
        setValue: (gasStation: GasStation, address: string) => ({ ...gasStation, address }),
        getErrors: (errorsObject?: GasStationErrorsObject) => errorsObject?.attributes?.address
      }),
      new DecimalFormField<GasStation, GasStationErrorsObject>({
        title: gasStationsTextProvider.latitudeField(),
        required: true,
        groupName: gpsGroupName,
        scale: 6,
        thousandsSeparator: "",
        shouldShowTrailingZeroes: true,
        getId: () => "location_latitude",
        getValue: (gasStation: GasStation) => gasStation.location?.latitude,
        setValue: (gasStation: GasStation, latitude: Decimal | null | undefined) => {
          const newLocation: GPSLocation = { ...gasStation.location, latitude }

          return {
            ...gasStation,
            location: newLocation
          }
        },
        getErrors: (
          errorsObject?: GasStationErrorsObject
        ) => errorsObject?.location?.attributes?.latitude ?? errorsObject?.attributes?.location
      }),
      new DecimalFormField<GasStation, GasStationErrorsObject>({
        title: gasStationsTextProvider.longitudeField(),
        required: true,
        groupName: gpsGroupName,
        scale: 6,
        shouldShowTrailingZeroes: true,
        thousandsSeparator: "",
        getId: () => "location_longitude",
        getValue: (gasStation: GasStation) => gasStation.location?.longitude,
        setValue: (gasStation: GasStation, longitude: Decimal | null | undefined) => {
          const newLocation: GPSLocation = { ...gasStation.location, longitude }

          return {
            ...gasStation,
            location: newLocation
          }
        },
        getErrors: (
          errorsObject?: GasStationErrorsObject
        ) => errorsObject?.location?.attributes?.longitude ?? errorsObject?.attributes?.location
      })
    ])
  }
}
