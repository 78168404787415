import TripsRepository from "../../domain/repositories/TripsRepository"
import { GetTripsParameters } from "../../domain/use-cases/trips/GetTripsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateTripParameters } from "../../domain/use-cases/trips/CreateTripUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetTripParameters } from "../../domain/use-cases/trips/GetTripUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateTripParameters } from "../../domain/use-cases/trips/UpdateTripUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import isPresent from "../../../../../admin/lib/isPresent"
import TripsNetworkSource from "../../../../core/data/sources/network/TripsNetworkSource"
import Trip from "../../../../core/domain/trips/Trip"
import NetworkTripsRequestFilter from "../../../../core/data/entities/trips/request-queries/NetworkTripsRequestFilter"
import TripsMapper from "../../../../core/data/mappers/TripsMapper"
import TripError from "../../../../core/domain/trips/TripError"
import TripStatusesStaticSource from "../../../../core/data/sources/static/TripStatusesStaticSource"
import DateTimeFormatter from "../../../../../admin/lib/DateTimeFormatter"

export default class DefaultTripsRepository implements TripsRepository {
  private readonly tripsNetworkSource: TripsNetworkSource
  private readonly tripStatusesStaticSource: TripStatusesStaticSource

  constructor(parameters: {
    readonly tripsNetworkSource: TripsNetworkSource
    readonly tripStatusesStaticSource: TripStatusesStaticSource
  }) {
    this.tripsNetworkSource = parameters.tripsNetworkSource
    this.tripStatusesStaticSource = parameters.tripStatusesStaticSource
  }

  async getTrips({
    filter,
    query,
    pagination,
    sort
  }: GetTripsParameters): Promise<GetObjectsResult<Trip>> {
    const carrierId = filter?.carrierId
    const driverId = filter?.driverId
    const transportId = filter?.transportId
    const status = filter?.status
    const dateFrom = filter?.dateFrom
    const dateTo = filter?.dateTo
    const result = await this.tripsNetworkSource.getTrips({
      filter: new NetworkTripsRequestFilter({
        query,
        carrierIds: isPresent(carrierId) ? [carrierId] : null,
        transportIds: isPresent(transportId) ? [transportId] : null,
        driverIds: isPresent(driverId) ? [driverId] : null,
        statuses: isPresent(status) ? [status] : null,
        dateFrom: dateFrom && new DateTimeFormatter(dateFrom).formatISODate(),
        dateTo: dateTo && new DateTimeFormatter(dateTo).formatISODate()
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const tripStatuses = await this.tripStatusesStaticSource.getTripStatuses({})
    switch (result.type) {
      case "success": {
        const tripsMapper = new TripsMapper()
        return {
          type: "success",
          data: {
            objects: result.data.trips!.map((trip) => {
              return tripsMapper.mapNetworkToDomain({
                trip,
                tripStatuses
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }

  async getTrip({
    objectId
  }: GetTripParameters): Promise<GetObjectResult<Trip>> {
    const result = await this.tripsNetworkSource.getTrip({
      tripId: parseInt(objectId)
    })

    const tripStatuses = await this.tripStatusesStaticSource.getTripStatuses({})
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new TripsMapper().mapNetworkToDomain({
            trip: result.data.trip!,
            tripStatuses
          })
        }
      default:
        return result
    }
  }

  async createTrip({
    object: trip
  }: CreateTripParameters): Promise<CreateObjectResult<Trip, TripError>> {
    const tripsMapper = new TripsMapper()
    const result = await this.tripsNetworkSource.createTrip({
      trip: tripsMapper.mapDomainToNetwork({ trip })
    })

    const tripStatuses = await this.tripStatusesStaticSource.getTripStatuses({})
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: tripsMapper.mapNetworkToDomain({
            trip: result.data.trip!,
            tripStatuses
          })
        }
      default:
        return result
    }
  }

  async updateTrip({
    objectId: tripId,
    object: trip
  }: UpdateTripParameters): Promise<UpdateObjectResult<Trip, TripError>> {
    const tripsMapper = new TripsMapper()
    const result = await this.tripsNetworkSource.updateTransport({
      tripId: parseInt(tripId),
      trip: tripsMapper.mapDomainToNetwork({
        trip
      })
    })

    const tripStatuses = await this.tripStatusesStaticSource.getTripStatuses({})
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: tripsMapper.mapNetworkToDomain({
            trip: result.data.trip!,
            tripStatuses
          })
        }
      default:
        return result
    }
  }
}
