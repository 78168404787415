import ChangingLogItemsRepository from "../../domain/repositories/ChangingLogItemsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultChangingLogItemsRepository from "../../data/repositories/DefaultChangingLogItemsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForTripChangingLogRepository from "../../domain/repositories/CarriersForTripChangingLogRepository"
import TripsForTripChangingLogRepository from "../../domain/repositories/TripsForTripChangingLogRepository"
import DefaultCarriersForTripChangingLogRepository
  from "../../data/repositories/DefaultCarriersForTripChangingLogRepository"
import DefaultTripsForTripChangingLogRepository from "../../data/repositories/DefaultTripsForTripChangingLogRepository"
import ChangingTypesForTripChangingLogRepository
  from "../../domain/repositories/ChangingTypesForTripChangingLogRepository"
import DefaultChangingTypesForTripChangingLogRepository
  from "../../data/repositories/DefaultChangingTypesForTripChangingLogRepository"

export default interface TripChangingLogDataDiModule {
  provideChangingLogItemsRepository(): ChangingLogItemsRepository

  provideCarriersForTripChangingLogRepository(): CarriersForTripChangingLogRepository

  provideTripsForTripChangingLogRepository(): TripsForTripChangingLogRepository

  provideChangingTypesForTripChangingLogRepository(): ChangingTypesForTripChangingLogRepository
}

export class DefaultTripChangingLogDataDiModule extends DiModule implements TripChangingLogDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideChangingLogItemsRepository(): ChangingLogItemsRepository {
    return this.single(
      DefaultChangingLogItemsRepository.name,
      () => new DefaultChangingLogItemsRepository({
        changingLogItemsNetworkSource: this.appDataDiModule.provideChangingLogItemsNetworkSource()
      })
    )
  }

  provideCarriersForTripChangingLogRepository(): CarriersForTripChangingLogRepository {
    return this.single(
      DefaultCarriersForTripChangingLogRepository.name,
      () => new DefaultCarriersForTripChangingLogRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }

  provideTripsForTripChangingLogRepository(): TripsForTripChangingLogRepository {
    return this.single(
      DefaultTripsForTripChangingLogRepository.name,
      () => new DefaultTripsForTripChangingLogRepository({
        tripsNetworkSource: this.appDataDiModule.provideTripsNetworkSource()
      })
    )
  }

  provideChangingTypesForTripChangingLogRepository(): ChangingTypesForTripChangingLogRepository {
    return this.single(
      DefaultChangingTypesForTripChangingLogRepository.name,
      () => new DefaultChangingTypesForTripChangingLogRepository({
        changingTypesStaticSource: this.appDataDiModule.provideChangingTypesStaticSource()
      })
    )
  }
}
