import AuthorizationErrorRepository from "../../repositories/authorization-error/AuthorizationErrorRepository"
import { AuthorizationErrorEventCallback } from "../../entities/AuthorizationErrorEvent"

export default class SubscribeToAuthorizationErrorUseCase {
  private readonly authorizationErrorRepository: AuthorizationErrorRepository

  constructor(parameters: {
    readonly authorizationErrorRepository: AuthorizationErrorRepository
  }) {
    this.authorizationErrorRepository = parameters.authorizationErrorRepository
  }

  call(callback: AuthorizationErrorEventCallback) {
    return this.authorizationErrorRepository.subscribeToAuthorizationError(callback)
  }
}
