import React, { ReactNode } from "react"
import styles from "./ObjectButtonsContainerComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"

export default function ObjectButtonsContainerComponent({
  leftButtons,
  rightButtons,
  errorMessage
}: {
  readonly leftButtons?: ReactNode
  readonly rightButtons?: ReactNode
  readonly errorMessage?: string | null
}) {
  const theme = useCoreThemeProvider()

  return (
    <div className={styles.root}>
      <div className={styles.actionsContainer}>
        { leftButtons }
        {errorMessage && (
          <div className={`${styles.errorMessage} ${theme.body3ErrorClassName()}`}>
            {errorMessage}
          </div>
        )}
      </div>
      <div className={styles.actionsContainer}>
        { rightButtons }
      </div>
    </div>
  )
}
