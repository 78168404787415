import Presenter from "../../../../../../admin/lib/presenter/Presenter"
import CurrentCarrierBalanceView, {
  AbstractCurrentCarrierBalanceHeaderViewState,
  AbstractCurrentCarrierBalanceListViewState,
  CurrentCarrierBalanceHeaderViewState, CurrentCarrierBalanceListItem,
  CurrentCarrierBalanceListViewState
} from "./CurrentCarrierBalanceView"
import SubscribeToObjectsEventsUseCase
  from "../../../../../../admin/features/objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase
  from "../../../../../../admin/features/objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsEvent, {
  ObjectsEventCallback
} from "../../../../../../admin/features/objects/domain/entities/ObjectsEvent"
import Page from "../../../../../../admin/core/domain/entities/pages/Page"
import autoBind from "auto-bind"
import assertNever from "../../../../../../admin/lib/assertNever"
import ExecutionError from "../../../../../../admin/core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../../admin/core/domain/exceptions/ApplicationException"
import CarrierBalanceTransaction
  from "../../../../../core/domain/carrier-balance-transactions/CarrierBalanceTransaction"
import Carrier from "../../../../../core/domain/carriers/Carrier"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import { GetObjectsResult } from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import Table from "../../../../../../admin/features/objects/presentation/entities/tables/Table"
import CheckPermissionDeniedUseCase
  from "../../../../../../admin/core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import TableProviderUtils from "../../../../../../admin/features/objects/presentation/providers/TableProviderUtils"
import CarrierBalanceChangeDocument
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import GetCurrentCarrierBalanceTransactionsUseCase
  from "../../../domain/use-cases/carrier-balance-transactions/GetCurrentCarrierBalanceTransactionsUseCase"
import GetCurrentCarrierBalanceChangeDocumentsUseCase
  from "../../../domain/use-cases/carrier-balance-change-documents/GetCurrentCarrierBalanceChangeDocumentsUseCase"
import GetCarrierForCurrentCarrierBalancesUseCase
  from "../../../domain/use-cases/carriers/GetCarrierForCurrentCarrierBalancesUseCase"
import CurrentCarrierBalanceTransactionsTableProvider
  from "../../table-providers/CurrentCarrierBalanceTransactionsTableProvider"
import CurrentCarrierBalanceChangeDocumentsTableProvider
  from "../../table-providers/CurrentCarrierBalanceChangeDocumentsTableProvider"

export default class CurrentCarrierBalancePresenter extends Presenter<CurrentCarrierBalanceView> {

  private readonly getCurrentCarrierBalanceTransactionsUseCase: GetCurrentCarrierBalanceTransactionsUseCase
  private readonly getCurrentCarrierBalanceChangeDocumentsUseCase: GetCurrentCarrierBalanceChangeDocumentsUseCase
  private readonly getCarrierUseCase: GetCarrierForCurrentCarrierBalancesUseCase
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly carrierBalanceTransactionTable: Table<CarrierBalanceTransaction>
  private readonly carrierBalanceChangeDocumentTable: Table<CarrierBalanceChangeDocument>
  private carrierBalanceTransactionTableProviderUtils : TableProviderUtils<CarrierBalanceTransaction>
  private carrierBalanceChangeDocumentTableProviderUtils : TableProviderUtils<CarrierBalanceChangeDocument>
  private objectsEventsCallback?: ObjectsEventCallback
  private carrierBalanceListViewState?: CurrentCarrierBalanceListViewState
  private carrierBalanceHeaderViewState?: CurrentCarrierBalanceHeaderViewState
  private carrier?: Carrier
  private items?: CurrentCarrierBalanceListItem[]
  private balanceTransactionType: BalanceTransactionType
  private page?: Page
  private needReloadObjectsOnReAttach: boolean
  private isNextPageLoading = false
  private lastObjectsLoadingTimestamp?: number

  constructor(parameters: {
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
    readonly getCurrentCarrierBalanceTransactionsUseCase: GetCurrentCarrierBalanceTransactionsUseCase
    readonly getCurrentCarrierBalanceChangeDocumentsUseCase: GetCurrentCarrierBalanceChangeDocumentsUseCase
    readonly getCarrierUseCase: GetCarrierForCurrentCarrierBalancesUseCase
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly transactionsType: BalanceTransactionType | null | undefined
    readonly carrierBalanceTransactionsTableProvider: CurrentCarrierBalanceTransactionsTableProvider
    readonly carrierBalanceChangeDocumentsTableProvider: CurrentCarrierBalanceChangeDocumentsTableProvider
  }) {
    super()

    autoBind(this)

    this.balanceTransactionType = parameters.transactionsType ?? BalanceTransactionType.REPLENISHMENT

    this.getCurrentCarrierBalanceTransactionsUseCase =
      parameters.getCurrentCarrierBalanceTransactionsUseCase
    this.getCurrentCarrierBalanceChangeDocumentsUseCase =
      parameters.getCurrentCarrierBalanceChangeDocumentsUseCase
    this.getCarrierUseCase = parameters.getCarrierUseCase
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.needReloadObjectsOnReAttach = false
    this.carrierBalanceChangeDocumentTable =
      parameters.carrierBalanceChangeDocumentsTableProvider.getTable()
    this.carrierBalanceTransactionTable =
      parameters.carrierBalanceTransactionsTableProvider.getTable()

    this.carrierBalanceTransactionTableProviderUtils =
      this.buildCarrierBalanceTransactionsTableProviderUtils(parameters)
    this.carrierBalanceTransactionTableProviderUtils.initTableByPermissions()
    this.carrierBalanceChangeDocumentTableProviderUtils =
      this.buildCarrierBalanceChangeDocumentsTableProviderUtils(parameters)
    this.carrierBalanceChangeDocumentTableProviderUtils.initTableByPermissions()
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    const isWriteOffTableVisible = this.carrierBalanceTransactionTableProviderUtils.isTableVisibleByPermission()
    const isReplenishmentTableVisible = this.carrierBalanceChangeDocumentTableProviderUtils.isTableVisibleByPermission()
    if (!isWriteOffTableVisible || !isReplenishmentTableVisible) {
      this.setAndShowForbiddenListViewState()
      return
    }

    const initialize = async(): Promise<void> => {
      this.setAndShowInitializingListViewState()

      this.subscribeToObjectsEvents()

      await this.loadAndShowInitialData()
    }

    initialize()
      .then()
  }

  protected onViewReAttach() {
    super.onViewReAttach()

    this.showHeaderViewState()
    this.showListViewState()

    // TODO: reload objects if related objects changed, not only current object-form type.
    if (this.getNeedReloadObjectsOnReAttach()) {
      this.setNeedReloadObjectsOnReAttach(false)
      this.loadAndShowInitialData()
        .then()
    }
  }

  protected onDestroy() {
    super.onDestroy()
    this.unsubscribeFromObjectsEvents()
  }

  onNextPageRequested() {
    const hasMore = this.page?.hasMore
    if (hasMore && !this.isNextPageLoading) {
      this.loadAndShowObjectsNextPage()
        .then()
    }
  }

  onRetryLoadClicked() {
    const itemsSize = this.items?.length ?? 0
    if (itemsSize > 0) {
      this.loadAndShowObjectsNextPage().then()
    } else {
      this.loadAndShowObjectsFirstPage().then()
    }
  }

  onSegmentSelected(segmentItemId: string) {
    this.balanceTransactionType = segmentItemId as BalanceTransactionType

    this.setAndShowLoadedHeaderViewState()
    this.loadAndShowObjectsFirstPage()
      .then()
    this.cacheTransactionsTypeInView()
  }

  private subscribeToObjectsEvents() {
    this.objectsEventsCallback = this.subscribeToObjectsEventsUseCase.call((event: ObjectsEvent) => {
      switch (event.type) {
        case "created":
        case "updated":
        case "destroyed":
          this.setNeedReloadObjectsOnReAttach(true)
          break
        default:
          assertNever(event)
      }
    })
  }

  private unsubscribeFromObjectsEvents() {
    this.objectsEventsCallback && this.unsubscribeFromObjectsEventsUseCase.call(this.objectsEventsCallback)
  }

  private async loadAndShowInitialData() {
    await this.loadAndShowCarrier()
    await this.loadAndShowObjectsFirstPage()
  }

  private async loadAndShowCarrier(): Promise<void> {
    this.setAndShowInitializingHeaderViewState()

    const result = await this.getCarrierUseCase.call({})

    switch (result.type) {
      case "error":
        this.setAndShowLoadingErrorHeaderViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowLoadingFailureHeaderViewState({ exception: result.exception })
        break
      case "success":
        this.setCarrier(result.data)
        this.setAndShowLoadedHeaderViewState()
        break
    }
  }

  private async loadAndShowObjectsFirstPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.setItems(undefined)
    this.setPage(undefined)
    this.setAndShowLoadingListViewState()

    const result: GetObjectsResult<CurrentCarrierBalanceListItem> = await (async() => {
      switch (this.balanceTransactionType) {
        case BalanceTransactionType.WRITE_OFF:
          return await this.getCurrentCarrierBalanceTransactionsUseCase.call({})
        case BalanceTransactionType.REPLENISHMENT:
          return await this.getCurrentCarrierBalanceChangeDocumentsUseCase.call({})
      }
    })()

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setAndShowLoadingErrorListViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowLoadingFailureListViewState({ exception: result.exception })
        break
      case "success":
        this.setItems(result.data.objects)
        this.setPage(result.data.page)
        this.setAndShowLoadedListViewState()
        break
    }
  }

  private async loadAndShowObjectsNextPage(): Promise<void> {
    const timestamp: number = new Date().getTime()
    this.lastObjectsLoadingTimestamp = timestamp

    this.isNextPageLoading = true
    this.setAndShowNextPageLoadingItemsListViewState()

    const lastTransactionIndex: number = this.items!.length - 1
    const lastTransaction = this.items![lastTransactionIndex]

    const result: GetObjectsResult<CurrentCarrierBalanceListItem> = await (async() => {
      switch (this.balanceTransactionType) {
        case BalanceTransactionType.WRITE_OFF:
          return await this.getCurrentCarrierBalanceTransactionsUseCase.call({
            pagination: {
              id: this.carrierBalanceTransactionTable.getObjectId(lastTransaction)
            }
          })
        case BalanceTransactionType.REPLENISHMENT:
          return await this.getCurrentCarrierBalanceChangeDocumentsUseCase.call({
            pagination: {
              id: this.carrierBalanceChangeDocumentTable.getObjectId(lastTransaction)
            }
          })
      }
    })()

    this.isNextPageLoading = false

    const isLastLoading: boolean = timestamp === this.lastObjectsLoadingTimestamp
    if (!isLastLoading) return

    switch (result.type) {
      case "error":
        this.setAndShowNextPageLoadingErrorListViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowNextPageLoadingFailureListViewState({ exception: result.exception })
        break
      case "success":
        this.setItems([...this.items!, ...result.data.objects])
        this.setPage(result.data.page)
        this.setAndShowLoadedListViewState()
        break
    }
  }

  private setAndShowInitializingHeaderViewState() {
    this.setAndShowHeaderViewState({
      ...this.getAbstractHeaderViewStateParameters(),
      type: "initializing"
    })
  }

  private setAndShowLoadingErrorHeaderViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowHeaderViewState({
      ...this.getAbstractHeaderViewStateParameters(),
      type: "loading_error",
      error
    })
  }

  private setAndShowLoadingFailureHeaderViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowHeaderViewState({
      ...this.getAbstractHeaderViewStateParameters(),
      type: "loading_failure",
      exception
    })
  }

  private setAndShowLoadedHeaderViewState() {
    this.setAndShowHeaderViewState({
      ...this.getAbstractHeaderViewStateParameters(),
      type: "loaded",
      carrier: this.carrier!,
      transactionType: this.balanceTransactionType
    })
  }

  private getAbstractHeaderViewStateParameters(): AbstractCurrentCarrierBalanceHeaderViewState {
    return {
      transactionType: this.balanceTransactionType
    }
  }

  private setAndShowHeaderViewState(headerViewState: CurrentCarrierBalanceHeaderViewState) {
    this.setHeaderViewState(headerViewState)
    this.showHeaderViewState()
  }

  private showHeaderViewState() {
    this.carrierBalanceHeaderViewState && this.getView()
      ?.showCarrierBalanceHeaderViewState(this.carrierBalanceHeaderViewState)
  }

  private setHeaderViewState(headerViewState: CurrentCarrierBalanceHeaderViewState) {
    this.carrierBalanceHeaderViewState = headerViewState
  }

  private setAndShowInitializingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "initializing"
    })
  }

  private setAndShowLoadingListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading"
    })
  }

  private setAndShowLoadingErrorListViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading_error",
      error
    })
  }

  private setAndShowLoadingFailureListViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loading_failure",
      exception
    })
  }

  private setAndShowLoadedListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "loaded",
      items: this.items!,
      page: this.page!
    })
  }

  private setAndShowNextPageLoadingItemsListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading",
      items: this.items!
    })
  }

  private setAndShowNextPageLoadingErrorListViewState({ error }: {
    readonly error: ExecutionError
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading_error",
      items: this.items!,
      error
    })
  }

  private setAndShowNextPageLoadingFailureListViewState({ exception }: {
    readonly exception: ApplicationException
  }) {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "next_page_loading_failure",
      items: this.items!,
      exception
    })
  }

  private setAndShowForbiddenListViewState() {
    this.setAndShowListViewState({
      ...this.getAbstractListViewStateParameters(),
      type: "forbidden"
    })
  }

  private getAbstractListViewStateParameters(): AbstractCurrentCarrierBalanceListViewState {
    return {
      table: (() => {
        switch (this.balanceTransactionType) {
          case BalanceTransactionType.WRITE_OFF:
            return this.carrierBalanceTransactionTable
          case BalanceTransactionType.REPLENISHMENT:
            return this.carrierBalanceChangeDocumentTable
        }
      })()
    }
  }

  private setAndShowListViewState(listViewState: CurrentCarrierBalanceListViewState) {
    this.setListViewState(listViewState)
    this.showListViewState()
  }

  private showListViewState() {
    this.carrierBalanceListViewState && this.getView()
      ?.showCarrierBalanceListViewState(this.carrierBalanceListViewState)
  }

  private setListViewState(listViewState: CurrentCarrierBalanceListViewState) {
    this.carrierBalanceListViewState = listViewState
  }

  private cacheTransactionsTypeInView() {
    this.getView()
      ?.cacheSelectedTransactionsType(this.balanceTransactionType)
  }

  private setItems(items: CurrentCarrierBalanceListItem[] | undefined) {
    this.items = items
  }

  private setCarrier(carrier: Carrier) {
    this.carrier = carrier
  }

  private setPage(page: Page | undefined) {
    this.page = page
  }

  private getNeedReloadObjectsOnReAttach() {
    return this.needReloadObjectsOnReAttach
  }

  private setNeedReloadObjectsOnReAttach(needReloadObjectsOnReAttach: boolean) {
    this.needReloadObjectsOnReAttach = needReloadObjectsOnReAttach
  }

  private buildCarrierBalanceTransactionsTableProviderUtils(parameters: {
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase,
    readonly carrierBalanceTransactionsTableProvider: CurrentCarrierBalanceTransactionsTableProvider
  }) {
    return new TableProviderUtils<CarrierBalanceTransaction>({
      table: this.carrierBalanceTransactionTable,
      tableProvider: parameters.carrierBalanceTransactionsTableProvider,
      checkPermissionDeniedUseCase: parameters.checkPermissionDeniedUseCase
    })
  }

  private buildCarrierBalanceChangeDocumentsTableProviderUtils(parameters: {
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase,
    readonly carrierBalanceChangeDocumentsTableProvider: CurrentCarrierBalanceChangeDocumentsTableProvider
  }) {
    return new TableProviderUtils<CarrierBalanceChangeDocument>({
      table: this.carrierBalanceChangeDocumentTable,
      tableProvider: parameters.carrierBalanceChangeDocumentsTableProvider,
      checkPermissionDeniedUseCase: parameters.checkPermissionDeniedUseCase
    })
  }
}
