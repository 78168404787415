import { Expose, Type } from "class-transformer"
import NetworkFuelCompanyCommercialTerm from "../NetworkFuelCompanyCommercialTerm"

export default class NetworkFuelCompanyCommercialTermRequestBody {

  @Expose()
  @Type(() => NetworkFuelCompanyCommercialTerm)
  readonly fuelCompanyCommercialTerm?: NetworkFuelCompanyCommercialTerm | null

  constructor(parameters: {
    readonly fuelCompanyCommercialTerm?: NetworkFuelCompanyCommercialTerm | null
  }) {
    this.fuelCompanyCommercialTerm = parameters.fuelCompanyCommercialTerm
  }
}
