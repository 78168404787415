import React from "react"
import styles from "./ButtonComponent.module.scss"
import { Link } from "react-router-dom"
import ButtonComponentStyles from "./ButtonComponentStyles"

export default function LinkButtonComponent<LinkState = never>({
  title = "",
  url,
  replace = false,
  isDisabled = false,
  isSelected = false,
  componentStyles = {},
  state
}: {
  readonly title?: string
  readonly url: string
  readonly replace?: boolean
  readonly isDisabled?: boolean
  readonly isSelected?: boolean
  readonly componentStyles?: ButtonComponentStyles
  readonly state?: LinkState
}) {
  const {
    classes: {
      default: buttonDefaultClassName = "",
      selected: buttonSelectedClassName = "",
      disabled: buttonDisabledClassName
    } = {}
  } = componentStyles

  return (
    <Link
      state={state}
      replace={replace}
      to={url}
      className={`${styles.root} ${title !== "" ? styles.textButton : ""} ${buttonDefaultClassName} ${isDisabled ? `${styles.disabled} ${buttonDisabledClassName}` : ""} ${isSelected ? buttonSelectedClassName : ""}`}
    >
      {title}
    </Link>
  )
}
