import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import BooleanFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/BooleanFormField"
import InstallationInstancesI18 from "../../i18n/InstallationInstancesI18"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import InstallationInstance from "../../../../core/domain/installation-instance/InstallationInstance"
import InstallationInstanceError from "../../../../core/domain/installation-instance/InstallationInstanceError"
import InstallationInstanceErrorsObject
  from "../../../../core/domain/installation-instance/InstallationInstanceErrorsObject"
import GetGasStationsForInstallationInstanceUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForInstallationInstanceUseCase"
import isPresent from "../../../../../admin/lib/isPresent"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GetFuelCompaniesForInstallationInstancesUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForInstallationInstancesUseCase"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import GasStationsFilter from "../../../../core/domain/gas-stations/GasStationsFilter"
import InstallationInstanceType, {
  InstallationInstanceTypeSelectOption
} from "../../../../core/domain/installation-instance/InstallationInstanceType"
import GetInstallationInstanceTypesForInstallationInstancesUseCase
  from "../../domain/use-cases/installation-instance-types/GetInstallationInstanceTypesForInstallationInstancesUseCase"
import { v4 as uuidv4 } from "uuid"

const mainGroupName = "main"

export default class InstallationInstanceFormProvider
  implements FormProvider<InstallationInstance, InstallationInstanceError, InstallationInstanceErrorsObject> {
  private readonly installationInstancesI18: InstallationInstancesI18
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForInstallationInstancesUseCase
  private readonly getGasStationsUseCase: GetGasStationsForInstallationInstanceUseCase
  private readonly getInstallationInstanceTypesUseCase: GetInstallationInstanceTypesForInstallationInstancesUseCase

  constructor(parameters: {
    readonly installationInstancesI18: InstallationInstancesI18
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForInstallationInstancesUseCase
    readonly getGasStationsUseCase: GetGasStationsForInstallationInstanceUseCase
    readonly getInstallationInstanceTypesUseCase: GetInstallationInstanceTypesForInstallationInstancesUseCase
  }) {
    this.installationInstancesI18 = parameters.installationInstancesI18
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
    this.getInstallationInstanceTypesUseCase = parameters.getInstallationInstanceTypesUseCase
  }

  getEntity(): string {
    return Entity.INSTALLATION_INSTANCES
  }

  getNewObjectTitle(): string {
    return this.installationInstancesI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle(): string {
    return this.installationInstancesI18.getTextProvider().existObjectTitle()
  }

  getExistedObjectTitle(): string {
    return this.installationInstancesI18.getTextProvider().existObjectTitle()
  }

  async buildObject(): Promise<InstallationInstance> {
    return {}
  }

  getErrorsObject(
    { error }: { readonly error?: InstallationInstanceError }
  ): InstallationInstanceErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<InstallationInstance, InstallationInstanceErrorsObject>[] {
    const installationInstancesTextProvider = this.installationInstancesI18.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<
        InstallationInstance,
        InstallationInstanceErrorsObject,
        InstallationInstanceTypeSelectOption
      >({
        title: installationInstancesTextProvider.typeField(),
        clearable: false,
        groupName: mainGroupName,
        required: true,
        searchingEnabled: false,
        getObjectsUseCase: this.getInstallationInstanceTypesUseCase,
        getErrors: (errorsObject?: InstallationInstanceErrorsObject) => errorsObject?.attributes?.type,
        getId: () => "type",
        getOptionId: (
          installationInstanceTypeSelectOption: InstallationInstanceTypeSelectOption
        ) => installationInstanceTypeSelectOption.id,
        getOptionText: (
          installationInstanceTypeSelectOption: InstallationInstanceTypeSelectOption
        ) => installationInstanceTypeSelectOption.text,
        getValue: (installationInstance: InstallationInstance) => installationInstance.typeSelectOption,
        setValue: (
          installationInstance: InstallationInstance,
          typeSelectOption: InstallationInstanceTypeSelectOption | null
        ) => {
          const type = typeSelectOption?.id as InstallationInstanceType
          const guid = type === InstallationInstanceType.SERVIO_PUMP_GAS ?
            uuidv4() :
            ""

          return {
            ...installationInstance,
            typeSelectOption,
            type,
            guid
          }
        }
      }),
      new StringFormField<InstallationInstance, InstallationInstanceErrorsObject>({
        title: installationInstancesTextProvider.guidField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "guid",
        getValue: (installationInstance: InstallationInstance) => installationInstance.guid,
        setValue: (installationInstance: InstallationInstance, guid: string) => ({ ...installationInstance, guid }),
        getErrors: (errorsObject?: InstallationInstanceErrorsObject) => errorsObject?.attributes?.guid
      }),
      new SingleSelectFormField<InstallationInstance, InstallationInstanceErrorsObject, FuelCompany>({
        title: installationInstancesTextProvider.fuelCompanyField(),
        clearable: true,
        groupName: mainGroupName,
        required: false,
        getObjectsUseCase: this.getFuelCompaniesUseCase,
        getId: () => "fuelCompany",
        getOptionId: (fuelCompany: FuelCompany) => fuelCompany.id!.toString(),
        getOptionText: (fuelCompany: FuelCompany) => fuelCompany.name,
        getValue: (installationInstance: InstallationInstance) => installationInstance.fuelCompany,
        setValue: (installationInstance: InstallationInstance, fuelCompany: FuelCompany | null) => ({
          ...installationInstance,
          fuelCompany,
          fuelCompanyId: fuelCompany?.id,
          gasStationId: fuelCompany?.id === installationInstance.gasStation?.fuelCompanyId ?
            installationInstance.gasStationId :
            null,
          gasStation: fuelCompany?.id === installationInstance.gasStation?.fuelCompanyId ?
            installationInstance.gasStation :
            null
        })
      }),
      new SingleSelectFormField<InstallationInstance, InstallationInstanceErrorsObject, GasStation, GasStationsFilter>({
        title: installationInstancesTextProvider.gasStationField(),
        clearable: false,
        groupName: mainGroupName,
        required: true,
        getObjectsUseCase: this.getGasStationsUseCase,
        getErrors: (errorsObject?: InstallationInstanceErrorsObject) => errorsObject?.attributes?.gasStationId,
        getId: () => "gasStation",
        getOptionId: (gasStation: GasStation) => gasStation.id!.toString(),
        getOptionText: (gasStation: GasStation) => gasStation.name,
        getValue: (installationInstance: InstallationInstance) => installationInstance.gasStation,
        setValue: (installationInstance: InstallationInstance, gasStation: GasStation | null) => ({
          ...installationInstance,
          gasStation,
          gasStationId: gasStation?.id,
          fuelCompany: gasStation?.fuelCompany,
          fuelCompanyId: gasStation?.fuelCompanyId
        }),
        getOptionObjectsFilter: (installationInstance: InstallationInstance) => ({
          fuelCompany: installationInstance.fuelCompany,
          fuelCompanyId: installationInstance.fuelCompanyId
        })
      }),
      new BooleanFormField<InstallationInstance, InstallationInstanceErrorsObject>({
        title: installationInstancesTextProvider.blockedField(),
        groupName: mainGroupName,
        getId: () => "blocked",
        getIsVisible: (installationInstance: InstallationInstance) => isPresent(installationInstance.id),
        getValue: (installationInstance: InstallationInstance) => installationInstance.blocked,
        setValue: (installationInstance: InstallationInstance, blocked: boolean | null | undefined) => ({
          ...installationInstance, blocked
        })
      })
    ])
  }
}
