import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkCarrierCommercialTermResponseBody
  from "../../entities/carrier-commercial-terms/response-bodies/NetworkCarrierCommercialTermResponseBody"
import NetworkCarrierCommercialTermErrorResponseBody
  from "../../entities/carrier-commercial-terms/response-bodies/errors/NetworkCarrierCommercialTermErrorResponseBody"
import NetworkCarrierCommercialTermsRequestFilter
  from "../../entities/carrier-commercial-terms/request-queries/NetworkCarrierCommercialTermsRequestFilter"
import NetworkCarrierCommercialTermRequestBody
  from "../../entities/carrier-commercial-terms/request-bodies/NetworkCarrierCommercialTermRequestBody"
import NetworkCarrierCommercialTerm from "../../entities/carrier-commercial-terms/NetworkCarrierCommercialTerm"
import NetworkCarrierCommercialTermsRequestQuery
  from "../../entities/carrier-commercial-terms/request-queries/NetworkCarrierCommercialTermsRequestQuery"
import NetworkCarrierCommercialTermsResponseBody
  from "../../entities/carrier-commercial-terms/response-bodies/NetworkCarrierCommercialTermsResponseBody"

const basePath = "/api/admin/carrier_commercial_terms"

export default class CarrierCommercialTermsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getCarrierCommercialTerms({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkCarrierCommercialTermsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetCarrierCommercialTermsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkCarrierCommercialTermsRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierCommercialTermsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getCarrierCommercialTerm({
    carrierCommercialTermId
  }: {
    readonly carrierCommercialTermId: number
  }): Promise<GetCarrierCommercialTermNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${carrierCommercialTermId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierCommercialTermResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createCarrierCommercialTerm({
    carrierCommercialTerm
  }: {
    readonly carrierCommercialTerm?: NetworkCarrierCommercialTerm | null
  }): Promise<CreateCarrierCommercialTermNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkCarrierCommercialTermRequestBody({
        carrierCommercialTerm
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierCommercialTermResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkCarrierCommercialTermErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateCarrierCommercialTerm({
    carrierCommercialTermId,
    carrierCommercialTerm
  }: {
    readonly carrierCommercialTermId: number
    readonly carrierCommercialTerm?: NetworkCarrierCommercialTerm | null
  }): Promise<UpdateCarrierCommercialTermNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${carrierCommercialTermId}`,
      body: instanceToPlain(new NetworkCarrierCommercialTermRequestBody({
        carrierCommercialTerm
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierCommercialTermResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkCarrierCommercialTermErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCarrierCommercialTermsNetworkResult =
  SuccessExecutionResult<NetworkCarrierCommercialTermsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetCarrierCommercialTermNetworkResult =
  SuccessExecutionResult<NetworkCarrierCommercialTermResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateCarrierCommercialTermNetworkResult =
  SuccessExecutionResult<NetworkCarrierCommercialTermResponseBody> |
  ErrorExecutionResult<NetworkCarrierCommercialTermErrorResponseBody> |
  FailureExecutionResult

export type UpdateCarrierCommercialTermNetworkResult =
  SuccessExecutionResult<NetworkCarrierCommercialTermResponseBody> |
  ErrorExecutionResult<NetworkCarrierCommercialTermErrorResponseBody> |
  FailureExecutionResult
