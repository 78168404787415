import BackendHttpClient from "../../../../../core/data/network/BackendHttpClient"
import {
  AuthorizationErrorEventCallback
} from "../../../../../../admin/features/root/domain/entities/AuthorizationErrorEvent"

export default class AuthorizationErrorNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  subscribeToAuthorizationError(callback: AuthorizationErrorEventCallback) {
    this.backendHttpClient.subscribeToAuthorizationError(callback)
  }
}
