import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelCompanyUsersRepository from "../../repositories/FuelCompanyUsersRepository"
import User from "../../../../../core/domain/users/User"

export interface GetFuelCompanyUserParameters {
  readonly objectId: string
}

export default class GetFuelCompanyUserUseCase implements GetObjectUseCase<User> {
  private readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository

  constructor(parameters: {
    readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository
  }) {
    this.fuelCompanyUsersRepository = parameters.fuelCompanyUsersRepository
  }

  async call(parameters: GetFuelCompanyUserParameters): Promise<GetObjectResult<User>> {
    return await this.fuelCompanyUsersRepository.getUser(parameters)
  }
}
