import DriversRepository from "../../domain/repositories/DriversRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultDriversRepository from "../../data/repositories/DefaultDriversRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForDriverRepository from "../../domain/repositories/CarriersForDriverRepository"
import DefaultCarriersForDriverRepository from "../../data/repositories/DefaultCarriersForDriverRepository"
import GetCarriersForDriverUseCase from "../../domain/use-cases/carriers/GetCarriersForDriverUseCase"

export default interface DriversDataDiModule {
  provideDriversRepository(): DriversRepository

  provideCarriersForDriverRepository(): CarriersForDriverRepository
}

export class DefaultDriversDataDiModule extends DiModule implements DriversDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideDriversRepository(): DriversRepository {
    return this.single(
      DefaultDriversRepository.name,
      () => new DefaultDriversRepository({
        driversNetworkSource: this.appDataDiModule.provideDriversNetworkSource()
      })
    )
  }

  provideCarriersForDriverRepository(): CarriersForDriverRepository {
    return this.single(
      GetCarriersForDriverUseCase.name,
      () => new DefaultCarriersForDriverRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }
}
