import Presenter from "../../../../../lib/presenter/Presenter"
import PasswordRecoveryView from "./PasswordRecoveryView"
import IsSessionExistUseCase from "../../../../../core/domain/use-cases/sessions/IsSessionExistUseCase"
import assertNever from "../../../../../lib/assertNever"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import autoBind from "auto-bind"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import CheckTokenUseCase, { CheckTokenResult } from "../../../domain/use-cases/check-token/CheckTokenUseCase"
import RecoveryPasswordUseCase, {
  RecoveryPasswordResult
} from "../../../domain/use-cases/password-recovery/RecoveryPasswordUseCase"

export default class PasswordRecoveryPresenter extends Presenter<PasswordRecoveryView> {
  private readonly isSessionExistUseCase: IsSessionExistUseCase
  private readonly checkTokenUseCase: CheckTokenUseCase
  private readonly recoveryPasswordUseCase: RecoveryPasswordUseCase
  private readonly token: string
  private password: string
  private passwordConfirmation: string
  private isPasswordRecovering: boolean
  private passwordRecoveryError?: ExecutionError
  private passwordRecoveryFailureException?: ApplicationException

  constructor(parameters: {
    readonly token?: string | null
    readonly isSessionExistUseCase: IsSessionExistUseCase
    readonly checkTokenUseCase: CheckTokenUseCase
    readonly recoveryPasswordUseCase: RecoveryPasswordUseCase
  }) {
    super()

    autoBind(this)

    this.isSessionExistUseCase = parameters.isSessionExistUseCase
    this.checkTokenUseCase = parameters.checkTokenUseCase
    this.recoveryPasswordUseCase = parameters.recoveryPasswordUseCase
    this.token = parameters.token ?? ""
    this.password = ""
    this.passwordConfirmation = ""
    this.isPasswordRecovering = false
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()
    this.checkIsCurrentUserAuthenticatedAndShowViewState()
  }

  onPasswordChanged(password: string) {
    this.setPassword(password)
    this.showNotAuthenticatedPasswordRecoveryViewState()
  }

  onPasswordConfirmationChanged(passwordConfirmation: string) {
    this.setPasswordConfirmation(passwordConfirmation)
    this.showNotAuthenticatedPasswordRecoveryViewState()
  }

  onRecoveryPasswordButtonClicked() {
    this.recoveryPasswordAndShowViewState().then()
  }

  private checkIsCurrentUserAuthenticatedAndShowViewState() {
    const isSessionExist: boolean = this.isSessionExistUseCase.call()

    if (isSessionExist) {
      this.showAlreadyAuthenticatedPasswordRecoveryViewState()
    } else {
      this.checkTokenAndShowViewState().then()
    }
  }

  private async checkTokenAndShowViewState() {
    const checkTokenResult: CheckTokenResult = await this.checkTokenUseCase.call({ token: this.token })

    switch (checkTokenResult.type) {
      case "success":
        this.showNotAuthenticatedPasswordRecoveryViewState()
        break
      case "error":
        this.setPasswordRecoveryError(checkTokenResult.error)
        this.showNotAuthenticatedPasswordRecoveryViewState()
        break
      case "failure":
        this.setPasswordRecoveryFailureException(checkTokenResult.exception)
        this.showNotAuthenticatedPasswordRecoveryViewState()
        break
      default:
        assertNever(checkTokenResult)
    }
  }

  private async recoveryPasswordAndShowViewState(): Promise<void> {
    this.setIsPasswordRecovering(true)
    this.setPasswordRecoveryError(undefined)
    this.setPasswordRecoveryFailureException(undefined)
    this.showNotAuthenticatedPasswordRecoveryViewState()

    const recoveryPasswordResult: RecoveryPasswordResult =
      await this.recoveryPasswordUseCase.call({
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.token
      })

    this.setIsPasswordRecovering(false)

    switch (recoveryPasswordResult.type) {
      case "success":
        this.showPasswordRecoveredViewState(recoveryPasswordResult.data.message)
        break
      case "error":
        this.setPasswordRecoveryError(recoveryPasswordResult.error)
        this.showNotAuthenticatedPasswordRecoveryViewState()
        break
      case "failure":
        this.setPasswordRecoveryFailureException(recoveryPasswordResult.exception)
        this.showNotAuthenticatedPasswordRecoveryViewState()
        break
      default:
        assertNever(recoveryPasswordResult)
    }
  }

  private setPassword(password: string) {
    this.password = password
    this.passwordRecoveryError = undefined
  }

  private setPasswordConfirmation(passwordConfirmation: string) {
    this.passwordConfirmation = passwordConfirmation
    this.passwordRecoveryError = undefined
  }

  private setIsPasswordRecovering(isRecovering: boolean) {
    this.isPasswordRecovering = isRecovering
  }

  private setPasswordRecoveryError(error: ExecutionError | undefined) {
    this.passwordRecoveryError = error
  }

  private setPasswordRecoveryFailureException(failureException?: ApplicationException | undefined) {
    this.passwordRecoveryFailureException = failureException
  }

  private showAlreadyAuthenticatedPasswordRecoveryViewState() {
    this.getView()?.showPasswordRecoveryViewState({
        type: "already_authenticated"
      })
  }

  private showNotAuthenticatedPasswordRecoveryViewState() {
    this.getView()?.showPasswordRecoveryViewState({
        type: "not_authenticated",
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        isPasswordRecovering: this.isPasswordRecovering,
        passwordRecoveryError: this.passwordRecoveryError,
        passwordRecoveryFailureException: this.passwordRecoveryFailureException
      })
  }

  private showPasswordRecoveredViewState(successMessage: string | null | undefined) {
    this.getView()?.showPasswordRecoveryViewState({
        type: "password_recovered",
        successMessage: successMessage
      })
  }
}
