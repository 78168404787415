import NetworkUserProfile from "../entities/user-profiles/NetworkUserProfile"
import UserProfile from "../../domain/user-profiles/UserProfile"

export default class UserProfilesMapper {
  mapNetworkToDomain({
    profile
  }: {
    readonly profile: NetworkUserProfile
  }): UserProfile {
    return {
      id: profile.id,
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
      birthdate: profile.birthdate,
      driverLicenseNumber: profile.driverLicenseNumber
    }
  }

  mapDomainToNetwork({
    profile
  }: {
    readonly profile: UserProfile
  }): NetworkUserProfile {
    return {
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
      birthdate: profile.birthdate,
      driverLicenseNumber: profile.driverLicenseNumber
    }
  }
}
