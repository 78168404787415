import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"
import NetworkRefuellingOrdersReportsRequestFilter from "./NetworkRefuellingOrdersReportsRequestFilter"

export default class NetworkRefuellingOrdersReportsRequestQuery {
  @Expose()
  @Type(() => NetworkRefuellingOrdersReportsRequestFilter)
  readonly filter?: NetworkRefuellingOrdersReportsRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  @Type(() => String)
  readonly sort?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly needPagination?: boolean | null

  constructor(parameters: {
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
    readonly needPagination?: boolean | null
    readonly filter?: NetworkRefuellingOrdersReportsRequestFilter | null
  }) {
    this.pagination = parameters.pagination
    this.sort = parameters.sort
    this.needPagination = parameters.needPagination
    this.filter = parameters.filter
  }
}
