import NetworkCarrierBalance from "../carrier-balances/NetworkCarrierBalance"
import { Expose, Type } from "class-transformer"
import NetworkLegalEntity from "../legal-entities/NetworkLegalEntity"
import NetworkContactPerson from "../contact-people/NetworkContactPerson"

export default class NetworkCarrier {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly code?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly blocked?: boolean | null

  @Expose()
  @Type(() => NetworkLegalEntity)
  readonly legalEntity?: NetworkLegalEntity | null

  @Expose()
  @Type(() => NetworkContactPerson)
  readonly contactPerson?: NetworkContactPerson | null

  @Expose()
  @Type(() => NetworkCarrierBalance)
  readonly balance?: NetworkCarrierBalance | null
}
