import NetworkFuelCompanyBalanceChangeDocumentErrorsObjectAttributes from "./NetworkFuelCompanyBalanceChangeDocumentErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import FuelCompanyBalanceChangeDocumentErrorsObject
  from "../../../../../domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentErrorsObject"

export default class NetworkFuelCompanyBalanceChangeDocumentErrorsObject
  implements FuelCompanyBalanceChangeDocumentErrorsObject {
  @Expose()
  @Type(() => NetworkFuelCompanyBalanceChangeDocumentErrorsObjectAttributes)
  readonly attributes?: NetworkFuelCompanyBalanceChangeDocumentErrorsObjectAttributes | null
}
