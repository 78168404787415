import CurrentFuelCompanyBalanceTransactionsRepository
  from "../../domain/repositories/CurrentFuelCompanyBalanceTransactionsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForCurrentFuelCompanyBalancesRepository
  from "../../domain/repositories/FuelCompaniesForCurrentFuelCompanyBalancesRepository"
import DefaultFuelCompaniesForCurrentFuelCompanyBalancesRepository
  from "../../data/repositories/DefaultFuelCompaniesForCurrentFuelCompanyBalancesRepository"
import DefaultCurrentFuelCompanyBalanceTransactionsRepository
  from "../../data/repositories/DefaultCurrentFuelCompanyBalanceTransactionsRepository"
import CurrentFuelCompanyBalanceChangeDocumentsRepository
  from "../../domain/repositories/CurrentFuelCompanyBalanceChangeDocumentsRepository"
import DefaultCurrentFuelCompanyBalanceChangeDocumentsRepository
  from "../../data/repositories/DefaultCurrentFuelCompanyBalanceChangeDocumentsRepository"

export default interface CurrentFuelCompanyBalancesDataDiModule {
  provideCurrentFuelCompanyBalanceTransactionsRepository(): CurrentFuelCompanyBalanceTransactionsRepository

  provideCurrentFuelCompanyBalanceChangeDocumentsRepository(): CurrentFuelCompanyBalanceChangeDocumentsRepository

  provideFuelCompaniesForCurrentFuelCompanyBalancesRepository(): FuelCompaniesForCurrentFuelCompanyBalancesRepository
}

export class DefaultCurrentFuelCompanyBalancesDataDiModule
  extends DiModule
  implements CurrentFuelCompanyBalancesDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCurrentFuelCompanyBalanceTransactionsRepository(): CurrentFuelCompanyBalanceTransactionsRepository {
    return this.single(
      DefaultCurrentFuelCompanyBalanceTransactionsRepository.name,
      () => new DefaultCurrentFuelCompanyBalanceTransactionsRepository({
        currentFuelCompanyBalanceTransactionsNetworkSource:
          this.appDataDiModule.provideCurrentFuelCompanyBalanceTransactionsNetworkSource()
      })
    )
  }

  provideCurrentFuelCompanyBalanceChangeDocumentsRepository(): CurrentFuelCompanyBalanceChangeDocumentsRepository {
    return this.single(
      DefaultCurrentFuelCompanyBalanceChangeDocumentsRepository.name,
      () => new DefaultCurrentFuelCompanyBalanceChangeDocumentsRepository({
        currentFuelCompanyBalanceChangeDocumentsNetworkSource:
          this.appDataDiModule.provideCurrentFuelCompanyBalanceChangeDocumentsNetworkSource()
      })
    )
  }

  provideFuelCompaniesForCurrentFuelCompanyBalancesRepository(): FuelCompaniesForCurrentFuelCompanyBalancesRepository {
    return this.single(
      DefaultFuelCompaniesForCurrentFuelCompanyBalancesRepository.name,
      () => new DefaultFuelCompaniesForCurrentFuelCompanyBalancesRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }
}
