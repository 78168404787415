import GetUsersUseCase from "../../domain/use-cases/users/GetUsersUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import UsersDataDiModule from "./UsersDataDiModule"
import CreateUserUseCase from "../../domain/use-cases/users/CreateUserUseCase"
import GetUserUseCase from "../../domain/use-cases/users/GetUserUseCase"
import UpdateUserUseCase from "../../domain/use-cases/users/UpdateUserUseCase"

export default interface UsersDomainDiModule {
  provideGetUsersUseCase(): GetUsersUseCase

  provideGetUserUseCase(): GetUserUseCase

  provideCreateUserUseCase(): CreateUserUseCase

  provideUpdateUsersUseCase(): UpdateUserUseCase
}

export class DefaultUsersDomainDiModule extends DiModule implements UsersDomainDiModule {
  private readonly usersDataDiModule: UsersDataDiModule

  constructor(parameters: {
    readonly usersDataDiModule: UsersDataDiModule
  }) {
    super()
    this.usersDataDiModule = parameters.usersDataDiModule
  }

  provideGetUsersUseCase(): GetUsersUseCase {
    return this.single(
      GetUsersUseCase.name,
      () => new GetUsersUseCase({
        usersRepository: this.usersDataDiModule.provideUsersRepository()
      })
    )
  }

  provideGetUserUseCase(): GetUserUseCase {
    return this.single(
      GetUserUseCase.name,
      () => new GetUserUseCase({
        usersRepository: this.usersDataDiModule.provideUsersRepository()
      })
    )
  }

  provideCreateUserUseCase(): CreateUserUseCase {
    return this.single(
      CreateUserUseCase.name,
      () => new CreateUserUseCase({
        usersRepository: this.usersDataDiModule.provideUsersRepository()
      })
    )
  }

  provideUpdateUsersUseCase(): UpdateUserUseCase {
    return this.single(
      UpdateUserUseCase.name,
      () => new UpdateUserUseCase({
        usersRepository: this.usersDataDiModule.provideUsersRepository()
      })
    )
  }
}
