import { Expose, Transform, Type } from "class-transformer"
import NetworkCarrier from "../carriers/NetworkCarrier"
import NetworkTransport from "../transports/NetworkTransport"
import TripStatus from "../../../domain/trips/TripStatus"
import NetworkUserTrip from "../user-trips/NetworkUserTrip"
import NetworkTripLimit from "../trip-limits/NetworkTripLimit"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"

export default class NetworkTrip {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly status?: TripStatus | null

  @Expose()
  @Type(() => String)
  readonly note?: string | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null

  @Expose()
  @Type(() => Number)
  readonly transportId?: number | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly transport?: NetworkTransport | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly startingAt?: Date | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly finishingAt?: Date | null

  @Expose()
  @Type(() => NetworkUserTrip)
  readonly userTrips?: NetworkUserTrip[] | null

  @Expose()
  @Type(() => Number)
  readonly driverIds?: number[] | null

  @Expose()
  @Type(() => NetworkTripLimit)
  readonly limits?: NetworkTripLimit[] | null
}
