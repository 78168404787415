import GasStationBreaksRepository from "../../repositories/GasStationBreaksRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import GasStationBreak from "../../../../../core/domain/gas-station-breaks/GasStationBreak"
import GasStationBreakError from "../../../../../core/domain/gas-station-breaks/GasStationBreakError"

export interface UpdateGasStationBreakParameters {
  readonly objectId: string
  readonly object: GasStationBreak
}

export default class UpdateGasStationBreakUseCase implements UpdateObjectUseCase<
  GasStationBreak, GasStationBreakError
> {
  private readonly gasStationBreaksRepository: GasStationBreaksRepository

  constructor(parameters: {
    readonly gasStationBreaksRepository: GasStationBreaksRepository
  }) {
    this.gasStationBreaksRepository = parameters.gasStationBreaksRepository
  }

  async call(
    parameters: UpdateGasStationBreakParameters
  ): Promise<UpdateObjectResult<GasStationBreak, GasStationBreakError>> {
    return await this.gasStationBreaksRepository.updateGasStationBreak(parameters)
  }
}
