import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import Carrier from "../../../../core/domain/carriers/Carrier"
import FuelsNetworkSource from "../../../../core/data/sources/network/FuelsNetworkSource"
import NetworkFuelsRequestFilter from "../../../../core/data/entities/fuels/request-queries/NetworkFuelsRequestFilter"
import FuelsMapper from "../../../../core/data/mappers/FuelsMapper"
import FuelsForTripLimitsRepository from "../../domain/repositories/FuelsForTripLimitsRepository"
import { GetFuelsForTripLimitsParameters } from "../../domain/use-cases/fuels/GetFuelsForTripLimitsUseCase"

export default class DefaultFuelsForTripLimitsRepository
  implements FuelsForTripLimitsRepository {
  private readonly fuelsNetworkSource: FuelsNetworkSource

  constructor(parameters: {
    readonly fuelsNetworkSource: FuelsNetworkSource
  }) {
    this.fuelsNetworkSource = parameters.fuelsNetworkSource
  }

  async getFuels({
    ids,
    query,
    pagination,
    sort
  }: GetFuelsForTripLimitsParameters): Promise<GetObjectsResult<Carrier>> {
    const result = await this.fuelsNetworkSource.getFuels({
      filter: new NetworkFuelsRequestFilter({
        query,
        ids
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const fuelsMapper = new FuelsMapper()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.fuels!.map((fuel) => {
              return fuelsMapper.mapNetworkToDomain({ fuel })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
