import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import TripChangingLogI18 from "../../i18n/TripChangingLogI18"
import ChangingLogItem from "../../../../core/domain/changing-log-items/ChangingLogItem"
import AppI18 from "../../../../core/i18n/AppI18"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import ChangingLogItemsFilter from "../../../../core/domain/changing-log-items/ChangingLogItemsFilter"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import ChangingType, { ChangingTypeSelectOption } from "../../../../core/domain/changing-log-items/ChangingType"
import TripLimitTargetType from "../../../../core/domain/trip-limits/TripLimitTargetType"
import DateTimeFormatter from "../../../../../admin/lib/DateTimeFormatter"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import isPresent from "../../../../../admin/lib/isPresent"
import isBlank from "../../../../../admin/lib/isBlank"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import GetCarriersForTripChangingLogUseCase from "../../domain/use-cases/carriers/GetCarriersForTripChangingLogUseCase"
import GetTripsForTripChangingLogUseCase from "../../domain/use-cases/trips/GetTripsForTripChangingLogUseCase"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Trip from "../../../../core/domain/trips/Trip"
import Carrier from "../../../../core/domain/carriers/Carrier"
import TripsFilter from "../../../../core/domain/trips/TripsFilter"
import GetChangingTypesForTripChangingLogUseCase
  from "../../domain/use-cases/changing-types/GetChangingTypesForTripChangingLogUseCase"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import TripStatus from "../../../../core/domain/trips/TripStatus"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const userTripObjectType = "userTrip"
const targetTypeColumnName = "targetType"
const fuelIdColumnName = "fuelId"
const startingAtColumnName = "startingAt"
const finishingAtColumnName = "finishingAt"
const tripLimitTypeIdColumnName = "tripLimitTypeId"
const changedByIdFilterName = "changedById"

export default class TripsChangingLogTableProvider implements TableProvider<ChangingLogItem, ChangingLogItemsFilter> {
  private readonly timeZone: string
  private readonly coreI18n: CoreI18n
  private readonly appI18: AppI18
  private readonly tripChangingLogI18: TripChangingLogI18
  private readonly getCarriersUseCase: GetCarriersForTripChangingLogUseCase
  private readonly getTripsUseCase: GetTripsForTripChangingLogUseCase
  private readonly getChangingTypesUseCase: GetChangingTypesForTripChangingLogUseCase

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18n: CoreI18n
    readonly appI18: AppI18
    readonly tripChangingLogI18: TripChangingLogI18
    readonly getCarriersUseCase: GetCarriersForTripChangingLogUseCase
    readonly getTripsUseCase: GetTripsForTripChangingLogUseCase
    readonly getChangingTypesUseCase: GetChangingTypesForTripChangingLogUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.coreI18n = parameters.coreI18n
    this.appI18 = parameters.appI18
    this.tripChangingLogI18 = parameters.tripChangingLogI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getTripsUseCase = parameters.getTripsUseCase
    this.getChangingTypesUseCase = parameters.getChangingTypesUseCase
  }

  getEntity(): string {
    return Entity.CHANGING_LOG_ITEMS
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<ChangingLogItem, ChangingLogItemsFilter> {
    const coreTextProvider = this.coreI18n.getTextProvider()
    const appUrlProvider = new AppUrlProvider()
    const appTextProvider = this.appI18.getTextProvider()
    const tripChangingLogTextProvider = this.tripChangingLogI18.getTextProvider()

    return new Table<ChangingLogItem, ChangingLogItemsFilter>({
      title: tripChangingLogTextProvider.listTitle(),
      getObjectId: (tripChangingLogItem: ChangingLogItem) => tripChangingLogItem.id!.toString(),
      getRowUrl: () => "",
      columns: [
        new TableColumn<ChangingLogItem>({
          name: "id",
          title: tripChangingLogTextProvider.idField(),
          createValue: (changingLogItem: ChangingLogItem) => new TextTableValue({
            value: `${changingLogItem.id!}`
          })
        }),
        new TableColumn<ChangingLogItem>({
          name: "parentTrip",
          entity: Entity.TRIPS,
          title: tripChangingLogTextProvider.parentTripField(),
          createValue: (changingLogItem: ChangingLogItem) => new TextTableValue({
            value: tripChangingLogTextProvider.tripNumber({
              tripNumber: changingLogItem.parentId!
            }),
            url: appUrlProvider.buildShowTripUrl({
              tripId: changingLogItem.parentId!
            })
          })
        }),
        new TableColumn<ChangingLogItem>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: tripChangingLogTextProvider.carrierField(),
          createValue: (changingLogItem: ChangingLogItem) => new TextTableValue({
            value: changingLogItem.parentTrip?.carrier?.name,
            url: (() => {
              const carrier = changingLogItem.parentTrip?.carrier
              if (isBlank(carrier)) return undefined

              return appUrlProvider.buildShowCarrierUrl({
                carrierId: carrier.id!
              })
            })()
          })
        }),
        new TableColumn<ChangingLogItem>({
          name: "action",
          title: tripChangingLogTextProvider.actionField(),
          createValue: (changingLogItem: ChangingLogItem) => new TextTableValue({
            value: (() => {
              switch (changingLogItem.changingType) {
                case ChangingType.CREATED: {
                  if (changingLogItem.objectType === userTripObjectType) {
                    return tripChangingLogTextProvider.driverAssignedAction({
                      objectName: changingLogItem.objectName!
                    })
                  }

                  return tripChangingLogTextProvider.createdAction({
                    objectType: changingLogItem.objectType!,
                    objectName: changingLogItem.objectName!
                  })
                }
                case ChangingType.DELETED: {
                  if (changingLogItem.objectType === userTripObjectType) {
                    return tripChangingLogTextProvider.driverTakenOffAction({
                      objectName: changingLogItem.objectName!
                    })
                  }

                  return tripChangingLogTextProvider.deletedAction({
                    objectName: changingLogItem.objectName!
                  })
                }
                case ChangingType.UPDATED: {
                  const columnName = changingLogItem.columnName!
                  const oldValue = this.displayValue({
                    columnName: columnName,
                    valueName: changingLogItem.oldValueName,
                    value: changingLogItem.oldValue
                  }) ?? ""

                  const newValue = this.displayValue({
                    columnName: columnName,
                    valueName: changingLogItem.newValueName,
                    value: changingLogItem.newValue
                  }) ?? ""

                  return tripChangingLogTextProvider.columnValueChangedAction({
                    objectType: changingLogItem.objectType!,
                    objectName: changingLogItem.objectName!,
                    columnName: columnName,
                    oldValue: this.formatValueByColumn(columnName, oldValue),
                    newValue: this.formatValueByColumn(columnName, newValue)
                  })
                }
              }

              return ""
            })()
          })
        }),
        new TableColumn<ChangingLogItem>({
          name: "changedBy",
          title: tripChangingLogTextProvider.changedByField(),
          createValue: (changingLogItem: ChangingLogItem) => new TextTableValue({
            value: appTextProvider.userName({
              user: changingLogItem.changedBy
            })
          })
        }),
        new TableColumn<ChangingLogItem>({
          name: "createdAt",
          title: tripChangingLogTextProvider.createdAtField(),
          createValue: (changingLogItem: ChangingLogItem) => new DateTableValue({
            value: changingLogItem.createdAt,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        })
      ],
      filter: new Filter<ChangingLogItemsFilter>({
        buildFilterObject: (filterValueByFilterId): ChangingLogItemsFilter => {
          const changedByIdFilterValue = filterValueByFilterId?.[changedByIdFilterName]
          const changedById = isPresent(changedByIdFilterValue) ? parseInt(changedByIdFilterValue) : null
          return {
            changedById: changedById
          }
        },
        fields: [
          new SingleSelectFormField<ChangingLogItemsFilter, void, ChangingTypeSelectOption>({
            title: tripChangingLogTextProvider.changingTypeFilter(),
            groupName: "",
            getId: () => "changingType",
            searchingEnabled: false,
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getValue: (filter: ChangingLogItemsFilter) => filter.changingTypeSelectOption,
            setValue: (
              filter: ChangingLogItemsFilter,
              changingTypeSelectOption: ChangingTypeSelectOption | null
            ): ChangingLogItemsFilter => ({
              ...filter,
              changingTypeSelectOption,
              changingType: changingTypeSelectOption?.id as ChangingType
            }),
            getObjectsUseCase: this.getChangingTypesUseCase,
            getOptionId: (optionObject: ChangingTypeSelectOption) => optionObject.id!.toString(),
            getOptionText: (optionObject: ChangingTypeSelectOption) => optionObject.text
          }),
          new SingleSelectFormField<ChangingLogItemsFilter, void, Carrier>({
            title: tripChangingLogTextProvider.carrierFilter(),
            entity: Entity.CARRIERS,
            groupName: "",
            getId: () => "carrierId",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getValue: (filter: ChangingLogItemsFilter) => filter.carrier,
            setValue: (
              filter: ChangingLogItemsFilter,
              carrier: Carrier | null
            ): ChangingLogItemsFilter => ({
              ...filter,
              carrier,
              carrierId: carrier?.id,
              trip: null,
              tripId: null
            }),
            getObjectsUseCase: this.getCarriersUseCase,
            getOptionId: (optionObject: Carrier) => optionObject.id!.toString(),
            getOptionText: (optionObject: Carrier) => optionObject.name
          }),
          new SingleSelectFormField<ChangingLogItemsFilter, void, Trip, TripsFilter>({
            title: tripChangingLogTextProvider.tripFilter(),
            entity: Entity.TRIPS,
            groupName: "",
            getId: () => "tripId",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getOptionObjectsFilter: (tripsFilter: TripsFilter) => ({
              carrier: tripsFilter.carrier,
              carrierId: tripsFilter.carrierId
            }),
            getValue: (filter: ChangingLogItemsFilter) => filter.trip,
            setValue: (
              filter: ChangingLogItemsFilter,
              trip: Trip | null
            ): ChangingLogItemsFilter => ({
              ...filter,
              trip,
              tripId: trip?.id,
              carrier: isPresent(trip) ? trip?.carrier : filter.carrier,
              carrierId: isPresent(trip) ? trip?.carrierId : filter.carrierId
            }),
            getObjectsUseCase: this.getTripsUseCase,
            getOptionId: (optionObject: Trip) => optionObject.id!.toString(),
            getOptionText: (optionObject: Trip) => {
              const tripName = tripChangingLogTextProvider.tripNumber({
                tripNumber: optionObject.id!
              })

              return `${tripName} - ${optionObject.name}`
            }
          }),
          new DateFormField<ChangingLogItem, never>({
            title: tripChangingLogTextProvider.startDateFilter(),
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "startDate",
            getValue: (changingLogItemsFilter: ChangingLogItemsFilter) => changingLogItemsFilter.startDate,
            setValue: (
              changingLogItemsFilter: ChangingLogItemsFilter,
              startDate: Date | null | undefined
            ): ChangingLogItemsFilter => ({
              ...changingLogItemsFilter, startDate
            })
          }),
          new DateFormField<ChangingLogItem, never>({
            title: tripChangingLogTextProvider.endDateFilter(),
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "endDate",
            getValue: (changingLogItemsFilter: ChangingLogItemsFilter) => changingLogItemsFilter.endDate,
            setValue: (
              changingLogItemsFilter: ChangingLogItemsFilter,
              endDate: Date | null | undefined
            ): ChangingLogItemsFilter => ({
              ...changingLogItemsFilter, endDate
            })
          })
        ]
      })
    })
  }

  private displayValue({
    columnName,
    value,
    valueName
  }: {
    readonly columnName: string
    readonly value: string | undefined | null
    readonly valueName: string | undefined | null
  }): string | undefined | null {
    const appTextProvider = this.appI18.getTextProvider()

    if (columnName === tripLimitTypeIdColumnName) {
      const targetTypeValue = valueName as TripLimitTargetType
      switch (targetTypeValue) {
        case TripLimitTargetType.REFUELLING_BY_MONEY:
          return appTextProvider.tripLimitTargetTypeRefuellingByMoneyText()
        case TripLimitTargetType.REFUELLING_BY_FUEL_TYPE:
          return appTextProvider.tripLimitTargetTypeRefuellingByFuelText()
        case TripLimitTargetType.DAILY_BY_MONEY:
          return appTextProvider.tripLimitTargetTypeDailyByMoneyText()
        case TripLimitTargetType.DAILY_BY_FUEL_TYPE:
          return appTextProvider.tripLimitTargetTypeDailyByFuelTypeText()
        case TripLimitTargetType.TRIP_BY_MONEY:
          return appTextProvider.tripLimitTargetTypeTripByMoneyText()
        case TripLimitTargetType.TRIP_BY_FUEL_TYPE:
          return appTextProvider.tripLimitTargetTypeTripByFuelText()
        default:
          return ""
      }
    }

    if (columnName === fuelIdColumnName) {
      return valueName
    }

    if (columnName === startingAtColumnName || columnName === finishingAtColumnName) {
      const date = new Date(Date.parse(value!))
      return new DateTimeFormatter(date).formatDate()
    }

    return value
  }

  private formatValueByColumn(columnName: string, value: string): string {
    if (columnName !== "status") return value

    switch (value) {
      case TripStatus.PENDING:
        return this.appI18.getTextProvider().tripStatusPendingText()
          .toLowerCase()
      case TripStatus.CONFIRMED:
        return this.appI18.getTextProvider().tripStatusConfirmedText()
          .toLowerCase()
      case TripStatus.FINISHED:
        return this.appI18.getTextProvider().tripStatusFinishedText()
          .toLowerCase()
      case TripStatus.CANCELED:
        return this.appI18.getTextProvider().tripStatusCanceledText()
          .toLowerCase()
      default:
        return value
    }
  }
}
