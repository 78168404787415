import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkRefuellingOrdersRequestFilter
  from "../../entities/refuelling-orders/request-queries/NetworkRefuellingOrdersRequestFilter"
import NetworkRefuellingOrdersResponseBody
  from "../../entities/refuelling-orders/response-bodies/NetworkRefuellingOrdersResponseBody"
import NetworkRefuellingOrderResponseBody
  from "../../entities/refuelling-orders/response-bodies/NetworkRefuellingOrderResponseBody"
import NetworkRefuellingOrderErrorResponseBody
  from "../../entities/refuelling-orders/response-bodies/errors/NetworkRefuellingOrderErrorResponseBody"
import NetworkRefuellingOrderRequestBody
  from "../../entities/refuelling-orders/request-bodies/NetworkRefuellingOrderRequestBody"
import NetworkRefuellingOrder from "../../entities/refuelling-orders/NetworkRefuellingOrder"
import NetworkRefuellingOrdersRequestQuery
  from "../../entities/refuelling-orders/request-queries/NetworkRefuellingOrdersRequestQuery"

const basePath = "/api/admin/refuelling_orders"

export default class RefuellingOrdersNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getRefuellingOrders({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkRefuellingOrdersRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetRefuellingOrdersNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkRefuellingOrdersRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkRefuellingOrdersResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getRefuellingOrder({
    refuellingOrderId
  }: {
    readonly refuellingOrderId: number
  }): Promise<GetRefuellingOrderNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${refuellingOrderId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkRefuellingOrderResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateRefuellingOrder({
    refuellingOrderId,
    refuellingOrder
  }: {
    readonly refuellingOrderId: number
    readonly refuellingOrder?: NetworkRefuellingOrder | null
  }): Promise<UpdateRefuellingOrderNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${refuellingOrderId}`,
      body: instanceToPlain(new NetworkRefuellingOrderRequestBody({
        refuellingOrder
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkRefuellingOrderResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkRefuellingOrderErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetRefuellingOrdersNetworkResult =
  SuccessExecutionResult<NetworkRefuellingOrdersResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetRefuellingOrderNetworkResult =
  SuccessExecutionResult<NetworkRefuellingOrderResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type UpdateRefuellingOrderNetworkResult =
  SuccessExecutionResult<NetworkRefuellingOrderResponseBody> |
  ErrorExecutionResult<NetworkRefuellingOrderErrorResponseBody> |
  FailureExecutionResult
