import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import CarrierCommercialTermErrorsObjectAttributes
  from "../../../../../domain/carrier-commercial-terms/CarrierCommercialTermErrorsObjectAttributes"

export default class NetworkCarrierCommercialTermErrorsObjectAttributes
  implements CarrierCommercialTermErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly carrierId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelCompanyId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly gasStationIds?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly beginDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly endDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly pricingType?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly price?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly discountPercentage?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly documentUrl?: NetworkAttributeError[] | null
}
