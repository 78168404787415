import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import TransportsTableProvider from "../../presentation/table-providers/TransportsTableProvider"
import TransportFormProvider from "../../presentation/form-providers/TransportFormProvider"
import TransportInfoProvider from "../../presentation/info-providers/TransportInfoProvider"
import TransportsI18nDiModule from "./TransportsI18nDiModule"
import TransportsDomainDiModule from "./TransportsDomainDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"

const moduleKey = "transports"

export default interface TransportsPresentationDiModule {
  provideTransportsListPage(): React.ReactNode

  provideTransportInfoPage(): React.ReactNode

  provideTransportFormPage(): React.ReactNode
}

export class DefaultTransportsPresentationDiModule
  extends DiModule
  implements TransportsPresentationDiModule {

  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly transportsI18nDiModule: TransportsI18nDiModule
  private readonly transportsDomainDiModule: TransportsDomainDiModule

  constructor(parameters: {
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly transportsI18nDiModule: TransportsI18nDiModule
    readonly transportsDomainDiModule: TransportsDomainDiModule
  }) {
    super()
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.transportsI18nDiModule = parameters.transportsI18nDiModule
    this.transportsDomainDiModule = parameters.transportsDomainDiModule
  }

  provideTransportsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.transportsDomainDiModule.provideGetTransportsUseCase(),
      tableProvider: this.provideTransportsTableProvider()
    })
  }

  private provideTransportsTableProvider(): TransportsTableProvider {
    return this.single(
      TransportsTableProvider.name,
      () => new TransportsTableProvider({
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        transportsI18: this.transportsI18nDiModule.provideTransportsI18n(),
        getCarriersForUseCase: this.transportsDomainDiModule.provideGetCarriersForTransportUseCase()
      })
    )
  }

  provideTransportFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.transportsDomainDiModule.provideGetTransportUseCase(),
      createObjectUseCase: this.transportsDomainDiModule.provideCreateTransportUseCase(),
      updateObjectUseCase: this.transportsDomainDiModule.provideUpdateTransportUseCase(),
      formProvider: this.provideTransportFormProvider()
    })
  }

  private provideTransportFormProvider(): TransportFormProvider {
    return this.single(
      TransportFormProvider.name,
      () => new TransportFormProvider({
        transportsI18: this.transportsI18nDiModule.provideTransportsI18n(),
        getCarriersUseCase: this.transportsDomainDiModule.provideGetCarriersForTransportUseCase(),
        getFuelsUseCase: this.transportsDomainDiModule.provideGetFuelsForTransportsUseCase()
      })
    )
  }

  provideTransportInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.transportsDomainDiModule.provideGetTransportUseCase(),
      infoProvider: this.provideTransportInfoProvider()
    })
  }

  private provideTransportInfoProvider(): TransportInfoProvider {
    return this.single(
      TransportInfoProvider.name,
      () => new TransportInfoProvider({
        transportsI18: this.transportsI18nDiModule.provideTransportsI18n()
      })
    )
  }
}
