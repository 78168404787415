import HttpClient, { Headers, HttpClientResult, HttpRequestType } from "../../../lib/http-client/HttpClient"
import NetworkRequestException from "./NetworkRequestException"
import SessionLocalSource from "../../../../admin/core/data/sources/sessions/SessionLocalSource"
import LocalSession from "../../../../admin/core/data/entities/local/sessions/LocalSession"
import {
  AuthorizationErrorEventCallback
} from "../../../../admin/features/root/domain/entities/AuthorizationErrorEvent"

const unauthorizedCode = 401

export default class BackendHttpClient {
  private readonly httpClient: HttpClient
  private readonly sessionLocalSource: SessionLocalSource
  private callback?: AuthorizationErrorEventCallback

  constructor(parameters: {
    readonly httpClient: HttpClient
    readonly sessionLocalSource: SessionLocalSource
  }) {
    this.httpClient = parameters.httpClient
    this.sessionLocalSource = parameters.sessionLocalSource
  }

  subscribeToAuthorizationError(callback: AuthorizationErrorEventCallback) {
    this.callback = callback
  }

  notifyAuthorizationErrorReceived() {
    if (this.callback) this.callback({ type: "received" })
  }

  async executeRequest({
    type,
    path,
    parameters,
    body
  }: {
    readonly type: HttpRequestType
    readonly path: string
    readonly parameters?: unknown
    readonly body?: object
  }): Promise<BackendHttpClientResult> {
    const localSession: LocalSession | null = this.sessionLocalSource.getSession()
    const sessionHeaders: Headers = localSession ? { "Authorization": `Bearer ${localSession.accessToken}` } : {}
    const httpClientResult: HttpClientResult = await this.httpClient.executeRequest({
      type,
      path,
      parameters,
      body: body && JSON.stringify(body),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...sessionHeaders
      }
    })

    switch (httpClientResult.type) {
      case "http_client_success":
        return {
          type: "success",
          body: JSON.parse(httpClientResult.body),
          status: httpClientResult.status
        }
      case "http_client_error":
        if (httpClientResult.status === unauthorizedCode) {
          this.notifyAuthorizationErrorReceived()
        }

        return {
          type: "error",
          body: JSON.parse(httpClientResult.body),
          status: httpClientResult.status
        }
      case "http_client_failure":
        return {
          type: "failure",
          // TODO: architecture. Map concrete exceptions to concrete exceptions.
          exception: new NetworkRequestException()
        }
    }
  }
}

export interface SuccessBackendHttpClientResult {
  readonly type: "success"
  readonly body?: object | null
  readonly status: number
}

export interface ErrorBackendHttpClientResult {
  readonly type: "error"
  readonly body?: object | null
  readonly status: number
}

export interface FailureBackendHttpClientResult {
  readonly type: "failure"
  readonly exception: NetworkRequestException
}

export type BackendHttpClientResult =
  SuccessBackendHttpClientResult |
  ErrorBackendHttpClientResult |
  FailureBackendHttpClientResult
