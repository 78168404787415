import autoBind from "auto-bind"
import FuelCompanyBalanceChangeDocumentFormProvider from "../../form-providers/FuelCompanyBalanceChangeDocumentFormProvider"
import Presenter from "../../../../../../admin/lib/presenter/Presenter"
import FuelCompanyBalanceChangeDocumentFormView, {
  AbstractFuelCompanyBalanceChangeDocumentFormViewState,
  FuelCompanyBalanceChangeDocumentFormViewState
} from "./FuelCompanyBalanceChangeDocumentFormView"
import BroadcastObjectsEventUseCase
  from "../../../../../../admin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import Form from "../../../../../../admin/features/objects/presentation/entities/forms/Form"
import ApplicationException from "../../../../../../admin/core/domain/exceptions/ApplicationException"
import FormField, {
  FormFieldViewState
} from "../../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import assertNever from "../../../../../../admin/lib/assertNever"
import CheckPermissionDeniedUseCase
  from "../../../../../../admin/core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import FormProviderUtils from "../../../../../../admin/features/objects/presentation/providers/FormProviderUtils"
import CreateFuelCompanyBalanceChangeDocumentUseCase
  from "../../../domain/use-cases/fuel-company-balance-change-documents/CreateFuelCompanyBalanceChangeDocumentUseCase"
import FuelCompanyBalanceChangeDocument
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import FuelCompanyBalanceChangeDocumentError
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentError"
import FuelCompanyBalanceChangeDocumentErrorsObject
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentErrorsObject"

export default class FuelCompanyBalanceChangeDocumentFormPresenter
  extends Presenter<FuelCompanyBalanceChangeDocumentFormView> {

  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly createFuelCompanyBalanceChangeDocumentUseCase:
    CreateFuelCompanyBalanceChangeDocumentUseCase

  private readonly formProviderUtils: FormProviderUtils<
    FuelCompanyBalanceChangeDocument,
    FuelCompanyBalanceChangeDocumentError,
    FuelCompanyBalanceChangeDocumentErrorsObject
  >

  private readonly formProvider: FuelCompanyBalanceChangeDocumentFormProvider
  private form!: Form<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject>
  private title!: string
  private readonly fuelCompanyId!: string | number
  private fuelCompanyBalanceChangeDocument?: FuelCompanyBalanceChangeDocument
  private changingError?: FuelCompanyBalanceChangeDocumentError
  private changingException?: ApplicationException
  private fuelCompanyBalanceChangeDocumentFormViewState?: FuelCompanyBalanceChangeDocumentFormViewState

  constructor(parameters: {
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
    readonly createFuelCompanyBalanceChangeDocumentUseCase: CreateFuelCompanyBalanceChangeDocumentUseCase
    readonly formProvider: FuelCompanyBalanceChangeDocumentFormProvider
    readonly fuelCompanyId: string | number
  }) {
    super()

    autoBind(this)

    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.createFuelCompanyBalanceChangeDocumentUseCase =
      parameters.createFuelCompanyBalanceChangeDocumentUseCase
    this.formProvider = parameters.formProvider
    this.fuelCompanyId = parameters.fuelCompanyId

    this.buildForm()
    this.buildTitles()

    this.formProviderUtils = this.buildFormProviderUtils(parameters.checkPermissionDeniedUseCase)
    this.formProviderUtils.initFormByPermissions()
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    if (this.formProviderUtils.isFormVisibleByPermission()) {
      this.buildAndShowFuelCompanyBalanceChangeDocument().then()
    } else {
      this.setAndShowForbiddenFuelCompanyBalanceChangeDocumentFormViewState()
    }
  }

  protected onViewReAttach() {
    super.onViewReAttach()
    this.showFuelCompanyBalanceChangeDocumentFormViewState()
  }

  onSubmitObjectClicked() {
    this.createFuelCompanyBalanceChangeDocumentAndShowBalance().then()
  }

  onCancelClicked() {
    this.setAndShowCancelingFuelCompanyBalanceChangeDocumentFormViewState()
  }

  private buildForm() {
    this.form = new Form<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject>({
      permissionsSet: {
        canCreate: true,
        canDestroy: false,
        canUpdate: false
      },
      groups: this.buildFieldsGroups(),
      fields: this.buildFormFields()
    })
  }

  private buildTitles() {
    this.title = this.formProvider.getNewObjectTitle()
  }

  private buildFieldsGroups(): FormFieldGroup[] {
    return this.formProvider.getFieldGroups()
  }

  private buildFormFields(): FormField<
    FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject
  >[] {
    const formFields = this.formProvider.getFields()

    formFields.forEach((formField) => {
      formField.setSetObject(this.setFuelCompanyBalanceChangeDocument)
      formField.setSetAndShowLoadedFuelCompanyBalanceChangeDocumentViewState(
        this.setAndShowIdleFuelCompanyBalanceChangeDocumentFormViewState
      )
    })

    return formFields
  }

  private async buildAndShowFuelCompanyBalanceChangeDocument(): Promise<void> {
    const newObject = await this.formProvider.buildObject()
    this.setFuelCompanyBalanceChangeDocument(newObject)
    this.buildTitles()
    this.setAndShowIdleFuelCompanyBalanceChangeDocumentFormViewState()
  }

  private async createFuelCompanyBalanceChangeDocumentAndShowBalance(): Promise<void> {
    this.clearChangingError()
    this.clearChangingException()
    this.setAndShowCreatingFuelCompanyBalanceChangeDocumentFormViewState()

    const result = await this.createFuelCompanyBalanceChangeDocumentUseCase.call({
      object: this.fuelCompanyBalanceChangeDocument!,
      fuelCompanyId: this.fuelCompanyId
    })

    switch (result.type) {
      case "success":
        this.broadcastObjectsEventUseCase.call({ type: "created" })
        this.setAndShowCancelingFuelCompanyBalanceChangeDocumentFormViewState()
        break
      case "error":
        this.setChangingError(result.error)
        this.setAndShowIdleFuelCompanyBalanceChangeDocumentFormViewState()
        break
      case "failure":
        this.setChangingException(result.exception)
        this.setAndShowIdleFuelCompanyBalanceChangeDocumentFormViewState()
        break
      default:
        assertNever(result)
    }
  }

  private buildFieldViewStates(): FormFieldViewState[] {
    const errorsObject = this.formProvider.getErrorsObject({
      error: this.changingError
    }) ?? undefined

    return this.form.getFormFields()
      .map((formField: FormField<
        FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject
      >): FormFieldViewState => {
      return formField.getViewState(this.fuelCompanyBalanceChangeDocument!, errorsObject)
    })
  }

  private setAndShowIdleFuelCompanyBalanceChangeDocumentFormViewState() {
    this.setAndShowFuelCompanyBalanceChangeDocumentFormViewState({
      ...this.buildAbstractFuelCompanyBalanceChangeDocumentFormViewState(),
      type: "idle",
      fieldViewStates: this.buildFieldViewStates(),
      changingError: this.changingError,
      changingException: this.changingException
    })
  }

  private setAndShowCreatingFuelCompanyBalanceChangeDocumentFormViewState() {
    this.setAndShowFuelCompanyBalanceChangeDocumentFormViewState({
      ...this.buildAbstractFuelCompanyBalanceChangeDocumentFormViewState(),
      type: "creating",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setAndShowCancelingFuelCompanyBalanceChangeDocumentFormViewState() {
    this.setAndShowFuelCompanyBalanceChangeDocumentFormViewState({
      ...this.buildAbstractFuelCompanyBalanceChangeDocumentFormViewState(),
      type: "canceling"
    })
  }

  private setAndShowForbiddenFuelCompanyBalanceChangeDocumentFormViewState() {
    this.setAndShowFuelCompanyBalanceChangeDocumentFormViewState({
      type: "forbidden"
    })
  }

  private buildAbstractFuelCompanyBalanceChangeDocumentFormViewState(): AbstractFuelCompanyBalanceChangeDocumentFormViewState {
    return {
      form: this.form,
      title: this.title,
      fieldViewStates: this.buildFieldViewStates()
    }
  }

  private setAndShowFuelCompanyBalanceChangeDocumentFormViewState(
    fuelCompanyBalanceChangeDocumentFormViewState: FuelCompanyBalanceChangeDocumentFormViewState
  ) {
    this.setFuelCompanyBalanceChangeDocumentFormViewStateFormViewState(fuelCompanyBalanceChangeDocumentFormViewState)
    this.showFuelCompanyBalanceChangeDocumentFormViewState()
  }

  private setFuelCompanyBalanceChangeDocumentFormViewStateFormViewState(
    fuelCompanyBalanceChangeDocumentFormViewState: FuelCompanyBalanceChangeDocumentFormViewState
  ) {
    this.fuelCompanyBalanceChangeDocumentFormViewState = fuelCompanyBalanceChangeDocumentFormViewState
  }

  private showFuelCompanyBalanceChangeDocumentFormViewState() {
    this.fuelCompanyBalanceChangeDocumentFormViewState &&
      this.getView()?.showFuelCompanyBalanceChangeDocumentFormViewState(
        this.fuelCompanyBalanceChangeDocumentFormViewState
      )
  }

  private setFuelCompanyBalanceChangeDocument(fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument) {
    this.fuelCompanyBalanceChangeDocument = fuelCompanyBalanceChangeDocument
  }

  private setChangingError(changingError: FuelCompanyBalanceChangeDocumentError) {
    this.changingError = changingError
  }

  private clearChangingError() {
    this.changingError = undefined
  }

  private setChangingException(changingException: ApplicationException) {
    this.changingException = changingException
  }

  private clearChangingException() {
    this.changingException = undefined
  }

  private buildFormProviderUtils(checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase) {
    return new FormProviderUtils<
      FuelCompanyBalanceChangeDocument,
      FuelCompanyBalanceChangeDocumentError,
      FuelCompanyBalanceChangeDocumentErrorsObject
    >({
      formProvider: this.formProvider,
      form: this.form,
      checkPermissionDeniedUseCase: checkPermissionDeniedUseCase,
      isNewObject: true,
      object: this.fuelCompanyBalanceChangeDocument!
    })
  }
}
