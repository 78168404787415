import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import TripErrorsObjectAttributes from "../../../../../domain/trips/TripErrorsObjectAttributes"
import AttributeError from "../../../../../../../admin/core/domain/entities/errors/AttributeError"

export default class NetworkTripErrorsObjectAttributes
  implements TripErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly startingAt?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly finishingAt?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly status?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly limits?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly carrierId?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly transportId?: AttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly driverIds?: AttributeError[] | null
}
