import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesForRefuellingOrdersRepository from "../../repositories/FuelCompaniesForRefuellingOrdersRepository"

export interface GetFuelCompaniesForRefuellingOrdersParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForRefuellingOrdersUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompanyForCarrierCommercialTermsRepository: FuelCompaniesForRefuellingOrdersRepository

  constructor(parameters: {
    readonly fuelCompanyForCarrierCommercialTermsRepository: FuelCompaniesForRefuellingOrdersRepository
  }) {
    this.fuelCompanyForCarrierCommercialTermsRepository = parameters.fuelCompanyForCarrierCommercialTermsRepository
  }

  async call(parameters: GetFuelCompaniesForRefuellingOrdersParameters): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompanyForCarrierCommercialTermsRepository.getFuelCompanies(parameters)
  }
}
