import AppI18 from "../../../i18n/AppI18"
import isPresent from "../../../../../admin/lib/isPresent"
import ChangingType, { ChangingTypeSelectOption } from "../../../domain/changing-log-items/ChangingType"

export default class ChangingTypesStaticSource {
  private readonly appI18n: AppI18

  constructor(parameters: {
    readonly appI18n: AppI18
  }) {
    this.appI18n = parameters.appI18n
  }

  async getChangingTypes({
    ids
  }: {
    readonly ids?: string[] | null
  }): Promise<ChangingTypeSelectOption[]> {
    const values = [
      {
        id: ChangingType.CREATED,
        text: this.appI18n.getTextProvider().changingTypeCreatedText()
      },
      {
        id: ChangingType.UPDATED,
        text: this.appI18n.getTextProvider().changingTypeUpdatedText()
      },
      {
        id: ChangingType.DELETED,
        text: this.appI18n.getTextProvider().changingTypeDeletedText()
      }
    ]

    if (isPresent(ids)) {
      return values.filter((value) => ids.includes(value.id))
    }

    return values
  }
}
