import React from "react"
import styles from "./NotFoundComponent.module.scss"
import { useNotFoundTextProvider } from "../../contexts/NotFoundTextProviderContext"
import { useNotFoundThemeProvider } from "../../contexts/NotFoundThemeProviderContext"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import LinkButtonComponent from "../../../../../lib/button/LinkButtonComponent"

export default function NotFoundComponent() {
  const coreTheme = useCoreThemeProvider()
  const notFoundTheme = useNotFoundThemeProvider()
  const notFoundTextProvider = useNotFoundTextProvider()
  const urlProvider = new CoreUrlProvider()

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <img
          src={notFoundTheme.imNotFoundLogoPath()}
          alt="imNotFoundLogoPath"
        />
        <h1 className={`${styles.title} ${coreTheme.headline3PrimaryClassName()}`}>
          {notFoundTextProvider.title()}
        </h1>
        <div className={`${styles.text} ${coreTheme.body5SecondaryClassName()}`}>
          {notFoundTextProvider.text()}
        </div>
        <LinkButtonComponent
          componentStyles={{
            ...coreTheme.buttonFilled1PrimaryStyle(),
            classes: {
              ...coreTheme.buttonFilled1PrimaryStyle().classes,
              default: `${styles.button} ${coreTheme.buttonFilled1PrimaryStyle().classes?.default ?? ""}`
            }
          }}
          url={urlProvider.buildRootUrl()}
          title={notFoundTextProvider.redirectToMainLink()}
        />
      </div>
    </div>
  )
}
