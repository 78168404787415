import SuccessExecutionResult from "../../../../../core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../core/domain/results/FailureExecutionResult"
import PasswordsRepository from "../../repositories/PasswordsRepository"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"

export interface CheckTokenParameters {
  readonly token: string
}

export default class CheckTokenUseCase {

  private readonly passwordsRepository: PasswordsRepository

  constructor(parameters: {
    readonly passwordsRepository: PasswordsRepository
  }) {
    this.passwordsRepository = parameters.passwordsRepository
  }

  async call(parameters: CheckTokenParameters): Promise<CheckTokenResult> {
    return await this.passwordsRepository.checkToken(parameters)
  }
}

export type CheckTokenResult =
  SuccessExecutionResult |
  ErrorExecutionResult<ExecutionError> |
  FailureExecutionResult
