import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkTripsRequestFilter from "../../entities/trips/request-queries/NetworkTripsRequestFilter"
import NetworkTripsResponseBody from "../../entities/trips/response-bodies/NetworkTripsResponseBody"
import NetworkTripResponseBody from "../../entities/trips/response-bodies/NetworkTripResponseBody"
import NetworkTripsRequestQuery from "../../entities/trips/request-queries/NetworkTripsRequestQuery"
import NetworkTrip from "../../entities/trips/NetworkTrip"
import NetworkTripRequestBody from "../../entities/trips/request-bodies/NetworkTripRequestBody"
import NetworkTripErrorResponseBody from "../../entities/trips/response-bodies/errors/NetworkTripErrorResponseBody"

const basePath = "/api/admin/trips"

export default class TripsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getTrips({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkTripsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetTripsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkTripsRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTripsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getTrip({
    tripId
  }: {
    readonly tripId: number
  }): Promise<GetTripNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${tripId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTripResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createTrip({
    trip
  }: {
    readonly trip?: NetworkTrip | null
  }): Promise<CreateTripNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkTripRequestBody({
        trip
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTripResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkTripErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateTransport({
    tripId,
    trip
  }: {
    readonly tripId: number
    readonly trip?: NetworkTrip | null
  }): Promise<UpdateTripNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${tripId}`,
      body: instanceToPlain(new NetworkTripRequestBody({
        trip
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTripResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkTripErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetTripsNetworkResult =
  SuccessExecutionResult<NetworkTripsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetTripNetworkResult =
  SuccessExecutionResult<NetworkTripResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateTripNetworkResult =
  SuccessExecutionResult<NetworkTripResponseBody> |
  ErrorExecutionResult<NetworkTripErrorResponseBody> |
  FailureExecutionResult

export type UpdateTripNetworkResult =
  SuccessExecutionResult<NetworkTripResponseBody> |
  ErrorExecutionResult<NetworkTripErrorResponseBody> |
  FailureExecutionResult
