import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForDriverRepository from "../../repositories/CarriersForDriverRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForDriverParameters extends GetObjectsParameters {
}

export default class GetCarriersForDriverUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForDriverRepository: CarriersForDriverRepository

  constructor(parameters: {
    readonly carriersForDriverRepository: CarriersForDriverRepository
  }) {
    this.carriersForDriverRepository = parameters.carriersForDriverRepository
  }

  async call(parameters: GetCarriersForDriverParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForDriverRepository.getCarriers(parameters)
  }
}
