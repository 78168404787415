import { Expose, Type } from "class-transformer"
import NetworkRefuellingOrderErrorsObject from "./NetworkRefuellingOrderErrorsObject"
import NetworkExecutionError from "../../../errors/NetworkExecutionError"
import RefuellingOrderError from "../../../../../domain/refuelling-orders/RefuellingOrderError"

export default class NetworkRefuellingOrderErrorResponseBody
  extends NetworkExecutionError
  implements RefuellingOrderError {

  @Expose()
  @Type(() => NetworkRefuellingOrderErrorsObject)
  readonly errorsObject?: NetworkRefuellingOrderErrorsObject | null
}
