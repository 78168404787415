import TableColumn from "./TableColumn"
import Filter from "../filters/Filter"

export default class Table<DomainObject, FilterObject = never> {
  private readonly title: string
  private readonly description?: string
  private readonly newObjectPageUrl?: string
  private readonly columns: TableColumn<DomainObject>[]
  private readonly filter?: Filter<FilterObject>
  private canCreateNewObject?: boolean
  private canShowObject?: boolean

  readonly getObjectId: (object: DomainObject) => string
  readonly needRefreshObject?: (object: DomainObject) => boolean
  readonly getRowUrl: (object: DomainObject) => string | null | undefined

  constructor(parameters: {
    readonly title: string
    readonly description?: string
    readonly newObjectPageUrl?: string
    readonly getObjectId: (object: DomainObject) => string
    readonly needRefreshObject?: (object: DomainObject) => boolean
    readonly getRowUrl: (object: DomainObject) => string | null | undefined
    readonly columns: TableColumn<DomainObject>[]
    readonly filter?: Filter<FilterObject>
  }) {
    this.title = parameters.title
    this.description = parameters.description
    this.newObjectPageUrl = parameters.newObjectPageUrl
    this.getObjectId = parameters.getObjectId
    this.getRowUrl = parameters.getRowUrl
    this.needRefreshObject = parameters.needRefreshObject
    this.columns = parameters.columns
    this.filter = parameters.filter
  }

  setCanCreateNewObject(canCreateNewObject: boolean) {
    this.canCreateNewObject = canCreateNewObject
  }

  setCanShowObject(canShowObject: boolean) {
    this.canShowObject = canShowObject
  }

  getCanShowObject() {
    return this.canShowObject
  }

  getCanCreateNewObject() {
    return this.canCreateNewObject
  }

  getTitle() {
    return this.title
  }

  getDescription() {
    return this.description
  }

  getNewObjectPageUrl() {
    return this.newObjectPageUrl
  }

  getColumns() {
    return this.columns
  }

  getFilter() {
    return this.filter
  }
}
