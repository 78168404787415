import { Expose, Type } from "class-transformer"
import NetworkUser from "../../users/NetworkUser"

export default class NetworkUserRequestBody {

  @Expose()
  @Type(() => NetworkUser)
  readonly user?: NetworkUser | null

  constructor(parameters: {
    readonly user?: NetworkUser | null
  }) {
    this.user = parameters.user
  }
}
