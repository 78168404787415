import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import DriverProfileErrorsObjectAttributes
  from "../../../../../domain/driver-profile/DriverProfileErrorsObjectAttributes"

export default class NetworkDriverProfileErrorsObjectAttributes implements DriverProfileErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly firstName?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly middleName?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly lastName?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly birthdate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly driverLicenseNumber?: NetworkAttributeError[] | null
}
