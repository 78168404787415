import DiModule from "../../../admin/lib/di/DiModule"
import AppNotFoundTheme from "../../theme/AppNotFoundTheme"
import NotFoundThemeDiModule from "../../../admin/features/not-found/di/modules/NotFoundThemeDiModule"
import NotFoundTheme from "../../../admin/features/not-found/presentation/theme/NotFoundTheme"

export default class DefaultNotFoundThemeDiModule extends DiModule implements NotFoundThemeDiModule {
  provideNotFoundTheme(): NotFoundTheme {
    return this.single(
      AppNotFoundTheme.name,
      () => new AppNotFoundTheme()
    )
  }
}
