import NetworkGasStationErrorsObjectAttributes from "./NetworkGasStationErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import GasStationErrorsObject from "../../../../../domain/gas-stations/GasStationErrorsObject"
import NetworkGPSLocationErrorsObject from "../../../gps-locations/response-bodies/errors/NetworkGPSLocationErrorsObject"

export default class NetworkGasStationErrorsObject implements GasStationErrorsObject {
  @Expose()
  @Type(() => NetworkGasStationErrorsObjectAttributes)
  readonly attributes?: NetworkGasStationErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkGPSLocationErrorsObject)
  readonly location?: NetworkGPSLocationErrorsObject | null
}
