import { plainToInstance } from "class-transformer"
import UserProfileNetworkSource, {
  GetUserProfileNetworkResult
} from "../../../../../admin/core/data/sources/user-profile/UserProfileNetworkSource"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkUserProfileResponseBody
  from "../../../../../admin/core/data/entities/network/user-profile/response-bodies/NetworkUserProfileResponseBody"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"

const basePath = "/api/admin/user_profile"

export default class DefaultUserProfileNetworkSource implements UserProfileNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getUserProfile(): Promise<GetUserProfileNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUserProfileResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}
