import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import DriversForTripsRepository from "../../domain/repositories/DriversForTripsRepository"
import DriversNetworkSource from "../../../../core/data/sources/network/DriversNetworkSource"
import { GetDriversForTripsParameters } from "../../domain/use-cases/drivers/GetDriversForTripsUseCase"
import User from "../../../../core/domain/users/User"
import UsersMapper from "../../../../core/data/mappers/UsersMapper"
import NetworkDriversRequestFilter
  from "../../../../core/data/entities/drivers/request-queries/NetworkDriversRequestFilter"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultDriversForTripsRepository implements DriversForTripsRepository {
  private readonly driversNetworkSource: DriversNetworkSource

  constructor(parameters: {
    readonly driversNetworkSource: DriversNetworkSource
  }) {
    this.driversNetworkSource = parameters.driversNetworkSource
  }

  async getDrivers({
    ids,
    filter,
    query,
    pagination,
    sort
  }: GetDriversForTripsParameters): Promise<GetObjectsResult<User>> {
    const carrierId = filter?.carrierId
    const blocked = filter?.blocked
    const result = await this.driversNetworkSource.getDrivers({
      filter: new NetworkDriversRequestFilter({
        query,
        carrierIds: isPresent(carrierId) ? [carrierId] : null,
        blocked: blocked,
        ids
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success": {
        const usersMapper = new UsersMapper()
        return {
          type: "success",
          data: {
            objects: result.data.drivers!.map((user) => {
              return usersMapper.mapNetworkToDomain({ user })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
