import NotFoundTextProvider from "../NotFoundTextProvider"

export default class DefaultRuNotFoundTextProvider implements NotFoundTextProvider {

  title(): string {
    return "Страница не найдена"
  }

  text(): string {
    return "Мы не смогли найти запрашиваемую страницу. Возможно, она была перемещена или удалена."
  }

  redirectToMainLink(): string {
    return "Вернуться на главную"
  }
}
