import FuelCompanyCommercialTermsRepository from "../../domain/repositories/FuelCompanyCommercialTermsRepository"
import { GetFuelCompanyCommercialTermsParameters } from "../../domain/use-cases/fuel-company-commercial-terms/GetFuelCompanyCommercialTermsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateFuelCompanyCommercialTermParameters } from "../../domain/use-cases/fuel-company-commercial-terms/CreateFuelCompanyCommercialTermUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetFuelCompanyCommercialTermParameters } from "../../domain/use-cases/fuel-company-commercial-terms/GetFuelCompanyCommercialTermUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateFuelCompanyCommercialTermParameters } from "../../domain/use-cases/fuel-company-commercial-terms/UpdateCarrierCommercialTermUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import PricingTypesStaticSource from "../../../../core/data/sources/static/PricingTypesStaticSource"
import FuelCompanyCommercialTermsNetworkSource
  from "../../../../core/data/sources/network/FuelCompanyCommercialTermsNetworkSource"
import FuelCompanyCommercialTerm from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"
import NetworkFuelCompanyCommercialTermsRequestFilter
  from "../../../../core/data/entities/fuel-company-commercial-terms/request-queries/NetworkFuelCompanyCommercialTermsRequestFilter"
import FuelCompanyCommercialTermsMapper from "../../../../core/data/mappers/FuelCompanyCommercialTermsMapper"
import FuelCompanyCommercialTermError
  from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTermError"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultFuelCompanyCommercialTermsRepository implements FuelCompanyCommercialTermsRepository {
  private readonly fuelCompanyCommercialTermsNetworkSource: FuelCompanyCommercialTermsNetworkSource
  private readonly pricingTypesStaticSource: PricingTypesStaticSource

  constructor(parameters: {
    readonly fuelCompanyCommercialTermsNetworkSource: FuelCompanyCommercialTermsNetworkSource
    readonly pricingTypesStaticSource: PricingTypesStaticSource
  }) {
    this.fuelCompanyCommercialTermsNetworkSource = parameters.fuelCompanyCommercialTermsNetworkSource
    this.pricingTypesStaticSource = parameters.pricingTypesStaticSource
  }

  async getFuelCompanyCommercialTerms({
    filter,
    pagination,
    sort
  }: GetFuelCompanyCommercialTermsParameters): Promise<GetObjectsResult<FuelCompanyCommercialTerm>> {
    const fuelCompanyId = filter?.fuelCompanyId
    const result = await this.fuelCompanyCommercialTermsNetworkSource.getFuelCompanyCommercialTerms({
      filter: new NetworkFuelCompanyCommercialTermsRequestFilter({
        fuelCompanyIds: isPresent(fuelCompanyId) ? [fuelCompanyId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    const fuelCompanyCommercialTermsMapper = new FuelCompanyCommercialTermsMapper()

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.fuelCompanyCommercialTerms!.map((fuelCompanyCommercialTerm) => {
              return fuelCompanyCommercialTermsMapper.mapNetworkToDomain({
                fuelCompanyCommercialTerm,
                pricingTypeSelectOptions
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getFuelCompanyCommercialTerm({
    objectId
  }: GetFuelCompanyCommercialTermParameters): Promise<GetObjectResult<FuelCompanyCommercialTerm>> {
    const result = await this.fuelCompanyCommercialTermsNetworkSource.getFuelCompanyCommercialTerm({
      fuelCompanyCommercialTermId: parseInt(objectId)
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new FuelCompanyCommercialTermsMapper().mapNetworkToDomain({
            fuelCompanyCommercialTerm: result.data.fuelCompanyCommercialTerm!,
            pricingTypeSelectOptions
          })
        }
      default:
        return result
    }
  }

  async createFuelCompanyCommercialTerm({
    object: fuelCompanyCommercialTerm
  }: CreateFuelCompanyCommercialTermParameters): Promise<CreateObjectResult<
    FuelCompanyCommercialTerm, FuelCompanyCommercialTermError
  >> {
    const mapper = new FuelCompanyCommercialTermsMapper()
    const result = await this.fuelCompanyCommercialTermsNetworkSource.createFuelCompanyCommercialTerm({
      fuelCompanyCommercialTerm: mapper.mapDomainToNetwork({ fuelCompanyCommercialTerm })
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompanyCommercialTerm: result.data.fuelCompanyCommercialTerm!,
            pricingTypeSelectOptions
          })
        }
      default:
        return result
    }
  }

  async updateFuelCompanyCommercialTerm({
    objectId: fuelCompanyCommercialTermId,
    object: fuelCompanyCommercialTerm
  }: UpdateFuelCompanyCommercialTermParameters): Promise<UpdateObjectResult<
    FuelCompanyCommercialTerm, FuelCompanyCommercialTermError
  >> {
    const mapper = new FuelCompanyCommercialTermsMapper()
    const result = await this.fuelCompanyCommercialTermsNetworkSource.updateFuelCompanyCommercialTerm({
      fuelCompanyCommercialTermId: parseInt(fuelCompanyCommercialTermId),
      fuelCompanyCommercialTerm: mapper.mapDomainToNetwork({
        fuelCompanyCommercialTerm
      })
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompanyCommercialTerm: result.data.fuelCompanyCommercialTerm!,
            pricingTypeSelectOptions
          })
        }
      default:
        return result
    }
  }
}
