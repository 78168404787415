import { Outlet } from "react-router-dom"
import React from "react"
import styles from "./RootComponent.module.scss"

import { LocationContext } from "../../../../../lib/presenter/useLocationContext"
import { LocationContextProvider } from "../../../../../lib/presenter/useLocationContextProvider"
import LeftMenuComponent from "../left-menu/LeftMenuComponent"
import UserProfile from "../../../../../core/domain/entities/user-profile/UserProfile"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import UiMenuItem from "../left-menu/UiMenuItem"

interface Props {
  readonly onLogoutClicked: () => void
  readonly menuItems?: UiMenuItem[]
  readonly userProfile?: UserProfile
  readonly locationContextProvider: LocationContextProvider
}

export default function RootComponent({
  onLogoutClicked,
  menuItems,
  userProfile,
  locationContextProvider
}: Props) {
  return (
    <>
      <HelmetComponent />
      <LeftMenuComponent
        menuItems={menuItems}
        userProfile={userProfile}
        onLogoutClicked={onLogoutClicked}
      />
      <div className={styles.content}>
        <div className={styles.page}>
          {locationContextProvider.locationId && (
            <Outlet
              context={{
                locationId: locationContextProvider.locationId,
                getOrCreatePresenter: locationContextProvider.getOrCreatePresenter
              } as LocationContext}
            />
          )}
        </div>
      </div>
    </>
  )
}
