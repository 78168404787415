import Presenter from "../../../../../lib/presenter/Presenter"
import ObjectFormView, { AbstractObjectFormViewState, ObjectFormViewState } from "./ObjectFormView"
import GetObjectUseCase, { GetObjectResult } from "../../../domain/use-cases/objects/GetObjectUseCase"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import assertNever from "../../../../../lib/assertNever"
import FormProvider from "../../providers/FormProvider"
import isBlank from "../../../../../lib/isBlank"
import CreateObjectUseCase, { CreateObjectResult } from "../../../domain/use-cases/objects/CreateObjectUseCase"
import UpdateObjectUseCase, { UpdateObjectResult } from "../../../domain/use-cases/objects/UpdateObjectUseCase"
import DestroyObjectUseCase, { DestroyObjectResult } from "../../../domain/use-cases/objects/DestroyObjectUseCase"
import BroadcastObjectsEventUseCase from "../../../domain/use-cases/objects/BroadcastObjectsEventUseCase"
import FormPermissionsSet from "../../entities/forms/FormPermissionsSet"
import FormField, { FormFieldViewState } from "../../entities/forms/FormField"
import autoBind from "auto-bind"
import FormFieldGroup from "../../entities/forms/FormFieldGroup"
import Form from "../../entities/forms/Form"
import CheckPermissionDeniedUseCase
  from "../../../../../core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import FormProviderUtils from "../../providers/FormProviderUtils"

export default class ObjectFormPresenter<DomainObject, DomainError extends ExecutionError, ErrorsObject>
  extends Presenter<ObjectFormView<DomainObject, ErrorsObject>> {

  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getObjectUseCase: GetObjectUseCase<DomainObject>
  private readonly createObjectUseCase?: CreateObjectUseCase<DomainObject, DomainError>
  private readonly updateObjectUseCase?: UpdateObjectUseCase<DomainObject, DomainError>
  private readonly destroyObjectUseCase?: DestroyObjectUseCase<DomainError>
  private readonly formProvider: FormProvider<DomainObject, DomainError, ErrorsObject>
  private readonly formProviderUtils: FormProviderUtils<DomainObject, DomainError, ErrorsObject>
  private readonly objectId: string | undefined
  private form!: Form<DomainObject, ErrorsObject>
  private title!: string
  private shortTitle!: string
  private object?: DomainObject
  private changingError?: DomainError
  private changingException?: ApplicationException
  private objectFormViewState?: ObjectFormViewState<DomainObject, ErrorsObject>

  constructor(parameters: {
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getObjectUseCase: GetObjectUseCase<DomainObject>
    readonly createObjectUseCase?: CreateObjectUseCase<DomainObject, DomainError>
    readonly updateObjectUseCase?: UpdateObjectUseCase<DomainObject, DomainError>
    readonly destroyObjectUseCase?: DestroyObjectUseCase<DomainError>,
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase,
    readonly formProvider: FormProvider<DomainObject, DomainError, ErrorsObject>
    readonly objectId: string | undefined
  }) {
    super()

    autoBind(this)

    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getObjectUseCase = parameters.getObjectUseCase
    this.createObjectUseCase = parameters.createObjectUseCase
    this.updateObjectUseCase = parameters.updateObjectUseCase
    this.destroyObjectUseCase = parameters.destroyObjectUseCase
    this.formProvider = parameters.formProvider
    this.objectId = parameters.objectId

    this.buildForm()
    this.buildTitles()

    this.formProviderUtils = this.buildFormProviderUtils(parameters.checkPermissionDeniedUseCase)
    this.formProviderUtils.initFormByPermissions()
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    if (this.formProviderUtils.isFormVisibleByPermission()) {
      this.init().then()
    } else {
      this.setAndShowForbiddenObjectFormViewState()
    }
  }

  private async init(): Promise<void> {
    this.setAndShowLoadingObjectFormViewState()

    if (this.formProvider.init) {
      const initResult = await this.formProvider.init()
      switch (initResult.type) {
        case "success":
          break
        case "error":
          this.setAndShowLoadingErrorObjectFormViewState({ error: initResult.error })
          return
        case "failure":
          this.setAndShowLoadingFailureObjectFormViewState({ exception: initResult.exception })
          return
      }
    }

    if (this.isNewObject()) {
      await this.buildAndShowObject()
    } else {
      await this.loadAndShowObject()
    }
  }

  protected onViewReAttach() {
    super.onViewReAttach()
    this.showObjectFormViewState()
  }

  onSubmitObjectClicked() {
    if (this.isNewObject()) {
      this.createObjectAndShowList().then()
    } else {
      this.updateObjectAndShowList().then()
    }
  }

  onDeleteObjectClicked() {
    this.destroyObjectAndShowList().then()
  }

  onCancelClicked() {
    this.setAndShowCancelingObjectFormViewState()
  }

  private buildFormPermissionsSet(): FormPermissionsSet {
    return {
      canCreate: this.createObjectUseCase !== undefined,
      canUpdate: this.updateObjectUseCase !== undefined,
      canDestroy: this.destroyObjectUseCase !== undefined
    }
  }

  private buildForm() {
    this.form = new Form<DomainObject, ErrorsObject>({
      permissionsSet: this.buildFormPermissionsSet(),
      groups: this.buildFieldsGroups(),
      fields: this.buildFormFields()
    })
  }

  private buildTitles() {
    this.shortTitle = this.isNewObject() ?
      this.formProvider.getNewObjectTitle() :
      this.formProvider.getExistedObjectShortTitle({
        object: this.object
      })

    this.title = this.isNewObject() ?
      this.formProvider.getNewObjectTitle() :
      this.formProvider.getExistedObjectTitle({
        object: this.object
      })
  }

  private buildFieldsGroups(): FormFieldGroup[] {
    return this.formProvider.getFieldGroups()
  }

  private buildFormFields(): FormField<DomainObject, ErrorsObject>[] {
    const formFields: FormField<DomainObject, ErrorsObject>[] = this.formProvider.getFields()

    formFields.forEach((formField: FormField<DomainObject, ErrorsObject>) => {
      formField.setSetObject(this.setObject)
      formField.setSetAndShowLoadedFuelCompanyBalanceChangeDocumentViewState(this.setAndShowLoadedObjectFormViewState)
    })

    return formFields
  }

  private async buildAndShowObject(): Promise<void> {
    const newObject: DomainObject = await this.formProvider.buildObject()
    this.setObject(newObject)
    this.buildTitles()
    this.setAndShowLoadedObjectFormViewState()
  }

  private async loadAndShowObject(): Promise<void> {
    this.setAndShowLoadingObjectFormViewState()

    const result: GetObjectResult<DomainObject> = await this.getObjectUseCase.call({
      objectId: this.objectId!
    })

    switch (result.type) {
      case "error":
        this.setAndShowLoadingErrorObjectFormViewState({ error: result.error })
        break
      case "failure":
        this.setAndShowLoadingFailureObjectFormViewState({ exception: result.exception })
        break
      case "success":
        this.setObject(result.data)
        this.buildTitles()
        this.setAndShowLoadedObjectFormViewState()
        break
      default:
        assertNever(result)
    }
  }

  private async createObjectAndShowList(): Promise<void> {
    if (!this.createObjectUseCase) {
      return
    }

    this.clearChangingError()
    this.clearChangingException()
    this.setAndShowCreatingObjectFormViewState()

    const result: CreateObjectResult<DomainObject, DomainError> = await this.createObjectUseCase.call({
      object: this.object!
    })

    switch (result.type) {
      case "success":
        this.broadcastObjectsEventUseCase.call({ type: "created" })
        this.setAndShowCancelingObjectFormViewState()
        break
      case "error":
        this.setChangingError(result.error)
        this.setAndShowLoadedObjectFormViewState()
        break
      case "failure":
        this.setChangingException(result.exception)
        this.setAndShowLoadedObjectFormViewState()
        break
      default:
        assertNever(result)
    }
  }

  private async updateObjectAndShowList(): Promise<void> {
    if (!this.updateObjectUseCase) {
      return
    }

    this.clearChangingError()
    this.clearChangingException()
    this.setAndShowUpdatingObjectFormViewState()

    const result: UpdateObjectResult<DomainObject, DomainError> = await this.updateObjectUseCase.call({
      objectId: this.objectId!,
      object: this.object!
    })

    switch (result.type) {
      case "success":
        this.broadcastObjectsEventUseCase.call({ type: "updated" })
        this.setAndShowCancelingObjectFormViewState()
        break
      case "error":
        this.setChangingError(result.error)
        this.setAndShowLoadedObjectFormViewState()
        break
      case "failure":
        this.setChangingException(result.exception)
        this.setAndShowLoadedObjectFormViewState()
        break
      default:
        assertNever(result)
    }
  }

  private async destroyObjectAndShowList(): Promise<void> {
    if (!this.destroyObjectUseCase) {
      return
    }

    this.clearChangingError()
    this.clearChangingException()
    this.setAndShowDestroyingObjectFormViewState()

    const result: DestroyObjectResult<DomainError> = await this.destroyObjectUseCase.call({
      objectId: this.objectId!
    })

    switch (result.type) {
      case "success":
        this.broadcastObjectsEventUseCase.call({ type: "destroyed" })
        this.setAndShowCancelingObjectFormViewState()
        break
      case "error":
        this.setChangingError(result.error)
        this.setAndShowLoadedObjectFormViewState()
        break
      case "failure":
        this.setChangingException(result.exception)
        this.setAndShowLoadedObjectFormViewState()
        break
      default:
        assertNever(result)
    }
  }

  private buildFieldViewStates(): FormFieldViewState[] {
    const errorsObject: ErrorsObject | undefined = this.formProvider.getErrorsObject({
      error: this.changingError
    }) ?? undefined

    return this.form.getFormFields().map((formField: FormField<DomainObject, ErrorsObject>): FormFieldViewState => {
      return formField.getViewState(this.object!, errorsObject)
    })
  }

  private setAndShowForbiddenObjectFormViewState() {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "forbidden"
    })
  }

  private setAndShowLoadingObjectFormViewState() {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "loading"
    })
  }

  private setAndShowLoadingErrorObjectFormViewState({ error }: { readonly error: ExecutionError }) {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "loading_error",
      error
    })
  }

  private setAndShowLoadingFailureObjectFormViewState({ exception }: { readonly exception: ApplicationException }) {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "loading_failure",
      exception
    })
  }

  private setAndShowLoadedObjectFormViewState() {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "loaded",
      fieldViewStates: this.buildFieldViewStates(),
      changingError: this.changingError,
      changingException: this.changingException
    })
  }

  private setAndShowCreatingObjectFormViewState() {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "creating",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setAndShowUpdatingObjectFormViewState() {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "updating",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setAndShowDestroyingObjectFormViewState() {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "destroying",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setAndShowCancelingObjectFormViewState() {
    this.setAndShowObjectFormViewState({
      ...this.buildAbstractObjectFormViewState(),
      type: "canceling"
    })
  }

  private buildAbstractObjectFormViewState(): AbstractObjectFormViewState<DomainObject, ErrorsObject> {
    return {
      form: this.form,
      shortTitle: this.shortTitle,
      title: this.title,
      isNewObject: this.isNewObject()
    }
  }

  private setAndShowObjectFormViewState(objectFormViewState: ObjectFormViewState<DomainObject, ErrorsObject>) {
    this.setObjectFormViewState(objectFormViewState)
    this.showObjectFormViewState()
  }

  private setObjectFormViewState(objectFormViewState: ObjectFormViewState<DomainObject, ErrorsObject>) {
    this.objectFormViewState = objectFormViewState
  }

  private showObjectFormViewState() {
    this.objectFormViewState && this.getView()?.showObjectFormViewState(this.objectFormViewState)
  }

  private setObject(object: DomainObject) {
    this.object = object
    this.formProvider.onNewObject?.(object)
  }

  private setChangingError(changingError: DomainError) {
    this.changingError = changingError
  }

  private clearChangingError() {
    this.changingError = undefined
  }

  private setChangingException(changingException: ApplicationException) {
    this.changingException = changingException
  }

  private clearChangingException() {
    this.changingException = undefined
  }

  private isNewObject(): boolean {
    return isBlank(this.objectId)
  }

  private buildFormProviderUtils(checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase) {
    return new FormProviderUtils<DomainObject, DomainError, ErrorsObject>({
      formProvider: this.formProvider,
      form: this.form,
      checkPermissionDeniedUseCase: checkPermissionDeniedUseCase,
      isNewObject: this.isNewObject(),
      object: this.object!
    })
  }
}
