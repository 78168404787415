import CarrierErrorsObject from "../../../../../domain/carriers/CarrierErrorsObject"
import NetworkCarrierErrorsObjectAttributes from "./NetworkCarrierErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import NetworkLegalEntityErrorsObject from "../../../legal-entities/NetworkLegalEntityErrorsObject"
import NetworkContactPersonErrorsObject from "../../../contact-people/NetworkContactPersonErrorsObject"

export default class NetworkCarrierErrorsObject implements CarrierErrorsObject {
  @Expose()
  @Type(() => NetworkCarrierErrorsObjectAttributes)
  readonly attributes?: NetworkCarrierErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkLegalEntityErrorsObject)
  readonly legalEntity?: NetworkLegalEntityErrorsObject | null

  @Expose()
  @Type(() => NetworkContactPersonErrorsObject)
  readonly contactPerson?: NetworkContactPersonErrorsObject | null
}
