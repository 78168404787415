import AppI18 from "../../../i18n/AppI18"
import isPresent from "../../../../../admin/lib/isPresent"
import RefuellingOrderStatus, {
  RefuellingOrderStatusSelectOption
} from "../../../domain/refuelling-orders/RefuellingOrderStatus"

export default class RefuellingOrderStatusesStaticSource {
  private readonly appI18n: AppI18

  constructor(parameters: {
    readonly appI18n: AppI18
  }) {
    this.appI18n = parameters.appI18n
  }

  async getRefuellingOrderStatuses({
    ids,
    query
  }: {
    readonly ids?: string[] | null
    readonly query?: string | null
  }): Promise<RefuellingOrderStatusSelectOption[]> {
    const values = [
      {
        id: RefuellingOrderStatus.GAS_STATION_CONFIRMATION_PENDING,
        text: this.appI18n.getTextProvider().refuellingOrderStatusGasStationConfirmationPendingText()
      },
      {
        id: RefuellingOrderStatus.NOT_CONFIRMED_BY_GAS_STATION,
        text: this.appI18n.getTextProvider().refuellingOrderStatusNotConfirmedByGasStationText()
      },
      {
        id: RefuellingOrderStatus.REFUELLING_PENDING,
        text: this.appI18n.getTextProvider().refuellingOrderStatusRefuellingPendingText()
      },
      {
        id: RefuellingOrderStatus.CANCELLED,
        text: this.appI18n.getTextProvider().refuellingOrderStatusCancelledText()
      },
      {
        id: RefuellingOrderStatus.REFUELLING,
        text: this.appI18n.getTextProvider().refuellingOrderStatusRefuellingText()
      },
      {
        id: RefuellingOrderStatus.DRIVER_CONFIRMATION_PENDING,
        text: this.appI18n.getTextProvider().refuellingOrderStatusDriverConfirmationPendingText()
      },
      {
        id: RefuellingOrderStatus.FINISHED,
        text: this.appI18n.getTextProvider().refuellingOrderStatusFinishedText()
      },
      {
        id: RefuellingOrderStatus.GAS_STATION_CORRECTION_PENDING,
        text: this.appI18n.getTextProvider().refuellingOrderStatusGasStationCorrectionPendingText()
      },
      {
        id: RefuellingOrderStatus.CLAIM,
        text: this.appI18n.getTextProvider().refuellingOrderStatusClaimText()
      }
    ]

    if (isPresent(ids)) {
      return values.filter((value) => ids.includes(value.id))
    }

    if (isPresent(query)) {
      return values.filter((value) => value.text.includes(query))
    }

    return values
  }
}
