import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkChangingLogItemsRequestFilter
  from "../../entities/changing-log-items/request-queries/NetworkChangingLogItemsRequestFilter"
import NetworkChangingLogItemsResponseBody
  from "../../entities/changing-log-items/response-bodies/NetworkChangingLogItemsResponseBody"
import NetworkChangingLogItemsRequestQuery
  from "../../entities/changing-log-items/request-queries/NetworkChangingLogItemsRequestQuery"

const basePath = "/api/admin/changing_log_items"

export default class ChangingLogItemsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getChangingLogItems({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkChangingLogItemsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetChangingLogItemsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkChangingLogItemsRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkChangingLogItemsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetChangingLogItemsNetworkResult =
  SuccessExecutionResult<NetworkChangingLogItemsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
