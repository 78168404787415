import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesForRefuellingOrdersReportsRepository
  from "../../repositories/FuelCompaniesForRefuellingOrdersReportsRepository"

export interface GetFuelCompaniesForRefuellingOrdersReportsParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForRefuellingOrdersReportsUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompanyForRefuellingOrdersReportsRepository: FuelCompaniesForRefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly fuelCompanyForRefuellingOrdersReportsRepository: FuelCompaniesForRefuellingOrdersReportsRepository
  }) {
    this.fuelCompanyForRefuellingOrdersReportsRepository = parameters.fuelCompanyForRefuellingOrdersReportsRepository
  }

  async call(parameters: GetFuelCompaniesForRefuellingOrdersReportsParameters): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompanyForRefuellingOrdersReportsRepository.getFuelCompanies(parameters)
  }
}
