import NetworkLastItemPagination from "../entities/pagination/NetworkLastItemPagination"
import LastItemPagination from "../../../../admin/core/domain/entities/pages/LastItemPagination"
import isBlank from "../../../../admin/lib/isBlank"

export default class LastItemPaginationsMapper {
  mapDomainToNetwork({
    pagination
  }: {
    readonly pagination: LastItemPagination
  }): NetworkLastItemPagination {
    return {
      id: isBlank(pagination.id) ? undefined : parseInt(pagination.id),
      size: pagination.size
    }
  }
}
