import UserProfileRepository from "../../repositories/UserProfileRepository"

export default class ClearPermissionGroupUseCase {
  private readonly userProfileRepository: UserProfileRepository

  constructor(parameters: {
    readonly userProfileRepository: UserProfileRepository
  }) {
    this.userProfileRepository = parameters.userProfileRepository
  }

  call(): void {
    return this.userProfileRepository.clearPermissionGroup()
  }
}
