import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import RefuellingOrdersReportsRepository from "../../repositories/RefuellingOrdersReportsRepository"
import RefuellingOrdersReport from "../../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReport"

export interface GetRefuellingOrdersReportsParameters extends GetObjectsParameters {
}

export default class GetRefuellingOrdersReportsUseCase
  implements GetObjectsUseCase<RefuellingOrdersReport> {
  private readonly refuellingOrdersReportsRepository: RefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly refuellingOrdersReportsRepository: RefuellingOrdersReportsRepository
  }) {
    this.refuellingOrdersReportsRepository = parameters.refuellingOrdersReportsRepository
  }

  async call(parameters: GetRefuellingOrdersReportsParameters): Promise<GetObjectsResult<RefuellingOrdersReport>> {
    return await this.refuellingOrdersReportsRepository.getRefuellingOrdersReports(parameters)
  }
}
