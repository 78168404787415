import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import TripsI18 from "../../i18n/TripsI18"
import Transport from "../../../../core/domain/transport/Transport"
import TransportsFilter from "../../../../core/domain/transport/TransportsFilter"
import GetCarriersForTripsUseCase from "../../domain/use-cases/carriers/GetCarriersForTripsUseCase"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import Trip from "../../../../core/domain/trips/Trip"
import TripsFilter from "../../../../core/domain/trips/TripsFilter"
import GetDriversForTripsUseCase from "../../domain/use-cases/drivers/GetDriversForTripsUseCase"
import GetTransportsForTripsUseCase from "../../domain/use-cases/transports/GetTransportsForTripsUseCase"
import GetTripStatusesForTripsUseCase from "../../domain/use-cases/trip-statuses/GetTripStatusesForTripsUseCase"
import User from "../../../../core/domain/users/User"
import TripStatus, { TripStatusSelectOption } from "../../../../core/domain/trips/TripStatus"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import ArrayTableValue, {
  ArrayTableValueItem
} from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/ArrayTableValue"
import AppI18 from "../../../../core/i18n/AppI18"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import TripErrorsObject from "../../../../core/domain/trips/TripErrorsObject"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import isPresent from "../../../../../admin/lib/isPresent"
import DriversFilter from "../../../../core/domain/drivers/DriversFilter"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class TripsTableProvider implements TableProvider<Trip, TripsFilter> {
  private readonly timeZone: string
  private readonly coreI18n: CoreI18n
  private readonly appI18: AppI18
  private readonly tripsI18: TripsI18
  private readonly getCarriersUseCase: GetCarriersForTripsUseCase
  private readonly getDriversUseCase: GetDriversForTripsUseCase
  private readonly getTransportsUseCase: GetTransportsForTripsUseCase
  private readonly getTripStatusesUseCase: GetTripStatusesForTripsUseCase

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18n: CoreI18n
    readonly appI18: AppI18
    readonly tripsI18n: TripsI18
    readonly getCarriersUseCase: GetCarriersForTripsUseCase
    readonly getDriversUseCase: GetDriversForTripsUseCase
    readonly getTransportsUseCase: GetTransportsForTripsUseCase
    readonly getTripStatusesUseCase: GetTripStatusesForTripsUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.coreI18n = parameters.coreI18n
    this.appI18 = parameters.appI18
    this.tripsI18 = parameters.tripsI18n
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getDriversUseCase = parameters.getDriversUseCase
    this.getTransportsUseCase = parameters.getTransportsUseCase
    this.getTripStatusesUseCase = parameters.getTripStatusesUseCase
  }

  getEntity(): string {
    return Entity.TRIPS
  }

  searchByQueryIsEnable(): boolean {
    return true
  }

  getTable(): Table<Trip, TripsFilter> {
    const coreTextProvider = this.coreI18n.getTextProvider()
    const appUrlProvider = new AppUrlProvider()
    const appTextProvider = this.appI18.getTextProvider()
    const tripsTextProvider = this.tripsI18.getTextProvider()

    return new Table<Transport, TransportsFilter>({
      title: tripsTextProvider.listTitle(),
      getObjectId: (trip: Trip) => trip.id!.toString(),
      getRowUrl: (trip: Trip) => appUrlProvider.buildShowTripUrl({
        tripId: trip.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewTripUrl(),
      columns: [
        new TableColumn<Trip>({
          name: "id",
          title: tripsTextProvider.idField(),
          createValue: (trip: Trip) => new TextTableValue({
            value: tripsTextProvider.existObjectTitle({
              id: trip.id!
            })
          })
        }),
        new TableColumn<Trip>({
          name: "name",
          title: tripsTextProvider.nameField(),
          isSortingAvailable: true,
          createValue: (trip: Trip) => new TextTableValue({
            value: trip.name
          })
        }),
        new TableColumn<Trip>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: tripsTextProvider.carrierField(),
          createValue: (trip: Trip) => new TextTableValue({
            value: trip.carrier?.name,
            url: appUrlProvider.buildShowCarrierUrl({
              carrierId: trip.carrierId!
            })
          })
        }),
        new TableColumn<Trip>({
          name: "transport",
          entity: Entity.TRANSPORTS,
          title: tripsTextProvider.transportField(),
          createValue: (trip: Trip) => new TextTableValue({
            value: trip.transport?.name,
            url: appUrlProvider.buildShowTransportUrl({
              transportId: trip.transportId!
            }),
            additionalValue: trip.transport?.stateNumber
          })
        }),
        new TableColumn<Trip>({
          name: "drivers",
          entity: Entity.DRIVERS,
          title: tripsTextProvider.driversField(),
          createValue: (trip: Trip) => new ArrayTableValue({
            values: trip.userTrips?.map((userTrip): ArrayTableValueItem => {
              return {
                key: `${userTrip.id!}`,
                text: appTextProvider.driverName({
                  user: userTrip.user
                }),
                url: appUrlProvider.buildShowDriverUrl({
                  driverId: userTrip.userId!
                })
              }
            })
          })
        }),
        new TableColumn<Trip>({
          name: "startingAt",
          title: tripsTextProvider.startingAtField(),
          createValue: (trip: Trip) => new DateTableValue({
            value: trip.startingAt,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<Trip>({
          name: "finishingAt",
          title: tripsTextProvider.finishingAtField(),
          createValue: (trip: Trip) => new DateTableValue({
            value: trip.finishingAt,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<Trip>({
          name: "status",
          title: tripsTextProvider.statusField(),
          createValue: (trip: Trip) => new TextTableValue({
            value: trip.statusSelectOption?.text
          })
        }),
        new TableColumn<Trip>({
          name: "note",
          title: tripsTextProvider.noteField(),
          createValue: (trip: Trip) => new TextTableValue({
            value: trip.note
          })
        })
      ],
      filter: new Filter<TripsFilter>({
        buildFilterObject: (): TripsFilter => ({}),
        fields: [
          new DateFormField<Trip, TripErrorsObject>({
            title: tripsTextProvider.dateFromFilter(),
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "dateFrom",
            getValue: (tripsFilter: TripsFilter) => tripsFilter.dateFrom,
            setValue: (
              tripsFilter: TripsFilter,
              dateFrom: Date | null | undefined
            ): TripsFilter => ({
              ...tripsFilter, dateFrom
            })
          }),
          new DateFormField<Trip, TripErrorsObject>({
            title: tripsTextProvider.dateToFilter(),
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "dateTo",
            getValue: (tripsFilter: TripsFilter) => tripsFilter.dateTo,
            setValue: (
              tripsFilter: TripsFilter,
              dateTo: Date | null | undefined
            ): TripsFilter => ({
              ...tripsFilter, dateTo
            })
          }),
          new SingleSelectFormField<TripsFilter, void, Trip>({
            title: tripsTextProvider.carrierFilter(),
            entity: Entity.CARRIERS,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "carrierId",
            getValue: (filter: TripsFilter) => filter.carrier,
            setValue: (
              filter: TripsFilter,
              carrier: Carrier | null
            ): TripsFilter => ({
              ...filter,
              carrier,
              carrierId: carrier?.id,
              transport: null,
              transportId: null,
              driver: null,
              driverId: null
            }),
            getObjectsUseCase: this.getCarriersUseCase,
            getOptionId: (optionObject: Carrier) => optionObject.id!.toString(),
            getOptionText: (optionObject: Carrier) => optionObject.name
          }),
          new SingleSelectFormField<TripsFilter, void, Transport, TransportsFilter>({
            title: tripsTextProvider.transportFilter(),
            entity: Entity.TRANSPORTS,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getOptionObjectsFilter: (filter: TransportsFilter) => ({
              carrier: filter.carrier,
              carrierId: filter.carrierId
            }),
            getId: () => "transportId",
            getValue: (filter: TripsFilter) => filter.transport,
            setValue: (
              filter: TripsFilter,
              transport: Transport | null
            ): TripsFilter => ({
              ...filter,
              transport,
              transportId: transport?.id,
              carrier: isPresent(transport) ? transport.carrier : filter.carrier,
              carrierId: isPresent(transport) ? transport.carrierId : filter.carrierId
            }),
            getObjectsUseCase: this.getTransportsUseCase,
            getOptionId: (optionObject: Transport) => optionObject.id!.toString(),
            getOptionText: (optionObject: Transport) => `${optionObject.name} (${optionObject.stateNumber})`
          }),
          new SingleSelectFormField<TripsFilter, void, User, DriversFilter>({
            title: tripsTextProvider.driverFilter(),
            entity: Entity.DRIVERS,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getOptionObjectsFilter: (filter: DriversFilter) => ({
              carrier: filter.carrier,
              carrierId: filter.carrierId
            }),
            getId: () => "driverId",
            getValue: (filter: TripsFilter) => filter.driver,
            setValue: (
              filter: TripsFilter,
              user: User | null
            ): TripsFilter => ({
              ...filter,
              driver: user,
              driverId: user?.id,
              carrier: isPresent(user) ? user.carrier : filter.carrier,
              carrierId: isPresent(user) ? user.carrierId : filter.carrierId
            }),
            getObjectsUseCase: this.getDriversUseCase,
            getOptionId: (optionObject: User) => optionObject.id!.toString(),
            getOptionText: (optionObject: User) => appTextProvider.driverName({
              user: optionObject
            })
          }),
          new SingleSelectFormField<TripsFilter, void, TripStatusSelectOption>({
            title: tripsTextProvider.statusFilter(),
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            searchingEnabled: false,
            getId: () => "status",
            getValue: (filter: TripsFilter) => filter.statusSelectOption,
            setValue: (
              filter: TripsFilter,
              statusSelectOption: TripStatusSelectOption | null
            ): TripsFilter => ({
              ...filter,
              statusSelectOption,
              status: statusSelectOption?.id as TripStatus
            }),
            getObjectsUseCase: this.getTripStatusesUseCase,
            getOptionId: (optionObject: TripStatusSelectOption) => optionObject.id!.toString(),
            getOptionText: (optionObject: TripStatusSelectOption) => optionObject.text
          })
        ]
      })
    })
  }
}
