import TripsRepository from "../../repositories/TripsRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import Trip from "../../../../../core/domain/trips/Trip"
import TripError from "../../../../../core/domain/trips/TripError"

export interface UpdateTripParameters {
  readonly objectId: string
  readonly object: Trip
}

export default class UpdateTripUseCase implements UpdateObjectUseCase<Trip, TripError> {
  private readonly tripsRepository: TripsRepository

  constructor(parameters: {
    readonly tripsRepository: TripsRepository
  }) {
    this.tripsRepository = parameters.tripsRepository
  }

  async call(parameters: UpdateTripParameters): Promise<UpdateObjectResult<Trip, TripError>> {
    return await this.tripsRepository.updateTrip(parameters)
  }
}
