import CarrierBalanceTransactionsRepository from "../../domain/repositories/CarrierBalanceTransactionsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForCarrierBalancesRepository
  from "../../domain/repositories/CarriersForCarrierBalancesRepository"
import DefaultCarriersForCarrierBalancesRepository
  from "../../data/repositories/DefaultCarriersForCarrierBalancesRepository"
import DefaultCarrierBalanceTransactionsRepository
  from "../../data/repositories/DefaultCarrierBalanceTransactionsRepository"
import CarrierBalanceChangeDocumentsRepository from "../../domain/repositories/CarrierBalanceChangeDocumentsRepository"
import DefaultCarrierBalanceChangeDocumentsRepository
  from "../../data/repositories/DefaultCarrierBalanceChangeDocumentsRepository"

export default interface CarrierBalancesDataDiModule {
  provideCarrierBalanceTransactionsRepository(): CarrierBalanceTransactionsRepository

  provideCarrierBalanceChangeDocumentsRepository(): CarrierBalanceChangeDocumentsRepository

  provideCarriersForCarrierBalancesRepository(): CarriersForCarrierBalancesRepository
}

export class DefaultCarrierBalancesDataDiModule extends DiModule implements CarrierBalancesDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCarrierBalanceTransactionsRepository(): CarrierBalanceTransactionsRepository {
    return this.single(
      DefaultCarrierBalanceTransactionsRepository.name,
      () => new DefaultCarrierBalanceTransactionsRepository({
        carrierBalanceTransactionsNetworkSource: this.appDataDiModule.provideCarrierBalanceTransactionsNetworkSource()
      })
    )
  }

  provideCarrierBalanceChangeDocumentsRepository(): CarrierBalanceChangeDocumentsRepository {
    return this.single(
      DefaultCarrierBalanceChangeDocumentsRepository.name,
      () => new DefaultCarrierBalanceChangeDocumentsRepository({
        carrierBalanceChangeDocumentsNetworkSource:
          this.appDataDiModule.provideCarrierBalanceChangeDocumentsNetworkSource()
      })
    )
  }

  provideCarriersForCarrierBalancesRepository(): CarriersForCarrierBalancesRepository {
    return this.single(
      DefaultCarriersForCarrierBalancesRepository.name,
      () => new DefaultCarriersForCarrierBalancesRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }
}
