import CarrierCommercialTermsRepository from "../../domain/repositories/CarrierCommercialTermsRepository"
import { GetCarrierCommercialTermsParameters } from "../../domain/use-cases/carrier-commercial-terms/GetCarrierCommercialTermsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateCarrierCommercialTermParameters } from "../../domain/use-cases/carrier-commercial-terms/CreateCarrierCommercialTermUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetCarrierCommercialTermParameters } from "../../domain/use-cases/carrier-commercial-terms/GetCarrierCommercialTermUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateCarrierCommercialTermParameters } from "../../domain/use-cases/carrier-commercial-terms/UpdateCarrierCommercialTermUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import CarrierCommercialTermsNetworkSource
  from "../../../../core/data/sources/network/CarrierCommercialTermsNetworkSource"
import NetworkCarrierCommercialTermsRequestFilter
  from "../../../../core/data/entities/carrier-commercial-terms/request-queries/NetworkCarrierCommercialTermsRequestFilter"
import CarrierCommercialTermsMapper from "../../../../core/data/mappers/CarrierCommercialTermsMapper"
import CarrierCommercialTerm from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import CarrierCommercialTermError from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTermError"
import PricingTypesStaticSource from "../../../../core/data/sources/static/PricingTypesStaticSource"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultCarrierCommercialTermsRepository implements CarrierCommercialTermsRepository {
  private readonly carrierCommercialTermsNetworkSource: CarrierCommercialTermsNetworkSource
  private readonly pricingTypesStaticSource: PricingTypesStaticSource

  constructor(parameters: {
    readonly carrierCommercialTermsNetworkSource: CarrierCommercialTermsNetworkSource
    readonly pricingTypesStaticSource: PricingTypesStaticSource
  }) {
    this.carrierCommercialTermsNetworkSource = parameters.carrierCommercialTermsNetworkSource
    this.pricingTypesStaticSource = parameters.pricingTypesStaticSource
  }

  async getCarrierCommercialTerms({
    filter,
    pagination,
    sort
  }: GetCarrierCommercialTermsParameters): Promise<GetObjectsResult<CarrierCommercialTerm>> {
    const carrierId = filter?.carrierId
    const result = await this.carrierCommercialTermsNetworkSource.getCarrierCommercialTerms({
      filter: new NetworkCarrierCommercialTermsRequestFilter({
        carrierIds: isPresent(carrierId) ? [carrierId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    const carrierCommercialTermsMapper = new CarrierCommercialTermsMapper()

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.carrierCommercialTerms!.map((carrierCommercialTerm) => {
              return carrierCommercialTermsMapper.mapNetworkToDomain({
                carrierCommercialTerm,
                pricingTypeSelectOptions
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getCarrierCommercialTerm({
    objectId
  }: GetCarrierCommercialTermParameters): Promise<GetObjectResult<CarrierCommercialTerm>> {
    const result = await this.carrierCommercialTermsNetworkSource.getCarrierCommercialTerm({
      carrierCommercialTermId: parseInt(objectId)
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new CarrierCommercialTermsMapper().mapNetworkToDomain({
            carrierCommercialTerm: result.data.carrierCommercialTerm!,
            pricingTypeSelectOptions
          })
        }
      default:
        return result
    }
  }

  async createCarrierCommercialTerm({
    object: carrierCommercialTerm
  }: CreateCarrierCommercialTermParameters): Promise<CreateObjectResult<
    CarrierCommercialTerm, CarrierCommercialTermError
  >> {
    const mapper = new CarrierCommercialTermsMapper()
    const result = await this.carrierCommercialTermsNetworkSource.createCarrierCommercialTerm({
      carrierCommercialTerm: mapper.mapDomainToNetwork({ carrierCommercialTerm })
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            carrierCommercialTerm: result.data.carrierCommercialTerm!,
            pricingTypeSelectOptions
          })
        }
      default:
        return result
    }
  }

  async updateCarrierCommercialTerm({
    objectId: carrierCommercialTermId,
    object: carrierCommercialTerm
  }: UpdateCarrierCommercialTermParameters): Promise<UpdateObjectResult<
    CarrierCommercialTerm, CarrierCommercialTermError
  >> {
    const mapper = new CarrierCommercialTermsMapper()
    const result = await this.carrierCommercialTermsNetworkSource.updateCarrierCommercialTerm({
      carrierCommercialTermId: parseInt(carrierCommercialTermId),
      carrierCommercialTerm: mapper.mapDomainToNetwork({
        carrierCommercialTerm
      })
    })

    const pricingTypeSelectOptions = await this.pricingTypesStaticSource.getPricingTypes()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            carrierCommercialTerm: result.data.carrierCommercialTerm!,
            pricingTypeSelectOptions
          })
        }
      default:
        return result
    }
  }
}
