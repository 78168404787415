import Carrier from "../../../../../core/domain/carriers/Carrier"
import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import CarriersRepository from "../../repositories/CarriersRepository"

export interface GetCarrierParameters {
  readonly objectId: string
}

export default class GetCarrierUseCase implements GetObjectUseCase<Carrier> {
  private readonly carriersRepository: CarriersRepository

  constructor(parameters: {
    readonly carriersRepository: CarriersRepository
  }) {
    this.carriersRepository = parameters.carriersRepository
  }

  async call(parameters: GetCarrierParameters): Promise<GetObjectResult<Carrier>> {
    return await this.carriersRepository.getCarrier(parameters)
  }
}
