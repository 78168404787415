import RequestPasswordRecoveryUseCase
  from "../../domain/use-cases/password-recovery-request/RequestPasswordRecoveryUseCase"
import DiModule from "../../../../lib/di/DiModule"
import AuthenticationDataDiModule from "./AuthenticationDataDiModule"
import CheckTokenUseCase from "../../domain/use-cases/check-token/CheckTokenUseCase"
import RecoveryPasswordUseCase from "../../domain/use-cases/password-recovery/RecoveryPasswordUseCase"

export default interface AuthenticationDomainDiModule {
  provideRequestPasswordRecoveryUseCase(): RequestPasswordRecoveryUseCase

  provideRecoveryPasswordUseCase(): RecoveryPasswordUseCase

  provideCheckTokenUseCase(): CheckTokenUseCase
}

export class DefaultAuthenticationDomainDiModule extends DiModule implements AuthenticationDomainDiModule {
  private readonly authenticationDataDiModule: AuthenticationDataDiModule

  constructor(parameters: {
    readonly authenticationDataDiModule: AuthenticationDataDiModule
  }) {
    super()
    this.authenticationDataDiModule = parameters.authenticationDataDiModule
  }

  provideRequestPasswordRecoveryUseCase(): RequestPasswordRecoveryUseCase {
    return this.single(
      RequestPasswordRecoveryUseCase.name,
      () => new RequestPasswordRecoveryUseCase({
        passwordsRepository:
          this.authenticationDataDiModule.providePasswordsRepository()
      })
    )
  }

  provideRecoveryPasswordUseCase(): RecoveryPasswordUseCase {
    return this.single(
      RecoveryPasswordUseCase.name,
      () => new RecoveryPasswordUseCase({
        passwordsRepository:
          this.authenticationDataDiModule.providePasswordsRepository()
      })
    )
  }

  provideCheckTokenUseCase(): CheckTokenUseCase {
    return this.single(
      CheckTokenUseCase.name,
      () => new CheckTokenUseCase({
        passwordsRepository:
          this.authenticationDataDiModule.providePasswordsRepository()
      })
    )
  }
}
