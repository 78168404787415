import { Expose, Type } from "class-transformer"
import NetworkInstallationInstance from "../NetworkInstallationInstance"

export default class NetworkInstallationInstanceRequestBody {

  @Expose()
  @Type(() => NetworkInstallationInstance)
  readonly installationInstance?: NetworkInstallationInstance | null

  constructor(parameters: {
    readonly installationInstance?: NetworkInstallationInstance | null
  }) {
    this.installationInstance = parameters.installationInstance
  }
}
