import React, { createContext, useContext } from "react"
import ForbiddenTextProvider from "../../i18n/ForbiddenTextProvider"
import ForbiddenI18n from "../../i18n/ForbiddenI18n"

// TODO: remove duplication between the same files
export const ForbiddenTextProviderContext: React.Context<ForbiddenTextProvider | null> =
  createContext<ForbiddenTextProvider | null>(null)

export function useForbiddenTextProvider(): ForbiddenTextProvider {
  return useContext(ForbiddenTextProviderContext)!
}

export interface ForbiddenTextProviderProps {
  readonly children?: React.ReactNode
  readonly forbiddenI18n: ForbiddenI18n
}

export function ForbiddenTextProviderProvider({
  children,
  forbiddenI18n
}: ForbiddenTextProviderProps) {
  return (
    <ForbiddenTextProviderContext.Provider value={forbiddenI18n.getTextProvider()}>
      {children}
    </ForbiddenTextProviderContext.Provider>
  )
}
