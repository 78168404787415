import TripChangingLogTextProvider from "../TripChangingLogTextProvider"

const tripObjectType = "trip"

export default class DefaultRuTripChangingLogTextProvider implements TripChangingLogTextProvider {
  listTitle(): string {
    return "История изменений рейса"
  }

  idField(): string {
    return "№"
  }

  parentTripField(): string {
    return "Рейс"
  }

  actionField(): string {
    return "Действие"
  }

  changedByField(): string {
    return "Автор"
  }

  createdAtField(): string {
    return "Дата"
  }

  createdAction({
    objectName,
    objectType
  } : {
    readonly objectName: string
    readonly objectType: string
  }): string {
    return objectType === tripObjectType ? "Рейс создан" : `${objectName} создан`
  }

  driverAssignedAction({
    objectName
  }: {
    readonly objectName: string
  }): string {
    return `${objectName} назначен на рейс`
  }

  deletedAction({
    objectName
  } : {
    readonly objectName: string
  }): string {
    return `${objectName} удален`
  }

  driverTakenOffAction({
    objectName
  }: {
    readonly objectName: string
  }): string {
    return `${objectName} снят с рейса`
  }

  columnValueChangedAction({
    objectType,
    objectName,
    columnName,
    oldValue,
    newValue
  }: {
    readonly objectType: string
    readonly objectName: string
    readonly columnName: string
    readonly oldValue: string,
    readonly newValue: string
  }): string {
    const prefix = objectType === tripObjectType ? "" : `${objectName}: `
    return `${prefix}${this.columnName({ columnName })} ${this.columnChanging({ columnName })} c ${oldValue} на ${newValue}`
  }

  columnName({
    columnName
  }: {
    readonly columnName: string
  }): string {
    if (columnName === "name") {
      return "Название"
    }

    if (columnName === "status") {
      return "Статус"
    }

    if (columnName === "startingAt") {
      return "Дата начала"
    }

    if (columnName === "finishingAt") {
      return "Дата окончания"
    }

    if (columnName === "note") {
      return "Примечание"
    }

    if (columnName === "tripLimitTypeId") {
      return "Тип лимита"
    }

    if (columnName === "fuelId") {
      return "Вид топлива"
    }

    if (columnName === "value") {
      return "Значение"
    }

    return ""
  }

  columnChanging({
    columnName
  }: {
    readonly columnName: string
  }): string {
    if (columnName === "name" || columnName === "note" || columnName === "value") {
      return "изменено"
    }

    if (columnName === "startingAt" || columnName === "finishingAt") {
      return "изменена"
    }

    return "изменен"
  }

  tripNumber({
    tripNumber
  }: {
    readonly tripNumber: number
  }): string {
    return `№\u00A0${tripNumber}`
  }

  carrierField(): string {
    return "Перевозчик"
  }

  tripFilter(): string {
    return "Рейс"
  }

  carrierFilter(): string {
    return "Перевозчик"
  }

  changingTypeFilter(): string {
    return "Тип события"
  }

  startDateFilter(): string {
    return "Дата от"
  }

  endDateFilter(): string {
    return "Дата до"
  }
}
