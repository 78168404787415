import { Expose, Transform, Type } from "class-transformer"
import NetworkCarrier from "../carriers/NetworkCarrier"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"

export default class NetworkCarrierReconciliationReport {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Date)
  readonly createdAt?: Date | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly fromDate?: Date | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly toDate?: Date | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null

  @Expose()
  @Type(() => String)
  readonly statusName?: string | null

  @Expose()
  @Type(() => String)
  readonly documentUrl?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly needRefresh?: boolean | null
}
