import GetTripsUseCase from "../../domain/use-cases/trips/GetTripsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import TripsDataDiModule from "./TripsDataDiModule"
import CreateTripUseCase from "../../domain/use-cases/trips/CreateTripUseCase"
import GetTripUseCase from "../../domain/use-cases/trips/GetTripUseCase"
import UpdateTripUseCase from "../../domain/use-cases/trips/UpdateTripUseCase"
import GetCarriersForTripsUseCase from "../../domain/use-cases/carriers/GetCarriersForTripsUseCase"
import GetFuelsForTripLimitsUseCase from "../../domain/use-cases/fuels/GetFuelsForTripLimitsUseCase"
import GetDriversForTripsUseCase from "../../domain/use-cases/drivers/GetDriversForTripsUseCase"
import GetTransportsForTripsUseCase from "../../domain/use-cases/transports/GetTransportsForTripsUseCase"
import GetTripStatusesForTripsUseCase from "../../domain/use-cases/trip-statuses/GetTripStatusesForTripsUseCase"
import GetTripLimitTypesForTripLimitsUseCase
  from "../../domain/use-cases/trip-limits/GetTripLimitTypesForTripLimitsUseCase"

export default interface TripsDomainDiModule {
  provideGetTripsUseCase(): GetTripsUseCase

  provideGetTripUseCase(): GetTripUseCase

  provideCreateTripUseCase(): CreateTripUseCase

  provideUpdateTripUseCase(): UpdateTripUseCase

  provideGetCarriersForTripsUseCase(): GetCarriersForTripsUseCase

  provideGetDriversForTripsUseCase(): GetDriversForTripsUseCase

  provideGetTransportsForTripsUseCase(): GetTransportsForTripsUseCase

  provideGetTripStatusesForTripsUseCase(): GetTripStatusesForTripsUseCase

  provideGetFuelsForTripLimitsUseCase(): GetFuelsForTripLimitsUseCase

  provideGetTripLimitTypesForTripLimitsUseCase(): GetTripLimitTypesForTripLimitsUseCase
}

export class DefaultTripsDomainDiModule extends DiModule implements TripsDomainDiModule {
  private readonly tripsDataDiModule: TripsDataDiModule

  constructor(parameters: {
    readonly tripsDataDiModule: TripsDataDiModule
  }) {
    super()
    this.tripsDataDiModule = parameters.tripsDataDiModule
  }

  provideGetTripsUseCase(): GetTripsUseCase {
    return this.single(
      GetTripsUseCase.name,
      () => new GetTripsUseCase({
        tripsRepository: this.tripsDataDiModule.provideTripsRepository()
      })
    )
  }

  provideGetTripUseCase(): GetTripUseCase {
    return this.single(
      GetTripUseCase.name,
      () => new GetTripUseCase({
        tripsRepository: this.tripsDataDiModule.provideTripsRepository()
      })
    )
  }

  provideCreateTripUseCase(): CreateTripUseCase {
    return this.single(
      CreateTripUseCase.name,
      () => new CreateTripUseCase({
        tripsRepository: this.tripsDataDiModule.provideTripsRepository()
      })
    )
  }

  provideUpdateTripUseCase(): UpdateTripUseCase {
    return this.single(
      UpdateTripUseCase.name,
      () => new UpdateTripUseCase({
        tripsRepository: this.tripsDataDiModule.provideTripsRepository()
      })
    )
  }

  provideGetCarriersForTripsUseCase(): GetCarriersForTripsUseCase {
    return this.single(
      GetCarriersForTripsUseCase.name,
      () => new GetCarriersForTripsUseCase({
        carriersForTripsRepository: this.tripsDataDiModule.provideCarriersForTripsRepository()
      })
    )
  }

  provideGetDriversForTripsUseCase(): GetDriversForTripsUseCase {
    return this.single(
      GetDriversForTripsUseCase.name,
      () => new GetDriversForTripsUseCase({
        driversForTripsRepository: this.tripsDataDiModule.provideDriversForTripsRepository()
      })
    )
  }

  provideGetTransportsForTripsUseCase(): GetTransportsForTripsUseCase {
    return this.single(
      GetTransportsForTripsUseCase.name,
      () => new GetTransportsForTripsUseCase({
        transportsForTripsRepository: this.tripsDataDiModule.provideTransportsForTripsRepository()
      })
    )
  }

  provideGetTripStatusesForTripsUseCase(): GetTripStatusesForTripsUseCase {
    return this.single(
      GetTripStatusesForTripsUseCase.name,
      () => new GetTripStatusesForTripsUseCase({
        tripStatusesForTripsRepository: this.tripsDataDiModule.provideTripStatusesForTripsRepository()
      })
    )
  }

  provideGetFuelsForTripLimitsUseCase(): GetFuelsForTripLimitsUseCase {
    return this.single(
      GetFuelsForTripLimitsUseCase.name,
      () => new GetFuelsForTripLimitsUseCase({
        fuelsForTripLimitsRepository: this.tripsDataDiModule.provideFuelsForTripLimitsRepository()
      })
    )
  }

  provideGetTripLimitTypesForTripLimitsUseCase(): GetTripLimitTypesForTripLimitsUseCase {
    return this.single(
      GetTripLimitTypesForTripLimitsUseCase.name,
      () => new GetTripLimitTypesForTripLimitsUseCase({
        tripLimitTypesForTripLimitsRepository:
          this.tripsDataDiModule.provideTripLimitTypesForTripLimitsRepository()
      })
    )
  }
}
