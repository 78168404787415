import FuelCompaniesRepository from "../../repositories/FuelCompaniesRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompanyError from "../../../../../core/domain/fuel-companies/FuelCompanyError"

export interface CreateFuelCompanyParameters {
  readonly object: FuelCompany
}

export default class CreateFuelCompanyUseCase implements CreateObjectUseCase<FuelCompany, FuelCompanyError> {
  private readonly fuelCompaniesRepository: FuelCompaniesRepository

  constructor(parameters: {
    readonly fuelCompaniesRepository: FuelCompaniesRepository
  }) {
    this.fuelCompaniesRepository = parameters.fuelCompaniesRepository
  }

  async call(parameters: CreateFuelCompanyParameters): Promise<CreateObjectResult<FuelCompany, FuelCompanyError>> {
    return await this.fuelCompaniesRepository.createFuelCompany(parameters)
  }
}
