import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationsForInstallationInstancesRepository
  from "../../repositories/GasStationsForInstallationInstancesRepository"
import GasStationsFilter from "../../../../../core/domain/gas-stations/GasStationsFilter"

export interface GetGasStationsForInstallationInstanceParameters extends GetObjectsParameters<GasStationsFilter> {
}

export default class GetGasStationsForInstallationInstanceUseCase implements
  GetObjectsUseCase<GasStation, GasStationsFilter> {

  private readonly gasStationsForInstallationInstancesRepository: GasStationsForInstallationInstancesRepository

  constructor(parameters: {
    readonly gasStationsForInstallationInstancesRepository: GasStationsForInstallationInstancesRepository
  }) {
    this.gasStationsForInstallationInstancesRepository = parameters.gasStationsForInstallationInstancesRepository
  }

  async call(parameters: GetGasStationsForInstallationInstanceParameters): Promise<GetObjectsResult<GasStation>> {
    return await this.gasStationsForInstallationInstancesRepository.getGasStations(parameters)
  }
}
