import FuelCompanyUsersRepository from "../../domain/repositories/FuelCompanyUsersRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultFuelCompanyUsersRepository from "../../data/repositories/DefaultFuelCompanyUsersRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForFuelCompanyUsersRepository
  from "../../domain/repositories/FuelCompaniesForFuelCompanyUsersRepository"
import DefaultFuelCompaniesForFuelCompanyUsersRepository
  from "../../data/repositories/DefaultFuelCompaniesForFuelCompanyUsersRepository"
import GetFuelCompaniesForFuelCompanyUsersUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForFuelCompanyUsersUseCase"

export default interface FuelCompanyUsersDataDiModule {
  provideFuelCompanyUsersRepository(): FuelCompanyUsersRepository

  provideFuelCompaniesForFuelCompanyUsersRepository(): FuelCompaniesForFuelCompanyUsersRepository
}

export class DefaultFuelCompanyUsersDataDiModule extends DiModule implements FuelCompanyUsersDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideFuelCompanyUsersRepository(): FuelCompanyUsersRepository {
    return this.single(
      DefaultFuelCompanyUsersRepository.name,
      () => new DefaultFuelCompanyUsersRepository({
        fuelCompanyUsersNetworkSource: this.appDataDiModule.provideFuelCompanyUsersNetworkSource()
      })
    )
  }

  provideFuelCompaniesForFuelCompanyUsersRepository(): FuelCompaniesForFuelCompanyUsersRepository {
    return this.single(
      GetFuelCompaniesForFuelCompanyUsersUseCase.name,
      () => new DefaultFuelCompaniesForFuelCompanyUsersRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }
}
