import PasswordsRepository from "../../domain/repositories/PasswordsRepository"
import DiModule from "../../../../lib/di/DiModule"
import DefaultPasswordsRepository from "../../data/repositories/DefaultPasswordsRepository"
import AuthenticationNetworkDiModule from "./AuthenticationNetworkDiModule"

export default interface AuthenticationDataDiModule {
  providePasswordsRepository(): PasswordsRepository
}

export class DefaultAuthenticationDataDiModule extends DiModule implements AuthenticationDataDiModule {
  private readonly authenticationNetworkDiModule: AuthenticationNetworkDiModule

  constructor(parameters: {
    readonly authenticationNetworkDiModule: AuthenticationNetworkDiModule
  }) {
    super()
    this.authenticationNetworkDiModule = parameters.authenticationNetworkDiModule
  }

  providePasswordsRepository(): PasswordsRepository {
    return this.single(
      DefaultPasswordsRepository.name,
      () => new DefaultPasswordsRepository({
        passwordsNetworkSource: this.authenticationNetworkDiModule.providePasswordsNetworkSource()
      })
    )
  }
}
