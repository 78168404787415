import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import BaseRefuellingOrderInfoProvider from "./BaseRefuellingOrderInfoProvider"
import RefuellingOrderStatus from "../../../../core/domain/refuelling-orders/RefuellingOrderStatus"

export default class ClaimedRefuellingOrderInfoProvider extends BaseRefuellingOrderInfoProvider {

  getEditUrl({
    object
  }: {
    readonly object: RefuellingOrder
  }): string {
    if (object.status !== RefuellingOrderStatus.CLAIM) return ""

    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditClaimedRefuellingOrderUrl({
      refuellingOrderId: object.id!
    })
  }
}
