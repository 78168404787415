import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkGasStationsResponseBody from "../../entities/gas-stations/response-bodies/NetworkGasStationsResponseBody"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkGasStationsRequestQuery from "../../entities/gas-stations/request-queries/NetworkGasStationsRequestQuery"
import NetworkGasStationsRequestFilter
  from "../../entities/gas-stations/request-queries/NetworkGasStationsRequestFilter"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkGasStationResponseBody from "../../entities/gas-stations/response-bodies/NetworkGasStationResponseBody"
import NetworkGasStationErrorResponseBody
  from "../../entities/gas-stations/response-bodies/errors/NetworkGasStationErrorResponseBody"
import NetworkGasStationRequestBody from "../../entities/gas-stations/request-bodies/NetworkGasStationRequestBody"
import NetworkGasStation from "../../entities/gas-stations/NetworkGasStation"

const basePath = "/api/admin/gas_stations"

export default class GasStationsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getGasStations({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkGasStationsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetGasStationsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkGasStationsRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getGasStation({
    gasStationId
  }: {
    readonly gasStationId: number
  }): Promise<GetGasStationNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${gasStationId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createGasStation({
    gasStation
  }: {
    readonly gasStation?: NetworkGasStation | null
  }): Promise<CreateGasStationNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkGasStationRequestBody({
        gasStation
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkGasStationErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateGasStation({
    gasStationId,
    gasStation
  }: {
    readonly gasStationId: number
    readonly gasStation?: NetworkGasStation | null
  }): Promise<UpdateGasStationNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${gasStationId}`,
      body: instanceToPlain(new NetworkGasStationRequestBody({
        gasStation
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkGasStationErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetGasStationsNetworkResult =
  SuccessExecutionResult<NetworkGasStationsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetGasStationNetworkResult =
  SuccessExecutionResult<NetworkGasStationResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateGasStationNetworkResult =
  SuccessExecutionResult<NetworkGasStationResponseBody> |
  ErrorExecutionResult<NetworkGasStationErrorResponseBody> |
  FailureExecutionResult

export type UpdateGasStationNetworkResult =
  SuccessExecutionResult<NetworkGasStationResponseBody> |
  ErrorExecutionResult<NetworkGasStationErrorResponseBody> |
  FailureExecutionResult
