import NetworkAttributeError from "../errors/NetworkAttributeError"
import { Expose, Type } from "class-transformer"
import AdminNetworkCreateSessionErrorsObjectAttributes
  from "../../../../../admin/core/data/entities/network/sessions/NetworkCreateSessionErrorsObjectAttributes"

export default class NetworkCreateSessionErrorsObjectAttributes
  implements AdminNetworkCreateSessionErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly emailAddress?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly password?: NetworkAttributeError[] | null
}
