import RefuellingOrdersRepository from "../../domain/repositories/RefuellingOrdersRepository"
import { GetRefuellingOrdersParameters } from "../../domain/use-cases/refuelling-orders/GetRefuellingOrdersUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { GetRefuellingOrderParameters } from "../../domain/use-cases/refuelling-orders/GetRefuellingOrderUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateRefuellingOrderParameters } from "../../domain/use-cases/refuelling-orders/UpdateRefuellingOrderUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import isPresent from "../../../../../admin/lib/isPresent"
import RefuellingOrdersNetworkSource from "../../../../core/data/sources/network/RefuellingOrdersNetworkSource"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import NetworkRefuellingOrdersRequestFilter
  from "../../../../core/data/entities/refuelling-orders/request-queries/NetworkRefuellingOrdersRequestFilter"
import RefuellingOrdersMapper from "../../../../core/data/mappers/RefuellingOrdersMapper"
import RefuellingOrderError from "../../../../core/domain/refuelling-orders/RefuellingOrderError"
import DateTimeFormatter from "../../../../../admin/lib/DateTimeFormatter"
import RefuellingOrderStatusesStaticSource
  from "../../../../core/data/sources/static/RefuellingOrderStatusesStaticSource"

export default class DefaultRefuellingOrdersRepository implements RefuellingOrdersRepository {
  private readonly refuellingOrdersNetworkSource: RefuellingOrdersNetworkSource
  private readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource

  constructor(parameters: {
    readonly refuellingOrdersNetworkSource: RefuellingOrdersNetworkSource
    readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource
  }) {
    this.refuellingOrdersNetworkSource = parameters.refuellingOrdersNetworkSource
    this.refuellingOrderStatusesStaticSource = parameters.refuellingOrderStatusesStaticSource
  }

  async getRefuellingOrders({
    query,
    filter,
    pagination,
    sort
  }: GetRefuellingOrdersParameters): Promise<GetObjectsResult<RefuellingOrder>> {
    const carrierId = filter?.carrierId
    const fuelId = filter?.fuelId
    const fuelCompanyId = filter?.fuelCompanyId
    const gasStationId = filter?.gasStationId
    const driverId = filter?.driverId
    const transportId = filter?.transportId
    const dateFrom = filter?.dateFrom
    const dateTo = filter?.dateTo
    const status = filter?.status
    const tripId = filter?.tripId

    const result = await this.refuellingOrdersNetworkSource.getRefuellingOrders({
      filter: new NetworkRefuellingOrdersRequestFilter({
        query,
        carrierIds: isPresent(carrierId) ? [carrierId] : null,
        fuelIds: isPresent(fuelId) ? [fuelId] : null,
        fuelCompanyIds: isPresent(fuelCompanyId) ? [fuelCompanyId] : null,
        gasStationIds: isPresent(gasStationId) ? [gasStationId] : null,
        driverIds: isPresent(driverId) ? [driverId] : null,
        transportIds: isPresent(transportId) ? [transportId] : null,
        dateFrom: dateFrom && new DateTimeFormatter(dateFrom).formatISODate(),
        dateTo: dateTo && new DateTimeFormatter(dateTo).formatISODate(),
        statuses: isPresent(status) ? [status] : null,
        tripIds: isPresent(tripId) ? [tripId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const refuellingOrdersMapper = new RefuellingOrdersMapper()
    const refuellingOrderStatuses = await this.refuellingOrderStatusesStaticSource.getRefuellingOrderStatuses({})
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.refuellingOrders!.map((refuellingOrder) => {
              return refuellingOrdersMapper.mapNetworkToDomain({
                refuellingOrder: refuellingOrder,
                refuellingOrderStatuses: refuellingOrderStatuses
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getRefuellingOrder({
    objectId
  }: GetRefuellingOrderParameters): Promise<GetObjectResult<RefuellingOrder>> {
    const result = await this.refuellingOrdersNetworkSource.getRefuellingOrder({
      refuellingOrderId: parseInt(objectId)
    })

    const refuellingOrderStatuses = await this.refuellingOrderStatusesStaticSource.getRefuellingOrderStatuses({})
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new RefuellingOrdersMapper().mapNetworkToDomain({
            refuellingOrder: result.data.refuellingOrder!,
            refuellingOrderStatuses: refuellingOrderStatuses
          })
        }
      default:
        return result
    }
  }

  async updateRefuellingOrder({
    objectId: refuellingOrderId,
    object: refuellingOrder
  }: UpdateRefuellingOrderParameters): Promise<UpdateObjectResult<RefuellingOrder, RefuellingOrderError>> {
    const mapper = new RefuellingOrdersMapper()
    const result = await this.refuellingOrdersNetworkSource.updateRefuellingOrder({
      refuellingOrderId: parseInt(refuellingOrderId),
      refuellingOrder: mapper.mapDomainToNetwork({ refuellingOrder })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            refuellingOrder: result.data.refuellingOrder!,
            refuellingOrderStatuses: []
          })
        }
      default:
        return result
    }
  }
}
