export interface TableValueParameters {
  readonly url?: string | null
  readonly isActive?: boolean
  readonly openInNewTab?: boolean | null
  readonly valueColorHex?: string | null
}

export default class TableValue {
  private readonly url?: string | null
  private readonly isActive
  private readonly openInNewTab: boolean
  private readonly valueColorHex?: string | null

  constructor(parameters: TableValueParameters) {
    this.url = parameters.url
    this.isActive = parameters.isActive ?? true
    this.openInNewTab = parameters.openInNewTab ?? false
    this.valueColorHex = parameters.valueColorHex
  }

  getUrl() {
    return this.url
  }

  getIsActive() {
    return this.isActive
  }

  getOpenInNewTab() {
    return this.openInNewTab
  }

  getValueColorHex() {
    return this.valueColorHex
  }
}
