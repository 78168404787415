import NetworkCreateSessionErrorsObject from "../../entities/network/sessions/NetworkCreateSessionErrorsObject"
import CreateSessionErrorsObject from "../../../domain/entities/session/CreateSessionErrorsObject"
import CreateSessionErrorsObjectAttributesMapper from "./CreateSessionErrorsObjectAttributesMapper"

export default class CreateSessionErrorsObjectsMapper {
  mapNetworkToDomain({
    object
  }: {
    readonly object: NetworkCreateSessionErrorsObject
  }): CreateSessionErrorsObject {
    return {
      attributes: object.attributes && new CreateSessionErrorsObjectAttributesMapper().mapNetworkToDomain({
        object: object.attributes
      })
    }
  }
}
