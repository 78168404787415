import React from "react"
import { Helmet } from "react-helmet"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"

export default function HelmetComponent({
  title = "",
  children
}: {
  readonly title?: string
  readonly children?: React.ReactNode
}) {
  const core = useCoreTextProvider()

  return (
    <Helmet>
      <title>{`${core.appName()}${title === "" ? "" : `: ${title}`}`}</title>
      <meta name="description" content={core.appDescription()} />
      {children}
    </Helmet>
  )
}
