import { Expose, Type } from "class-transformer"
import ChangingType from "../../../../domain/changing-log-items/ChangingType"

export default class NetworkChangingLogItemsRequestFilter {
  @Expose()
  @Type(() => Number)
  readonly tripId?: number | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Type(() => String)
  readonly changingTypes?: ChangingType[] | null

  @Expose()
  @Type(() => String)
  readonly startDate?: string | null

  @Expose()
  @Type(() => String)
  readonly endDate?: string | null

  @Expose()
  @Type(() => Number)
  readonly changedByIds?: number[] | null

  constructor(parameters: {
    readonly tripId?: number | null
    readonly carrierId?: number | null
    readonly changingTypes?: ChangingType[] | null
    readonly startDate?: string | null
    readonly endDate?: string | null
    readonly changedByIds?: number[] | null
  }) {
    this.tripId = parameters.tripId
    this.carrierId = parameters.carrierId
    this.changingTypes = parameters.changingTypes
    this.startDate = parameters.startDate
    this.endDate = parameters.endDate
    this.changedByIds = parameters.changedByIds
  }
}
