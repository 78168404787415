import { Expose } from "class-transformer"

export default class NetworkUserProfile {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly firstName?: string | null

  @Expose()
  readonly lastName?: string | null
}
