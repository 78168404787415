import React, { useEffect } from "react"
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from "react-router-dom"
import Di, { createDi } from "./di/Di"
import { CoreTextProviderProvider } from "../admin/core/presentation/contexts/CoreTextProviderContext"
import {
  AuthenticationTextProviderProvider
} from "../admin/features/authentication/presentation/contexts/AuthenticationTextProviderContext"
import AdminCoreUrlProvider from "../admin/core/presentation/services/CoreUrlProvider"
import { CoreThemeProvider } from "../admin/core/presentation/contexts/CoreThemeProviderContext"
import CoreTheme from "../admin/core/presentation/theme/CoreTheme"
import AppCoreTheme from "./theme/AppCoreTheme"
import AppAuthenticationTheme from "./theme/AppAuthenticationTheme"
import {
  AuthenticationThemeProvider
} from "../admin/features/authentication/presentation/contexts/AuthenticationThemeProviderContext"
import AppRootTheme from "./theme/AppRootTheme"
import { RootThemeProvider } from "../admin/features/root/presentation/contexts/RootThemeProviderContext"
import AppUrlProvider from "./core/presentation/services/AppUrlProvider"
import { objectIdUrlPart } from "../admin/core/presentation/constants/urlParts"
import { carrierIdUrlPart, fuelCompanyIdUrlPart } from "./core/presentation/constants/urlParts"
import { RouteObject } from "react-router/dist/lib/context"
import isPresent from "../admin/lib/isPresent"
import MainPage from "../admin/features/root/presentation/pages/main/MainPage"

// TODO: think how to run app with another DI.
const di: Di = createDi()
const adminCoreUrlProvider = new AdminCoreUrlProvider()
const appUrlProvider = new AppUrlProvider()

const router = createBrowserRouter([
  {
    element: <Main />,
    children: [
      {
        path: "",
        element: <Navigate to={adminCoreUrlProvider.buildRootUrl()} replace={true} />
      },
      {
        path: adminCoreUrlProvider.buildRootUrl(),
        element: di.provideRootPage(),
        children: [
          ...buildMainPageRoutes(),
          ...buildRootRoutes(),
          ...buildCarriersRoutes(),
          ...buildCarrierCommercialTermsRoutes(),
          ...buildFuelCompaniesRoutes(),
          ...buildFuelCompanyCommercialTermsRoutes(),
          ...buildGasStationsRoutes(),
          ...buildFuelPricesRoutes(),
          ...buildInstallationInstancesRoutes(),
          ...buildTransportsRoutes(),
          ...buildDriversRoutes(),
          ...buildUsersRoutes(),
          ...buildCarrierUsersRoutes(),
          ...buildFuelCompanyUsersRoutes(),
          ...buildTripsRoutes(),
          ...buildTripChangingLogRoutes(),
          ...buildCarrierBalanceRoutes(),
          ...buildCurrentCarrierBalanceRoutes(),
          ...buildRefuellingOrdersRoutes(),
          ...buildClaimedRefuellingOrdersRoutes(),
          ...buildCancelledRefuellingOrdersRoutes(),
          ...buildFuelCompanyBalanceRoutes(),
          ...buildCurrentFuelCompanyBalanceRoutes(),
          ...buildRefuellingOrdersReportsRoutes(),
          ...buildCarrierReconciliationReportsRoutes(),
          ...buildFuelCompanyReconciliationReportsRoutes(),
          ...buildErrorPagesRoutes(),
          ...buildGasStationBreaksRoutes()
        ]
      },
      {
        path: adminCoreUrlProvider.buildSignInUrl(),
        element: di.provideSignInPage()
      },
      {
        path: adminCoreUrlProvider.buildPasswordRecoveryRequestUrl(),
        element: di.providePasswordRecoveryRequestPage()
      },
      {
        path: adminCoreUrlProvider.buildPasswordRecoveryUrl(),
        element: di.providePasswordRecoveryPage()
      }
    ]
  }
])

function App() {
  const coreI18n = di.provideAdminCoreI18n()
  const authenticationI18n = di.provideAdminAuthenticationI18n()

  const theme: CoreTheme = new AppCoreTheme()
  const authenticationTheme = new AppAuthenticationTheme()
  const rootTheme = new AppRootTheme()

  return (
    <CoreThemeProvider theme={theme}>
      <AuthenticationThemeProvider theme={authenticationTheme}>
        <RootThemeProvider theme={rootTheme}>
          <AuthenticationTextProviderProvider authenticationI18n={authenticationI18n}>
            <CoreTextProviderProvider coreI18n={coreI18n}>
              <RouterProvider router={router} />
            </CoreTextProviderProvider>
          </AuthenticationTextProviderProvider>
        </RootThemeProvider>
      </AuthenticationThemeProvider>
    </CoreThemeProvider>
  )
}

export default App

function buildMainPageRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildMainUrl(),
      element: <MainPage />
    }
  ]
}

function buildRootRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildRootUrl(),
      element: <Navigate to={appUrlProvider.buildRefuellingOrdersUrl()} replace={true} />
    }
  ]
}

function buildCarriersRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCarriersUrl(),
      element: di.provideCarriersListPage()
    },
    {
      path: appUrlProvider.buildNewCarrierUrl(),
      element: di.provideCarrierFormPage()
    },
    {
      path: appUrlProvider.buildShowCarrierUrl({ carrierId: `:${objectIdUrlPart}` }),
      element: di.provideCarrierInfoPage()
    },
    {
      path: appUrlProvider.buildEditCarrierUrl({ carrierId: `:${objectIdUrlPart}` }),
      element: di.provideCarrierFormPage()
    }
  ]
}

function buildCarrierCommercialTermsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCarrierCommercialTermsUrl(),
      element: di.provideCarrierCommercialTermsListPage()
    },
    {
      path: appUrlProvider.buildNewCarrierCommercialTermUrl(),
      element: di.provideCarrierCommercialTermFormPage()
    },
    {
      path: appUrlProvider.buildShowCarrierCommercialTermUrl({ carrierCommercialTermId: `:${objectIdUrlPart}` }),
      element: di.provideCarrierCommercialTermInfoPage()
    },
    {
      path: appUrlProvider.buildEditCarrierCommercialTermUrl({ carrierCommercialTermId: `:${objectIdUrlPart}` }),
      element: di.provideCarrierCommercialTermFormPage()
    }
  ]
}

function buildFuelCompaniesRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildFuelCompaniesUrl(),
      element: di.provideFuelCompaniesListPage()
    },
    {
      path: appUrlProvider.buildNewFuelCompanyUrl(),
      element: di.provideFuelCompanyFormPage()
    },
    {
      path: appUrlProvider.buildShowFuelCompanyUrl({ fuelCompanyId: `:${objectIdUrlPart}` }),
      element: di.provideFuelCompanyInfoPage()
    },
    {
      path: appUrlProvider.buildEditFuelCompanyUrl({ fuelCompanyId: `:${objectIdUrlPart}` }),
      element: di.provideFuelCompanyFormPage()
    }
  ]
}

function buildFuelCompanyCommercialTermsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildFuelCompanyCommercialTermsUrl(),
      element: di.provideFuelCompanyCommercialTermsListPage()
    },
    {
      path: appUrlProvider.buildNewFuelCompanyCommercialTermUrl(),
      element: di.provideFuelCompanyCommercialTermFormPage()
    },
    {
      path: appUrlProvider.buildShowFuelCompanyCommercialTermUrl({ fuelCompanyCommercialTermId: `:${objectIdUrlPart}` }),
      element: di.provideFuelCompanyCommercialTermInfoPage()
    },
    {
      path: appUrlProvider.buildEditFuelCompanyCommercialTermUrl({ fuelCompanyCommercialTermId: `:${objectIdUrlPart}` }),
      element: di.provideFuelCompanyCommercialTermFormPage()
    }
  ]
}

function buildGasStationsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildGasStationsUrl(),
      element: di.provideGasStationsListPage()
    },
    {
      path: appUrlProvider.buildNewGasStationUrl(),
      element: di.provideGasStationFormPage()
    },
    {
      path: appUrlProvider.buildShowGasStationUrl({ gasStationId: `:${objectIdUrlPart}` }),
      element: di.provideGasStationInfoPage()
    },
    {
      path: appUrlProvider.buildEditGasStationUrl({ gasStationId: `:${objectIdUrlPart}` }),
      element: di.provideGasStationFormPage()
    }
  ]
}

function buildFuelPricesRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildFuelPricesUrl(),
      element: di.provideFuelPricesListPage()
    },
    {
      path: appUrlProvider.buildNewFuelPriceUrl(),
      element: di.provideFuelPriceFormPage()
    },
    {
      path: appUrlProvider.buildShowFuelPriceUrl({ fuelPriceId: `:${objectIdUrlPart}` }),
      element: di.provideFuelPriceInfoPage()
    },
    {
      path: appUrlProvider.buildEditFuelPriceUrl({ fuelPriceId: `:${objectIdUrlPart}` }),
      element: di.provideFuelPriceFormPage()
    }
  ]
}

function buildInstallationInstancesRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildInstallationInstancesUrl(),
      element: di.provideInstallationInstancesListPage()
    },
    {
      path: appUrlProvider.buildNewInstallationInstanceUrl(),
      element: di.provideInstallationInstanceFormPage()
    },
    {
      path: appUrlProvider.buildShowInstallationInstanceUrl({ installationInstanceId: `:${objectIdUrlPart}` }),
      element: di.provideInstallationInstanceInfoPage()
    },
    {
      path: appUrlProvider.buildEditInstallationInstanceUrl({ installationInstanceId: `:${objectIdUrlPart}` }),
      element: di.provideInstallationInstanceFormPage()
    }
  ]
}

function buildTransportsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildTransportsUrl(),
      element: di.provideTransportsListPage()
    },
    {
      path: appUrlProvider.buildNewTransportUrl(),
      element: di.provideTransportFormPage()
    },
    {
      path: appUrlProvider.buildShowTransportUrl({ transportId: `:${objectIdUrlPart}` }),
      element: di.provideTransportInfoPage()
    },
    {
      path: appUrlProvider.buildEditTransportUrl({ transportId: `:${objectIdUrlPart}` }),
      element: di.provideTransportFormPage()
    }
  ]
}

function buildDriversRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildDriversUrl(),
      element: di.provideDriversListPage()
    },
    {
      path: appUrlProvider.buildNewDriverUrl(),
      element: di.provideDriverFormPage()
    },
    {
      path: appUrlProvider.buildShowDriverUrl({ driverId: `:${objectIdUrlPart}` }),
      element: di.provideDriverInfoPage()
    },
    {
      path: appUrlProvider.buildEditDriverUrl({ driverId: `:${objectIdUrlPart}` }),
      element: di.provideDriverFormPage()
    }
  ]
}

function buildUsersRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildUsersUrl(),
      element: di.provideUsersListPage()
    },
    {
      path: appUrlProvider.buildNewUserUrl(),
      element: di.provideUserFormPage()
    },
    {
      path: appUrlProvider.buildShowUserUrl({ userId: `:${objectIdUrlPart}` }),
      element: di.provideUserInfoPage()
    },
    {
      path: appUrlProvider.buildEditUserUrl({ userId: `:${objectIdUrlPart}` }),
      element: di.provideUserFormPage()
    }
  ]
}

function buildCarrierUsersRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCarrierUsersUrl(),
      element: di.provideCarrierUsersListPage()
    },
    {
      path: appUrlProvider.buildNewCarrierUserUrl(),
      element: di.provideCarrierUserFormPage()
    },
    {
      path: appUrlProvider.buildShowCarrierUserUrl({ userId: `:${objectIdUrlPart}` }),
      element: di.provideCarrierUserInfoPage()
    },
    {
      path: appUrlProvider.buildEditCarrierUserUrl({ userId: `:${objectIdUrlPart}` }),
      element: di.provideCarrierUserFormPage()
    }
  ]
}

function buildFuelCompanyUsersRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildFuelCompanyUsersUrl(),
      element: di.provideFuelCompanyUsersListPage()
    },
    {
      path: appUrlProvider.buildNewFuelCompanyUserUrl(),
      element: di.provideFuelCompanyUserFormPage()
    },
    {
      path: appUrlProvider.buildShowFuelCompanyUserUrl({ userId: `:${objectIdUrlPart}` }),
      element: di.provideFuelCompanyUserInfoPage()
    },
    {
      path: appUrlProvider.buildEditFuelCompanyUserUrl({ userId: `:${objectIdUrlPart}` }),
      element: di.provideFuelCompanyUserFormPage()
    }
  ]
}

function buildTripsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildTripsUrl(),
      element: di.provideTripsListPage()
    },
    {
      path: appUrlProvider.buildNewTripUrl(),
      element: di.provideTripFormPage()
    },
    {
      path: appUrlProvider.buildShowTripUrl({ tripId: `:${objectIdUrlPart}` }),
      element: di.provideTripInfoPage()
    },
    {
      path: appUrlProvider.buildEditTripUrl({ tripId: `:${objectIdUrlPart}` }),
      element: di.provideTripFormPage()
    }
  ]
}

function buildTripChangingLogRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildTripChangingLogUrl(),
      element: di.provideTripChangingLogPage()
    }
  ]
}

function buildCarrierBalanceRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCarrierBalanceUrl({ carrierId: `:${objectIdUrlPart}` }),
      element: di.provideCarrierBalancePage()
    },
    {
      path: appUrlProvider.buildCarrierBalanceReplenishmentUrl({ carrierId: `:${carrierIdUrlPart}` }),
      element: di.provideCarrierBalanceReplenishmentPage()
    }
  ]
}

function buildCurrentCarrierBalanceRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCurrentCarrierBalanceUrl(),
      element: di.provideCurrentCarrierBalancePage()
    }
  ]
}

function buildRefuellingOrdersRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildRefuellingOrdersUrl(),
      element: di.provideRefuellingOrdersListPage()
    },
    {
      path: appUrlProvider.buildShowRefuellingOrderUrl({ refuellingOrderId: `:${objectIdUrlPart}` }),
      element: di.provideRefuellingOrdersInfoPage()
    }
  ]
}

function buildClaimedRefuellingOrdersRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildClaimedRefuellingOrdersUrl(),
      element: di.provideClaimedRefuellingOrdersListPage()
    },
    {
      path: appUrlProvider.buildShowClaimedRefuellingOrderUrl({ refuellingOrderId: `:${objectIdUrlPart}` }),
      element: di.provideClaimedRefuellingOrdersInfoPage()
    },
    {
      path: appUrlProvider.buildEditClaimedRefuellingOrderUrl({ refuellingOrderId: `:${objectIdUrlPart}` }),
      element: di.provideRefuellingOrderFormPage()
    }
  ]
}

function buildCancelledRefuellingOrdersRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCancelledRefuellingOrdersUrl(),
      element: di.provideCancelledRefuellingOrdersListPage()
    },
    {
      path: appUrlProvider.buildShowCancelledRefuellingOrderUrl({ refuellingOrderId: `:${objectIdUrlPart}` }),
      element: di.provideCancelledRefuellingOrdersInfoPage()
    }
  ]
}

function buildFuelCompanyBalanceRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildFuelCompanyBalanceUrl({ fuelCompanyId: `:${fuelCompanyIdUrlPart}` }),
      element: di.provideFuelCompanyBalancePage()
    },
    {
      path: appUrlProvider.buildFuelCompanyBalanceChangeDocumentUrl({ fuelCompanyId: `:${fuelCompanyIdUrlPart}` }),
      element: di.provideFuelCompanyChangeDocumentPage()
    },
    {
      path: appUrlProvider.buildEditRefuellingOrderUrl({ refuellingOrderId: `:${objectIdUrlPart}` }),
      element: di.provideRefuellingOrderFormPage()
    }
  ]
}

function buildCurrentFuelCompanyBalanceRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCurrentFuelCompanyBalanceUrl(),
      element: di.provideCurrentFuelCompanyBalancePage()
    }
  ]
}

function buildRefuellingOrdersReportsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildRefuellingOrdersReportsUrl(),
      element: di.provideRefuellingOrdersReportsListPage()
    },
    {
      path: appUrlProvider.buildNewRefuellingOrdersReportsUrl(),
      element: di.provideRefuellingOrdersReportsFormPage()
    }
  ]
}

function buildCarrierReconciliationReportsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildCarrierReconciliationReportsUrl(),
      element: di.provideCarrierReconciliationReportsListPage()
    },
    {
      path: appUrlProvider.buildNewCarrierReconciliationReportsUrl(),
      element: di.provideCarrierReconciliationReportsFormPage()
    }
  ]
}

function buildFuelCompanyReconciliationReportsRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildFuelCompanyReconciliationReportsUrl(),
      element: di.provideFuelCompanyReconciliationReportsListPage()
    },
    {
      path: appUrlProvider.buildNewFuelCompanyReconciliationReportsUrl(),
      element: di.provideFuelCompanyReconciliationReportsFormPage()
    }
  ]
}

function buildGasStationBreaksRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildGasStationBreaksUrl(),
      element: di.provideGasStationBreaksListPage()
    },
    {
      path: appUrlProvider.buildNewGasStationBreaksUrl(),
      element: di.provideGasStationBreakFormPage()
    },
    {
      path: appUrlProvider.buildShowGasStationBreakUrl({ gasStationBreakId: `:${objectIdUrlPart}` }),
      element: di.provideGasStationBreakInfoPage()
    },
    {
      path: appUrlProvider.buildEditGasStationBreakUrl({ gasStationBreakId: `:${objectIdUrlPart}` }),
      element: di.provideGasStationBreakFormPage()
    }
  ]
}

function buildErrorPagesRoutes(): RouteObject[] {
  return [
    {
      path: appUrlProvider.buildForbiddenUrl(),
      element: di.provideForbiddenPage()
    },
    {
      path: "*",
      element: di.provideNotFoundPage()
    }
  ]
}

// TODO: Provide element with DI, create class for send analytics
function Main() {
  const location = useLocation()

  let url: string = location.pathname

  if (isPresent(location.search)) {
    url = `${url}${location.search}`
  }

  if (isPresent(location.hash)) {
    url = `${url}${location.hash}`
  }

  useEffect(() => {
    ym(process.env.REACT_APP_YANDEX_METRIKA_COUNTER_ID!, "hit", url)
  }, [url])

  return <Outlet />
}
