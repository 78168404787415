import autoBind from "auto-bind"
import CarrierBalanceChangeDocumentFormProvider from "../../form-providers/CarrierBalanceChangeDocumentFormProvider"
import Presenter from "../../../../../../admin/lib/presenter/Presenter"
import CarrierBalanceChangeDocumentFormView, {
  AbstractCarrierBalanceChangeDocumentFormViewState,
  CarrierBalanceChangeDocumentFormViewState
} from "./CarrierBalanceChangeDocumentFormView"
import BroadcastObjectsEventUseCase
  from "../../../../../../admin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import Form from "../../../../../../admin/features/objects/presentation/entities/forms/Form"
import ApplicationException from "../../../../../../admin/core/domain/exceptions/ApplicationException"
import FormField, {
  FormFieldViewState
} from "../../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import assertNever from "../../../../../../admin/lib/assertNever"
import CheckPermissionDeniedUseCase
  from "../../../../../../admin/core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import FormProviderUtils from "../../../../../../admin/features/objects/presentation/providers/FormProviderUtils"
import CarrierBalanceChangeDocumentErrorsObject
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentErrorsObject"
import CarrierBalanceChangeDocument
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import CarrierBalanceChangeDocumentError
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentError"
import CreateCarrierBalanceChangeDocumentUseCase
  from "../../../domain/use-cases/carrier-balance-change-documents/CreateCarrierBalanceChangeDocumentUseCase"

export default class CarrierBalanceChangeDocumentFormPresenter
  extends Presenter<CarrierBalanceChangeDocumentFormView> {

  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly createCarrierBalanceChangeDocumentUseCase:
    CreateCarrierBalanceChangeDocumentUseCase

  private readonly formProvider: CarrierBalanceChangeDocumentFormProvider
  private form!: Form<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>
  private title!: string
  private readonly carrierId!: string | number
  private carrierBalanceChangeDocument?: CarrierBalanceChangeDocument
  private changingError?: CarrierBalanceChangeDocumentError
  private changingException?: ApplicationException
  private carrierBalanceChangeDocumentFormViewState?: CarrierBalanceChangeDocumentFormViewState
  private readonly formProviderUtils:
    FormProviderUtils<
      CarrierBalanceChangeDocument,
      CarrierBalanceChangeDocumentError,
      CarrierBalanceChangeDocumentErrorsObject
    >

  constructor(parameters: {
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly createCarrierBalanceChangeDocumentUseCase: CreateCarrierBalanceChangeDocumentUseCase
    readonly formProvider: CarrierBalanceChangeDocumentFormProvider
    readonly carrierId: string | number
  }) {
    super()

    autoBind(this)

    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.createCarrierBalanceChangeDocumentUseCase =
      parameters.createCarrierBalanceChangeDocumentUseCase
    this.formProvider = parameters.formProvider
    this.carrierId = parameters.carrierId

    this.buildForm()
    this.buildTitles()

    this.formProviderUtils = this.buildFormProviderUtils(parameters.checkPermissionDeniedUseCase)
    this.formProviderUtils.initFormByPermissions()
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()

    if (this.formProviderUtils.isFormVisibleByPermission()) {
      this.buildAndShowCarrierBalanceChangeDocument().then()
    } else {
      this.setAndShowForbiddenCarrierBalanceChangeDocumentFormViewState()
    }
  }

  protected onViewReAttach() {
    super.onViewReAttach()
    this.showCarrierBalanceChangeDocumentFormViewState()
  }

  onSubmitObjectClicked() {
    this.createCarrierBalanceChangeDocumentAndShowList().then()
  }

  onCancelClicked() {
    this.setAndShowCancelingCarrierBalanceChangeDocumentFormViewState()
  }

  private buildForm() {
    this.form = new Form<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>({
      permissionsSet: {
        canCreate: true,
        canDestroy: false,
        canUpdate: false
      },
      groups: this.buildFieldsGroups(),
      fields: this.buildFormFields()
    })
  }

  private buildTitles() {
    this.title = this.formProvider.getNewObjectTitle()
  }

  private buildFieldsGroups(): FormFieldGroup[] {
    return this.formProvider.getFieldGroups()
  }

  private buildFormFields(): FormField<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject>[] {
    const formFields = this.formProvider.getFields()

    formFields.forEach((formField) => {
      formField.setSetObject(this.setCarrierBalanceChangeDocument)
      formField.setSetAndShowLoadedFuelCompanyBalanceChangeDocumentViewState(
        this.setAndShowIdleCarrierBalanceChangeDocumentFormViewState
      )
    })

    return formFields
  }

  private async buildAndShowCarrierBalanceChangeDocument(): Promise<void> {
    const newObject = await this.formProvider.buildObject()
    this.setCarrierBalanceChangeDocument(newObject)
    this.buildTitles()
    this.setAndShowIdleCarrierBalanceChangeDocumentFormViewState()
  }

  private async createCarrierBalanceChangeDocumentAndShowList(): Promise<void> {
    this.clearChangingError()
    this.clearChangingException()
    this.setAndShowCreatingCarrierBalanceChangeDocumentFormViewState()

    const result = await this.createCarrierBalanceChangeDocumentUseCase.call({
      object: this.carrierBalanceChangeDocument!,
      carrierId: this.carrierId
    })

    switch (result.type) {
      case "success":
        this.broadcastObjectsEventUseCase.call({ type: "created" })
        this.setAndShowCancelingCarrierBalanceChangeDocumentFormViewState()
        break
      case "error":
        this.setChangingError(result.error)
        this.setAndShowIdleCarrierBalanceChangeDocumentFormViewState()
        break
      case "failure":
        this.setChangingException(result.exception)
        this.setAndShowIdleCarrierBalanceChangeDocumentFormViewState()
        break
      default:
        assertNever(result)
    }
  }

  private buildFieldViewStates(): FormFieldViewState[] {
    const errorsObject = this.formProvider.getErrorsObject({
      error: this.changingError
    }) ?? undefined

    return this.form.getFormFields()
      .map((formField: FormField<
        CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentErrorsObject
      >): FormFieldViewState => {
      return formField.getViewState(this.carrierBalanceChangeDocument!, errorsObject)
    })
  }

  private setAndShowIdleCarrierBalanceChangeDocumentFormViewState() {
    this.setAndShowCarrierBalanceChangeDocumentFormViewState({
      ...this.buildAbstractCarrierBalanceChangeDocumentFormViewState(),
      type: "idle",
      fieldViewStates: this.buildFieldViewStates(),
      changingError: this.changingError,
      changingException: this.changingException
    })
  }

  private setAndShowCreatingCarrierBalanceChangeDocumentFormViewState() {
    this.setAndShowCarrierBalanceChangeDocumentFormViewState({
      ...this.buildAbstractCarrierBalanceChangeDocumentFormViewState(),
      type: "creating",
      fieldViewStates: this.buildFieldViewStates()
    })
  }

  private setAndShowCancelingCarrierBalanceChangeDocumentFormViewState() {
    this.setAndShowCarrierBalanceChangeDocumentFormViewState({
      ...this.buildAbstractCarrierBalanceChangeDocumentFormViewState(),
      type: "canceling"
    })
  }

  private setAndShowForbiddenCarrierBalanceChangeDocumentFormViewState() {
    this.setAndShowCarrierBalanceChangeDocumentFormViewState({
      type: "forbidden"
    })
  }

  private buildAbstractCarrierBalanceChangeDocumentFormViewState(): AbstractCarrierBalanceChangeDocumentFormViewState {
    return {
      form: this.form,
      title: this.title,
      fieldViewStates: this.buildFieldViewStates()
    }
  }

  private setAndShowCarrierBalanceChangeDocumentFormViewState(
    carrierBalanceChangeDocumentFormViewState: CarrierBalanceChangeDocumentFormViewState
  ) {
    this.setCarrierBalanceChangeDocumentFormViewStateFormViewState(carrierBalanceChangeDocumentFormViewState)
    this.showCarrierBalanceChangeDocumentFormViewState()
  }

  private setCarrierBalanceChangeDocumentFormViewStateFormViewState(
    carrierBalanceChangeDocumentFormViewState: CarrierBalanceChangeDocumentFormViewState
  ) {
    this.carrierBalanceChangeDocumentFormViewState = carrierBalanceChangeDocumentFormViewState
  }

  private showCarrierBalanceChangeDocumentFormViewState() {
    this.carrierBalanceChangeDocumentFormViewState &&
      this.getView()?.showCarrierBalanceChangeDocumentFormViewState(
        this.carrierBalanceChangeDocumentFormViewState
      )
  }

  private setCarrierBalanceChangeDocument(carrierBalanceChangeDocument: CarrierBalanceChangeDocument) {
    this.carrierBalanceChangeDocument = carrierBalanceChangeDocument
  }

  private setChangingError(changingError: CarrierBalanceChangeDocumentError) {
    this.changingError = changingError
  }

  private clearChangingError() {
    this.changingError = undefined
  }

  private setChangingException(changingException: ApplicationException) {
    this.changingException = changingException
  }

  private clearChangingException() {
    this.changingException = undefined
  }

  private buildFormProviderUtils(checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase) {
    return new FormProviderUtils<
      CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentError, CarrierBalanceChangeDocumentErrorsObject
    >({
      formProvider: this.formProvider,
      form: this.form,
      checkPermissionDeniedUseCase: checkPermissionDeniedUseCase,
      isNewObject: true,
      object: this.carrierBalanceChangeDocument!
    })
  }
}
