import FuelCompanyUsersRepository from "../../repositories/FuelCompanyUsersRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import UsersFilter from "../../../../../core/domain/users/UsersFilter"

export interface GetFuelCompanyUsersParameters extends GetObjectsParameters<UsersFilter> {
}

export default class GetFuelCompanyUsersUseCase implements GetObjectsUseCase<User> {
  private readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository

  constructor(parameters: {
    readonly fuelCompanyUsersRepository: FuelCompanyUsersRepository
  }) {
    this.fuelCompanyUsersRepository = parameters.fuelCompanyUsersRepository
  }

  async call(parameters: GetFuelCompanyUsersParameters): Promise<GetObjectsResult<User>> {
    return await this.fuelCompanyUsersRepository.getUsers(parameters)
  }
}
