import ChangingLogItemsRepository from "../../domain/repositories/ChangingLogItemsRepository"
import { GetChangingLogItemsParameters } from "../../domain/use-cases/changing-log-items/GetChangingLogItemsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import ChangingLogItemsNetworkSource from "../../../../core/data/sources/network/ChangingLogItemsNetworkSource"
import ChangingLogItem from "../../../../core/domain/changing-log-items/ChangingLogItem"
import NetworkChangingLogItemsRequestFilter
  from "../../../../core/data/entities/changing-log-items/request-queries/NetworkChangingLogItemsRequestFilter"
import ChangingLogItemsMapper from "../../../../core/data/mappers/ChangingLogItemsMapper"
import DateTimeFormatter from "../../../../../admin/lib/DateTimeFormatter"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultChangingLogItemsRepository implements ChangingLogItemsRepository {
  private readonly changingLogItemsNetworkSource: ChangingLogItemsNetworkSource

  constructor(parameters: {
    readonly changingLogItemsNetworkSource: ChangingLogItemsNetworkSource
  }) {
    this.changingLogItemsNetworkSource = parameters.changingLogItemsNetworkSource
  }

  async getChangingLogItems({
    filter,
    pagination,
    sort
  }: GetChangingLogItemsParameters): Promise<GetObjectsResult<ChangingLogItem>> {
    const tripId = filter?.tripId
    const carrierId = filter?.carrierId
    const startDate = filter?.startDate
    const endDate = filter?.endDate
    const changingType = filter?.changingType
    const changedById = filter?.changedById
    const result = await this.changingLogItemsNetworkSource.getChangingLogItems({
      filter: new NetworkChangingLogItemsRequestFilter({
        tripId,
        carrierId,
        startDate: startDate && new DateTimeFormatter(startDate).formatISODate(),
        endDate: endDate && new DateTimeFormatter(endDate).formatISODate(),
        changingTypes: isPresent(changingType) ? [changingType] : null,
        changedByIds: isPresent(changedById) ? [changedById] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success": {
        const changingLogItemsMapper = new ChangingLogItemsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.changingLogItems!.map((changingLogItem) => {
              return changingLogItemsMapper.mapNetworkToDomain({ changingLogItem })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
