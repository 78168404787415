import React, { ReactNode } from "react"
import styles from "./LoadingIndicatorComponent.module.scss"

export default function LoadingIndicatorComponent({
  className = "",
  children
}: {
  readonly className?: string
  readonly children: ReactNode
}) {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.indicator}>
        { children }
      </div>
    </div>
  )
}
