import { Expose, Type } from "class-transformer"
import UserTripStatus from "../../../domain/user-trips/UserTripStatus"
import NetworkUser from "../users/NetworkUser"
import NetworkTrip from "../trips/NetworkTrip"

export default class NetworkUserTrip {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Number)
  readonly userId?: number | null

  @Expose()
  @Type(() => String)
  readonly status?: UserTripStatus | null

  @Expose()
  @Type(() => NetworkUser)
  readonly user?: NetworkUser | null

  @Expose()
  @Type(() => NetworkTrip)
  readonly trip?: NetworkTrip | null
}
