import NetworkTransportFuelErrorsObjectAttributes from "./NetworkTransportFuelErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import TransportFuelErrorsObject from "../../../../../domain/transport-fuel/TransportFuelErrorsObject"

export default class NetworkTransportFuelErrorsObject implements TransportFuelErrorsObject {
  @Expose()
  @Type(() => String)
  readonly clientId?: string | null

  @Expose()
  @Type(() => NetworkTransportFuelErrorsObjectAttributes)
  readonly attributes?: NetworkTransportFuelErrorsObjectAttributes | null
}
