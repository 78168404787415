import { Expose, Type } from "class-transformer"
import NetworkFuelCompanyReconciliationReportErrorsObjectAttributes from "./NetworkFuelCompanyReconciliationReportErrorsObjectAttributes"
import FuelCompanyReconciliationReportErrorsObject
  from "../../../../../domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReportErrorsObject"

export default class NetworkFuelCompanyReconciliationReportErrorsObject
  implements FuelCompanyReconciliationReportErrorsObject {
  @Expose()
  @Type(() => NetworkFuelCompanyReconciliationReportErrorsObjectAttributes)
  readonly attributes?: NetworkFuelCompanyReconciliationReportErrorsObjectAttributes | null
}
