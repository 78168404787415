import DiModule from "../../../../../admin/lib/di/DiModule"
import RootDataDiModule from "../../../../../admin/features/root/di/modules/RootDataDiModule"
import MenusRepository from "../../../../../admin/features/root/domain/repositories/menus/MenusRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import DefaultMenusRepository from "../../data/repositories/menus/DefaultMenusRepository"
import AuthorizationErrorRepository
  from "../../../../../admin/features/root/domain/repositories/authorization-error/AuthorizationErrorRepository"
import DefaultAuthorizationErrorRepository
  from "../../data/repositories/authorization-error/DefaultAuthorizationErrorRepository"

export default class DefaultRootDataDiModule extends DiModule implements RootDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideMenusRepository(): MenusRepository {
    return this.single(
      DefaultMenusRepository.name,
      () => new DefaultMenusRepository({
        menusNetworkSource: this.appDataDiModule.provideMenusNetworkSource()
      })
    )
  }

  provideAuthorizationErrorRepository(): AuthorizationErrorRepository {
    return this.single(
      DefaultAuthorizationErrorRepository.name,
      () => new DefaultAuthorizationErrorRepository({
        authorizationErrorNetworkSource: this.appDataDiModule.provideAuthorizationErrorNetworkSource()
      })
    )
  }
}
