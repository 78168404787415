import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import FuelCompanyReconciliationReportsI18n from "../../i18n/FuelCompanyReconciliationReportsI18n"
import GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase
  from "../../domain/use-cases/fuel-company/GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompanyReconciliationReport
  from "../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReport"
import FuelCompanyReconciliationReportError
  from "../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReportError"
import FuelCompanyReconciliationReportErrorsObject
  from "../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReportErrorsObject"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import GetUserTypeUseCase from "../../../../../admin/core/domain/use-cases/user-profile/GetUserTypeUseCase"

const mainGroupName = "main"

export default class FuelCompanyReconciliationReportsFormProvider
  implements FormProvider<
    FuelCompanyReconciliationReport, FuelCompanyReconciliationReportError, FuelCompanyReconciliationReportErrorsObject
  > {
  private readonly fuelCompanyReconciliationReportsI18n: FuelCompanyReconciliationReportsI18n
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase
  private readonly getUserTypeUseCase: GetUserTypeUseCase

  constructor(parameters: {
    readonly fuelCompaniesReconciliationReportsI18: FuelCompanyReconciliationReportsI18n
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase
    readonly getUserTypeUseCase: GetUserTypeUseCase
  }) {
    this.fuelCompanyReconciliationReportsI18n = parameters.fuelCompaniesReconciliationReportsI18
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getUserTypeUseCase = parameters.getUserTypeUseCase
  }

  getEntity(): string {
    return Entity.FUEL_COMPANY_RECONCILIATION_REPORTS
  }

  getNewObjectTitle(): string {
    const userType = this.getUserTypeUseCase.call()
    return this.fuelCompanyReconciliationReportsI18n.getTextProvider().newObjectTitle({
      userType
    })
  }

  getExistedObjectTitle(): string {
    return ""
  }

  getExistedObjectShortTitle(): string {
    return ""
  }

  async buildObject(): Promise<FuelCompanyReconciliationReport> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: FuelCompanyReconciliationReportError
  }): FuelCompanyReconciliationReportErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<FuelCompanyReconciliationReport, FuelCompanyReconciliationReportErrorsObject>[] {
    const fuelCompanyReconciliationReportsTextProvider = this.fuelCompanyReconciliationReportsI18n.getTextProvider()

    return [
      new DateFormField<FuelCompanyReconciliationReport, FuelCompanyReconciliationReportErrorsObject>({
        title: fuelCompanyReconciliationReportsTextProvider.fromDateField(),
        clearable: true,
        required: true,
        groupName: mainGroupName,
        getId: () => "fromDate",
        getValue: (report: FuelCompanyReconciliationReport) => report.fromDate,
        setValue: (
          report: FuelCompanyReconciliationReport,
          fromDate: Date | null | undefined
        ): FuelCompanyReconciliationReport => ({
          ...report, fromDate
        }),
        getErrors: (errorsObject?: FuelCompanyReconciliationReportErrorsObject) => errorsObject?.attributes?.fromDate
      }),
      new DateFormField<FuelCompanyReconciliationReport, FuelCompanyReconciliationReportErrorsObject>({
        title: fuelCompanyReconciliationReportsTextProvider.toDateField(),
        clearable: true,
        required: true,
        groupName: mainGroupName,
        getId: () => "toDate",
        getValue: (report: FuelCompanyReconciliationReport) => report.toDate,
        setValue: (
          report: FuelCompanyReconciliationReport,
          toDate: Date | null | undefined
        ): FuelCompanyReconciliationReport => ({
          ...report, toDate
        }),
        getErrors: (errorsObject?: FuelCompanyReconciliationReportErrorsObject) => errorsObject?.attributes?.toDate
      }),
      new SingleSelectFormField<
        FuelCompanyReconciliationReport, FuelCompanyReconciliationReportErrorsObject, FuelCompany
      >({
        title: fuelCompanyReconciliationReportsTextProvider.fuelCompanyField(),
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelCompaniesUseCase,
        required: true,
        getId: () => "fuelCompany",
        getOptionId: (fuelCompany: FuelCompany) => fuelCompany.id!.toString(),
        getOptionText: (fuelCompany: FuelCompany) => fuelCompany.name,
        getValue: (report: FuelCompanyReconciliationReport) => report.fuelCompany,
        setValue: (report: FuelCompanyReconciliationReport, fuelCompany: FuelCompany | null) => {
          return {
            ...report,
            fuelCompany,
            fuelCompanyId: fuelCompany?.id
          }
        },
        getErrors: (
          errorsObject?: FuelCompanyReconciliationReportErrorsObject
        ) => errorsObject?.attributes?.fuelCompanyId
      })
    ]
  }
}
