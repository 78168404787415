import DiModule from "../../../admin/lib/di/DiModule"
import ForbiddenThemeDiModule from "../../../admin/features/forbidden/di/modules/ForbiddenThemeDiModule"
import ForbiddenTheme from "../../../admin/features/forbidden/presentation/theme/ForbiddenTheme"
import AppForbiddenTheme from "../../theme/AppForbiddenTheme"

export default class DefaultForbiddenThemeDiModule extends DiModule implements ForbiddenThemeDiModule {
  provideForbiddenTheme(): ForbiddenTheme {
    return this.single(
      AppForbiddenTheme.name,
      () => new AppForbiddenTheme()
    )
  }
}
