import { Expose, Type } from "class-transformer"
import NetworkTransportsRequestFilter from "./NetworkTransportsRequestFilter"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkTransportsRequestQuery {
  @Expose()
  @Type(() => NetworkTransportsRequestFilter)
  readonly filter?: NetworkTransportsRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  @Type(() => String)
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkTransportsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
