import styles from "./FilterComponent.module.scss"
import { FormFieldViewState } from "../../../../features/objects/presentation/entities/forms/FormField"
import {
  SingleSelectFormFieldViewState
} from "../../../../features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import SingleSelectFormFieldComponent
  from "../../../../features/objects/presentation/components/form-field-by-type/single-select-form-field/SingleSelectFormFieldComponent"
import ButtonComponent, { ButtonType } from "../../../../lib/button/ButtonComponent"
import React from "react"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import CoreTheme from "../../theme/CoreTheme"
import { useCoreThemeProvider } from "../../contexts/CoreThemeProviderContext"
import {
  DateFormFieldViewState
} from "../../../../features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import DateFormFieldComponent
  from "../../../../features/objects/presentation/components/form-field-by-type/date-form-field/DateFormFieldComponent"

export interface Props {
  readonly filterFieldViewStates: FormFieldViewState[]
  readonly onResetFilterClicked: () => void
  readonly onApplyFilterClicked: () => void
}

export default function FilterComponent({
  filterFieldViewStates,
  onResetFilterClicked,
  onApplyFilterClicked
}: Props) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const theme: CoreTheme = useCoreThemeProvider()

  return (
    <div className={styles.filter}>
      <div className={styles.body}>
        {filterFieldViewStates.map((filterFieldViewState: FormFieldViewState) => (
          (filterFieldViewState.getVisible() &&
            <div
              key={filterFieldViewState.getId()}
              className={styles.field}
            >
              {(() => {
                if (filterFieldViewState instanceof SingleSelectFormFieldViewState) {
                  return (
                    <SingleSelectFormFieldComponent
                      fieldViewState={filterFieldViewState}
                      isDisabled={false}
                    />
                  )
                }

                if (filterFieldViewState instanceof DateFormFieldViewState) {
                  return (
                    <DateFormFieldComponent
                      fieldViewState={filterFieldViewState}
                      isDisabled={false}
                    />
                  )
                }

                throw "Unknown filter field view state type"
              })()}
            </div>
          )))}
      </div>
      <div className={styles.footer}>
        <ButtonComponent
          type={ButtonType.BUTTON}
          title={coreTextProvider.applyFilter()}
          componentStyles={theme.buttonFilled1PrimaryStyle()}
          onClick={onApplyFilterClicked}
        />
        <ButtonComponent
          type={ButtonType.BUTTON}
          title={coreTextProvider.resetFilter()}
          componentStyles={theme.buttonOutlined1PrimaryStyle()}
          onClick={onResetFilterClicked}
        />
      </div>
    </div>
  )
}
