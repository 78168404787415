import CarrierCommercialTermsRepository from "../../repositories/CarrierCommercialTermsRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import CarrierCommercialTerm from "../../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import CarrierCommercialTermError from "../../../../../core/domain/carrier-commercial-terms/CarrierCommercialTermError"

export interface UpdateCarrierCommercialTermParameters {
  readonly objectId: string
  readonly object: CarrierCommercialTerm
}

export default class UpdateCarrierCommercialTermUseCase
  implements UpdateObjectUseCase<CarrierCommercialTerm, CarrierCommercialTermError> {
  private readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository

  constructor(parameters: {
    readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository
  }) {
    this.carrierCommercialTermsRepository = parameters.carrierCommercialTermsRepository
  }

  async call(
    parameters: UpdateCarrierCommercialTermParameters
  ): Promise<UpdateObjectResult<CarrierCommercialTerm, CarrierCommercialTermError>> {
    return await this.carrierCommercialTermsRepository.updateCarrierCommercialTerm(parameters)
  }
}
