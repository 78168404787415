import RefuellingOrdersRepository from "../../domain/repositories/RefuellingOrdersRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultRefuellingOrdersRepository from "../../data/repositories/DefaultRefuellingOrdersRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForRefuellingOrdersRepository
  from "../../domain/repositories/FuelCompaniesForRefuellingOrdersRepository"
import DefaultFuelCompaniesForRefuellingOrdersRepository
  from "../../data/repositories/DefaultFuelCompaniesForRefuellingOrdersRepository"
import CarriersForRefuellingOrdersRepository from "../../domain/repositories/CarriersForRefuellingOrdersRepository"
import DefaultCarriersForRefuellingOrdersRepository
  from "../../data/repositories/DefaultCarriersForRefuellingOrdersRepository"
import FuelsForRefuellingOrdersRepository from "../../domain/repositories/FuelsForRefuellingOrdersRepository"
import DefaultFuelsForRefuellingOrdersRepository
  from "../../data/repositories/DefaultFuelsForRefuellingOrdersRepository"
import GasStationsForRefuellingOrdersRepository
  from "../../domain/repositories/GasStationsForRefuellingOrdersRepository"
import DefaultGasStationsForRefuellingOrdersRepository
  from "../../data/repositories/DefaultGasStationsForRefuellingOrdersRepository"
import DriversForRefuellingOrdersRepository from "../../domain/repositories/DriversForRefuellingOrdersRepository"
import DefaultDriversForRefuellingOrdersRepository
  from "../../data/repositories/DefaultDriversForRefuellingOrdersRepository"
import TransportsForRefuellingOrdersRepository from "../../domain/repositories/TransportsForRefuellingOrdersRepository"
import DefaultTransportsForRefuellingOrdersRepository
  from "../../domain/repositories/DefaultTransportsForRefuellingOrdersRepository"
import RefuellingOrderStatusesForRefuellingOrdersRepository
  from "../../domain/repositories/RefuellingOrderStatusesForRefuellingOrdersRepository"
import DefaultRefuellingOrderStatusesForRefuellingOrdersRepository
  from "../../data/repositories/DefaultRefuellingOrderStatusesForRefuellingOrdersRepository"

export default interface RefuellingOrdersDataDiModule {
  provideRefuellingOrdersRepository(): RefuellingOrdersRepository

  provideCarriersForRefuellingOrdersRepository(): CarriersForRefuellingOrdersRepository

  provideFuelCompaniesForRefuellingOrdersRepository(): FuelCompaniesForRefuellingOrdersRepository

  provideFuelsForRefuellingOrdersRepository(): FuelsForRefuellingOrdersRepository

  provideGasStationsForRefuellingOrdersRepository(): GasStationsForRefuellingOrdersRepository

  provideDriversForRefuellingOrdersRepository(): DriversForRefuellingOrdersRepository

  provideTransportsForRefuellingOrdersRepository(): TransportsForRefuellingOrdersRepository

  provideRefuellingOrderStatusesForRefuellingOrdersRepository(): RefuellingOrderStatusesForRefuellingOrdersRepository
}

export class DefaultRefuellingOrdersDataDiModule extends DiModule implements RefuellingOrdersDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideRefuellingOrdersRepository(): RefuellingOrdersRepository {
    return this.single(
      DefaultRefuellingOrdersRepository.name,
      () => new DefaultRefuellingOrdersRepository({
        refuellingOrdersNetworkSource: this.appDataDiModule.provideRefuellingOrdersNetworkSource(),
        refuellingOrderStatusesStaticSource: this.appDataDiModule.provideRefuellingOrderStatusesStaticSource()
      })
    )
  }

  provideCarriersForRefuellingOrdersRepository(): CarriersForRefuellingOrdersRepository {
    return this.single(
      DefaultCarriersForRefuellingOrdersRepository.name,
      () => new DefaultCarriersForRefuellingOrdersRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }

  provideFuelCompaniesForRefuellingOrdersRepository(): FuelCompaniesForRefuellingOrdersRepository {
    return this.single(
      DefaultFuelCompaniesForRefuellingOrdersRepository.name,
      () => new DefaultFuelCompaniesForRefuellingOrdersRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }

  provideFuelsForRefuellingOrdersRepository(): FuelsForRefuellingOrdersRepository {
    return this.single(
      DefaultFuelsForRefuellingOrdersRepository.name,
      () => new DefaultFuelsForRefuellingOrdersRepository({
        fuelsNetworkSource: this.appDataDiModule.provideFuelsNetworkSource()
      })
    )
  }

  provideGasStationsForRefuellingOrdersRepository(): GasStationsForRefuellingOrdersRepository {
    return this.single(
      DefaultGasStationsForRefuellingOrdersRepository.name,
      () => new DefaultGasStationsForRefuellingOrdersRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }

  provideDriversForRefuellingOrdersRepository(): DriversForRefuellingOrdersRepository {
    return this.single(
      DefaultDriversForRefuellingOrdersRepository.name,
      () => new DefaultDriversForRefuellingOrdersRepository({
        driversNetworkSource: this.appDataDiModule.provideDriversNetworkSource()
      })
    )
  }

  provideTransportsForRefuellingOrdersRepository(): TransportsForRefuellingOrdersRepository {
    return this.single(
      DefaultTransportsForRefuellingOrdersRepository.name,
      () => new DefaultTransportsForRefuellingOrdersRepository({
        transportsNetworkSource: this.appDataDiModule.provideTransportsNetworkSource()
      })
    )
  }

  provideRefuellingOrderStatusesForRefuellingOrdersRepository(): RefuellingOrderStatusesForRefuellingOrdersRepository {
    return this.single(
      DefaultRefuellingOrderStatusesForRefuellingOrdersRepository.name,
      () => new DefaultRefuellingOrderStatusesForRefuellingOrdersRepository({
        refuellingOrderStatusesStaticSource: this.appDataDiModule.provideRefuellingOrderStatusesStaticSource()
      })
    )
  }
}
