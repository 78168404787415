import { Expose, Type } from "class-transformer"
import NetworkCarrierReconciliationReportErrorsObject from "./NetworkCarrierReconciliationReportErrorsObject"
import NetworkExecutionError from "../../../errors/NetworkExecutionError"
import CarrierReconciliationReportError
  from "../../../../../domain/carrier-reconciliation-reports/CarrierReconciliationReportError"

export default class NetworkCarrierReconciliationReportErrorResponseBody
  extends NetworkExecutionError
  implements CarrierReconciliationReportError {

  @Expose()
  @Type(() => NetworkCarrierReconciliationReportErrorsObject)
  readonly errorsObject?: NetworkCarrierReconciliationReportErrorsObject | null
}
