import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CurrentCarrierBalancesDomainDiModule from "./CurrentCarrierBalancesDomainDiModule"
import CurrentCarrierBalancesI18nDiModule from "./CurrentCarrierBalancesI18nDiModule"
import Sort from "../../../../../admin/features/objects/presentation/entities/tables/Sort"
import ObjectsDomainDiModule from "../../../../../admin/features/objects/di/modules/ObjectsDomainDiModule"
import BalanceTransactionType from "../../../../core/domain/balance-transactions/BalanceTransactionType"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"
import CurrentCarrierBalanceChangeDocumentsTableProvider
  from "../../presentation/table-providers/CurrentCarrierBalanceChangeDocumentsTableProvider"
import CurrentCarrierBalanceTransactionsTableProvider
  from "../../presentation/table-providers/CurrentCarrierBalanceTransactionsTableProvider"
import CurrentCarrierBalancePageRouteElement
  from "../../presentation/pages/carrier-balance/CurrentCarrierBalancePageRouteElement"
import CurrentCarrierBalancePresenter from "../../presentation/pages/carrier-balance/CurrentCarrierBalancePresenter"

const moduleKey = "сurrentСarrierBalances"

export default interface CurrentCarrierBalancesPresentationDiModule {
  provideCurrentCarrierBalancePage(): React.ReactNode
}

export class DefaultCurrentCarrierBalancesPresentationDiModule
  extends DiModule
  implements CurrentCarrierBalancesPresentationDiModule {

  private readonly timeZone: string
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly carrierBalancesI18nDiModule: CurrentCarrierBalancesI18nDiModule
  private readonly carrierBalancesDomainDiModule: CurrentCarrierBalancesDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly carrierBalancesI18nDiModule: CurrentCarrierBalancesI18nDiModule
    readonly carrierBalancesDomainDiModule: CurrentCarrierBalancesDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.carrierBalancesI18nDiModule = parameters.carrierBalancesI18nDiModule
    this.carrierBalancesDomainDiModule = parameters.carrierBalancesDomainDiModule
  }

  provideCurrentCarrierBalancePage(): React.ReactNode {
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <CurrentCarrierBalancePageRouteElement
        carrierBalancesI18n={this.carrierBalancesI18nDiModule.provideCurrentCarrierBalancesI18n()}
        providePresenter={({
          transactionsType
        }) => this.provideCarrierBalanceTransactionsPresenter({
          key: moduleKey,
          transactionsType
        })}
      />
    )
  }

  protected provideCarrierBalanceTransactionsPresenter({
    key,
    transactionsType
  }: {
    readonly key: string
    readonly sort?: Sort
    readonly transactionsType?: BalanceTransactionType | null
  }): CurrentCarrierBalancePresenter {
    return new CurrentCarrierBalancePresenter({
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      subscribeToObjectsEventsUseCase:
        this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
          key
        }),
      unsubscribeFromObjectsEventsUseCase:
        this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
          key
        }),
      getCurrentCarrierBalanceTransactionsUseCase:
        this.carrierBalancesDomainDiModule.provideGetCurrentCarrierBalanceTransactionsUseCase(),
      getCurrentCarrierBalanceChangeDocumentsUseCase:
        this.carrierBalancesDomainDiModule.provideGetCurrentCarrierBalanceChangeDocumentsUseCase(),
      getCarrierUseCase: this.carrierBalancesDomainDiModule.provideGetCarrierForCurrentCarrierBalancesUseCase(),
      transactionsType,
      carrierBalanceTransactionsTableProvider: this.provideCurrentCarrierBalanceTransactionsTableProvider(),
      carrierBalanceChangeDocumentsTableProvider:
        this.provideCurrentCarrierBalanceChangeDocumentsTableProvider()
    })
  }

  private provideCurrentCarrierBalanceTransactionsTableProvider(): CurrentCarrierBalanceTransactionsTableProvider {
    return this.single(
      CurrentCarrierBalanceTransactionsTableProvider.name,
      () => new CurrentCarrierBalanceTransactionsTableProvider({
        carrierBalancesI18: this.carrierBalancesI18nDiModule.provideCurrentCarrierBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }

  // eslint-disable-next-line max-len
  private provideCurrentCarrierBalanceChangeDocumentsTableProvider(): CurrentCarrierBalanceChangeDocumentsTableProvider {
    return this.single(
      CurrentCarrierBalanceChangeDocumentsTableProvider.name,
      () => new CurrentCarrierBalanceChangeDocumentsTableProvider({
        carrierBalancesI18: this.carrierBalancesI18nDiModule.provideCurrentCarrierBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }
}
