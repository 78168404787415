import FuelCompaniesRepository from "../../repositories/FuelCompaniesRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"

export interface GetFuelCompaniesParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompaniesRepository: FuelCompaniesRepository

  constructor(parameters: {
    readonly fuelCompaniesRepository: FuelCompaniesRepository
  }) {
    this.fuelCompaniesRepository = parameters.fuelCompaniesRepository
  }

  async call(parameters: GetFuelCompaniesParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.fuelCompaniesRepository.getFuelCompanies(parameters)
  }
}
