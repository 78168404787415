import FuelCompaniesI18 from "../../i18n/FuelCompaniesI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import FuelCompaniesTextProvider from "../../i18n/FuelCompaniesTextProvider"

export default interface FuelCompaniesI18nDiModule {
  provideFuelCompaniesI18n(): FuelCompaniesI18
}

export class DefaultFuelCompaniesI18nDiModule
  extends I18nDiModule<FuelCompaniesTextProvider>
  implements FuelCompaniesI18nDiModule {

  provideFuelCompaniesI18n(): FuelCompaniesI18 {
    return this.single(
      FuelCompaniesI18.name,
      () => new FuelCompaniesI18(this.getI18nParameters())
    )
  }
}
