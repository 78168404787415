import { Expose, Type } from "class-transformer"
import NetworkFuelCompany from "../../fuel-companies/NetworkFuelCompany"

export default class NetworkFuelCompanyAppAdministrator {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Boolean)
  readonly isOwner?: boolean | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyId?: number | null

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompany?: NetworkFuelCompany | null
}
