import FuelCompanyUsersTextProvider from "../FuelCompanyUsersTextProvider"

export default class DefaultRuFuelCompanyUsersTextProvider implements FuelCompanyUsersTextProvider {
  emailField(): string {
    return "E-mail"
  }

  defaultListTitle(): string {
    return "Пользователи топливной компании"
  }

  fuelCompanyAdministratorListTitle(): string {
    return "Пользователи"
  }

  fuelCompanyField(): string {
    return "Топливная компания"
  }

  firstNameField(): string {
    return "Имя"
  }

  middleNameField(): string {
    return "Отчество"
  }

  lastNameField(): string {
    return "Фамилия"
  }

  newObjectTitle(): string {
    return "Новый пользователь"
  }

  eventsLink(): string {
    return "События"
  }

  statusField(): string {
    return "Статус"
  }

  ownerField(): string {
    return "Владелец"
  }

  ownerValuePositive(): string {
    return "Да"
  }

  ownerValueNegative(): string {
    return "Нет"
  }

  blockedField(): string {
    return "Блокировка"
  }

  statusValueActive(): string {
    return "Активен"
  }

  statusValueBlocked(): string {
    return "Заблокирован"
  }
}
