import DiModule from "../../../../admin/lib/di/DiModule"
import CoreNetworkDiModule from "../../../../admin/core/di/modules/CoreNetworkDiModule"
import SessionsNetworkSource from "../../../../admin/core/data/sources/sessions/SessionsNetworkSource"
import DefaultSessionNetworkSource from "../../data/sources/network/DefaultSessionNetworkSource"
import AppNetworkDiModule from "./AppNetworkDiModule"
import UserProfileNetworkSource from "../../../../admin/core/data/sources/user-profile/UserProfileNetworkSource"
import DefaultUserProfileNetworkSource from "../../data/sources/network/DefaultUserProfileNetworkSource"

export default class DefaultCoreNetworkDiModule
  extends DiModule
  implements CoreNetworkDiModule {

  private readonly appNetworkDiModule: AppNetworkDiModule

  constructor(parameters: {
    readonly appNetworkDiModule: AppNetworkDiModule
  }) {
    super()
    this.appNetworkDiModule = parameters.appNetworkDiModule
  }

  provideSessionNetworkSource(): SessionsNetworkSource {
    return this.single(
      DefaultSessionNetworkSource.name,
      () => new DefaultSessionNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }

  provideUserProfileNetworkSource(): UserProfileNetworkSource {
    return this.single(
      DefaultUserProfileNetworkSource.name,
      () => new DefaultUserProfileNetworkSource({
        backendHttpClient: this.appNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
