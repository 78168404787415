import GetChangingLogItemsUseCase from "../../domain/use-cases/changing-log-items/GetChangingLogItemsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import TripChangingLogDataDiModule from "./TripChangingLogDataDiModule"
import GetCarriersForTripChangingLogUseCase from "../../domain/use-cases/carriers/GetCarriersForTripChangingLogUseCase"
import GetTripsForTripChangingLogUseCase from "../../domain/use-cases/trips/GetTripsForTripChangingLogUseCase"
import GetChangingTypesForTripChangingLogUseCase
  from "../../domain/use-cases/changing-types/GetChangingTypesForTripChangingLogUseCase"

export default interface TripChangingLogDomainDiModule {
  provideGetChangingLogItemsUseCase(): GetChangingLogItemsUseCase

  provideGetCarriersForTripChangingLogUseCase(): GetCarriersForTripChangingLogUseCase

  provideGetTripsForTripChangingLogUseCase(): GetTripsForTripChangingLogUseCase

  provideGetChangingTypesForTripChangingLogUseCase(): GetChangingTypesForTripChangingLogUseCase
}

export class DefaultTripChangingLogDomainDiModule extends DiModule implements TripChangingLogDomainDiModule {
  private readonly tripChangingLogDataDiModule: TripChangingLogDataDiModule

  constructor(parameters: {
    readonly tripChangingLogDataDiModule: TripChangingLogDataDiModule
  }) {
    super()
    this.tripChangingLogDataDiModule = parameters.tripChangingLogDataDiModule
  }

  provideGetChangingLogItemsUseCase(): GetChangingLogItemsUseCase {
    return this.single(
      GetChangingLogItemsUseCase.name,
      () => new GetChangingLogItemsUseCase({
        changingLogItemsRepository: this.tripChangingLogDataDiModule.provideChangingLogItemsRepository()
      })
    )
  }

  provideGetCarriersForTripChangingLogUseCase(): GetCarriersForTripChangingLogUseCase {
    return this.single(
      GetCarriersForTripChangingLogUseCase.name,
      () => new GetCarriersForTripChangingLogUseCase({
        carriersForTripChangingLogRepository:
          this.tripChangingLogDataDiModule.provideCarriersForTripChangingLogRepository()
      })
    )
  }

  provideGetTripsForTripChangingLogUseCase(): GetTripsForTripChangingLogUseCase {
    return this.single(
      GetTripsForTripChangingLogUseCase.name,
      () => new GetTripsForTripChangingLogUseCase({
        tripsForTripChangingLogItemsRepository:
          this.tripChangingLogDataDiModule.provideTripsForTripChangingLogRepository()
      })
    )
  }

  provideGetChangingTypesForTripChangingLogUseCase(): GetChangingTypesForTripChangingLogUseCase {
    return this.single(
      GetChangingTypesForTripChangingLogUseCase.name,
      () => new GetChangingTypesForTripChangingLogUseCase({
        changingTypesForTripChangingLogRepository:
          this.tripChangingLogDataDiModule.provideChangingTypesForTripChangingLogRepository()
      })
    )
  }
}
