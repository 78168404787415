import React from "react"
import styles from "../AuthenticationComponent.module.scss"
import LinkButtonComponent from "../../../../../lib/button/LinkButtonComponent"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import { useAuthenticationThemeProvider } from "../../contexts/AuthenticationThemeProviderContext"
import { useAuthenticationTextProvider } from "../../contexts/AuthenticationTextProviderContext"

interface Props {
  readonly successMessage?: string | null
}

export default function SuccessPasswordRecoveryComponent({
  successMessage
}: Props) {
  const authenticationTextProvider = useAuthenticationTextProvider()
  const theme = useCoreThemeProvider()
  const authenticationTheme = useAuthenticationThemeProvider()
  const urlProvider = new CoreUrlProvider()

  return (
    <>
      <HelmetComponent
        title={authenticationTextProvider.newPassword()}
      />
      <div className={styles.root}>
        <form className={styles.formContainer}>
          <div className={styles.form}>
            <img
              src={authenticationTheme.imAuthenticationLogoPath()}
              alt="imAuthenticationLogo"
            />
            <h1 className={`${styles.title} ${theme.headline1PrimaryClassName()}`}>
              {successMessage ?? ""}
            </h1>
          </div>
          <div className={styles.backButton}>
            <LinkButtonComponent
              replace={true}
              url={urlProvider.buildSignInUrl()}
              title={authenticationTextProvider.authenticate()}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
          </div>
        </form>
      </div>
    </>
  )
}
