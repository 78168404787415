import PasswordsRepository from "../../domain/repositories/PasswordsRepository"
import {
  RequestPasswordRecoveryParameters,
  RequestPasswordRecoveryResult
} from "../../domain/use-cases/password-recovery-request/RequestPasswordRecoveryUseCase"
import PasswordsNetworkSource from "../sources/PasswordsNetworkSource"
import PasswordRecoveryRequestMapper from "../mappers/password-recovery-request/PasswordRecoveryRequestMapper"
import { CheckTokenParameters, CheckTokenResult } from "../../domain/use-cases/check-token/CheckTokenUseCase"
import {
  RecoveryPasswordParameters,
  RecoveryPasswordResult
} from "../../domain/use-cases/password-recovery/RecoveryPasswordUseCase"
import PasswordRecoveryMapper from "../mappers/password-recovery/PasswordRecoveryMapper"

export default class DefaultPasswordsRepository implements PasswordsRepository {
  private readonly passwordsNetworkSource: PasswordsNetworkSource

  constructor(parameters: {
    readonly passwordsNetworkSource: PasswordsNetworkSource
  }) {
    this.passwordsNetworkSource = parameters.passwordsNetworkSource
  }

  async requestPasswordRecovery(
    { emailAddress }: RequestPasswordRecoveryParameters
  ): Promise<RequestPasswordRecoveryResult> {
    const result = await this.passwordsNetworkSource.requestPasswordRecovery({ emailAddress })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PasswordRecoveryRequestMapper().mapNetworkToDomain({
            passwordRecoveryRequestResponseBody: result.data
          })
        }
      default:
        return result
    }
  }

  async recoveryPassword({
      password,
      passwordConfirmation,
      token
    }: RecoveryPasswordParameters
  ): Promise<RecoveryPasswordResult> {
    const result = await this.passwordsNetworkSource.recoveryPassword({
      password,
      passwordConfirmation,
      token
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new PasswordRecoveryMapper().mapNetworkToDomain({
            passwordRecoveryResponseBody: result.data
          })
        }
      default:
        return result
    }
  }

  async checkToken({ token }: CheckTokenParameters): Promise<CheckTokenResult> {
    return await this.passwordsNetworkSource.checkToken({ token })
  }
}
