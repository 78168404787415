import DiModule from "../../../../../admin/lib/di/DiModule"
import CurrentFuelCompanyBalancesDataDiModule from "./CurrentFuelCompanyBalancesDataDiModule"
import GetFuelCompanyForCurrentFuelCompanyBalancesUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompanyForCurrentFuelCompanyBalancesUseCase"
import GetCurrentFuelCompanyBalanceTransactionsUseCase
  from "../../domain/use-cases/fuel-company-balance-transactions/GetCurrentFuelCompanyBalanceTransactionsUseCase"
import GetCurrentFuelCompanyBalanceChangeDocumentsUseCase
  from "../../domain/use-cases/fuel-company-balance-change-documents/GetCurrentFuelCompanyBalanceChangeDocumentsUseCase"

export default interface CurrentFuelCompanyBalancesDomainDiModule {
  provideGetCurrentFuelCompanyBalanceChangeDocumentsUseCase(): GetCurrentFuelCompanyBalanceChangeDocumentsUseCase

  provideGetCurrentFuelCompanyBalanceTransactionsUseCase(): GetCurrentFuelCompanyBalanceTransactionsUseCase

  provideGetFuelCompanyForCurrentFuelCompanyBalancesUseCase(): GetFuelCompanyForCurrentFuelCompanyBalancesUseCase
}

export class DefaultCurrentFuelCompanyBalancesDomainDiModule
  extends DiModule
  implements CurrentFuelCompanyBalancesDomainDiModule {
  private readonly fuelCompanyBalancesDataDiModule: CurrentFuelCompanyBalancesDataDiModule

  constructor(parameters: {
    readonly fuelCompanyBalancesDataDiModule: CurrentFuelCompanyBalancesDataDiModule
  }) {
    super()
    this.fuelCompanyBalancesDataDiModule = parameters.fuelCompanyBalancesDataDiModule
  }

  provideGetCurrentFuelCompanyBalanceChangeDocumentsUseCase(): GetCurrentFuelCompanyBalanceChangeDocumentsUseCase {
    return this.single(
      GetCurrentFuelCompanyBalanceChangeDocumentsUseCase.name,
      () => new GetCurrentFuelCompanyBalanceChangeDocumentsUseCase({
        currentFuelCompanyBalanceChangeDocumentsRepository:
          this.fuelCompanyBalancesDataDiModule.provideCurrentFuelCompanyBalanceChangeDocumentsRepository()
      })
    )
  }

  provideGetCurrentFuelCompanyBalanceTransactionsUseCase(): GetCurrentFuelCompanyBalanceTransactionsUseCase {
    return this.single(
      GetCurrentFuelCompanyBalanceTransactionsUseCase.name,
      () => new GetCurrentFuelCompanyBalanceTransactionsUseCase({
        currentFuelCompanyBalanceTransactionsRepository:
          this.fuelCompanyBalancesDataDiModule.provideCurrentFuelCompanyBalanceTransactionsRepository()
      })
    )
  }

  provideGetFuelCompanyForCurrentFuelCompanyBalancesUseCase(): GetFuelCompanyForCurrentFuelCompanyBalancesUseCase {
    return this.single(
      GetFuelCompanyForCurrentFuelCompanyBalancesUseCase.name,
      () => new GetFuelCompanyForCurrentFuelCompanyBalancesUseCase({
        fuelCompaniesForFuelCompanyBalancesRepository:
          this.fuelCompanyBalancesDataDiModule.provideFuelCompaniesForCurrentFuelCompanyBalancesRepository()
      })
    )
  }
}
