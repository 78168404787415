import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkRefuellingOrder from "../NetworkRefuellingOrder"

export default class NetworkRefuellingOrdersResponseBody {

  @Expose()
  @Type(() => NetworkRefuellingOrder)
  readonly refuellingOrders?: NetworkRefuellingOrder[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
