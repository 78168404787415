import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import FuelCompanyAppAdministratorErrorsObjectAttributes
  from "../../../../../domain/fuel-company-app-administrators/FuelCompanyAppAdministratorErrorsObjectAttributes"

export default class NetworkFuelCompanyAppAdministratorErrorsObjectAttributes
  implements FuelCompanyAppAdministratorErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelCompanyId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly isOwner?: NetworkAttributeError[] | null
}
