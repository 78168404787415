import NetworkPasswordRecoveryResponseBody
  from "../../../../../../app/core/data/entities/password-recovery/response-bodies/NetworkPasswordRecoveryResponseBody"
import PasswordRecoveryResponseBody from "../../../domain/entities/PasswordRecoveryResponseBody"

export default class PasswordRecoveryMapper {
  mapNetworkToDomain({
    passwordRecoveryResponseBody
  }: {
    readonly passwordRecoveryResponseBody: NetworkPasswordRecoveryResponseBody
  }): PasswordRecoveryResponseBody {
    return {
      message: passwordRecoveryResponseBody.message
    }
  }
}
