import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import RefuellingOrdersI18n from "../../i18n/RefuellingOrdersI18n"
import DecimalFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import RefuellingOrderError from "../../../../core/domain/refuelling-orders/RefuellingOrderError"
import RefuellingOrderErrorsObject from "../../../../core/domain/refuelling-orders/RefuellingOrderErrorsObject"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import InfoFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/InfoFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GetUserTypeUseCase from "../../../../../admin/core/domain/use-cases/user-profile/GetUserTypeUseCase"
import ContactPerson from "../../../../core/domain/contact-people/ContactPerson"
import { UserType } from "../../../../../admin/core/domain/entities/user-profile/UserType"
import isPresent from "../../../../../admin/lib/isPresent"
import isBlank from "../../../../../admin/lib/isBlank"
import formatPhoneNumber from "../../../../../admin/lib/formatPhone"

const mainGroupName = "main"

export default class RefuellingOrderFormProvider
  implements FormProvider<RefuellingOrder, RefuellingOrderError, RefuellingOrderErrorsObject> {
  private readonly refuellingOrdersI18n: RefuellingOrdersI18n
  private readonly getUserTypeUseCase: GetUserTypeUseCase

  constructor(parameters: {
    readonly refuellingOrdersI18n: RefuellingOrdersI18n
    readonly getUserTypeUseCase: GetUserTypeUseCase
  }) {
    this.refuellingOrdersI18n = parameters.refuellingOrdersI18n
    this.getUserTypeUseCase = parameters.getUserTypeUseCase
  }

  getEntity(): string {
    return Entity.REFUELLING_ORDERS
  }

  getNewObjectTitle(): string {
    return ""
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: RefuellingOrder
  }): string {
    return this.refuellingOrdersI18n.getTextProvider().existObjectTitle({ refuellingOrder: object })
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: RefuellingOrder
  }): string {
    return this.refuellingOrdersI18n.getTextProvider().existObjectTitle({ refuellingOrder: object })
  }

  async buildObject(): Promise<RefuellingOrder> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: RefuellingOrderError
  }): RefuellingOrderErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<RefuellingOrder, RefuellingOrderErrorsObject>[] {
    const refuellingOrdersTextProvider = this.refuellingOrdersI18n.getTextProvider()

    return filterNotEmpty([
      new InfoFormField<RefuellingOrder, RefuellingOrderErrorsObject>({
        title: refuellingOrdersTextProvider.carrierContacts(),
        groupName: mainGroupName,
        getIsVisible: (refuellingOrder) => this.getCarrierContactsVisible(refuellingOrder),
        getId: () => "carrierContactPerson",
        getValue: (refuellingOrder) => this.buildContacts(refuellingOrder.carrier?.contactPerson)
      }),
      new InfoFormField<RefuellingOrder, RefuellingOrderErrorsObject>({
        title: refuellingOrdersTextProvider.fuelCompanyContacts(),
        groupName: mainGroupName,
        getIsVisible: (refuellingOrder) => this.getFuelCompanyContactsVisible(refuellingOrder),
        getId: () => "fuelCompanyContactPerson",
        getValue: (refuellingOrder) => this.buildContacts(refuellingOrder.fuelCompany?.contactPerson)
      }),
      new StringFormField<RefuellingOrder, RefuellingOrderErrorsObject>({
        getTitle: (refuellingOrder: RefuellingOrder) => {
          return refuellingOrdersTextProvider.releasedFuelByFuelCompanyInfoField({ refuellingOrder })
        },
        groupName: mainGroupName,
        getIsEditable: () => false,
        getId: () => "expectedValue",
        getValue: (refuellingOrder: RefuellingOrder) => refuellingOrder.claim?.formattedExpectedValue,
        setValue: (refuellingOrder: RefuellingOrder) => ({ ...refuellingOrder })
      }),
      new StringFormField<RefuellingOrder, RefuellingOrderErrorsObject>({
        getTitle: (refuellingOrder: RefuellingOrder) => {
          return refuellingOrdersTextProvider.releasedFuelByCarrierInfoField({ refuellingOrder })
        },
        groupName: mainGroupName,
        getIsEditable: () => false,
        getId: () => "actualValue",
        getValue: (refuellingOrder: RefuellingOrder) => refuellingOrder.claim?.formattedActualValue,
        setValue: (refuellingOrder: RefuellingOrder) => ({ ...refuellingOrder })
      }),
      new DecimalFormField<RefuellingOrder, RefuellingOrderErrorsObject>({
        getTitle: (refuellingOrder: RefuellingOrder) => {
          return refuellingOrdersTextProvider.carrierFuelField({ refuellingOrder })
        },
        required: true,
        groupName: mainGroupName,
        getId: () => "carrierValue",
        getValue: (refuellingOrder: RefuellingOrder) => refuellingOrder.claim?.carrierValue,
        setValue: (refuellingOrder: RefuellingOrder, carrierValue: Decimal | null | undefined) => ({
          ...refuellingOrder,
          claim: {
            ...refuellingOrder.claim,
            carrierValue: carrierValue
          }
        }),
        getErrors: (errorsObject?: RefuellingOrderErrorsObject) => errorsObject?.claim?.attributes?.carrierValue
      }),
      new DecimalFormField<RefuellingOrder, RefuellingOrderErrorsObject>({
        getTitle: (refuellingOrder: RefuellingOrder) => {
          return refuellingOrdersTextProvider.fuelCompanyFuelField({ refuellingOrder })
        },
        required: true,
        groupName: mainGroupName,
        getId: () => "fuelCompanyValue",
        getValue: (refuellingOrder: RefuellingOrder) => refuellingOrder.claim?.fuelCompanyValue,
        setValue: (refuellingOrder: RefuellingOrder, fuelCompanyValue: Decimal | null | undefined) => ({
          ...refuellingOrder,
          claim: {
            ...refuellingOrder.claim,
            fuelCompanyValue: fuelCompanyValue
          }
        }),
        getErrors: (errorsObject?: RefuellingOrderErrorsObject) => errorsObject?.claim?.attributes?.fuelCompanyValue
      }),
      new InfoFormField<RefuellingOrder, RefuellingOrderErrorsObject>({
        title: refuellingOrdersTextProvider.claimInfoTitle(),
        groupName: mainGroupName,
        getId: () => "claimInfo",
        getValue: () => refuellingOrdersTextProvider.claimInfoDescription()
      })
    ])
  }

  private getCarrierContactsVisible(refuellingOrder: RefuellingOrder) {
    const userType = this.getUserTypeUseCase.call()
    if (userType === UserType.CARRIER_APP_ADMINISTRATOR) return false

    return isPresent(refuellingOrder.carrier?.contactPerson)
  }

  private getFuelCompanyContactsVisible(refuellingOrder: RefuellingOrder) {
    const userType = this.getUserTypeUseCase.call()
    if (userType === UserType.FUEL_COMPANY_APP_ADMINISTRATOR) return false

    return isPresent(refuellingOrder.fuelCompany?.contactPerson)
  }

  private buildContacts(contactPerson: ContactPerson | null| undefined) {
    if (isBlank(contactPerson)) return null

    const name = contactPerson.name
    const { phoneNumber, email } = contactPerson
    const contacts = filterNotEmpty([formatPhoneNumber(phoneNumber), email]).join(", ")
    return filterNotEmpty([name, contacts]).join("\n")
  }
}
