import DriversRepository from "../../repositories/DriversRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import DriverError from "../../../../../core/domain/drivers/DriverError"

export interface UpdateDriverParameters {
  readonly objectId: string
  readonly object: User
}

export default class UpdateDriverUseCase implements UpdateObjectUseCase<User, DriverError> {
  private readonly driversRepository: DriversRepository

  constructor(parameters: {
    readonly driversRepository: DriversRepository
  }) {
    this.driversRepository = parameters.driversRepository
  }

  async call(parameters: UpdateDriverParameters): Promise<UpdateObjectResult<User, DriverError>> {
    return await this.driversRepository.updateDriver(parameters)
  }
}
