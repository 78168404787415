import NetworkPage from "../entities/pagination/NetworkPage"
import Page from "../../../../admin/core/domain/entities/pages/Page"

export default class PagesMapper {
  mapNetworkToDomain({
    page
  }: {
    readonly page: NetworkPage
  }): Page {
    return {
      hasMore: page.hasMore ?? false
    }
  }
}
