import { Expose, Type } from "class-transformer"
import NetworkGasStationBreak from "../NetworkGasStationBreak"

export default class NetworkGasStationBreakRequestBody {

  @Expose()
  @Type(() => NetworkGasStationBreak)
  readonly gasStationBreak?: NetworkGasStationBreak | null

  constructor(parameters: {
    readonly gasStationBreak?: NetworkGasStationBreak | null
  }) {
    this.gasStationBreak = parameters.gasStationBreak
  }
}
