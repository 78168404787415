import TripsI18 from "../../i18n/TripsI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import TripsTextProvider from "../../i18n/TripsTextProvider"

export default interface TripsI18nDiModule {
  provideTripsI18n(): TripsI18
}

export class DefaultTripsI18nDiModule
  extends I18nDiModule<TripsTextProvider>
  implements TripsI18nDiModule {

  provideTripsI18n(): TripsI18 {
    return this.single(
      TripsI18.name,
      () => new TripsI18(this.getI18nParameters())
    )
  }
}
