import NetworkTripLimitErrorsObjectAttributes from "./NetworkTripLimitErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import TripLimitErrorsObject from "../../../../../domain/trip-limits/TripLimitErrorsObject"

export default class NetworkTripLimitErrorsObject implements TripLimitErrorsObject {
  @Expose()
  @Type(() => String)
  readonly clientId?: string | null

  @Expose()
  @Type(() => NetworkTripLimitErrorsObjectAttributes)
  readonly attributes?: NetworkTripLimitErrorsObjectAttributes | null
}
