import NetworkCarrierBalance from "../entities/carrier-balances/NetworkCarrierBalance"
import CarrierBalance from "../../domain/carrier-balances/CarrierBalance"

export default class CarrierBalancesMapper {
  mapNetworkToDomain({
    carrierBalance
  }: {
    readonly carrierBalance: NetworkCarrierBalance
  }): CarrierBalance {
    return {
      value: carrierBalance.value,
      formattedValue: carrierBalance.formattedValue
    }
  }
}
