import UsersRepository from "../../repositories/UsersRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import UserError from "../../../../../core/domain/users/UserError"

export interface CreateUserParameters {
  readonly object: User
}

export default class CreateUserUseCase implements CreateObjectUseCase<User, UserError> {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: CreateUserParameters): Promise<CreateObjectResult<User, UserError>> {
    return await this.usersRepository.createUser(parameters)
  }
}
