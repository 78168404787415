import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkUser from "../../entities/users/NetworkUser"
import NetworkUsersRequestFilter from "../../entities/users/request-queries/NetworkUsersRequestFilter"
import NetworkUserResponseBody from "../../entities/users/response-bodies/NetworkUserResponseBody"
import NetworkUserErrorResponseBody from "../../entities/users/response-bodies/errors/NetworkUserErrorResponseBody"
import NetworkUserRequestBody from "../../entities/users/request-bodies/NetworkUserRequestBody"
import NetworkUsersRequestQuery from "../../entities/users/request-queries/NetworkUsersRequestQuery"
import NetworkUsersResponseBody from "../../entities/users/response-bodies/NetworkUsersResponseBody"

const basePath = "/api/admin/users"

export default class UsersNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getUsers({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkUsersRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetUsersNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkUsersRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUsersResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getUser({
    userId
  }: {
    readonly userId: number
  }): Promise<GetUserNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${userId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUserResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createUser({
    user
  }: {
    readonly user?: NetworkUser | null
  }): Promise<CreateUserNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkUserRequestBody({
        user
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUserResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkUserErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateUser({
    userId,
    user
  }: {
    readonly userId: number
    readonly user?: NetworkUser | null
  }): Promise<UpdateUserNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${userId}`,
      body: instanceToPlain(new NetworkUserRequestBody({
        user
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkUserResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkUserErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetUsersNetworkResult =
  SuccessExecutionResult<NetworkUsersResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetUserNetworkResult =
  SuccessExecutionResult<NetworkUserResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateUserNetworkResult =
  SuccessExecutionResult<NetworkUserResponseBody> |
  ErrorExecutionResult<NetworkUserErrorResponseBody> |
  FailureExecutionResult

export type UpdateUserNetworkResult =
  SuccessExecutionResult<NetworkUserResponseBody> |
  ErrorExecutionResult<NetworkUserErrorResponseBody> |
  FailureExecutionResult
