import ChangingLogItemsRepository from "../../repositories/ChangingLogItemsRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import ChangingLogItem from "../../../../../core/domain/changing-log-items/ChangingLogItem"
import ChangingLogItemsFilter from "../../../../../core/domain/changing-log-items/ChangingLogItemsFilter"

export interface GetChangingLogItemsParameters extends GetObjectsParameters<ChangingLogItemsFilter> {
}

export default class GetChangingLogItemsUseCase implements GetObjectsUseCase<ChangingLogItem> {
  private readonly changingLogItemsRepository: ChangingLogItemsRepository

  constructor(parameters: {
    readonly changingLogItemsRepository: ChangingLogItemsRepository
  }) {
    this.changingLogItemsRepository = parameters.changingLogItemsRepository
  }

  async call(parameters: GetChangingLogItemsParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.changingLogItemsRepository.getChangingLogItems(parameters)
  }
}
