import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelCompanyCommercialTermsRepository from "../../repositories/FuelCompanyCommercialTermsRepository"
import FuelCompanyCommercialTerm
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"

export interface GetFuelCompanyCommercialTermParameters {
  readonly objectId: string
}

export default class GetFuelCompanyCommercialTermUseCase implements GetObjectUseCase<FuelCompanyCommercialTerm> {
  private readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository

  constructor(parameters: {
    readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository
  }) {
    this.fuelCompanyCommercialTermsRepository = parameters.fuelCompanyCommercialTermsRepository
  }

  async call(parameters: GetFuelCompanyCommercialTermParameters): Promise<GetObjectResult<FuelCompanyCommercialTerm>> {
    return await this.fuelCompanyCommercialTermsRepository.getFuelCompanyCommercialTerm(parameters)
  }
}
