import DiModule from "../../../../../admin/lib/di/DiModule"
import FuelCompanyBalancesDataDiModule from "./FuelCompanyBalancesDataDiModule"
import GetFuelCompanyForFuelCompanyBalancesUseCase from "../../domain/use-cases/fuel-companies/GetFuelCompanyForFuelCompanyBalancesUseCase"
import GetFuelCompanyBalanceTransactionsUseCase from "../../domain/use-cases/fuel-company-balance-transactions/GetFuelCompanyBalanceTransactionsUseCase"
import GetFuelCompanyBalanceChangeDocumentsUseCase
  from "../../domain/use-cases/fuel-company-balance-change-documents/GetFuelCompanyBalanceChangeDocumentsUseCase"
import CreateFuelCompanyBalanceChangeDocumentUseCase
  from "../../domain/use-cases/fuel-company-balance-change-documents/CreateFuelCompanyBalanceChangeDocumentUseCase"

export default interface FuelCompanyBalancesDomainDiModule {
  provideGetFuelCompanyBalanceChangeDocumentsUseCase(): GetFuelCompanyBalanceChangeDocumentsUseCase

  provideGetFuelCompanyBalanceTransactionsUseCase(): GetFuelCompanyBalanceTransactionsUseCase

  provideCreateFuelCompanyBalanceChangeDocumentUseCase(): CreateFuelCompanyBalanceChangeDocumentUseCase

  provideGetFuelCompanyForFuelCompanyBalancesUseCase(): GetFuelCompanyForFuelCompanyBalancesUseCase
}

export class DefaultFuelCompanyBalancesDomainDiModule extends DiModule implements FuelCompanyBalancesDomainDiModule {
  private readonly fuelCompanyBalancesDataDiModule: FuelCompanyBalancesDataDiModule

  constructor(parameters: {
    readonly fuelCompanyBalancesDataDiModule: FuelCompanyBalancesDataDiModule
  }) {
    super()
    this.fuelCompanyBalancesDataDiModule = parameters.fuelCompanyBalancesDataDiModule
  }

  provideGetFuelCompanyBalanceChangeDocumentsUseCase(): GetFuelCompanyBalanceChangeDocumentsUseCase {
    return this.single(
      GetFuelCompanyBalanceChangeDocumentsUseCase.name,
      () => new GetFuelCompanyBalanceChangeDocumentsUseCase({
        fuelCompanyBalanceChangeDocumentsRepository:
          this.fuelCompanyBalancesDataDiModule.provideFuelCompanyBalanceChangeDocumentsRepository()
      })
    )
  }

  provideGetFuelCompanyBalanceTransactionsUseCase(): GetFuelCompanyBalanceTransactionsUseCase {
    return this.single(
      GetFuelCompanyBalanceTransactionsUseCase.name,
      () => new GetFuelCompanyBalanceTransactionsUseCase({
        fuelCompanyBalanceTransactionsRepository:
          this.fuelCompanyBalancesDataDiModule.provideFuelCompanyBalanceTransactionsRepository()
      })
    )
  }

  provideCreateFuelCompanyBalanceChangeDocumentUseCase(): CreateFuelCompanyBalanceChangeDocumentUseCase {
    return this.single(
      CreateFuelCompanyBalanceChangeDocumentUseCase.name,
      () => new CreateFuelCompanyBalanceChangeDocumentUseCase({
        fuelCompanyBalanceChangeDocumentsRepository:
          this.fuelCompanyBalancesDataDiModule.provideFuelCompanyBalanceChangeDocumentsRepository()
      })
    )
  }

  provideGetFuelCompanyForFuelCompanyBalancesUseCase(): GetFuelCompanyForFuelCompanyBalancesUseCase {
    return this.single(
      GetFuelCompanyForFuelCompanyBalancesUseCase.name,
      () => new GetFuelCompanyForFuelCompanyBalancesUseCase({
        fuelCompaniesForFuelCompanyBalancesRepository:
          this.fuelCompanyBalancesDataDiModule.provideFuelCompaniesForFuelCompanyBalancesRepository()
      })
    )
  }
}
