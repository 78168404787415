import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import RefuellingOrdersFilter from "../../../../../core/domain/refuelling-orders/RefuellingOrdersFilter"
import RefuellingOrder from "../../../../../core/domain/refuelling-orders/RefuellingOrder"
import RefuellingOrdersRepository from "../../repositories/RefuellingOrdersRepository"

export interface GetRefuellingOrdersParameters extends GetObjectsParameters<RefuellingOrdersFilter> {
}

export default class GetRefuellingOrdersUseCase
  implements GetObjectsUseCase<RefuellingOrder, RefuellingOrdersFilter> {
  private readonly refuellingOrdersRepository: RefuellingOrdersRepository

  constructor(parameters: {
    readonly refuellingOrdersRepository: RefuellingOrdersRepository
  }) {
    this.refuellingOrdersRepository = parameters.refuellingOrdersRepository
  }

  async call(parameters: GetRefuellingOrdersParameters): Promise<GetObjectsResult<RefuellingOrder>> {
    return await this.refuellingOrdersRepository.getRefuellingOrders(parameters)
  }
}
