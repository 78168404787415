import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStationsForRefuellingOrdersReportsRepository
  from "../../repositories/GasStationsForRefuellingOrdersReportsRepository"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationsFilter from "../../../../../core/domain/gas-stations/GasStationsFilter"

export interface GetGasStationsForRefuellingOrdersReportsParameters
  extends GetObjectsParameters<GasStationsFilter> {
}

export default class GetGasStationsForRefuellingOrdersReportsUseCase implements GetObjectsUseCase<GasStation> {
  private readonly gasStationsForRefuellingOrdersReportsRepository: GasStationsForRefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly gasStationsForRefuellingOrdersReportsRepository: GasStationsForRefuellingOrdersReportsRepository
  }) {
    this.gasStationsForRefuellingOrdersReportsRepository = parameters.gasStationsForRefuellingOrdersReportsRepository
  }

  async call(parameters: GetGasStationsForRefuellingOrdersReportsParameters): Promise<GetObjectsResult<GasStation>> {
    return await this.gasStationsForRefuellingOrdersReportsRepository.getGasStations(parameters)
  }
}
