import { Expose, Type } from "class-transformer"

export default class NetworkGasStationBreaksRequestFilter {

  @Expose()
  @Type(() => Number)
  readonly gasStationIds?: number[] | null

  constructor(parameters: {
    readonly gasStationIds?: number[] | null
  }) {
    this.gasStationIds = parameters.gasStationIds
  }
}
