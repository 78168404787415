import React from "react"
import PickerComponentStyles from "../../picker/PickerComponentStyles"
import styles from "./MonthPickerComponent.module.scss"
import SelectPickerComponent from "../../picker/select-picker/SelectPickerComponent"
import SelectOption from "../../picker/SelectOption"
import isBlank from "../../isBlank"
import { maxAvailableDate, minAvailableDate } from "../dateConstants"

const monthsCount = 12

export interface MonthPickerComponentStyles {
  selectPicker?: PickerComponentStyles
}

export default function MonthPickerComponent({
  date,
  onChange,
  isDisabled,
  monthNameByNumber,
  componentStyle = {}
}: {
  readonly date: Date | null
  readonly isDisabled: boolean
  readonly onChange: (parameters: {
    readonly date: Date | null
  }) => void
  readonly componentStyle?: MonthPickerComponentStyles
  readonly monthNameByNumber: { [_: number]: string }
}) {
  const monthOptions: SelectOption[] = Array(monthsCount)
    .fill(null)
    .map((_, monthIndex: number): SelectOption => ({
      id: `${monthIndex}`,
      text: monthNameByNumber[monthIndex]
    }))

  const monthNumber: number | undefined = date?.getMonth()
  const selectedMonthOption = monthOptions.find((option) => option.id === `${monthNumber}`) ?? null

  const startYear = minAvailableDate.getFullYear()
  const endYear = maxAvailableDate.getFullYear()
  const year: number | undefined = date?.getFullYear()
  const yearOptions: SelectOption[] = Array.from({ length: endYear - startYear + 1 }, (_, year) => year + startYear)
    .map((value) => {
      return {
        id: `${value}`,
        text: `${value}`
      }
    })

  const selectedYearOption = yearOptions.find((option) => option.id === `${year}`) ?? null

  function handleMonthNumberChange({
    newMonthNumber
  }: {
    readonly newMonthNumber: number
  }) {
    const newDate: Date = createZeroDate()
    newDate.setFullYear((date ?? new Date()).getFullYear())
    newDate.setMonth(newMonthNumber)
    onChange({ date: newDate })
  }

  function handleYearChange({
    newYear
  }: {
    readonly newYear: number
  }) {
    const newDate: Date = createZeroDate()
    newDate.setFullYear(newYear)
    newDate.setMonth((date ?? new Date()).getMonth())
    onChange({ date: newDate })
  }

  function createZeroDate(): Date {
    const newDate: Date = new Date()

    newDate.setDate(1)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)

    return newDate
  }

  return (
    <div className={styles.root}>
      <SelectPickerComponent
        options={monthOptions}
        disabled={isDisabled}
        selectedOption={selectedMonthOption}
        searchingEnabled={false}
        componentStyle={componentStyle.selectPicker}
        onChange={(option) => {
          if (isBlank(option)) return

          handleMonthNumberChange({
            newMonthNumber: parseInt(option.id)
          })
        }}
      />
      <SelectPickerComponent
        options={yearOptions}
        disabled={isDisabled}
        selectedOption={selectedYearOption}
        searchingEnabled={false}
        componentStyle={componentStyle.selectPicker}
        onChange={(option) => {
          if (isBlank(option)) return

          handleYearChange({
            newYear: parseInt(option.id)
          })
        }}
      />
      {/*<select*/}
      {/*  value={monthNumber}*/}
      {/*  disabled={isDisabled}*/}
      {/*  onChange={(event) => handleMonthNumberChange({*/}
      {/*    newMonthNumber: parseInt(event.target.value)*/}
      {/*  })}*/}
      {/*>*/}
      {/*  {monthNumbers.map((monthNumber: number) => (*/}
      {/*    <option*/}
      {/*      key={monthNumber}*/}
      {/*      value={monthNumber}*/}
      {/*    >*/}
      {/*      {monthNameByNumber[monthNumber]}*/}
      {/*    </option>*/}
      {/*  ))}*/}
      {/*</select>*/}
      {/*<select*/}
      {/*  value={year}*/}
      {/*  disabled={isDisabled}*/}
      {/*  onChange={(event) => handleYearChange({*/}
      {/*    newYear: parseInt(event.target.value)*/}
      {/*  })}*/}
      {/*>*/}
      {/*  {years.map((year: number) => (*/}
      {/*    <option key={year}>{year}</option>*/}
      {/*  ))}*/}
      {/*</select>*/}
    </div>
  )
}
