import NetworkAttributeErrorObject from "../../entities/network/errors/NetworkAttributeErrorObject"
import AttributeErrorObject from "../../../domain/entities/errors/AttributeErrorObject"

export default class AttributeErrorObjectsMapper {
  mapNetworkToDomain({ object }: { readonly object: NetworkAttributeErrorObject }): AttributeErrorObject {
    return {
      type: object.type,
      id: object.id
    }
  }
}
