import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkCarrierBalanceTransaction from "../NetworkCarrierBalanceTransaction"

export default class NetworkCarrierBalanceTransactionsResponseBody {

  @Expose()
  @Type(() => NetworkCarrierBalanceTransaction)
  readonly carrierBalanceWriteOffs?: NetworkCarrierBalanceTransaction[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
