import TripsTextProvider from "../TripsTextProvider"
import TripLimit from "../../../../core/domain/trip-limits/TripLimit"
import isBlank from "../../../../../admin/lib/isBlank"

export default class DefaultRuTripsTextProvider implements TripsTextProvider {
  addLimit(): string {
    return "Новый лимит"
  }

  tripLimitFuelField(): string {
    return "Вид топлива"
  }

  disableTripLimitFuelPlaceholder(): string {
    return "Для всех видов топлива"
  }

  tripLimitTargetTypeField(): string {
    return "Тип лимита"
  }

  tripLimitValueField(parameters: { readonly tripLimit: TripLimit | null | undefined }): string {
    const shortFormattedUom = parameters.tripLimit?.fuel?.shortFormattedUom
    if (isBlank(shortFormattedUom)) return "Значение"

    return `Значение, ${shortFormattedUom}`
  }

  newTripLimit({
    index
  }: { readonly index: number }): string {
    return `Лимит №${index}`
  }

  driversField(): string {
    return "Водители"
  }

  startingAtField(): string {
    return "Начало"
  }

  finishingAtField(): string {
    return "Конец"
  }

  noteField(): string {
    return "Примечание"
  }

  driverFilter(): string {
    return "Водитель"
  }

  transportFilter(): string {
    return "Автомобиль"
  }

  statusFilter(): string {
    return "Статус"
  }

  existObjectTitle({
    id
  }: {
    readonly id: number
  }): string {
    return `№\u00A0${id}`
  }

  nameField(): string {
    return "Название"
  }

  transportField(): string {
    return "Автомобиль"
  }

  listTitle(): string {
    return "Рейсы"
  }

  idField(): string {
    return "№"
  }

  carrierField(): string {
    return "Перевозчик"
  }

  newObjectTitle(): string {
    return "Новый рейс"
  }

  statusField(): string {
    return "Статус"
  }

  carrierFilter(): string {
    return "Перевозчик"
  }

  refuellingOrdersLink(): string {
    return "Заправки"
  }

  limitsField(): string {
    return "Лимиты"
  }

  dateFromFilter(): string {
    return "Дата от"
  }

  dateToFilter(): string {
    return "Дата до"
  }

  changingLogLink(): string {
    return "История изменений"
  }
}
