import AppTextProvider from "../AppTextProvider"
import User from "../../domain/users/User"
import isBlank from "../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../admin/lib/isPresent"
import withRubbleSymbol from "../../../../admin/lib/withRubbleSymbol"

export default class DefaultRuAppTextProvider implements AppTextProvider {

  pricingTypeFixedText(): string {
    return "Фиксированная цена"
  }

  pricingTypeDiscountText(): string {
    return "От цены на стеле"
  }

  refuellingOrderStatusCancelledText(): string {
    return "Отменена заправщиком"
  }

  refuellingOrderStatusClaimText(): string {
    return "Претензия"
  }

  refuellingOrderStatusDriverConfirmationPendingText(): string {
    return "Ожидание подтверждения водителя"
  }

  refuellingOrderStatusFinishedText(): string {
    return "Успешно завершена"
  }

  refuellingOrderStatusGasStationConfirmationPendingText(): string {
    return "Создание"
  }

  refuellingOrderStatusGasStationCorrectionPendingText(): string {
    return "На пересмотре"
  }

  refuellingOrderStatusNotConfirmedByGasStationText(): string {
    return "Не состоялась"
  }

  refuellingOrderStatusRefuellingPendingText(): string {
    return "Ожидание старта"
  }

  refuellingOrderStatusRefuellingText(): string {
    return "Идет заправка"
  }

  tripStatusPendingText(): string {
    return "Создан"
  }

  tripStatusConfirmedText(): string {
    return "Подтвержден"
  }

  tripStatusFinishedText(): string {
    return "Завершен"
  }

  tripStatusCanceledText(): string {
    return "Отменен"
  }

  userTripStatusPendingText(): string {
    return "Ожидает подтверждения"
  }

  userTripStatusConfirmedText(): string {
    return "Подтвержден"
  }

  userTripStatusFinishedText(): string {
    return "Завершен"
  }

  userTripStatusCanceledText(): string {
    return "Отменен"
  }

  tripLimitTargetTypeRefuellingByMoneyText(): string {
    return "На разовую заправку, в рублях"
  }

  tripLimitTargetTypeRefuellingByFuelText(): string {
    return "На разовую заправку, в объеме топлива"
  }

  tripLimitTargetTypeTripByMoneyText(): string {
    return "На рейс, в рублях"
  }

  tripLimitTargetTypeTripByFuelText(): string {
    return "На рейс, в объеме топлива"
  }

  tripLimitTargetTypeDailyByMoneyText(): string {
    return "На сутки, в рублях"
  }

  tripLimitTargetTypeDailyByFuelTypeText(): string {
    return "На сутки, в объеме топлива"
  }

  driverName({
    user
  }: {
    readonly user: User | null | undefined
  }): string {
    if (isBlank(user)) return ""

    const fullName = filterNotEmpty([
      user.profile?.lastName,
      user.profile?.firstName,
      user.profile?.middleName
    ]).join(" ")

    if (isPresent(fullName)) return fullName

    return `Водитель №${user.id!}`
  }

  userName({
    user
  }: {
    readonly user: User | null | undefined
  }): string {
    if (isBlank(user)) return ""

    const fullName = filterNotEmpty([
      user.profile?.lastName,
      user.profile?.firstName,
      user.profile?.middleName
    ]).join(" ")

    if (isPresent(fullName)) return fullName

    return `Пользователь №${user.id!}`
  }

  changingTypeCreatedText(): string {
    return "Создание"
  }

  changingTypeUpdatedText(): string {
    return "Обновление"
  }

  changingTypeDeletedText(): string {
    return "Удаление"
  }

  installationInstanceTypeMethanikaRefuellerText(): string {
    return "Android-устройство"
  }

  installationInstanceTypeShelfGasStationText(): string {
    return "SHELF GAS STATION"
  }

  installationInstanceTypeServioPumpGasText(): string {
    return "SERVIOPUMP GAS"
  }

  installationInstanceTypeRodnik2Text(): string {
    return "Родник-2"
  }

  installationInstanceTypeSnCardText(): string {
    return "Сибнефтекарт"
  }

  installationInstanceTypeTopazText(): string {
    return "Топаз-АЗС"
  }

  installationInstanceTypeZvezdaText(): string {
    return "Звезда"
  }

  creditLimitPeriodTypeOneMonthText(): string {
    return "Месяц"
  }

  creditLimitPeriodTypeHalfOfMonthText(): string {
    return "Половина месяца"
  }

  creditLimitPeriodTypeThirdOfMonthText(): string {
    return "Треть месяца"
  }

  creditLimitPeriodTypeOneWeekText(): string {
    return "Неделя"
  }

  creditLimitPeriodTypeDescription(): string {
    return `Месяц (1-последнее число);
Половина месяца (1-15, 16-последнее число)
Треть месяца (1-10, 11-20, 21-последнее число)
Неделя (1-7, 8-14, 15-21, 22-последнее число)
`
  }

  withoutCreditLimit(): string {
    return "Без лимита"
  }

  creditLimitNotSet(): string {
    return "Не задан"
  }

  creditLimitOverall({
    formattedCreditLimit,
    formattedValue
  }: {
    readonly formattedValue: string | null | undefined
    readonly formattedCreditLimit: string | null | undefined
  }): string {
    return `Осталось ${withRubbleSymbol(formattedValue)} из ${withRubbleSymbol(formattedCreditLimit)}`
  }

  creditLimitDebt({
    formattedValue
  }: {
    readonly formattedValue: string | null | undefined
  }): string {
    return `Кредитный лимит превышен на ${withRubbleSymbol(formattedValue)}`
  }
}
