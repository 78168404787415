import TableColumn, {
  TableColumnAlignment
} from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import CarrierBalancesI18n from "../../i18n/CarrierBalancesI18n"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import CarrierBalanceChangeDocument
  from "../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"

export default class CarrierBalanceChangeDocumentsTableProvider
  implements TableProvider<CarrierBalanceChangeDocument> {
  protected readonly timeZone: string
  protected readonly carrierBalancesI18: CarrierBalancesI18n

  constructor(parameters: {
    readonly timeZone: string
    readonly carrierBalancesI18: CarrierBalancesI18n
  }) {
    this.timeZone = parameters.timeZone
    this.carrierBalancesI18 = parameters.carrierBalancesI18
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getEntity(): string {
    return Entity.CARRIER_BALANCE_REPLENISHMENTS
  }

  getTable(): Table<CarrierBalanceChangeDocument> {
    const appUrlProvider = new AppUrlProvider()
    const carrierBalancesTextProvider = this.carrierBalancesI18.getTextProvider()

    return new Table<CarrierBalanceChangeDocument>({
      title: carrierBalancesTextProvider.listTitle(),
      getObjectId: (
        carrierBalanceChangeDocument: CarrierBalanceChangeDocument
      ) => carrierBalanceChangeDocument.id!.toString(),
      getRowUrl: () => "",
      newObjectPageUrl: appUrlProvider.buildNewCarrierUrl(),
      columns: [
        new TableColumn<CarrierBalanceChangeDocument>({
          name: "id",
          title: carrierBalancesTextProvider.idField(),
          createValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => new TextTableValue({
            value: `${carrierBalanceChangeDocument.carrierBalanceTransaction?.id}`
          })
        }),
        new TableColumn<CarrierBalanceChangeDocument>({
          name: "date",
          title: carrierBalancesTextProvider.dateField(),
          createValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => new DateTableValue({
            value: carrierBalanceChangeDocument.carrierBalanceTransaction?.createdAt,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        }),
        new TableColumn<CarrierBalanceChangeDocument>({
          name: "value",
          title: carrierBalancesTextProvider.valueField(),
          columnAlignment: TableColumnAlignment.RIGHT,
          createValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => new TextTableValue({
            value: withRubbleSymbol(carrierBalanceChangeDocument.formattedValue)
          })
        }),
        new TableColumn<CarrierBalanceChangeDocument>({
          name: "reason_id",
          title: carrierBalancesTextProvider.reasonIdField(),
          createValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => new TextTableValue({
            value: `${carrierBalanceChangeDocument.id}`
          })
        }),
        new TableColumn<CarrierBalanceChangeDocument>({
          name: "reason",
          title: carrierBalancesTextProvider.reasonField(),
          createValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => new TextTableValue({
            value: carrierBalanceChangeDocument.reason
          })
        }),
        new TableColumn<CarrierBalanceChangeDocument>({
          name: "reason_date",
          title: carrierBalancesTextProvider.reasonDateField(),
          createValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => new DateTableValue({
            value: carrierBalanceChangeDocument.date,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<CarrierBalanceChangeDocument>({
          name: "comment",
          title: carrierBalancesTextProvider.commentField(),
          createValue: (carrierBalanceChangeDocument: CarrierBalanceChangeDocument) => new TextTableValue({
            value: carrierBalanceChangeDocument.comment
          })
        })
      ]
    })
  }
}
