import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesForCarrierCommercialTermsRepository from "../../repositories/FuelCompaniesForCarrierCommercialTermsRepository"

export interface GetFuelCompaniesForCarrierCommercialTermsParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForCarrierCommercialTermsUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompanyForCarrierCommercialTermsRepository: FuelCompaniesForCarrierCommercialTermsRepository

  constructor(parameters: {
    readonly fuelCompanyForCarrierCommercialTermsRepository: FuelCompaniesForCarrierCommercialTermsRepository
  }) {
    this.fuelCompanyForCarrierCommercialTermsRepository = parameters.fuelCompanyForCarrierCommercialTermsRepository
  }

  async call(parameters: GetFuelCompaniesForCarrierCommercialTermsParameters): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompanyForCarrierCommercialTermsRepository.getFuelCompanies(parameters)
  }
}
