export enum RestrictionType {
  DENY_ENTITIES_LIST_DISPLAY = "denyEntitiesListDisplay",
  DENY_ENTITY_DISPLAY = "denyEntityDisplay",
  DENY_ENTITY_CREATE = "denyEntityCreate",
  DENY_ENTITY_EDIT = "denyEntityEdit",
  DENY_ENTITY_DELETE = "denyEntityDelete",
  DENY_ENTITY_FIELD_DISPLAY_IN_TABLES = "denyEntityFieldDisplayInTables",
  DENY_ENTITY_FIELD_DISPLAY = "denyEntityFieldDisplay",
  DENY_ENTITY_FIELD_EDIT = "denyEntityFieldEdit"
}
