import React from "react"
import styles from "./TextFormFieldComponent.module.scss"
import { TextFormFieldViewState } from "../../../entities/forms/form-field-by-type/TextFormField"

export default function TextFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: TextFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    fieldViewState.onChange(event.target.value)
  }

  return (
    <textarea
      className={styles.input}
      value={fieldViewState.value ?? ""}
      disabled={isDisabled || !fieldViewState.getEditable()}
      onChange={handleOnChange}
    />
  )
}
