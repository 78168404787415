import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStationsForRefuellingOrdersRepository from "../../repositories/GasStationsForRefuellingOrdersRepository"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationsFilter from "../../../../../core/domain/gas-stations/GasStationsFilter"

export interface GetGasStationsForRefuellingOrdersParameters
  extends GetObjectsParameters<GasStationsFilter> {
}

export default class GetGasStationsForRefuellingOrdersUseCase implements GetObjectsUseCase<GasStation> {
  private readonly gasStationsForRefuellingOrdersRepository: GasStationsForRefuellingOrdersRepository

  constructor(parameters: {
    readonly gasStationsForRefuellingOrdersRepository: GasStationsForRefuellingOrdersRepository
  }) {
    this.gasStationsForRefuellingOrdersRepository = parameters.gasStationsForRefuellingOrdersRepository
  }

  async call(parameters: GetGasStationsForRefuellingOrdersParameters): Promise<GetObjectsResult<GasStation>> {
    return await this.gasStationsForRefuellingOrdersRepository.getGasStations(parameters)
  }
}
