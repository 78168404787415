import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import FuelCompanyReconciliationReportsDomainDiModule from "./FuelCompanyReconciliationReportsDomainDiModule"
import FuelCompanyReconciliationReportsI18nDiModule from "./FuelCompanyReconciliationReportsI18nDiModule"
import FuelCompanyReconciliationReportsTableProvider
  from "../../presentation/table-providers/FuelCompanyReconciliationReportsTableProvider"
import FuelCompanyReconciliationReportsFormProvider
  from "../../presentation/form-providers/FuelCompanyReconciliationReportsFormProvider"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "fuelCompanyReconciliationReports"

export default interface FuelCompanyReconciliationReportsPresentationDiModule {
  provideFuelCompanyReconciliationReportsListPage(): React.ReactNode

  provideFuelCompanyReconciliationReportFormPage(): React.ReactNode
}

export class DefaultFuelCompanyReconciliationReportsPresentationDiModule
  extends DiModule
  implements FuelCompanyReconciliationReportsPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly fuelCompanyReconciliationReportsI18nDiModule: FuelCompanyReconciliationReportsI18nDiModule
  private readonly fuelCompanyReconciliationReportsDomainDiModule: FuelCompanyReconciliationReportsDomainDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly fuelCompanyReconciliationReportsDomainDiModule: FuelCompanyReconciliationReportsDomainDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly fuelCompanyReconciliationReportsI18nDiModule: FuelCompanyReconciliationReportsI18nDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.fuelCompanyReconciliationReportsI18nDiModule = parameters.fuelCompanyReconciliationReportsI18nDiModule
    this.fuelCompanyReconciliationReportsDomainDiModule = parameters.fuelCompanyReconciliationReportsDomainDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
  }

  provideFuelCompanyReconciliationReportsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase:
        this.fuelCompanyReconciliationReportsDomainDiModule.provideGetFuelCompanyReconciliationReportsUseCase(),
      tableProvider: this.provideFuelCompanyReconciliationReportsTableProvider()
    })
  }

  private provideFuelCompanyReconciliationReportsTableProvider(): FuelCompanyReconciliationReportsTableProvider {
    return this.single(
      FuelCompanyReconciliationReportsTableProvider.name,
      () => new FuelCompanyReconciliationReportsTableProvider({
        timeZone: this.timeZone,
        fuelCompanyReconciliationReportsI18n:
          this.fuelCompanyReconciliationReportsI18nDiModule.provideFuelCompanyReconciliationReportsI18n(),
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase()
      })
    )
  }

  provideFuelCompanyReconciliationReportFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      createObjectUseCase:
        this.fuelCompanyReconciliationReportsDomainDiModule.provideCreateFuelCompanyReconciliationReportUseCase(),
      formProvider: this.provideFuelCompanyReconciliationReportsFormProvider()
    })
  }

  private provideFuelCompanyReconciliationReportsFormProvider(): FuelCompanyReconciliationReportsFormProvider {
    return this.single(
      FuelCompanyReconciliationReportsFormProvider.name,
      () => new FuelCompanyReconciliationReportsFormProvider({
        fuelCompaniesReconciliationReportsI18:
          this.fuelCompanyReconciliationReportsI18nDiModule.provideFuelCompanyReconciliationReportsI18n(),
        getFuelCompaniesUseCase:
          this.fuelCompanyReconciliationReportsDomainDiModule
            .provideGetFuelCompaniesForFuelCompanyReconciliationReportsUseCase(),
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase()
      })
    )
  }
}
