import CarrierCommercialTermsRepository from "../../repositories/CarrierCommercialTermsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarrierCommercialTerm from "../../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import CarrierCommercialTermsFilter
  from "../../../../../core/domain/carrier-commercial-terms/CarrierCommercialTermsFilter"

export interface GetCarrierCommercialTermsParameters extends GetObjectsParameters<CarrierCommercialTermsFilter> {
}

export default class GetCarrierCommercialTermsUseCase
  implements GetObjectsUseCase<CarrierCommercialTerm, CarrierCommercialTermsFilter> {
  private readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository

  constructor(parameters: {
    readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository
  }) {
    this.carrierCommercialTermsRepository = parameters.carrierCommercialTermsRepository
  }

  async call(parameters: GetCarrierCommercialTermsParameters): Promise<GetObjectsResult<CarrierCommercialTerm>> {
    return await this.carrierCommercialTermsRepository.getCarrierCommercialTerms(parameters)
  }
}
