import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import CarrierBalanceChangeDocument
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import CarrierBalanceChangeDocumentError
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentError"
import CarrierBalanceChangeDocumentsRepository from "../../repositories/CarrierBalanceChangeDocumentsRepository"

export interface CreateCarrierBalanceChangeDocumentParameters {
  readonly object: CarrierBalanceChangeDocument
  readonly carrierId: string | number
}

export default class CreateCarrierBalanceChangeDocumentUseCase
  implements CreateObjectUseCase<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentError> {
  private readonly carrierBalanceChangeDocumentsRepository: CarrierBalanceChangeDocumentsRepository

  constructor(parameters: {
    readonly carrierBalanceChangeDocumentsRepository: CarrierBalanceChangeDocumentsRepository
  }) {
    this.carrierBalanceChangeDocumentsRepository = parameters.carrierBalanceChangeDocumentsRepository
  }

  async call(
    parameters: CreateCarrierBalanceChangeDocumentParameters
  ): Promise<CreateObjectResult<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentError>> {
    return await this.carrierBalanceChangeDocumentsRepository.createDocument(parameters)
  }
}
