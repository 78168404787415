import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkFuelCompanyBalanceTransaction from "../NetworkFuelCompanyBalanceTransaction"

export default class NetworkFuelCompanyBalanceTransactionsResponseBody {

  @Expose()
  @Type(() => NetworkFuelCompanyBalanceTransaction)
  readonly fuelCompanyBalanceReplenishments?: NetworkFuelCompanyBalanceTransaction[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
