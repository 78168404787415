import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import ContactPersonErrorsObjectAttributes from "../../../domain/contact-people/ContactPersonErrorsObjectAttributes"

export default class NetworkContactPersonErrorsObjectAttributes implements ContactPersonErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly email?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly phoneNumber?: NetworkAttributeError[] | null
}
