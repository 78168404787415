import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkGasStation from "../NetworkGasStation"

export default class NetworkGasStationsResponseBody {

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStations?: NetworkGasStation[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
