import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import BooleanFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/BooleanFormField"
import DriversI18 from "../../i18n/DriversI18"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import DriverErrorsObject from "../../../../core/domain/drivers/DriverErrorsObject"
import DriverError from "../../../../core/domain/drivers/DriverError"
import User from "../../../../core/domain/users/User"
import GetCarriersForDriverUseCase from "../../domain/use-cases/carriers/GetCarriersForDriverUseCase"
import Carrier from "../../../../core/domain/carriers/Carrier"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import isBlank from "../../../../../admin/lib/isBlank"
import isPresent from "../../../../../admin/lib/isPresent"
import AppI18 from "../../../../core/i18n/AppI18"
import PhoneNumberFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/PhoneNumberFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"

export default class DriversFormProvider
  implements FormProvider<User, DriverError, DriverErrorsObject> {
  private readonly timeZone: string
  private readonly driversI18: DriversI18
  private readonly appI18: AppI18
  private readonly getCarriersForDriverUseCase: GetCarriersForDriverUseCase

  constructor(parameters: {
    readonly timeZone: string
    readonly driversI18: DriversI18
    readonly appI18: AppI18
    readonly getCarriersForDriverUseCase: GetCarriersForDriverUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.driversI18 = parameters.driversI18
    this.appI18 = parameters.appI18
    this.getCarriersForDriverUseCase = parameters.getCarriersForDriverUseCase
  }

  getEntity(): string {
    return Entity.DRIVERS
  }

  getNewObjectTitle(): string {
    return this.driversI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().driverName({ user: object })
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().driverName({ user: object })
  }

  async buildObject(): Promise<User> {
    return {}
  }

  getErrorsObject({ error }: {
    readonly error?: DriverError
  }): DriverErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<User, DriverErrorsObject>[] {
    const driversTextProvider = this.driversI18.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<User, DriverErrorsObject, Carrier>({
        title: driversTextProvider.carrierField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getCarriersForDriverUseCase,
        getErrors: (errorsObject?: DriverErrorsObject) => errorsObject?.attributes?.carrierId,
        getId: () => "carrier",
        getIsVisible: (driver: User) => isBlank(driver.id),
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (driver: User) => driver.carrier,
        setValue: (driver: User, carrier: Carrier | null) => ({
          ...driver, carrier, carrierId: carrier?.id
        })
      }),
      new StringFormField<User, DriverErrorsObject>({
        title: driversTextProvider.lastNameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "lastName",
        getValue: (driver: User) => driver.profile?.lastName,
        setValue: (driver: User, lastName: string) => ({
          ...driver,
          profile: {
            ...driver.profile,
            lastName
          }
        }),
        getErrors: (errorsObject?: DriverErrorsObject) => errorsObject?.profile?.attributes?.lastName
      }),
      new StringFormField<User, DriverErrorsObject>({
        title: driversTextProvider.firstNameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "firstName",
        getValue: (driver: User) => driver.profile?.firstName,
        setValue: (driver: User, firstName: string) => ({
          ...driver,
          profile: {
            ...driver.profile,
            firstName
          }
        }),
        getErrors: (errorsObject?: DriverErrorsObject) => errorsObject?.profile?.attributes?.firstName
      }),
      new StringFormField<User, DriverErrorsObject>({
        title: driversTextProvider.middleNameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "middleName",
        getValue: (driver: User) => driver.profile?.middleName,
        setValue: (driver: User, middleName: string) => ({
          ...driver,
          profile: {
            ...driver.profile,
            middleName
          }
        }),
        getErrors: (errorsObject?: DriverErrorsObject) => errorsObject?.profile?.attributes?.middleName
      }),
      new DateFormField<User, DriverErrorsObject>({
        title: driversTextProvider.birthdateField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "birthdate",
        getValue: (driver: User) => driver.profile?.birthdate,
        setValue: (driver: User, birthdate: Date | null | undefined) => ({
          ...driver,
          profile: {
            ...driver.profile,
            birthdate
          }
        }),
        getErrors: (errorsObject?: DriverErrorsObject) => errorsObject?.profile?.attributes?.birthdate
      }),
      new StringFormField<User, DriverErrorsObject>({
        title: driversTextProvider.driverLicenseNumberField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "driverLicenseNumber",
        getValue: (driver: User) => driver.profile?.driverLicenseNumber,
        setValue: (driver: User, driverLicenseNumber: string) => ({
          ...driver,
          profile: {
            ...driver.profile,
            driverLicenseNumber
          }
        }),
        getErrors: (errorsObject?: DriverErrorsObject) => errorsObject?.profile?.attributes?.driverLicenseNumber
      }),
      new PhoneNumberFormField<User, DriverErrorsObject>({
        title: driversTextProvider.phoneNumberField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "phoneNumber",
        getValue: (driver: User) => driver.phoneNumber?.value,
        setValue: (driver: User, value: string) => ({
          ...driver,
          phoneNumber: {
            ...driver.phoneNumber,
            value
          }
        }),
        getErrors: (errorsObject?: DriverErrorsObject) => errorsObject?.phoneNumber?.attributes?.value
      }),
      new BooleanFormField<User, DriverErrorsObject>({
        title: driversTextProvider.blockedField(),
        groupName: mainGroupName,
        getId: () => "blocked",
        getIsVisible: (driver: User) => isPresent(driver.id),
        getValue: (driver: User) => driver.blocked,
        setValue: (driver: User, blocked: boolean | null | undefined) => ({ ...driver, blocked })
      })
    ])
  }
}
