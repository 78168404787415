import FuelCompanyReconciliationReportsI18n from "../../i18n/FuelCompanyReconciliationReportsI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import FuelCompanyReconciliationReportsTextProvider from "../../i18n/FuelCompanyReconciliationReportsTextProvider"

export default interface FuelCompanyReconciliationReportsI18nDiModule {
  provideFuelCompanyReconciliationReportsI18n(): FuelCompanyReconciliationReportsI18n
}

export class DefaultFuelCompanyReconciliationReportsI18nDiModule
  extends I18nDiModule<FuelCompanyReconciliationReportsTextProvider>
  implements FuelCompanyReconciliationReportsI18nDiModule {

  provideFuelCompanyReconciliationReportsI18n(): FuelCompanyReconciliationReportsI18n {
    return this.single(
      FuelCompanyReconciliationReportsI18n.name,
      () => new FuelCompanyReconciliationReportsI18n(this.getI18nParameters())
    )
  }
}
