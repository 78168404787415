import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForTransportRepository from "../../repositories/CarriersForTransportRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForTransportParameters extends GetObjectsParameters {
}

export default class GetCarriersForTransportUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForTransportRepository: CarriersForTransportRepository

  constructor(parameters: {
    readonly carriersForTransportRepository: CarriersForTransportRepository
  }) {
    this.carriersForTransportRepository = parameters.carriersForTransportRepository
  }

  async call(parameters: GetCarriersForTransportParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForTransportRepository.getCarriers(parameters)
  }
}
