import React, { useEffect } from "react"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import styles from "../AuthenticationComponent.module.scss"
import TextInputComponent, { TextInputType } from "../../../../../lib/text-input/TextInputComponent"
import LabelComponent from "../../../../../lib/label/LabelComponent"
import ButtonComponent, { ButtonType } from "../../../../../lib/button/ButtonComponent"
import LinkButtonComponent from "../../../../../lib/button/LinkButtonComponent"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import { useAuthenticationThemeProvider } from "../../contexts/AuthenticationThemeProviderContext"
import { useLocation } from "react-router-dom"
import { useAuthenticationTextProvider } from "../../contexts/AuthenticationTextProviderContext"
import AuthenticationLinkState from "../../entities/AuthenticationLinkState"
import isBlank from "../../../../../lib/isBlank"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"

interface Props {
  readonly onEmailAddressChanged: (parameters: { readonly emailAddress: string }) => void
  readonly onSendEmailClicked: () => void
  readonly emailAddress: string
  readonly isPasswordRecovering: boolean
  readonly passwordRecoveryRequestError?: ExecutionError
  readonly passwordRecoveryRequestFailureException?: ApplicationException
}

export default function PasswordRecoveryRequestComponent({
  onEmailAddressChanged,
  onSendEmailClicked,
  emailAddress,
  isPasswordRecovering,
  passwordRecoveryRequestError,
  passwordRecoveryRequestFailureException
}: Props) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const authenticationTextProvider = useAuthenticationTextProvider()
  const authenticationLinkState: AuthenticationLinkState = useLocation().state
  const theme = useCoreThemeProvider()
  const authenticationTheme = useAuthenticationThemeProvider()
  const urlProvider = new CoreUrlProvider()

  useEffect(() => {
    if (isBlank(authenticationLinkState?.email)) return

    onEmailAddressChanged({ emailAddress: authenticationLinkState.email })
  }, [])

  function handleOnSubmit(event: React.FormEvent) {
    event.preventDefault()
    onSendEmailClicked()
  }

  return (
    <>
      <HelmetComponent
        title={authenticationTextProvider.passwordRecovery()}
      />
      <div className={styles.root}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <div className={styles.form}>
            <img
              src={authenticationTheme.imAuthenticationLogoPath()}
              alt="imAuthenticationLogo"
            />
            <h1 className={`${styles.title} ${theme.headline1PrimaryClassName()}`}>
              {authenticationTextProvider.passwordRecovery()}
            </h1>
            <div className={styles.formRow}>
              <LabelComponent
                htmlFor="email"
                text={authenticationTextProvider.enterEmailForPasswordRecovery()}
                className={theme.label1PrimaryClassName()}
              />
              <TextInputComponent
                name="email"
                type={TextInputType.EMAIL}
                value={emailAddress}
                isDisabled={isPasswordRecovering}
                onChange={(value) => {
                  onEmailAddressChanged({ emailAddress: value })
                }}
                componentStyle={theme.input1PrimaryStyle()}
              />
            </div>

          </div>
          <div className={styles.submitRow}>
            <ButtonComponent
              type={ButtonType.SUBMIT}
              isDisabled={isPasswordRecovering}
              title={authenticationTextProvider.send()}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
            <LinkButtonComponent
              replace={true}
              url={urlProvider.buildSignInUrl()}
              isDisabled={isPasswordRecovering}
              title={authenticationTextProvider.back()}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
          </div>
          {passwordRecoveryRequestFailureException && (
            <div className={`${styles.errorMessage} ${theme.body3ErrorClassName()}`}>
              {coreTextProvider.somethingWentWrong()}
            </div>
          )}
          {passwordRecoveryRequestError && passwordRecoveryRequestError.message && (
            <div className={`${styles.errorMessage} ${theme.body3ErrorClassName()}`}>
              {passwordRecoveryRequestError.message}
            </div>
          )}
        </form>
      </div>
    </>
  )
}
