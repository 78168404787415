import { Expose, Type } from "class-transformer"
import NetworkFuelCompanyCommercialTermErrorsObject from "./NetworkFuelCompanyCommercialTermErrorsObject"
import NetworkExecutionError from "../../../errors/NetworkExecutionError"
import FuelCompanyCommercialTermError
  from "../../../../../domain/fuel-company-commercial-terms/FuelCompanyCommercialTermError"

export default class NetworkFuelCompanyCommercialTermErrorResponseBody
  extends NetworkExecutionError
  implements FuelCompanyCommercialTermError {
  @Expose()
  @Type(() => NetworkFuelCompanyCommercialTermErrorsObject)
  readonly errorsObject?: NetworkFuelCompanyCommercialTermErrorsObject | null
}
