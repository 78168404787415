import { Expose } from "class-transformer"
import NetworkMenuItemResponsePartial from "./NetworkMenuItemResponsePartial"
import NetworkMenu from "../NetworkMenu"

export default class NetworkMenuResponsePartial implements NetworkMenu {

  @Expose()
  readonly menuItems?: NetworkMenuItemResponsePartial[] | null

  constructor(parameters?: {
    readonly menuItems?: NetworkMenuItemResponsePartial[] | null
  }) {
    if (parameters === undefined) {
      return
    }

    this.menuItems = parameters.menuItems
  }
}
