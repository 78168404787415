import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import UserErrorsObjectAttributes from "../../../../../domain/users/UserErrorsObjectAttributes"

export default class NetworkUserErrorsObjectAttributes implements UserErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly carrierId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelCompanyId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly emailAddress?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly blocked?: NetworkAttributeError[] | null
}
