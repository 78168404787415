import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import InstallationInstancesRepository from "../../repositories/InstallationInstancesRepository"
import InstallationInstance from "../../../../../core/domain/installation-instance/InstallationInstance"

export interface GetInstallationInstanceParameters {
  readonly objectId: string
}

export default class GetInstallationInstanceUseCase implements GetObjectUseCase<InstallationInstance> {
  private readonly installationInstancesRepository: InstallationInstancesRepository

  constructor(parameters: {
    readonly installationInstancesRepository: InstallationInstancesRepository
  }) {
    this.installationInstancesRepository = parameters.installationInstancesRepository
  }

  async call(parameters: GetInstallationInstanceParameters): Promise<GetObjectResult<InstallationInstance>> {
    return await this.installationInstancesRepository.getInstallationInstance(parameters)
  }
}
