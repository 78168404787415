import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelsForCarrierCommercialTermsRepository from "../../repositories/FuelsForCarrierCommercialTermsRepository"
import Fuel from "../../../../../core/domain/fuels/Fuel"

export interface GetFuelsForCarrierCommercialTermsParameters extends GetObjectsParameters {
}

export default class GetFuelsForCarrierCommercialTermsUseCase implements GetObjectsUseCase<Fuel> {
  private readonly fuelsForCarrierCommercialTermsRepository: FuelsForCarrierCommercialTermsRepository

  constructor(parameters: {
    readonly fuelsForCarrierCommercialTermsRepository: FuelsForCarrierCommercialTermsRepository
  }) {
    this.fuelsForCarrierCommercialTermsRepository = parameters.fuelsForCarrierCommercialTermsRepository
  }

  async call(parameters: GetFuelsForCarrierCommercialTermsParameters): Promise<GetObjectsResult<Fuel>> {
    return await this.fuelsForCarrierCommercialTermsRepository.getFuels(parameters)
  }
}
