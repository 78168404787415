import ForbiddenTextProvider from "../ForbiddenTextProvider"

export default class DefaultRuForbiddenTextProvider implements ForbiddenTextProvider {

  title(): string {
    return "Отказано в доступе"
  }

  text(): string {
    return "Вы запросили страницу, доступ к которой ограничен специальными правами."
  }

  redirectToMainLink(): string {
    return "Вернуться на главную"
  }
}
