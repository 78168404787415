import React from "react"
import BooleanTableValue from "../../../entities/tables/table-value-by-type/BooleanTableValue"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import CheckboxComponent from "../../../../../../lib/checkbox/CheckboxComponent"

export default function BooleanTableValueComponent({
  booleanTableValue
}: {
  readonly booleanTableValue: BooleanTableValue
}) {
  const theme = useCoreThemeProvider()

  return (
    <CheckboxComponent
      name=""
      checkboxClassName={theme.checkbox1ClassName()}
      labelClassName={theme.checkboxLabel1PrimaryClassName()}
      isDisabled={true}
      isChecked={booleanTableValue.getValue() ?? false}
    />
  )
}
