import CarriersTextProvider from "../CarriersTextProvider"

export default class DefaultRuCarriersTextProvider implements CarriersTextProvider {
  balanceField(): string {
    return "Баланс"
  }

  blockedField(): string {
    return "Блокировка"
  }

  codeField(): string {
    return "Код"
  }

  contactPersonEmailField(): string {
    return "E-mail"
  }

  contactPersonField(): string {
    return "Контакты"
  }

  contactPersonNameField(): string {
    return "Имя"
  }

  contactPersonPhoneNumberField(): string {
    return "Номер телефона"
  }

  driversLink(): string {
    return "Водители"
  }

  existObjectTitle(): string {
    return "Перевозчик"
  }

  legalEntityField(): string {
    return "Юр. лицо"
  }

  legalEntityIndividualTaxpayerNumberField(): string {
    return "ИНН"
  }

  legalEntityTaxRegistrationReasonCode(): string {
    return "КПП"
  }

  listTitle(): string {
    return "Перевозчики"
  }

  nameField(): string {
    return "Наименование"
  }

  newObjectTitle(): string {
    return "Новый перевозчик"
  }

  refuellingOrdersLink(): string {
    return "Заправки"
  }

  statusField(): string {
    return "Статус"
  }

  statusValueActive(): string {
    return "Активен"
  }

  statusValueBlocked(): string {
    return "Заблокирован"
  }

  balanceLink(): string {
    return "История списаний и начислений"
  }

  transportsLink(): string {
    return "Автомобили"
  }

  tripsLink(): string {
    return "Рейсы"
  }

  usersLink(): string {
    return "Пользователи"
  }

  legalEntityNameField(): string {
    return "Название"
  }

  fuelCompanyFilter(): string {
    return "Топливная компания"
  }
}
