import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkTripLimitTypesRequestQuery {
  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  constructor(parameters: {
    readonly pagination?: NetworkLastItemPagination | null
  }) {
    this.pagination = parameters.pagination
  }
}
