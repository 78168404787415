import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import StringFormField, {
  StringFormType
} from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import BooleanFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/BooleanFormField"
import CarrierUsersI18 from "../../i18n/CarrierUsersI18"
import User from "../../../../core/domain/users/User"
import GetCarriersForCarrierUsersUseCase from "../../domain/use-cases/carriers/GetCarriersForCarrierUsersUseCase"
import isPresent from "../../../../../admin/lib/isPresent"
import AppI18 from "../../../../core/i18n/AppI18"
import UserError from "../../../../core/domain/users/UserError"
import UserErrorsObject from "../../../../core/domain/users/UserErrorsObject"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import isBlank from "../../../../../admin/lib/isBlank"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"

export default class CarrierUsersFormProvider
  implements FormProvider<User, UserError, UserErrorsObject> {
  private readonly usersI18: CarrierUsersI18
  private readonly appI18: AppI18
  private readonly getCarriersUseCase: GetCarriersForCarrierUsersUseCase

  constructor(parameters: {
    readonly usersI18: CarrierUsersI18
    readonly appI18: AppI18
    readonly getCarriersUseCase: GetCarriersForCarrierUsersUseCase
  }) {
    this.usersI18 = parameters.usersI18
    this.appI18 = parameters.appI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
  }

  getEntity(): string {
    return Entity.CARRIER_USERS
  }

  getNewObjectTitle(): string {
    return this.usersI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().userName({ user: object })
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().userName({ user: object })
  }

  async buildObject(): Promise<User> {
    return {}
  }

  getErrorsObject({ error }: {
    readonly error?: UserError
  }): UserErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<User, UserErrorsObject>[] {
    const usersTextProvider = this.usersI18.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<User, UserErrorsObject, Carrier>({
        title: usersTextProvider.carrierField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getCarriersUseCase,
        getErrors: (errorsObject?: UserErrorsObject) => errorsObject?.carrierAppAdministrator?.attributes?.carrierId,
        getId: () => "carrier",
        getIsVisible: (user: User) => isBlank(user.id),
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (user: User) => user.carrierAppAdministrator?.carrier,
        setValue: (user: User, carrier: Carrier | null) => ({
          ...user,
          carrierAppAdministrator: {
            ...user.carrierAppAdministrator,
            carrier: carrier,
            carrierId: carrier?.id
          }
        })
      }),
      new StringFormField<User, UserErrorsObject>({
        title: usersTextProvider.lastNameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "lastName",
        getValue: (user: User) => user.profile?.lastName,
        setValue: (user: User, lastName: string) => ({
          ...user,
          profile: {
            ...user.profile,
            lastName
          }
        }),
        getErrors: (errorsObject?: UserErrorsObject) => errorsObject?.profile?.attributes?.lastName
      }),
      new StringFormField<User, UserErrorsObject>({
        title: usersTextProvider.firstNameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "firstName",
        getValue: (user: User) => user.profile?.firstName,
        setValue: (user: User, firstName: string) => ({
          ...user,
          profile: {
            ...user.profile,
            firstName
          }
        }),
        getErrors: (errorsObject?: UserErrorsObject) => errorsObject?.profile?.attributes?.firstName
      }),
      new StringFormField<User, UserErrorsObject>({
        title: usersTextProvider.middleNameField(),
        groupName: mainGroupName,
        getId: () => "middleName",
        getValue: (user: User) => user.profile?.middleName,
        setValue: (user: User, middleName: string) => ({
          ...user,
          profile: {
            ...user.profile,
            middleName
          }
        }),
        getErrors: (errorsObject?: UserErrorsObject) => errorsObject?.profile?.attributes?.middleName
      }),
      new StringFormField<User, UserErrorsObject>({
        title: usersTextProvider.emailField(),
        type: StringFormType.EMAIL,
        required: true,
        groupName: mainGroupName,
        getId: () => "emailAddress",
        getValue: (user: User) => user.emailAddress,
        setValue: (user: User, value: string) => ({
          ...user,
          emailAddress: value
        }),
        getErrors: (errorsObject?: UserErrorsObject) => errorsObject?.attributes?.emailAddress
      }),
      new BooleanFormField<User, UserErrorsObject>({
        title: usersTextProvider.blockedField(),
        groupName: mainGroupName,
        getId: () => "blocked",
        getErrors: (errorsObject?: UserErrorsObject) => errorsObject?.attributes?.blocked,
        getIsVisible: (user: User) => isPresent(user.id),
        getValue: (user: User) => user.blocked,
        setValue: (user: User, blocked: boolean | null | undefined) => ({ ...user, blocked })
      }),
      new BooleanFormField<User, UserErrorsObject>({
        title: usersTextProvider.ownerField(),
        groupName: mainGroupName,
        getId: () => "isOwner",
        getErrors: (errorsObject?: UserErrorsObject) => errorsObject?.carrierAppAdministrator?.attributes?.isOwner,
        getValue: (user: User) => user.carrierAppAdministrator?.isOwner,
        setValue: (user: User, isOwner: boolean | null | undefined) => ({
          ...user,
          carrierAppAdministrator: {
            ...user.carrierAppAdministrator,
            isOwner: isOwner
          }
        })
      })
    ])
  }
}
