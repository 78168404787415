import { Expose, Type } from "class-transformer"

export default class NetworkLegalEntity {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly individualTaxpayerNumber?: string | null

  @Expose()
  @Type(() => String)
  readonly taxRegistrationReasonCode?: string | null
}
