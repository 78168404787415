import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelCompanyBalanceChangeDocumentsResponseBody
  from "../../entities/fuel-company-balance-change-document/response-bodies/NetworkFuelCompanyBalanceChangeDocumentsResponseBody"
import NetworkFuelCompanyBalanceChangeDocumentsRequestQuery
  from "../../entities/fuel-company-balance-change-document/request-queries/NetworkFuelCompanyBalanceChangeDocumentsRequestQuery"

const baseUrl = "/api/admin/current_fuel_company_balance_write_offs"

export default class CurrentFuelCompanyBalanceChangeDocumentsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getDocuments({
    pagination,
    sort
  }: {
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetCurrentFuelCompanyBalanceChangeDocumentsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${baseUrl}`,
      parameters: instanceToPlain(new NetworkFuelCompanyBalanceChangeDocumentsRequestQuery({
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelCompanyBalanceChangeDocumentsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCurrentFuelCompanyBalanceChangeDocumentsNetworkResult =
  SuccessExecutionResult<NetworkFuelCompanyBalanceChangeDocumentsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

