import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkFuelCompanyCommercialTerm from "../NetworkFuelCompanyCommercialTerm"

export default class NetworkFuelCompanyCommercialTermsResponseBody {

  @Expose()
  @Type(() => NetworkFuelCompanyCommercialTerm)
  readonly fuelCompanyCommercialTerms?: NetworkFuelCompanyCommercialTerm[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
