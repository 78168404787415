import React, { CSSProperties } from "react"
import TextTableValue from "../../../entities/tables/table-value-by-type/TextTableValue"
import styles from "./TextTableValueComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import TableColumn, { TableColumnAlignment } from "../../../entities/tables/TableColumn"
import isBlank from "../../../../../../lib/isBlank"

export default function TextTableValueComponent<DomainObject>({
  textTableValue,
  column
}: {
  readonly column: TableColumn<DomainObject>
  readonly textTableValue: TextTableValue
}) {
  const theme = useCoreThemeProvider()
  const valueColorHex = textTableValue.getValueColorHex()

  const valueClassName = textTableValue.getIsActive() ?
    theme.tableRow1PrimaryClassName() :
    theme.tableRow1SecondaryClassName()

  const valueStyle = (): CSSProperties => {
    if (isBlank(valueColorHex)) return {}

    return { color: valueColorHex }
  }

  const alignmentClassName = (() => {
    switch (column.getColumnAlignment()) {
      case TableColumnAlignment.RIGHT:
        return styles.rightAlignment
      default:
        return undefined
    }
  })()

  return (
    <div className={`${styles.root} ${alignmentClassName}`}>
      <div className={valueClassName} style={valueStyle()}>
        {textTableValue.getValue()}
      </div>
      { textTableValue.getAdditionalValue() && (
        <div className={theme.tableRow2SecondaryClassName()}>
          {textTableValue.getAdditionalValue()}
        </div>
      )}
    </div>
  )
}
