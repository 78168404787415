import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import DriversI18 from "../../i18n/DriversI18"
import User from "../../../../core/domain/users/User"
import DateInfoRow from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/DateInfoRow"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import AppI18 from "../../../../core/i18n/AppI18"
import isBlank from "../../../../../admin/lib/isBlank"
import IMask from "imask"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class DriverInfoProvider implements InfoProvider<User> {
  private readonly timeZone: string
  private readonly driversI18: DriversI18
  private readonly appI18: AppI18

  constructor(parameters: {
    readonly timeZone: string
    readonly driversI18: DriversI18
    readonly appI18: AppI18
  }) {
    this.timeZone = parameters.timeZone
    this.driversI18 = parameters.driversI18
    this.appI18 = parameters.appI18
  }

  getEntity(): string {
    return Entity.DRIVERS
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().driverName({ user: object })
  }

  getObjectTitle({
    object
  }: {
    readonly object?: User
  }): string {
    return this.appI18.getTextProvider().driverName({ user: object })
  }

  getInfoRows({ object }: {
    readonly object: User
  }): InfoRow[] {
    const driversTextProvider = this.driversI18.getTextProvider()
    const appUrlProvider = new AppUrlProvider()

    return [
      new StringInfoRow({
        name: "carrier",
        entity: Entity.CARRIERS,
        title: driversTextProvider.carrierField(),
        value: object?.carrier?.name,
        url: object.carrier && appUrlProvider.buildShowCarrierUrl({
          carrierId: object.carrier.id!
        })
      }),
      new StringInfoRow({
        name: "lastName",
        title: driversTextProvider.lastNameField(),
        value: object.profile?.lastName
      }),
      new StringInfoRow({
        name: "firstName",
        title: driversTextProvider.firstNameField(),
        value: object.profile?.firstName
      }),
      new StringInfoRow({
        name: "middleName",
        title: driversTextProvider.middleNameField(),
        value: object.profile?.middleName
      }),
      new DateInfoRow({
        name: "birthdate",
        title: driversTextProvider.birthdateField(),
        value: object.profile?.birthdate,
        format: DateTimeFormat.DATE
      }),
      new StringInfoRow({
        name: "driverLicense",
        title: driversTextProvider.driverLicenseNumberField(),
        value: object.profile?.driverLicenseNumber
      }),
      new StringInfoRow({
        name: "phoneNumber",
        title: driversTextProvider.phoneNumberField(),
        value: (() => {
          const mask = object.phoneNumber?.country?.phoneNumberFormattingMask
          const phoneNumber = object.phoneNumber?.value
          if (isBlank(mask) || isBlank(phoneNumber)) return phoneNumber
          const numberPipe = IMask.createPipe({
            mask
          })

          return numberPipe(phoneNumber)
        })()
      }),
      new StringInfoRow({
        name: "blocked",
        title: driversTextProvider.statusField(),
        value: (() => {
          const { blocked = false } = object

          return (blocked ?? false) ?
            driversTextProvider.statusValueBlocked() :
            driversTextProvider.statusValueActive()
        })()
      })
    ]
  }

  getLinks({ object }: {
    readonly object: User
  }): LinkInfo[] {
    const urlProvider = new AppUrlProvider()
    const driversTextProvider = this.driversI18.getTextProvider()

    return [
      {
        id: "refuellingOrders",
        entity: Entity.REFUELLING_ORDERS,
        title: driversTextProvider.refuellingOrdersLink(),
        url: urlProvider.buildRefuellingOrdersUrl({
          driverId: object.id
        })
      }
    ]
  }

  getEditUrl({
    object
  }: {
    readonly object: User
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditDriverUrl({
      driverId: object.id!
    })
  }
}
