import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import FuelCompanyReconciliationReportsI18n from "../../i18n/FuelCompanyReconciliationReportsI18n"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import FuelCompanyReconciliationReport
  from "../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReport"
import isBlank from "../../../../../admin/lib/isBlank"
import GetUserTypeUseCase from "../../../../../admin/core/domain/use-cases/user-profile/GetUserTypeUseCase"

export interface FuelCompanyReconciliationReportsTableProviderParameters {
  readonly fuelCompanyReconciliationReportsI18n: FuelCompanyReconciliationReportsI18n
  readonly timeZone: string
  readonly getUserTypeUseCase: GetUserTypeUseCase
}

export default class FuelCompanyReconciliationReportsTableProvider
  implements TableProvider<FuelCompanyReconciliationReport> {
  protected readonly fuelCompanyReconciliationReportsI18n: FuelCompanyReconciliationReportsI18n
  private readonly timeZone: string
  private readonly getUserTypeUseCase: GetUserTypeUseCase

  constructor(parameters: FuelCompanyReconciliationReportsTableProviderParameters) {
    this.fuelCompanyReconciliationReportsI18n = parameters.fuelCompanyReconciliationReportsI18n
    this.timeZone = parameters.timeZone
    this.getUserTypeUseCase = parameters.getUserTypeUseCase
  }

  getEntity(): string {
    return Entity.FUEL_COMPANY_RECONCILIATION_REPORTS
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getTable(): Table<FuelCompanyReconciliationReport> {
    const appUrlProvider = new AppUrlProvider()
    const fuelCompanyReconciliationReportsTextProvider = this.fuelCompanyReconciliationReportsI18n.getTextProvider()

    const userType = this.getUserTypeUseCase.call()
    return new Table<FuelCompanyReconciliationReport>({
      title: fuelCompanyReconciliationReportsTextProvider.listTitle({
        userType
      }),
      description: fuelCompanyReconciliationReportsTextProvider.listDescription(),
      newObjectPageUrl: appUrlProvider.buildNewFuelCompanyReconciliationReportsUrl(),
      getObjectId: (report: FuelCompanyReconciliationReport) => report.id!.toString(),
      getRowUrl: () => "",
      needRefreshObject: (report: FuelCompanyReconciliationReport) => report.needRefresh ?? false,
      columns: [
        new TableColumn<FuelCompanyReconciliationReport>({
          name: "createdAt",
          title: fuelCompanyReconciliationReportsTextProvider.createdDateField(),
          createValue: (report: FuelCompanyReconciliationReport) => new DateTableValue({
            value: report.createdAt,
            format: DateTimeFormat.DATE_TIME,
            timeZone: this.timeZone
          })
        }),
        new TableColumn<FuelCompanyReconciliationReport>({
          name: "fromDate",
          title: fuelCompanyReconciliationReportsTextProvider.fromDateField(),
          createValue: (report: FuelCompanyReconciliationReport) => new DateTableValue({
            value: report.fromDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<FuelCompanyReconciliationReport>({
          name: "toDate",
          title: fuelCompanyReconciliationReportsTextProvider.toDateField(),
          createValue: (report: FuelCompanyReconciliationReport) => new DateTableValue({
            value: report.toDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<FuelCompanyReconciliationReport>({
          name: "fuelCompany",
          entity: Entity.FUEL_COMPANIES,
          title: fuelCompanyReconciliationReportsTextProvider.fuelCompanyField(),
          createValue: (report: FuelCompanyReconciliationReport) => new TextTableValue({
            value: report.fuelCompany?.name,
            url: (() => {
              const { fuelCompany } = report
              if (isBlank(fuelCompany)) return null

              return appUrlProvider.buildShowFuelCompanyUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })()
          })
        }),
        new TableColumn<FuelCompanyReconciliationReport>({
          name: "report",
          title: fuelCompanyReconciliationReportsTextProvider.reportField(),
          createValue: (report: FuelCompanyReconciliationReport) => new TextTableValue({
            value: report.statusName,
            url: report.documentUrl && report.documentUrl,
            openInNewTab: true
          })
        })
      ]
    })
  }
}
