import React, { ReactNode } from "react"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import styles from "./ObjectFooterComponent.module.scss"

export default function ObjectFooterComponent({
  children
}: {
  readonly children: ReactNode
}) {
  const theme = useCoreThemeProvider()

  return (
    <div className={`${styles.root} ${theme.objectFooterClassName()}`}>
      {children}
    </div>
  )
}
