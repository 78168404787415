import { Expose, Type } from "class-transformer"
import NetworkTransport from "../NetworkTransport"

export default class NetworkTransportRequestBody {

  @Expose()
  @Type(() => NetworkTransport)
  readonly transport?: NetworkTransport | null

  constructor(parameters: {
    readonly transport?: NetworkTransport | null
  }) {
    this.transport = parameters.transport
  }
}
