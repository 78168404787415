import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import NestedListObject from "../../../../../../core/domain/entities/shared/NestedListObject"

// TODO: adding new item and deleting old item
export default class ListFormField<
  DomainObject,
  ErrorsObject,
  NestedObject extends NestedListObject,
  NestedErrorsObject>
  extends FormField<DomainObject, ErrorsObject> {

  private readonly newValue: () => NestedObject
  private readonly getValue: (object: DomainObject) => NestedObject[] | null | undefined
  private readonly setValue: (object: DomainObject, value: NestedObject[]) => DomainObject
  private readonly getNestedObjectTitle: (
    nestedObject: NestedObject,
    nestedObjectIndex: number
  ) => string | null | undefined

  private readonly getNestedErrorsObject: (
    nestedObject: NestedObject,
    errorsObject?: ErrorsObject
  ) => NestedErrorsObject | null | undefined

  private readonly fields: FormField<NestedObject, NestedErrorsObject>[]
  private objectFieldViewStateById: { [key: string]: ObjectFieldViewState<NestedObject> }
  private readonly addObjectButton?: string

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly newValue: () => NestedObject
    readonly getValue: (object: DomainObject) => NestedObject[] | null | undefined
    readonly setValue: (object: DomainObject, value: NestedObject[]) => DomainObject
    readonly getNestedObjectTitle: (
      nestedObject: NestedObject,
      nestedObjectIndex: number
    ) => string | null | undefined
    readonly getNestedErrorsObject: (
      nestedObject: NestedObject,
      errorsObject?: ErrorsObject
    ) => NestedErrorsObject | null | undefined
    readonly fields: FormField<NestedObject, NestedErrorsObject>[]
    readonly addObjectButton?: string
  }) {
    super(parameters)

    this.fields = parameters.fields
    this.newValue = parameters.newValue
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
    this.getNestedObjectTitle = parameters.getNestedObjectTitle
    this.getNestedErrorsObject = parameters.getNestedErrorsObject
    this.addObjectButton = parameters.addObjectButton
    this.objectFieldViewStateById = {}

    this.fields.forEach((field: FormField<NestedObject, NestedErrorsObject>) => {
      field.setSetAndShowLoadedFuelCompanyBalanceChangeDocumentViewState(() => {
        this.setAndShowLoadedObjectViewState()
      })

      field.setSetObject((nestedObject: NestedObject) => {
        this.objectFieldViewStateById[nestedObject.clientId!].setObject(nestedObject)
      })
    })
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    const nestedObjects: NestedObject[] = this.getValue(object) ?? []

    const objectFieldViewStates: ObjectFieldViewState<NestedObject>[] = nestedObjects.map(
      (nestedObject: NestedObject, nestedObjectIndex: number): ObjectFieldViewState<NestedObject> => {
        return this.createObjectFieldViewState({
          object,
          nestedObject,
          allNestedObjects: nestedObjects,
          index: nestedObjectIndex,
          errorsObject
        })
      }
    )

    const objectFieldViewStateById: { [key: string]: ObjectFieldViewState<NestedObject> } = {}

    objectFieldViewStates.forEach((objectFieldViewState: ObjectFieldViewState<NestedObject>) => {
      objectFieldViewStateById[objectFieldViewState.id] = objectFieldViewState
    })

    this.objectFieldViewStateById = objectFieldViewStateById

    return new ListFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      objectFieldViewStates,
      addObjectButton: this.addObjectButton,
      addObject: () => {
        const newNestedObject = this.newValue()
        const newNestedObjects: NestedObject[] = [...nestedObjects]
        newNestedObjects.push(newNestedObject)
        this.objectFieldViewStateById[newNestedObject.clientId!] = this.createObjectFieldViewState({
          object,
          nestedObject: newNestedObject,
          index: newNestedObjects.findIndex((nestedObject) => nestedObject.clientId === newNestedObject.clientId) ?? -1,
          errorsObject,
          allNestedObjects: nestedObjects
        })
        this.setObject(this.setValue(object, newNestedObjects))
        this.setAndShowLoadedObjectViewState()
      },
      removeObject: (clientId: string) => {
        const newNestedObjects: NestedObject[] = [...nestedObjects]
          .filter((object) => object.clientId !== clientId)

        delete this.objectFieldViewStateById[clientId]
        this.setObject(this.setValue(object, newNestedObjects))
        this.setAndShowLoadedObjectViewState()
      }
    })
  }

  serializeValue(_object: DomainObject): string | null | undefined {
    throw "Not implemented"
  }

  init(_parameters: {
    readonly object: DomainObject;
    readonly serializedValue: string | null | undefined
  }): Promise<void> {
    throw "Not implemented"
  }

  private createObjectFieldViewState({
    object,
    nestedObject,
    allNestedObjects,
    errorsObject,
    index
  }: {
    readonly object: DomainObject,
    readonly nestedObject: NestedObject,
    readonly allNestedObjects: NestedObject[],
    readonly errorsObject: ErrorsObject | undefined,
    readonly index: number
  }): ObjectFieldViewState<NestedObject> {
    return new ObjectFieldViewState({
      id: nestedObject.clientId!,
      title: this.getNestedObjectTitle(nestedObject, index),
      fieldViewStates: this.fields.map((field: FormField<NestedObject, NestedErrorsObject>) => {
        const nestedErrorsObject: NestedErrorsObject | undefined =
          this.getNestedErrorsObject(nestedObject, errorsObject) ?? undefined

        return field.getViewState(nestedObject, nestedErrorsObject)
      }),
      setObject: (newNestedObject: NestedObject) => {
        const newNestedObjects: NestedObject[] = [...allNestedObjects]
        newNestedObjects[index] = newNestedObject
        this.setObject(this.setValue(object, newNestedObjects))
      }
    })
  }
}

export class ListFormFieldViewState<DomainObject> extends FormFieldViewState {
  private readonly objectFieldViewStates: ObjectFieldViewState<DomainObject>[]
  private readonly addObjectButton: string | undefined
  readonly addObject: () => void
  readonly removeObject: (clientId: string) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly objectFieldViewStates: ObjectFieldViewState<DomainObject>[]
    readonly addObject: () => void
    readonly removeObject: (clientId: string) => void
    readonly addObjectButton: string | undefined
  }) {
    super(parameters)
    this.objectFieldViewStates = parameters.objectFieldViewStates
    this.addObject = parameters.addObject
    this.removeObject = parameters.removeObject
    this.addObjectButton = parameters.addObjectButton
  }

  getObjectFieldViewStates(): ObjectFieldViewState<DomainObject>[] {
    return this.objectFieldViewStates
  }

  getAddObjectButton() {
    return this.addObjectButton
  }

  hasValue(): boolean {
    return this.objectFieldViewStates.length > 0
  }
}

export class ObjectFieldViewState<DomainObject> {
  readonly id: string
  readonly title: string | null | undefined
  readonly fieldViewStates: FormFieldViewState[]
  readonly setObject: (newNestedObject: DomainObject) => void

  constructor(parameters: {
    readonly id: string
    readonly title: string | null | undefined
    readonly fieldViewStates: FormFieldViewState[]
    readonly setObject: (newNestedObject: DomainObject) => void
  }) {
    this.id = parameters.id
    this.title = parameters.title
    this.fieldViewStates = parameters.fieldViewStates
    this.setObject = parameters.setObject
  }
}
