import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import ClaimErrorsObjectAttributes from "../../../../../domain/claims/ClaimErrorsObjectAttributes"

export default class NetworkClaimErrorsObjectAttributes implements ClaimErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly carrierValue?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fuelCompanyValue?: NetworkAttributeError[] | null
}
