import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import CarrierReconciliationReportsRepository from "../../domain/repositories/CarrierReconciliationReportsRepository"
import CarrierReconciliationReportsNetworkSource
  from "../../../../core/data/sources/network/CarrierReconciliationReportsNetworkSource"
import {
  GetCarrierReconciliationReportsParameters
} from "../../domain/use-cases/carrier-reconciliation-reports/GetCarrierReconciliationReportsUseCase"
import {
  CreateCarrierReconciliationReportParameters
} from "../../domain/use-cases/carrier-reconciliation-reports/CreateCarrierReconciliationReportUseCase"
import CarrierReconciliationReport
  from "../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReport"
import NetworkCarrierReconciliationReportsRequestFilter
  from "../../../../core/data/entities/carrier-reconciliation-reports/request-queries/NetworkCarrierReconciliationReportsRequestFilter"
import CarrierReconciliationReportsMapper from "../../../../core/data/mappers/CarrierReconciliationReportsMapper"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import CarrierReconciliationReportError
  from "../../../../core/domain/carrier-reconciliation-reports/CarrierReconciliationReportError"

export default class DefaultCarrierReconciliationReportsRepository implements CarrierReconciliationReportsRepository {
  private readonly carrierReconciliationReportsNetworkSource: CarrierReconciliationReportsNetworkSource

  constructor(parameters: {
    readonly carrierReconciliationReportsNetworkSource: CarrierReconciliationReportsNetworkSource
  }) {
    this.carrierReconciliationReportsNetworkSource = parameters.carrierReconciliationReportsNetworkSource
  }

  async getCarrierReconciliationReports({
    pagination,
    sort,
    ids
  }: GetCarrierReconciliationReportsParameters): Promise<GetObjectsResult<CarrierReconciliationReport>> {
    const result = await this.carrierReconciliationReportsNetworkSource.getCarrierReconciliationReports({
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort }),
      filter: new NetworkCarrierReconciliationReportsRequestFilter({
        ids
      })
    })

    const reportsMapper = new CarrierReconciliationReportsMapper()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.reports!.map((report) => {
              return reportsMapper.mapNetworkToDomain({
                report
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async createCarrierReconciliationReport({
    object: report
  }: CreateCarrierReconciliationReportParameters): Promise<
    CreateObjectResult<CarrierReconciliationReport, CarrierReconciliationReportError>
  > {
    const mapper = new CarrierReconciliationReportsMapper()
    const result = await this.carrierReconciliationReportsNetworkSource.createCarrierReconciliationReport({
      report: mapper.mapDomainToNetwork({ report })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            report: result.data.report!
          })
        }
      default:
        return result
    }
  }
}
