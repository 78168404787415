import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForRefuellingOrdersRepository from "../../repositories/CarriersForRefuellingOrdersRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForRefuellingOrdersParameters extends GetObjectsParameters {
}

export default class GetCarriersForRefuellingOrdersUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForCarrierCommercialTermsRepository: CarriersForRefuellingOrdersRepository

  constructor(parameters: {
    readonly carriersForCarrierCommercialTermsRepository: CarriersForRefuellingOrdersRepository
  }) {
    this.carriersForCarrierCommercialTermsRepository = parameters.carriersForCarrierCommercialTermsRepository
  }

  async call(parameters: GetCarriersForRefuellingOrdersParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForCarrierCommercialTermsRepository.getCarriers(parameters)
  }
}
