import { Decimal } from "decimal.js"
import { Expose, Transform, Type } from "class-transformer"
import transformDecimal from "../../network/transformers/transformDecimal"
import NetworkFuelTransaction from "../fuel-transactions/NetworkFuelTransaction"

export default class NetworkCarrierBalanceTransaction {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Date)
  readonly createdAt?: Date | null

  @Expose()
  @Type(() => Date)
  readonly date?: Date | null

  @Expose()
  @Transform(transformDecimal)
  readonly value?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedValue?: string | null

  @Expose()
  @Type(() => NetworkFuelTransaction)
  readonly fuelTransaction?: NetworkFuelTransaction

}
