import RefuellingOrdersReportsRepository from "../../domain/repositories/RefuellingOrdersReportsRepository"
import {
  GetRefuellingOrdersReportsParameters
} from "../../domain/use-cases/refuelling-orders-reports/GetRefuellingOrdersReportsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import {
  CreateRefuellingOrdersReportParameters
} from "../../domain/use-cases/refuelling-orders-reports/CreateRefuellingOrdersReportUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import RefuellingOrderStatusesStaticSource
  from "../../../../core/data/sources/static/RefuellingOrderStatusesStaticSource"
import RefuellingOrdersReportsNetworkSource
  from "../../../../core/data/sources/network/RefuellingOrdersReportsNetworkSource"
import RefuellingOrdersReport from "../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReport"
import RefuellingOrdersReportsMapper from "../../../../core/data/mappers/RefuellingOrdersReportsMapper"
import RefuellingOrdersReportError from "../../../../core/domain/refuelling-orders-reports/RefuellingOrdersReportError"
import NetworkRefuellingOrdersReportsRequestFilter
  from "../../../../core/data/entities/refuelling-orders-reports/request-queries/NetworkRefuellingOrdersReportsRequestFilter"

export default class DefaultRefuellingOrdersReportsRepository implements RefuellingOrdersReportsRepository {
  private readonly refuellingOrdersReportsNetworkSource: RefuellingOrdersReportsNetworkSource
  private readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource

  constructor(parameters: {
    readonly refuellingOrdersReportsNetworkSource: RefuellingOrdersReportsNetworkSource
    readonly refuellingOrderStatusesStaticSource: RefuellingOrderStatusesStaticSource
  }) {
    this.refuellingOrdersReportsNetworkSource = parameters.refuellingOrdersReportsNetworkSource
    this.refuellingOrderStatusesStaticSource = parameters.refuellingOrderStatusesStaticSource
  }

  async getRefuellingOrdersReports({
    pagination,
    sort,
    ids
  }: GetRefuellingOrdersReportsParameters): Promise<GetObjectsResult<RefuellingOrdersReport>> {
    const result = await this.refuellingOrdersReportsNetworkSource.getRefuellingOrdersReports({
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort }),
      filter: new NetworkRefuellingOrdersReportsRequestFilter({
        ids
      })
    })

    const reportsMapper = new RefuellingOrdersReportsMapper()
    const refuellingOrderStatuses = await this.refuellingOrderStatusesStaticSource.getRefuellingOrderStatuses({})
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.reports!.map((report) => {
              return reportsMapper.mapNetworkToDomain({
                report: report,
                refuellingOrderStatuses: refuellingOrderStatuses
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async createRefuellingOrdersReports({
    object: report
  }: CreateRefuellingOrdersReportParameters): Promise<UpdateObjectResult<RefuellingOrdersReport, RefuellingOrdersReportError>> {
    const refuellingOrderStatuses = await this.refuellingOrderStatusesStaticSource.getRefuellingOrderStatuses({})
    const mapper = new RefuellingOrdersReportsMapper()
    const result = await this.refuellingOrdersReportsNetworkSource.createRefuellingOrdersReport({
      report: mapper.mapDomainToNetwork({ report })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            report: result.data.report!,
            refuellingOrderStatuses: refuellingOrderStatuses
          })
        }
      default:
        return result
    }
  }
}
