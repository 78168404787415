import CarriersRepository from "../../domain/repositories/CarriersRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultCarriersRepository from "../../data/repositories/DefaultCarriersRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"

export default interface CarriersDataDiModule {
  provideCarriersRepository(): CarriersRepository
}

export class DefaultCarriersDataDiModule extends DiModule implements CarriersDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCarriersRepository(): CarriersRepository {
    return this.single(
      DefaultCarriersRepository.name,
      () => new DefaultCarriersRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }
}
