import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PricingTypesForCarrierCommercialTermsRepository
  from "../../domain/repositories/PricingTypesForCarrierCommercialTermsRepository"
import PricingTypesStaticSource from "../../../../core/data/sources/static/PricingTypesStaticSource"
import { PricingTypeSelectOption } from "../../../../core/domain/commercial-terms/PricingType"

export default class DefaultPricingTypesForCarrierCommercialTermsRepository
  implements PricingTypesForCarrierCommercialTermsRepository {
  private readonly pricingTypesStaticSource: PricingTypesStaticSource

  constructor(parameters: {
    readonly pricingTypesStaticSource: PricingTypesStaticSource
  }) {
    this.pricingTypesStaticSource = parameters.pricingTypesStaticSource
  }

  async getPricingTypes(): Promise<GetObjectsResult<PricingTypeSelectOption>> {
    const pricingTypes = await this.pricingTypesStaticSource.getPricingTypes()

    return {
      type: "success",
      data: {
        objects: pricingTypes,
        page: {
          hasMore: false
        }
      }
    }
  }
}
