import { Expose, Type } from "class-transformer"
import NetworkCarrierReconciliationReportErrorsObjectAttributes from "./NetworkCarrierReconciliationReportErrorsObjectAttributes"
import CarrierReconciliationReportErrorsObject
  from "../../../../../domain/carrier-reconciliation-reports/CarrierReconciliationReportErrorsObject"

export default class NetworkCarrierReconciliationReportErrorsObject implements CarrierReconciliationReportErrorsObject {
  @Expose()
  @Type(() => NetworkCarrierReconciliationReportErrorsObjectAttributes)
  readonly attributes?: NetworkCarrierReconciliationReportErrorsObjectAttributes | null
}
