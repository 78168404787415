import { Expose, Transform, Type } from "class-transformer"
import NetworkCarrier from "../carriers/NetworkCarrier"
import NetworkFuelCompany from "../fuel-companies/NetworkFuelCompany"
import NetworkFuel from "../fuels/NetworkFuel"
import PricingType from "../../../domain/commercial-terms/PricingType"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"

export default class NetworkCarrierCommercialTerm {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyId?: number | null

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompany?: NetworkFuelCompany | null

  @Expose()
  @Type(() => Number)
  readonly fuelId?: number | null

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuel?: NetworkFuel | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly beginDate?: Date | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly endDate?: Date | null

  @Expose()
  @Type(() => String)
  readonly pricingType?: PricingType | null

  @Expose()
  @Transform(transformDecimal)
  readonly price?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedPrice?: string | null

  @Expose()
  @Transform(transformDecimal)
  readonly discountPercentage?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedDiscountPercentage?: string | null

  @Expose()
  @Type(() => Number)
  readonly gasStationIds?: number[] | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStations?: NetworkGasStation[] | null

  @Expose()
  @Type(() => String)
  readonly documentUrl?: string | null
}
