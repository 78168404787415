import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import RefuellingOrdersFilter from "../../../../core/domain/refuelling-orders/RefuellingOrdersFilter"
import RefuellingOrderStatus from "../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import BaseRefuellingOrdersTableProvider from "./BaseRefuellingOrdersTableProvider"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"

export default class ClaimedRefuellingOrdersTableProvider extends BaseRefuellingOrdersTableProvider {

  getRowUrl(refuellingOrder: RefuellingOrder): string {
    const appUrlProvider = new AppUrlProvider()
    return appUrlProvider.buildShowClaimedRefuellingOrderUrl({
      refuellingOrderId: refuellingOrder.id!
    })
  }

  getFilter(): Filter<RefuellingOrdersFilter> {
    const filter = super.getFilter()
    return new Filter<RefuellingOrdersFilter>({
      fields: filter.getFields(),
      buildFilterObject: (): RefuellingOrdersFilter => ({ status: RefuellingOrderStatus.CLAIM })
    })
  }

  getTitle(): string {
    const refuellingOrdersTextProvider = this.refuellingOrdersI18n.getTextProvider()
    return refuellingOrdersTextProvider.claimedRefuellingOrders()
  }
}
