import FuelCompanyUsersRepository from "../../domain/repositories/FuelCompanyUsersRepository"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import User from "../../../../core/domain/users/User"
import { GetFuelCompanyUsersParameters } from "../../domain/use-cases/fuel-company-users/GetFuelCompanyUsersUseCase"
import UsersMapper from "../../../../core/data/mappers/UsersMapper"
import { GetFuelCompanyUserParameters } from "../../domain/use-cases/fuel-company-users/GetFuelCompanyUserUseCase"
import { CreateFuelCompanyUserParameters } from "../../domain/use-cases/fuel-company-users/CreateFuelCompanyUserUseCase"
import isPresent from "../../../../../admin/lib/isPresent"
import NetworkUsersRequestFilter from "../../../../core/data/entities/users/request-queries/NetworkUsersRequestFilter"
import { UpdateFuelCompanyUserParameters } from "../../domain/use-cases/fuel-company-users/UpdateFuelCompanyUserUseCase"
import UserError from "../../../../core/domain/users/UserError"
import FuelCompanyUsersNetworkSource from "../../../../core/data/sources/network/FuelCompanyUsersNetworkSource"

export default class DefaultFuelCompanyUsersRepository implements FuelCompanyUsersRepository {
  private readonly fuelCompanyUsersNetworkSource: FuelCompanyUsersNetworkSource

  constructor(parameters: {
    readonly fuelCompanyUsersNetworkSource: FuelCompanyUsersNetworkSource
  }) {
    this.fuelCompanyUsersNetworkSource = parameters.fuelCompanyUsersNetworkSource
  }

  async getUsers({
    filter,
    query,
    pagination,
    sort
  }: GetFuelCompanyUsersParameters): Promise<GetObjectsResult<User>> {
    const fuelCompanyId = filter?.fuelCompanyId
    const result = await this.fuelCompanyUsersNetworkSource.getUsers({
      filter: new NetworkUsersRequestFilter({
        query,
        fuelCompanyIds: isPresent(fuelCompanyId) ? [fuelCompanyId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.users!.map((user) => {
              return new UsersMapper().mapNetworkToDomain({ user })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getUser({
    objectId
  }: GetFuelCompanyUserParameters): Promise<GetObjectResult<User>> {
    const result = await this.fuelCompanyUsersNetworkSource.getUser({
      userId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }

  async createUser({
    object: user
  }: CreateFuelCompanyUserParameters): Promise<CreateObjectResult<User, UserError>> {
    const mapper = new UsersMapper()
    const result = await this.fuelCompanyUsersNetworkSource.createUser({
      user: mapper.mapDomainToNetwork({ user: user })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }

  async updateUser({
    objectId: userId,
    object: user
  }: UpdateFuelCompanyUserParameters): Promise<UpdateObjectResult<User, UserError>> {
    const mapper = new UsersMapper()
    const result = await this.fuelCompanyUsersNetworkSource.updateUser({
      userId: parseInt(userId),
      user: mapper.mapDomainToNetwork({ user })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.user!
          })
        }
      default:
        return result
    }
  }
}
