import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkTrip from "../NetworkTrip"

export default class NetworkTripsResponseBody {

  @Expose()
  @Type(() => NetworkTrip)
  readonly trips?: NetworkTrip[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
