import NetworkMenuItem from "../../../../../app/features/root/data/entities/menus/NetworkMenuItem"
import MenuItem from "../../../domain/entities/menus/MenuItem"

export default class MenuItemsMapper {
  mapNetworkToDomain({
    menuItem
  }: {
    readonly menuItem: NetworkMenuItem
  }): MenuItem {
    const mapper = new MenuItemsMapper()
    const { menuItems } = menuItem
    return {
      id: menuItem.id,
      title: menuItem.title,
      type: menuItem.type,
      resource: menuItem.resource,
      menuItems: menuItems && menuItems.map((menuItem) => {
        return mapper.mapNetworkToDomain({
          menuItem
        })
      })
    }
  }
}
