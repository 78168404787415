import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import CarrierReconciliationReportErrorsObjectAttributes
  from "../../../../../domain/carrier-reconciliation-reports/CarrierReconciliationReportErrorsObjectAttributes"

export default class NetworkCarrierReconciliationReportErrorsObjectAttributes
  implements CarrierReconciliationReportErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fromDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly toDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly carrierId?: NetworkAttributeError[] | null
}
