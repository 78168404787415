import React from "react"
import DateTableValue from "../../../entities/tables/table-value-by-type/DateTableValue"

export default function DateTableValueComponent({
  dateTableValue
}: {
  readonly dateTableValue: DateTableValue
}) {
  return <span>{dateTableValue.getValue()}</span>
}
