import React from "react"
import { DateFormFieldViewState } from "../../../entities/forms/form-field-by-type/DateFormField"
import AppDatePickerComponent
  from "../../../../../../core/presentation/components/app-date-picker/AppDatePickerComponent"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import styles from "../string-form-field/StringFormFieldComponent.module.scss"
import LabelComponent from "../../../../../../lib/label/LabelComponent"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"

export default function DateFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: DateFormFieldViewState
  readonly isDisabled: boolean
}) {
  function handleOnChange(date: Date | null) {
    fieldViewState.onChange(date)
  }

  const theme = useCoreThemeProvider()

  const name = fieldViewState.getId()
  const label = fieldViewState.getTitle()
  const isRequired = fieldViewState.getRequired()
  const clearable = fieldViewState.getClearable()
  const placeholder = fieldViewState.getPlaceholder()
  const errors = fieldViewState.getErrors()

  return (
    <div className={styles.root}>
      {label && name && (
        <LabelComponent
          htmlFor={name}
          text={`${label}${isRequired ? " *" : ""}`}
          className={theme.label1PrimaryClassName()}
        />
      )}
      <AppDatePickerComponent
        name={name}
        clearable={clearable}
        value={fieldViewState.value ?? null}
        timeZone={fieldViewState.timeZone}
        disabled={isDisabled || !fieldViewState.getEditable()}
        onChange={handleOnChange}
        placeholder={placeholder}
        withTime={fieldViewState.withTime}
        hasErrors={(errors && errors.length > 0) ?? false}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
