import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkFuelCompany from "../NetworkFuelCompany"

export default class NetworkFuelCompaniesResponseBody {

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompanies?: NetworkFuelCompany[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
