import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkGasStationBreaksRequestFilter
  from "../../entities/gas-station-breaks/request-queries/NetworkGasStationBreaksRequestFilter"
import NetworkGasStationBreakResponseBody
  from "../../entities/gas-station-breaks/response-bodies/NetworkGasStationBreakResponseBody"
import NetworkGasStationBreakErrorResponseBody
  from "../../entities/gas-station-breaks/response-bodies/errors/NetworkGasStationBreakErrorResponseBody"
import NetworkGasStationBreaksRequestQuery
  from "../../entities/gas-station-breaks/request-queries/NetworkGasStationBreaksRequestQuery"
import NetworkGasStationBreaksResponseBody
  from "../../entities/gas-station-breaks/response-bodies/NetworkGasStationBreaksResponseBody"
import NetworkGasStationBreak from "../../entities/gas-station-breaks/NetworkGasStationBreak"
import NetworkGasStationBreakRequestBody
  from "../../entities/gas-station-breaks/request-bodies/NetworkGasStationBreakRequestBody"

const basePath = "/api/admin/gas_station_breaks"

export default class GasStationBreaksNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getGasStationBreaks({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkGasStationBreaksRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetGasStationBreaksNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkGasStationBreaksRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationBreaksResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getGasStationBreak({
    gasStationBreakId
  }: {
    readonly gasStationBreakId: number
  }): Promise<GetGasStationBreakNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${gasStationBreakId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationBreakResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createGasStationBreak({
    gasStationBreak
  }: {
    readonly gasStationBreak?: NetworkGasStationBreak | null
  }): Promise<CreateGasStationBreakNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkGasStationBreakRequestBody({
        gasStationBreak
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationBreakResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkGasStationBreakErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateGasStationBreak({
    gasStationBreakId,
    gasStationBreak
  }: {
    readonly gasStationBreakId: number
    readonly gasStationBreak?: NetworkGasStationBreak | null
  }): Promise<UpdateGasStationBreakNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${gasStationBreakId}`,
      body: instanceToPlain(new NetworkGasStationBreakRequestBody({
        gasStationBreak
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkGasStationBreakResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkGasStationBreakErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetGasStationBreaksNetworkResult =
  SuccessExecutionResult<NetworkGasStationBreaksResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetGasStationBreakNetworkResult =
  SuccessExecutionResult<NetworkGasStationBreakResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateGasStationBreakNetworkResult =
  SuccessExecutionResult<NetworkGasStationBreakResponseBody> |
  ErrorExecutionResult<NetworkGasStationBreakErrorResponseBody> |
  FailureExecutionResult

export type UpdateGasStationBreakNetworkResult =
  SuccessExecutionResult<NetworkGasStationBreakResponseBody> |
  ErrorExecutionResult<NetworkGasStationBreakErrorResponseBody> |
  FailureExecutionResult
