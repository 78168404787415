import { Expose, Type } from "class-transformer"

export default class NetworkFuel {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly uom?: string | null

  @Expose()
  @Type(() => String)
  readonly shortFormattedUom?: string | null

  @Expose()
  @Type(() => String)
  readonly colorHex?: string | null
}
