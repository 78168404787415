import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkTransportsRequestFilter from "../../entities/transports/request-queries/NetworkTransportsRequestFilter"
import NetworkTransportsResponseBody from "../../entities/transports/response-bodies/NetworkTransportsResponseBody"
import NetworkTransportResponseBody from "../../entities/transports/response-bodies/NetworkTransportResponseBody"
import NetworkTransportErrorResponseBody
  from "../../entities/transports/response-bodies/errors/NetworkTransportErrorResponseBody"
import NetworkTransportsRequestQuery from "../../entities/transports/request-queries/NetworkTransportsRequestQuery"
import NetworkTransport from "../../entities/transports/NetworkTransport"
import NetworkTransportRequestBody from "../../entities/transports/request-bodies/NetworkTransportRequestBody"

const basePath = "/api/admin/transports"

export default class TransportsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getTransports({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkTransportsRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetTransportsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkTransportsRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTransportsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getTransport({
    transportId
  }: {
    readonly transportId: number
  }): Promise<GetTransportNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${transportId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTransportResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createTransport({
    transport
  }: {
    readonly transport?: NetworkTransport | null
  }): Promise<CreateTransportNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkTransportRequestBody({
        transport
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTransportResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkTransportErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateTransport({
    transportId,
    transport
  }: {
    readonly transportId: number
    readonly transport?: NetworkTransport | null
  }): Promise<UpdateTransportNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${transportId}`,
      body: instanceToPlain(new NetworkTransportRequestBody({
        transport
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTransportResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkTransportErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetTransportsNetworkResult =
  SuccessExecutionResult<NetworkTransportsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetTransportNetworkResult =
  SuccessExecutionResult<NetworkTransportResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateTransportNetworkResult =
  SuccessExecutionResult<NetworkTransportResponseBody> |
  ErrorExecutionResult<NetworkTransportErrorResponseBody> |
  FailureExecutionResult

export type UpdateTransportNetworkResult =
  SuccessExecutionResult<NetworkTransportResponseBody> |
  ErrorExecutionResult<NetworkTransportErrorResponseBody> |
  FailureExecutionResult
