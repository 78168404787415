import CarrierCommercialTermsRepository from "../../domain/repositories/CarrierCommercialTermsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultCarrierCommercialTermsRepository from "../../data/repositories/DefaultCarrierCommercialTermsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForCarrierCommercialTermsRepository from "../../domain/repositories/FuelCompaniesForCarrierCommercialTermsRepository"
import DefaultFuelCompaniesForCarrierCommercialTermsRepository
  from "../../data/repositories/DefaultFuelCompaniesForCarrierCommercialTermsRepository"
import CarriersForCarrierCommercialTermsRepository
  from "../../domain/repositories/CarriersForCarrierCommercialTermsRepository"
import DefaultCarriersForCarrierCommercialTermsRepository
  from "../../data/repositories/DefaultCarriersForCarrierCommercialTermsRepository"
import FuelsForCarrierCommercialTermsRepository
  from "../../domain/repositories/FuelsForCarrierCommercialTermsRepository"
import DefaultFuelsForCarrierCommercialTermsRepository
  from "../../data/repositories/DefaultFuelsForCarrierCommercialTermsRepository"
import GasStationsForCarrierCommercialTermsRepository
  from "../../domain/repositories/GasStationsForCarrierCommercialTermsRepository"
import DefaultGasStationsForCarrierCommercialTermsRepository
  from "../../data/repositories/DefaultGasStationsForCarrierCommercialTermsRepository"
import PricingTypesForCarrierCommercialTermsRepository
  from "../../domain/repositories/PricingTypesForCarrierCommercialTermsRepository"
import DefaultPricingTypesForCarrierCommercialTermsRepository
  from "../../data/repositories/DefaultPricingTypesForCarrierCommercialTermsRepository"

export default interface CarrierCommercialTermsDataDiModule {
  provideCarrierCommercialTermsRepository(): CarrierCommercialTermsRepository

  provideCarriersForCarrierCommercialTermsRepository(): CarriersForCarrierCommercialTermsRepository

  provideFuelCompaniesForCarrierCommercialTermsRepository(): FuelCompaniesForCarrierCommercialTermsRepository

  provideFuelsForCarrierCommercialTermsRepository(): FuelsForCarrierCommercialTermsRepository

  provideGasStationsForCarrierCommercialTermsRepository(): GasStationsForCarrierCommercialTermsRepository

  providePricingTypesForCarrierCommercialTermsRepository(): PricingTypesForCarrierCommercialTermsRepository
}

export class DefaultCarrierCommercialTermsDataDiModule extends DiModule implements CarrierCommercialTermsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCarrierCommercialTermsRepository(): CarrierCommercialTermsRepository {
    return this.single(
      DefaultCarrierCommercialTermsRepository.name,
      () => new DefaultCarrierCommercialTermsRepository({
        carrierCommercialTermsNetworkSource: this.appDataDiModule.provideCarrierCommercialTermsNetworkSource(),
        pricingTypesStaticSource: this.appDataDiModule.providePricingTypesStaticSource()
      })
    )
  }

  provideCarriersForCarrierCommercialTermsRepository(): CarriersForCarrierCommercialTermsRepository {
    return this.single(
      DefaultCarriersForCarrierCommercialTermsRepository.name,
      () => new DefaultCarriersForCarrierCommercialTermsRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }

  provideFuelCompaniesForCarrierCommercialTermsRepository(): FuelCompaniesForCarrierCommercialTermsRepository {
    return this.single(
      DefaultFuelCompaniesForCarrierCommercialTermsRepository.name,
      () => new DefaultFuelCompaniesForCarrierCommercialTermsRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }

  provideFuelsForCarrierCommercialTermsRepository(): FuelsForCarrierCommercialTermsRepository {
    return this.single(
      DefaultFuelsForCarrierCommercialTermsRepository.name,
      () => new DefaultFuelsForCarrierCommercialTermsRepository({
        fuelsNetworkSource: this.appDataDiModule.provideFuelsNetworkSource()
      })
    )
  }

  provideGasStationsForCarrierCommercialTermsRepository(): GasStationsForCarrierCommercialTermsRepository {
    return this.single(
      DefaultGasStationsForCarrierCommercialTermsRepository.name,
      () => new DefaultGasStationsForCarrierCommercialTermsRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }

  providePricingTypesForCarrierCommercialTermsRepository(): PricingTypesForCarrierCommercialTermsRepository {
    return this.single(
      DefaultPricingTypesForCarrierCommercialTermsRepository.name,
      () => new DefaultPricingTypesForCarrierCommercialTermsRepository({
        pricingTypesStaticSource: this.appDataDiModule.providePricingTypesStaticSource()
      })
    )
  }
}
