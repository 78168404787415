import PermissionGroup from "../../../domain/entities/user-profile/PermissionGroup"
import { CheckPermissionDeniedParameters } from "../../../domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import isBlank from "../../../../lib/isBlank"
import isPresent from "../../../../lib/isPresent"

export default class UserProfileLocalSource {
  private permissionGroup?: PermissionGroup = undefined

  setPermissionGroup(permissionGroup?: PermissionGroup) {
    this.permissionGroup = permissionGroup
  }

  getUserType(): string {
    return this.permissionGroup?.userType ?? ""
  }

  checkPermissionsDenied(parameters: CheckPermissionDeniedParameters): boolean {
    const { entity, entityField, restrictionType } = parameters
    const permissions = this.permissionGroup?.permissions ?? []

    const deniedPermission = permissions.find(permission => {
      return (isBlank(entity) || permission.entity === entity) &&
        (isBlank(entityField) || permission.entityField === entityField) &&
        (isBlank(restrictionType) || permission.restrictionType === restrictionType)
    })

    return isPresent(deniedPermission)
  }

  clearPermissionGroup() {
    this.permissionGroup = undefined
  }
}
