import FormField, { FormFieldParameters, FormFieldViewState, FormFieldViewStateParameters } from "../FormField"
import { Decimal } from "decimal.js"
import isPresent from "../../../../../../lib/isPresent"

export default class DecimalFormField<DomainObject, ErrorsObject> extends FormField<DomainObject, ErrorsObject> {
  private readonly scale: number | undefined
  private readonly thousandsSeparator: string | undefined
  private readonly shouldShowTrailingZeroes: boolean | undefined
  private readonly minValue: number | undefined
  private readonly maxValue: number | undefined
  private readonly getValue: (object: DomainObject) => Decimal | null | undefined
  private readonly setValue: (object: DomainObject, value: Decimal | null | undefined) => DomainObject

  constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject> & {
    readonly scale?: number
    readonly thousandsSeparator?: string
    readonly shouldShowTrailingZeroes?: boolean
    readonly minValue?: number
    readonly maxValue?: number
    readonly getValue: (object: DomainObject) => Decimal | null | undefined
    readonly setValue: (object: DomainObject, value: Decimal | null | undefined) => DomainObject
  }) {
    super(parameters)
    this.scale = parameters.scale
    this.thousandsSeparator = parameters.thousandsSeparator
    this.shouldShowTrailingZeroes = parameters.shouldShowTrailingZeroes
    this.minValue = parameters.minValue
    this.maxValue = parameters.maxValue
    this.getValue = parameters.getValue
    this.setValue = parameters.setValue
  }

  getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState {
    return new DecimalFormFieldViewState({
      ...this.getFormFieldViewStateParameters(object, errorsObject),
      scale: this.scale,
      thousandsSeparator: this.thousandsSeparator,
      shouldShowTrailingZeroes: this.shouldShowTrailingZeroes,
      minValue: this.minValue,
      maxValue: this.maxValue,
      value: this.getValue(object),
      onChange: (value: Decimal | null | undefined) => {
        this.setObject(this.setValue(object, value))
        this.setAndShowLoadedObjectViewState()
      }
    })
  }

  serializeValue(object: DomainObject): string | null | undefined {
    const value: Decimal | null | undefined = this.getValue(object)
    return value?.toFixed()
  }

  async init({
    object,
    serializedValue
  }: {
    readonly object: DomainObject
    readonly serializedValue: string | null | undefined
  }): Promise<void> {
    if (serializedValue === null || serializedValue === undefined) {
      return
    }

    this.setObject(this.setValue(object, new Decimal(serializedValue)))
  }
}

export class DecimalFormFieldViewState extends FormFieldViewState {
  readonly value: Decimal | null | undefined
  readonly scale: number | undefined
  readonly shouldShowTrailingZeroes: boolean | undefined
  readonly thousandsSeparator: string | undefined
  readonly minValue: number | undefined
  readonly maxValue: number | undefined
  onChange: (value: Decimal | null | undefined) => void

  constructor(parameters: FormFieldViewStateParameters & {
    readonly scale: number | undefined
    readonly shouldShowTrailingZeroes: boolean | undefined
    readonly thousandsSeparator: string | undefined
    readonly minValue: number | undefined
    readonly maxValue: number | undefined
    readonly onChange: (value: Decimal | null | undefined) => void
    readonly value: Decimal | null | undefined
  }) {
    super(parameters)
    this.minValue = parameters.minValue
    this.maxValue = parameters.maxValue
    this.scale = parameters.scale
    this.thousandsSeparator = parameters.thousandsSeparator
    this.shouldShowTrailingZeroes = parameters.shouldShowTrailingZeroes
    this.value = parameters.value
    this.onChange = parameters.onChange
  }

  hasValue(): boolean {
    return isPresent(this.value)
  }
}
