import FuelCompanyBalanceTransactionsRepository from "../../repositories/FuelCompanyBalanceTransactionsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompanyBalanceTransaction
  from "../../../../../core/domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"

export interface GetFuelCompanyBalanceTransactionsParameters
  extends GetObjectsParameters {
  readonly fuelCompanyId: string | number
}

export default class GetFuelCompanyBalanceTransactionsUseCase
  implements GetObjectsUseCase<FuelCompanyBalanceTransaction> {
  private readonly fuelCompanyBalanceTransactionsRepository: FuelCompanyBalanceTransactionsRepository

  constructor(parameters: {
    readonly fuelCompanyBalanceTransactionsRepository: FuelCompanyBalanceTransactionsRepository
  }) {
    this.fuelCompanyBalanceTransactionsRepository = parameters.fuelCompanyBalanceTransactionsRepository
  }

  async call(
    parameters: GetFuelCompanyBalanceTransactionsParameters
  ): Promise<GetObjectsResult<FuelCompanyBalanceTransaction>> {
    return await this.fuelCompanyBalanceTransactionsRepository.getTransactions(parameters)
  }
}
