import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import CarrierReconciliationReportsDomainDiModule from "./CarrierReconciliationReportsDomainDiModule"
import CarrierReconciliationReportsI18nDiModule from "./CarrierReconciliationReportsI18nDiModule"
import CarrierReconciliationReportsTableProvider
  from "../../presentation/table-providers/CarrierReconciliationReportsTableProvider"
import CarrierReconciliationReportsFormProvider
  from "../../presentation/form-providers/CarrierReconciliationReportsFormProvider"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "carrierReconciliationReports"

export default interface CarrierReconciliationReportsPresentationDiModule {
  provideCarrierReconciliationReportsListPage(): React.ReactNode

  provideCarrierReconciliationReportFormPage(): React.ReactNode
}

export class DefaultCarrierReconciliationReportsPresentationDiModule
  extends DiModule
  implements CarrierReconciliationReportsPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly carrierReconciliationReportsI18nDiModule: CarrierReconciliationReportsI18nDiModule
  private readonly carrierReconciliationReportsDomainDiModule: CarrierReconciliationReportsDomainDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly carrierReconciliationReportsDomainDiModule: CarrierReconciliationReportsDomainDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly carrierReconciliationReportsI18nDiModule: CarrierReconciliationReportsI18nDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.carrierReconciliationReportsI18nDiModule = parameters.carrierReconciliationReportsI18nDiModule
    this.carrierReconciliationReportsDomainDiModule = parameters.carrierReconciliationReportsDomainDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
  }

  provideCarrierReconciliationReportsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase:
        this.carrierReconciliationReportsDomainDiModule.provideGetCarrierReconciliationReportsUseCase(),
      tableProvider: this.provideCarrierReconciliationReportsTableProvider()
    })
  }

  private provideCarrierReconciliationReportsTableProvider(): CarrierReconciliationReportsTableProvider {
    return this.single(
      CarrierReconciliationReportsTableProvider.name,
      () => new CarrierReconciliationReportsTableProvider({
        timeZone: this.timeZone,
        carrierReconciliationReportsI18n:
          this.carrierReconciliationReportsI18nDiModule.provideCarrierReconciliationReportsI18n(),
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase()
      })
    )
  }

  provideCarrierReconciliationReportFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      createObjectUseCase:
        this.carrierReconciliationReportsDomainDiModule.provideCreateCarrierReconciliationReportUseCase(),
      formProvider: this.provideCarrierReconciliationReportsFormProvider()
    })
  }

  private provideCarrierReconciliationReportsFormProvider(): CarrierReconciliationReportsFormProvider {
    return this.single(
      CarrierReconciliationReportsFormProvider.name,
      () => new CarrierReconciliationReportsFormProvider({
        carrierReconciliationReportsI18:
          this.carrierReconciliationReportsI18nDiModule.provideCarrierReconciliationReportsI18n(),
        getCarriersUseCase:
          this.carrierReconciliationReportsDomainDiModule.provideGetCarriersForRefuellingOrdersReportsUseCase(),
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase()
      })
    )
  }
}
