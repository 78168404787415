import RefuellingOrdersRepository from "../../repositories/RefuellingOrdersRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import RefuellingOrder from "../../../../../core/domain/refuelling-orders/RefuellingOrder"
import RefuellingOrderError from "../../../../../core/domain/refuelling-orders/RefuellingOrderError"

export interface UpdateRefuellingOrderParameters {
  readonly objectId: string
  readonly object: RefuellingOrder
}

export default class UpdateRefuellingOrderUseCase
  implements UpdateObjectUseCase<RefuellingOrder, RefuellingOrderError> {
  private readonly refuellingOrdersRepository: RefuellingOrdersRepository

  constructor(parameters: {
    readonly refuellingOrdersRepository: RefuellingOrdersRepository
  }) {
    this.refuellingOrdersRepository = parameters.refuellingOrdersRepository
  }

  async call(
    parameters: UpdateRefuellingOrderParameters
  ): Promise<UpdateObjectResult<RefuellingOrder, RefuellingOrderError>> {
    return await this.refuellingOrdersRepository.updateRefuellingOrder(parameters)
  }
}
