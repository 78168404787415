import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import TripStatusesStaticSource from "../../../../core/data/sources/static/TripStatusesStaticSource"
import { TripStatusSelectOption } from "../../../../core/domain/trips/TripStatus"
import TripStatusesForTripsRepository from "../../domain/repositories/TripStatusesForTripsRepository"
import { GetTripStatusesForTripsParameters } from "../../domain/use-cases/trip-statuses/GetTripStatusesForTripsUseCase"

export default class DefaultTripStatusesForTripsRepository
  implements TripStatusesForTripsRepository {
  private readonly tripStatusesStaticSource: TripStatusesStaticSource

  constructor(parameters: {
    readonly tripStatusesStaticSource: TripStatusesStaticSource
  }) {
    this.tripStatusesStaticSource = parameters.tripStatusesStaticSource
  }

  async getTripStatuses({
    ids
  }: GetTripStatusesForTripsParameters): Promise<GetObjectsResult<TripStatusSelectOption>> {
    const statuses = await this.tripStatusesStaticSource.getTripStatuses({
      ids
    })

    return {
      type: "success",
      data: {
        objects: statuses,
        page: {
          hasMore: false
        }
      }
    }
  }
}
