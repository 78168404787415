import React from "react"
import useWeekDayNameByNumber from "../../hooks/useWeekDayNameByNumber"
import useMonthNameByNumber from "../../hooks/useMonthNameByNumber"
import DatePickerComponent from "../../../../lib/date-picker/date-picker/DatePickerComponent"
import { useCoreThemeProvider } from "../../contexts/CoreThemeProviderContext"

export default function AppDatePickerComponent(parameters: {
  readonly name: string
  readonly value: Date | null
  readonly timeZone?: string
  readonly onChange: (date: Date | null) => void
  readonly disabled?: boolean
  readonly clearable?: boolean
  readonly placeholder?: string
  readonly clearButtonText?: string
  readonly hasErrors?: boolean
  readonly withTime: boolean
}) {
  const coreTheme = useCoreThemeProvider()
  const weekDayNameByNumber: { [key: number]: string } = useWeekDayNameByNumber()
  const monthNameByNumber: { [key: number]: string } = useMonthNameByNumber()

  return (
    <DatePickerComponent
      {...parameters}
      componentStyle={coreTheme.datePicker1PrimaryStyle()}
      weekDayNameByNumber={weekDayNameByNumber}
      monthNameByNumber={monthNameByNumber}
    />
  )
}
