import Permission from "../../../domain/entities/user-profile/Permission"
import NetworkPermission from "../../entities/network/user-profile/NetworkPermission"

export default class PermissionsMapper {
  mapNetworkToDomain({
    permission
  }: {
    readonly permission: NetworkPermission
  }): Permission {
    return {
      entity: permission.entity,
      entityField: permission.entityField,
      restrictionType: permission.restrictionType
    }
  }
}
