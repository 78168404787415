import React, { useRef } from "react"
import SelectPickerComponent, {
  SelectPickerComponentProps
} from "../../../../lib/picker/select-picker/SelectPickerComponent"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import styles from "./AppSelectPickerComponent.module.scss"
import Page from "../../../domain/entities/pages/Page"
import isPresent from "../../../../lib/isPresent"
import PaginationComponent from "../pagination/PaginationComponent"
import TextInputComponent, { TextInputComponentRef } from "../../../../lib/text-input/TextInputComponent"
import { useCoreThemeProvider } from "../../contexts/CoreThemeProviderContext"
import ContentLoadingComponent from "../content-loading/ContentLoadingComponent"

export default function AppSelectPickerComponent(props: SelectPickerComponentProps & {
  readonly query?: string | null
  readonly page?: Page
  readonly onQueryChanged: (query: string) => void
  readonly onSearchRequested: () => void
  readonly onNextPageRequested: () => void
  readonly clearable?: boolean
  readonly searchingEnabled: boolean
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const theme = useCoreThemeProvider()
  const searchBarRef: React.MutableRefObject<TextInputComponentRef | null> = useRef(null)

  const handleInputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onSearchRequested()
      event.preventDefault()
    }
  }

  function handleOnOpened() {
    searchBarRef.current?.focusOnInput()

    props.onOpened && props.onOpened()
  }

  function requestNextPage() {
    if (!props.isLoading && isPresent(props.page) && props.page.hasMore) {
      props.onNextPageRequested()
    }
  }

  return (
    <SelectPickerComponent
      {...props}
      clearable={props.clearable ?? true}
      placeholder={props.placeholder ?? coreTextProvider.select()}
      onOpened={handleOnOpened}
      className={styles.selectPicker}
      componentStyle={theme.selectPicker1PrimaryStyle()}
      aboveOptionsElement={
        <>
          {props.searchingEnabled && (
            <>
              <div className={styles.searchBar}>
                <TextInputComponent
                  ref={searchBarRef}
                  value={props.query ?? ""}
                  componentStyle={theme.searchBar1PrimaryStyle()}
                  onChange={props.onQueryChanged}
                  onKeyDown={handleInputOnKeyDown}
                  className={styles.search}
                  placeholder={coreTextProvider.enterSearchQuery()}
                />
              </div>
              {!props.isLoading && props.options.length === 0 && (
                <div className={`${styles.noObjects} ${theme.dropdownItem1SecondaryClassName()}`}>
                  {coreTextProvider.noFoundedObjects()}
                </div>
              )}
            </>
          )}
        </>
      }
      belowOptionsElement={
        <>
          {props.isLoading && <ContentLoadingComponent />}
          <PaginationComponent onComponentAppeared={requestNextPage} />
        </>
      }
    />
  )
}
