import CurrentCarrierBalanceTransactionsRepository
  from "../../domain/repositories/CurrentCarrierBalanceTransactionsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForCurrentCarrierBalancesRepository
  from "../../domain/repositories/CarriersForCurrentCarrierBalancesRepository"
import DefaultCarriersForCurrentCarrierBalancesRepository
  from "../../data/repositories/DefaultCarriersForCurrentCarrierBalancesRepository"
import DefaultCurrentCarrierBalanceTransactionsRepository
  from "../../data/repositories/DefaultCurrentCarrierBalanceTransactionsRepository"
import CurrentCarrierBalanceChangeDocumentsRepository
  from "../../domain/repositories/CurrentCarrierBalanceChangeDocumentsRepository"
import DefaultCurrentCarrierBalanceChangeDocumentsRepository
  from "../../data/repositories/DefaultCurrentCarrierBalanceChangeDocumentsRepository"

export default interface CurrentCarrierBalancesDataDiModule {
  provideCurrentCarrierBalanceTransactionsRepository(): CurrentCarrierBalanceTransactionsRepository

  provideCurrentCarrierBalanceChangeDocumentsRepository(): CurrentCarrierBalanceChangeDocumentsRepository

  provideCarriersForCurrentCarrierBalancesRepository(): CarriersForCurrentCarrierBalancesRepository
}

export class DefaultCurrentCarrierBalancesDataDiModule extends DiModule implements CurrentCarrierBalancesDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideCurrentCarrierBalanceTransactionsRepository(): CurrentCarrierBalanceTransactionsRepository {
    return this.single(
      DefaultCurrentCarrierBalanceTransactionsRepository.name,
      () => new DefaultCurrentCarrierBalanceTransactionsRepository({
        carrierBalanceTransactionsNetworkSource:
          this.appDataDiModule.provideCurrentCarrierBalanceTransactionsNetworkSource()
      })
    )
  }

  provideCurrentCarrierBalanceChangeDocumentsRepository(): CurrentCarrierBalanceChangeDocumentsRepository {
    return this.single(
      DefaultCurrentCarrierBalanceChangeDocumentsRepository.name,
      () => new DefaultCurrentCarrierBalanceChangeDocumentsRepository({
        currentCarrierBalanceChangeDocumentsNetworkSource:
          this.appDataDiModule.provideCurrentCarrierBalanceChangeDocumentsNetworkSource()
      })
    )
  }

  provideCarriersForCurrentCarrierBalancesRepository(): CarriersForCurrentCarrierBalancesRepository {
    return this.single(
      DefaultCarriersForCurrentCarrierBalancesRepository.name,
      () => new DefaultCarriersForCurrentCarrierBalancesRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }
}
