import UsersRepository from "../../repositories/UsersRepository"
import UpdateObjectUseCase, {
  UpdateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import UserError from "../../../../../core/domain/users/UserError"

export interface UpdateUserParameters {
  readonly objectId: string
  readonly object: User
}

export default class UpdateUserUseCase implements UpdateObjectUseCase<User, UserError> {
  private readonly usersRepository: UsersRepository

  constructor(parameters: {
    readonly usersRepository: UsersRepository
  }) {
    this.usersRepository = parameters.usersRepository
  }

  async call(parameters: UpdateUserParameters): Promise<UpdateObjectResult<User, UserError>> {
    return await this.usersRepository.updateUser(parameters)
  }
}
