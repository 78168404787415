import React from "react"
import styles from "./ArrayInfoRowComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import { ArrayInfoRowItem } from "../../../entities/info/info-row-by-type/ArrayInfoRow"
import ChipComponent from "../../../../../../lib/chip/ChipComponent"

export default function ArrayInfoRowComponent({
  items,
  canShowUrl
}: {
  readonly items: ArrayInfoRowItem[],
  readonly canShowUrl?: boolean
}) {
  const theme = useCoreThemeProvider()

  return (
    <div className={styles.root}>
      {items.map((item) => {
        return (
          <ChipComponent
            key={item.key}
            text={item.value ?? ""}
            url={item.url ?? undefined}
            canShowUrl={canShowUrl}
            className={theme.chip1PrimaryClassName()}
          />
        )
      })}
    </div>
  )
}
