import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarriersForRefuellingOrdersReportsRepository
  from "../../repositories/CarriersForRefuellingOrdersReportsRepository"
import Carrier from "../../../../../core/domain/carriers/Carrier"

export interface GetCarriersForRefuellingOrdersReportsParameters extends GetObjectsParameters {
}

export default class GetCarriersForRefuellingOrdersReportsUseCase implements GetObjectsUseCase<Carrier> {
  private readonly carriersForRefuellingOrdersReportsRepository: CarriersForRefuellingOrdersReportsRepository

  constructor(parameters: {
    readonly carriersForRefuellingOrdersReportsRepository: CarriersForRefuellingOrdersReportsRepository
  }) {
    this.carriersForRefuellingOrdersReportsRepository = parameters.carriersForRefuellingOrdersReportsRepository
  }

  async call(parameters: GetCarriersForRefuellingOrdersReportsParameters): Promise<GetObjectsResult<Carrier>> {
    return await this.carriersForRefuellingOrdersReportsRepository.getCarriers(parameters)
  }
}
