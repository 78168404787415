import Presenter from "../../../../../lib/presenter/Presenter"
import PasswordRecoveryRequestView from "./PasswordRecoveryRequestView"
import IsSessionExistUseCase from "../../../../../core/domain/use-cases/sessions/IsSessionExistUseCase"
import assertNever from "../../../../../lib/assertNever"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import autoBind from "auto-bind"
import RequestPasswordRecoveryUseCase, {
  RequestPasswordRecoveryResult
} from "../../../domain/use-cases/password-recovery-request/RequestPasswordRecoveryUseCase"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"

export default class PasswordRecoveryRequestPresenter extends Presenter<PasswordRecoveryRequestView> {
  private readonly isSessionExistUseCase: IsSessionExistUseCase
  private readonly requestPasswordRecoveryUseCase: RequestPasswordRecoveryUseCase
  private emailAddress: string
  private isPasswordRecoveryRequesting: boolean
  private passwordRecoveryRequestError?: ExecutionError
  private passwordRecoveryRequestFailureException?: ApplicationException

  constructor(parameters: {
    readonly isSessionExistUseCase: IsSessionExistUseCase
    readonly requestPasswordRecoveryUseCase: RequestPasswordRecoveryUseCase
  }) {
    super()

    autoBind(this)

    this.isSessionExistUseCase = parameters.isSessionExistUseCase
    this.requestPasswordRecoveryUseCase = parameters.requestPasswordRecoveryUseCase
    this.emailAddress = ""
    this.isPasswordRecoveryRequesting = false
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()
    this.checkIsCurrentUserAuthenticatedAndShowViewState()
  }

  onEmailAddressChanged({ emailAddress }: { readonly emailAddress: string }) {
    this.setEmailAddress(emailAddress)
    this.showNotAuthenticatedPasswordRecoveryRequestViewState()
  }

  onSendEmailClicked() {
    this.requestPasswordRecoveryAndShowViewState().then()
  }

  private checkIsCurrentUserAuthenticatedAndShowViewState() {
    const isSessionExist: boolean = this.isSessionExistUseCase.call()

    if (isSessionExist) {
      this.showAlreadyAuthenticatedPasswordRecoveryRequestViewState()
    } else {
      this.showNotAuthenticatedPasswordRecoveryRequestViewState()
    }
  }

  private async requestPasswordRecoveryAndShowViewState(): Promise<void> {
    this.setIsPasswordRecovering(true)
    this.setPasswordRecoveryRequestError(undefined)
    this.setPasswordRecoveryRequestFailureException(undefined)
    this.showNotAuthenticatedPasswordRecoveryRequestViewState()

    const passwordRecoveryRequestResult: RequestPasswordRecoveryResult =
      await this.requestPasswordRecoveryUseCase.call({ emailAddress: this.emailAddress })

    this.setIsPasswordRecovering(false)

    switch (passwordRecoveryRequestResult.type) {
      case "success":
        this.showPasswordRecoveryRequestedViewState(passwordRecoveryRequestResult.data.message)
        break
      case "error":
        this.setPasswordRecoveryRequestError(passwordRecoveryRequestResult.error)
        this.showNotAuthenticatedPasswordRecoveryRequestViewState()
        break
      case "failure":
        this.setPasswordRecoveryRequestFailureException(passwordRecoveryRequestResult.exception)
        this.showNotAuthenticatedPasswordRecoveryRequestViewState()
        break
      default:
        assertNever(passwordRecoveryRequestResult)
    }
  }

  private setEmailAddress(emailAddress: string) {
    this.emailAddress = emailAddress
    this.passwordRecoveryRequestError = undefined
  }

  private setIsPasswordRecovering(isRecovering: boolean) {
    this.isPasswordRecoveryRequesting = isRecovering
  }

  private setPasswordRecoveryRequestError(error: ExecutionError | undefined) {
    this.passwordRecoveryRequestError = error
  }

  private setPasswordRecoveryRequestFailureException(failureException?: ApplicationException | undefined) {
    this.passwordRecoveryRequestFailureException = failureException
  }

  private showAlreadyAuthenticatedPasswordRecoveryRequestViewState() {
    this.getView()?.showPasswordRecoveryRequestViewState({
        type: "already_authenticated"
      })
  }

  private showNotAuthenticatedPasswordRecoveryRequestViewState() {
    this.getView()?.showPasswordRecoveryRequestViewState({
        type: "not_authenticated",
        emailAddress: this.emailAddress,
        isPasswordRecovering: this.isPasswordRecoveryRequesting,
        passwordRecoveryRequestError: this.passwordRecoveryRequestError,
        passwordRecoveryRequestFailureException: this.passwordRecoveryRequestFailureException
      })
  }

  private showPasswordRecoveryRequestedViewState(successMessage: string | null | undefined) {
    this.getView()?.showPasswordRecoveryRequestViewState({
        type: "password_recovery_requested",
        successMessage: successMessage
      })
  }
}
