import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkFuelPricesRequestFilter from "../../entities/fuel-prices/request-queries/NetworkFuelPricesRequestFilter"
import NetworkFuelPricesResponseBody from "../../entities/fuel-prices/response-bodies/NetworkFuelPricesResponseBody"
import NetworkFuelPriceResponseBody from "../../entities/fuel-prices/response-bodies/NetworkFuelPriceResponseBody"
import NetworkFuelPriceErrorResponseBody
  from "../../entities/fuel-prices/response-bodies/errors/NetworkFuelPriceErrorResponseBody"
import NetworkFuelPricesRequestQuery from "../../entities/fuel-prices/request-queries/NetworkFuelPricesRequestQuery"
import NetworkFuelPrice from "../../entities/fuel-prices/NetworkFuelPrice"
import NetworkFuelPriceRequestBody from "../../entities/fuel-prices/request-bodies/NetworkFuelPriceRequestBody"

const basePath = "/api/admin/fuel_prices"

export default class FuelPricesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getFuelPrices({
    filter,
    pagination,
    sort
  }: {
    readonly filter?: NetworkFuelPricesRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetFuelPricesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkFuelPricesRequestQuery({
        pagination,
        filter,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelPricesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async getFuelPrice({
    fuelPriceId
  }: {
    readonly fuelPriceId: number
  }): Promise<GetFuelPriceNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${fuelPriceId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelPriceResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createFuelPrice({
    fuelPrice
  }: {
    readonly fuelPrice?: NetworkFuelPrice | null
  }): Promise<CreateFuelPriceNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkFuelPriceRequestBody({
        fuelPrice
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelPriceResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelPriceErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }

  async updateFuelPrice({
    fuelPriceId,
    fuelPrice
  }: {
    readonly fuelPriceId: number
    readonly fuelPrice?: NetworkFuelPrice | null
  }): Promise<UpdateFuelPriceNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.PUT,
      path: `${basePath}/${fuelPriceId}`,
      body: instanceToPlain(new NetworkFuelPriceRequestBody({
        fuelPrice
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkFuelPriceResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkFuelPriceErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetFuelPricesNetworkResult =
  SuccessExecutionResult<NetworkFuelPricesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type GetFuelPriceNetworkResult =
  SuccessExecutionResult<NetworkFuelPriceResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateFuelPriceNetworkResult =
  SuccessExecutionResult<NetworkFuelPriceResponseBody> |
  ErrorExecutionResult<NetworkFuelPriceErrorResponseBody> |
  FailureExecutionResult

export type UpdateFuelPriceNetworkResult =
  SuccessExecutionResult<NetworkFuelPriceResponseBody> |
  ErrorExecutionResult<NetworkFuelPriceErrorResponseBody> |
  FailureExecutionResult
