import CurrentFuelCompanyBalancesTextProvider from "../CurrentFuelCompanyBalancesTextProvider"
import isBlank from "../../../../../admin/lib/isBlank"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultRuCurrentFuelCompanyBalancesTextProvider implements CurrentFuelCompanyBalancesTextProvider {

  reasonIdField(): string {
    return "Номер документа-основания"
  }

  reasonDateField(): string {
    return "Дата документа-основания"
  }

  dateField(): string {
    return "Дата"
  }

  commentField(): string {
    return "Комментарий"
  }

  newObjectTitle(): string {
    return "Списание баланса"
  }

  listTitle(parameters?: {
    readonly fuelCompanyName: string | null | undefined
    readonly fuelCompanyBalance: string | null | undefined
  }): string {
    const fuelCompanyName = parameters?.fuelCompanyName
    const fuelCompanyBalance = parameters?.fuelCompanyBalance
    if (isBlank(fuelCompanyName) || isBlank(fuelCompanyBalance)) return "Баланс топливной компании"

    return `${fuelCompanyName}, баланс ${withRubbleSymbol(fuelCompanyBalance)}`
  }

  writeOffBalance(): string {
    return "Списать с баланса"
  }

  writeOffsSegment(): string {
    return "Списания"
  }

  replenishmentsSegment(): string {
    return "Пополнения"
  }

  idField(): string {
    return "№"
  }

  valueField(): string {
    return "Сумма"
  }

  reasonField(): string {
    return "Основание"
  }

  refuellingReasonValue({
    refuellingOrderCode
  }: {
    readonly refuellingOrderCode: string | null | undefined
  }): string {
    return isPresent(refuellingOrderCode) ? `Заправка №${refuellingOrderCode}` : ""
  }
}
