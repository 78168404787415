import NetworkPhoneNumber from "../entities/phone-number/NetworkPhoneNumber"
import PhoneNumber from "../../domain/phone-number/PhoneNumber"
import CountriesMapper from "./CountriesMapper"

export default class PhoneNumberMapper {
  mapNetworkToDomain({
    phoneNumber
  }: {
    readonly phoneNumber: NetworkPhoneNumber
  }): PhoneNumber {
    const {
      country
    } = phoneNumber

    return {
      value: phoneNumber.value,
      countryId: phoneNumber.countryId,
      country: country && new CountriesMapper().mapNetworkToDomain({ country })
    }
  }

  mapDomainToNetwork({
    phoneNumber
  }: {
    readonly phoneNumber: PhoneNumber
  }): NetworkPhoneNumber {
    return {
      value: phoneNumber.value
    }
  }
}
