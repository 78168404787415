import NotFoundPage from "./NotFoundPage"
import React from "react"
import NotFoundPresenter from "./NotFoundPresenter"
import useLocationContext, { LocationContext } from "../../../../lib/presenter/useLocationContext"
import NotFoundTheme from "../theme/NotFoundTheme"
import { NotFoundThemeProvider } from "../contexts/NotFoundThemeProviderContext"
import { NotFoundTextProviderProvider } from "../contexts/NotFoundTextProviderContext"
import NotFoundI18n from "../../i18n/NotFoundI18n"

interface Props {
  readonly providePresenter: () => NotFoundPresenter
  readonly notFoundTheme: NotFoundTheme
  readonly notFoundI18n: NotFoundI18n
}

export default function NotFoundPageRouteElement({
  notFoundTheme,
  providePresenter,
  notFoundI18n
}: Props) {
  const locationContext: LocationContext = useLocationContext()

  return (
    <NotFoundTextProviderProvider notFoundI18n={notFoundI18n}>
      <NotFoundThemeProvider theme={notFoundTheme}>
        <NotFoundPage
          key={locationContext.locationId}
          providePresenter={() => {
            return locationContext.getOrCreatePresenter(() => {
              return providePresenter()
            })
          }}
          notFoundTextProvider={notFoundI18n.getTextProvider()}
        />
      </NotFoundThemeProvider>
    </NotFoundTextProviderProvider>
  )
}
