import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarrierBalanceChangeDocument
  from "../../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import CarrierBalanceChangeDocumentsRepository from "../../repositories/CarrierBalanceChangeDocumentsRepository"

export interface GetCarrierBalanceChangeDocumentsParameters
  extends GetObjectsParameters {
  readonly carrierId: string | number
}

export default class GetCarrierBalanceChangeDocumentsUseCase
  implements GetObjectsUseCase<CarrierBalanceChangeDocument> {
  private readonly carrierBalanceChangeDocumentsRepository: CarrierBalanceChangeDocumentsRepository

  constructor(parameters: {
    readonly carrierBalanceChangeDocumentsRepository: CarrierBalanceChangeDocumentsRepository
  }) {
    this.carrierBalanceChangeDocumentsRepository = parameters.carrierBalanceChangeDocumentsRepository
  }

  async call(
    parameters: GetCarrierBalanceChangeDocumentsParameters
  ): Promise<GetObjectsResult<CarrierBalanceChangeDocument>> {
    return await this.carrierBalanceChangeDocumentsRepository.getDocuments(parameters)
  }
}
