import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkCarrierReconciliationReport
  from "../../entities/carrier-reconciliation-reports/NetworkCarrierReconciliationReport"
import NetworkCarrierReconciliationReportsResponseBody
  from "../../entities/carrier-reconciliation-reports/response-bodies/NetworkCarrierReconciliationReportsResponseBody"
import NetworkCarrierReconciliationReportResponseBody
  from "../../entities/carrier-reconciliation-reports/response-bodies/NetworkCarrierReconciliationReportResponseBody"
import NetworkCarrierReconciliationReportErrorResponseBody
  from "../../entities/carrier-reconciliation-reports/response-bodies/errors/NetworkCarrierReconciliationReportErrorResponseBody"
import NetworkCarrierReconciliationReportsRequestFilter
  from "../../entities/carrier-reconciliation-reports/request-queries/NetworkCarrierReconciliationReportsRequestFilter"
import NetworkCarrierReconciliationReportsRequestQuery
  from "../../entities/carrier-reconciliation-reports/request-queries/NetworkCarrierReconciliationReportsRequestQuery"
import NetworkCarrierReconciliationReportRequestBody
  from "../../entities/carrier-reconciliation-reports/request-bodies/NetworkCarrierReconciliationReportRequestBody"

const basePath = "/api/admin/carrier_reconciliation_reports"

export default class CarrierReconciliationReportsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getCarrierReconciliationReports({
    pagination,
    sort,
    filter,
    needPagination
  }: {
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null,
    readonly filter?: NetworkCarrierReconciliationReportsRequestFilter | null
    readonly needPagination?: boolean | null
  }): Promise<GetCarrierReconciliationReportsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkCarrierReconciliationReportsRequestQuery({
        pagination,
        sort,
        filter,
        needPagination
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierReconciliationReportsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createCarrierReconciliationReport({
    report
  }: {
    readonly report?: NetworkCarrierReconciliationReport | null
  }): Promise<CreateCarrierReconciliationReportNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkCarrierReconciliationReportRequestBody({ report }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierReconciliationReportResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkCarrierReconciliationReportErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCarrierReconciliationReportsNetworkResult =
  SuccessExecutionResult<NetworkCarrierReconciliationReportsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateCarrierReconciliationReportNetworkResult =
  SuccessExecutionResult<NetworkCarrierReconciliationReportResponseBody> |
  ErrorExecutionResult<NetworkCarrierReconciliationReportErrorResponseBody> |
  FailureExecutionResult
