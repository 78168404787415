import NetworkDriverErrorsObjectAttributes from "./NetworkDriverErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import DriverErrorsObject from "../../../../../domain/drivers/DriverErrorsObject"
import NetworkPhoneNumberErrorsObject from "../../../phone-number/response-bodies/errors/NetworkPhoneNumberErrorsObject"
import NetworkDriverProfileErrorsObject
  from "../../../driver-profile/response-bodies/errors/NetworkDriverProfileErrorsObject"

export default class NetworkDriverErrorsObject implements DriverErrorsObject {
  @Expose()
  @Type(() => NetworkDriverErrorsObjectAttributes)
  readonly attributes?: NetworkDriverErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkPhoneNumberErrorsObject)
  readonly phoneNumber?: NetworkPhoneNumberErrorsObject | null

  @Expose()
  @Type(() => NetworkDriverProfileErrorsObject)
  readonly profile?: NetworkDriverProfileErrorsObject | null
}
