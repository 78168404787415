import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import TripsNetworkSource from "../../../../core/data/sources/network/TripsNetworkSource"
import NetworkTripsRequestFilter from "../../../../core/data/entities/trips/request-queries/NetworkTripsRequestFilter"
import isPresent from "../../../../../admin/lib/isPresent"
import TripsMapper from "../../../../core/data/mappers/TripsMapper"
import TripsForRefuellingOrdersReportsRepository
  from "../../domain/repositories/TripsForRefuellingOrdersReportsRepository"
import {
  GetTripsForRefuellingOrdersReportsParameters
} from "../../domain/use-cases/trips/GetTripsForRefuellingOrdersReportsUseCase"
import Trip from "../../../../core/domain/trips/Trip"

export default class DefaultTripsForRefuellingOrdersReportsRepository
  implements TripsForRefuellingOrdersReportsRepository {
  private readonly tripsNetworkSource: TripsNetworkSource

  constructor(parameters: {
    readonly tripsNetworkSource: TripsNetworkSource
  }) {
    this.tripsNetworkSource = parameters.tripsNetworkSource
  }

  async getTrips({
    ids,
    filter,
    query,
    pagination,
    sort
  }: GetTripsForRefuellingOrdersReportsParameters): Promise<GetObjectsResult<Trip>> {
    const carrierId = filter?.carrierId
    const result = await this.tripsNetworkSource.getTrips({
      filter: new NetworkTripsRequestFilter({
        query,
        ids,
        carrierIds: isPresent(carrierId) ? [carrierId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success": {
        const tripsMapper = new TripsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.trips!.map((trip) => {
              return tripsMapper.mapNetworkToDomain({
                trip,
                tripStatuses: []
              })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
