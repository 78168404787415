import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import FuelCompanyBalanceChangeDocumentErrorsObjectAttributes
  from "../../../../../domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentErrorsObjectAttributes"

export default class NetworkFuelCompanyBalanceChangeDocumentErrorsObjectAttributes
  implements FuelCompanyBalanceChangeDocumentErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly date?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly value?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly note?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly reason?: NetworkAttributeError[] | null
}
