import React, { useEffect, useState } from "react"
import { DecimalFormFieldViewState } from "../../../entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import styles from "./DecimalFormValueComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import LabelComponent from "../../../../../../lib/label/LabelComponent"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"
import { DecimalInputComponent } from "../../../../../../lib/text-input/DecimalInputComponent"

export default function DecimalFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: DecimalFormFieldViewState
  readonly isDisabled: boolean
}) {
  const theme = useCoreThemeProvider()

  const name = fieldViewState.getId()
  const label = fieldViewState.getTitle()
  const isRequired = fieldViewState.getRequired()
  const errors = fieldViewState.getErrors()
  const value = fieldViewState.value
  const stringValue = value?.toFixed().replaceAll(".", ",") ?? ""

  const [inputText, setInputText] = useState(stringValue)

  useEffect(() => {
    if (convertStringToDecimal(inputText)?.toFixed() === stringValue) return
    setInputText(stringValue)
  }, [value])

  function convertStringToDecimal(textValue: string): Decimal | null {
    const formattedTextValue = textValue
      .replaceAll(" ", "")
      .replaceAll(",", ".")

    try {
      return new Decimal(formattedTextValue)
    } catch {
      return null
    }
  }

  function onChange(textValue: string) {
    setInputText(textValue)

    const value = convertStringToDecimal(textValue)
    fieldViewState.onChange(value)
  }

  return (
    <div className={styles.root}>
      {label && name && (
        <LabelComponent
          htmlFor={name}
          text={`${label}${isRequired ? " *" : ""}`}
          className={theme.label1PrimaryClassName()}
        />
      )}
      <DecimalInputComponent
        name={name}
        value={inputText}
        scale={fieldViewState.scale}
        minValue={fieldViewState.minValue}
        maxValue={fieldViewState.maxValue}
        thousandsSeparator={fieldViewState.thousandsSeparator}
        shouldShowTrailingZeroes={fieldViewState.shouldShowTrailingZeroes}
        isDisabled={isDisabled || !fieldViewState.getEditable()}
        onChange={onChange}
        className={styles.inputWrapper}
        componentStyle={theme.input1PrimaryStyle()}
        hasErrors={(errors && errors.length > 0) ?? false}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
