import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import RefuellingOrdersReportErrorsObjectAttributes from "../../../../../domain/refuelling-orders-reports/RefuellingOrdersReportErrorsObjectAttributes"

export default class NetworkRefuellingOrdersReportErrorsObjectAttributes implements RefuellingOrdersReportErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly fromDate?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly toDate?: NetworkAttributeError[] | null
}
