import CarrierCommercialTermsRepository from "../../repositories/CarrierCommercialTermsRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import CarrierCommercialTerm from "../../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import CarrierCommercialTermError from "../../../../../core/domain/carrier-commercial-terms/CarrierCommercialTermError"

export interface CreateCarrierCommercialTermParameters {
  readonly object: CarrierCommercialTerm
}

export default class CreateCarrierCommercialTermUseCase
  implements CreateObjectUseCase<CarrierCommercialTerm, CarrierCommercialTermError> {
  private readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository

  constructor(parameters: {
    readonly carrierCommercialTermsRepository: CarrierCommercialTermsRepository
  }) {
    this.carrierCommercialTermsRepository = parameters.carrierCommercialTermsRepository
  }

  async call(
    parameters: CreateCarrierCommercialTermParameters
  ): Promise<CreateObjectResult<CarrierCommercialTerm, CarrierCommercialTermError>> {
    return await this.carrierCommercialTermsRepository.createCarrierCommercialTerm(parameters)
  }
}
