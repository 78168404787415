import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import CarrierBalanceChangeDocumentsMapper
  from "../../../../core/data/mappers/CarrierBalanceChangeDocumentsMapper"
import CarrierBalanceChangeDocumentsRepository from "../../domain/repositories/CarrierBalanceChangeDocumentsRepository"
import CarrierBalanceChangeDocumentsNetworkSource
  from "../../../../core/data/sources/network/CarrierBalanceChangeDocumentsNetworkSource"
import {
  GetCarrierBalanceChangeDocumentsParameters
} from "../../domain/use-cases/carrier-balance-change-documents/GetCarrierBalanceChangeDocumentsUseCase"
import CarrierBalanceChangeDocument
  from "../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocument"
import {
  CreateCarrierBalanceChangeDocumentParameters
} from "../../domain/use-cases/carrier-balance-change-documents/CreateCarrierBalanceChangeDocumentUseCase"
import CarrierBalanceChangeDocumentError
  from "../../../../core/domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentError"

export default class DefaultCarrierBalanceChangeDocumentsRepository implements CarrierBalanceChangeDocumentsRepository {
  private readonly carrierBalanceChangeDocumentsNetworkSource: CarrierBalanceChangeDocumentsNetworkSource

  constructor(parameters: {
    readonly carrierBalanceChangeDocumentsNetworkSource: CarrierBalanceChangeDocumentsNetworkSource
  }) {
    this.carrierBalanceChangeDocumentsNetworkSource = parameters.carrierBalanceChangeDocumentsNetworkSource
  }

  async getDocuments({
    carrierId,
    pagination,
    sort
  }: GetCarrierBalanceChangeDocumentsParameters): Promise<GetObjectsResult<CarrierBalanceChangeDocument>> {
    const result = await this.carrierBalanceChangeDocumentsNetworkSource
      .getDocuments({
        carrierId,
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
          pagination
        }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success": {
        const carrierBalanceChangeDocumentsMapper = new CarrierBalanceChangeDocumentsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.carrierBalanceReplenishments!.map((carrierBalanceChangeDocument) => {
              return carrierBalanceChangeDocumentsMapper.mapNetworkToDomain({ carrierBalanceChangeDocument })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }

  async createDocument({
    carrierId,
    object: carrierBalanceChangeDocument
  }: CreateCarrierBalanceChangeDocumentParameters): Promise<
    CreateObjectResult<CarrierBalanceChangeDocument, CarrierBalanceChangeDocumentError>
  > {
    const mapper = new CarrierBalanceChangeDocumentsMapper()
    const result = await this.carrierBalanceChangeDocumentsNetworkSource.createDocument({
      carrierId,
      carrierBalanceChangeDocument: mapper.mapDomainToNetwork({
        carrierBalanceChangeDocument
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            carrierBalanceChangeDocument: result.data.carrierBalanceReplenishment!
          })
        }
      default:
        return result
    }
  }
}
