import React from "react"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import ButtonComponent, { ButtonType } from "../../../../../lib/button/ButtonComponent"
import ObjectButtonsContainerComponent from "../object-buttons-container/ObjectButtonsContainerComponent"
import FormPermissionsSet from "../../entities/forms/FormPermissionsSet"

export default function FormMenuComponent({
  isObjectLoaded = true,
  errorMessage,
  isLoading,
  formPermissionsSet,
  isNewObject,
  onDeleteObjectClicked = () => undefined,
  onCancelClicked = () => undefined
}: {
  readonly isObjectLoaded?: boolean,
  readonly formPermissionsSet: FormPermissionsSet
  readonly isLoading: boolean
  readonly isNewObject: boolean
  readonly errorMessage?: string | null
  readonly onDeleteObjectClicked?: () => void
  readonly onCancelClicked?: () => void
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()

  const {
    canCreate,
    canDestroy,
    canUpdate
  } = formPermissionsSet

  const theme = useCoreThemeProvider()
  const canSubmit = (canCreate || canUpdate) && isObjectLoaded
  const buttonTitle = isNewObject ?
    coreTextProvider.createObject() :
    coreTextProvider.updateObject()

  return (
    <ObjectButtonsContainerComponent
      errorMessage={errorMessage}
      leftButtons={(
        <>
          {canSubmit && (
            <ButtonComponent
              type={ButtonType.SUBMIT}
              isDisabled={isLoading}
              title={buttonTitle}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
          )}
          {!isNewObject && (
            <>
              {canDestroy && (
                <ButtonComponent
                  isDisabled={isLoading}
                  title={coreTextProvider.deleteObject()}
                  componentStyles={theme.buttonOutlined1PrimaryStyle()}
                  onClick={onDeleteObjectClicked}
                />
              )}
            </>
          )}
        </>
      )}
      rightButtons={(
        <>
          <ButtonComponent
            isDisabled={isLoading}
            title={coreTextProvider.cancel()}
            componentStyles={theme.buttonFilled1SecondaryStyle()}
            onClick={onCancelClicked}
          />
        </>
      )}
    />
  )
}
