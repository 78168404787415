import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompaniesForFuelCompanyUsersRepository from "../../repositories/FuelCompaniesForFuelCompanyUsersRepository"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"

export interface GetFuelCompaniesForFuelCompanyUsersParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForFuelCompanyUsersUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompaniesForFuelCompanyUsersRepository: FuelCompaniesForFuelCompanyUsersRepository

  constructor(parameters: {
    readonly fuelCompaniesForFuelCompanyUsersRepository: FuelCompaniesForFuelCompanyUsersRepository
  }) {
    this.fuelCompaniesForFuelCompanyUsersRepository = parameters.fuelCompaniesForFuelCompanyUsersRepository
  }

  async call(parameters: GetFuelCompaniesForFuelCompanyUsersParameters): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompaniesForFuelCompanyUsersRepository.getFuelCompanies(parameters)
  }
}
