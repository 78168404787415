import React from "react"
import styles from "./DateInfoRowComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import isBlank from "../../../../../../lib/isBlank"
import DateTimeFormat from "../../../entities/shared/DateTimeFormat"
import DateTimeFormatter from "../../../../../../lib/DateTimeFormatter"

export default function DateInfoRowComponent({
  value: date,
  format,
  timeZone
}: {
  readonly value?: Date | null
  readonly format: DateTimeFormat
  readonly timeZone?: string
}) {
  const theme = useCoreThemeProvider()

  if (isBlank(date)) return <></>

  const formatter = new DateTimeFormatter(date, timeZone)
  const formattedDate = (() => {
    switch (format) {
      case DateTimeFormat.DATE:
        return formatter.formatDate()
      case DateTimeFormat.TIME:
        return formatter.formatHoursMinutes()
      case DateTimeFormat.DATE_TIME:
        return formatter.formatDateTime()
    }
  })()

  return (
    <div className={styles.root}>
      <div className={theme.body2PrimaryClassName()}>
        {formattedDate}
      </div>
    </div>
  )
}
