import { Expose, Type } from "class-transformer"
import GPSLocationErrorsObjectAttributes from "../../../../../domain/gps-locations/GPSLocationErrorsObjectAttributes"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"

export default class NetworkGPSLocationErrorsObjectAttributes implements GPSLocationErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly latitude?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly longitude?: NetworkAttributeError[] | null
}
