import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import FuelCompanyBalanceChangeDocumentsMapper
  from "../../../../core/data/mappers/FuelCompanyBalanceChangeDocumentsMapper"
import CurrentFuelCompanyBalanceChangeDocumentsRepository
  from "../../domain/repositories/CurrentFuelCompanyBalanceChangeDocumentsRepository"
import CurrentFuelCompanyBalanceChangeDocumentsNetworkSource
  from "../../../../core/data/sources/network/CurrentFuelCompanyBalanceChangeDocumentsNetworkSource"
import {
  GetCurrentFuelCompanyBalanceChangeDocumentsParameters
} from "../../domain/use-cases/fuel-company-balance-change-documents/GetCurrentFuelCompanyBalanceChangeDocumentsUseCase"
import FuelCompanyBalanceChangeDocument
  from "../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"

export default class DefaultCurrentFuelCompanyBalanceChangeDocumentsRepository
  implements CurrentFuelCompanyBalanceChangeDocumentsRepository {
  private readonly currentFuelCompanyBalanceChangeDocumentsNetworkSource: CurrentFuelCompanyBalanceChangeDocumentsNetworkSource

  constructor(parameters: {
    readonly currentFuelCompanyBalanceChangeDocumentsNetworkSource: CurrentFuelCompanyBalanceChangeDocumentsNetworkSource
  }) {
    this.currentFuelCompanyBalanceChangeDocumentsNetworkSource = parameters.currentFuelCompanyBalanceChangeDocumentsNetworkSource
  }

  async getDocuments({
    pagination,
    sort
  }: GetCurrentFuelCompanyBalanceChangeDocumentsParameters): Promise<
    GetObjectsResult<FuelCompanyBalanceChangeDocument>
  > {
    const result = await this.currentFuelCompanyBalanceChangeDocumentsNetworkSource
      .getDocuments({
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
          pagination
        }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success": {
        const fuelCompanyBalanceTransactionsMapper = new FuelCompanyBalanceChangeDocumentsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.fuelCompanyBalanceWriteOffs!.map((fuelCompanyBalanceChangeDocument) => {
              return fuelCompanyBalanceTransactionsMapper.mapNetworkToDomain({ fuelCompanyBalanceChangeDocument })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
