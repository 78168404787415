import React from "react"
import { useCoreThemeProvider } from "../../contexts/CoreThemeProviderContext"
import LoadingIndicatorComponent from "../../../../lib/loading-indicator/LoadingIndicatorComponent"

export default function ContentLoadingComponent() {
  const theme = useCoreThemeProvider()

  return (
    <LoadingIndicatorComponent>
      { theme.icLoadingIndicatorComponent() }
    </LoadingIndicatorComponent>
  )
}
