import React from "react"
import styles from "./CheckboxComponent.module.scss"

export default function CheckboxComponent({
  name,
  label,
  additionalLabel,
  isChecked = false,
  isDisabled = false,
  checkboxClassName = "",
  labelClassName = "",
  additionalLabelClassName = "",
  onChange = () => undefined
}: {
  readonly name: string
  readonly label?: string
  readonly additionalLabel?: string
  readonly isChecked?: boolean
  readonly isDisabled?: boolean
  readonly checkboxClassName?: string
  readonly labelClassName?: string
  readonly additionalLabelClassName?: string
  readonly onChange?: (value: boolean) => void
}) {
  return (
    <div
      className={styles.root}
    >
      <input
        id={name}
        name={name}
        className={`${styles.checkbox} ${checkboxClassName}`}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onChange={(event) => {
          onChange(event.target.checked)
        }}
      />
      <label htmlFor={name}>
        <div
          className={styles.labelContainer}
        >
          <div className={`${styles.label} ${labelClassName}`}>{label}</div>
          {additionalLabel && (
            <div className={`${styles.additionalLabel} ${additionalLabelClassName}`}>{additionalLabel}</div>
          )}
        </div>
      </label>
    </div>
  )
}
