import React, { useEffect, useRef, useState } from "react"
import styles from "./TextInputComponent.module.scss"
import isPresent from "../isPresent"
import TextInputComponentStyle from "./TextInputComponentStyles"
import { IMaskInput } from "react-imask"
import isBlank from "../isBlank"
import { countryCodeRegExp, maskDefault, maskWithAdditionalNumber, numberWithoutAdditionalLength } from "../formatPhone"

const defaultPadding = 8

function getMaskForValue({
  isFocused,
  value
}: {
  readonly value: string | null | undefined
  readonly isFocused: boolean
}): string {
  if (isBlank(value)) return maskDefault

  if (value.length > numberWithoutAdditionalLength) return maskWithAdditionalNumber

  if (value.length === numberWithoutAdditionalLength) {
    return isFocused ? maskWithAdditionalNumber : maskDefault
  }

  return maskDefault
}

export function PhoneNumberInputComponent({
  name,
  value = "",
  isDisabled = false,
  hasErrors = false,
  isActive = false,
  placeholder = "",
  className = "",
  componentStyle = {},
  onChange = () => undefined,
  onKeyDown = () => undefined,
  onClick = () => undefined
}: {
  readonly name?: string
  readonly value?: string | null
  readonly isDisabled?: boolean
  readonly isActive?: boolean
  readonly placeholder?: string
  readonly hasErrors?: boolean
  readonly className?: string
  readonly componentStyle?: TextInputComponentStyle
  readonly onChange?: (value: string) => void
  readonly onKeyDown?: (key: React.KeyboardEvent<HTMLInputElement>) => void
  readonly onClick?: () => void
}) {
  const [isFocused, setFocused] = useState(false)
  const [leftIconWidth, setLeftIconWidth] = useState(defaultPadding)
  const [rightIconWidth, setRightIconWidth] = useState(defaultPadding)
  const [currentMask, setCurrentMask] = useState(getMaskForValue({ value, isFocused }))

  const inputRef = useRef<HTMLInputElement | null>(null)

  const leftIconDivRef = useRef<HTMLDivElement>(null)
  const rightIconDivRef = useRef<HTMLDivElement>(null)

  const currentlyActive = isFocused || isActive

  const {
    classes: {
      inputDefault: inputDefaultClassName = "",
      inputActive: inputActiveClassName = "",
      inputErrors: inputErrorsClassName = ""
    } = {},
    icons = {}
  } = componentStyle

  const leftIcon = (() => {
    if (isDisabled) {
      return icons.leftIconDisabled ?? icons.leftIconDefault
    }

    if (currentlyActive) {
      return icons.leftIconActive ?? icons.leftIconDefault
    }

    return icons.leftIconDefault
  })()

  const rightIcon = (() => {
    if (isDisabled) {
      return icons.rightIconDisabled ?? icons.rightIconDefault
    }

    if (currentlyActive) {
      return icons.rightIconActive ?? icons.rightIconDefault
    }

    return icons.rightIconDefault
  })()

  // TODO: Think how to do it without useEffect
  useEffect(() => {
    if (isPresent(leftIconDivRef.current)) {
      setLeftIconWidth(leftIconDivRef.current.clientWidth)
    }

    if (isPresent(rightIconDivRef.current)) {
      setRightIconWidth(rightIconDivRef.current.clientWidth)
    }
  }, [])

  useEffect(() => {
    setCurrentMask(getMaskForValue({
      value,
      isFocused
    }))
  }, [value, isFocused])

  function handlePhoneNumberChanged(value: string) {
    const matchedCountryCode = value.match(countryCodeRegExp)?.[0] ?? ""
    const formattedPhone = value?.replace(matchedCountryCode, "")?.replace(/\D/g, "")
    onChange(formattedPhone)
  }

  return (
    <div className={`${styles.root} ${className}`}>
      {leftIcon && (
        <div
          className={`${styles.icon} ${styles.left}`}
          ref={leftIconDivRef}
        >
          {leftIcon}
        </div>
      )}
      <IMaskInput
        lazy={false}
        mask={currentMask}
        id={name}
        ref={inputRef}
        className={`${styles.input} ${inputDefaultClassName} ${hasErrors ? inputErrorsClassName : ""} ${currentlyActive ? inputActiveClassName : ""}`}
        type="tel"
        value={value ?? ""}
        placeholder={placeholder}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onAccept={(value) => handlePhoneNumberChanged(value)}
        disabled={isDisabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{
          paddingLeft: leftIconWidth,
          paddingRight: rightIconWidth
        }}
      />
      {rightIcon && (
        <div
          className={`${styles.icon} ${styles.right}`}
          ref={rightIconDivRef}
        >
          {rightIcon}
        </div>
      )}
    </div>
  )
}
