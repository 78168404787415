import React, { createContext, useContext } from "react"
import CoreTheme from "../theme/CoreTheme"

export const CoreThemeProviderContext: React.Context<CoreTheme | null> =
  createContext<CoreTheme | null>(null)

export function useCoreThemeProvider(): CoreTheme {
  return useContext(CoreThemeProviderContext)!
}

export interface CoreThemeProviderProps {
  readonly children?: React.ReactNode
  readonly theme: CoreTheme
}

export function CoreThemeProvider({
  children,
  theme
}: CoreThemeProviderProps) {
  return (
    <CoreThemeProviderContext.Provider value={theme}>
      {children}
    </CoreThemeProviderContext.Provider>
  )
}
