import GasStationsRepository from "../../domain/repositories/GasStationsRepository"
import GasStationsNetworkSource from "../../../../core/data/sources/network/GasStationsNetworkSource"
import { GetGasStationsParameters } from "../../domain/use-cases/gas-stations/GetGasStationsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import NetworkGasStationsRequestFilter
  from "../../../../core/data/entities/gas-stations/request-queries/NetworkGasStationsRequestFilter"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateGasStationParameters } from "../../domain/use-cases/gas-stations/CreateGasStationUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetGasStationParameters } from "../../domain/use-cases/gas-stations/GetGasStationUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateGasStationParameters } from "../../domain/use-cases/gas-stations/UpdateGasStationUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import GasStationsMapper from "../../../../core/data/mappers/GasStationsMapper"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import GasStationError from "../../../../core/domain/gas-stations/GasStationError"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultGasStationsRepository implements GasStationsRepository {
  private readonly gasStationsNetworkSource: GasStationsNetworkSource

  constructor(parameters: {
    readonly gasStationsNetworkSource: GasStationsNetworkSource
  }) {
    this.gasStationsNetworkSource = parameters.gasStationsNetworkSource
  }

  async getGasStations({
    filter,
    query,
    pagination,
    sort
  }: GetGasStationsParameters): Promise<GetObjectsResult<GasStation>> {
    const fuelCompanyId = filter?.fuelCompanyId
    const result = await this.gasStationsNetworkSource.getGasStations({
      filter: new NetworkGasStationsRequestFilter({
        query,
        fuelCompanyIds: isPresent(fuelCompanyId) ? [fuelCompanyId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.gasStations!.map((gasStation) => {
              return new GasStationsMapper().mapNetworkToDomain({ gasStation })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getGasStation({
    objectId
  }: GetGasStationParameters): Promise<GetObjectResult<GasStation>> {
    const result = await this.gasStationsNetworkSource.getGasStation({
      gasStationId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new GasStationsMapper().mapNetworkToDomain({
            gasStation: result.data.gasStation!
          })
        }
      default:
        return result
    }
  }

  async createGasStation({
    object: gasStation
  }: CreateGasStationParameters): Promise<CreateObjectResult<GasStation, GasStationError>> {
    const mapper = new GasStationsMapper()
    const result = await this.gasStationsNetworkSource.createGasStation({
      gasStation: mapper.mapDomainToNetwork({ gasStation })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            gasStation: result.data.gasStation!
          })
        }
      default:
        return result
    }
  }

  async updateGasStation({
    objectId: gasStationId,
    object: gasStation
  }: UpdateGasStationParameters): Promise<UpdateObjectResult<GasStation, GasStationError>> {
    const mapper = new GasStationsMapper()
    const result = await this.gasStationsNetworkSource.updateGasStation({
      gasStationId: parseInt(gasStationId),
      gasStation: mapper.mapDomainToNetwork({
        gasStation
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            gasStation: result.data.gasStation!
          })
        }
      default:
        return result
    }
  }
}
