import TransportFuel from "../../domain/transport-fuel/TransportFuel"
import NetworkTransportFuel from "../entities/transport-fuel/NetworkTransportFuel"
import FuelsMapper from "./FuelsMapper"
import { v4 as uuidv4 } from "uuid"
import isPresent from "../../../../admin/lib/isPresent"

export default class TransportFuelMapper {
  mapNetworkToDomain({
    transportFuel
  }: {
    readonly transportFuel: NetworkTransportFuel
  }): TransportFuel {
    const { fuel } = transportFuel
    return {
      id: transportFuel.id,
      clientId: isPresent(transportFuel.id) ? `${transportFuel.id}` : uuidv4(),
      fuelId: transportFuel.fuelId,
      tankVolume: transportFuel.tankVolume,
      fuel: fuel && new FuelsMapper().mapNetworkToDomain({ fuel })
    }
  }

  mapDomainToNetwork({
    transportFuel
  }: {
    readonly transportFuel: TransportFuel
  }): NetworkTransportFuel {
    const { fuel } = transportFuel
    return {
      id: transportFuel.id,
      clientId: transportFuel.clientId,
      fuelId: transportFuel.fuelId,
      tankVolume: transportFuel.tankVolume,
      fuel: fuel && new FuelsMapper().mapDomainToNetwork({ fuel })
    }
  }
}
