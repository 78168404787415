import isBlank from "./isBlank"
import { createMask } from "imask"

export const countryCode = "+7"
export const maskDefault = `${countryCode} (000) 000-00-00`
export const maskWithAdditionalNumber = `${countryCode} (000) 000-00-00[, доб. 0000000000]`
export const countryCodeForRegExp = countryCode.replace("+", "\\+")
export const countryCodeRegExp = new RegExp(`^${countryCodeForRegExp}`)

export const numberWithoutAdditionalLength = 10

export default function formatPhoneNumber(value: string | null | undefined): string | undefined | null {
  if (isBlank(value)) return value

  const mask = createMask({
    mask: value.length > numberWithoutAdditionalLength ?
      maskWithAdditionalNumber :
      maskDefault
  })

  mask.resolve(value)
  return mask.value
}
