import CurrentCarrierBalanceTransactionsRepository from "../../repositories/CurrentCarrierBalanceTransactionsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import CarrierBalanceTransaction
  from "../../../../../core/domain/carrier-balance-transactions/CarrierBalanceTransaction"

export interface GetCurrentCarrierBalanceTransactionsParameters extends GetObjectsParameters {
}

export default class GetCurrentCarrierBalanceTransactionsUseCase
  implements GetObjectsUseCase<CarrierBalanceTransaction> {
  private readonly carrierBalanceTransactionsRepository: CurrentCarrierBalanceTransactionsRepository

  constructor(parameters: {
    readonly carrierBalanceTransactionsRepository: CurrentCarrierBalanceTransactionsRepository
  }) {
    this.carrierBalanceTransactionsRepository = parameters.carrierBalanceTransactionsRepository
  }

  async call(
    parameters: GetCurrentCarrierBalanceTransactionsParameters
  ): Promise<GetObjectsResult<CarrierBalanceTransaction>> {
    return await this.carrierBalanceTransactionsRepository.getTransactions(parameters)
  }
}
