import { Expose, Transform, Type } from "class-transformer"
import NetworkCarrier from "../carriers/NetworkCarrier"
import NetworkFuelCompany from "../fuel-companies/NetworkFuelCompany"
import NetworkFuel from "../fuels/NetworkFuel"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"
import NetworkUser from "../users/NetworkUser"
import NetworkTrip from "../trips/NetworkTrip"

export default class NetworkRefuellingOrdersReport {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Date)
  readonly createdAt?: Date | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly fromDate?: Date | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly toDate?: Date | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyIds?: number[] | null

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompanies?: NetworkFuelCompany[] | null

  @Expose()
  @Type(() => Number)
  readonly carrierIds?: number[] | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carriers?: NetworkCarrier[] | null

  @Expose()
  @Type(() => Number)
  readonly gasStationIds?: number[] | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStations?: NetworkGasStation[] | null

  @Expose()
  @Type(() => Number)
  readonly tripIds?: number[] | null

  @Expose()
  @Type(() => NetworkTrip)
  readonly trips?: NetworkTrip[] | null

  @Expose()
  @Type(() => Number)
  readonly driverIds?: number[] | null

  @Expose()
  @Type(() => NetworkUser)
  readonly drivers?: NetworkUser[] | null

  @Expose()
  @Type(() => Number)
  readonly fuelIds?: number[] | null

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuels?: NetworkFuel[] | null

  @Expose()
  @Type(() => String)
  readonly refuellingOrderStatuses?: string[] | null

  @Expose()
  @Type(() => String)
  readonly statusName?: string | null

  @Expose()
  @Type(() => String)
  readonly documentUrl?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly needRefresh?: boolean | null
}
