import TableProvider from "./TableProvider"
import CheckPermissionDeniedUseCase from "../../../../core/domain/use-cases/user-profile/CheckPermissionDeniedUseCase"
import { Entity } from "../../../../core/domain/entities/user-profile/Entity"
import isBlank from "../../../../lib/isBlank"
import { RestrictionType } from "../../../../core/domain/entities/user-profile/RestrictionType"
import FormField from "../entities/forms/FormField"
import Table from "../entities/tables/Table"

export default class TableProviderUtils<DomainObject, FilterObject = never> {
  private readonly tableProvider: TableProvider<DomainObject, FilterObject>
  private readonly table: Table<DomainObject, FilterObject>
  private readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase

  constructor(parameters: {
    readonly tableProvider: TableProvider<DomainObject, FilterObject>,
    readonly table: Table<DomainObject, FilterObject>,
    readonly checkPermissionDeniedUseCase: CheckPermissionDeniedUseCase
  }) {
    this.tableProvider = parameters.tableProvider
    this.table = parameters.table
    this.checkPermissionDeniedUseCase = parameters.checkPermissionDeniedUseCase
  }

  isTableVisibleByPermission() {
    return !this.isPermissionDenied({ restrictionType: RestrictionType.DENY_ENTITIES_LIST_DISPLAY })
  }

  initTableByPermissions() {
    this.initTableColumns()
    this.initFilterFields()
    this.setCanCreateNewObject()
    this.setCanShowObject()
  }

  private initTableColumns() {
    const columns = this.table.getColumns()
    columns.forEach((column) => {
      column.setUrlVisibilityByPermission(this.getRelatedEntityVisibility(column.getEntity()))
      column.setVisibilityByPermission(this.getColumnVisibility(column.getName()))
    })
  }

  private initFilterFields() {
    this.table
      .getFilter()
      ?.getFields()
      ?.forEach((filterField: FormField<FilterObject, never>) => {
        filterField.setVisibilityByPermission(this.getFilterFieldVisibility(filterField.getEntity()))
      })
  }

  private setCanCreateNewObject() {
    const canCreateNewObject = !this.isPermissionDenied({
      restrictionType: RestrictionType.DENY_ENTITY_CREATE
    })

    this.table.setCanCreateNewObject(canCreateNewObject)
  }

  private setCanShowObject() {
    const canShowObject = !this.isPermissionDenied({
      restrictionType: RestrictionType.DENY_ENTITY_DISPLAY
    })

    this.table.setCanShowObject(canShowObject)
  }

  private getRelatedEntityVisibility(entity: Entity | undefined) {
    if (isBlank(entity)) return true

    return !this.isPermissionDenied({
      entity: entity,
      restrictionType: RestrictionType.DENY_ENTITY_DISPLAY
    })
  }

  private getFilterFieldVisibility(entity: Entity | undefined) {
    if (isBlank(entity)) return true

    return !this.isPermissionDenied({
      entity: entity,
      restrictionType: RestrictionType.DENY_ENTITIES_LIST_DISPLAY
    })
  }

  private getColumnVisibility(columnName: string) {
    return !this.isPermissionDenied({
      entityField: columnName,
      restrictionType: RestrictionType.DENY_ENTITY_FIELD_DISPLAY_IN_TABLES
    })
  }

  private isPermissionDenied(parameters: { entity?: string, entityField?: string, restrictionType: RestrictionType }) {
    return this.checkPermissionDeniedUseCase.call({
      entity: parameters.entity ?? this.tableProvider.getEntity(),
      entityField: parameters.entityField,
      restrictionType: parameters.restrictionType
    })
  }
}
