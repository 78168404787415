import { Expose, Type } from "class-transformer"
import NetworkFuel from "../fuels/NetworkFuel"

export default class NetworkTransportFuel {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly clientId?: string | null

  @Expose()
  @Type(() => Number)
  readonly fuelId?: number | null

  @Expose()
  @Type(() => Number)
  readonly tankVolume?: number | null

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuel?: NetworkFuel | null
}
