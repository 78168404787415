import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import RefuellingOrdersI18n from "../../i18n/RefuellingOrdersI18n"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import DateInfoRow from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/DateInfoRow"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import AppI18 from "../../../../core/i18n/AppI18"
import isBlank from "../../../../../admin/lib/isBlank"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default abstract class BaseRefuellingOrderInfoProvider implements InfoProvider<RefuellingOrder> {
  private readonly appI18n: AppI18
  private readonly refuellingOrdersI18n: RefuellingOrdersI18n
  private readonly timeZone: string

  constructor(parameters: {
    readonly timeZone: string
    readonly refuellingOrdersI18n: RefuellingOrdersI18n
    readonly appI18n: AppI18
  }) {
    this.timeZone = parameters.timeZone
    this.refuellingOrdersI18n = parameters.refuellingOrdersI18n
    this.appI18n = parameters.appI18n
  }

  abstract getEditUrl({ object }: { readonly object: RefuellingOrder }): string

  getEntity(): string {
    return Entity.REFUELLING_ORDERS
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: RefuellingOrder
  }): string {
    return this.refuellingOrdersI18n.getTextProvider().existObjectTitle({ refuellingOrder: object })
  }

  getObjectTitle({
    object
  }: {
    readonly object?: RefuellingOrder
  }): string {
    return this.refuellingOrdersI18n.getTextProvider()
      .existObjectTitle({ refuellingOrder: object })
  }

  getInfoRows({ object }: {
    readonly object: RefuellingOrder
  }): InfoRow[] {
    const appUrlProvider = new AppUrlProvider()
    const refuellingOrdersTextProvider = this.refuellingOrdersI18n.getTextProvider()
    const appTextProvider = this.appI18n.getTextProvider()

    const {
      carrier,
      fuelCompany,
      fuel,
      gasStation,
      userTrip
    } = object

    const fuelCompanyCommercialTermId = object.currentTransaction?.fuelCompanyCommercialTermId
    const carrierCommercialTermId = object.currentTransaction?.carrierCommercialTermId
    return [
      new DateInfoRow({
        name: "beginDate",
        title: refuellingOrdersTextProvider.beginDateField(),
        value: object.createdAt,
        format: DateTimeFormat.DATE_TIME,
        timeZone: this.timeZone
      }),
      new StringInfoRow({
        name: "carrier",
        entity: Entity.CARRIERS,
        title: refuellingOrdersTextProvider.carrierField(),
        value: carrier?.name,
        url: carrier && appUrlProvider.buildShowCarrierUrl({
          carrierId: carrier.id!
        })
      }),
      new StringInfoRow({
        name: "fuelCompany",
        entity: Entity.FUEL_COMPANIES,
        title: refuellingOrdersTextProvider.fuelCompanyField(),
        value: fuelCompany?.name,
        url: fuelCompany && appUrlProvider.buildShowFuelCompanyUrl({
          fuelCompanyId: fuelCompany.id!
        })
      }),
      new StringInfoRow({
        name: "gasStation",
        entity: Entity.GAS_STATIONS,
        title: refuellingOrdersTextProvider.gasStationField(),
        value: gasStation?.name,
        url: gasStation && appUrlProvider.buildShowGasStationUrl({
          gasStationId: gasStation.id!
        })
      }),
      new StringInfoRow({
        name: "trip",
        entity: Entity.TRIPS,
        title: refuellingOrdersTextProvider.tripField(),
        value: userTrip?.trip?.name,
        url: userTrip?.trip && appUrlProvider.buildShowTripUrl({
          tripId: userTrip!.trip.id!
        })
      }),
      new StringInfoRow({
        name: "transport",
        entity: Entity.TRANSPORTS,
        title: refuellingOrdersTextProvider.transportField(),
        value: userTrip?.trip?.transport?.name,
        additionalValue: userTrip?.trip?.transport?.stateNumber,
        url: (() => {
          if (isBlank(userTrip?.trip?.transport)) return null

          return appUrlProvider.buildShowTransportUrl({
            transportId: userTrip!.trip!.transport.id!
          })
        })()
      }),
      new StringInfoRow({
        name: "driver",
        entity: Entity.DRIVERS,
        title: refuellingOrdersTextProvider.driverField(),
        value: appTextProvider.driverName({
          user: userTrip?.user
        }),
        url: (() => {
          if (isBlank(userTrip?.user)) return null

          return appUrlProvider.buildShowDriverUrl({
            driverId: userTrip!.user.id!
          })
        })()
      }),
      new StringInfoRow({
        name: "fuel",
        title: refuellingOrdersTextProvider.fuelField(),
        value: fuel?.name
      }),
      new StringInfoRow({
        name: "price",
        title: refuellingOrdersTextProvider.priceField(),
        value: object.formattedFuelPrice && withRubbleSymbol(object.formattedFuelPrice)
      }),
      new StringInfoRow({
        name: "fuelValue",
        title: refuellingOrdersTextProvider.fillingVolumeField(),
        value: (() => {
          const formattedFuelValue = object.currentTransaction?.formattedFuelValue
          if (isBlank(formattedFuelValue)) return null

          return `${formattedFuelValue} ${object.fuel?.shortFormattedUom}`
        })()
      }),
      new StringInfoRow({
        name: "moneyValue",
        title: refuellingOrdersTextProvider.moneyValueField(),
        value: (() => {
          const { currentTransaction } = object
          const value = currentTransaction?.formattedMoneyValue
          if (isBlank(value)) return null

          return value && withRubbleSymbol(value)
        })()
      }),
      new StringInfoRow({
        name: "costValue",
        title: refuellingOrdersTextProvider.costValueField(),
        value: (() => {
          const { currentTransaction } = object
          const value = currentTransaction?.formattedCostValue
          if (isBlank(value)) return null

          return value && withRubbleSymbol(value)
        })()
      }),
      new StringInfoRow({
        name: "status",
        title: refuellingOrdersTextProvider.refuellingStatusField(),
        value: object.statusSelectOption?.text
      }),
      new StringInfoRow({
        name: "fuelCompanyCommercialTerms",
        entity: Entity.FUEL_COMPANY_COMMERCIAL_TERMS,
        title: refuellingOrdersTextProvider.fuelCompanyCommercialTermsField(),
        value: (() => {
          return isBlank(fuelCompanyCommercialTermId) ? null : refuellingOrdersTextProvider.move()
        })(),
        url: (() => {
          if (isBlank(fuelCompanyCommercialTermId)) return null
          return appUrlProvider.buildShowFuelCompanyCommercialTermUrl({ fuelCompanyCommercialTermId })
        })()
      }),
      new StringInfoRow({
        name: "carrierCommercialTerms",
        entity: Entity.CARRIER_COMMERCIAL_TERMS,
        title: refuellingOrdersTextProvider.carrierCommercialTermsField(),
        value: (() => {
          return isBlank(carrierCommercialTermId) ? null : refuellingOrdersTextProvider.move()
        })(),
        url: (() => {
          if (isBlank(carrierCommercialTermId)) return null
          return appUrlProvider.buildShowCarrierCommercialTermUrl({ carrierCommercialTermId })
        })()
      })
    ]
  }

  getLinks(): LinkInfo[] {
    return []
  }
}
