import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import Fuel from "../../../../../core/domain/fuels/Fuel"
import FuelsForTransportsRepository from "../../repositories/FuelsForTransportsRepository"

export interface GetFuelsForTransportsParameters extends GetObjectsParameters {
}

export default class GetFuelsForTransportsUseCase implements GetObjectsUseCase<Fuel> {
  private readonly fuelsForTransportsRepository: FuelsForTransportsRepository

  constructor(parameters: {
    readonly fuelsForTransportsRepository: FuelsForTransportsRepository
  }) {
    this.fuelsForTransportsRepository = parameters.fuelsForTransportsRepository
  }

  async call(parameters: GetFuelsForTransportsParameters): Promise<GetObjectsResult<Fuel>> {
    return await this.fuelsForTransportsRepository.getFuels(parameters)
  }
}
