import { Expose, Type } from "class-transformer"

export default class NetworkFuelPricesRequestFilter {
  @Expose()
  @Type(() => String)
  readonly query?: string | null

  @Expose()
  @Type(() => Number)
  readonly gasStationIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly fuelIds?: number[] | null

  constructor(parameters: {
    readonly query?: string | null
    readonly gasStationIds?: number[] | null
    readonly fuelIds?: number[] | null
  }) {
    this.query = parameters.query
    this.gasStationIds = parameters.gasStationIds
    this.fuelIds = parameters.fuelIds
  }
}
