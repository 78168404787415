import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import GasStationsDomainDiModule from "./GasStationsDomainDiModule"
import GasStationsTableProvider from "../../presentation/table-providers/GasStationsTableProvider"
import GasStationFormProvider from "../../presentation/form-providers/GasStationFormProvider"
import GasStationInfoProvider from "../../presentation/info-providers/GasStationInfoProvider"
import GasStationsI18nDiModule from "./GasStationsI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"

const moduleKey = "gasStations"

export default interface GasStationsPresentationDiModule {
  provideGasStationsListPage(): React.ReactNode

  provideGasStationInfoPage(): React.ReactNode

  provideGasStationFormPage(): React.ReactNode
}

export class DefaultGasStationsPresentationDiModule
  extends DiModule
  implements GasStationsPresentationDiModule {

  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly gasStationsI18nDiModule: GasStationsI18nDiModule
  private readonly gasStationsDomainDiModule: GasStationsDomainDiModule

  constructor(parameters: {
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly gasStationsI18nDiModule: GasStationsI18nDiModule
    readonly gasStationsDomainDiModule: GasStationsDomainDiModule
  }) {
    super()
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.gasStationsI18nDiModule = parameters.gasStationsI18nDiModule
    this.gasStationsDomainDiModule = parameters.gasStationsDomainDiModule
  }

  provideGasStationsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.gasStationsDomainDiModule.provideGetGasStationsUseCase(),
      tableProvider: this.provideGasStationsTableProvider()
    })
  }

  private provideGasStationsTableProvider(): GasStationsTableProvider {
    return this.single(
      GasStationsTableProvider.name,
      () => new GasStationsTableProvider({
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        gasStationsI18: this.gasStationsI18nDiModule.provideGasStationsI18n(),
        getFuelCompaniesUseCase: this.gasStationsDomainDiModule.provideGetFuelStationsForGasStationUseCase()
      })
    )
  }

  provideGasStationFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.gasStationsDomainDiModule.provideGetGasStationUseCase(),
      createObjectUseCase: this.gasStationsDomainDiModule.provideCreateGasStationUseCase(),
      updateObjectUseCase: this.gasStationsDomainDiModule.provideUpdateGasStationUseCase(),
      formProvider: this.provideGasStationFormProvider()
    })
  }

  private provideGasStationFormProvider(): GasStationFormProvider {
    return this.single(
      GasStationFormProvider.name,
      () => new GasStationFormProvider({
        gasStationsI18: this.gasStationsI18nDiModule.provideGasStationsI18n(),
        getFuelCompaniesUseCase: this.gasStationsDomainDiModule.provideGetFuelStationsForGasStationUseCase()
      })
    )
  }

  provideGasStationInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.gasStationsDomainDiModule.provideGetGasStationUseCase(),
      infoProvider: this.provideGasStationInfoProvider()
    })
  }

  private provideGasStationInfoProvider(): GasStationInfoProvider {
    return this.single(
      GasStationInfoProvider.name,
      () => new GasStationInfoProvider({
        gasStationsI18: this.gasStationsI18nDiModule.provideGasStationsI18n()
      })
    )
  }
}
