import styles from "./InfoInputComponent.module.scss"
import { useCoreThemeProvider } from "../../core/presentation/contexts/CoreThemeProviderContext"
import React from "react"
import isPresent from "../isPresent"

export function InfoInputComponent({
  title,
  text
}: {
  readonly title?: string
  readonly text?: string | null
}) {
  const theme = useCoreThemeProvider()

  return (
    <div className={`${styles.root} ${theme.info1DefaultClassName()}`}>
      {isPresent(title) && <div className={`${styles.title} ${theme.headline7PrimaryClassName()}`}>
        {title}
      </div>}
      {isPresent(text) && <div className={`${styles.text} ${theme.body6SecondaryClassName()}`}>
        {text}
      </div>}
    </div>
  )
}
