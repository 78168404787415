import TransportsI18 from "../../i18n/TransportsI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import TransportsTextProvider from "../../i18n/TransportsTextProvider"

export default interface TransportsI18nDiModule {
  provideTransportsI18n(): TransportsI18
}

export class DefaultTransportsI18nDiModule
  extends I18nDiModule<TransportsTextProvider>
  implements TransportsI18nDiModule {

  provideTransportsI18n(): TransportsI18 {
    return this.single(
      TransportsI18.name,
      () => new TransportsI18(this.getI18nParameters())
    )
  }
}
