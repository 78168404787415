import { Expose, Transform, Type } from "class-transformer"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"

export default class NetworkUserProfile {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly firstName?: string | null

  @Expose()
  @Type(() => String)
  readonly middleName?: string | null

  @Expose()
  @Type(() => String)
  readonly lastName?: string | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly birthdate?: Date | null

  @Expose()
  @Type(() => String)
  readonly driverLicenseNumber?: string | null

  @Expose()
  @Type(() => String)
  readonly adminLabel?: string | null
}
