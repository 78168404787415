import FuelPricesRepository from "../../repositories/FuelPricesRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import FuelPrice from "../../../../../core/domain/fuel-prices/FuelPrice"
import FuelPriceError from "../../../../../core/domain/fuel-prices/FuelPriceError"

export interface CreateFuelPriceParameters {
  readonly object: FuelPrice
}

export default class CreateFuelPriceUseCase implements CreateObjectUseCase<FuelPrice, FuelPriceError> {
  private readonly fuelPricesRepository: FuelPricesRepository

  constructor(parameters: {
    readonly fuelPricesRepository: FuelPricesRepository
  }) {
    this.fuelPricesRepository = parameters.fuelPricesRepository
  }

  async call(parameters: CreateFuelPriceParameters): Promise<CreateObjectResult<FuelPrice, FuelPriceError>> {
    return await this.fuelPricesRepository.createFuelPrice(parameters)
  }
}
