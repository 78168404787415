import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import CarrierBalanceChangeDocumentErrorsObjectAttributes
  from "../../../../../domain/carrier-balance-change-documents/CarrierBalanceChangeDocumentErrorsObjectAttributes"

export default class NetworkCarrierBalanceChangeDocumentErrorsObjectAttributes
  implements CarrierBalanceChangeDocumentErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly date?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly value?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly note?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly reason?: NetworkAttributeError[] | null
}
