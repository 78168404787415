import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import RefuellingOrdersReportsDomainDiModule, {
  DefaultRefuellingOrdersReportsDomainDiModule
} from "./RefuellingOrdersReportsDomainDiModule"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import RefuellingOrdersReportsI18nDiModule, {
  DefaultRefuellingOrdersReportsI18nDiModule
} from "./RefuellingOrdersReportsI18nDiModule"
import RefuellingOrdersReportsTableProvider
  from "../../presentation/table-providers/RefuellingOrdersReportsTableProvider"
import RefuellingOrdersReportsFormProvider from "../../presentation/form-providers/RefuellingOrdersReportsFormProvider"

const moduleKey = "refuellingOrdersReports"

export default interface RefuellingOrdersReportsPresentationDiModule {
  provideRefuellingOrdersReportsListPage(): React.ReactNode

  provideRefuellingOrdersReportFormPage(): React.ReactNode
}

export class DefaultRefuellingOrdersReportsPresentationDiModule
  extends DiModule
  implements RefuellingOrdersReportsPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly refuellingOrdersReportsI18nDiModule: RefuellingOrdersReportsI18nDiModule
  private readonly refuellingOrdersReportsDomainDiModule: RefuellingOrdersReportsDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly refuellingOrdersReportsDomainDiModule: DefaultRefuellingOrdersReportsDomainDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly refuellingOrdersReportsI18nDiModule: DefaultRefuellingOrdersReportsI18nDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.appI18nDiModule = parameters.appI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.refuellingOrdersReportsI18nDiModule = parameters.refuellingOrdersReportsI18nDiModule
    this.refuellingOrdersReportsDomainDiModule = parameters.refuellingOrdersReportsDomainDiModule
  }

  provideRefuellingOrdersReportsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetRefuellingOrdersReportsUseCase(),
      tableProvider: this.provideRefuellingOrdersReportsTableProvider()
    })
  }

  private provideRefuellingOrdersReportsTableProvider(): RefuellingOrdersReportsTableProvider {
    return this.single(
      RefuellingOrdersReportsTableProvider.name,
      () => new RefuellingOrdersReportsTableProvider({
        timeZone: this.timeZone,
        appI18n: this.appI18nDiModule.provideAppI18n(),
        refuellingOrdersReportsI18n: this.refuellingOrdersReportsI18nDiModule.provideRefuellingOrdersReportsI18n()
      })
    )
  }

  provideRefuellingOrdersReportFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      createObjectUseCase: this.refuellingOrdersReportsDomainDiModule.provideCreateRefuellingOrderReportUseCase(),
      formProvider: this.provideRefuellingOrderReportsFormProvider()
    })
  }

  private provideRefuellingOrderReportsFormProvider(): RefuellingOrdersReportsFormProvider {
    return this.single(
      RefuellingOrdersReportsFormProvider.name,
      () => new RefuellingOrdersReportsFormProvider({
        appI18: this.appI18nDiModule.provideAppI18n(),
        refuellingOrdersReportsI18: this.refuellingOrdersReportsI18nDiModule.provideRefuellingOrdersReportsI18n(),
        getCarriersUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetCarriersForRefuellingOrdersReportsUseCase(),
        getDriversUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetDriversForRefuellingOrdersReportsUseCase(),
        getFuelCompaniesUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetFuelCompaniesForRefuellingOrdersReportsUseCase(),
        getFuelsUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetFuelsForRefuellingOrdersReportsUseCase(),
        getGasStationsUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetGasStationsForRefuellingOrdersReportsUseCase(),
        getRefuellingOrderStatusesUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetRefuellingOrderStatusesForRefuellingOrdersReportsUseCase(),
        getTripsForRefuellingOrdersReportsUseCase: this.refuellingOrdersReportsDomainDiModule.provideGetTripsForRefuellingOrdersReportsUseCase()
      })
    )
  }
}
