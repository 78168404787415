import AttributeError from "../../../../../core/domain/entities/errors/AttributeError"
import { Entity } from "../../../../../core/domain/entities/user-profile/Entity"

export interface FormFieldParameters<DomainObject, ErrorsObject> {
  readonly title?: string
  readonly entity?: Entity
  readonly required?: boolean
  readonly clearable?: boolean
  readonly groupName: string
  readonly placeholder?: string
  readonly getId: (object: DomainObject) => string
  readonly getTitle?: (object: DomainObject) => string
  readonly getPlaceholder?: (object: DomainObject) => string
  readonly getIsVisible?: (object: DomainObject) => boolean
  readonly getIsEditable?: (object: DomainObject) => boolean
  readonly getErrors?: (errorsObject?: ErrorsObject) => AttributeError[] | null | undefined
}

// TODO: make groupName optional

export default abstract class FormField<DomainObject, ErrorsObject> {
  private readonly title?: string
  private readonly entity?: Entity
  private readonly required: boolean
  private readonly clearable: boolean
  private readonly groupName: string
  private readonly placeholder?: string
  private visibilityByPermission?: boolean
  private disableByPermission?: boolean
  protected setObject!: (object: DomainObject) => void
  protected setAndShowLoadedObjectViewState!: () => void
  protected cacheObject?: (object: DomainObject) => void
  getId: (object: DomainObject) => string
  getTitle?: (object: DomainObject) => string
  getPlaceholder?: (object: DomainObject) => string
  protected getIsVisible: (object: DomainObject) => boolean
  protected getIsEditable: (object: DomainObject) => boolean
  protected getErrors?: (errorsObject?: ErrorsObject) => AttributeError[] | null | undefined

  protected constructor(parameters: FormFieldParameters<DomainObject, ErrorsObject>) {
    this.title = parameters.title
    this.entity = parameters.entity
    this.groupName = parameters.groupName
    this.required = parameters.required ?? false
    this.clearable = parameters.clearable ?? true
    this.placeholder = parameters.placeholder
    this.getId = parameters.getId
    this.getTitle = parameters.getTitle
    this.getPlaceholder = parameters.getPlaceholder
    this.getErrors = parameters.getErrors
    this.getIsVisible = parameters.getIsVisible ?? (() => true)
    this.getIsEditable = parameters.getIsEditable ?? (() => true)
  }

  abstract getViewState(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewState

  abstract serializeValue(object: DomainObject): string | null | undefined

  abstract init(parameters: {
    readonly object: DomainObject
    readonly serializedValue: string | null | undefined
  }): Promise<void>

  getEntity() {
    return this.entity
  }

  setSetObject(setObject: (object: DomainObject) => void) {
    this.setObject = setObject
  }

  setSetAndShowLoadedFuelCompanyBalanceChangeDocumentViewState(setAndShowLoadedObjectViewState: () => void) {
    this.setAndShowLoadedObjectViewState = setAndShowLoadedObjectViewState
  }

  setCacheObject(cacheObject: (object: DomainObject) => void) {
    this.cacheObject = cacheObject
  }

  setVisibilityByPermission(isVisible: boolean) {
    this.visibilityByPermission = isVisible
  }

  setDisableByPermission(isDisable: boolean) {
    this.disableByPermission = isDisable
  }

  getFormFieldViewStateParameters(object: DomainObject, errorsObject?: ErrorsObject): FormFieldViewStateParameters {
    const errors = this.getErrors ? this.getErrors(errorsObject) : []
    return {
      title: this.getTitle ? this.getTitle(object) : this.title ?? "",
      groupName: this.groupName,
      id: this.getId(object),
      required: this.required,
      clearable: this.clearable,
      placeholder: this.getPlaceholder ? this.getPlaceholder(object) : this.placeholder,
      visible: this.visibilityByPermission === false ? false : this.getIsVisible(object),
      editable: this.disableByPermission === true ? false : this.getIsEditable(object),
      errors: errors
    }
  }
}

export interface FormFieldViewStateParameters {
  readonly id: string
  readonly title: string
  readonly visible: boolean
  readonly editable: boolean
  readonly required: boolean
  readonly clearable: boolean
  readonly groupName: string
  readonly placeholder: string | undefined
  readonly errors: AttributeError[] | null | undefined
}

export abstract class FormFieldViewState {
  private readonly id: string
  private readonly title: string
  private readonly groupName: string
  private readonly required: boolean
  private readonly visible: boolean
  private readonly editable: boolean
  private readonly clearable: boolean
  private readonly placeholder: string | undefined
  private readonly errors: AttributeError[] | null | undefined

  protected constructor(parameters: FormFieldViewStateParameters) {
    this.id = parameters.id
    this.title = parameters.title
    this.groupName = parameters.groupName
    this.required = parameters.required
    this.errors = parameters.errors
    this.clearable = parameters.clearable
    this.visible = parameters.visible
    this.editable = parameters.editable
    this.placeholder = parameters.placeholder
  }

  getId(): string {
    return this.id
  }

  getTitle(): string {
    return this.title
  }

  getGroupName(): string {
    return this.groupName
  }

  getRequired(): boolean {
    return this.required
  }

  getErrors(): AttributeError[] | null | undefined {
    return this.errors
  }

  getClearable(): boolean {
    return this.clearable
  }

  getVisible() {
    return this.visible
  }

  getEditable() {
    return this.editable
  }

  getPlaceholder() {
    return this.placeholder
  }

  abstract hasValue(): boolean
}
