import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import RefuellingOrder from "../../../../core/domain/refuelling-orders/RefuellingOrder"
import RefuellingOrderStatus from "../../../../core/domain/refuelling-orders/RefuellingOrderStatus"
import BaseRefuellingOrderInfoProvider from "./BaseRefuellingOrderInfoProvider"

export default class RefuellingOrderInfoProvider extends BaseRefuellingOrderInfoProvider {

  getEditUrl({
    object
  }: {
    readonly object: RefuellingOrder
  }): string {
    if (object.status !== RefuellingOrderStatus.CLAIM) return ""

    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditRefuellingOrderUrl({
      refuellingOrderId: object.id!
    })
  }
}
