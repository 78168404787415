import React from "react"
import styles from "./FormFieldsComponent.module.scss"
import { FormFieldViewState } from "../../entities/forms/FormField"
import { StringFormFieldViewState } from "../../entities/forms/form-field-by-type/StringFormField"
import StringFormFieldComponent from "../form-field-by-type/string-form-field/StringFormFieldComponent"
import { DecimalFormFieldViewState } from "../../entities/forms/form-field-by-type/DecimalFormField"
import DecimalFormFieldComponent from "../form-field-by-type/decimal-form-field/DecimalFormValueComponent"
import { ListFormFieldViewState } from "../../entities/forms/form-field-by-type/ListFormField"
import { SingleSelectFormFieldViewState } from "../../entities/forms/form-field-by-type/SingleSelectFormField"
import SingleSelectFormFieldComponent
  from "../form-field-by-type/single-select-form-field/SingleSelectFormFieldComponent"
import { TextFormFieldViewState } from "../../entities/forms/form-field-by-type/TextFormField"
import TextFormFieldComponent from "../form-field-by-type/text-form-field/TextFormFieldComponent"
import { MultiSelectFormFieldViewState } from "../../entities/forms/form-field-by-type/MultiSelectFormField"
import MultiSelectFormFieldComponent from "../form-field-by-type/multi-select-form-field/MultiSelectFormFieldComponent"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import FormFieldGroup from "../../entities/forms/FormFieldGroup"
import { BooleanFormFieldViewState } from "../../entities/forms/form-field-by-type/BooleanFormField"
import BooleanFormFieldComponent from "../form-field-by-type/boolean-form-field/BooleanFormFieldComponent"
import { DateFormFieldViewState } from "../../entities/forms/form-field-by-type/DateFormField"
import DateFormFieldComponent from "../form-field-by-type/date-form-field/DateFormFieldComponent"
import { NumberFormFieldViewState } from "../../entities/forms/form-field-by-type/NumberFormField"
import NumberFormFieldComponent from "../form-field-by-type/number-form-field/NumberFormValueComponent"
import ButtonComponent from "../../../../../lib/button/ButtonComponent"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import InfoFormFieldComponent from "../form-field-by-type/info-form-field/InfoFormFieldComponent"
import { InfoFormFieldViewState } from "../../entities/forms/form-field-by-type/InfoFormField"
import PhoneNumberFormFieldComponent from "../form-field-by-type/phone-number-form-field/PhoneNumberFormFieldComponent"
import { PhoneNumberFormFieldViewState } from "../../entities/forms/form-field-by-type/PhoneNumberFormField"
import ErrorsContainerComponent from "../../../../../lib/errors-container/ErrorsContainerComponent"

export default function FormFieldsComponent({
  fieldViewStates,
  fieldsGroups = [],
  isDisabled,
  className = ""
}: {
  readonly fieldViewStates: FormFieldViewState[]
  readonly fieldsGroups?: FormFieldGroup[]
  readonly isDisabled: boolean
  readonly className?: string
}) {
  const theme = useCoreThemeProvider()
  const textProvider = useCoreTextProvider()

  const formFields = (states: FormFieldViewState[]) => {
    return (
      <>
        {states.map((fieldViewState: FormFieldViewState) => {
          const name: string = fieldViewState.getId()
          const visible = fieldViewState.getVisible()

          return (
            <div key={name} className={styles.field}>
              {(() => {
                if (!visible) return <></>

                if (fieldViewState instanceof ListFormFieldViewState) {
                  const id = fieldViewState.getId()
                  const title = fieldViewState.getTitle()
                  const isRequired = fieldViewState.getRequired()
                  const errors = fieldViewState.getErrors()
                  return (
                    <div key={id}>
                      {title && (
                        <h2 className={`${errors ? styles.fieldsGroupTitleWithoutMargins : styles.fieldsGroupTitle} ${theme.headline2PrimaryClassName()}`}>
                          {title}{isRequired ? " *" : ""}
                        </h2>
                      )}
                      <ErrorsContainerComponent
                        errors={errors ?? []}
                        errorMessageClassName={`${styles.listError} ${theme.body3ErrorClassName()}`}
                      />
                      {fieldViewState.getObjectFieldViewStates().map((objectState, index) => {
                        return (
                          <div key={index} className={styles.listObjectWrapper}>
                            <h3 className={`${styles.listObjectTitle} ${theme.headline4PrimaryClassName()}`}>
                              {objectState.title}
                            </h3>
                            <div className={styles.listObjectContainer}>
                              <FormFieldsComponent
                                fieldViewStates={objectState.fieldViewStates}
                                isDisabled={isDisabled}
                                className={styles.listObjectFields}
                              />
                              <div className={styles.listObjectActions}>
                                <ButtonComponent
                                  isDisabled={isDisabled}
                                  title={textProvider.deleteNestedObject()}
                                  componentStyles={theme.buttonFilled1SecondaryStyle()}
                                  onClick={() => fieldViewState.removeObject(objectState.id)}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <ButtonComponent
                        componentStyles={theme.buttonFilled1PrimaryStyle()}
                        title={fieldViewState.getAddObjectButton() ?? textProvider.addNestedObject()}
                        onClick={fieldViewState.addObject}
                        isDisabled={isDisabled}
                      />
                    </div>
                  )
                }

                if (fieldViewState instanceof StringFormFieldViewState) {
                  return (
                    <StringFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof PhoneNumberFormFieldViewState) {
                  return (
                    <PhoneNumberFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof InfoFormFieldViewState) {
                  return (
                    <InfoFormFieldComponent
                      fieldViewState={fieldViewState}
                    />
                  )
                }

                if (fieldViewState instanceof BooleanFormFieldViewState) {
                  return (
                    <BooleanFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof TextFormFieldViewState) {
                  return (
                    <TextFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof SingleSelectFormFieldViewState) {
                  return (
                    <SingleSelectFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof MultiSelectFormFieldViewState) {
                  return (
                    <MultiSelectFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof DecimalFormFieldViewState) {
                  return (
                    <DecimalFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof NumberFormFieldViewState) {
                  return (
                    <NumberFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                if (fieldViewState instanceof DateFormFieldViewState) {
                  return (
                    <DateFormFieldComponent
                      fieldViewState={fieldViewState}
                      isDisabled={isDisabled}
                    />
                  )
                }

                throw "Unknown form field type"
              })()}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className={`${styles.root} ${className}`}>
      {fieldsGroups.length > 0 && (
        <>
          {fieldsGroups.map((fieldsGroup) => {
            const groupFieldViewStates = fieldViewStates.filter((state) => state.getGroupName() === fieldsGroup.name)
            const { title } = fieldsGroup
            if (groupFieldViewStates.length === 0) return <div key={fieldsGroup.name} />

            return (
              <div key={fieldsGroup.name}>
                {title && (
                  <h2 className={`${styles.fieldsGroupTitle} ${theme.headline2PrimaryClassName()}`}>
                    {title}
                  </h2>
                )}
                { formFields(groupFieldViewStates) }
              </div>
            )
          })}
        </>
      )}
      {fieldsGroups.length === 0 && (
        <>
          { formFields(fieldViewStates) }
        </>
      )}
    </div>
  )
}
