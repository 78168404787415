import { Expose, Type } from "class-transformer"
import RefuellingOrderStatus from "../../../../domain/refuelling-orders/RefuellingOrderStatus"

export default class NetworkRefuellingOrdersRequestFilter {
  @Expose()
  @Type(() => String)
  readonly query?: string | null

  @Expose()
  @Type(() => Number)
  readonly carrierIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly fuelIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly gasStationIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly driverIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly transportIds?: number[] | null

  @Expose()
  @Type(() => String)
  readonly dateFrom?: string | null

  @Expose()
  @Type(() => String)
  readonly dateTo?: string | null

  @Expose()
  @Type(() => String)
  readonly statuses?: RefuellingOrderStatus[] | null

  @Expose()
  @Type(() => Number)
  readonly tripIds?: number[] | null

  constructor(parameters: {
    readonly query?: string | null
    readonly carrierIds?: number[] | null
    readonly fuelIds?: number[] | null
    readonly fuelCompanyIds?: number[] | null
    readonly gasStationIds?: number[] | null
    readonly driverIds?: number[] | null
    readonly transportIds?: number[] | null
    readonly dateFrom?: string | null
    readonly dateTo?: string | null
    readonly statuses?: RefuellingOrderStatus[] | null
    readonly tripIds?: number[] | null
  }) {
    this.query = parameters.query
    this.carrierIds = parameters.carrierIds
    this.fuelIds = parameters.fuelIds
    this.fuelCompanyIds = parameters.fuelCompanyIds
    this.gasStationIds = parameters.gasStationIds
    this.driverIds = parameters.driverIds
    this.transportIds = parameters.transportIds
    this.dateFrom = parameters.dateFrom
    this.dateTo = parameters.dateTo
    this.statuses = parameters.statuses
    this.tripIds = parameters.tripIds
  }
}
