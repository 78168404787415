import InstallationInstancesRepository from "../../repositories/InstallationInstancesRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import InstallationInstance from "../../../../../core/domain/installation-instance/InstallationInstance"
import InstallationInstanceError from "../../../../../core/domain/installation-instance/InstallationInstanceError"

export interface CreateInstallationInstanceParameters {
  readonly object: InstallationInstance
}

export default class CreateInstallationInstanceUseCase
  implements CreateObjectUseCase<InstallationInstance, InstallationInstanceError> {

  private readonly installationInstancesRepository: InstallationInstancesRepository

  constructor(parameters: {
    readonly installationInstancesRepository: InstallationInstancesRepository
  }) {
    this.installationInstancesRepository = parameters.installationInstancesRepository
  }

  async call(
    parameters: CreateInstallationInstanceParameters
  ): Promise<CreateObjectResult<InstallationInstance, InstallationInstanceError>> {
    return await this.installationInstancesRepository.createInstallationInstance(parameters)
  }
}
