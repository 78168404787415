import { Expose, Transform, Type } from "class-transformer"
import NetworkCarrier from "../carriers/NetworkCarrier"
import NetworkFuelCompany from "../fuel-companies/NetworkFuelCompany"
import NetworkFuel from "../fuels/NetworkFuel"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import RefuellingOrderStatus from "../../../domain/refuelling-orders/RefuellingOrderStatus"
import NetworkUserTrip from "../user-trips/NetworkUserTrip"
import NetworkClaim from "../claims/NetworkClaim"
import NetworkFuelTransaction from "../fuel-transactions/NetworkFuelTransaction"

export default class NetworkRefuellingOrder {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly code?: string | null

  @Expose()
  @Type(() => Date)
  readonly createdAt?: Date | null

  @Expose()
  @Type(() => Number)
  readonly carrierId?: number | null

  @Expose()
  @Transform(transformDecimal)
  readonly fuelCount?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly fuelCountType?: string | null

  @Expose()
  @Transform(transformDecimal)
  readonly fuelPrice?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedFuelPrice?: string | null

  @Expose()
  @Type(() => String)
  readonly status?: RefuellingOrderStatus | null

  @Expose()
  @Type(() => String)
  readonly statusName?: string | null

  @Expose()
  @Type(() => String)
  readonly statusColorHex?: string | null

  @Expose()
  @Type(() => Number)
  readonly userTripId?: number | null

  @Expose()
  @Type(() => Number)
  readonly fuelId?: number | null

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuel?: NetworkFuel | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStation?: NetworkGasStation | null

  @Expose()
  @Type(() => NetworkCarrier)
  readonly carrier?: NetworkCarrier | null

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompany?: NetworkFuelCompany | null

  @Expose()
  @Type(() => NetworkFuelTransaction)
  readonly currentTransaction?: NetworkFuelTransaction | null

  @Expose()
  @Type(() => NetworkUserTrip)
  readonly userTrip?: NetworkUserTrip | null

  @Expose()
  @Type(() => NetworkClaim)
  readonly claim?: NetworkClaim | null
}
