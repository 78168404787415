import React from "react"
import styles from "./NumberFormValueComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import LabelComponent from "../../../../../../lib/label/LabelComponent"
import TextInputComponent, { TextInputType } from "../../../../../../lib/text-input/TextInputComponent"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"
import { NumberFormFieldViewState } from "../../../entities/forms/form-field-by-type/NumberFormField"

export default function NumberFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: NumberFormFieldViewState
  readonly isDisabled: boolean
}) {
  function onChange(textValue: string) {
    const value: number | null = (() => {
      const result = parseInt(textValue)
      return isNaN(result) ? null : result
    })()

    fieldViewState.onChange(value)
  }

  const theme = useCoreThemeProvider()

  const name = fieldViewState.getId()
  const label = fieldViewState.getTitle()
  const isRequired = fieldViewState.getRequired()
  const errors = fieldViewState.getErrors()

  return (
    <div className={styles.root}>
      {label && name && (
        <LabelComponent
          htmlFor={name}
          text={`${label}${isRequired ? " *" : ""}`}
          className={theme.label1PrimaryClassName()}
        />
      )}
      <TextInputComponent
        name={name}
        type={TextInputType.NUMBER}
        value={fieldViewState.value?.toFixed() ?? ""}
        isDisabled={isDisabled || !fieldViewState.getEditable()}
        onChange={onChange}
        className={styles.inputWrapper}
        componentStyle={theme.input1PrimaryStyle()}
        hasErrors={(errors && errors.length > 0) ?? false}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
