import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkUser from "../../users/NetworkUser"

export default class NetworkDriversResponseBody {

  @Expose()
  @Type(() => NetworkUser)
  readonly drivers?: NetworkUser[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
