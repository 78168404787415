import SelectOption from "../../../../admin/lib/picker/SelectOption"

enum TripStatus {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  FINISHED = "finished",
  CANCELED = "canceled"
}

export default TripStatus

export interface TripStatusSelectOption extends SelectOption { }
