import { Expose, Type } from "class-transformer"
import NetworkCarrierCommercialTermErrorsObject from "./NetworkCarrierCommercialTermErrorsObject"
import NetworkExecutionError from "../../../errors/NetworkExecutionError"
import CarrierCommercialTermError from "../../../../../domain/carrier-commercial-terms/CarrierCommercialTermError"

export default class NetworkCarrierCommercialTermErrorResponseBody
  extends NetworkExecutionError
  implements CarrierCommercialTermError {
  @Expose()
  @Type(() => NetworkCarrierCommercialTermErrorsObject)
  readonly errorsObject?: NetworkCarrierCommercialTermErrorsObject | null
}
