import NotFoundI18n from "../../i18n/NotFoundI18n"
import NotFoundTextProvider from "../../i18n/NotFoundTextProvider"
import { I18nDiModule } from "../../../../core/di/modules/I18nDiModule"

export default interface NotFoundI18nDiModule {
  provideNotFoundI18n(): NotFoundI18n
}

export class DefaultNotFoundI18nDiModule
  extends I18nDiModule<NotFoundTextProvider>
  implements NotFoundI18nDiModule {

  provideNotFoundI18n(): NotFoundI18n {
    return this.single(
      NotFoundI18n.name,
      () => new NotFoundI18n(this.getI18nParameters())
    )
  }
}
