import UsersTextProvider from "../UsersTextProvider"

export default class DefaultRuUsersTextProvider implements UsersTextProvider {
  emailField(): string {
    return "E-mail"
  }

  listTitle(): string {
    return "Пользователи"
  }

  firstNameField(): string {
    return "Имя"
  }

  middleNameField(): string {
    return "Отчество"
  }

  lastNameField(): string {
    return "Фамилия"
  }

  newObjectTitle(): string {
    return "Новый пользователь"
  }

  eventsLink(): string {
    return "События"
  }

  statusField(): string {
    return "Статус"
  }

  blockedField(): string {
    return "Блокировка"
  }

  statusValueActive(): string {
    return "Активен"
  }

  statusValueBlocked(): string {
    return "Заблокирован"
  }
}
