import { Expose, Type } from "class-transformer"
import NetworkPage from "../../pagination/NetworkPage"
import NetworkTransport from "../NetworkTransport"

export default class NetworkTransportsResponseBody {

  @Expose()
  @Type(() => NetworkTransport)
  readonly transports?: NetworkTransport[] | null

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
