import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import FuelCompanyReconciliationReportsRepository
  from "../../domain/repositories/FuelCompanyReconciliationReportsRepository"
import {
  GetFuelCompanyReconciliationReportsParameters
} from "../../domain/use-cases/fuel-company-reconciliation-reports/GetFuelCompanyReconciliationReportsUseCase"
import {
  CreateFuelCompanyReconciliationReportParameters
} from "../../domain/use-cases/fuel-company-reconciliation-reports/CreateFuelCompanyReconciliationReportUseCase"
import FuelCompanyReconciliationReportsNetworkSource
  from "../../../../core/data/sources/network/FuelCompanyReconciliationReportsNetworkSource"
import FuelCompanyReconciliationReport
  from "../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReport"
import NetworkFuelCompanyReconciliationReportsRequestFilter
  from "../../../../core/data/entities/fuel-company-reconciliation-reports/request-queries/NetworkFuelCompanyReconciliationReportsRequestFilter"
import FuelCompanyReconciliationReportsMapper
  from "../../../../core/data/mappers/FuelCompanyReconciliationReportsMapper"
import FuelCompanyReconciliationReportError
  from "../../../../core/domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReportError"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"

export default class DefaultFuelCompanyReconciliationReportsRepository
  implements FuelCompanyReconciliationReportsRepository {
  private readonly fuelCompanyReconciliationReportsNetworkSource: FuelCompanyReconciliationReportsNetworkSource

  constructor(parameters: {
    readonly fuelCompanyReconciliationReportsNetworkSource: FuelCompanyReconciliationReportsNetworkSource
  }) {
    this.fuelCompanyReconciliationReportsNetworkSource = parameters.fuelCompanyReconciliationReportsNetworkSource
  }

  async getFuelCompanyReconciliationReports({
    pagination,
    sort,
    ids,
    needPagination
  }: GetFuelCompanyReconciliationReportsParameters): Promise<GetObjectsResult<FuelCompanyReconciliationReport>> {
    const result = await this.fuelCompanyReconciliationReportsNetworkSource.getFuelCompanyReconciliationReports({
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort }),
      needPagination: needPagination,
      filter: new NetworkFuelCompanyReconciliationReportsRequestFilter({
        ids
      })
    })

    const reportsMapper = new FuelCompanyReconciliationReportsMapper()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.reports!.map((report) => {
              return reportsMapper.mapNetworkToDomain({
                report
              })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async createFuelCompanyReconciliationReport({
    object: report
  }: CreateFuelCompanyReconciliationReportParameters): Promise<
    CreateObjectResult<FuelCompanyReconciliationReport, FuelCompanyReconciliationReportError>
  > {
    const mapper = new FuelCompanyReconciliationReportsMapper()
    const result = await this.fuelCompanyReconciliationReportsNetworkSource.createFuelCompanyReconciliationReport({
      report: mapper.mapDomainToNetwork({ report })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            report: result.data.report!
          })
        }
      default:
        return result
    }
  }
}
