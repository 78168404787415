import { useOutletContext } from "react-router-dom"

export interface LocationContext {
  readonly locationId: string
  readonly getOrCreatePresenter: <Presenter>(creator: () => Presenter) => Presenter
}

export default function useLocationContext(): LocationContext {
  return useOutletContext() as LocationContext
}
