import { Expose, Type } from "class-transformer"
import NetworkTripErrorsObject from "./NetworkTripErrorsObject"
import NetworkExecutionError from "../../../errors/NetworkExecutionError"
import TripError from "../../../../../domain/trips/TripError"

export default class NetworkTripErrorResponseBody
  extends NetworkExecutionError
  implements TripError {

  @Expose()
  @Type(() => NetworkTripErrorsObject)
  readonly errorsObject?: NetworkTripErrorsObject | null
}
