import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import TripLimitTypesForTripLimitsRepository from "../../domain/repositories/TripLimitTypesForTripLimitsRepository"
import TripLimitTypesNetworkSource from "../../../../core/data/sources/network/TripLimitTypesNetworkSource"
import {
  GetTripLimitTypesForTripLimitsParameters
} from "../../domain/use-cases/trip-limits/GetTripLimitTypesForTripLimitsUseCase"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import TripLimitType from "../../../../core/domain/trip-limit-type/TripLimitType"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import TripLimitTypesMapper from "../../../../core/data/mappers/TripLimitTypesMapper"

export default class DefaultTripLimitTypesForTripLimitsRepository
  implements TripLimitTypesForTripLimitsRepository {
  private readonly tripLimitTypesNetworkSource: TripLimitTypesNetworkSource

  constructor(parameters: {
    readonly tripLimitTypesNetworkSource: TripLimitTypesNetworkSource
  }) {
    this.tripLimitTypesNetworkSource = parameters.tripLimitTypesNetworkSource
  }

  async getTripLimitTypes({
      pagination
    }: GetTripLimitTypesForTripLimitsParameters
  ): Promise<GetObjectsResult<TripLimitType>> {
    const result = await this.tripLimitTypesNetworkSource.getTripLimitTypes({
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.tripLimitTypes!.map((tripLimitType) => {
              return new TripLimitTypesMapper().mapNetworkToDomain({ tripLimitType })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
