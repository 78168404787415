import GetCarrierCommercialTermsUseCase from "../../domain/use-cases/carrier-commercial-terms/GetCarrierCommercialTermsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CarrierCommercialTermsDataDiModule from "./CarrierCommercialTermsDataDiModule"
import CreateCarrierCommercialTermUseCase from "../../domain/use-cases/carrier-commercial-terms/CreateCarrierCommercialTermUseCase"
import GetCarrierCommercialTermUseCase from "../../domain/use-cases/carrier-commercial-terms/GetCarrierCommercialTermUseCase"
import UpdateCarrierCommercialTermUseCase from "../../domain/use-cases/carrier-commercial-terms/UpdateCarrierCommercialTermUseCase"
import GetFuelCompaniesForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForCarrierCommercialTermsUseCase"
import GetCarriersForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForCarrierCommercialTermsUseCase"
import GetFuelsForCarrierCommercialTermsUseCase from "../../domain/use-cases/fuels/GetFuelsForCarrierCommercialTermsUseCase"
import GetGasStationsForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForCarrierCommercialTermsUseCase"
import GetPricingTypesForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/pricing-types/GetPricingTypesForCarrierCommercialTermsUseCase"

export default interface CarrierCommercialTermsDomainDiModule {
  provideGetCarrierCommercialTermsUseCase(): GetCarrierCommercialTermsUseCase

  provideGetCarrierCommercialTermUseCase(): GetCarrierCommercialTermUseCase

  provideCreateCarrierCommercialTermUseCase(): CreateCarrierCommercialTermUseCase

  provideUpdateCarrierCommercialTermUseCase(): UpdateCarrierCommercialTermUseCase

  provideGetCarriersForCarrierCommercialTermsUseCase(): GetCarriersForCarrierCommercialTermsUseCase

  provideGetFuelCompaniesForCarrierCommercialTermsUseCase(): GetFuelCompaniesForCarrierCommercialTermsUseCase

  provideGetFuelsForCarrierCommercialTermsUseCase(): GetFuelsForCarrierCommercialTermsUseCase

  provideGetGasStationsForCarrierCommercialTermsUseCase(): GetGasStationsForCarrierCommercialTermsUseCase

  provideGetPricingTypesForCarrierCommercialTermsUseCase(): GetPricingTypesForCarrierCommercialTermsUseCase
}

export class DefaultCarrierCommercialTermsDomainDiModule
  extends DiModule
  implements CarrierCommercialTermsDomainDiModule {
  private readonly carrierCommercialTermsDataDiModule: CarrierCommercialTermsDataDiModule

  constructor(parameters: {
    readonly carrierCommercialTermsDataDiModule: CarrierCommercialTermsDataDiModule
  }) {
    super()
    this.carrierCommercialTermsDataDiModule = parameters.carrierCommercialTermsDataDiModule
  }

  provideGetCarrierCommercialTermsUseCase(): GetCarrierCommercialTermsUseCase {
    return this.single(
      GetCarrierCommercialTermsUseCase.name,
      () => new GetCarrierCommercialTermsUseCase({
        carrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideCarrierCommercialTermsRepository()
      })
    )
  }

  provideGetCarrierCommercialTermUseCase(): GetCarrierCommercialTermUseCase {
    return this.single(
      GetCarrierCommercialTermUseCase.name,
      () => new GetCarrierCommercialTermUseCase({
        carrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideCarrierCommercialTermsRepository()
      })
    )
  }

  provideCreateCarrierCommercialTermUseCase(): CreateCarrierCommercialTermUseCase {
    return this.single(
      CreateCarrierCommercialTermUseCase.name,
      () => new CreateCarrierCommercialTermUseCase({
        carrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideCarrierCommercialTermsRepository()
      })
    )
  }

  provideUpdateCarrierCommercialTermUseCase(): UpdateCarrierCommercialTermUseCase {
    return this.single(
      UpdateCarrierCommercialTermUseCase.name,
      () => new UpdateCarrierCommercialTermUseCase({
        carrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideCarrierCommercialTermsRepository()
      })
    )
  }

  provideGetCarriersForCarrierCommercialTermsUseCase(): GetCarriersForCarrierCommercialTermsUseCase {
    return this.single(
      GetCarriersForCarrierCommercialTermsUseCase.name,
      () => new GetCarriersForCarrierCommercialTermsUseCase({
        carriersForCarrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideCarriersForCarrierCommercialTermsRepository()
      })
    )
  }

  provideGetFuelCompaniesForCarrierCommercialTermsUseCase(): GetFuelCompaniesForCarrierCommercialTermsUseCase {
    return this.single(
      GetFuelCompaniesForCarrierCommercialTermsUseCase.name,
      () => new GetFuelCompaniesForCarrierCommercialTermsUseCase({
        fuelCompanyForCarrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideFuelCompaniesForCarrierCommercialTermsRepository()
      })
    )
  }

  provideGetFuelsForCarrierCommercialTermsUseCase(): GetFuelsForCarrierCommercialTermsUseCase {
    return this.single(
      GetFuelsForCarrierCommercialTermsUseCase.name,
      () => new GetFuelsForCarrierCommercialTermsUseCase({
        fuelsForCarrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideFuelsForCarrierCommercialTermsRepository()
      })
    )
  }

  provideGetGasStationsForCarrierCommercialTermsUseCase(): GetGasStationsForCarrierCommercialTermsUseCase {
    return this.single(
      GetGasStationsForCarrierCommercialTermsUseCase.name,
      () => new GetGasStationsForCarrierCommercialTermsUseCase({
        gasStationsForCarrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.provideGasStationsForCarrierCommercialTermsRepository()
      })
    )
  }

  provideGetPricingTypesForCarrierCommercialTermsUseCase(): GetPricingTypesForCarrierCommercialTermsUseCase {
    return this.single(
      GetPricingTypesForCarrierCommercialTermsUseCase.name,
      () => new GetPricingTypesForCarrierCommercialTermsUseCase({
        pricingTypesForCarrierCommercialTermsRepository:
          this.carrierCommercialTermsDataDiModule.providePricingTypesForCarrierCommercialTermsRepository()
      })
    )
  }
}
