import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import ChangingTypesForTripChangingLogRepository
  from "../../domain/repositories/ChangingTypesForTripChangingLogRepository"
import {
  GetChangingTypesForTripChangingLogParameters
} from "../../domain/use-cases/changing-types/GetChangingTypesForTripChangingLogUseCase"
import { ChangingTypeSelectOption } from "../../../../core/domain/changing-log-items/ChangingType"
import ChangingTypesStaticSource from "../../../../core/data/sources/static/ChangingTypesStaticSource"

export default class DefaultChangingTypesForTripChangingLogRepository
  implements ChangingTypesForTripChangingLogRepository {
  private readonly changingTypesStaticSource: ChangingTypesStaticSource

  constructor(parameters: {
    readonly changingTypesStaticSource: ChangingTypesStaticSource
  }) {
    this.changingTypesStaticSource = parameters.changingTypesStaticSource
  }

  async getChangingTypes({
    ids
  }: GetChangingTypesForTripChangingLogParameters): Promise<GetObjectsResult<ChangingTypeSelectOption>> {
    const statuses = await this.changingTypesStaticSource.getChangingTypes({
      ids
    })

    return {
      type: "success",
      data: {
        objects: statuses,
        page: {
          hasMore: false
        }
      }
    }
  }
}
