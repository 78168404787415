import FuelTransactionsMapper from "./FuelTransactionsMapper"
import NetworkFuelCompanyBalanceTransaction
  from "../entities/fuel-company-balance-transactions/NetworkFuelCompanyBalanceTransaction"
import FuelCompanyBalanceTransaction from "../../domain/fuel-company-balance-transactions/FuelCompanyBalanceTransaction"

export default class FuelCompanyBalanceTransactionsMapper {
  mapNetworkToDomain({
    fuelCompanyBalanceTransaction
  }: {
    readonly fuelCompanyBalanceTransaction: NetworkFuelCompanyBalanceTransaction
  }): FuelCompanyBalanceTransaction {
    const {
      fuelTransaction
    } = fuelCompanyBalanceTransaction

    return {
      id: fuelCompanyBalanceTransaction.id,
      createdAt: fuelCompanyBalanceTransaction.createdAt,
      date: fuelCompanyBalanceTransaction.date,
      value: fuelCompanyBalanceTransaction.value,
      formattedValue: fuelCompanyBalanceTransaction.formattedValue,
      fuelTransaction: fuelTransaction && new FuelTransactionsMapper().mapNetworkToDomain({ fuelTransaction })
    }
  }
}
