import GetGasStationsUseCase from "../../domain/use-cases/gas-stations/GetGasStationsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import GasStationsDataDiModule from "./GasStationsDataDiModule"
import CreateGasStationUseCase from "../../domain/use-cases/gas-stations/CreateGasStationUseCase"
import GetGasStationUseCase from "../../domain/use-cases/gas-stations/GetGasStationUseCase"
import UpdateGasStationUseCase from "../../domain/use-cases/gas-stations/UpdateGasStationUseCase"
import GetFuelCompaniesForGasStationsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForGasStationUseCase"

export default interface GasStationsDomainDiModule {
  provideGetGasStationsUseCase(): GetGasStationsUseCase

  provideGetGasStationUseCase(): GetGasStationUseCase

  provideCreateGasStationUseCase(): CreateGasStationUseCase

  provideUpdateGasStationUseCase(): UpdateGasStationUseCase

  provideGetFuelStationsForGasStationUseCase(): GetFuelCompaniesForGasStationsUseCase
}

export class DefaultGasStationsDomainDiModule extends DiModule implements GasStationsDomainDiModule {
  private readonly gasStationsDataDiModule: GasStationsDataDiModule

  constructor(parameters: {
    readonly gasStationsDataDiModule: GasStationsDataDiModule
  }) {
    super()
    this.gasStationsDataDiModule = parameters.gasStationsDataDiModule
  }

  provideGetGasStationsUseCase(): GetGasStationsUseCase {
    return this.single(
      GetGasStationsUseCase.name,
      () => new GetGasStationsUseCase({
        gasStationsRepository: this.gasStationsDataDiModule.provideGasStationsRepository()
      })
    )
  }

  provideGetGasStationUseCase(): GetGasStationUseCase {
    return this.single(
      GetGasStationUseCase.name,
      () => new GetGasStationUseCase({
        gasStationsRepository: this.gasStationsDataDiModule.provideGasStationsRepository()
      })
    )
  }

  provideCreateGasStationUseCase(): CreateGasStationUseCase {
    return this.single(
      CreateGasStationUseCase.name,
      () => new CreateGasStationUseCase({
        gasStationsRepository: this.gasStationsDataDiModule.provideGasStationsRepository()
      })
    )
  }

  provideUpdateGasStationUseCase(): UpdateGasStationUseCase {
    return this.single(
      UpdateGasStationUseCase.name,
      () => new UpdateGasStationUseCase({
        gasStationsRepository: this.gasStationsDataDiModule.provideGasStationsRepository()
      })
    )
  }

  provideGetFuelStationsForGasStationUseCase(): GetFuelCompaniesForGasStationsUseCase {
    return this.single(
      GetFuelCompaniesForGasStationsUseCase.name,
      () => new GetFuelCompaniesForGasStationsUseCase({
        fuelCompanyForGasStationsRepository: this.gasStationsDataDiModule.provideFuelCompanyForGasStationsRepository()
      })
    )
  }
}
