import CarriersForCarrierBalancesRepository
  from "../../domain/repositories/CarriersForCarrierBalancesRepository"
import CarriersNetworkSource from "../../../../core/data/sources/network/CarriersNetworkSource"
import {
  GetCarrierForCarrierBalancesParameters
} from "../../domain/use-cases/carriers/GetCarrierForCarrierBalancesUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import Carrier from "../../../../core/domain/carriers/Carrier"
import CarriersMapper from "../../../../core/data/mappers/CarriersMapper"

export default class DefaultCarriersForCarrierBalancesRepository
  implements CarriersForCarrierBalancesRepository {
  private readonly carriersNetworkSource: CarriersNetworkSource

  constructor(parameters: {
    readonly carriersNetworkSource: CarriersNetworkSource
  }) {
    this.carriersNetworkSource = parameters.carriersNetworkSource
  }

  async getCarrier({
    objectId
  }: GetCarrierForCarrierBalancesParameters): Promise<GetObjectResult<Carrier>> {
    const mapper = new CarriersMapper()
    const result = await this.carriersNetworkSource.getCarrier({
      carrierId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            carrier: result.data.carrier!
          })
        }
      default:
        return result
    }
  }
}
