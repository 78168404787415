import FuelPricesI18n from "../../i18n/FuelPricesI18n"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import FuelPricesTextProvider from "../../i18n/FuelPricesTextProvider"

export default interface FuelPricesI18nDiModule {
  provideFuelPricesI18n(): FuelPricesI18n
}

export class DefaultFuelPricesI18nDiModule
  extends I18nDiModule<FuelPricesTextProvider>
  implements FuelPricesI18nDiModule {

  provideFuelPricesI18n(): FuelPricesI18n {
    return this.single(
      FuelPricesI18n.name,
      () => new FuelPricesI18n(this.getI18nParameters())
    )
  }
}
