import UsersRepository from "../../domain/repositories/UsersRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultUsersRepository from "../../data/repositories/DefaultUsersRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"

export default interface UsersDataDiModule {
  provideUsersRepository(): UsersRepository
}

export class DefaultUsersDataDiModule extends DiModule implements UsersDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideUsersRepository(): UsersRepository {
    return this.single(
      DefaultUsersRepository.name,
      () => new DefaultUsersRepository({
        usersNetworkSource: this.appDataDiModule.provideUsersNetworkSource()
      })
    )
  }
}
