import { Decimal } from "decimal.js"
import { Expose, Transform, Type } from "class-transformer"
import transformDecimal from "../../network/transformers/transformDecimal"
import NetworkFuelTransaction from "../fuel-transactions/NetworkFuelTransaction"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"
import NetworkFuelCompanyBalanceTransaction
  from "../fuel-company-balance-transactions/NetworkFuelCompanyBalanceTransaction"

export default class NetworkFuelCompanyBalanceChangeDocument {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly date?: Date | null

  @Expose()
  @Transform(transformDecimal)
  readonly value?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedValue?: string | null

  @Expose()
  @Type(() => String)
  readonly comment?: string | null

  @Expose()
  @Type(() => String)
  readonly reason?: string | null

  @Expose()
  @Type(() => NetworkFuelCompanyBalanceTransaction)
  readonly fuelCompanyBalanceTransaction?: NetworkFuelCompanyBalanceTransaction | null
}
