import TripsRepository from "../../repositories/TripsRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import Trip from "../../../../../core/domain/trips/Trip"
import TripError from "../../../../../core/domain/trips/TripError"

export interface CreateTripParameters {
  readonly object: Trip
}

export default class CreateTripUseCase implements CreateObjectUseCase<Trip, TripError> {
  private readonly tripsRepository: TripsRepository

  constructor(parameters: {
    readonly tripsRepository: TripsRepository
  }) {
    this.tripsRepository = parameters.tripsRepository
  }

  async call(parameters: CreateTripParameters): Promise<CreateObjectResult<Trip, TripError>> {
    return await this.tripsRepository.createTrip(parameters)
  }
}
