import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import CarrierCommercialTermsDomainDiModule from "./CarrierCommercialTermsDomainDiModule"
import CarrierCommercialTermsTableProvider from "../../presentation/table-providers/CarrierCommercialTermsTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import CarrierCommercialTermFormProvider from "../../presentation/form-providers/CarrierCommercialTermFormProvider"
import CarrierCommercialTermInfoProvider from "../../presentation/info-providers/CarrierCommercialTermInfoProvider"
import CarrierCommercialTermsI18nDiModule from "./CarrierCommercialTermsI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "carrierCommercialTerms"

export default interface CarrierCommercialTermsPresentationDiModule {
  provideCarrierCommercialTermsListPage(): React.ReactNode

  provideCarrierCommercialTermsInfoPage(): React.ReactNode

  provideCarrierCommercialTermsFormPage(): React.ReactNode
}

export class DefaultCarrierCommercialTermsPresentationDiModule
  extends DiModule
  implements CarrierCommercialTermsPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly carrierCommercialTermsI18nDiModule: CarrierCommercialTermsI18nDiModule
  private readonly carrierCommercialTermsDomainDiModule: CarrierCommercialTermsDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly carrierCommercialTermsI18nDiModule: CarrierCommercialTermsI18nDiModule
    readonly carrierCommercialTermsDomainDiModule: CarrierCommercialTermsDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.carrierCommercialTermsI18nDiModule = parameters.carrierCommercialTermsI18nDiModule
    this.carrierCommercialTermsDomainDiModule = parameters.carrierCommercialTermsDomainDiModule
  }

  provideCarrierCommercialTermsListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.carrierCommercialTermsDomainDiModule.provideGetCarrierCommercialTermsUseCase(),
      tableProvider: this.provideCarrierCommercialTermsTableProvider()
    })
  }

  private provideCarrierCommercialTermsTableProvider(): CarrierCommercialTermsTableProvider {
    return this.single(
      CarrierCommercialTermsTableProvider.name,
      () => new CarrierCommercialTermsTableProvider({
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        carrierCommercialTermsI18: this.carrierCommercialTermsI18nDiModule.provideCarrierCommercialTermsI18n(),
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase(),
        getCarriersUseCase:
          this.carrierCommercialTermsDomainDiModule.provideGetCarriersForCarrierCommercialTermsUseCase()
      })
    )
  }

  provideCarrierCommercialTermsFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.carrierCommercialTermsDomainDiModule.provideGetCarrierCommercialTermUseCase(),
      createObjectUseCase: this.carrierCommercialTermsDomainDiModule.provideCreateCarrierCommercialTermUseCase(),
      updateObjectUseCase: this.carrierCommercialTermsDomainDiModule.provideUpdateCarrierCommercialTermUseCase(),
      formProvider: this.provideCarrierCommercialTermFormProvider()
    })
  }

  private provideCarrierCommercialTermFormProvider(): CarrierCommercialTermFormProvider {
    return this.single(
      CarrierCommercialTermFormProvider.name,
      () => new CarrierCommercialTermFormProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        carrierCommercialTermsI18: this.carrierCommercialTermsI18nDiModule.provideCarrierCommercialTermsI18n(),
        getCarriersUseCase:
          this.carrierCommercialTermsDomainDiModule.provideGetCarriersForCarrierCommercialTermsUseCase(),
        getFuelCompaniesUseCase:
          this.carrierCommercialTermsDomainDiModule.provideGetFuelCompaniesForCarrierCommercialTermsUseCase(),
        getFuelsUseCase:
          this.carrierCommercialTermsDomainDiModule.provideGetFuelsForCarrierCommercialTermsUseCase(),
        getGasStationsUseCase:
          this.carrierCommercialTermsDomainDiModule.provideGetGasStationsForCarrierCommercialTermsUseCase(),
        getPricingTypesUseCase:
          this.carrierCommercialTermsDomainDiModule.provideGetPricingTypesForCarrierCommercialTermsUseCase()
      })
    )
  }

  provideCarrierCommercialTermsInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.carrierCommercialTermsDomainDiModule.provideGetCarrierCommercialTermUseCase(),
      infoProvider: this.provideCarrierCommercialTermInfoProvider()
    })
  }

  private provideCarrierCommercialTermInfoProvider(): CarrierCommercialTermInfoProvider {
    return this.single(
      CarrierCommercialTermInfoProvider.name,
      () => new CarrierCommercialTermInfoProvider({
        timeZone: this.timeZone,
        carrierCommercialTermsI18: this.carrierCommercialTermsI18nDiModule.provideCarrierCommercialTermsI18n()
      })
    )
  }
}
