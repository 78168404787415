import InfoRow, { InfoRowParameters } from "../InfoRow"

export default class StringInfoRow extends InfoRow {
  readonly value: string | null | undefined
  readonly additionalValue: string | null | undefined
  readonly url: string | null | undefined
  readonly openInNewTab: boolean

  constructor(parameters: InfoRowParameters & {
    readonly value?: string | null
    readonly additionalValue?: string | null
    readonly url?: string | null
    readonly openInNewTab?: boolean
  }) {
    super(parameters)
    this.value = parameters.value
    this.additionalValue = parameters.additionalValue
    this.url = parameters.url
    this.openInNewTab = parameters.openInNewTab ?? false
  }
}
