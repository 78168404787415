import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompanyBalanceChangeDocument
  from "../../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import CurrentFuelCompanyBalanceChangeDocumentsRepository
  from "../../repositories/CurrentFuelCompanyBalanceChangeDocumentsRepository"

export interface GetCurrentFuelCompanyBalanceChangeDocumentsParameters extends GetObjectsParameters {
}

export default class GetCurrentFuelCompanyBalanceChangeDocumentsUseCase
  implements GetObjectsUseCase<FuelCompanyBalanceChangeDocument> {
  private readonly currentFuelCompanyBalanceChangeDocumentsRepository: CurrentFuelCompanyBalanceChangeDocumentsRepository

  constructor(parameters: {
    readonly currentFuelCompanyBalanceChangeDocumentsRepository: CurrentFuelCompanyBalanceChangeDocumentsRepository
  }) {
    this.currentFuelCompanyBalanceChangeDocumentsRepository = parameters.currentFuelCompanyBalanceChangeDocumentsRepository
  }

  async call(
    parameters: GetCurrentFuelCompanyBalanceChangeDocumentsParameters
  ): Promise<GetObjectsResult<FuelCompanyBalanceChangeDocument>> {
    return await this.currentFuelCompanyBalanceChangeDocumentsRepository.getDocuments(parameters)
  }
}
