import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import RefuellingOrdersDomainDiModule, { DefaultRefuellingOrdersDomainDiModule } from "./RefuellingOrdersDomainDiModule"
import RefuellingOrdersTableProvider from "../../presentation/table-providers/RefuellingOrdersTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import RefuellingOrderInfoProvider from "../../presentation/info-providers/RefuellingOrderInfoProvider"
import RefuellingOrdersI18nDiModule, { DefaultRefuellingOrdersI18nDiModule } from "./RefuellingOrdersI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"
import ClaimedRefuellingOrdersTableProvider
  from "../../presentation/table-providers/ClaimedRefuellingOrdersTableProvider"
import CancelledRefuellingOrdersTableProvider
  from "../../presentation/table-providers/CancelledRefuellingOrdersTableProvider"
import CancelledRefuellingOrderInfoProvider
  from "../../presentation/info-providers/CancelledRefuellingOrderInfoProvider"
import ClaimedRefuellingOrderInfoProvider from "../../presentation/info-providers/ClaimedRefuellingOrderInfoProvider"
import RefuellingOrderFormProvider from "../../presentation/form-providers/RefuellingOrderFormProvider"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "refuellingOrders"

export default interface RefuellingOrdersPresentationDiModule {
  provideRefuellingOrdersListPage(): React.ReactNode

  provideClaimedRefuellingOrdersListPage(): React.ReactNode

  provideCancelledRefuellingOrdersListPage(): React.ReactNode

  provideRefuellingOrderInfoPage(): React.ReactNode

  provideClaimedRefuellingOrderInfoPage(): React.ReactNode

  provideCancelledRefuellingOrderInfoPage(): React.ReactNode

  provideRefuellingOrderFormPage(): React.ReactNode
}

export class DefaultRefuellingOrdersPresentationDiModule
  extends DiModule
  implements RefuellingOrdersPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly refuellingOrdersI18nDiModule: RefuellingOrdersI18nDiModule
  private readonly refuellingOrdersDomainDiModule: RefuellingOrdersDomainDiModule
  private readonly coreDomainDiModule: CoreDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly refuellingOrdersDomainDiModule: DefaultRefuellingOrdersDomainDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly refuellingOrdersI18nDiModule: DefaultRefuellingOrdersI18nDiModule
    readonly coreDomainDiModule: CoreDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.refuellingOrdersI18nDiModule = parameters.refuellingOrdersI18nDiModule
    this.refuellingOrdersDomainDiModule = parameters.refuellingOrdersDomainDiModule
    this.coreDomainDiModule = parameters.coreDomainDiModule
  }

  provideRefuellingOrdersListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.refuellingOrdersDomainDiModule.provideGetRefuellingOrdersUseCase(),
      tableProvider: this.provideRefuellingOrdersTableProvider()
    })
  }

  private provideRefuellingOrdersTableProvider(): RefuellingOrdersTableProvider {
    return this.single(
      RefuellingOrdersTableProvider.name,
      () => new RefuellingOrdersTableProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        appI18n: this.appI18nDiModule.provideAppI18n(),
        refuellingOrdersI18n: this.refuellingOrdersI18nDiModule.provideRefuellingOrdersI18n(),
        getCarriersUseCase: this.refuellingOrdersDomainDiModule.provideGetCarriersForRefuellingOrdersUseCase(),
        getFuelsUseCase: this.refuellingOrdersDomainDiModule.provideGetFuelsForRefuellingOrdersUseCase(),
        getGasStationsUseCase: this.refuellingOrdersDomainDiModule.provideGetGasStationsForRefuellingOrdersUseCase(),
        getDriversUseCase: this.refuellingOrdersDomainDiModule.provideGetDriversForRefuellingOrdersUseCase(),
        getTransportsUseCase: this.refuellingOrdersDomainDiModule.provideGetTransportsForRefuellingOrdersUseCase(),
        getRefuellingOrderStatusesUseCase:
          this.refuellingOrdersDomainDiModule.provideGetRefuellingOrderStatusesForRefuellingOrdersUseCase(),
        getFuelCompaniesUseCase:
          this.refuellingOrdersDomainDiModule.provideGetFuelCompaniesForRefuellingOrdersUseCase()
      })
    )
  }

  provideClaimedRefuellingOrdersListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.refuellingOrdersDomainDiModule.provideGetRefuellingOrdersUseCase(),
      tableProvider: this.provideClaimedRefuellingOrdersTableProvider()
    })
  }

  private provideClaimedRefuellingOrdersTableProvider(): ClaimedRefuellingOrdersTableProvider {
    return this.single(
      ClaimedRefuellingOrdersTableProvider.name,
      () => new ClaimedRefuellingOrdersTableProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        appI18n: this.appI18nDiModule.provideAppI18n(),
        refuellingOrdersI18n: this.refuellingOrdersI18nDiModule.provideRefuellingOrdersI18n(),
        getCarriersUseCase: this.refuellingOrdersDomainDiModule.provideGetCarriersForRefuellingOrdersUseCase(),
        getFuelsUseCase: this.refuellingOrdersDomainDiModule.provideGetFuelsForRefuellingOrdersUseCase(),
        getGasStationsUseCase: this.refuellingOrdersDomainDiModule.provideGetGasStationsForRefuellingOrdersUseCase(),
        getDriversUseCase: this.refuellingOrdersDomainDiModule.provideGetDriversForRefuellingOrdersUseCase(),
        getTransportsUseCase: this.refuellingOrdersDomainDiModule.provideGetTransportsForRefuellingOrdersUseCase(),
        getFuelCompaniesUseCase:
          this.refuellingOrdersDomainDiModule.provideGetFuelCompaniesForRefuellingOrdersUseCase()
      })
    )
  }

  provideCancelledRefuellingOrdersListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.refuellingOrdersDomainDiModule.provideGetRefuellingOrdersUseCase(),
      tableProvider: this.provideCancelledRefuellingOrdersTableProvider()
    })
  }

  private provideCancelledRefuellingOrdersTableProvider(): CancelledRefuellingOrdersTableProvider {
    return this.single(
      CancelledRefuellingOrdersTableProvider.name,
      () => new CancelledRefuellingOrdersTableProvider({
        timeZone: this.timeZone,
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        appI18n: this.appI18nDiModule.provideAppI18n(),
        refuellingOrdersI18n: this.refuellingOrdersI18nDiModule.provideRefuellingOrdersI18n(),
        getCarriersUseCase: this.refuellingOrdersDomainDiModule.provideGetCarriersForRefuellingOrdersUseCase(),
        getFuelsUseCase: this.refuellingOrdersDomainDiModule.provideGetFuelsForRefuellingOrdersUseCase(),
        getGasStationsUseCase: this.refuellingOrdersDomainDiModule.provideGetGasStationsForRefuellingOrdersUseCase(),
        getDriversUseCase: this.refuellingOrdersDomainDiModule.provideGetDriversForRefuellingOrdersUseCase(),
        getTransportsUseCase: this.refuellingOrdersDomainDiModule.provideGetTransportsForRefuellingOrdersUseCase(),
        getFuelCompaniesUseCase:
          this.refuellingOrdersDomainDiModule.provideGetFuelCompaniesForRefuellingOrdersUseCase()
      })
    )
  }

  provideClaimedRefuellingOrderInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.refuellingOrdersDomainDiModule.provideGetRefuellingOrderUseCase(),
      infoProvider: this.provideClaimedRefuellingOrderInfoProvider()
    })
  }

  private provideClaimedRefuellingOrderInfoProvider(): ClaimedRefuellingOrderInfoProvider {
    return this.single(
      ClaimedRefuellingOrderInfoProvider.name,
      () => new ClaimedRefuellingOrderInfoProvider({
        refuellingOrdersI18n: this.refuellingOrdersI18nDiModule.provideRefuellingOrdersI18n(),
        appI18n: this.appI18nDiModule.provideAppI18n(),
        timeZone: this.timeZone
      })
    )
  }

  provideRefuellingOrderInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.refuellingOrdersDomainDiModule.provideGetRefuellingOrderUseCase(),
      infoProvider: this.provideRefuellingOrderInfoProvider()
    })
  }

  private provideRefuellingOrderInfoProvider(): RefuellingOrderInfoProvider {
    return this.single(
      RefuellingOrderInfoProvider.name,
      () => new RefuellingOrderInfoProvider({
        refuellingOrdersI18n: this.refuellingOrdersI18nDiModule.provideRefuellingOrdersI18n(),
        appI18n: this.appI18nDiModule.provideAppI18n(),
        timeZone: this.timeZone
      })
    )
  }

  provideRefuellingOrderFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.refuellingOrdersDomainDiModule.provideGetRefuellingOrderUseCase(),
      updateObjectUseCase: this.refuellingOrdersDomainDiModule.provideUpdateRefuellingOrderUseCase(),
      formProvider: this.provideRefuellingOrderFormProvider()
    })
  }

  private provideRefuellingOrderFormProvider(): RefuellingOrderFormProvider {
    return this.single(
      RefuellingOrderFormProvider.name,
      () => new RefuellingOrderFormProvider({
        getUserTypeUseCase: this.coreDomainDiModule.provideGetUserTypeUseCase(),
        refuellingOrdersI18n: this.refuellingOrdersI18nDiModule.provideRefuellingOrdersI18n()
      })
    )
  }

  provideCancelledRefuellingOrderInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.refuellingOrdersDomainDiModule.provideGetRefuellingOrderUseCase(),
      infoProvider: this.provideCancelledRefuellingOrderInfoProvider()
    })
  }

  private provideCancelledRefuellingOrderInfoProvider(): CancelledRefuellingOrderInfoProvider {
    return this.single(
      CancelledRefuellingOrderInfoProvider.name,
      () => new CancelledRefuellingOrderInfoProvider({
        refuellingOrdersI18n: this.refuellingOrdersI18nDiModule.provideRefuellingOrdersI18n(),
        appI18n: this.appI18nDiModule.provideAppI18n(),
        timeZone: this.timeZone
      })
    )
  }
}
