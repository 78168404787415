import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import CarrierCommercialTermsI18 from "../../i18n/CarrierCommercialTermsI18"
import CarrierCommercialTerm from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTerm"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import isBlank from "../../../../../admin/lib/isBlank"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import ArrayTableValue, {
  ArrayTableValueItem
} from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/ArrayTableValue"
import PricingType from "../../../../core/domain/commercial-terms/PricingType"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import CarrierCommercialTermsFilter from "../../../../core/domain/carrier-commercial-terms/CarrierCommercialTermsFilter"
import GetCarriersForCarrierCommercialTermsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForCarrierCommercialTermsUseCase"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import GasStationsFilter from "../../../../core/domain/gas-stations/GasStationsFilter"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import Carrier from "../../../../core/domain/carriers/Carrier"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GetUserTypeUseCase from "../../../../../admin/core/domain/use-cases/user-profile/GetUserTypeUseCase"
import { UserType } from "../../../../../admin/core/domain/entities/user-profile/UserType"

export default class CarrierCommercialTermsTableProvider
  implements TableProvider<CarrierCommercialTerm, CarrierCommercialTermsFilter> {
  private readonly coreI18n: CoreI18n
  private readonly carrierCommercialTermsI18: CarrierCommercialTermsI18
  private readonly getCarriersUseCase: GetCarriersForCarrierCommercialTermsUseCase
  private readonly getUserTypeUseCase: GetUserTypeUseCase

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly carrierCommercialTermsI18: CarrierCommercialTermsI18
    readonly getCarriersUseCase: GetCarriersForCarrierCommercialTermsUseCase
    readonly getUserTypeUseCase: GetUserTypeUseCase
  }) {
    this.coreI18n = parameters.coreI18n
    this.carrierCommercialTermsI18 = parameters.carrierCommercialTermsI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getUserTypeUseCase = parameters.getUserTypeUseCase
  }

  getEntity(): string {
    return Entity.CARRIER_COMMERCIAL_TERMS
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getTable(): Table<CarrierCommercialTerm, CarrierCommercialTermsFilter> {
    const appUrlProvider = new AppUrlProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()
    const carrierCommercialTermsTextProvider = this.carrierCommercialTermsI18.getTextProvider()

    return new Table<CarrierCommercialTerm, CarrierCommercialTermsFilter>({
      title: this.getTitle(),
      getObjectId: (carrierCommercialTerm: CarrierCommercialTerm) => carrierCommercialTerm.id!.toString(),
      getRowUrl: (carrierCommercialTerm: CarrierCommercialTerm) => appUrlProvider.buildShowCarrierCommercialTermUrl({
        carrierCommercialTermId: carrierCommercialTerm.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewCarrierCommercialTermUrl(),
      columns: [
        new TableColumn<CarrierCommercialTerm>({
          name: "id",
          title: carrierCommercialTermsTextProvider.idField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new TextTableValue({
            value: `${carrierCommercialTerm.id!}`
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "carrier",
          entity: Entity.CARRIERS,
          title: carrierCommercialTermsTextProvider.carrierField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new TextTableValue({
            value: carrierCommercialTerm.carrier?.name,
            url: (() => {
              const { carrier } = carrierCommercialTerm
              if (isBlank(carrier)) return null

              return appUrlProvider.buildShowCarrierUrl({
                carrierId: carrier.id!
              })
            })()
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "fuelCompany",
          entity: Entity.FUEL_COMPANIES,
          title: carrierCommercialTermsTextProvider.fuelCompanyField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new TextTableValue({
            value: carrierCommercialTerm.fuelCompany?.name,
            url: (() => {
              const { fuelCompany } = carrierCommercialTerm
              if (isBlank(fuelCompany)) return null

              return appUrlProvider.buildShowFuelCompanyUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })()
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "fuel",
          title: carrierCommercialTermsTextProvider.fuelField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new TextTableValue({
            value: carrierCommercialTerm.fuel?.name
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "gasStationIds",
          entity: Entity.GAS_STATIONS,
          title: carrierCommercialTermsTextProvider.gasStationsIdField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new ArrayTableValue({
            values: carrierCommercialTerm.gasStations?.map((gasStation): ArrayTableValueItem => {
              return {
                key: `${gasStation.id!}`,
                text: gasStation.name,
                url: appUrlProvider.buildShowGasStationUrl({
                  gasStationId: gasStation.id!
                })
              }
            })
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "beginDate",
          title: carrierCommercialTermsTextProvider.beginDateField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new DateTableValue({
            value: carrierCommercialTerm.beginDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "endDate",
          title: carrierCommercialTermsTextProvider.endDateField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new DateTableValue({
            value: carrierCommercialTerm.endDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "pricingType",
          title: carrierCommercialTermsTextProvider.pricingTypeField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new TextTableValue({
            value: (() => {
              switch (carrierCommercialTerm.pricingType) {
                case PricingType.FIXED:
                  return carrierCommercialTermsTextProvider.pricingTypePriceValue({
                    formattedPrice: carrierCommercialTerm.formattedPrice
                  })
                case PricingType.DISCOUNT:
                  return carrierCommercialTermsTextProvider.pricingTypeDiscountPercentageValue({
                    formattedDiscountPercentage: carrierCommercialTerm.formattedDiscountPercentage
                  })
                default:
                  return undefined
              }
            })()
          })
        }),
        new TableColumn<CarrierCommercialTerm>({
          name: "documentUrl",
          title: carrierCommercialTermsTextProvider.documentUrlField(),
          createValue: (carrierCommercialTerm: CarrierCommercialTerm) => new TextTableValue({
            value: carrierCommercialTerm.documentUrl,
            url: carrierCommercialTerm.documentUrl,
            openInNewTab: true
          })
        })
      ],
      filter: new Filter<CarrierCommercialTermsFilter>({
        buildFilterObject: (): CarrierCommercialTermsFilter => ({}),
        fields: [
          new SingleSelectFormField<GasStationsFilter, void, FuelCompany>({
            title: carrierCommercialTermsTextProvider.carrierFilter(),
            entity: Entity.CARRIERS,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "carrierId",
            getValue: (filter: CarrierCommercialTermsFilter) => filter.carrier,
            setValue: (
              filter: CarrierCommercialTermsFilter,
              carrier: Carrier | null
            ): CarrierCommercialTermsFilter => ({
              ...filter,
              carrier,
              carrierId: carrier?.id
            }),
            getObjectsUseCase: this.getCarriersUseCase,
            getOptionId: (optionObject: Carrier) => optionObject.id!.toString(),
            getOptionText: (optionObject: Carrier) => optionObject.name
          })
        ]
      })
    })
  }

  private getTitle() {
    const textProvider = this.carrierCommercialTermsI18.getTextProvider()
    const userType = this.getUserTypeUseCase.call()
    const isCarrierAppAdministrator = userType === UserType.CARRIER_APP_ADMINISTRATOR
    return isCarrierAppAdministrator ? textProvider.carrierAdministratorListTitle() : textProvider.defaultListTitle()
  }
}
