import { Expose, Type } from "class-transformer"
import NetworkGasStation from "../NetworkGasStation"

export default class NetworkGasStationRequestBody {

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStation?: NetworkGasStation | null

  constructor(parameters: {
    readonly gasStation?: NetworkGasStation | null
  }) {
    this.gasStation = parameters.gasStation
  }
}
