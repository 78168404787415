import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import CarriersForFuelCompanyTermCommercialTermsRepository from "../../repositories/CarriersForCarrierCommercialTermsRepository"

export interface GetCarriersForFuelCompanyCommercialTermsParameters extends GetObjectsParameters {
}

export default class GetCarriersForFuelCompanyCommercialTermsUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly carriersForFuelCompanyCommercialTermsRepository: CarriersForFuelCompanyTermCommercialTermsRepository

  constructor(parameters: {
    readonly carriersForFuelCompanyCommercialTermsRepository: CarriersForFuelCompanyTermCommercialTermsRepository
  }) {
    this.carriersForFuelCompanyCommercialTermsRepository = parameters.carriersForFuelCompanyCommercialTermsRepository
  }

  async call(parameters: GetCarriersForFuelCompanyCommercialTermsParameters): Promise<GetObjectsResult<FuelCompany>> {
    return await this.carriersForFuelCompanyCommercialTermsRepository.getCarriers(parameters)
  }
}
