import { Expose, Type } from "class-transformer"
import NetworkRefuellingOrder from "../NetworkRefuellingOrder"

export default class NetworkRefuellingOrderRequestBody {

  @Expose()
  @Type(() => NetworkRefuellingOrder)
  readonly refuellingOrder?: NetworkRefuellingOrder | null

  constructor(parameters: {
    readonly refuellingOrder?: NetworkRefuellingOrder | null
  }) {
    this.refuellingOrder = parameters.refuellingOrder
  }
}
