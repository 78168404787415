import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import CarrierAppAdministratorErrorsObjectAttributes
  from "../../../../../domain/carrier-app-administrators/CarrierAppAdministratorErrorsObjectAttributes"

export default class NetworkCarrierAppAdministratorErrorsObjectAttributes
  implements CarrierAppAdministratorErrorsObjectAttributes {

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly carrierId?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly isOwner?: NetworkAttributeError[] | null
}
