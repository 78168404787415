import { Expose, Transform, Type } from "class-transformer"
import NetworkRefuellingOrder from "../refuelling-orders/NetworkRefuellingOrder"
import transformDecimal from "../../network/transformers/transformDecimal"
import { Decimal } from "decimal.js"
import FuelTransactionStatus from "../../../domain/fuel-transactions/FuelTransactionStatus"

export default class NetworkFuelTransaction {
  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Number)
  readonly carrierCommercialTermId?: number | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyCommercialTermId?: number | null

  @Expose()
  @Transform(transformDecimal)
  readonly fuelValue?: Decimal | null

  @Expose()
  @Transform(transformDecimal)
  readonly moneyValue?: Decimal | null

  @Expose()
  @Transform(transformDecimal)
  readonly costValue?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly status?: FuelTransactionStatus | null

  @Expose()
  @Type(() => String)
  readonly formattedFuelValue?: string | null

  @Expose()
  @Type(() => String)
  readonly formattedMoneyValue?: string | null

  @Expose()
  @Type(() => String)
  readonly formattedCostValue?: string | null

  @Expose()
  @Type(() => NetworkRefuellingOrder)
  readonly refuellingOrder?: NetworkRefuellingOrder | null
}
