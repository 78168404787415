import GetCarrierReconciliationReportsUseCase
  from "../../domain/use-cases/carrier-reconciliation-reports/GetCarrierReconciliationReportsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CarrierReconciliationReportsDataDiModule from "./CarrierReconciliationReportsDataDiModule"
import CreateCarrierReconciliationReportUseCase
  from "../../domain/use-cases/carrier-reconciliation-reports/CreateCarrierReconciliationReportUseCase"
import GetCarriersForCarrierReconciliationReportsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForCarrierReconciliationReportsUseCase"

export default interface CarrierReconciliationReportsDomainDiModule {
  provideGetCarrierReconciliationReportsUseCase(): GetCarrierReconciliationReportsUseCase

  provideCreateCarrierReconciliationReportUseCase(): CreateCarrierReconciliationReportUseCase

  provideGetCarriersForRefuellingOrdersReportsUseCase(): GetCarriersForCarrierReconciliationReportsUseCase
}

export class DefaultCarrierReconciliationReportsDomainDiModule
  extends DiModule
  implements CarrierReconciliationReportsDomainDiModule {
  private readonly carrierReconciliationReportsDataDiModule: CarrierReconciliationReportsDataDiModule

  constructor(parameters: {
    readonly carrierReconciliationReportsDataDiModule: CarrierReconciliationReportsDataDiModule
  }) {
    super()
    this.carrierReconciliationReportsDataDiModule = parameters.carrierReconciliationReportsDataDiModule
  }

  provideGetCarrierReconciliationReportsUseCase(): GetCarrierReconciliationReportsUseCase {
    return this.single(
      GetCarrierReconciliationReportsUseCase.name,
      () => new GetCarrierReconciliationReportsUseCase({
        carrierReconciliationReportsRepository:
          this.carrierReconciliationReportsDataDiModule.provideCarrierReconciliationReportsRepository()
      })
    )
  }

  provideCreateCarrierReconciliationReportUseCase(): CreateCarrierReconciliationReportUseCase {
    return this.single(
      CreateCarrierReconciliationReportUseCase.name,
      () => new CreateCarrierReconciliationReportUseCase({
        carrierReconciliationReportsRepository:
          this.carrierReconciliationReportsDataDiModule.provideCarrierReconciliationReportsRepository()
      })
    )
  }

  provideGetCarriersForRefuellingOrdersReportsUseCase(): GetCarriersForCarrierReconciliationReportsUseCase {
    return this.single(
      GetCarriersForCarrierReconciliationReportsUseCase.name,
      () => new GetCarriersForCarrierReconciliationReportsUseCase({
        carriersForCarrierReconciliationReportsRepository:
          this.carrierReconciliationReportsDataDiModule.provideCarriersForCarrierReconciliationReportsRepository()
      })
    )
  }
}
