import GetObjectsUseCase, {
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PricingTypesForFuelCompanyCommercialTermsRepository
  from "../../repositories/PricingTypesForFuelCompanyCommercialTermsRepository"
import { PricingTypeSelectOption } from "../../../../../core/domain/commercial-terms/PricingType"

export default class GetPricingTypesForFuelCompanyCommercialTermsUseCase
  implements GetObjectsUseCase<PricingTypeSelectOption> {
  private readonly pricingTypesForFuelCompanyCommercialTermsRepository:
    PricingTypesForFuelCompanyCommercialTermsRepository

  constructor(parameters: {
    readonly pricingTypesForFuelCompanyCommercialTermsRepository: PricingTypesForFuelCompanyCommercialTermsRepository
  }) {
    this.pricingTypesForFuelCompanyCommercialTermsRepository =
      parameters.pricingTypesForFuelCompanyCommercialTermsRepository
  }

  async call(): Promise<GetObjectsResult<PricingTypeSelectOption>> {
    return await this.pricingTypesForFuelCompanyCommercialTermsRepository.getPricingTypes()
  }
}
