import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompany from "../../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesForFuelCompaniesCommercialTermsRepository from "../../repositories/FuelCompaniesForFuelCompaniesCommercialTermsRepository"

export interface GetFuelCompaniesForFuelCompanyCommercialTermsParameters extends GetObjectsParameters {
}

export default class GetFuelCompaniesForFuelCompanyCommercialTermsUseCase implements GetObjectsUseCase<FuelCompany> {
  private readonly fuelCompanyForFuelCompanyCommercialTermsRepository:
    FuelCompaniesForFuelCompaniesCommercialTermsRepository

  constructor(parameters: {
    readonly fuelCompanyForFuelCompanyCommercialTermsRepository: FuelCompaniesForFuelCompaniesCommercialTermsRepository
  }) {
    this.fuelCompanyForFuelCompanyCommercialTermsRepository =
      parameters.fuelCompanyForFuelCompanyCommercialTermsRepository
  }

  async call(
    parameters: GetFuelCompaniesForFuelCompanyCommercialTermsParameters
  ): Promise<GetObjectsResult<FuelCompany>> {
    return await this.fuelCompanyForFuelCompanyCommercialTermsRepository.getFuelCompanies(parameters)
  }
}
