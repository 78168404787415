import DriversI18 from "../../i18n/DriversI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import DriversTextProvider from "../../i18n/DriversTextProvider"

export default interface DriversI18nDiModule {
  provideDriversI18n(): DriversI18
}

export class DefaultDriversI18nDiModule
  extends I18nDiModule<DriversTextProvider>
  implements DriversI18nDiModule {

  provideDriversI18n(): DriversI18 {
    return this.single(
      DriversI18.name,
      () => new DriversI18(this.getI18nParameters())
    )
  }
}
