import TripsRepository from "../../domain/repositories/TripsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultTripsRepository from "../../data/repositories/DefaultTripsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import CarriersForTripsRepository from "../../domain/repositories/CarriersForTripsRepository"
import DefaultCarriersForTripsRepository from "../../data/repositories/DefaultCarriersForTripsRepository"
import FuelsForTripLimitsRepository from "../../domain/repositories/FuelsForTripLimitsRepository"
import DefaultFuelsForTripLimitsRepository from "../../data/repositories/DefaultFuelsForTripLimitsRepository"
import DriversForTripsRepository from "../../domain/repositories/DriversForTripsRepository"
import TransportsForTripsRepository from "../../domain/repositories/TransportsForTripsRepository"
import TripStatusesForTripsRepository from "../../domain/repositories/TripStatusesForTripsRepository"
import TripLimitTypesForTripLimitsRepository from "../../domain/repositories/TripLimitTypesForTripLimitsRepository"
import DefaultDriversForTripsRepository from "../../data/repositories/DefaultDriversForTripsRepository"
import DefaultTransportsForTripsRepository from "../../data/repositories/DefaultTransportsForTripsRepository"
import DefaultTripStatusesForTripsRepository from "../../data/repositories/DefaultTripStatusesForTripsRepository"
import DefaultTripLimitTypesForTripLimitsRepository
  from "../../data/repositories/DefaultTripLimitTypesForTripLimitsRepository"

export default interface TripsDataDiModule {
  provideTripsRepository(): TripsRepository

  provideCarriersForTripsRepository(): CarriersForTripsRepository

  provideDriversForTripsRepository(): DriversForTripsRepository

  provideTransportsForTripsRepository(): TransportsForTripsRepository

  provideTripStatusesForTripsRepository(): TripStatusesForTripsRepository

  provideFuelsForTripLimitsRepository(): FuelsForTripLimitsRepository

  provideTripLimitTypesForTripLimitsRepository(): TripLimitTypesForTripLimitsRepository
}

export class DefaultTripsDataDiModule extends DiModule implements TripsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideTripsRepository(): TripsRepository {
    return this.single(
      DefaultTripsRepository.name,
      () => new DefaultTripsRepository({
        tripsNetworkSource: this.appDataDiModule.provideTripsNetworkSource(),
        tripStatusesStaticSource: this.appDataDiModule.provideTripStatusesStaticSource()
      })
    )
  }

  provideCarriersForTripsRepository(): CarriersForTripsRepository {
    return this.single(
      DefaultCarriersForTripsRepository.name,
      () => new DefaultCarriersForTripsRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }

  provideDriversForTripsRepository(): DriversForTripsRepository {
    return this.single(
      DefaultDriversForTripsRepository.name,
      () => new DefaultDriversForTripsRepository({
        driversNetworkSource: this.appDataDiModule.provideDriversNetworkSource()
      })
    )
  }

  provideTransportsForTripsRepository(): TransportsForTripsRepository {
    return this.single(
      DefaultTransportsForTripsRepository.name,
      () => new DefaultTransportsForTripsRepository({
        transportsNetworkSource: this.appDataDiModule.provideTransportsNetworkSource()
      })
    )
  }

  provideTripStatusesForTripsRepository(): TripStatusesForTripsRepository {
    return this.single(
      DefaultTripStatusesForTripsRepository.name,
      () => new DefaultTripStatusesForTripsRepository({
        tripStatusesStaticSource: this.appDataDiModule.provideTripStatusesStaticSource()
      })
    )
  }

  provideFuelsForTripLimitsRepository(): FuelsForTripLimitsRepository {
    return this.single(
      DefaultFuelsForTripLimitsRepository.name,
      () => new DefaultFuelsForTripLimitsRepository({
        fuelsNetworkSource: this.appDataDiModule.provideFuelsNetworkSource()
      })
    )
  }

  provideTripLimitTypesForTripLimitsRepository(): TripLimitTypesForTripLimitsRepository {
    return this.single(
      DefaultTripLimitTypesForTripLimitsRepository.name,
      () => new DefaultTripLimitTypesForTripLimitsRepository({
        tripLimitTypesNetworkSource: this.appDataDiModule.provideTripLimitTypesNetworkSource()
      })
    )
  }
}
