import Presenter from "../../../../../lib/presenter/Presenter"
import SignInView from "./SignInView"
import IsSessionExistUseCase from "../../../../../core/domain/use-cases/sessions/IsSessionExistUseCase"
import CreateSessionUserPartial from "../../../../../core/domain/entities/session/CreateSessionUserPartial"
import CreateSessionUseCase, {
  CreateSessionError,
  CreateSessionResult
} from "../../../../../core/domain/use-cases/sessions/CreateSessionUseCase"
import assertNever from "../../../../../lib/assertNever"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"

export default class SignInPresenter extends Presenter<SignInView> {
  private readonly isSessionExistUseCase: IsSessionExistUseCase
  private readonly createSessionUseCase: CreateSessionUseCase
  private user: CreateSessionUserPartial
  private isAuthenticating: boolean
  private authenticationError?: CreateSessionError
  private authenticationFailureException?: ApplicationException

  constructor(parameters: {
    readonly isSessionExistUseCase: IsSessionExistUseCase
    readonly createSessionUseCase: CreateSessionUseCase
  }) {
    super()

    this.onEmailAddressChange = this.onEmailAddressChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onAuthenticateClicked = this.onAuthenticateClicked.bind(this)

    this.isSessionExistUseCase = parameters.isSessionExistUseCase
    this.createSessionUseCase = parameters.createSessionUseCase

    this.user = { emailAddress: "", password: "" }
    this.isAuthenticating = false
  }

  protected onFirstViewAttach() {
    super.onFirstViewAttach()
    this.checkIsCurrentUserAuthenticatedAndShowViewState()
  }

  onEmailAddressChange({ emailAddress }: { readonly emailAddress: string }) {
    this.setEmailAddress(emailAddress)
    this.showNotAuthenticatedSignInViewState()
  }

  onPasswordChange({ password }: { readonly password: string }) {
    this.setPassword(password)
    this.showNotAuthenticatedSignInViewState()
  }

  onAuthenticateClicked() {
    this.authenticateAndShowAuthenticatedViewState().then()
  }

  private checkIsCurrentUserAuthenticatedAndShowViewState() {
    const isSessionExist: boolean = this.isSessionExistUseCase.call()

    if (isSessionExist) {
      this.showAlreadyAuthenticatedSignInViewState()
    } else {
      this.showNotAuthenticatedSignInViewState()
    }
  }

  private async authenticateAndShowAuthenticatedViewState(): Promise<void> {
    this.setIsAuthenticating(true)
    this.setAuthenticationError(undefined)
    this.setAuthenticationFailureException(undefined)
    this.showNotAuthenticatedSignInViewState()

    const authenticationResult: CreateSessionResult =
      await this.createSessionUseCase.call({ user: this.user })

    this.setIsAuthenticating(false)

    switch (authenticationResult.type) {
      case "success":
        this.showNowAuthenticatedSignInViewState()
        break
      case "error":
        this.setAuthenticationError(authenticationResult.error)
        this.showNotAuthenticatedSignInViewState()
        break
      case "failure":
        this.setAuthenticationFailureException(authenticationResult.exception)
        this.showNotAuthenticatedSignInViewState()
        break
      default:
        assertNever(authenticationResult)
    }
  }

  private setEmailAddress(emailAddress: string) {
    this.user = {
      ...this.user,
      emailAddress
    }
    this.authenticationError = {
      ...this.authenticationError,
      errorsObject: {
        ...this.authenticationError?.errorsObject,
        attributes: {
          ...this.authenticationError?.errorsObject?.attributes,
          emailAddress: null
        }
      }
    }
  }

  private setPassword(password: string) {
    this.user = {
      ...this.user,
      password
    }
    this.authenticationError = {
      ...this.authenticationError,
      errorsObject: {
        ...this.authenticationError?.errorsObject,
        attributes: {
          ...this.authenticationError?.errorsObject?.attributes,
          password: null
        }
      }
    }
  }

  private setIsAuthenticating(isAuthenticating: boolean) {
    this.isAuthenticating = isAuthenticating
  }

  private setAuthenticationError(authenticationError: CreateSessionError | undefined) {
    this.authenticationError = authenticationError
  }

  private setAuthenticationFailureException(authenticationFailureException?: ApplicationException | undefined) {
    this.authenticationFailureException = authenticationFailureException
  }

  private showAlreadyAuthenticatedSignInViewState() {
    this.getView()?.showSignInViewState({
      type: "already_authenticated"
    })
  }

  private showNotAuthenticatedSignInViewState() {
    this.getView()?.showSignInViewState({
      type: "not_authenticated",
      user: this.user,
      isAuthenticating: this.isAuthenticating,
      authenticationError: this.authenticationError,
      authenticationFailureException: this.authenticationFailureException
    })
  }

  private showNowAuthenticatedSignInViewState() {
    this.getView()?.showSignInViewState({
      type: "now_authenticated"
    })
  }
}
