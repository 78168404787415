import InfoRow, { InfoRowParameters } from "../InfoRow"
import DateTimeFormat from "../../shared/DateTimeFormat"

export default class DateInfoRow extends InfoRow {
  readonly value: Date | null | undefined
  readonly format: DateTimeFormat
  readonly timeZone?: string

  constructor(parameters: InfoRowParameters & {
    readonly value?: Date | null
    readonly format?: DateTimeFormat
    readonly timeZone?: string
  }) {
    super(parameters)
    this.value = parameters.value
    this.format = parameters.format ?? DateTimeFormat.DATE_TIME
    this.timeZone = parameters.timeZone
  }
}
