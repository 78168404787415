import React from "react"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import styles from "../AuthenticationComponent.module.scss"
import TextInputComponent, { TextInputType } from "../../../../../lib/text-input/TextInputComponent"
import LabelComponent from "../../../../../lib/label/LabelComponent"
import ButtonComponent, { ButtonType } from "../../../../../lib/button/ButtonComponent"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import { useAuthenticationThemeProvider } from "../../contexts/AuthenticationThemeProviderContext"
import { useAuthenticationTextProvider } from "../../contexts/AuthenticationTextProviderContext"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"

interface Props {
  readonly onPasswordChanged: (password: string) => void
  readonly onPasswordConfirmationChanged: (passwordConfirmation: string) => void
  readonly onRecoveryPasswordButtonClicked: () => void
  readonly password: string
  readonly passwordConfirmation: string
  readonly isPasswordRecovering: boolean
  readonly passwordRecoveryError?: ExecutionError
  readonly passwordRecoveryFailureException?: ApplicationException
}

export default function PasswordRecoveryComponent({
  onPasswordChanged,
  onPasswordConfirmationChanged,
  onRecoveryPasswordButtonClicked,
  password,
  passwordConfirmation,
  isPasswordRecovering,
  passwordRecoveryError,
  passwordRecoveryFailureException
}: Props) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const authenticationTextProvider = useAuthenticationTextProvider()
  const theme = useCoreThemeProvider()
  const authenticationTheme = useAuthenticationThemeProvider()

  function handleOnSubmit(event: React.FormEvent) {
    event.preventDefault()
    onRecoveryPasswordButtonClicked()
  }

  return (
    <>
      <HelmetComponent
        title={authenticationTextProvider.newPassword()}
      />
      <div className={styles.root}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <div className={styles.form}>
            <img
              src={authenticationTheme.imAuthenticationLogoPath()}
              alt="imAuthenticationLogo"
            />
            <h1 className={`${styles.title} ${theme.headline1PrimaryClassName()}`}>
              {authenticationTextProvider.newPassword()}
            </h1>
            <div className={styles.formRow}>
              <LabelComponent
                htmlFor="password"
                text={authenticationTextProvider.password()}
                className={theme.label1PrimaryClassName()}
              />
              <TextInputComponent
                name="password"
                type={TextInputType.PASSWORD}
                value={password}
                isDisabled={isPasswordRecovering}
                onChange={(value) => {
                  onPasswordChanged(value)
                }}
                componentStyle={theme.input1PrimaryStyle()}
              />
            </div>
            <div className={styles.formRow}>
              <LabelComponent
                htmlFor="passwordConfirmation"
                text={authenticationTextProvider.passwordConfirmation()}
                className={theme.label1PrimaryClassName()}
              />
              <TextInputComponent
                name="passwordConfirmation"
                type={TextInputType.PASSWORD}
                value={passwordConfirmation}
                isDisabled={isPasswordRecovering}
                onChange={(value) => {
                  onPasswordConfirmationChanged(value)
                }}
                componentStyle={theme.input1PrimaryStyle()}
              />
            </div>
          </div>
          <div className={styles.submitButton}>
            <ButtonComponent
              type={ButtonType.SUBMIT}
              isDisabled={isPasswordRecovering}
              title={authenticationTextProvider.send()}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
          </div>
          {passwordRecoveryFailureException && (
            <div className={`${styles.errorMessage} ${theme.body3ErrorClassName()}`}>
              {coreTextProvider.somethingWentWrong()}
            </div>
          )}
          {passwordRecoveryError && passwordRecoveryError.message && (
            <div className={`${styles.errorMessage} ${theme.body3ErrorClassName()}`}>
              {passwordRecoveryError.message}
            </div>
          )}
        </form>
      </div>
    </>
  )
}
