import CarrierUsersRepository from "../../repositories/CarrierUsersRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import User from "../../../../../core/domain/users/User"
import UsersFilter from "../../../../../core/domain/users/UsersFilter"

export interface GetCarrierUsersParameters extends GetObjectsParameters<UsersFilter> {
}

export default class GetCarrierUsersUseCase implements GetObjectsUseCase<User> {
  private readonly carrierUsersRepository: CarrierUsersRepository

  constructor(parameters: {
    readonly carrierUsersRepository: CarrierUsersRepository
  }) {
    this.carrierUsersRepository = parameters.carrierUsersRepository
  }

  async call(parameters: GetCarrierUsersParameters): Promise<GetObjectsResult<User>> {
    return await this.carrierUsersRepository.getUsers(parameters)
  }
}
