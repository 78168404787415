import FuelCompanyCommercialTermsRepository from "../../domain/repositories/FuelCompanyCommercialTermsRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultFuelCompanyCommercialTermsRepository from "../../data/repositories/DefaultFuelCompanyCommercialTermsRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import FuelCompaniesForFuelCompaniesCommercialTermsRepository from "../../domain/repositories/FuelCompaniesForFuelCompaniesCommercialTermsRepository"
import DefaultFuelCompaniesForFuelCompanyCommercialTermsRepository
  from "../../data/repositories/DefaultFuelCompaniesForFuelCompanyCommercialTermsRepository"
import CarriersForFuelCompanyTermCommercialTermsRepository
  from "../../domain/repositories/CarriersForCarrierCommercialTermsRepository"
import DefaultCarriersForFuelCompanyCommercialTermsRepository
  from "../../data/repositories/DefaultCarriersForFuelCompanyCommercialTermsRepository"
import FuelsForFuelCompanyCommercialTermsRepository
  from "../../domain/repositories/FuelsForFuelCompanyCommercialTermsRepository"
import DefaultFuelsForFuelCompanyCommercialTermsRepository
  from "../../data/repositories/DefaultFuelsForFuelCompanyCommercialTermsRepository"
import GasStationsForFuelCompanyCommercialTermsRepository
  from "../../domain/repositories/GasStationsForFuelCompanyCommercialTermsRepository"
import DefaultGasStationsForFuelCompanyCommercialTermsRepository
  from "../../data/repositories/DefaultGasStationsForFuelCompanyCommercialTermsRepository"
import PricingTypesForFuelCompanyCommercialTermsRepository
  from "../../domain/repositories/PricingTypesForFuelCompanyCommercialTermsRepository"
import DefaultPricingTypesForFuelCompanyCommercialTermsRepository
  from "../../data/repositories/DefaultPricingTypesForFuelCompanyCommercialTermsRepository"

export default interface FuelCompanyCommercialTermsDataDiModule {
  provideFuelCompanyCommercialTermsRepository(): FuelCompanyCommercialTermsRepository

  provideCarriersForFuelCompanyCommercialTermsRepository(): CarriersForFuelCompanyTermCommercialTermsRepository

  provideFuelCompaniesForFuelCompanyCommercialTermsRepository(): FuelCompaniesForFuelCompaniesCommercialTermsRepository

  provideFuelsForFuelCompanyCommercialTermsRepository(): FuelsForFuelCompanyCommercialTermsRepository

  provideGasStationsForFuelCompanyCommercialTermsRepository(): GasStationsForFuelCompanyCommercialTermsRepository

  providePricingTypesForFuelCompanyCommercialTermsRepository(): PricingTypesForFuelCompanyCommercialTermsRepository
}

export class DefaultFuelCompanyCommercialTermsDataDiModule
  extends DiModule
  implements FuelCompanyCommercialTermsDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideFuelCompanyCommercialTermsRepository(): FuelCompanyCommercialTermsRepository {
    return this.single(
      DefaultFuelCompanyCommercialTermsRepository.name,
      () => new DefaultFuelCompanyCommercialTermsRepository({
        fuelCompanyCommercialTermsNetworkSource: this.appDataDiModule.provideFuelCompanyCommercialTermsNetworkSource(),
        pricingTypesStaticSource: this.appDataDiModule.providePricingTypesStaticSource()
      })
    )
  }

  provideCarriersForFuelCompanyCommercialTermsRepository(): CarriersForFuelCompanyTermCommercialTermsRepository {
    return this.single(
      DefaultCarriersForFuelCompanyCommercialTermsRepository.name,
      () => new DefaultCarriersForFuelCompanyCommercialTermsRepository({
        carriersNetworkSource: this.appDataDiModule.provideCarriersNetworkSource()
      })
    )
  }

  provideFuelCompaniesForFuelCompanyCommercialTermsRepository():
    FuelCompaniesForFuelCompaniesCommercialTermsRepository {
    return this.single(
      DefaultFuelCompaniesForFuelCompanyCommercialTermsRepository.name,
      () => new DefaultFuelCompaniesForFuelCompanyCommercialTermsRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }

  provideFuelsForFuelCompanyCommercialTermsRepository(): FuelsForFuelCompanyCommercialTermsRepository {
    return this.single(
      DefaultFuelsForFuelCompanyCommercialTermsRepository.name,
      () => new DefaultFuelsForFuelCompanyCommercialTermsRepository({
        fuelsNetworkSource: this.appDataDiModule.provideFuelsNetworkSource()
      })
    )
  }

  provideGasStationsForFuelCompanyCommercialTermsRepository(): GasStationsForFuelCompanyCommercialTermsRepository {
    return this.single(
      DefaultGasStationsForFuelCompanyCommercialTermsRepository.name,
      () => new DefaultGasStationsForFuelCompanyCommercialTermsRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }

  providePricingTypesForFuelCompanyCommercialTermsRepository(): PricingTypesForFuelCompanyCommercialTermsRepository {
    return this.single(
      DefaultPricingTypesForFuelCompanyCommercialTermsRepository.name,
      () => new DefaultPricingTypesForFuelCompanyCommercialTermsRepository({
        pricingTypesStaticSource: this.appDataDiModule.providePricingTypesStaticSource()
      })
    )
  }
}
