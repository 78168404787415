import FuelCompanyCommercialTermsRepository from "../../repositories/FuelCompanyCommercialTermsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import FuelCompanyCommercialTerm
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"
import FuelCompanyCommercialTermsFilter
  from "../../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTermsFilter"

export interface GetFuelCompanyCommercialTermsParameters
  extends GetObjectsParameters<FuelCompanyCommercialTermsFilter> {
}

export default class GetFuelCompanyCommercialTermsUseCase implements GetObjectsUseCase<FuelCompanyCommercialTerm> {
  private readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository

  constructor(parameters: {
    readonly fuelCompanyCommercialTermsRepository: FuelCompanyCommercialTermsRepository
  }) {
    this.fuelCompanyCommercialTermsRepository = parameters.fuelCompanyCommercialTermsRepository
  }

  async call(
    parameters: GetFuelCompanyCommercialTermsParameters
  ): Promise<GetObjectsResult<FuelCompanyCommercialTerm>> {
    return await this.fuelCompanyCommercialTermsRepository.getFuelCompanyCommercialTerms(parameters)
  }
}
