import GetFuelPricesUseCase from "../../domain/use-cases/fuel-prices/GetFuelPricesUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import FuelPricesDataDiModule from "./FuelPricesDataDiModule"
import CreateFuelPriceUseCase from "../../domain/use-cases/fuel-prices/CreateFuelPriceUseCase"
import GetFuelPriceUseCase from "../../domain/use-cases/fuel-prices/GetFuelPriceUseCase"
import UpdateFuelPriceUseCase from "../../domain/use-cases/fuel-prices/UpdateFuelPriceUseCase"
import GetGasStationsForFuelPriceUseCase from "../../domain/use-cases/gas-stations/GetGasStationsForFuelPriceUseCase"
import GetFuelsForFuelPriceUseCase from "../../domain/use-cases/fuels/GetFuelsForFuelPriceUseCase"

export default interface FuelPricesDomainDiModule {
  provideGetFuelPricesUseCase(): GetFuelPricesUseCase

  provideGetFuelPriceUseCase(): GetFuelPriceUseCase

  provideCreateFuelPriceUseCase(): CreateFuelPriceUseCase

  provideUpdateFuelPriceUseCase(): UpdateFuelPriceUseCase

  provideGetGasStationsForFuelPriceUseCase(): GetGasStationsForFuelPriceUseCase

  provideGetFuelsForFuelPriceUseCase(): GetFuelsForFuelPriceUseCase
}

export class DefaultFuelPricesDomainDiModule extends DiModule implements FuelPricesDomainDiModule {
  private readonly fuelPricesDataDiModule: FuelPricesDataDiModule

  constructor(parameters: {
    readonly fuelPricesDataDiModule: FuelPricesDataDiModule
  }) {
    super()
    this.fuelPricesDataDiModule = parameters.fuelPricesDataDiModule
  }

  provideGetFuelPricesUseCase(): GetFuelPricesUseCase {
    return this.single(
      GetFuelPricesUseCase.name,
      () => new GetFuelPricesUseCase({
        fuelPricesRepository: this.fuelPricesDataDiModule.provideFuelPricesRepository()
      })
    )
  }

  provideGetFuelPriceUseCase(): GetFuelPriceUseCase {
    return this.single(
      GetFuelPriceUseCase.name,
      () => new GetFuelPriceUseCase({
        fuelPricesRepository: this.fuelPricesDataDiModule.provideFuelPricesRepository()
      })
    )
  }

  provideCreateFuelPriceUseCase(): CreateFuelPriceUseCase {
    return this.single(
      CreateFuelPriceUseCase.name,
      () => new CreateFuelPriceUseCase({
        fuelPricesRepository: this.fuelPricesDataDiModule.provideFuelPricesRepository()
      })
    )
  }

  provideUpdateFuelPriceUseCase(): UpdateFuelPriceUseCase {
    return this.single(
      UpdateFuelPriceUseCase.name,
      () => new UpdateFuelPriceUseCase({
        fuelPricesRepository: this.fuelPricesDataDiModule.provideFuelPricesRepository()
      })
    )
  }

  provideGetGasStationsForFuelPriceUseCase(): GetGasStationsForFuelPriceUseCase {
    return this.single(
      GetGasStationsForFuelPriceUseCase.name,
      () => new GetGasStationsForFuelPriceUseCase({
        gasStationsForFuelPricesRepository: this.fuelPricesDataDiModule.provideGasStationsForFuelPricesRepository()
      })
    )
  }

  provideGetFuelsForFuelPriceUseCase(): GetFuelsForFuelPriceUseCase {
    return this.single(
      GetFuelsForFuelPriceUseCase.name,
      () => new GetFuelsForFuelPriceUseCase({
        fuelsForFuelPricesRepository: this.fuelPricesDataDiModule.provideFuelsForFuelPricesRepository()
      })
    )
  }
}
