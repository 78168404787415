import { Expose, Type } from "class-transformer"
import NetworkRefuellingOrdersRequestFilter from "./NetworkRefuellingOrdersRequestFilter"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkRefuellingOrdersRequestQuery {
  @Expose()
  @Type(() => NetworkRefuellingOrdersRequestFilter)
  readonly filter?: NetworkRefuellingOrdersRequestFilter | null

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  @Type(() => String)
  readonly sort?: string | null

  constructor(parameters: {
    readonly filter?: NetworkRefuellingOrdersRequestFilter | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.filter = parameters.filter
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
