import React, { Component } from "react"
import RootComponent from "../../components/root/RootComponent"
import RootView, { RootViewState } from "./RootView"
import RootPresenter from "./RootPresenter"
import { Navigate } from "react-router-dom"
import assertNever from "../../../../../lib/assertNever"
import { LocationContextProvider } from "../../../../../lib/presenter/useLocationContextProvider"
import RootMenuProvider from "../../services/RootMenuProvider"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import ContentLoadingComponent
  from "../../../../../core/presentation/components/content-loading/ContentLoadingComponent"
import styles from "./RootPage.module.scss"

interface Props {
  readonly providePresenter: () => RootPresenter
  readonly menuProvider: RootMenuProvider
  readonly locationContextProvider: LocationContextProvider
}

interface State {
  readonly rootViewState?: RootViewState
}

export default class RootPage extends Component<Props, State> implements RootView {
  private readonly presenter: RootPresenter
  private readonly menuProvider: RootMenuProvider

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
    this.menuProvider = props.menuProvider
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showRootViewState(rootViewState: RootViewState) {
    this.setState({ rootViewState })
  }

  render() {
    const { rootViewState } = this.state

    const coreUrlProvider = new CoreUrlProvider()

    return (
      <>
        {rootViewState && (() => {
          switch (rootViewState.type) {
            case "authenticated": {
              const menuItems = this.menuProvider.createLeftMenuItems({
                menuItems: rootViewState.menuItems
              })

              return (
                <RootComponent
                  menuItems={menuItems}
                  userProfile={rootViewState.userProfile}
                  onLogoutClicked={this.presenter.onLogoutClicked}
                  locationContextProvider={this.props.locationContextProvider}
                />
              )
            }
            case "loading":
              return (
                <div className={styles.loader}>
                  <ContentLoadingComponent />
                </div>
              )
            case "not_authenticated":
              return <Navigate to={coreUrlProvider.buildSignInUrl()} replace={true} />
            default:
              assertNever(rootViewState)
          }
        })()}
      </>
    )
  }
}
