import DriversRepository from "../../domain/repositories/DriversRepository"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import DriversNetworkSource from "../../../../core/data/sources/network/DriversNetworkSource"
import User from "../../../../core/domain/users/User"
import NetworkDriversRequestFilter
  from "../../../../core/data/entities/drivers/request-queries/NetworkDriversRequestFilter"
import { GetDriversParameters } from "../../domain/use-cases/drivers/GetDriversUseCase"
import UsersMapper from "../../../../core/data/mappers/UsersMapper"
import { GetDriverParameters } from "../../domain/use-cases/drivers/GetDriverUseCase"
import { CreateDriverParameters } from "../../domain/use-cases/drivers/CreateDriverUseCase"
import DriverError from "../../../../core/domain/drivers/DriverError"
import { UpdateDriverParameters } from "../../domain/use-cases/drivers/UpdateDriverUseCase"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultDriversRepository implements DriversRepository {
  private readonly driversNetworkSource: DriversNetworkSource

  constructor(parameters: {
    readonly driversNetworkSource: DriversNetworkSource
  }) {
    this.driversNetworkSource = parameters.driversNetworkSource
  }

  async getDrivers({
    filter,
    query,
    pagination,
    sort
  }: GetDriversParameters): Promise<GetObjectsResult<User>> {
    const carrierId = filter?.carrierId
    const result = await this.driversNetworkSource.getDrivers({
      filter: new NetworkDriversRequestFilter({
        query,
        carrierIds: isPresent(carrierId) ? [carrierId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.drivers!.map((driver) => {
              return new UsersMapper().mapNetworkToDomain({ user: driver })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getDriver({
    objectId
  }: GetDriverParameters): Promise<GetObjectResult<User>> {
    const result = await this.driversNetworkSource.getDriver({
      driverId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new UsersMapper().mapNetworkToDomain({
            user: result.data.driver!
          })
        }
      default:
        return result
    }
  }

  async createDriver({
    object: driver
  }: CreateDriverParameters): Promise<CreateObjectResult<User, DriverError>> {
    const mapper = new UsersMapper()
    const result = await this.driversNetworkSource.createDriver({
      driver: mapper.mapDomainToNetwork({ user: driver })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.driver!
          })
        }
      default:
        return result
    }
  }

  async updateDriver({
    objectId: driverId,
    object: driver
  }: UpdateDriverParameters): Promise<UpdateObjectResult<User, DriverError>> {
    const mapper = new UsersMapper()
    const result = await this.driversNetworkSource.updateDriver({
      driverId: parseInt(driverId),
      driver: mapper.mapDomainToNetwork({
        user: driver
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            user: result.data.driver!
          })
        }
      default:
        return result
    }
  }
}
