import React, { useEffect, useRef } from "react"
import styles from "./DropdownComponent.module.scss"
import getScrollParent from "../getScrollParent"
import assertNever from "../assertNever"
import isBlank from "../isBlank"

export enum DropdownWidthMode {
  MATCH_PARENT,
  WRAP_CONTENT_WITH_MAX_WIDTH_AS_PARENT_WIDTH,
  WRAP_CONTENT
}

export enum DropdownAlign {
  LEFT,
  RIGHT
}

export interface DropdownProps {
  className?: string
  isVisible: boolean
  children: React.ReactNode
  hasScroll?: boolean
  hideOnClickOutside?: boolean
  requestHide?: () => void
  widthMode?: DropdownWidthMode
  align?: DropdownAlign
}

const additionalSpacing = 15

export default function DropdownComponent({
  className = "",
  isVisible,
  children,
  hideOnClickOutside = false,
  hasScroll = true,
  requestHide,
  widthMode = DropdownWidthMode.MATCH_PARENT,
  align = DropdownAlign.LEFT
}: DropdownProps) {
  const dropdownElementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!isVisible) {
      return
    }

    setSizeMetrics()
    setPositionMetrics()

    return initMetricsSettingOnWindowResize()
  }, [isVisible])

  useEffect(() => {
    if (isVisible) {
      return initPositionMetricsSettingOnParentScroll()
    }
  }, [isVisible])

  useEffect(() => {
    if (!isVisible) {
      return
    }

    if (!hideOnClickOutside) {
      return
    }

    return initDropdownHidingWhenClickedOutside()
  }, [isVisible, requestHide])

  if (!isVisible) {
    return <></>
  }

  function initDropdownHidingWhenClickedOutside() {
    const listener = (event: MouseEvent) => {
      if (isBlank(requestHide)) {
        return
      }

      const isClickedOutside = (): boolean => {
        let element: EventTarget | null = event.target

        while (element !== null) {
          if (element === dropdownElementRef.current) {
            return false
          }

          if (element instanceof Element) {
            element = element.parentNode
          } else {
            break
          }
        }

        return true
      }

      if (isClickedOutside()) {
        requestHide()
      }
    }

    window.addEventListener("mousedown", listener)

    return () => {
      window.removeEventListener("mousedown", listener)
    }
  }

  function initPositionMetricsSettingOnParentScroll() {
    const dropdownElement: HTMLElement | null = dropdownElementRef.current

    if (dropdownElement === null) {
      return
    }

    const dropdownElementScrollParent: HTMLElement | null = getScrollParent(dropdownElement)

    if (dropdownElementScrollParent === null) {
      return
    }

    const onScrollListener = () => {
      setPositionMetrics()
    }

    dropdownElementScrollParent.addEventListener("scroll", onScrollListener)

    return () => {
      dropdownElementScrollParent.removeEventListener("scroll", onScrollListener)
    }
  }

  function initMetricsSettingOnWindowResize() {
    const onResize = () => {
      setSizeMetrics()
      setPositionMetrics()
    }

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }

  function setPositionMetrics() {
    if (!hasScroll) {
      return
    }

    const dropdownElement: HTMLElement | null = dropdownElementRef.current

    if (dropdownElement === null) {
      return
    }

    const anchor: HTMLElement | null = dropdownElement.parentElement

    if (anchor === null) {
      return
    }

    const anchorBoundingClientRect: DOMRect = anchor.getBoundingClientRect()

    const anchorTopOffsetOnWindow: number = anchorBoundingClientRect.top
    const anchorHeight: number = anchor.offsetHeight
    const dropdownTopOffset: number = anchorTopOffsetOnWindow + anchorHeight
    const windowHeight: number = window.innerHeight
    const dropdownBottomOffset: number = windowHeight - anchorTopOffsetOnWindow
    const anchorLeftOffsetOnWindow: number = anchorBoundingClientRect.left
    const anchorStyle: CSSStyleDeclaration = window.getComputedStyle(anchor)
    const anchorPaddingLeft: number = parseInt(anchorStyle.getPropertyValue("padding-left"))
    const dropdownInverse: boolean = anchorTopOffsetOnWindow > windowHeight / 2.0

    if (dropdownInverse) {
      dropdownElement.style.top = ""
      dropdownElement.style.bottom = `${dropdownBottomOffset}px`
      dropdownElement.style.maxHeight = `${windowHeight - additionalSpacing - dropdownBottomOffset}px`
    } else {
      dropdownElement.style.top = `${dropdownTopOffset}px`
      dropdownElement.style.bottom = ""
      dropdownElement.style.maxHeight = `${windowHeight - additionalSpacing - dropdownTopOffset}px`
    }

    switch (align) {
      case DropdownAlign.LEFT: {
        const dropdownLeftOffset: number = anchorLeftOffsetOnWindow + anchorPaddingLeft
        dropdownElement.style.left = `${dropdownLeftOffset}px`
        break
      }
      case DropdownAlign.RIGHT: {
        const dropdownLeftOffset: number =
          anchorLeftOffsetOnWindow +
          anchor.offsetWidth -
          anchorPaddingLeft -
          dropdownElement.offsetWidth

        dropdownElement.style.left = `${dropdownLeftOffset}px`
        break
      }
      default:
        assertNever(align)
    }
  }

  function setSizeMetrics() {
    const dropdownElement: HTMLElement | null = dropdownElementRef.current

    if (dropdownElement === null) {
      return
    }

    const parentElement: HTMLElement | null = dropdownElement.parentElement

    if (parentElement === null) {
      return
    }

    const parentStyle: CSSStyleDeclaration = window.getComputedStyle(parentElement)
    const parentPaddingRight: number = parseInt(parentStyle.getPropertyValue("padding-right"))
    const parentPaddingLeft: number = parseInt(parentStyle.getPropertyValue("padding-left"))
    const innerParentWidth: number = parentElement.offsetWidth - parentPaddingLeft - parentPaddingRight

    switch (widthMode) {
      case DropdownWidthMode.MATCH_PARENT:
        dropdownElement.style.width = `${innerParentWidth}px`
        break
      case DropdownWidthMode.WRAP_CONTENT_WITH_MAX_WIDTH_AS_PARENT_WIDTH:
        dropdownElement.style.maxWidth = `${innerParentWidth}px`
        break
      case DropdownWidthMode.WRAP_CONTENT:
        break
      default:
        assertNever(widthMode)
    }
  }

  function onDropdownElementRendered(element: HTMLDivElement | null) {
    dropdownElementRef.current = element

    if (element !== null) {
      setPositionMetrics()
    }
  }

  return (
    <div
      ref={(element: HTMLDivElement | null) => onDropdownElementRendered(element)}
      className={`${styles.root} ${hasScroll ? styles.hasScroll : styles.noScroll} ${className}`}
      // onMouseDown={(event) => event.stopPropagation()}
    >
      {children}
    </div>
  )
}
