import React from "react"
import AppSelectPickerComponent
  from "../../../../../../core/presentation/components/app-select-picker/AppSelectPickerComponent"
import { SingleSelectFormFieldViewState } from "../../../entities/forms/form-field-by-type/SingleSelectFormField"
import LabelComponent from "../../../../../../lib/label/LabelComponent"
import styles from "./SingleSelectFormFieldComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../../core/presentation/contexts/CoreThemeProviderContext"
import ErrorsContainerComponent from "../../../../../../lib/errors-container/ErrorsContainerComponent"

export default function SingleSelectFormFieldComponent({
  fieldViewState,
  isDisabled
}: {
  readonly fieldViewState: SingleSelectFormFieldViewState<unknown>
  readonly isDisabled: boolean
}) {
  const {
    selectedOption,
    options,
    isLoading,
    query,
    page,
    searchingEnabled,
    onOpened,
    onSelect,
    onQueryChanged,
    onSearchRequested,
    onNextPageRequested
  } = fieldViewState

  const theme = useCoreThemeProvider()
  const label = fieldViewState.getTitle()
  const name = fieldViewState.getId()
  const isRequired = fieldViewState.getRequired()
  const isClearable = fieldViewState.getClearable()
  const errors = fieldViewState.getErrors()
  const placeholder = fieldViewState.getPlaceholder()
  const isEditable = fieldViewState.getEditable() ?? true

  return (
    <div className={styles.root}>
      {label && name && (
        <LabelComponent
          htmlFor={name}
          text={`${label}${isRequired ? " *" : ""}`}
          className={theme.label1PrimaryClassName()}
        />
      )}
      <AppSelectPickerComponent
        hasErrors={(errors && errors.length > 0) ?? false}
        clearable={isClearable}
        searchingEnabled={searchingEnabled}
        selectedOption={selectedOption}
        placeholder={placeholder}
        options={options}
        disabled={isDisabled || !isEditable}
        isLoading={isLoading}
        query={query}
        page={page}
        onChange={onSelect}
        onOpened={onOpened}
        onQueryChanged={onQueryChanged}
        onSearchRequested={onSearchRequested}
        onNextPageRequested={onNextPageRequested}
      />
      <ErrorsContainerComponent
        errors={errors ?? []}
        errorMessageClassName={theme.body3ErrorClassName()}
      />
    </div>
  )
}
