import { Expose, Type } from "class-transformer"
import TripStatus from "../../../../domain/trips/TripStatus"

export default class NetworkTripsRequestFilter {
  @Expose()
  @Type(() => String)
  readonly ids?: string[] | null

  @Expose()
  @Type(() => String)
  readonly query?: string | null

  @Expose()
  @Type(() => Number)
  readonly carrierIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly transportIds?: number[] | null

  @Expose()
  @Type(() => Number)
  readonly driverIds?: number[] | null

  @Expose()
  @Type(() => String)
  readonly dateFrom?: string | null

  @Expose()
  @Type(() => String)
  readonly dateTo?: string | null

  @Expose()
  @Type(() => String)
  readonly statuses?: TripStatus[] | null

  constructor(parameters: {
    readonly ids?: string[] | null
    readonly carrierIds?: number[] | null
    readonly driverIds?: number[] | null
    readonly transportIds?: number[] | null
    readonly dateFrom?: string | null
    readonly dateTo?: string | null
    readonly statuses?: TripStatus[] | null
    readonly query?: string | null
  }) {
    this.ids = parameters.ids
    this.driverIds = parameters.driverIds
    this.carrierIds = parameters.carrierIds
    this.transportIds = parameters.transportIds
    this.dateFrom = parameters.dateFrom
    this.dateTo = parameters.dateTo
    this.statuses = parameters.statuses
    this.query = parameters.query
  }
}
