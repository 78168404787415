import BackendHttpClient, { BackendHttpClientResult } from "../../../../../../app/core/data/network/BackendHttpClient"
import { plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../../../../../app/core/data/entities/errors/NetworkExecutionError"
import { HttpRequestType } from "../../../../../lib/http-client/HttpClient"
import NetworkMenuResponseBody
  from "../../entities/menus/response-bodies/NetworkMenuResponseBody"
import SuccessExecutionResult from "../../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../../admin/core/domain/results/FailureExecutionResult"

const basePath = "/api/admin/menus"

export default class MenusNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getMenu({
    menuId
  }: {
    readonly menuId: string
  }): Promise<GetMenusNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${menuId}`
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkMenuResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetMenusNetworkResult =
  SuccessExecutionResult<NetworkMenuResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
