import FuelCompanyBalanceChangeDocumentFormPage from "./FuelCompanyBalanceChangeDocumentFormPage"
import React from "react"
import FuelCompanyBalanceChangeDocumentFormPresenter from "./FuelCompanyBalanceChangeDocumentFormPresenter"
import { Params, useNavigate, useParams } from "react-router-dom"
import useLocationContext, { LocationContext } from "../../../../../../admin/lib/presenter/useLocationContext"
import { useCoreTextProvider } from "../../../../../../admin/core/presentation/contexts/CoreTextProviderContext"
import { fuelCompanyIdUrlPart } from "../../../../../core/presentation/constants/urlParts"

interface Props {
  readonly providePresenter: (parameters: {
    readonly fuelCompanyId: string | number
  }) => FuelCompanyBalanceChangeDocumentFormPresenter
}

export default function FuelCompanyBalanceChangeDocumentFormPageRouteElement({
  providePresenter
}: Props) {
  const urlParams: Readonly<Params<string>> = useParams()
  const fuelCompanyId: string | null = urlParams[fuelCompanyIdUrlPart]!
  const locationContext: LocationContext = useLocationContext()
  const navigate = useNavigate()
  const coreTextProvider = useCoreTextProvider()

  return (
    <FuelCompanyBalanceChangeDocumentFormPage
      key={locationContext.locationId}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            fuelCompanyId
          })
        })
      }}
      coreTextProvider={coreTextProvider}
      navigate={navigate}
    />
  )
}
