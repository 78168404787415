import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkTripLimitTypesResponseBody
  from "../../entities/trip-limits-types/response-bodies/NetworkTripLimitTypesResponseBody"
import NetworkTripLimitTypesRequestQuery
  from "../../entities/trip-limits-types/request-queries/NetworkTripLimitTypesRequestQuery"

const basePath = "/api/admin/trip_limit_types"

export default class TripLimitTypesNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getTripLimitTypes({
    pagination
  }: {
    readonly pagination?: NetworkLastItemPagination | null
  }): Promise<GetTripLimitTypesNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkTripLimitTypesRequestQuery({ pagination }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkTripLimitTypesResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetTripLimitTypesNetworkResult =
  SuccessExecutionResult<NetworkTripLimitTypesResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
