import GetObjectUseCase, {
  GetObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelPricesRepository from "../../repositories/FuelPricesRepository"
import FuelPrice from "../../../../../core/domain/fuel-prices/FuelPrice"

export interface GetFuelPriceParameters {
  readonly objectId: string
}

export default class GetFuelPriceUseCase implements GetObjectUseCase<FuelPrice> {
  private readonly fuelPricesRepository: FuelPricesRepository

  constructor(parameters: {
    readonly fuelPricesRepository: FuelPricesRepository
  }) {
    this.fuelPricesRepository = parameters.fuelPricesRepository
  }

  async call(parameters: GetFuelPriceParameters): Promise<GetObjectResult<FuelPrice>> {
    return await this.fuelPricesRepository.getFuelPrice(parameters)
  }
}
