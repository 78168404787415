import React from "react"
import { useAuthenticationTextProvider } from "../../contexts/AuthenticationTextProviderContext"
import AuthenticationTextProvider from "../../../i18n/AuthenticationTextProvider"
import CreateSessionUserPartial from "../../../../../core/domain/entities/session/CreateSessionUserPartial"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { CreateSessionError } from "../../../../../core/domain/use-cases/sessions/CreateSessionUseCase"

import styles from "../AuthenticationComponent.module.scss"
import TextInputComponent, { TextInputType } from "../../../../../lib/text-input/TextInputComponent"
import LabelComponent from "../../../../../lib/label/LabelComponent"
import ErrorsContainerComponent from "../../../../../lib/errors-container/ErrorsContainerComponent"
import ButtonComponent, { ButtonType } from "../../../../../lib/button/ButtonComponent"
import LinkButtonComponent from "../../../../../lib/button/LinkButtonComponent"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import { useAuthenticationThemeProvider } from "../../contexts/AuthenticationThemeProviderContext"
import HelmetComponent from "../../../../../core/presentation/components/helmet/HelmetComponent"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import AuthenticationLinkState from "../../entities/AuthenticationLinkState"

interface Props {
  readonly onEmailAddressChanged: (parameters: { readonly emailAddress: string }) => void
  readonly onPasswordChanged: (parameters: { readonly password: string }) => void
  readonly onAuthenticateClicked: () => void
  readonly user: CreateSessionUserPartial
  readonly isAuthenticating: boolean
  readonly authenticationError?: CreateSessionError
  readonly authenticationFailureException?: ApplicationException
}

export default function SignInComponent({
  onEmailAddressChanged,
  onPasswordChanged,
  onAuthenticateClicked,
  user,
  isAuthenticating,
  authenticationError,
  authenticationFailureException
}: Props) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const authenticationTextProvider: AuthenticationTextProvider = useAuthenticationTextProvider()
  const urlProvider = new CoreUrlProvider()

  const theme = useCoreThemeProvider()
  const authenticationTheme = useAuthenticationThemeProvider()

  const isFormDisabled = isAuthenticating
  const emailAddressAttributeErrors = authenticationError?.errorsObject?.attributes?.emailAddress
  const passwordAttributeErrors = authenticationError?.errorsObject?.attributes?.password

  function handleOnSubmit(event: React.FormEvent) {
    event.preventDefault()
    onAuthenticateClicked()
  }

  return (
    <>
      <HelmetComponent
        title={authenticationTextProvider.authentication()}
      />
      <div className={styles.root}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <div className={styles.form}>
            <img
              src={authenticationTheme.imAuthenticationLogoPath()}
              alt="ImAuthenticationLogo"
            />
            <h1 className={`${styles.title} ${theme.headline1PrimaryClassName()}`}>
              {authenticationTextProvider.authentication()}
            </h1>
            <div className={styles.formRow}>
              <LabelComponent
                htmlFor="email"
                text={authenticationTextProvider.emailAddress()}
                className={theme.label1PrimaryClassName()}
              />
              <TextInputComponent
                name="email"
                type={TextInputType.EMAIL}
                value={user.emailAddress}
                onChange={(value) => {
                  onEmailAddressChanged({ emailAddress: value })
                }}
                componentStyle={theme.input1PrimaryStyle()}
                hasErrors={(emailAddressAttributeErrors && emailAddressAttributeErrors.length > 0) ?? false}
              />
              <ErrorsContainerComponent
                errors={emailAddressAttributeErrors ?? []}
                errorMessageClassName={theme.body3ErrorClassName()}
              />
            </div>
            <div className={styles.formRow}>
              <LabelComponent
                htmlFor="password"
                text={authenticationTextProvider.password()}
                className={theme.label1PrimaryClassName()}
              />
              <TextInputComponent
                name="password"
                type={TextInputType.PASSWORD}
                value={user.password}
                onChange={(value) => {
                  onPasswordChanged({ password: value })
                }}
                componentStyle={theme.input1PrimaryStyle()}
                hasErrors={(passwordAttributeErrors && passwordAttributeErrors.length > 0) ?? false}
              />
              <ErrorsContainerComponent
                errors={passwordAttributeErrors ?? []}
                errorMessageClassName={theme.body3ErrorClassName()}
              />
            </div>
          </div>
          <div className={styles.submitRow}>
            <ButtonComponent
              type={ButtonType.SUBMIT}
              isDisabled={isFormDisabled}
              title={authenticationTextProvider.authenticate()}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
            <LinkButtonComponent<AuthenticationLinkState>
              state={{
                email: user.emailAddress
              }}
              url={urlProvider.buildPasswordRecoveryRequestUrl()}
              isDisabled={isFormDisabled}
              title={authenticationTextProvider.forgotPassword()}
              componentStyles={theme.buttonFilled1PrimaryStyle()}
            />
          </div>
          {authenticationFailureException && (
            <div className={`${styles.errorMessage} ${theme.body3ErrorClassName()}`}>{coreTextProvider.somethingWentWrong()}</div>
          )}
          {authenticationError && authenticationError.message && (
            <div className={`${styles.errorMessage} ${theme.body3ErrorClassName()}`}>{authenticationError.message}</div>
          )}
        </form>
      </div>
    </>
  )
}
