import DriversRepository from "../../repositories/DriversRepository"
import CreateObjectUseCase, {
  CreateObjectResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import User from "../../../../../core/domain/users/User"
import DriverError from "../../../../../core/domain/drivers/DriverError"

export interface CreateDriverParameters {
  readonly object: User
}

export default class CreateDriverUseCase implements CreateObjectUseCase<User, DriverError> {
  private readonly driversRepository: DriversRepository

  constructor(parameters: {
    readonly driversRepository: DriversRepository
  }) {
    this.driversRepository = parameters.driversRepository
  }

  async call(parameters: CreateDriverParameters): Promise<CreateObjectResult<User, DriverError>> {
    return await this.driversRepository.createDriver(parameters)
  }
}
