import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import FuelCompanyCommercialTermsI18 from "../../i18n/FuelCompanyCommercialTermsI18"
import GetFuelCompaniesForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/fuel-companies/GetFuelCompaniesForFuelCompanyCommercialTermsUseCase"
import GetCarriersForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/carriers/GetCarriersForFuelCompanyCommercialTermsUseCase"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import Carrier from "../../../../core/domain/carriers/Carrier"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import GetFuelsForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/fuels/GetFuelsForFuelCompanyCommercialTermsUseCase"
import Fuel from "../../../../core/domain/fuels/Fuel"
import MultiSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/MultiSelectFormField"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import GetGasStationsForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/gas-stations/GetGasStationsForFuelCompanyCommercialTermsUseCase"
import GasStationsFilter from "../../../../core/domain/gas-stations/GasStationsFilter"
import GetPricingTypesForFuelCompanyCommercialTermsUseCase
  from "../../domain/use-cases/pricing-types/GetPricingTypesForFuelCompanyCommercialTermsUseCase"
import PricingType, { PricingTypeSelectOption } from "../../../../core/domain/commercial-terms/PricingType"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import DecimalFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import AttributeError from "../../../../../admin/core/domain/entities/errors/AttributeError"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FuelCompanyCommercialTerm from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTerm"
import FuelCompanyCommercialTermError
  from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTermError"
import FuelCompanyCommercialTermErrorsObject
  from "../../../../core/domain/fuel-company-commercial-terms/FuelCompanyCommercialTermErrorsObject"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"

const fuelCompanyCommercialTermObjectType = "fuelCompanyCommercialTerm"

export default class FuelCompanyCommercialTermFormProvider
  implements FormProvider<
    FuelCompanyCommercialTerm, FuelCompanyCommercialTermError, FuelCompanyCommercialTermErrorsObject
  > {
  private readonly timeZone: string
  private readonly coreI18n: CoreI18n
  private readonly fuelCompanyCommercialTermsI18: FuelCompanyCommercialTermsI18
  private readonly getCarriersUseCase: GetCarriersForFuelCompanyCommercialTermsUseCase
  private readonly getFuelCompaniesUseCase: GetFuelCompaniesForFuelCompanyCommercialTermsUseCase
  private readonly getFuelsUseCase: GetFuelsForFuelCompanyCommercialTermsUseCase
  private readonly getGasStationsUseCase: GetGasStationsForFuelCompanyCommercialTermsUseCase
  private readonly getPricingTypesUseCase: GetPricingTypesForFuelCompanyCommercialTermsUseCase

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18n: CoreI18n
    readonly fuelCompanyCommercialTermsI18: FuelCompanyCommercialTermsI18
    readonly getCarriersUseCase: GetCarriersForFuelCompanyCommercialTermsUseCase
    readonly getFuelCompaniesUseCase: GetFuelCompaniesForFuelCompanyCommercialTermsUseCase
    readonly getFuelsUseCase: GetFuelsForFuelCompanyCommercialTermsUseCase
    readonly getGasStationsUseCase: GetGasStationsForFuelCompanyCommercialTermsUseCase
    readonly getPricingTypesUseCase: GetPricingTypesForFuelCompanyCommercialTermsUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.coreI18n = parameters.coreI18n
    this.fuelCompanyCommercialTermsI18 = parameters.fuelCompanyCommercialTermsI18
    this.getCarriersUseCase = parameters.getCarriersUseCase
    this.getFuelCompaniesUseCase = parameters.getFuelCompaniesUseCase
    this.getFuelsUseCase = parameters.getFuelsUseCase
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
    this.getPricingTypesUseCase = parameters.getPricingTypesUseCase
  }

  getEntity(): string {
    return Entity.FUEL_COMPANY_COMMERCIAL_TERMS
  }

  getNewObjectTitle(): string {
    return this.fuelCompanyCommercialTermsI18.getTextProvider()
      .newObjectTitle()
  }

  getExistedObjectShortTitle(_parameters: {
    readonly object?: FuelCompanyCommercialTerm
  }): string {
    return this.fuelCompanyCommercialTermsI18.getTextProvider()
      .existObjectTitle()
  }

  getExistedObjectTitle(_parameters: {
    readonly object?: FuelCompanyCommercialTerm
  }): string {
    return this.fuelCompanyCommercialTermsI18.getTextProvider()
      .existObjectTitle()
  }

  async buildObject(): Promise<FuelCompanyCommercialTerm> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: FuelCompanyCommercialTermError
  }): FuelCompanyCommercialTermErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject>[] {
    const fuelCompanyCommercialTermsTextProvider = this.fuelCompanyCommercialTermsI18.getTextProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject, FuelCompany>({
        title: fuelCompanyCommercialTermsTextProvider.fuelCompanyField(),
        clearable: true,
        placeholder: coreTextProvider.unlimited(),
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelCompaniesUseCase,
        getErrors: (errorsObject?: FuelCompanyCommercialTermErrorsObject) => errorsObject?.attributes?.fuelCompanyId,
        getId: () => "fuelCompanyId",
        getOptionId: (fuelCompany: FuelCompany) => fuelCompany.id!.toString(),
        getOptionText: (fuelCompany: FuelCompany) => fuelCompany.name,
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.fuelCompany,
        setValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm, fuelCompany: FuelCompany | null) => {
          const previousFuelCompanyId = fuelCompanyCommercialTerm.fuelCompanyId
          let gasStations = fuelCompanyCommercialTerm.gasStations ?? []
          if (previousFuelCompanyId !== fuelCompany?.id) {
            gasStations = []
          }

          return {
            ...fuelCompanyCommercialTerm,
            fuelCompany,
            fuelCompanyId: fuelCompany?.id ?? null,
            gasStations,
            gasStationIds: gasStations?.map((gasStation) => gasStation.id!) ?? []
          }
        }
      }),
      new SingleSelectFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject, Carrier>({
        title: fuelCompanyCommercialTermsTextProvider.carrierField(),
        clearable: true,
        placeholder: coreTextProvider.unlimited(),
        groupName: mainGroupName,
        getObjectsUseCase: this.getCarriersUseCase,
        getErrors: (errorsObject?: FuelCompanyCommercialTermErrorsObject) => errorsObject?.attributes?.carrierId,
        getId: () => "carrierId",
        getOptionId: (carrier: Carrier) => carrier.id!.toString(),
        getOptionText: (carrier: Carrier) => carrier.name,
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.carrier,
        setValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm,
          carrier: Carrier | null
        ) => ({
          ...fuelCompanyCommercialTerm,
          carrier,
          carrierId: carrier?.id ?? null
        })
      }),
      new SingleSelectFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject, Fuel>({
        title: fuelCompanyCommercialTermsTextProvider.fuelField(),
        clearable: false,
        required: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelsUseCase,
        getErrors: (errorsObject?: FuelCompanyCommercialTermErrorsObject) => errorsObject?.attributes?.fuelId,
        getId: () => "fuelId",
        getOptionId: (fuel: Fuel) => fuel.id!.toString(),
        getOptionText: (fuel: Fuel) => fuel.name,
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.fuel,
        setValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm, fuel: Fuel | null) => ({
          ...fuelCompanyCommercialTerm,
          fuel,
          fuelId: fuel?.id ?? null
        })
      }),
      new MultiSelectFormField<
        FuelCompanyCommercialTerm,
        FuelCompanyCommercialTermErrorsObject,
        GasStation,
        GasStationsFilter
      >({
        title: fuelCompanyCommercialTermsTextProvider.gasStationsIdField(),
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getGasStationsUseCase,
        getErrors: (errorsObject?: FuelCompanyCommercialTermErrorsObject) => errorsObject?.attributes?.gasStationIds,
        getId: () => "gasStationIds",
        getOptionId: (gasStation: GasStation) => gasStation.id!.toString(),
        getOptionText: (gasStation: GasStation) => gasStation.name,
        getOptionObjectsFilter: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => ({
          fuelCompany: fuelCompanyCommercialTerm.fuelCompany,
          fuelCompanyId: fuelCompanyCommercialTerm.fuelCompanyId
        }),
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.gasStations,
        setValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm, gasStations: GasStation[] | null) => {
          return {
            ...fuelCompanyCommercialTerm,
            gasStations,
            gasStationIds: gasStations?.map((gasStation) => gasStation.id!) ?? null
          }
        }
      }),
      new DateFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject>({
        title: fuelCompanyCommercialTermsTextProvider.beginDateField(),
        groupName: mainGroupName,
        placeholder: coreTextProvider.unlimited(),
        getId: () => "beginDate",
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.beginDate,
        setValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm,
          beginDate: Date | null | undefined
        ): FuelCompanyCommercialTerm => ({
          ...fuelCompanyCommercialTerm, beginDate
        }),
        getErrors: (errorsObject?: FuelCompanyCommercialTermErrorsObject) => errorsObject?.attributes?.beginDate
      }),
      new DateFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject>({
        title: fuelCompanyCommercialTermsTextProvider.endDateField(),
        groupName: mainGroupName,
        placeholder: coreTextProvider.unlimited(),
        getId: () => "endDate",
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.endDate,
        setValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm,
          endDate: Date | null | undefined
        ): FuelCompanyCommercialTerm => ({
          ...fuelCompanyCommercialTerm, endDate
        }),
        getErrors: (errorsObject?: FuelCompanyCommercialTermErrorsObject) => errorsObject?.attributes?.endDate
      }),
      new SingleSelectFormField<
        FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject, PricingTypeSelectOption
      >({
        title: fuelCompanyCommercialTermsTextProvider.pricingTypeField(),
        clearable: false,
        required: true,
        groupName: mainGroupName,
        getObjectsUseCase: this.getPricingTypesUseCase,
        searchingEnabled: false,
        getErrors: (
          errorsObject?: FuelCompanyCommercialTermErrorsObject
        ) => this.generateLinksInsideAttributeErrors(errorsObject?.attributes?.pricingType),
        getId: () => "pricingType",
        getOptionId: (pricingTypeSelectOption: PricingTypeSelectOption) => pricingTypeSelectOption.id,
        getOptionText: (pricingTypeSelectOption: PricingTypeSelectOption) => pricingTypeSelectOption.text,
        getValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm
        ) => fuelCompanyCommercialTerm.pricingTypeSelectOption,
        setValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm,
          pricingTypeSelectOption: PricingTypeSelectOption | null
        ) => {
          const previousPricingType = fuelCompanyCommercialTerm.pricingType
          let price = fuelCompanyCommercialTerm.price
          let discountPercentage = fuelCompanyCommercialTerm.discountPercentage
          if (previousPricingType !== pricingTypeSelectOption?.id) {
            price = null
            discountPercentage = null
          }

          return {
            ...fuelCompanyCommercialTerm,
            pricingTypeSelectOption,
            price,
            discountPercentage,
            pricingType: pricingTypeSelectOption!.id as PricingType
          }
        }
      }),
      new DecimalFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject>({
        title: fuelCompanyCommercialTermsTextProvider.priceField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "price",
        getIsVisible: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm
        ) => fuelCompanyCommercialTerm.pricingType === PricingType.FIXED,
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.price,
        setValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm,
          price: Decimal | null | undefined
        ) => ({ ...fuelCompanyCommercialTerm, price }),
        getErrors: (
          errorsObject?: FuelCompanyCommercialTermErrorsObject
        ) => this.generateLinksInsideAttributeErrors(errorsObject?.attributes?.price)
      }),
      new DecimalFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject>({
        title: fuelCompanyCommercialTermsTextProvider.discountPercentageField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "discountPercentage",
        getIsVisible: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm
        ) => fuelCompanyCommercialTerm.pricingType === PricingType.DISCOUNT,
        getValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm
        ) => fuelCompanyCommercialTerm.discountPercentage,
        setValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm,
          discountPercentage: Decimal | null | undefined
        ) => ({ ...fuelCompanyCommercialTerm, discountPercentage }),
        getErrors: (
          errorsObject?: FuelCompanyCommercialTermErrorsObject
        ) => this.generateLinksInsideAttributeErrors(errorsObject?.attributes?.discountPercentage)
      }),
      new StringFormField<FuelCompanyCommercialTerm, FuelCompanyCommercialTermErrorsObject>({
        title: fuelCompanyCommercialTermsTextProvider.documentUrlField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "documentUrl",
        getValue: (fuelCompanyCommercialTerm: FuelCompanyCommercialTerm) => fuelCompanyCommercialTerm.documentUrl,
        setValue: (
          fuelCompanyCommercialTerm: FuelCompanyCommercialTerm,
          documentUrl: string
        ) => ({ ...fuelCompanyCommercialTerm, documentUrl }),
        getErrors: (errorsObject?: FuelCompanyCommercialTermErrorsObject) => errorsObject?.attributes?.documentUrl
      })
    ])
  }

  private generateLinksInsideAttributeErrors(
    attributeErrors: AttributeError[] | undefined | null
  ): AttributeError[] | undefined | null {
    const urlProvider = new AppUrlProvider()
    const fuelCompanyCommercialTermsTextProvider = this.fuelCompanyCommercialTermsI18.getTextProvider()

    return attributeErrors?.map((attributeError) => {
      const { objects } = attributeError
      return {
        ...attributeError,
        objects: objects && objects.map((attributeErrorObject) => {
          const linkInfo: LinkInfo | null | undefined = (() => {
            if (attributeErrorObject.type === fuelCompanyCommercialTermObjectType) {
              const url = urlProvider.buildShowCarrierCommercialTermUrl({
                carrierCommercialTermId: attributeErrorObject.id!
              })

              return {
                id: `${attributeErrorObject.id!}`,
                title: `${fuelCompanyCommercialTermsTextProvider.existObjectTitle()} ${attributeErrorObject.id!}`,
                url
              }
            }

            return undefined
          })()

          return {
            ...attributeErrorObject,
            linkInfo: linkInfo
          }
        })
      }
    })
  }
}
