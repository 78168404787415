import { Expose, Type } from "class-transformer"
import NetworkCarrierBalanceChangeDocument from "../NetworkCarrierBalanceChangeDocument"

export default class NetworkCarrierBalanceChangeDocumentRequestBody {
  @Expose()
  @Type(() => NetworkCarrierBalanceChangeDocument)
  readonly carrierBalanceReplenishment?: NetworkCarrierBalanceChangeDocument | null

  constructor(parameters: {
    readonly carrierBalanceReplenishment?: NetworkCarrierBalanceChangeDocument | null
  }) {
    this.carrierBalanceReplenishment = parameters.carrierBalanceReplenishment
  }
}
