import SelectOption from "../../../../admin/lib/picker/SelectOption"

enum InstallationInstanceType {
  METHANIKA_REFUELLER = "methanikaRefueller",
  SHELF_GAS_STATION = "shelfGasStation",
  SERVIO_PUMP_GAS = "servioPumpGas",
  RODNIK_2 = "rodnik2",
  SNCARD = "snCard",
  TOPAZ = "topaz",
  ZVEZDA = "zvezda"
}

export default InstallationInstanceType

export interface InstallationInstanceTypeSelectOption extends SelectOption { }
