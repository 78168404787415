import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkCarrierBalanceTransactionsResponseBody
  from "../../entities/carrier-balance-transactions/response-bodies/NetworkCarrierBalanceTransactionsResponseBody"
import NetworkCarrierBalanceTransactionsRequestQuery
  from "../../entities/carrier-balance-transactions/request-queries/NetworkCarrierBalanceTransactionsRequestQuery"

const basePath = "/api/admin/carrier_balance_write_offs"

export default class CarrierBalanceTransactionsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getTransactions({
    carrierId,
    pagination,
    sort
  }: {
    readonly carrierId: string | number
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetCarrierBalanceTransactionsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${carrierId}`,
      parameters: instanceToPlain(new NetworkCarrierBalanceTransactionsRequestQuery({
        pagination,
        sort
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkCarrierBalanceTransactionsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetCarrierBalanceTransactionsNetworkResult =
  SuccessExecutionResult<NetworkCarrierBalanceTransactionsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
