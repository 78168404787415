import RefuellingOrdersReportsTextProvider from "../RefuellingOrdersReportsTextProvider"

export default class DefaultRuRefuellingOrdersReportsTextProvider implements RefuellingOrdersReportsTextProvider {
  listTitle(): string {
    return "Отчеты по заправкам"
  }

  listDescription(): string {
    return "Срок хранения сформированных отчетов 30 календарных дней"
  }

  newObjectTitle(): string {
    return "Новый отчет"
  }

  createdDateField(): string {
    return "Дата создания"
  }

  fromDateField(): string {
    return "Дата начала"
  }

  toDateField(): string {
    return "Дата окончания"
  }

  refuellingOrders(): string {
    return "Заправки"
  }

  cancelledRefuellingOrders(): string {
    return "Отмененные заправки"
  }

  claimedRefuellingOrders(): string {
    return "Претензии"
  }

  codeField(): string {
    return "Код заправки"
  }

  driversField(): string {
    return "Водители"
  }

  priceField(): string {
    return "Цена стелы"
  }

  refuellingOrderStatusesField(): string {
    return "Статусы заправок"
  }

  tripsField(): string {
    return "Рейсы"
  }

  gasStationsField(): string {
    return "АЗС"
  }

  carriersField(): string {
    return "Перевозчики"
  }

  fuelCompaniesField(): string {
    return "Топливные компании"
  }

  fuelsField(): string {
    return "Виды топлива"
  }

  reportField(): string {
    return "Отчет"
  }
}
