import AuthenticationTextProvider from "../AuthenticationTextProvider"

export default class DefaultRuAuthenticationTextProvider implements AuthenticationTextProvider {

  authentication(): string {
    return "Логин"
  }

  emailAddress(): string {
    return "Адрес электронной почты"
  }

  password(): string {
    return "Пароль"
  }

  passwordConfirmation(): string {
    return "Подтвердите пароль"
  }

  authenticate(): string {
    return "Войти"
  }

  newPassword(): string {
    return "Новый пароль"
  }

  forgotPassword(): string {
    return "Забыли пароль?"
  }

  passwordRecovery(): string {
    return "Восстановление пароля"
  }

  enterEmailForPasswordRecovery(): string {
    return "Введите ваш адрес электронной почты, на которую мы вышлем новый пароль для входа в систему"
  }

  back(): string {
    return "Назад"
  }

  send(): string {
    return "Отправить"
  }
}
