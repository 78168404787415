import GetCurrentCarrierBalanceTransactionsUseCase
  from "../../domain/use-cases/carrier-balance-transactions/GetCurrentCarrierBalanceTransactionsUseCase"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CurrentCarrierBalancesDataDiModule from "./CurrentCarrierBalancesDataDiModule"
import GetCarrierForCurrentCarrierBalancesUseCase
  from "../../domain/use-cases/carriers/GetCarrierForCurrentCarrierBalancesUseCase"
import GetCurrentCarrierBalanceChangeDocumentsUseCase
  from "../../domain/use-cases/carrier-balance-change-documents/GetCurrentCarrierBalanceChangeDocumentsUseCase"

export default interface CurrentCarrierBalancesDomainDiModule {
  provideGetCurrentCarrierBalanceTransactionsUseCase(): GetCurrentCarrierBalanceTransactionsUseCase

  provideGetCurrentCarrierBalanceChangeDocumentsUseCase(): GetCurrentCarrierBalanceChangeDocumentsUseCase

  provideGetCarrierForCurrentCarrierBalancesUseCase(): GetCarrierForCurrentCarrierBalancesUseCase
}

export class DefaultCurrentCarrierBalancesDomainDiModule
  extends DiModule
  implements CurrentCarrierBalancesDomainDiModule {
  private readonly carrierBalancesDataDiModule: CurrentCarrierBalancesDataDiModule

  constructor(parameters: {
    readonly carrierBalancesDiModule: CurrentCarrierBalancesDataDiModule
  }) {
    super()
    this.carrierBalancesDataDiModule = parameters.carrierBalancesDiModule
  }

  provideGetCurrentCarrierBalanceTransactionsUseCase(): GetCurrentCarrierBalanceTransactionsUseCase {
    return this.single(
      GetCurrentCarrierBalanceTransactionsUseCase.name,
      () => new GetCurrentCarrierBalanceTransactionsUseCase({
        carrierBalanceTransactionsRepository:
          this.carrierBalancesDataDiModule.provideCurrentCarrierBalanceTransactionsRepository()
      })
    )
  }

  provideGetCurrentCarrierBalanceChangeDocumentsUseCase(): GetCurrentCarrierBalanceChangeDocumentsUseCase {
    return this.single(
      GetCurrentCarrierBalanceChangeDocumentsUseCase.name,
      () => new GetCurrentCarrierBalanceChangeDocumentsUseCase({
        currentCarrierBalanceChangeDocumentsRepository:
          this.carrierBalancesDataDiModule.provideCurrentCarrierBalanceChangeDocumentsRepository()
      })
    )
  }

  provideGetCarrierForCurrentCarrierBalancesUseCase(): GetCarrierForCurrentCarrierBalancesUseCase {
    return this.single(
      GetCarrierForCurrentCarrierBalancesUseCase.name,
      () => new GetCarrierForCurrentCarrierBalancesUseCase({
        carriersForCarrierBalancesRepository:
          this.carrierBalancesDataDiModule.provideCarriersForCurrentCarrierBalancesRepository()
      })
    )
  }
}
