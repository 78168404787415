import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import { ChangingTypeSelectOption } from "../../../../../core/domain/changing-log-items/ChangingType"
import ChangingTypesForTripChangingLogRepository from "../../repositories/ChangingTypesForTripChangingLogRepository"

export interface GetChangingTypesForTripChangingLogParameters extends GetObjectsParameters {
}

export default class GetChangingTypesForTripChangingLogUseCase implements GetObjectsUseCase<ChangingTypeSelectOption> {
  private readonly changingTypesForTripChangingLogRepository: ChangingTypesForTripChangingLogRepository

  constructor(parameters: {
    readonly changingTypesForTripChangingLogRepository: ChangingTypesForTripChangingLogRepository
  }) {
    this.changingTypesForTripChangingLogRepository = parameters.changingTypesForTripChangingLogRepository
  }

  async call(parameters: GetChangingTypesForTripChangingLogParameters): Promise<
    GetObjectsResult<ChangingTypeSelectOption>
  > {
    return await this.changingTypesForTripChangingLogRepository.getChangingTypes(parameters)
  }
}
