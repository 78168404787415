import isBlank from "../../../../../admin/lib/isBlank"
import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import TripsI18 from "../../i18n/TripsI18"
import Transport from "../../../../core/domain/transport/Transport"
import Trip from "../../../../core/domain/trips/Trip"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppI18 from "../../../../core/i18n/AppI18"
import DateInfoRow from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/DateInfoRow"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import TripStatus from "../../../../core/domain/trips/TripStatus"
import ArrayInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/ArrayInfoRow"
import UserTripStatus from "../../../../core/domain/user-trips/UserTripStatus"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"

export default class TripsInfoProvider implements InfoProvider<Trip> {
  private readonly timeZone: string
  private readonly appI18: AppI18
  private readonly tripsI18: TripsI18

  constructor(parameters: {
    readonly timeZone: string
    readonly appI18: AppI18
    readonly tripsI18: TripsI18
  }) {
    this.timeZone = parameters.timeZone
    this.appI18 = parameters.appI18
    this.tripsI18 = parameters.tripsI18
  }

  getEntity(): string {
    return Entity.TRIPS
  }

  getObjectShortTitle({
    object
  }: {
    readonly object?: Trip
  }): string {
    if (isBlank(object)) {
      return ""
    }

    return this.tripsI18.getTextProvider().existObjectTitle({
      id: object.id!
    })
  }

  getObjectTitle({
    object
  }: {
    readonly object?: Transport
  }): string {
    if (isBlank(object)) {
      return ""
    }

    return this.tripsI18.getTextProvider().existObjectTitle({
      id: object.id!
    })
  }

  getInfoRows({ object }: {
    readonly object: Trip
  }): InfoRow[] {
    const urlProvider = new AppUrlProvider()
    const tripsTextProvider = this.tripsI18.getTextProvider()
    const appTextsProvider = this.appI18.getTextProvider()

    return [
      new StringInfoRow({
        name: "name",
        title: tripsTextProvider.nameField(),
        value: object?.name
      }),
      new StringInfoRow({
        name: "carrier",
        entity: Entity.CARRIERS,
        title: tripsTextProvider.carrierField(),
        value: object?.carrier?.name,
        url: urlProvider.buildShowCarrierUrl({
          carrierId: object.carrierId!
        })
      }),
      new StringInfoRow({
        name: "transport",
        entity: Entity.TRANSPORTS,
        title: tripsTextProvider.transportField(),
        value: object?.transport?.name,
        additionalValue: object.transport?.stateNumber,
        url: urlProvider.buildShowTransportUrl({
          transportId: object.transportId!
        })
      }),
      new StringInfoRow({
        name: "status",
        title: tripsTextProvider.statusField(),
        value: (() => {
          switch (object.status) {
            case TripStatus.PENDING: {
              return appTextsProvider.tripStatusPendingText()
            }
            case TripStatus.CONFIRMED: {
              return appTextsProvider.tripStatusConfirmedText()
            }
            case TripStatus.FINISHED: {
              return appTextsProvider.tripStatusFinishedText()
            }
            case TripStatus.CANCELED: {
              return appTextsProvider.tripStatusCanceledText()
            }
            default: {
              return null
            }
          }
        })()
      }),
      new DateInfoRow({
        name: "startingAt",
        title: tripsTextProvider.startingAtField(),
        value: object.startingAt,
        format: DateTimeFormat.DATE
      }),
      new DateInfoRow({
        name: "finishingAt",
        title: tripsTextProvider.finishingAtField(),
        value: object.finishingAt,
        format: DateTimeFormat.DATE
      }),
      new ArrayInfoRow({
        name: "drivers",
        entity: Entity.DRIVERS,
        title: tripsTextProvider.driversField(),
        items: object.userTrips?.map((userTrip) => {
          return {
            key: `${userTrip.id!}`,
            value: (() => {
              const userFullName = appTextsProvider.driverName({
                user: userTrip.user
              })

              const statusName = (() => {
                switch (userTrip.status) {
                  case UserTripStatus.PENDING: {
                    return appTextsProvider.userTripStatusPendingText()
                  }
                  case UserTripStatus.CONFIRMED: {
                    return appTextsProvider.userTripStatusConfirmedText()
                  }
                  case UserTripStatus.FINISHED: {
                    return appTextsProvider.userTripStatusFinishedText()
                  }
                  case UserTripStatus.CANCELED: {
                    return appTextsProvider.userTripStatusCanceledText()
                  }
                  default: {
                    return null
                  }
                }
              })()

              return `${userFullName}: ${statusName}`
            })(),
            url: urlProvider.buildShowDriverUrl({
              driverId: userTrip.userId!
            })
          }
        })
      }),
      new ArrayInfoRow({
        name: "limits",
        title: tripsTextProvider.limitsField(),
        items: object.limits?.map((tripLimit) => {
          return {
            key: `${tripLimit.id!}`,
            value: (() => {
              const { fuel, formattedValue, tripLimitType } = tripLimit
              const name = filterNotEmpty([tripLimitType?.name, fuel?.name]).join(" ")
              const value = filterNotEmpty([formattedValue, fuel?.shortFormattedUom]).join(" ")
              return `${name}: ${value}`
            })()
          }
        })
      }),
      new StringInfoRow({
        name: "note",
        title: tripsTextProvider.noteField(),
        value: object?.note
      })
    ]
  }

  getLinks({ object }: {
    readonly object: Trip
  }): LinkInfo[] {
    const urlProvider = new AppUrlProvider()
    const tripsTextProvider = this.tripsI18.getTextProvider()

    return [
      {
        id: "refuellingOrders",
        entity: Entity.REFUELLING_ORDERS,
        title: tripsTextProvider.refuellingOrdersLink(),
        url: urlProvider.buildRefuellingOrdersUrl({
          tripId: object.id
        })
      },
      {
        id: "tripChangingLog",
        entity: Entity.CHANGING_LOG_ITEMS,
        title: tripsTextProvider.changingLogLink(),
        url: urlProvider.buildTripChangingLogUrl({
          tripId: object.id
        })
      }
    ]
  }

  getEditUrl({
    object
  }: {
    readonly object: Trip
  }): string {
    if (object.status === TripStatus.FINISHED || object.status === TripStatus.CANCELED) return ""

    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditTripUrl({
      tripId: object.id!
    })
  }
}
