import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import CarrierBalancesDomainDiModule from "./CarrierBalancesDomainDiModule"
import CarrierBalancesI18nDiModule from "./CarrierBalancesI18nDiModule"
import CarrierBalancePresenter from "../../presentation/pages/carrier-balance/CarrierBalancePresenter"
import ObjectsDomainDiModule from "../../../../../admin/features/objects/di/modules/ObjectsDomainDiModule"
import CarrierBalancePageRouteElement from "../../presentation/pages/carrier-balance/CarrierBalancePageRouteElement"
import CarrierBalanceTransactionsTableProvider
  from "../../presentation/table-providers/CarrierBalanceTransactionsTableProvider"
import CarrierBalanceChangeDocumentsTableProvider
  from "../../presentation/table-providers/CarrierBalanceChangeDocumentsTableProvider"
import BalanceTransactionType from "../../../../core/domain/balance-transactions/BalanceTransactionType"
import CarrierBalanceChangeDocumentFormProvider
  from "../../presentation/form-providers/CarrierBalanceChangeDocumentFormProvider"
import CarrierBalanceChangeDocumentFormPresenter
  from "../../presentation/pages/carrier-balance-change-documents/CarrierBalanceChangeDocumentFormPresenter"
import CarrierBalanceChangeDocumentFormPageRouteElement
  from "../../presentation/pages/carrier-balance-change-documents/CarrierBalanceChangeDocumentFormPageRouteElement"
import CoreDomainDiModule from "../../../../../admin/core/di/modules/CoreDomainDiModule"

const moduleKey = "carrierBalances"

export default interface CarrierBalancesPresentationDiModule {
  provideCarrierBalancePage(): React.ReactNode

  provideCarrierBalanceChangeDocumentPage(): React.ReactNode
}

export class DefaultCarrierBalancesPresentationDiModule
  extends DiModule
  implements CarrierBalancesPresentationDiModule {

  private readonly timeZone: string
  private readonly coreDomainDiModule: CoreDomainDiModule
  private readonly objectsDomainDiModule: ObjectsDomainDiModule
  private readonly carrierBalancesI18nDiModule: CarrierBalancesI18nDiModule
  private readonly carrierBalancesDomainDiModule: CarrierBalancesDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly coreDomainDiModule: CoreDomainDiModule
    readonly objectsDomainDiModule: ObjectsDomainDiModule
    readonly carrierBalancesI18nDiModule: CarrierBalancesI18nDiModule
    readonly carrierBalancesDomainDiModule: CarrierBalancesDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreDomainDiModule = parameters.coreDomainDiModule
    this.objectsDomainDiModule = parameters.objectsDomainDiModule
    this.carrierBalancesI18nDiModule = parameters.carrierBalancesI18nDiModule
    this.carrierBalancesDomainDiModule = parameters.carrierBalancesDomainDiModule
  }

  provideCarrierBalancePage(): React.ReactNode {
    return (
      <CarrierBalancePageRouteElement
        carrierBalancesI18n={this.carrierBalancesI18nDiModule.provideCarrierBalancesI18n()}
        providePresenter={({
          carrierId,
          transactionsType
        }) => this.provideCarrierBalanceTransactionsPresenter({
          key: moduleKey,
          carrierId,
          transactionsType
        })}
      />
    )
  }

  protected provideCarrierBalanceTransactionsPresenter({
    key,
    carrierId,
    transactionsType
  }: {
    readonly key: string
    readonly carrierId: string
    readonly transactionsType?: BalanceTransactionType | null
  }): CarrierBalancePresenter {
    return new CarrierBalancePresenter({
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      subscribeToObjectsEventsUseCase:
        this.objectsDomainDiModule.provideSubscribeToObjectsEventsUseCase({
          key
        }),
      unsubscribeFromObjectsEventsUseCase:
        this.objectsDomainDiModule.provideUnsubscribeFromObjectsEventsUseCase({
          key
        }),
      getCarrierBalanceTransactionsUseCase:
        this.carrierBalancesDomainDiModule.provideGetCarrierBalanceChangeTransactionsUseCase(),
      getCarrierBalanceChangeDocumentsUseCase:
        this.carrierBalancesDomainDiModule.provideGetCarrierBalanceChangeDocumentsUseCase(),
      getCarrierUseCase: this.carrierBalancesDomainDiModule.provideGetCarrierForCarrierBalancesUseCase(),
      carrierId,
      transactionsType,
      carrierBalanceTransactionsTableProvider: this.provideCarrierBalanceTransactionsTableProvider(),
      carrierBalanceChangeDocumentsTableProvider:
        this.provideCarrierBalanceChangeDocumentsTableProvider()
    })
  }

  private provideCarrierBalanceTransactionsTableProvider(): CarrierBalanceTransactionsTableProvider {
    return this.single(
      CarrierBalanceTransactionsTableProvider.name,
      () => new CarrierBalanceTransactionsTableProvider({
        carrierBalancesI18: this.carrierBalancesI18nDiModule.provideCarrierBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }

  // eslint-disable-next-line max-len
  private provideCarrierBalanceChangeDocumentsTableProvider(): CarrierBalanceChangeDocumentsTableProvider {
    return this.single(
      CarrierBalanceChangeDocumentsTableProvider.name,
      () => new CarrierBalanceChangeDocumentsTableProvider({
        carrierBalancesI18: this.carrierBalancesI18nDiModule.provideCarrierBalancesI18n(),
        timeZone: this.timeZone
      })
    )
  }

  provideCarrierBalanceChangeDocumentPage(): React.ReactNode {
    return (
      <CarrierBalanceChangeDocumentFormPageRouteElement
        providePresenter={({ carrierId }) => this.provideCarrierBalanceChangeDocumentFormPresenter({
          key: moduleKey,
          carrierId
        })}
      />
    )
  }

  protected provideCarrierBalanceChangeDocumentFormPresenter({
    key,
    carrierId
  }: {
    readonly key: string
    readonly carrierId: string | number
  }): CarrierBalanceChangeDocumentFormPresenter {
    return new CarrierBalanceChangeDocumentFormPresenter({
      checkPermissionDeniedUseCase: this.coreDomainDiModule.provideCheckPermissionDeniedUseCase(),
      broadcastObjectsEventUseCase: this.objectsDomainDiModule.provideBroadcastObjectsEventUseCase({
        key
      }),
      formProvider: this.provideCarrierBalanceChangeDocumentFormProvider(),
      createCarrierBalanceChangeDocumentUseCase:
        this.carrierBalancesDomainDiModule.provideCreateCarrierBalanceChangeDocumentUseCase(),
      carrierId
    })
  }

  private provideCarrierBalanceChangeDocumentFormProvider(): CarrierBalanceChangeDocumentFormProvider {
    return this.single(
      CarrierBalanceChangeDocumentFormProvider.name,
      () => new CarrierBalanceChangeDocumentFormProvider({
        carrierBalancesI18n: this.carrierBalancesI18nDiModule.provideCarrierBalancesI18n()
      })
    )
  }
}
