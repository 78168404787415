import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import FuelCompanyBalancesI18n from "../../i18n/FuelCompanyBalancesI18n"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import DecimalFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import FuelCompanyBalanceChangeDocument
  from "../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import FuelCompanyBalanceChangeDocumentError
  from "../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentError"
import FuelCompanyBalanceChangeDocumentErrorsObject
  from "../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentErrorsObject"

const mainGroupName = "main"

export default class FuelCompanyBalanceChangeDocumentFormProvider
  implements FormProvider<
    FuelCompanyBalanceChangeDocument,
    FuelCompanyBalanceChangeDocumentError,
    FuelCompanyBalanceChangeDocumentErrorsObject
  > {
  private readonly fuelCompanyBalancesI18n: FuelCompanyBalancesI18n

  constructor(parameters: {
    readonly fuelCompanyBalancesI18n: FuelCompanyBalancesI18n
  }) {
    this.fuelCompanyBalancesI18n = parameters.fuelCompanyBalancesI18n
  }

  getEntity(): string {
    return Entity.FUEL_COMPANY_BALANCE_WRITE_OFFS
  }

  getNewObjectTitle(): string {
    return this.fuelCompanyBalancesI18n.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle(): string {
    return ""
  }

  getExistedObjectTitle(): string {
    return ""
  }

  async buildObject(): Promise<FuelCompanyBalanceChangeDocument> {
    return {}
  }

  getErrorsObject({
    error
  }: {
    readonly error?: FuelCompanyBalanceChangeDocumentError
  }): FuelCompanyBalanceChangeDocumentErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject>[] {
    const fuelCompanyBalancesTextProvider = this.fuelCompanyBalancesI18n.getTextProvider()

    return [
      new DateFormField<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject>({
        title: fuelCompanyBalancesTextProvider.dateField(),
        groupName: mainGroupName,
        required: true,
        getId: () => "date",
        getValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument
        ) => fuelCompanyBalanceChangeDocument.date,
        setValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument,
          date: Date | null | undefined
        ): FuelCompanyBalanceChangeDocument => ({
          ...fuelCompanyBalanceChangeDocument, date
        }),
        getErrors: (errorsObject?: FuelCompanyBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.date
      }),
      new DecimalFormField<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject>({
        title: fuelCompanyBalancesTextProvider.valueField(),
        groupName: mainGroupName,
        required: true,
        getId: () => "value",
        getValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument
        ) => fuelCompanyBalanceChangeDocument.value,
        setValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument,
          value: Decimal | null | undefined
        ): FuelCompanyBalanceChangeDocument => ({
          ...fuelCompanyBalanceChangeDocument, value
        }),
        getErrors: (errorsObject?: FuelCompanyBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.value
      }),
      new StringFormField<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject>({
        title: fuelCompanyBalancesTextProvider.reasonField(),
        groupName: mainGroupName,
        getId: () => "reason",
        getValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument
        ) => fuelCompanyBalanceChangeDocument.reason,
        setValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument,
          reason: string | null | undefined
        ): FuelCompanyBalanceChangeDocument => ({
          ...fuelCompanyBalanceChangeDocument, reason
        }),
        getErrors: (errorsObject?: FuelCompanyBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.reason
      }),
      new StringFormField<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentErrorsObject>({
        title: fuelCompanyBalancesTextProvider.commentField(),
        groupName: mainGroupName,
        getId: () => "comment",
        getValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument
        ) => fuelCompanyBalanceChangeDocument.comment,
        setValue: (
          fuelCompanyBalanceChangeDocument: FuelCompanyBalanceChangeDocument,
          comment: string | null | undefined
        ): FuelCompanyBalanceChangeDocument => ({
          ...fuelCompanyBalanceChangeDocument, comment
        }),
        getErrors: (errorsObject?: FuelCompanyBalanceChangeDocumentErrorsObject) => errorsObject?.attributes?.comment
      })
    ]
  }
}
