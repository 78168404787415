import React from "react"
import SegmentControlComponentStyles from "./SegmentControlComponentStyles"
import styles from "./SegmentControlComponent.module.scss"

export interface SegmentControlItem {
  readonly id: string
  readonly text: string
}

export default function SegmentControlComponent({
  items,
  selectedItem,
  isDisabled = false,
  componentStyles = {},
  onSelectSegment = () => undefined
}: {
  readonly items: SegmentControlItem[]
  readonly selectedItem?: SegmentControlItem
  readonly isDisabled?: boolean
  readonly componentStyles?: SegmentControlComponentStyles
  readonly onSelectSegment?: (item: SegmentControlItem) => void
}) {
  const {
    classes: {
      default: segmentDefaultClassName = "",
      selected: segmentSelectedClassName = ""
    } = {}
  } = componentStyles

  return (
    <div className={styles.root}>
      {items.map((item) => {
        const selectedClassName = item.id === selectedItem?.id ?
          `${styles.selected} ${segmentSelectedClassName}` :
          ""

        return (
          <div
            key={item.id}
            className={`${styles.segment} ${segmentDefaultClassName} ${selectedClassName}`}
            onClick={() => {
              if (selectedItem?.id !== item.id) {
                onSelectSegment(item)
              }
            }}
          >
            <div className={styles.segmentText}>
              {item.text}
            </div>
          </div>
        )
      })}
    </div>
  )
}
