import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import Fuel from "../../../../../core/domain/fuels/Fuel"
import FuelsForTripLimitsRepository from "../../repositories/FuelsForTripLimitsRepository"

export interface GetFuelsForTripLimitsParameters extends GetObjectsParameters {
}

export default class GetFuelsForTripLimitsUseCase implements GetObjectsUseCase<Fuel> {
  private readonly fuelsForTripLimitsRepository: FuelsForTripLimitsRepository

  constructor(parameters: {
    readonly fuelsForTripLimitsRepository: FuelsForTripLimitsRepository
  }) {
    this.fuelsForTripLimitsRepository = parameters.fuelsForTripLimitsRepository
  }

  async call(parameters: GetFuelsForTripLimitsParameters): Promise<GetObjectsResult<Fuel>> {
    return await this.fuelsForTripLimitsRepository.getFuels(parameters)
  }
}
