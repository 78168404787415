import React, { useRef } from "react"
import CoreTextProvider from "../../../i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../contexts/CoreTextProviderContext"
import styles from "./AppMultiSelectPickerComponent.module.scss"
import Page from "../../../domain/entities/pages/Page"
import isPresent from "../../../../lib/isPresent"
import PaginationComponent from "../pagination/PaginationComponent"
import MultiSelectPickerComponent, {
  MultiSelectPickerComponentProps
} from "../../../../lib/picker/multi-select-picker/MultiSelectPickerComponent"
import ContentLoadingComponent from "../content-loading/ContentLoadingComponent"
import { useCoreThemeProvider } from "../../contexts/CoreThemeProviderContext"
import TextInputComponent, { TextInputComponentRef } from "../../../../lib/text-input/TextInputComponent"

export default function AppMultiSelectPickerComponent(props: MultiSelectPickerComponentProps & {
  readonly query?: string | null
  readonly page?: Page
  readonly onQueryChanged: (query: string) => void
  readonly onSearchRequested: () => void
  readonly onNextPageRequested: () => void
  readonly clearable?: boolean
}) {
  const searchBarRef: React.MutableRefObject<TextInputComponentRef | null> = useRef(null)
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const theme = useCoreThemeProvider()

  const handleInputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onSearchRequested()
      event.preventDefault()
    }
  }

  function handleOnOpened() {
    searchBarRef.current?.focusOnInput()
    props.onOpened && props.onOpened()
  }

  function requestNextPage() {
    if (!props.isLoading && isPresent(props.page) && props.page.hasMore) {
      props.onNextPageRequested()
    }
  }

  return (
    <MultiSelectPickerComponent
      {...props}
      placeholder={props.placeholder ?? coreTextProvider.select()}
      onOpened={handleOnOpened}
      inputClasses={{
        default: theme.input1PrimaryClassName(),
        active: theme.input1PrimaryActiveClassName(),
        disabled: theme.input1PrimaryDisabledClassName(),
        errors: theme.input1PrimaryErrorsClassName(),
        noValues: theme.input1PrimaryPlaceholderClassName()
      }}
      dropdownClasses={{
        default: theme.dropdownClassName(),
        itemDefault: theme.dropdownItem1PrimaryClassName(),
        itemSelected: theme.dropdownItem1PrimarySelectedClassName(),
        itemDisabled: theme.dropdownItem1PrimaryDisabledClassName()
      }}
      iconsSet={{
        default: theme.icExpandMoreComponent(),
        opened: theme.icExpandMoreActiveComponent(),
        disabled: theme.icExpandMoreDisabledComponent()
      }}
      chipsClasses={{
        default: theme.chip1PrimaryClassName(),
        clearItem: theme.chip1PrimaryClearItemClassName()
      }}
      aboveOptionsElement={
        <>
          <div className={styles.searchBar}>
            <TextInputComponent
              ref={searchBarRef}
              value={props.query ?? ""}
              onChange={props.onQueryChanged}
              onKeyDown={handleInputOnKeyDown}
              className={styles.search}
              componentStyle={theme.searchBar1PrimaryStyle()}
              placeholder={coreTextProvider.enterSearchQuery()}
            />
          </div>
          {!props.isLoading && props.options.length === 0 && (
            <div className={`${styles.noObjects} ${theme.dropdownItem1SecondaryClassName()}`}>
              {coreTextProvider.noFoundedObjects()}
            </div>
          )}
        </>
      }
      belowOptionsElement={
        <>
          {props.isLoading && <ContentLoadingComponent />}
          <PaginationComponent onComponentAppeared={requestNextPage} />
        </>
      }
    />
  )
}
