import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import TransportsForTripsRepository from "../../domain/repositories/TransportsForTripsRepository"
import { GetTransportsForTripsParameters } from "../../domain/use-cases/transports/GetTransportsForTripsUseCase"
import Transport from "../../../../core/domain/transport/Transport"
import TransportsNetworkSource from "../../../../core/data/sources/network/TransportsNetworkSource"
import TransportsMapper from "../../../../core/data/mappers/TransportsMapper"
import NetworkTransportsRequestFilter
  from "../../../../core/data/entities/transports/request-queries/NetworkTransportsRequestFilter"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultTransportsForTripsRepository implements TransportsForTripsRepository {
  private readonly transportsNetworkSource: TransportsNetworkSource

  constructor(parameters: {
    readonly transportsNetworkSource: TransportsNetworkSource
  }) {
    this.transportsNetworkSource = parameters.transportsNetworkSource
  }

  async getTransports({
    filter,
    ids,
    query,
    pagination,
    sort
  }: GetTransportsForTripsParameters): Promise<GetObjectsResult<Transport>> {
    const carrierId = filter?.carrierId
    const blocked = filter?.blocked
    const result = await this.transportsNetworkSource.getTransports({
      filter: new NetworkTransportsRequestFilter({
        query,
        ids,
        carrierIds: isPresent(carrierId) ? [carrierId] : null,
        blocked: blocked
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.transports!.map((transport) => {
              return new TransportsMapper().mapNetworkToDomain({ transport })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
