enum TripLimitTargetType {
  REFUELLING_BY_MONEY = "refuelling_by_money",
  REFUELLING_BY_FUEL_TYPE = "refuelling_by_fuel_type",
  DAILY_BY_MONEY = "daily_by_money",
  DAILY_BY_FUEL_TYPE = "daily_by_fuel_type",
  TRIP_BY_MONEY = "trip_by_money",
  TRIP_BY_FUEL_TYPE = "trip_by_fuel_type"
}

export default TripLimitTargetType
