import TransportsRepository from "../../domain/repositories/TransportsRepository"
import { GetTransportsParameters } from "../../domain/use-cases/transports/GetTransportsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateTransportParameters } from "../../domain/use-cases/transports/CreateTransportUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetTransportParameters } from "../../domain/use-cases/transports/GetTransportUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateTransportParameters } from "../../domain/use-cases/transports/UpdateTransportUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import TransportsNetworkSource from "../../../../core/data/sources/network/TransportsNetworkSource"
import Transport from "../../../../core/domain/transport/Transport"
import NetworkTransportsRequestFilter
  from "../../../../core/data/entities/transports/request-queries/NetworkTransportsRequestFilter"
import TransportsMapper from "../../../../core/data/mappers/TransportsMapper"
import TransportError from "../../../../core/domain/transport/TransportError"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultTransportsRepository implements TransportsRepository {
  private readonly transportsNetworkSource: TransportsNetworkSource

  constructor(parameters: {
    readonly transportsNetworkSource: TransportsNetworkSource
  }) {
    this.transportsNetworkSource = parameters.transportsNetworkSource
  }

  async getTransports({
    filter,
    query,
    pagination,
    sort
  }: GetTransportsParameters): Promise<GetObjectsResult<Transport>> {
    const carrierId = filter?.carrierId
    const result = await this.transportsNetworkSource.getTransports({
      filter: new NetworkTransportsRequestFilter({
        query,
        carrierIds: isPresent(carrierId) ? [carrierId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.transports!.map((transport) => {
              return new TransportsMapper().mapNetworkToDomain({ transport })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getTransport({
    objectId
  }: GetTransportParameters): Promise<GetObjectResult<Transport>> {
    const result = await this.transportsNetworkSource.getTransport({
      transportId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new TransportsMapper().mapNetworkToDomain({
            transport: result.data.transport!
          })
        }
      default:
        return result
    }
  }

  async createTransport({
    object: transport
  }: CreateTransportParameters): Promise<CreateObjectResult<Transport, TransportError>> {
    const mapper = new TransportsMapper()
    const result = await this.transportsNetworkSource.createTransport({
      transport: mapper.mapDomainToNetwork({ transport })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            transport: result.data.transport!
          })
        }
      default:
        return result
    }
  }

  async updateTransport({
    objectId: transportId,
    object: transport
  }: UpdateTransportParameters): Promise<UpdateObjectResult<Transport, TransportError>> {
    const mapper = new TransportsMapper()
    const result = await this.transportsNetworkSource.updateTransport({
      transportId: parseInt(transportId),
      transport: mapper.mapDomainToNetwork({
        transport
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            transport: result.data.transport!
          })
        }
      default:
        return result
    }
  }
}
