import { instanceToPlain, plainToInstance } from "class-transformer"
import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import NetworkExecutionError from "../../entities/errors/NetworkExecutionError"
import SuccessExecutionResult from "../../../../../admin/core/domain/results/SuccessExecutionResult"
import ErrorExecutionResult from "../../../../../admin/core/domain/results/ErrorExecutionResult"
import FailureExecutionResult from "../../../../../admin/core/domain/results/FailureExecutionResult"
import NetworkLastItemPagination from "../../entities/pagination/NetworkLastItemPagination"
import NetworkRefuellingOrdersReportsRequestQuery from "../../entities/refuelling-orders-reports/request-queries/NetworkRefuellingOrdersReportsRequestQuery"
import NetworkRefuellingOrdersReportResponseBody from "../../entities/refuelling-orders-reports/response-bodies/NetworkRefuellingOrdersReportResponseBody"
import NetworkRefuellingOrdersReportsResponseBody from "../../entities/refuelling-orders-reports/response-bodies/NetworkRefuellingOrdersReportsResponseBody"
import NetworkRefuellingOrdersReportErrorResponseBody
  from "../../entities/refuelling-orders-reports/response-bodies/errors/NetworkRefuellingOrdersReportErrorResponseBody"
import NetworkRefuellingOrdersReport from "../../entities/refuelling-orders-reports/NetworkRefuellingOrdersReport"
import NetworkRefueliingOrdersReportRequestBody from "../../entities/refuelling-orders-reports/request-bodies/NetworkRefueliingOrdersReportRequestBody"
import NetworkRefuellingOrdersReportsRequestFilter from "../../entities/refuelling-orders-reports/request-queries/NetworkRefuellingOrdersReportsRequestFilter"

const basePath = "/api/admin/refuelling_orders_reports"

export default class RefuellingOrdersReportsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getRefuellingOrdersReports({
    filter,
    needPagination,
    pagination,
    sort
  }: {
    readonly filter?: NetworkRefuellingOrdersReportsRequestFilter | null
    readonly needPagination?: boolean | null
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }): Promise<GetRefuellingOrdersReportsNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}`,
      parameters: instanceToPlain(new NetworkRefuellingOrdersReportsRequestQuery({
        pagination,
        sort,
        needPagination,
        filter
      }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkRefuellingOrdersReportsResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkExecutionError, result.body)
        }
      case "failure":
        return result
    }
  }

  async createRefuellingOrdersReport({
    report
  }: {
    readonly report?: NetworkRefuellingOrdersReport | null
  }): Promise<CreateRefuellingOrderReportNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.POST,
      path: `${basePath}`,
      body: instanceToPlain(new NetworkRefueliingOrdersReportRequestBody({ report }))
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: plainToInstance(NetworkRefuellingOrdersReportResponseBody, result.body)
        }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkRefuellingOrdersReportErrorResponseBody, result.body)
        }
      case "failure":
        return result
    }
  }
}

export type GetRefuellingOrdersReportsNetworkResult =
  SuccessExecutionResult<NetworkRefuellingOrdersReportsResponseBody> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult

export type CreateRefuellingOrderReportNetworkResult =
  SuccessExecutionResult<NetworkRefuellingOrdersReportResponseBody> |
  ErrorExecutionResult<NetworkRefuellingOrdersReportErrorResponseBody> |
  FailureExecutionResult
