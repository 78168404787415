import { Expose, Type } from "class-transformer"
import NetworkFuelPrice from "../NetworkFuelPrice"

export default class NetworkFuelPriceRequestBody {

  @Expose()
  @Type(() => NetworkFuelPrice)
  readonly fuelPrice?: NetworkFuelPrice | null

  constructor(parameters: {
    readonly fuelPrice?: NetworkFuelPrice | null
  }) {
    this.fuelPrice = parameters.fuelPrice
  }
}
