import CarrierBalancesTextProvider from "../CarrierBalancesTextProvider"
import isBlank from "../../../../../admin/lib/isBlank"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultRuCarrierBalancesTextProvider implements CarrierBalancesTextProvider {
  reasonIdField(): string {
    return "Номер документа-основания"
  }

  reasonDateField(): string {
    return "Дата документа основания"
  }

  dateField(): string {
    return "Дата"
  }

  commentField(): string {
    return "Комментарий"
  }

  newObjectTitle(): string {
    return "Пополнение баланса"
  }

  listTitle(parameters?: {
    readonly carrierName: string | null | undefined
    readonly carrierBalance: string | null | undefined
  }): string {
    const carrierName = parameters?.carrierName
    const carrierBalance = parameters?.carrierBalance
    if (isBlank(carrierName) || isBlank(carrierBalance)) return "Баланс перевозчика"

    return `${carrierName}, баланс ${withRubbleSymbol(carrierBalance)}`
  }

  replenishBalance(): string {
    return "Пополнить баланс"
  }

  writeOffsSegment(): string {
    return "Списания"
  }

  replenishmentsSegment(): string {
    return "Пополнения"
  }

  idField(): string {
    return "№"
  }

  valueField(): string {
    return "Сумма"
  }

  reasonField(): string {
    return "Основание"
  }

  refuellingReasonValue({
    refuellingOrderCode
  }: {
    readonly refuellingOrderCode: string | null | undefined
  }): string {
    return isPresent(refuellingOrderCode) ? `Заправка №${refuellingOrderCode}` : ""
  }
}
