import { Expose, Type } from "class-transformer"

export default class NetworkGasStationsRequestFilter {
  @Expose()
  @Type(() => String)
  readonly query?: string | null

  @Expose()
  @Type(() => String)
  readonly ids?: string[] | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyIds?: number[] | null

  constructor(parameters: {
    readonly query?: string | null
    readonly ids?: string[] | null
    readonly fuelCompanyIds?: number[] | null
  }) {
    this.query = parameters.query
    this.ids = parameters.ids
    this.fuelCompanyIds = parameters.fuelCompanyIds
  }
}
