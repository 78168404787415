import { Expose, Type } from "class-transformer"
import NetworkCarrierReconciliationReport from "../NetworkCarrierReconciliationReport"

export default class NetworkCarrierReconciliationReportRequestBody {

  @Expose()
  @Type(() => NetworkCarrierReconciliationReport)
  readonly report?: NetworkCarrierReconciliationReport | null

  constructor(parameters: {
    readonly report?: NetworkCarrierReconciliationReport | null
  }) {
    this.report = parameters.report
  }
}
