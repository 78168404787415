import NetworkCarrierAppAdministratorErrorsObjectAttributes
  from "./NetworkCarrierAppAdministratorErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import CarrierAppAdministratorErrorsObject
  from "../../../../../domain/carrier-app-administrators/CarrierAppAdministratorErrorsObject"

export default class NetworkCarrierAppAdministratorErrorsObject implements CarrierAppAdministratorErrorsObject {
  @Expose()
  @Type(() => NetworkCarrierAppAdministratorErrorsObjectAttributes)
  readonly attributes?: NetworkCarrierAppAdministratorErrorsObjectAttributes | null
}
