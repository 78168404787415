import { Expose, Type } from "class-transformer"
import NetworkFuelCompany from "../fuel-companies/NetworkFuelCompany"
import NetworkGPSLocation from "../gps-locations/NetworkGPSLocation"

export default class NetworkGasStation {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => String)
  readonly name?: string | null

  @Expose()
  @Type(() => String)
  readonly code?: string | null

  @Expose()
  @Type(() => Boolean)
  readonly blocked?: boolean | null

  @Expose()
  @Type(() => String)
  readonly address?: string | null

  @Expose()
  @Type(() => String)
  readonly phoneNumber?: string | null

  @Expose()
  @Type(() => Number)
  readonly fuelCompanyId?: number | null

  @Expose()
  @Type(() => NetworkFuelCompany)
  readonly fuelCompany?: NetworkFuelCompany | null

  @Expose()
  @Type(() => NetworkGPSLocation)
  readonly location?: NetworkGPSLocation | null
}
