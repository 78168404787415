import UserProfileRepository from "../../repositories/UserProfileRepository"

export interface CheckPermissionDeniedParameters {
  entity?: string,
  entityField?: string,
  restrictionType?: string
}

export default class CheckPermissionDeniedUseCase {
  private readonly userProfileRepository: UserProfileRepository

  constructor(parameters: {
    readonly userProfileRepository: UserProfileRepository
  }) {
    this.userProfileRepository = parameters.userProfileRepository
  }

  call(parameters: CheckPermissionDeniedParameters): boolean {
    return this.userProfileRepository.checkPermissionDenied(parameters)
  }
}
