import CarrierUsersI18 from "../../i18n/CarrierUsersI18"
import { I18nDiModule } from "../../../../../admin/core/di/modules/I18nDiModule"
import CarrierUsersTextProvider from "../../i18n/CarrierUsersTextProvider"

export default interface CarrierUsersI18nDiModule {
  provideCarrierUsersI18n(): CarrierUsersI18
}

export class DefaultCarrierUsersI18nDiModule
  extends I18nDiModule<CarrierUsersTextProvider>
  implements CarrierUsersI18nDiModule {

  provideCarrierUsersI18n(): CarrierUsersI18 {
    return this.single(
      CarrierUsersI18.name,
      () => new CarrierUsersI18(this.getI18nParameters())
    )
  }
}
