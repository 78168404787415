import NetworkCarrierBalanceTransaction from "../entities/carrier-balance-transactions/NetworkCarrierBalanceTransaction"
import CarrierBalanceTransaction from "../../domain/carrier-balance-transactions/CarrierBalanceTransaction"
import FuelTransactionsMapper from "./FuelTransactionsMapper"

export default class CarrierBalanceTransactionsMapper {
  mapNetworkToDomain({
    carrierBalanceTransaction
  }: {
    readonly carrierBalanceTransaction: NetworkCarrierBalanceTransaction
  }): CarrierBalanceTransaction {
    const {
      fuelTransaction
    } = carrierBalanceTransaction

    return {
      id: carrierBalanceTransaction.id,
      createdAt: carrierBalanceTransaction.createdAt,
      date: carrierBalanceTransaction.date,
      value: carrierBalanceTransaction.value,
      formattedValue: carrierBalanceTransaction.formattedValue,
      fuelTransaction: fuelTransaction && new FuelTransactionsMapper().mapNetworkToDomain({ fuelTransaction })
    }
  }
}
