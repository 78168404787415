import { Expose, Type } from "class-transformer"
import NetworkLastItemPagination from "../../pagination/NetworkLastItemPagination"

export default class NetworkFuelCompanyBalanceChangeDocumentsRequestQuery {
  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  @Expose()
  @Type(() => String)
  readonly sort?: string | null

  constructor(parameters: {
    readonly pagination?: NetworkLastItemPagination | null
    readonly sort?: string | null
  }) {
    this.pagination = parameters.pagination
    this.sort = parameters.sort
  }
}
