import CarrierBalanceTransactionsRepository from "../../domain/repositories/CarrierBalanceTransactionsRepository"
import { GetCarrierBalanceTransactionsParameters } from "../../domain/use-cases/carrier-balance-transactions/GetCarrierBalanceTransactionsUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import CarrierBalanceTransactionsNetworkSource
  from "../../../../core/data/sources/network/CarrierBalanceTransactionsNetworkSource"
import CarrierBalanceTransaction from "../../../../core/domain/carrier-balance-transactions/CarrierBalanceTransaction"
import CarrierBalanceTransactionsMapper from "../../../../core/data/mappers/CarrierBalanceTransactionsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"

export default class DefaultCarrierBalanceTransactionsRepository implements CarrierBalanceTransactionsRepository {
  private readonly carrierBalanceTransactionsNetworkSource: CarrierBalanceTransactionsNetworkSource

  constructor(parameters: {
    readonly carrierBalanceTransactionsNetworkSource: CarrierBalanceTransactionsNetworkSource
  }) {
    this.carrierBalanceTransactionsNetworkSource = parameters.carrierBalanceTransactionsNetworkSource
  }

  async getTransactions({
    carrierId,
    pagination,
    sort
  }: GetCarrierBalanceTransactionsParameters): Promise<GetObjectsResult<CarrierBalanceTransaction>> {
    const result = await this.carrierBalanceTransactionsNetworkSource
      .getTransactions({
        carrierId,
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
          pagination
        }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success": {
        const carrierBalanceTransactionsMapper = new CarrierBalanceTransactionsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.carrierBalanceWriteOffs!.map((carrierBalanceTransaction) => {
              return carrierBalanceTransactionsMapper.mapNetworkToDomain({ carrierBalanceTransaction })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
