import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import FuelCompanyBalanceChangeDocumentsMapper
  from "../../../../core/data/mappers/FuelCompanyBalanceChangeDocumentsMapper"
import FuelCompanyBalanceChangeDocumentsRepository
  from "../../domain/repositories/FuelCompanyBalanceChangeDocumentsRepository"
import {
  GetFuelCompanyBalanceChangeDocumentsParameters
} from "../../domain/use-cases/fuel-company-balance-change-documents/GetFuelCompanyBalanceChangeDocumentsUseCase"
import FuelCompanyBalanceChangeDocument
  from "../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocument"
import FuelCompanyBalanceChangeDocumentsNetworkSource
  from "../../../../core/data/sources/network/FuelCompanyBalanceChangeDocumentsNetworkSource"
import {
  CreateFuelCompanyBalanceChangeDocumentParameters
} from "../../domain/use-cases/fuel-company-balance-change-documents/CreateFuelCompanyBalanceChangeDocumentUseCase"
import FuelCompanyBalanceChangeDocumentError
  from "../../../../core/domain/fuel-company-balance-change-document/FuelCompanyBalanceChangeDocumentError"

export default class DefaultFuelCompanyBalanceChangeDocumentsRepository
  implements FuelCompanyBalanceChangeDocumentsRepository {
  private readonly fuelCompanyBalanceChangeDocumentsNetworkSource: FuelCompanyBalanceChangeDocumentsNetworkSource

  constructor(parameters: {
    readonly fuelCompanyBalanceChangeDocumentsNetworkSource: FuelCompanyBalanceChangeDocumentsNetworkSource
  }) {
    this.fuelCompanyBalanceChangeDocumentsNetworkSource = parameters.fuelCompanyBalanceChangeDocumentsNetworkSource
  }

  async getDocuments({
    fuelCompanyId,
    pagination,
    sort
  }: GetFuelCompanyBalanceChangeDocumentsParameters): Promise<GetObjectsResult<FuelCompanyBalanceChangeDocument>> {
    const result = await this.fuelCompanyBalanceChangeDocumentsNetworkSource
      .getDocuments({
        fuelCompanyId,
        pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
          pagination
        }),
        sort: sort && new SortMapper().mapDomainToNetwork({ sort })
      })

    switch (result.type) {
      case "success": {
        const fuelCompanyBalanceChangeDocumentsMapper = new FuelCompanyBalanceChangeDocumentsMapper()
        const pagesMapper = new PagesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.fuelCompanyBalanceWriteOffs!.map((fuelCompanyBalanceChangeDocument) => {
              return fuelCompanyBalanceChangeDocumentsMapper.mapNetworkToDomain({
                fuelCompanyBalanceChangeDocument
              })
            }),
            page: pagesMapper.mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }

  async createDocument({
    fuelCompanyId,
    object: fuelCompanyBalanceChangeDocument
  }: CreateFuelCompanyBalanceChangeDocumentParameters): Promise<
    CreateObjectResult<FuelCompanyBalanceChangeDocument, FuelCompanyBalanceChangeDocumentError>
  > {
    const mapper = new FuelCompanyBalanceChangeDocumentsMapper()
    const result = await this.fuelCompanyBalanceChangeDocumentsNetworkSource.createDocument({
      fuelCompanyId,
      fuelCompanyBalanceChangeDocument: mapper.mapDomainToNetwork({
        fuelCompanyBalanceChangeDocument
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompanyBalanceChangeDocument: result.data.fuelCompanyBalanceWriteOff!
          })
        }
      default:
        return result
    }
  }
}
