import GasStationsRepository from "../../repositories/GasStationsRepository"
import GetObjectsUseCase, {
  GetObjectsParameters,
  GetObjectsResult
} from "../../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import GasStation from "../../../../../core/domain/gas-stations/GasStation"
import GasStationsFilter from "../../../../../core/domain/gas-stations/GasStationsFilter"

export interface GetGasStationsParameters extends GetObjectsParameters<GasStationsFilter> {
}

export default class GetGasStationsUseCase implements GetObjectsUseCase<GasStation> {
  private readonly gasStationsRepository: GasStationsRepository

  constructor(parameters: {
    readonly gasStationsRepository: GasStationsRepository
  }) {
    this.gasStationsRepository = parameters.gasStationsRepository
  }

  async call(parameters: GetGasStationsParameters): Promise<GetObjectsResult<GasStation>> {
    return await this.gasStationsRepository.getGasStations(parameters)
  }
}
