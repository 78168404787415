import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import InstallationInstanceTypesStaticSource
  from "../../../../core/data/sources/static/InstallationInstanceTypesStaticSource"
import {
  GetInstallationInstanceTypesForInstallationInstancesParameters
} from "../../domain/use-cases/installation-instance-types/GetInstallationInstanceTypesForInstallationInstancesUseCase"
import {
  InstallationInstanceTypeSelectOption
} from "../../../../core/domain/installation-instance/InstallationInstanceType"
import InstallationInstanceTypesForInstallationInstancesRepository
  from "../../domain/repositories/InstallationInstanceTypesForInstallationInstancesRepository"

export default class DefaultInstallationInstanceTypesForInstallationInstancesRepository
  implements InstallationInstanceTypesForInstallationInstancesRepository {
  private readonly installationInstanceTypesStaticSource: InstallationInstanceTypesStaticSource

  constructor(parameters: {
    readonly installationInstanceTypesStaticSource: InstallationInstanceTypesStaticSource
  }) {
    this.installationInstanceTypesStaticSource = parameters.installationInstanceTypesStaticSource
  }

  async getInstallationInstanceTypes({
    ids
  }: GetInstallationInstanceTypesForInstallationInstancesParameters): Promise<
    GetObjectsResult<InstallationInstanceTypeSelectOption>
  > {
    const types = await this.installationInstanceTypesStaticSource.getInstallationInstances({
      ids
    })

    return {
      type: "success",
      data: {
        objects: types,
        page: {
          hasMore: false
        }
      }
    }
  }
}
