import { Expose, Type } from "class-transformer"

export default class NetworkCarrierCommercialTermsRequestFilter {
  @Expose()
  @Type(() => Number)
  readonly carrierIds?: number[] | null

  constructor(parameters: {
    readonly carrierIds?: number[] | null
  }) {
    this.carrierIds = parameters.carrierIds
  }
}
