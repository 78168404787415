import UserProfileRepository from "../../repositories/UserProfileRepository"
import { GetObjectResult } from "../../../../features/objects/domain/use-cases/objects/GetObjectUseCase"
import UserProfile from "../../entities/user-profile/UserProfile"

export default class GetUserProfileUseCase {
  private readonly userProfileRepository: UserProfileRepository

  constructor(parameters: {
    readonly userProfileRepository: UserProfileRepository
  }) {
    this.userProfileRepository = parameters.userProfileRepository
  }

  async call(): Promise<GetObjectResult<UserProfile>> {
    return await this.userProfileRepository.getUserProfile()
  }
}
