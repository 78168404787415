import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import DriversDomainDiModule from "./DriversDomainDiModule"
import DriversTableProvider from "../../presentation/table-providers/DriversTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import DriversFormProvider from "../../presentation/form-providers/DriversFormProvider"
import DriverInfoProvider from "../../presentation/info-providers/DriverInfoProvider"
import DriversI18nDiModule from "./DriversI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"

const moduleKey = "drivers"

export default interface DriversPresentationDiModule {
  provideDriversListPage(): React.ReactNode

  provideDriverInfoPage(): React.ReactNode

  provideDriverFormPage(): React.ReactNode
}

export class DefaultDriversPresentationDiModule
  extends DiModule
  implements DriversPresentationDiModule {

  private readonly timeZone: string
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly driversI18nDiModule: DriversI18nDiModule
  private readonly driversDomainDiModule: DriversDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly driversI18nDiModule: DriversI18nDiModule
    readonly driversDomainDiModule: DriversDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.driversI18nDiModule = parameters.driversI18nDiModule
    this.driversDomainDiModule = parameters.driversDomainDiModule
  }

  provideDriversListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.driversDomainDiModule.provideGetDriversUseCase(),
      tableProvider: this.provideDriversTableProvider()
    })
  }

  private provideDriversTableProvider(): DriversTableProvider {
    return this.single(
      DriversTableProvider.name,
      () => new DriversTableProvider({
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        driversI18: this.driversI18nDiModule.provideDriversI18n(),
        getCarriersUseCase: this.driversDomainDiModule.provideGetCarriersForDriverUseCase()
      })
    )
  }

  provideDriverFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.driversDomainDiModule.provideGetDriverUseCase(),
      createObjectUseCase: this.driversDomainDiModule.provideCreateDriverUseCase(),
      updateObjectUseCase: this.driversDomainDiModule.provideUpdateDriverUseCase(),
      formProvider: this.provideDriverFormProvider()
    })
  }

  private provideDriverFormProvider(): DriversFormProvider {
    return this.single(
      DriversFormProvider.name,
      () => new DriversFormProvider({
        timeZone: this.timeZone,
        driversI18: this.driversI18nDiModule.provideDriversI18n(),
        appI18: this.appI18nDiModule.provideAppI18n(),
        getCarriersForDriverUseCase: this.driversDomainDiModule.provideGetCarriersForDriverUseCase()
      })
    )
  }

  provideDriverInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.driversDomainDiModule.provideGetDriverUseCase(),
      infoProvider: this.provideDriverInfoProvider()
    })
  }

  private provideDriverInfoProvider(): DriverInfoProvider {
    return this.single(
      DriverInfoProvider.name,
      () => new DriverInfoProvider({
        timeZone: this.timeZone,
        driversI18: this.driversI18nDiModule.provideDriversI18n(),
        appI18: this.appI18nDiModule.provideAppI18n()
      })
    )
  }
}
